import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Company API URL
export const COMPANY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Organizations`;

// Create Company API
export const createCompanyAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${COMPANY_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update Company API
export const updateCompanyAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${COMPANY_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Company By ID
export const getCompanyByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all Companies
export const getCompaniesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Get all organizations of user
export const getUserOrganizations = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/GetUserAllOrganizations`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Switch default organization
export const switchDefaultUserOrganization = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${COMPANY_API_URL}/ChangeUserDefaultOrganization`, data, {
      headers: headers
    })
    .then(res => res);
};

// Switch default project
export const switchProject = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/ChangeProject/${id}`, { headers: headers })
    .then(res => res);
};

// Get all organizations of user
export const getUserFavoriteOrganizations = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/GetUserFavoriteOrganizations`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Switch default language
export const updateUserDefaultLanguage = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${COMPANY_API_URL}/ChangeUserDefaultLanguage`, data, {
      headers: headers
    })
    .then(res => res);
};

// Make Organization Favourite
export const makeOrganizationFavorite = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${COMPANY_API_URL}/ChangeUserFavoriteOrganization`, data, {
      headers: headers
    })
    .then(res => res);
};

// Get all organizations of user
export const getUserFavoritePartners = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/GetUserFavoritePartners`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Toggle favourite partners
export const toggleFavouritePartnerAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${COMPANY_API_URL}/ChangeUserFavoritePartner`, data, {
      headers: headers
    })
    .then(res => res);
};

// Get all organizations of user
export const getUserProjectsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/UserProjects`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get all partners of user
export const getUserPartnersAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/UserPartners`, {
      headers: headers
    })
    .then(({ data }) => data);
};
// Get all organizations of user
export const getUserPartnerOrCompanyProjectsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/UserPartnerOrCompanyProjects`, {
      headers: headers
    })
    .then(({ data }) => data);
};
