import axios from "axios";
import { Config } from "../../../../../../config/Config";
import authService from "../../../../../../components/api-authorization/AuthorizeService";

// Pages API URL
const PAGES_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Pages`;

// Page Translations API URL
const PAGE_TRANSLATIONS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Pages/Translations`;

const PAGE_TRANSLATIONS_API_STRING = "PageTranslations";
const PAGE_TRANSLATION_API_STRING = "PageTranslation";

// Create
export const createPageAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${PAGES_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update
export const updatePageAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${PAGES_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get By ID
export const getPageByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PAGES_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all
export const getPagesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PAGES_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deletePageByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${PAGES_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Get panels of a specific page
export const getPagePanelsByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PAGES_API_URL}/${id}/Panels`, { headers: headers })
    .then(({ data }) => data);
};

// Translations

// Get Base translation
export const getPageBaseTranslationsByIdAPI = async pageID => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PAGES_API_URL}/${pageID}/DefaultPageTranslation`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Add Translation for a Page
export const addPageTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${PAGE_TRANSLATIONS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Specific Page Translations
export const getPageTranslationsAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PAGES_API_URL}/${id}/${PAGE_TRANSLATIONS_API_STRING}`, {
      headers: headers
    })
    .then(res => res);
};

// Update Page Translation API
export const updatePageTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${PAGE_TRANSLATIONS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Single Translation By ID
export const getSingleTranslationAPI = async translationId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PAGES_API_URL}/${PAGE_TRANSLATION_API_STRING}/${translationId}`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Delete Translation By ID
export const deletePageTranslationByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${PAGES_API_URL}/DeletePageTranslation`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};
