import React, { useEffect, useState } from "react";
import "survey-react/survey.min.css";
import { Row, Col, Button } from "reactstrap";
import "./SurveyWizard.scss";
import * as Survey from "survey-react";
import TranslatableText from "../../Language/TranslatableText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheckCircle, faSave } from "@fortawesome/pro-solid-svg-icons";
import { usePlatformUserData } from "../../contexts";
import HandleCaught_API_Error from "../../apiClients/api_caughtError";
import { CustomEntityValuesClient, DashboardsClient, GetCustomEntityValueResponse } from "../../apiClients";
import { HotKeys } from "react-hotkeys";
import Select from "react-select";
import "react-select2-wrapper/css/select2.css";
import { ResourceTypes } from "../../config/ResourceTypes";
import { useHistory } from "react-router-dom";


type IProps = {
  model: Survey.SurveyModel,
  header: boolean,
  flowEngine: boolean,
  selectRef: any,
  searchInWizard: boolean,
  goBack: () => void,
  handleNavLoader: () => void,
  completeSurvey: () => void
}

type Selected = {
  label: string | undefined;
  value: string;
}


export default function SurveyNavigation(props: IProps) {
  const { header, selectRef, searchInWizard, handleNavLoader, flowEngine } = props;
  const currentOrgData = usePlatformUserData();
  const [isFirstPage, setIsFirstPage] = useState(props.model.isFirstPage);
  const [isLastPage, setIsLastPage] = useState(props.model.isLastPage);
  const [processesDropdown, setProcessesDropdown] = useState<Selected[]>([]);
  const [data, setData] = useState<GetCustomEntityValueResponse[] | null>(null);
  const history = useHistory();
  const _DashboardsClient = new DashboardsClient();
  const _CustomEntityValuesClient = new CustomEntityValuesClient();

  useEffect(() => {
    if (searchInWizard) {
      _CustomEntityValuesClient.customEntityValuesGetByRegardingObjectTypeId(ResourceTypes.process)
        .then(res => {
          let dropdownOptions = res.map(val => {
            return { label: val.name, value: val.id };
          })
          setProcessesDropdown(dropdownOptions);
          setData(res);
        })
        .catch(error => {
          HandleCaught_API_Error(error);
        })

    }
  }, [])

  const handleChange = (action: ("previous" | "next")) => {
    switch (action) {
      case 'previous': props.model.prevPage(); break;
      case 'next': props.model.nextPage(); break;
      default:
        break;
    }
    setIsFirstPage(props.model.isFirstPage)
    setIsLastPage(props.model.isLastPage)
    handleNavLoader();
  }
  const handleSelectChange = (newSelectedOption: Selected | null) => {
    if (newSelectedOption === null) {
      return;
    }
    let target = data?.find(x => x.id === newSelectedOption.value)
    if (target !== (null || undefined)) {
      //For REWrapper
      let route = "/wizard/" + target.regardingObjectTypeId + "/" + target.id;
      history.push(route);
    }
  };
  const SaveAndFinishAndTrigger = (isTrigger: boolean) => {
    // @ts-ignore
    props.model.triggerFlow = isTrigger;
    props.model.completeLastPage()
  }

  return (
    <HotKeys>
      <Row>
        <Col md={12}>
          <div className="flex space-between btns-container">
            <Button
              className={`survey-custom-btn ${isFirstPage ? "d-none" : ""}`}
              onClick={() => handleChange('previous')}
            >
              <FontAwesomeIcon icon={faArrowLeft} />&nbsp;<TranslatableText translationKey="previous page" />
            </Button>
            <Button
              className={`survey-custom-btn ${isFirstPage ? "" : "d-none"}`}
              onClick={() => props.goBack()}
            >
              <FontAwesomeIcon icon={faArrowLeft} />&nbsp;<TranslatableText translationKey="back" />
            </Button>
            {currentOrgData.currentOrgData.systemSettings.wizard_enable_save &&
              <Button
                className={`survey-custom-btn`}
                onClick={() => props.completeSurvey()}
              >
                <FontAwesomeIcon icon={faSave} />&nbsp;<TranslatableText translationKey="wizard_save" />
              </Button>
            }
            {(searchInWizard && header
            ) &&
              <div className="survey-wizard-select">

                <Select
                  ref={selectRef}
                  closeMenuOnSelect={false}
                  onChange={(e) => handleSelectChange(e)}
                  options={processesDropdown}
                />
              </div>
            }
            <Button
              className={`survey-custom-btn ${isLastPage ? "" : "d-none"}`}
              color="success"
              onClick={() => SaveAndFinishAndTrigger(false)}
            >
              <FontAwesomeIcon icon={faCheckCircle} />&nbsp;<TranslatableText translationKey="complete" />
            </Button>
            {(currentOrgData.currentOrgData.systemSettings.enable_save_finish_trigger_to_wizard && flowEngine) &&
              <Button
                className={`survey-custom-btn ${isLastPage ? "" : "d-none"}`}
                color="success"
                onClick={() => SaveAndFinishAndTrigger(true)}
              >
                <FontAwesomeIcon icon={faCheckCircle} />&nbsp;<TranslatableText translationKey="complete_and_trigger" />
              </Button>
            }
            <Button
              className={`survey-custom-btn ${isLastPage ? "d-none" : ""}`}
              onClick={() => handleChange('next')}
            >
              <TranslatableText translationKey="next page" />&nbsp;<FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </Col>
      </Row>
    </HotKeys>
  );
}