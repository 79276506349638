import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import TranslatableText from "../../../../../../Language/TranslatableText";
import {
  getTranslationsAPI,
  getAnswerOptionSetValuesByIdAPI,
  deleteAnswerOptionSetTranslationByIdAPI
} from "./answeroptionsetAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { toast } from "react-toastify";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { InlineLoading } from "../../../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class AnswerOptionSetValuesTranslations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      tableLoader: false,
      deleteLoader: false,
      deleteModal: false,
      answerOptionSetValueId: null,
      translations: [],
      isDisabled: false,
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "displayText",
          text: "Name Short",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ]
    };
  }
  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getAnswerOptionSetValuesByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.value
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
    }
    this.populateAnswerOptionSetTranslations();
  }

  populateAnswerOptionSetTranslations = () => {
    this.setState({ tableLoader: true, translations: [] });
    getTranslationsAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var self = this;
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink
                tag={Link}
                to={`/admin/wizard/answeroptionset/details/translations/update/${self.props.match.params.id}/${el.id}`}
              >
                <FontAwesomeIcon icon={faEdit} size="1x" />
              </NavLink>
              <NavLink onClick={e => self.openDeleteModal(el.id, e)}>
                <FontAwesomeIcon
                  style={{ color: "#d84a4a" }}
                  icon={faTrash}
                  size="1x"
                />
              </NavLink>
            </div>
          );
          return o;
        });
        // if (res.length == 2) {
        //   this.setState({ isDisabled: true });
        // } else {
        //   this.setState({ isDisabled: false });
        // }
        this.setState({ translations: data, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            answerOptionSetValueId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      answerOptionSetValueId: id
    });
  };

  deleteAnswerOptionSetTranslation = id => {
    this.setState({ deleteLoader: true });
    deleteAnswerOptionSetTranslationByIdAPI(
      id,
      this.context.user.organizations.userId
    )
      .then(res => {
        this.populateAnswerOptionSetTranslations();
        this.setState({
          deleteModal: false,
          answerOptionSetValueId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          answerOptionSetValueId: null,
          deleteLoader: false
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  render() {
    let { translations, columns, isDisabled, tableLoader } = this.state;
    return (
      <div className="details-view">
        {this.state.name !== "" ? (
          <h1 className="text-center capitalize">{this.state.name}</h1>
        ) : (
          <div className="center-align">
            <InlineLoading />
          </div>
        )}

        <Row>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={translations}
                columns={columns}
                search
                exportCSV={{
                  fileName: "data.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/admin/wizard/answeroptionset/details/translations/add/${this.props.match.params.id}`}
                              disabled={isDisabled ? true : null}
                            >
                              <TranslatableText translationKey="answeroptionset.add_translation" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
          <Col className="space-between flex" md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back_to_list" />
            </Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              <TranslatableText translationKey="confirm_delete" />?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="answeroptionset.confirm_delete_translation" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e =>
                    this.deleteAnswerOptionSetTranslation(
                      this.state.answerOptionSetValueId,
                      e
                    )
                  }
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(AnswerOptionSetValuesTranslations);
AnswerOptionSetValuesTranslations.contextType = GlobalContext;