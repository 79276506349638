import { faArrowRightLong } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CreateKdFiscalYearRequest, KrestonClient, OrganizationsClient, UsersClient } from "../../../apiClients";
import HandleCaught_API_Error from "../../../apiClients/api_caughtError";
import { ICreateKdFiscalYearRequest } from "../../../apiClients/api_client_v2";
import { InlineLoading } from "../../../components";
import Accent from "../../../components/accent/Accent";
import { GlobalContext } from "../../../contexts/global-context";
import TranslatableText from "../../../Language/TranslatableText";

type KdNewYearPageParamsState = {
    selectedProjectId: string | undefined;
    selectedTemplateId: string | undefined;
    selectedFiscalYear: string;
    copyTeam: boolean;
    copyRegnskabsElementer: boolean;
    copyAnswers: boolean;
    copyDifferencer: boolean;
    copyNotes: boolean;
}

const initialState: KdNewYearPageParamsState = {
    selectedProjectId: undefined,
    selectedTemplateId: undefined,
    selectedFiscalYear: "2022",
    copyTeam: true,
    copyRegnskabsElementer: true,
    copyAnswers: true,
    copyDifferencer: true,
    copyNotes: true,
};

type KdNewYearPageParams = { partnerId: string, companyId: string }
export function KdNewYearPage() {
    let { languages } = useContext(GlobalContext);
    const { partnerId, companyId } = useParams<KdNewYearPageParams>();
    const history = useHistory();
    let [newProject, setNewProject] = useState(initialState);
    let [isLoadingWhilaAction, setIsLoadingWhilaAction] = useState(false);

    const ApprovalSwal = withReactContent(Swal);

    const _OrganizationsClient = new OrganizationsClient();
    const _UsersClient = new UsersClient();
    const _krestonClient = new KrestonClient();


    let projects = useQuery(['companyProjects', companyId], async () => {
        return _OrganizationsClient.projectsGetByCompanyId(companyId);
    });
    let company = useQuery(['organizations', companyId], async () => {
        return _OrganizationsClient.organizations(companyId);
    });
    let templateProjects = useQuery(['templateProjects'], async () => {
        return _OrganizationsClient.templateProjects();
    });


    // Get company Info
    // get templates

    // teams

    // account responsible

    // state for selected project



    async function createNewYear(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (newProject.selectedProjectId === undefined) {
            Swal.fire({
                title: "Du skal vælge et projekt",
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }
        if (newProject.selectedTemplateId === undefined) {
            Swal.fire({
                title: "Du skal vælge en skabelon",
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }

        setIsLoadingWhilaAction(true);
        const value = await ApprovalSwal.fire({
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--success)',
            confirmButtonText: '<i class="fa-solid fa-thumbs-up"></i> ' + languages['kd.createNewYear'] + '!',
            title: <p>Opret <b>{newProject.selectedFiscalYear}</b> som nyt finansår for {company.data?.name}?</p>
        });
        if (value.isDismissed) {
            return;
        }
        setIsLoadingWhilaAction(true);
        let data = new CreateKdFiscalYearRequest({
            originProjectId: newProject.selectedProjectId,
            templateProjectId: newProject.selectedTemplateId,
            fiscalYear: newProject.selectedFiscalYear,
            copyTeams: newProject.copyTeam,
            copyUsers: false,
            copyRegnskabsElementer: newProject.copyRegnskabsElementer,
            copyQuestionAnswers: newProject.copyAnswers,
            copyDifferencer: newProject.copyDifferencer,
            copyNotes: newProject.copyNotes,
        });

        await _krestonClient.createKdFiscalYear(data)
            .then(async (response) => {
                toast.success("Finansåret er oprettet");
            }).catch(async (error) => {
                HandleCaught_API_Error(error)
            }).finally(async () => {
                setIsLoadingWhilaAction(false);
                history.goBack();
            });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.type === "checkbox") {
            setNewProject((prevState) => ({ ...prevState, [e.target.name]: !prevState[e.target.name] }));
        } else {
            setNewProject((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    }
    console.log(newProject);
    return (
        <div className="admin-view">
            <Accent useIcon icon={"fa-calendar-circle-plus"}>
                <TranslatableText translationKey="kd.newFiscalYear" /><br />
                {company.data ? company.data.name : ""}
            </Accent>
            {isLoadingWhilaAction && <InlineLoading />}
            {(projects.isLoading) ? (<InlineLoading />) : (
                <Form onSubmit={(e) => createNewYear(e)}>
                    <Row form>
                        {/* <Col md={this.state.wizardId === "" ? 4 : 3}>
                        <FormGroup
                            className={validation.name.isInvalid ? "has-error" : ""}
                        >
                            <Label for="name">
                                <TranslatableText translationKey="name" />
                            </Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                placeholder=""
                                value={this.state.name}
                                onChange={this.handleChange}
                                disabled={lock ? true : null}
                            />
                            <span className="help-block">{validation.name.message}</span>
                        </FormGroup>
                    </Col> */}
                        <Col md={4}>
                            <FormGroup>
                                <Label for="selectedProjectId">
                                    Project
                                </Label>
                                <Input
                                    type="select"
                                    name="selectedProjectId"
                                    id="selectedProjectId"
                                    value={newProject.selectedProjectId}
                                    onChange={handleChange}
                                >
                                    <option value="">-- Vælg --</option>
                                    {projects.data?.map((e, key) => {
                                        if (e.isActive && e.systems![0].name === "KD-Sys 360") {
                                            return (
                                                <option key={e.id} value={e.id}>
                                                    {(e.fiscalYear ?? '') + ' - ' + e.name}
                                                </option>
                                            );
                                        }
                                    })}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={4} className={"center"}>
                            <FontAwesomeIcon icon={faArrowRightLong} size={"3x"} className={"mt-2"} />
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="selectedTemplateId">
                                    Template
                                </Label>
                                <Input
                                    type="select"
                                    name="selectedTemplateId"
                                    id="selectedTemplateId"
                                    value={newProject.selectedTemplateId}
                                    onChange={handleChange}
                                >
                                    <option value="">-- Vælg --</option>
                                    {templateProjects.data?.map((e, key) => {
                                        if (e.isActive) {
                                            return (
                                                <option key={e.id} value={e.id}>
                                                    {e.name}
                                                </option>
                                            );
                                        }
                                    })}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={4} />
                        <Col md={4}>
                            <FormGroup>
                                <Label for="selectedFiscalYear">
                                    Finansår
                                </Label>
                                <Input
                                    type="select"
                                    name="selectedFiscalYear"
                                    id="selectedFiscalYear"
                                    value={newProject.selectedFiscalYear}
                                    onChange={handleChange}
                                >
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={4} />

                        <Col md={5} />
                        <Col md={4}>
                            {FormInputCheckbox("copyTeam", false, false)}
                            {FormInputCheckbox("copyRegnskabsElementer", false, false)}
                            {FormInputCheckbox("copyAnswers", false, false)}
                            {FormInputCheckbox("copyDifferencer", false, false)}
                            {FormInputCheckbox("copyNotes", false, false)}
                        </Col>
                        <Col md={3} />

                        <Col md={2} />
                        <Col className="space-between flex" md={8}>
                            <Button onClick={() => history.goBack()}>
                                <TranslatableText translationKey="back_to_list" />
                            </Button>
                            {projects.isLoading ? (
                                <InlineLoading />
                            ) : (
                                <Button>
                                    <TranslatableText translationKey="create" />
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Form>
            )}
        </div >
    );
    function FormInputCheckbox(name: string, required = false, locked = false) {
        return (
            <FormGroup className={`custom-control custom-switch`}>
                <input type='checkbox'
                    id={name.toString()}
                    required={required}
                    disabled={locked}
                    checked={newProject[name]}
                    value={newProject[name]}
                    name={name}
                    onChange={handleChange}
                    className={`custom-control-input`}
                />
                <Label for={name.toString()} className={'custom-control-label'} style={{ lineBreak: 'anywhere' }}>
                    <TranslatableText translationKey={name.toString()} />
                </Label>
            </FormGroup>
        );
    }
}


