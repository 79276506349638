import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faFlagCheckered, faFlag } from '@fortawesome/pro-solid-svg-icons';
import { useLanguage, usePlatformUser, usePlatformUserData } from '../contexts';
import axios from 'axios';
import { Config } from '../config/Config';
import { updateUserDefaultLanguage } from '../views/company-admin/views/company/firmAPI';
import { toast } from 'react-toastify';

export function LanguageSelector() {
    const { languageDispatch, language, languagesSupported, languageData } = useLanguage();
    const { userDispatch, currentOrgData } = usePlatformUserData();
    const [isOpen, setIsOpen] = useState(false);

    function updateLanguage(localeId: string) {
        languageDispatch({ type: 'request', language: localeId });
        const commitData = {
            currentDefaultOrganizationId: currentOrgData.organizationId,
            selectedLanguageForDefault: localeId,
            currentDefaultUserId: currentOrgData.userId
        };
        updateUserDefaultLanguage(commitData)
            .then(() => {
                // After Switching Language - reload everything
                axios.get(`${Config.globalization_url}/Globalize?localeId=${localeId}&resourceSet=${Config.resourceSet[0]}`)
                    .then(({ data }) => data)
                    .then(res => {
                        languageDispatch({ type: 'success', results: res, language: localeId });
                    })
                    .catch(err => {
                        languageDispatch({ type: 'failure', error: err.message })
                    });
            })
            .catch(err => {
                toast.error(languageData.languageData["api_error"]);
                languageDispatch({ type: 'failure', error: err.message })
            });
    }

    return (
        <NavItem>
            <Dropdown
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
            >
                <DropdownToggle caret>
                    <FontAwesomeIcon icon={faGlobe} />
                </DropdownToggle>
                <DropdownMenu className="dropdown-wrapper" right>
                    {languagesSupported.map(lang => (
                        <DropdownItem
                            key={lang.value}
                            className={language === lang.value ? "selected" : ""}
                            onClick={() => updateLanguage(lang.value)}
                        >
                            <FontAwesomeIcon
                                icon={language === lang.value ? faFlagCheckered : faFlag}
                            />
                            &nbsp;
                            {lang.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </NavItem>
    );
}