// @ts-nocheck
import {
  faHandshake, faStar
} from "@fortawesome/pro-regular-svg-icons";
import {
  faHandshake as faHandshakeFull, faRandom, faSave, faStar as faStarFull
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import classnames from "classnames";
import { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { toast } from "react-toastify";
import {
  Button, Col, Form,
  FormGroup,
  Label, Nav,
  NavItem,
  NavLink,
  Row, TabContent,
  TabPane
} from "reactstrap";
import { UsersClient } from "../../apiClients";
import HandleCaught_API_Error from "../../apiClients/api_caughtError";
import { InlineLoading, ScreenLoading } from "../../components";
import authService from "../../components/api-authorization/AuthorizeService";
import Gap from "../../components/gap/Gap";
import TabPaneSettingsTable from "../../components/table/TabPaneSettingsTable";
import { GlobalContext } from "../../contexts/global-context";
import TranslatableText from "../../Language/TranslatableText";
import {
  getUserPartnersAPI, getUserProjectsAPI, makeOrganizationFavorite, switchDefaultUserOrganization, toggleFavouritePartnerAPI, updateUserDefaultLanguage
} from "../company-admin/views/company/firmAPI";
import "./settings.scss";


class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableLoader: false,
      activeTab: "1",
      username: "",
      name: "",
      companies: [],
      partners: [],
      language: "",
      favorites: [],
      favoritePartners: [],
      manageOrg: true,
      userDetails: null,
      landOnDashboard: false,
      columns: [
        {
          dataField: "name",
          text: "Name",
          sort: true,
          formatter: this.nameFormatter
        },
        {
          dataField: "address",
          text: "Address",
          sort: true
        },
        {
          dataField: "logo",
          text: "Logo",
          sort: true,
          formatter: this.logoFormatter
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ]
    };
    this._UsersClient = new UsersClient();
    this.updateDefaultLanguage = null;
  }

  componentDidMount() {
    let tab = this.props.history.location.search;
    const { user } = this.context;
    if (tab === '') {
      tab = '1'
    } else {
      tab = tab.split("=")[1];
    }
    this.toggle(tab);
    this.setState({ activeTab: tab });
    this.setState({ language: this.context.language });
    if (user.organizations.b2CId) {
      this._UsersClient.userDetailGetById(user.organizations.b2CId)
        .then(res => {
          this.setState({ userDetails: res, landOnDashboard: !res.landOnDashboard })
        })
        .catch(error => {
          HandleCaught_API_Error(error);
        })
    }
  }

  handleSubmit = (event: any) => {
    this.setState({ tableLoader: true });
    const { user } = this.context;
    event.preventDefault();
    let data = {
      userID: user.organizations.b2CId,
      landOnDashboard: !this.state.landOnDashboard,
    }
    this._UsersClient.updateUserSetting(data)
      .then(res => {
        let temp = this.state.userDetails;
        temp.landOnDashboard = !this.state.landOnDashboard;
        this.setState({ tableLoader: false, userDetails: temp })
      })
      .catch(error => {
        HandleCaught_API_Error(error);
        this.setState({ tableLoader: false });
      })
  }

  populatePartners = () => {
    this.setState({
      tableLoader: true,
      partners: []
    });

    getUserPartnersAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var self = this;
        var favoriteOrganizations = [];
        var favoritePartnersList = [];
        var data = res.map(function (el) {
          if (el.isFavorite === true) {
            favoriteOrganizations.push(el);
          }
          if (el.isPartnerFavourite) {
            favoritePartnersList.push(el);
          }
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink
                onClick={e =>
                  self.toggleFavouritePartner(
                    el.userId,
                    el.organizationId,
                    el.isPartnerFavourite,
                    e
                  )
                }
              >
                <Tippy placement="top" content="Mark Favourite">
                  <span>
                    {!el.isPartnerFavourite ? (
                      <FontAwesomeIcon
                        style={{ color: "#00a1ff" }}
                        icon={faHandshake}
                        size="1x"
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ color: "#00a1ff" }}
                        icon={faHandshakeFull}
                        size="1x"
                      />
                    )}
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
          return o;
        });
        this.setState({
          partners: data,
          tableLoader: false,
          favorites: favoriteOrganizations,
          favoritePartners: favoritePartnersList
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };
  //
  populateOrganizations = () => {
    this.setState({
      tableLoader: true,
      companies: []
    });

    getUserProjectsAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var self = this;
        var favoriteOrganizations = [];
        var favoritePartnersList = [];
        var data = res.map(function (el) {
          if (el.isFavorite === true) {
            favoriteOrganizations.push(el);
          }
          if (el.isPartnerFavourite) {
            favoritePartnersList.push(el);
          }
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              {el.isFavorite === true ? (
                <NavLink
                  onClick={e =>
                    self.makeFavourite(
                      el.organizationId,
                      el.userId,
                      el.isFavorite,
                      el.isDefault,
                      e
                    )
                  }
                >
                  <Tippy placement="top" content="Mark Favourite">
                    <span>
                      <FontAwesomeIcon
                        style={{ color: "orange" }}
                        icon={faStarFull}
                        size="1x"
                      />
                    </span>
                  </Tippy>
                </NavLink>
              ) : (
                <NavLink
                  onClick={e =>
                    self.makeFavourite(
                      el.organizationId,
                      el.userId,
                      el.isFavorite,
                      el.isDefault,
                      e
                    )
                  }
                >
                  <Tippy placement="top" content="Mark Favourite">
                    <span>
                      <FontAwesomeIcon icon={faStar} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
              )}
              {!el.isDefault && (
                <NavLink onClick={() => self.makeDefaultOrganization(el.organizationId, el.userId)} >
                  <Tippy placement="top" content={<TranslatableText translationKey="change_project" />}>
                    <span>
                      <FontAwesomeIcon icon={faRandom} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
              )}

              {el.isPartner === true ? (
                <NavLink
                  onClick={e =>
                    self.toggleFavouritePartner(
                      el.userId,
                      el.organizationId,
                      el.isPartnerFavourite,
                      e
                    )
                  }
                >
                  <Tippy placement="top" content="Mark Favourite">
                    <span>
                      {!el.isPartnerFavourite ? (
                        <FontAwesomeIcon
                          style={{ color: "#00a1ff" }}
                          icon={faHandshake}
                          size="1x"
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{ color: "#00a1ff" }}
                          icon={faHandshakeFull}
                          size="1x"
                        />
                      )}
                    </span>
                  </Tippy>
                </NavLink>
              ) : (
                <></>
              )}
            </div>
          );
          return o;
        });
        this.setState({
          companies: data,
          tableLoader: false,
          favorites: favoriteOrganizations,
          favoritePartners: favoritePartnersList
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  logoFormatter = cell => {
    if (
      cell !== "" &&
      cell !== null &&
      cell !== undefined &&
      cell.startsWith("data:")
    ) {
      return <img className="image-max-width" src={cell} />;
    } else {
      return "";
    }
  };

  nameFormatter = cell => {
    return (
      <Tippy placement="top" content={cell}>
        <span>{cell}</span>
      </Tippy>
    );
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "language") {
      this.setState(
        {
          [name]: value
        },
        () => {
          this.updateDefaultLanguage = toast.info(
            "Updating language, please wait...",
            {
              position: toast.POSITION.BOTTOM_RIGHT
            }
          );
          const data = {
            currentDefaultOrganizationId: this.context.user.organizations
              .organizationId,
            selectedLanguageForDefault: value,
            currentDefaultUserId: this.context.user.organizations.userId
          };
          updateUserDefaultLanguage(data)
            .then(res => {
              this.context.updateLanguage(value);
              toast.dismiss(this.updateDefaultLanguage);
              toast.success("Language updated successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 2000
              });
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
            });
        }
      );
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  makeDefaultOrganization = (organizationId, userId) => {
    const data = {
      currentDefaultUserId: this.context.user.organizations.userId,
      currentDefaultOrganizationId: this.context.user.organizations
        .organizationId,
      selectedForDefaultOrganizationId: organizationId,
      selectedForDefaultUserId: userId
    };
    this.setState({ tableLoader: true, companies: [] });
    switchDefaultUserOrganization(data)
      .then(res => {
        window.location.replace("/");
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  makeFavourite = (organizationId, userId, isFavorite, isDefault) => {
    if (isFavorite === false && this.state.favorites.length + 1 > 4) {
      alert("Only 4 organizations can be marked as Favorite");
      return false;
    }
    // if (isFavorite === true && isDefault === true) {
    //   alert("You cannot unfavorite a default selected organization");
    //   return false;
    // }
    const data = {
      currentDefaultUserId: userId,
      currentDefaultOrganizationId: organizationId,
      isFavorite: isFavorite === true ? false : true
    };
    this.setState({ tableLoader: true, companies: [] });
    makeOrganizationFavorite(data)
      .then(res => {
        authService.getAccessToken().then(token => {
          // update organizations table
          // this.props
          //   .dispatch(fetchOrganizations(token))
          //   .then(() => {
          //     this.populateOrganizations();
          //   })
          //   .catch(() => {
          //     console.log("failed");
          //   });
          this.populateOrganizations();
          // update favourite organizations in the menu list
          // this.props
          //   .dispatch(fetchFavouriteOrganizations(token))
          //   .then(res => {
          //     console.log(
          //       "Fav Organizations Dispatch Called:",
          //       res,
          //       "check props",
          //       this.props
          //     );
          //   })
          //   .catch(() => {
          //     console.log("failed");
          //   });
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  toggleFavouritePartner = (userId, organizationId, isFavorite) => {
    console.log("toogle")
    if (isFavorite === false && this.state.favoritePartners.length + 1 > 4) {
      alert("Only 4 partners can be marked as favorites");
      return false;
    }
    const data = {
      userId: userId,
      organizationId: organizationId,
      isPartnerFavourite: isFavorite === true ? false : true
    };
    this.setState({ tableLoader: true, partners: [] });
    toggleFavouritePartnerAPI(data)
      .then(res => {
        // authService.getAccessToken().then(token => {
        //   // update organizations table
        //   this.props
        //     .dispatch(fetchOrganizations(token))
        //     .then(() => {
        //       this.populatePartners();
        //     })
        //     .catch(() => {
        //       console.log("failed");
        //     });

        //   // update favourite organizations in the menu list
        //   this.props
        //     .dispatch(fetchFavouritePartners(token))
        //     .then(res => {
        //       console.log(
        //         "Fav Organizations Dispatch Called:",
        //         res,
        //         "check props",
        //         this.props
        //       );
        //     })
        //     .catch(() => {
        //       this.setState({ tableLoader: false, partners: temp });
        //       console.debug("failed");
        //     });
        // });
        this.populatePartners();
      })
      .catch(error => {
        HandleCaught_API_Error(error);
      });
  };
  render() {
    let { partners, companies, columns, loading, tableLoader, activeTab } = this.state;
    return (
      <div className="settings">
        {loading && (
          <ScreenLoading />
        )}
        <Row>
          <Col md={12}>
            <h3>
              <TranslatableText translationKey="settings" />
            </h3>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="5" sm="3" md="3">
            <Nav tabs vertical pills>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1"
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  <TranslatableText translationKey="manage_projects" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2"
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  <TranslatableText translationKey="manage_partners" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "3"
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  <TranslatableText translationKey="manage_users" />
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="7" sm="9" md="9">
            <TabContent activeTab={activeTab}>
              <TabPaneSettingsTable
                tabId={"1"}
                title={<TranslatableText translationKey="manage_projects" />}
                data={companies}
                columns={columns}
                tableLoader={tableLoader}
                onLoad={this.populateOrganizations}
              />
              <TabPaneSettingsTable
                tabId={"2"}
                title={<TranslatableText translationKey="manage_partners" />}
                data={partners}
                columns={columns}
                tableLoader={tableLoader}
                onLoad={this.populatePartners}
              />
              <TabPane tabId={"3"}>
                <h4>
                  <TranslatableText translationKey="manage_users" />
                </h4>
                <Gap size={"20px"} />
                <Row>
                  <Col md={12}>
                    <>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <TranslatableText translationKey="name" />
                            </td>
                            <td>{this.state.userDetails?.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <TranslatableText translationKey="email" />
                            </td>
                            <td>{this.state.userDetails?.email}</td>
                          </tr>
                          <tr>
                            <td>
                              <TranslatableText translationKey="phone" />
                            </td>
                            <td>{this.state.userDetails?.phone}</td>
                          </tr>
                          <tr>
                            <td>
                              <TranslatableText translationKey="role" />
                            </td>
                            <td>{this.state.userDetails?.role}</td>
                          </tr>
                          <tr>
                            <td>
                              <TranslatableText translationKey="landOnPartnerPage" />
                            </td>
                            <td>{!this.state.userDetails?.landOnDashboard ? "Yes" : "No"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form onSubmit={this.handleSubmit}>
                      <Row form>
                        <Col className="justify-content-center flex" md={12}>
                          <FormGroup className={`custom-control custom-switch`}>

                            <input type='checkbox'
                              id={'landOnDashboard'}
                              name={'landOnDashboard'}
                              className={`custom-control-input`}
                              checked={this.state.landOnDashboard}
                              onChange={this.handleChange}
                            />
                            <Label for={'landOnDashboard'} className={'custom-control-label'}>
                              <TranslatableText translationKey={'landOnPartnerPage'} />
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col className="flex-end flex" md={12}>
                          {this.state.tableLoader ?
                            <InlineLoading />
                            :
                            <Button type='submit'>
                              <FontAwesomeIcon icon={faSave} />&nbsp;<TranslatableText translationKey={'update'} />
                            </Button>
                          }
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="4">
                <h4>
                  <TranslatableText translationKey="notification_settings" />
                </h4>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Settings;
Settings.contextType = GlobalContext;