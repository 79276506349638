import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Dashboard API URL
const SHAREPOINT_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/SharePoint`;

// Create Folders
export const createFolderAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${SHAREPOINT_API_URL}/CreateFolder`, data, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Upload Files
export const uploadFilesAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${SHAREPOINT_API_URL}/UploadFiles`, data, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get all root folders
export const getRootFolderItemsAPI = async language => {
  const token = await authService.getAccessToken();
  var headers = {
    Language: language,
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${SHAREPOINT_API_URL}/GetRootFolderItems`, { headers: headers })
    .then(({ data }) => data);
};

// Get FoldersById
export const getFoldersByIdAPI = async uniqueID => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${SHAREPOINT_API_URL}/${uniqueID}/GetFolderItems`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Delete By uniqueID
export const deleteAssetAPI = async uniqueId => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${SHAREPOINT_API_URL}/DeleteFolderOrFile`, {
      data: {
        uniqueId: uniqueId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(({ data }) => data);
};

// Download File
export const downloadSharePointFileByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };

  return axios
    .get(`${SHAREPOINT_API_URL}/DownloadFile/${id}`, {
      headers: headers,
      responseType: "blob"
    })
    .then(res => res);
};

// Download Root folder
export const downloadSharePointRootFolderAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };

  return axios
    .get(`${SHAREPOINT_API_URL}/DownloadRootFolder`, {
      headers: headers,
      responseType: "blob"
    })
    .then(res => res);
};
