import { faBook, faBooks, faCalendarCheck, faClipboard, faCrosshairs, faShieldCheck, faTachometerAlt, faTasks } from "@fortawesome/pro-solid-svg-icons";
import { NavMenuLink } from "../NavMenuLink";

export function DPOAdvisorNavigation(menuItemClick: () => void) {
  return (
    <>
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faTachometerAlt}
        path='/'
        translationKey='dashboard'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faBook}
        path='/processes'
        translationKey='dpo.processes'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faBooks}
        path='/entity/99a987d7-f819-4c55-de50-08d824c34b31'
        translationKey='assets'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faClipboard}
        path='/entity/ec3c87c8-4053-4147-5034-08d816ab75f0'
        translationKey='processor'
      />
      {/* <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faFileAlt}
        path='/documents'
        translationKey='documents'
      /> */}
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faShieldCheck}
        path='/platform/dataProtection'
        translationKey='dataProtection'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faCrosshairs}
        path='/platform/riskAssesment'
        translationKey='dpo.risk.Header'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faCalendarCheck}
        path='/annual-cycle-of-work'
        translationKey='annualCycleOfWork'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faTasks}
        path='/tasks'
        translationKey='tasks'
      />
    </>
  );
}
