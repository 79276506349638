import React, { ChangeEvent, useState } from "react";
import "survey-react/survey.min.css";
import { Button, Form, Label, Input, FormGroup, Card, CardBody, CardTitle, CardText } from "reactstrap";
import "./SurveyWizard.scss";

import axios from "axios";
import { Config } from "../../config/Config";
import { InlineLoading } from "../../components";

const ENTITY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Survey`;

type IProps = {
  questionnaire: string
}

interface IRequestNewLinkData {
  email: string,
  questionnaire: string
}

export default function RequestNewLink(props: IProps) {
  const [email, setEmail] = useState('')
  const [emailValidation, setEmailValidation] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasRequested, setHasRequested] = useState(false)


  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    // setLoading(true)

    // if (email && email.indexOf('@') < 0) {
    //   setEmailValidation('Invalid email');
    //   return
    // }
    // else if (email && email.indexOf('.') < 0) {
    //   setEmailValidation('Invalid email');
    //   return
    // }
    // else if (!email) {
    //   setEmailValidation('Invalid email');
    //   return
    // }
    // else {
    //   setEmailValidation('')
    // }

    // await requestNewOTPLink({email, questionnaire: props.questionnaire})

    // setLoading(false)
    // setHasRequested(true)
    // setEmail('')
  }

  const requestNewOTPLink = async (data: IRequestNewLinkData) => {
    return axios.post(`${ENTITY_API_URL}/RequestNewLinkForSurvey`, data)
  };

  if (loading) {
    return (
      <div className="container d-flex vh-100 vw-100">
        <div className="row h-100 justify-content-center align-items-center mx-auto">
          <Card>
            <CardBody>
              <InlineLoading />
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }

  if (hasRequested) {
    return (
      <div className="container d-flex vh-100 vw-100">
        <div className="row h-100 justify-content-center align-items-center mx-auto">
          <Card>
            <CardBody>
              <CardTitle tag="h5">Check your email</CardTitle>
              <CardText>
                If the email you entered matched the original email in the invitation, <br />
                then you'll receive a new link soon.
              </CardText>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }

  // return (
  //   <div className="container d-flex vh-100 vw-100">
  //     <div className="row h-100 justify-content-center align-items-center mx-auto">
  //       <Form className="card" onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
  //         <CardBody>
  //           <CardTitle tag="h5">Request new link to questionnaire</CardTitle>
  //           <CardText tag="p">
  //             You are not authorized to see this resource.<br/> 
  //             If you still need access, you can request a new link be sent to your email.
  //           </CardText>
  //           <CardText tag="p">
  //             The email has to match the one provided in the initial inviation.
  //           </CardText>
  //           <FormGroup className={emailValidation ? "has-error" : ""}>
  //             <Label for="email">
  //               Email
  //             </Label>
  //             <Input
  //               type="text"
  //               name="email"
  //               id="email"
  //               placeholder="Email"
  //               value={email}
  //               onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
  //             />
  //             <span className="help-block">
  //               {emailValidation}
  //             </span>
  //           </FormGroup>
  //           <FormGroup>
  //             <Button className="btn btn-primary">
  //               Request link
  //             </Button>
  //           </FormGroup>
  //         </CardBody>
  //       </Form>
  //     </div>
  //   </div>
  // );

  return (
    <div className="container d-flex vh-100 vw-100">
      <div className="row h-100 justify-content-center align-items-center mx-auto">
        <Form className="card">
          <CardBody>
            <CardTitle tag="h5">Dit link er ikke gyldigt.</CardTitle>
            <CardText tag="p">
              Det link du har brugt for at komme hertil er ikke længere gyldigt.<br />
              Hvis du stadig har brug for adgang, kan du bede om et nyt link via din konto her:<br />
            </CardText>
            <CardText tag="p">
              <a href={`https://bruun-rasmussen.dk/m/account`}>
                https://bruun-rasmussen.dk/m/account
              </a>
            </CardText>
          </CardBody>
        </Form>
      </div>
    </div>
  );
}