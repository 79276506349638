import React from 'react';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { UserOrganization } from '../../../apiClients';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import TranslatableText from '../../../Language';
import { faLaptopHouse } from '@fortawesome/pro-light-svg-icons';

export function NavMenuFavoriteProjects(currentOrgId: string, organizations: UserOrganization[], changeProject: (organizationId: string) => void, navigateTo: (view: string) => void) {
    return organizations.map(
        (item, index) => {
            if (index === 4) {
                return (
                    <DropdownItem 
                    key={item.organizationId}
                    className={'company-item'}
                    onClick={() => navigateTo("/settings?tab=1")}
                    >
                        <FontAwesomeIcon icon={faLaptopHouse} />&nbsp;<TranslatableText translationKey="more" /> <TranslatableText translationKey="companies" />
                    </DropdownItem>
                );
            } else {
                let _currOrg = currentOrgId === item.organizationId;
                return (
                    <DropdownItem
                        key={item.organizationId}
                        className={`company-item ${_currOrg && 'default-company-selected'}`}
                        onClick={() => changeProject(item.organizationId)}
                    >
                        <div>
                            {_currOrg ? (
                                <FontAwesomeIcon style={{ color: "orange" }} icon={faCircleSolid} />
                            ) : (
                                <FontAwesomeIcon icon={faCircle} />
                            )}
                            &nbsp;{item.name}
                        </div>
                    </DropdownItem>
                );
            }
        }
    );
}
