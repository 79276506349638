import { faBallotCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faInfoCircle, faPencilAlt, faRandom } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import {
  Column,
  Table,
  ColumnDef,
  createColumnHelper,
  flexRender, getCoreRowModel,
  getExpandedRowModel, Row, useReactTable,
  FilterFn,
  SortingFn,
  sortingFns,
  ColumnFiltersState,
  getFilteredRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import Tippy from "@tippyjs/react";
import dayjs from "dayjs";
import localeDa from 'dayjs/locale/da';
import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, ButtonGroup, Col, NavLink, Row as RRow } from "reactstrap";
import { KrestonClient, OrganizationsClient } from "../../../apiClients";
import HandleCaught_API_Error from "../../../apiClients/api_caughtError";
import { PartnersApprovals } from "../../../apiClients/api_client_v2";
import { InlineLoading } from "../../../components";
import Accent from "../../../components/accent/Accent";
import groupBy from "../../../components/utilities/groupBy/groupBy";
import { approvalStateVisualiser, getApprovalState, getApprovalStateKd } from "../../../config/ApprovalFlowState";
import { changeProject } from "./changeProject";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils'
import { DebouncedInput, Filter } from "../../../components/table/tableFunctions";
import { ReactTableDevtools } from "@tanstack/react-table-devtools";
import SecuredComponent, { AccountLevel } from "../../../components/secured-component/SecuredComponent";
import { faArrowDownAZ, faArrowUpAZ } from "@fortawesome/pro-regular-svg-icons";


declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    )
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}


interface FormattedGetApprovalFlowsHistoryResponse extends PartnersApprovals {
  rowSpan: number,
  sl_id: number
}

type PartnerParams = { partnerId: string }
export default function PartnerApprovals() {
  const { partnerId } = useParams<PartnerParams>();
  const _krestonClient = new KrestonClient();
  const _OrganizationsClient = new OrganizationsClient();

  const partnerData = useQuery(['organizations', partnerId], async () => {
    return _OrganizationsClient.organizations(partnerId);
  });

  const { isLoading, isError, data, error, } = useQuery(['platformApprovals', partnerId], async () => {
    return _krestonClient.approvalStatus(partnerId)
      .then(res => {
        return formateApprovalsList(res);
      })
  });

  const formateApprovalsList = (res: PartnersApprovals[]) => {
    res = res.reverse();
    let tempFinal: FormattedGetApprovalFlowsHistoryResponse[] = []
    let rowId = 1;

    groupBy(res, x => (x.flowEngineHistoryLogId)).forEach((entityAppr) => {
      let t = entityAppr;
      entityAppr.forEach((approval, i) => {
        if (i === 0 || approval.dataVersion === '1') {
          let formattedAprroval = approval as FormattedGetApprovalFlowsHistoryResponse;
          formattedAprroval.rowSpan = approval.dataVersion !== '1' ? entityAppr.length : 1;
          formattedAprroval.sl_id = rowId;
          rowId++;
        }
      })
      tempFinal.push(...t as FormattedGetApprovalFlowsHistoryResponse[])
    })
    return tempFinal;
  }

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [
      {
        id: 'approvalFlowState',
        value: 'Afventer',
      }
    ]
  )
  const [globalFilter, setGlobalFilter] = React.useState('')

  if (isError) {
    HandleCaught_API_Error(error);
  }

  const columnHelper = createColumnHelper<FormattedGetApprovalFlowsHistoryResponse>();
  const columns = React.useMemo<ColumnDef<FormattedGetApprovalFlowsHistoryResponse, any>[]>(
    () => [
      {
        accessorFn: row => row.organizationName,
        id: 'sl_id',
        cell: info => info.getValue(),
        header: () => <span>Projekt</span>,
        footer: props => props.column.id,
        enableSorting: true,
      },
      {
        accessorFn: row => row.fiscalYear,
        id: 'f_sl_id',
        cell: info => info.getValue(),
        header: () => <span>Finansår</span>,
        footer: props => props.column.id,
        enableSorting: true,
      },
      {
        accessorFn: row => row.regardingObject,
        id: 'regardingObject',
        header: () => <span>Process</span>,
        cell: ({ row }) => (
          <Tippy placement="top" content={row.original.regardingObject}>
            <span>
              {row.original.icon && <FontAwesomeIcon className="mr-1"
                // @ts-ignore
                icon={row.original.icon} size="1x" />}
              &nbsp;{row.original.regardingObject}
            </span>
          </Tippy>
        ),
        footer: props => props.column.id,
        enableSorting: true,
      },
      {
        accessorFn: row => getApprovalStateKd(row.approvalFlowState),
        id: 'approvalFlowState',
        header: () => <span>Status</span>,
        cell: ({ row }) => (
          <>
            {approvalStateVisualiser(row.original.approvalFlowState)}
            <Tippy placement="top" content={<>Sendt til godkendelse: {dateFormatter(row.original.triggerTime)}</>}>
              <span>
                &nbsp;{dateFormatter(row.original.updatedAtTime)}
              </span>
            </Tippy>
          </>
        ),
        footer: props => props.column.id,
        enableSorting: true,
        enableColumnFilter: true,
        enableGlobalFilter: true,
      },
      columnHelper.display({
        maxSize: 2,
        id: 'actions',
        cell: props =>
          <div className="actions">
            <Tippy placement="top" content="Skift til dete projekt">
              <span>
                <NavLink onClick={e => changeProject(props.row.original.organizationId, '/approvals')}>
                  <FontAwesomeIcon icon={faRandom} size="1x" />
                </NavLink>
              </span>
            </Tippy>
          </div>,
      }),
    ],
    []
  )

  const table = useReactTable<FormattedGetApprovalFlowsHistoryResponse>({
    data: data ?? [],
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    // debugTable: true,
    // debugHeaders: true,
    debugColumns: true,
    // debugRows: true,
    initialState: {
      pagination: {
        pageSize: 50
      },
    },
  })

  React.useEffect(() => {
    console.log("useEffect", table.getState().columnFilters[0]?.id)
    if (table.getState().columnFilters[0]?.id === 'fullName') {
      console.log("useEffect - fullName")
      if (table.getState().sorting[0]?.id !== 'fullName') {
        console.log("useEffect - not fullName")
        table.setSorting([{ id: 'fullName', desc: false }])
      }
    }
  }, [table.getState().columnFilters[0]?.id])

  return (
    <div className="admin-partners-view">
      <Accent useIcon={true} icon={faBallotCheck}>
        Dine godkendelser for {partnerData.data?.name}
      </Accent>
      <div className="table-container">

        <div className="p-2">
          <div className="h-2" />
          <table className="table table-bordered table-striped table-hover">

            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <>&nbsp;<FontAwesomeIcon icon={faArrowDownAZ} /></>,
                                desc: <>&nbsp;<FontAwesomeIcon icon={faArrowUpAZ} /></>,
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                            {header.column.getCanFilter() ? (
                              <div>
                                <Filter column={header.column} table={table} />
                              </div>
                            ) : null}
                          </>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            {isLoading && <tbody>
              <tr>
                <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                  <InlineLoading />
                </ td>
              </tr>
            </tbody>}
            {!isLoading && table.getRowModel().rows.length === 0 && <tbody>
              <tr>
                <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                  <span>Du har ingen godkendelser for denne partner.</span>
                </ td>
              </tr>
            </tbody>}
            <tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Fragment key={row.id}>
                    <tr className={rowClassesApprovals(row.original)}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                    </tr>
                    {row.getIsExpanded() && (
                      <tr>
                        {/* 2nd row is a custom 1 cell row */}
                        <td colSpan={row.getVisibleCells().length}>
                          {renderSubComponent({ row })}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )
              })}
            </tbody>
          </table>
          <div className="h-2" />
          <div className="d-flex justify-content-between gap-2">
            <select
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Vis {pageSize}
                </option>
              ))}
            </select>
            <span className="flex items-center gap-1">
              <div>Side </div>
              <strong>
                {table.getState().pagination.pageIndex + 1} af{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <ButtonGroup>
              <Button
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}>{'<<'}
              </Button>
              <Button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}>{'<'}
              </Button>
              <Button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}>
                {'>'}
              </Button>
              <Button
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}>
                {'>>'}
              </Button>
            </ButtonGroup>
          </div>
          <div>{table.getRowModel().rows.length} Rækker</div>
          <div className="h-2" />
        </div>
      </div>
      <SecuredComponent accountLevel={AccountLevel.Developer}>
        <ReactTableDevtools table={table} />
      </SecuredComponent>
    </div>
  );
}


const renderSubComponent = ({ row }: { row: Row<FormattedGetApprovalFlowsHistoryResponse> }) => {
  return (
    <pre style={{ fontSize: '10px' }}>
      <code>{JSON.stringify(row.original, null, 2)}</code>
    </pre>
  )
}

const dateFormatter = (time: dayjs.Dayjs) => {
  if (typeof time === 'string') {
    time = dayjs(time);
  }
  return (time.locale(localeDa).format('HH:mm, DD MMM YYYY'));
};

const getStatusColorApprovals = (state: number) => {
  if (state === 1002 || state === 2002) {
    return "green";
  } else if (state === 1003 || state === 2003) {
    return "red";
  } else {
    return "";
  }
};

const rowClassesApprovals = (row: FormattedGetApprovalFlowsHistoryResponse) => {
  switch (getStatusColorApprovals(row.approvalFlowState)) {
    case 'red': // Used in KD for signing that the process is not in use
      return 'table-danger';
    case 'green':
      return 'table-success';
    default:
      return '';
  }
};

const rowStyle = (tRow: Row<FormattedGetApprovalFlowsHistoryResponse>, data: FormattedGetApprovalFlowsHistoryResponse[] | undefined): React.CSSProperties => {
  let row = tRow.original;
  let group = data!.filter(val => val.organizationId === row.organizationId && val.fiscalYear === row.fiscalYear);
  let groupN = data!.filter(val => val.organizationId === row.organizationId && val.fiscalYear === row.fiscalYear && val.approvalFlowState !== 1001)
  let color = "#fff";
  let borderColor = "#dee2e6";
  if (group.length > 1) {
    if (group.length !== groupN.length) {
      color = "#fff";
      borderColor = "#dee2e6"
    }
    if (group.length === groupN.length) {
      let checkIfRejected = data?.filter(val => val.organizationId === row.organizationId && val.fiscalYear === row.fiscalYear && (val.approvalFlowState === 1003 || val.approvalFlowState === 2003))
      if (checkIfRejected !== undefined && checkIfRejected.length > 0) {
        color = "#f8cdc8"
        borderColor = "#f3a29a"
      } else {
        color = "#bae6cf"
        borderColor = "#80d1a6"
      }
    }
  }
  return { backgroundColor: color, borderColor: borderColor }
}