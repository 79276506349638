import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxFull } from '@fortawesome/pro-solid-svg-icons';
import TranslatableText from '../../../Language';
import { GetRegardingObjectTypeResponse } from '../../../apiClients';

export function NavMenuModulesDropdown(customEntitiesDropDownOpen: boolean, setCustomEntitiesDropDownOpen: React.Dispatch<React.SetStateAction<boolean>>, regardingObjectTypes: GetRegardingObjectTypeResponse[], navigateTo: (view: string) => void) {
    return <NavItem>
        <Dropdown
            isOpen={customEntitiesDropDownOpen}
            toggle={() => setCustomEntitiesDropDownOpen(!customEntitiesDropDownOpen)}
        >
            <DropdownToggle caret>
                <FontAwesomeIcon icon={faBoxFull} />&nbsp;
                <TranslatableText translationKey="custom_entities" />
            </DropdownToggle>
            <DropdownMenu right>
                {regardingObjectTypes.map((item) => {
                    return (
                        <DropdownItem
                            key={item.id}
                            onClick={() => navigateTo(`/entity/${item.id}`)}
                        >
                            {item.pluralName}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    </NavItem>;
}
