import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport, Search
} from "react-bootstrap-table2-toolkit";
import MultiSlider, { Dot, Progress } from "react-multi-bar-slider";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { InlineLoading, ScreenLoading } from "../../components";
import Accent from "../../components/accent/Accent";
import ProgressWidget from "../../components/progress/ProgressWidget";
import { GlobalContext } from "../../contexts/global-context";
import TranslatableText from "../../Language/TranslatableText";
import {
  cancelApprovalAPI, getApprovalFlowsProgressV1API, getApprovalsHistoryAPI, readyApprovalAPI
} from "../company-admin/views/approvals-flow/approvalsFlowAPI";
import { getTaskByIdAPI, updateTaskStatusAPI } from "./tasksAPI";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class TaskDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskDetails: {
        approvers: [],
        seriesId: "",
        status: "",
        actualDueDate: "",
        actualEndDate: "",
        name: "",
        dueTime: "",
        endDate: "",
        dueDate: "",
        dayOfWeek: 0,
        dayOfMonth: 0,
        monthOfYear: 0,
        description: "",
        regardingObjectId: "",
        regardingObjectTypeId: "",
        recurrenceTypeId: "",
        statusCode: -1
      },
      customFields: [],
      approvers: [],
      postUserIds: [],
      actualDueDate: "",
      actualEndDate: "",
      name: "",
      dueTime: "",
      endDate: "",
      dueDate: "",
      dayOfWeek: 0,
      dayOfMonth: 0,
      monthOfYear: 0,
      description: "",
      regardingObjectId: "",
      regardingObjectTypeId: "",
      recurrenceTypeId: "",
      progress: 0,
      statusCode: -1,
      taskRegardingObjectTypeId: "",
      taskCompleteLoader: false,
      taskCancelLoader: false,
      inProgressLoader: false,
      dragedProgress: 0,
      approvalCancelLoader: false,
      approvalsHistory: [],
      columns: [
        {
          dataField: "userName",
          text: "User",
          sort: true
        },
        {
          dataField: "comments",
          text: "Comments",
          sort: true
        },
        {
          dataField: "updateTime",
          text: "Approved Time",
          sort: true
        },
        {
          dataField: "approvalFlowState",
          text: "Current Status",
          formatter: this.statusFormatter,
          sort: true
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.populateApprovalsList();
      getTaskByIdAPI(this.props.match.params.id)
        .then(res => {
          var selected = res.data.users.map(el => {
            var pair = { label: el.name, value: el.id };
            return pair;
          });
          this.setState({
            taskDetails: res.data,
            id: res.data.id,
            name: res.data.name === null ? "" : res.data.name,
            progression:
              res.data.progression === null ? "" : res.data.progression,
            dueTimeDatePicker: this.stringToTimestamp(res.data.dueTime),
            endDatePicker: this.dateFormatter(res.data.endDate),
            dueDatePicker: this.dateFormatter(res.data.actualDueDate),
            endDate: new Date(res.data.endDate).toISOString(),
            dueDate: new Date(res.data.actualDueDate).toISOString(),
            dueTime: res.data.dueTime,
            dayOfWeek: res.data.dayOfWeek === null ? "" : res.data.dayOfWeek,
            dayOfMonth: res.data.dayOfMonth === null ? "" : res.data.dayOfMonth,
            monthOfYear:
              res.data.monthOfYear === null ? "" : res.data.monthOfYear,
            description:
              res.data.description === null ? "" : res.data.description,
            regardingObjectId:
              res.data.regardingObjectId === null
                ? ""
                : res.data.regardingObjectId,
            regardingObjectTypeId:
              res.data.regardingObjectTypeId === null
                ? ""
                : res.data.regardingObjectTypeId,
            recurrenceTypeId: res.data.recurrenceTypeId,
            selectedOption: selected,
            postUserIds: selected.map(row => row.value),
            entityTypeName:
              res.data.entityTypeName === null ? "" : res.data.entityTypeName,
            entityName: res.data.entityName === null ? "" : res.data.entityName,
            statusCode: res.data.statusCode,
            updateSeries: res.data.updateSeries,
            taskRegardingObjectTypeId: res.data.taskRegardingObjectTypeId,
            progress:
              res.data.progressPercentage === undefined ||
                res.data.progressPercentage === null ||
                res.data.progressPercentage === ""
                ? 0
                : res.data.progressPercentage,
            customFields:
              res.data.customFieldValues === null
                ? []
                : res.data.customFieldValues,
            loading: false
          });
          if (
            res.data.progressPercentage === 100 &&
            res.data.statusCode === 1005
          ) {
            getApprovalFlowsProgressV1API(
              res.data.taskRegardingObjectTypeId,
              this.props.match.params.id
            )
              .then(res => {
                let list = res.approvalProgresses;
                list.sort(function (l, r) {
                  return l.approvalFlowOrder - r.approvalFlowOrder;
                });
                this.setState({ approvers: list });
              })
              .catch(error => {
                this.setState({ approvers: [] });
              });
          }
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
    }
  }

  statusFormatter = (cell, row) => {
    if (cell === 1001) {
      return "Open";
    } else if (cell === 1002) {
      return "Approved";
    } else if (cell === 1003) {
      return "Rejected";
    } else if (cell === 2002) {
      return "Approved by other";
    } else if (cell === 2003) {
      return "Rejected by other";
    } else if (cell === 2004) {
      return "Cancelled by other";
    } else {
      return "Cancelled";
    }
  };

  dateFormatter = timestamp => {
    return new Date(timestamp);
  };

  stringToTimestamp = time => {
    var d = new Date(), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
      dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
      timestamp;

    // ie: 2014-03-24, 3:00 PM
    timestamp = yyyy + "-" + mm + "-" + dd + " " + time;
    return new Date(timestamp);
  };

  populateApprovalsList = () => {
    this.setState({ tableLoader: true, approvalsHistory: [] });
    getApprovalsHistoryAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({
          approvalsHistory: res,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  usersFormatter = users => {
    return users.map((item, index) => (
      <span key={item.id}>
        {item.name} {index < users.length - 1 ? ",\u00A0" : ""}
      </span>
    ));
  };

  timeFormatter = (cell, row) => {
    var d = new Date(cell), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
      dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
      timestamp;

    // ie: 2014-03-24, 3:00 PM
    timestamp = yyyy + "-" + mm + "-" + dd;
    return <span>{timestamp}</span>;
  };

  updateTaskStatus = (status, progress) => {
    const data = {
      id: this.props.match.params.id,
      tasksStatus: status,
      progressPercentage: status === 1004 && progress === 0 ? 1 : progress
    };
    if (status === 1002) {
      this.setState({ taskCompleteLoader: true });
    } else if (status === 1003) {
      this.setState({ taskCancelLoader: true });
    } else if (status === 1004) {
      this.setState({ inProgressLoader: true });
    }
    updateTaskStatusAPI(data)
      .then(res => {
        if (status === 1002) {
          this.setState(
            {
              taskCompleteLoader: false,
              progress: 100,
              statusCode: status
            },
            () => {
              // this.goBack();
            }
          );
        } else if (status === 1003) {
          this.setState(
            {
              taskCancelLoader: false,
              progress: 0,
              statusCode: status
            },
            () => {
              // this.goBack();
            }
          );
        } else if (status === 1004) {
          this.setState(
            {
              inProgressLoader: false,
              statusCode: status,
              progress: this.state.progress === 0 ? 1 : this.state.progress
            },
            () => {
              // this.goBack();
            }
          );
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ taskCompleteLoader: false, taskCancelLoader: false });
      });
  };

  cancelTask = status => {
    const data = {
      id: this.props.match.params.id,
      tasksStatus: status,
      progressPercentage: this.state.progress === 100 ? 99 : this.state.progress
    };
    this.setState({ taskCancelLoader: true });
    Swal.fire({
      title: this.context.languages["swal.are_you_sure"],
      text: this.context.languages["swal.are_you_sure.confirm_task_cancel"],
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: this.context.languages["swal.are_you_sure.no"],
      confirmButtonText: this.context.languages["swal.are_you_sure.yes"]
    }).then(result => {
      if (result.isConfirmed) {
        updateTaskStatusAPI(data).then(res => {
          this.setState({
            taskCancelLoader: false,
            progress: this.state.progress === 100 ? 99 : this.state.progress,
            statusCode: status
          });
        });
        Swal.fire(
          this.context.languages["swal.cancelled"],
          this.context.languages["swal.cancelled_task_cancelled"],
          "success"
        );
      } else {
        this.setState({ taskCancelLoader: false });
      }
    });
  };

  completeTask = status => {
    const data = {
      id: this.props.match.params.id,
      tasksStatus: status,
      progressPercentage: 100
    };
    this.setState({ taskCompleteLoader: true });

    Swal.fire({
      title: this.context.languages["swal.are_you_sure"],
      text: "You want to complete this Task?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.context.languages["swal.are_you_sure.yes"],
      cancelButtonText: this.context.languages["swal.are_you_sure.no"]
    }).then(result => {
      if (result.value) {
        this.setState({ progress: 100 });
        let data = {
          task: {
            id:
              this.props.match.params.id !== undefined
                ? this.props.match.params.id
                : "",
            progressPercentage: this.state.progress,
            responsibleIds: this.state.postUserIds,
            dueTime: this.state.dueTime,
            actualDueDate: this.state.dueDate,
            description: this.state.description,
            progression: this.state.progression,
            updateSeries: this.state.updateSeries
          },
          regardingObjectTypeId: this.state.taskRegardingObjectTypeId,
          regardingObjectId: this.props.match.params.id
        };
        readyApprovalAPI(data).then(res => {
          getApprovalFlowsProgressV1API(
            this.state.taskRegardingObjectTypeId,
            this.props.match.params.id
          ).then(approvers => {
            if (approvers.approvalProgresses.length > 0) {
              let list = approvers.approvalProgresses;
              list.sort(function (l, r) {
                return l.approvalFlowOrder - r.approvalFlowOrder;
              });
              this.setState(
                {
                  approvers: list,
                  loading: false,
                  statusCode: 1005,
                  taskCompleteLoader: false
                },
                () => {
                  Swal.fire(
                    this.context.languages["swal.Success"],
                    this.context.languages["swal.success_waiting_approval"],
                    "success"
                  );
                }
              );
            } else {
              this.setState(
                {
                  taskCompleteLoader: false,
                  progress: 100,
                  statusCode: status
                },
                () => {
                  Swal.fire(
                    this.context.languages["swal.Success"],
                    this.context.languages["swal.success_task_completed"],
                    "success"
                  );
                }
              );
            }
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.setState({ taskCompleteLoader: false });
      }
    });
    // }
  };

  cancelApproval = e => {
    this.setState({ approvalCancelLoader: true }, () => {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.cancel_flow"],
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.context.languages["swal.are_you_sure.no"],
        confirmButtonText: this.context.languages["swal.are_you_sure.yes"]
      }).then(result => {
        if (result.isConfirmed) {
          let data = {
            regardingObjectTypeId: this.state.taskRegardingObjectTypeId,
            regardingObjectId: this.props.match.params.id
          };
          cancelApprovalAPI(data).then(res => {
            this.setState(
              {
                approvalCancelLoader: false,
                cancelled: true,
                statusCode: 1004,
                progress: this.state.progress === 100 ? 99 : this.state.progress
              },
              () => {
                Swal.fire(
                  this.context.languages["swal.cancelled"],
                  this.context.languages["swal.cancelled_approval_cancelled"],
                  "error"
                );
              }
            );
          });
        } else {
          this.setState({
            approvalCancelLoader: false
          });
        }
      });
    });
  };

  handleSlide = newProgress => {
    this.setState({ progress: newProgress });
  };

  onDragStart = progress => {
    this.setState({ dragedProgress: progress });
  };

  onDragStop = progress => {
    if (progress === 100) {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.complete_task"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.context.languages["swal.are_you_sure.yes"],
        cancelButtonText: this.context.languages["swal.are_you_sure.no"]
      }).then(result => {
        if (result.value) {
          let data = {
            task: {
              id:
                this.props.match.params.id !== undefined
                  ? this.props.match.params.id
                  : "",
              progressPercentage: this.state.progress,
              responsibleIds: this.state.postUserIds,
              dueTime: this.state.dueTime,
              actualDueDate: this.state.dueDate,
              description: this.state.description,
              progression: this.state.progression,
              updateSeries: this.state.updateSeries
            },
            regardingObjectTypeId: this.state.taskRegardingObjectTypeId,
            regardingObjectId: this.props.match.params.id
          };
          this.setState({ loading: true }, () => {
            readyApprovalAPI(data).then(res => {
              getApprovalFlowsProgressV1API(
                this.state.taskRegardingObjectTypeId,
                this.props.match.params.id
              ).then(approvers => {
                if (approvers.approvalProgresses.length > 0) {
                  let list = approvers.approvalProgresses;
                  list.sort(function (l, r) {
                    return l.approvalFlowOrder - r.approvalFlowOrder;
                  });
                  this.setState(
                    {
                      approvers: list,
                      loading: false,
                      statusCode: 1005
                    },
                    () => {
                      Swal.fire(
                        this.context.languages["swal.Success"],
                        this.context.languages["swal.success_waiting_approval"],
                        "success"
                      );
                    }
                  );
                }
              });
            });
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState({ progress: this.state.dragedProgress });
          // Swal.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )
        }
      });
    } else if (
      progress < 100 &&
      this.state.statusCode !== 1004 &&
      this.state.statusCode !== 1002 &&
      this.state.statusCode !== 1003 &&
      this.state.statusCode !== 1005
    ) {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.task_status_progress"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.context.languages["swal.are_you_sure.yes"],
        cancelButtonText: this.context.languages["swal.are_you_sure.no"]
      }).then(result => {
        if (result.value) {
          // Maybe call the update task API here
          this.updateTaskStatus(1004, progress);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState({ progress: this.state.dragedProgress });
        }
      });
    }
  };

  arrayFormatter = fields => {
    if (fields !== null && fields !== "" && Array.isArray(fields)) {
      return fields.map((item, index) => (
        <span key={index}>
          {item.label} {index < fields.length - 1 ? ",\u00A0" : ""}
        </span>
      ));
    }
  };

  renderTableData = customField => {
    if (customField.displayTypeCode === 1007)
      if (customField.tagBoxValues.length > 0) {
        return customField.tagBoxValues.map((field, index) => (
          <tr key={index}>
            <td>{field.label}</td>
            <td>{field.description}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan="2">
              <TranslatableText translationKey="no_data_available" />
            </td>
          </tr>
        );
      }
  };
  renderTableHeader = () => {
    return (
      <tr className="thead">
        <th style={{ width: '30%' }}>
          <TranslatableText translationKey="name" />
        </th>
        <th style={{ width: '70%' }}>
          <TranslatableText translationKey="description" />
        </th>
      </tr>
    );
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let {
      taskDetails,
      columns,
      approvalsHistory,
      tableLoader,
      loading
    } = this.state;
    return (
      <div className="notifications-details-view">
        {loading && (
          <ScreenLoading />
        )}
        {this.state.statusCode === 1005 ? (
          <ProgressWidget approvers={this.state.approvers} />
        ) : (
          <></>
        )}
        <Row>
          <Col md={12}>
            <h3 className="progress-header">
              Task Progress {this.state.progress}%
            </h3>
          </Col>
          <Col md={12}>
            <MultiSlider
              readOnly={
                this.state.statusCode === 1005 ||
                  this.state.statusCode === 1002 ||
                  this.state.statusCode === 1003
                  ? true
                  : false
              }
              classnName="task-progress"
              width={"100%"}
              height={20}
              backgroundColor="#ccc"
              equalColor="#42a5f5"
              style={{ marginBottom: 40 }}
              slidableZoneSize={40}
              onSlide={this.handleSlide}
              onDragStart={progress => this.onDragStart(progress)}
              onDragStop={progress => this.onDragStop(progress)}
              roundedCorners
            >
              <Progress
                height={20}
                color="blue"
                progress={this.state.progress}
                style={{ overflow: "unset" }}
              >
                <Dot width={35} height={35} color="#1976d2" />
              </Progress>
            </MultiSlider>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row className="mt-10">
              <Col md={12}>
                <div className="flex space-between">
                  <h3>
                    <TranslatableText translationKey="tasks.tasksDetails" />
                  </h3>
                  {this.props.match.params.id !== undefined ? (
                    <>
                      <div>
                        {!this.state.inProgressLoader &&
                          this.state.statusCode !== 1005 ? (
                          <Button
                            className={
                              this.state.statusCode === 1004 ? "orange-bg" : ""
                            }
                            outline
                            onClick={e =>
                              this.updateTaskStatus(1004, this.state.progress)
                            }
                            disabled={
                              this.state.statusCode !== 1001 ? true : null
                            }
                          >
                            <FontAwesomeIcon
                              icon={faClock}
                              size="1x"
                              className="cursor"
                            />
                            <TranslatableText translationKey="tasks.inProgress" />
                          </Button>
                        ) : this.state.statusCode === 1005 &&
                          !this.state.inProgressLoader ? (
                          <></>
                        ) : (
                          <div className="inline-block">
                            <InlineLoading />
                          </div>
                        )}{" "}
                        {this.state.statusCode === 1005 ? (
                          <Button className="orange-bg" outline disabled={true}>
                            <FontAwesomeIcon
                              icon={faClock}
                              size="1x"
                              disabled={true}
                              className="cursor"
                            />
                            <TranslatableText translationKey="tasks.waitingForApproval" />
                          </Button>
                        ) : (
                          <></>
                        )}{" "}
                        {!this.state.taskCompleteLoader &&
                          this.state.statusCode !== 1005 ? (
                          <Button
                            className={
                              this.state.statusCode === 1002 ? "green-bg" : ""
                            }
                            outline
                            onClick={e => this.completeTask(1002, e)}
                            disabled={
                              this.state.statusCode !== 1001 &&
                                this.state.statusCode !== 1004
                                ? true
                                : null
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="1x"
                              className="cursor"
                            />
                            {this.state.statusCode === 1002
                              ? "Completed"
                              : "Mark Complete"}
                          </Button>
                        ) : !this.state.taskCompleteLoader &&
                          this.state.statusCode === 1005 ? (
                          <></>
                        ) : (
                          <div className="inline-block">
                            <InlineLoading />
                          </div>
                        )}{" "}
                        {!this.state.taskCancelLoader &&
                          this.state.statusCode !== 1005 ? (
                          <Button
                            className={
                              this.state.statusCode === 1003 ? "red-bg" : ""
                            }
                            outline
                            onClick={e => this.cancelTask(1003, e)}
                            disabled={
                              this.state.statusCode !== 1001 &&
                                this.state.statusCode !== 1004
                                ? true
                                : null
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              size="1x"
                              className="cursor"
                            />
                            {this.state.statusCode === 1003
                              ? "Cancelled"
                              : "Mark Cancel"}
                          </Button>
                        ) : !this.state.taskCancelLoader &&
                          this.state.statusCode === 1005 ? (
                          <></>
                        ) : (
                          <div className="inline-block">
                            <InlineLoading />
                          </div>
                        )}
                        {this.state.statusCode === 1005 ? (
                          <span className="separator"></span>
                        ) : (
                          <></>
                        )}
                        {!this.state.approvalCancelLoader &&
                          this.state.statusCode === 1005 ? (
                          <Button outline onClick={e => this.cancelApproval(e)}>
                            <FontAwesomeIcon
                              icon={faTimes}
                              size="1x"
                              className="cursor"
                            />
                            <TranslatableText translationKey="tasks.inProgress" />
                          </Button>
                        ) : !this.state.approvalCancelLoader &&
                          this.state.statusCode !== 1005 ? (
                          <></>
                        ) : (
                          <div className="inline-block">
                            <InlineLoading />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={12}>
            {this.state.taskDetails.name !== "" ? (
              <div>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td className="bold">
                        <TranslatableText
                          translationKey="name
                      "
                        />
                      </td>
                      <td>{taskDetails.name}</td>
                    </tr>
                    <tr>
                      <td className="bold">
                        <TranslatableText translationKey="tasks.status" />
                      </td>
                      <td>{taskDetails.status}</td>
                    </tr>
                    <tr>
                      <td className="bold">
                        <TranslatableText translationKey="tasks.entity" />
                      </td>
                      <td>{taskDetails.entityTypeName}</td>
                    </tr>
                    <tr>
                      <td className="bold">
                        <TranslatableText translationKey="tasks.entityName" />
                      </td>
                      <td>{taskDetails.entityName}</td>
                    </tr>
                    <tr>
                      <td className="bold">
                        <TranslatableText translationKey="tasks.dueDate" />
                      </td>
                      <td>{this.timeFormatter(taskDetails.actualDueDate)}</td>
                    </tr>
                    <tr>
                      <td className="bold">
                        <TranslatableText translationKey="tasks.dueTime" />
                      </td>
                      <td>{taskDetails.dueTime}</td>
                    </tr>
                    <tr>
                      <td className="bold">
                        <TranslatableText translationKey="tasks.recurrenceType" />
                      </td>
                      <td>{taskDetails.recurrenceType}</td>
                    </tr>
                    <tr>
                      <td className="bold">
                        <TranslatableText translationKey="tasks.progression" />
                      </td>
                      <td>{taskDetails.progression}</td>
                    </tr>
                    <tr>
                      <td className="bold">
                        <TranslatableText translationKey="tasks.responsable" />
                      </td>
                      <td>{this.usersFormatter(taskDetails.users)}</td>
                    </tr>
                    {this.state.customFields
                      .filter(item => {
                        return item.displayTypeCode !== 1007;
                      })
                      .map((element, key) => (
                        <tr key={key}>
                          <td className="bold">{element.displayText}</td>
                          <td>
                            {element.value === true
                              ? "Yes"
                              : element.value === false
                                ? "No"
                                : element.value}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <InlineLoading />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="center-align" md={12}>
            <Accent useHtmlIcon={false}>
              <TranslatableText translationKey="tasks.approvalHistory" />
            </Accent>
          </Col>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={approvalsHistory}
                columns={columns}
                search
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                      pagination={paginationFactory()}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="custom-fields-section">
              {this.state.customFields.map((customField, index) =>
                customField.displayTypeCode === 1007 ? (
                  <div key={index}>
                    <Accent useHtmlIcon={false}>
                      {customField.displayText}
                    </Accent>
                    <table className="table table-bordered ">
                      <thead>{this.renderTableHeader()}</thead>
                      <tbody>{this.renderTableData(customField)}</tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(TaskDetails);
TaskDetails.contextType = GlobalContext;