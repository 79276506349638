import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import {
  getAnswerOptionSetByIdAPI,
  getAllOptionsForAnswerOptionSetByIdAPI,
  deleteAnswerOptionSetValuesByIdAPI
} from "./answeroptionsetAPI";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faGlobe,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import Accent from "../../../../../../components/accent/Accent";

import { toast } from "react-toastify";
import TranslatableText from "../../../../../../Language/TranslatableText";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { InlineLoading } from "../../../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class AnswerOptionSetDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      deleteLoader: false,
      name: "",
      answerType: "",
      displayType: "",
      deleteModal: false,
      isDisabled: false,
      answerOptionSetValueId: "",
      answeroptionsetValues: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          classes: "recordID"
        },
        {
          dataField: "value",
          text: "Name",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getAnswerOptionSetByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.name,
            answerType: res.data.answerType,
            displayType: res.data.displayType
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
      this.populateAnswerOptionSetValuesList();
    }
  }

  populateAnswerOptionSetValuesList = () => {
    var self = this;
    this.setState({ tableLoader: true, answeroptionsetValues: [] });
    getAllOptionsForAnswerOptionSetByIdAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        if (res.length >= 2) {
          this.setState({ isDisabled: true });
        } else {
          this.setState({ isDisabled: false });
        }
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink
                tag={Link}
                to={`/admin/wizard/answeroptionset/details/translations/${el.id}`}
              >
                <Tippy placement="top" content="Add Translations">
                  <span>
                    <FontAwesomeIcon icon={faGlobe} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink
                tag={Link}
                to={`/admin/wizard/answeroptionset/details/update/${self.props.match.params.id}/${el.id}`}
              >
                <Tippy placement="top" content="Update Answer Option Set Value">
                  <span>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink onClick={e => self.openDeleteModal(el.id, e)}>
                <Tippy placement="top" content="Remove">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
          return o;
        });

        this.setState({
          answeroptionsetValues: data,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            answerOptionSetValueId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      answerOptionSetValueId: id
    });
  };

  deleteAnswerOptionSetValue = id => {
    this.setState({ deleteLoader: true });
    deleteAnswerOptionSetValuesByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populateAnswerOptionSetValuesList();
        this.setState({
          deleteModal: false,
          answerOptionSetValueId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          answerOptionSetValueId: null,
          deleteLoader: false
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { tableLoader } = this.state;
    return (
      <div className="user-details-view">
        <Row>
          <Col>
            <Accent useIcon={false}>Details</Accent>
          </Col>
          <Col md={12}>
            {this.state.name !== "" ? (
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="name" />
                    </td>
                    <td>{this.state.name}</td>
                  </tr>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="answeroptionset.answer_type" />
                    </td>
                    <td>{this.state.answerType}</td>
                  </tr>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="custom_fields.display_type" />
                    </td>
                    <td>{this.state.displayType}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="center-align">
                <InlineLoading />
              </div>
            )}
          </Col>
        </Row>
        {this.state.answerType !== "Single Line Text" ? (
          <Row>
            <Col>
              <Accent useIcon={false}>
                <TranslatableText translationKey="answeroptionset.answer_option_sets_values" />
              </Accent>
            </Col>
            <Col md={12}>
              <div className="table-container">
                <ToolkitProvider
                  keyField="id"
                  data={this.state.answeroptionsetValues}
                  columns={this.state.columns}
                  search
                  exportCSV={{
                    fileName: "data.csv",
                    separator: this.context.user.language === 'dk' ? ';' : ',',
                    ignoreHeader: false,
                    noAutoBOM: false
                  }}
                >
                  {props => (
                    <div>
                      <div className="flex space-between">
                        <div>
                          <SearchBar {...props.searchProps} />
                        </div>
                        <div>
                          <div className="flex space-between">
                            {this.context.userRoles.find(r =>
                              r.includes("Developer")
                            ) !== undefined ? (
                              <ExportCSVButton
                                className="export-btn"
                                {...props.csvProps}
                              >
                                <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                            ) : (
                              <></>
                            )}
                            <div>
                              {this.state.isDisabled &&
                              this.state.answerType === "Two Option" ? (
                                ""
                              ) : (
                                <NavLink
                                  className="button-primary"
                                  tag={Link}
                                  to={`/admin/wizard/answeroptionset/details/add/${this.props.match.params.id}`}
                                >
                                  <TranslatableText translationKey="add" />
                                </NavLink>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <BootstrapTable
                        noDataIndication={() =>
                          tableLoader ? (
                            <InlineLoading />
                          ) : (
                            <NoDataIndication />
                          )
                        }
                        {...props.baseProps}
                        pagination={paginationFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>

        <div className="modals">
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              <TranslatableText translationKey="confirm_remove" />
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="answeroptionset.confirm_remove_entity_description" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e =>
                    this.deleteAnswerOptionSetValue(
                      this.state.answerOptionSetValueId,
                      e
                    )
                  }
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(AnswerOptionSetDetails);
AnswerOptionSetDetails.contextType = GlobalContext;