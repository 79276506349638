import React, { Component } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHatWizard, faInfoCircle,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { LanguageContext } from "../../../../contexts/language-context";
import TranslatableText from "../../../../Language/TranslatableText";
import Accent from "../../../../components/accent/Accent";
import { getWizardsAPI } from "./companyWizardAPI";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";



const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class CompanyWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      wizards: [],
      wizardId: null,
      deleteModal: false,
      deleteLoader: false,
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name", //res[0].systems.map(row => row.name)
          text: "name",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ]
    };
  }

  componentDidMount() {
    this.populateWizardList();
  }

  populateWizardList = () => {
    var self = this;
    this.setState({
      tableLoader: true,
      wizards: []
    });
    getWizardsAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink
                tag={Link}
                to={`/company-admin/wizard/details/${el.id}/${self.props.match.params.organizationId}`}
              >
                <Tippy placement="top" content="View Details">
                  <span>
                    <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
          return o;
        });
        this.setState({
          wizards: data,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  headerFormatter = column => {
    return <TranslatableText translationKey={column.text} />;
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let { tableLoader } = this.state;
    return (
      <div className="admin-partners-view">
        <Accent useIcon={true} icon={faHatWizard}>
          Wizard
        </Accent>
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={this.state.wizards}
            columns={this.state.columns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        {this.props.match.params.organizationId ===
                          undefined ? (
                          <NavLink
                            className="button-primary"
                            tag={Link}
                            to={`${this.props.match.path}/create`}
                          >
                            <TranslatableText translationKey="create" />
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>Back</Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              <TranslatableText translationKey="confirm_delete" />?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="confirm_delete_wizard" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deleteWizard(this.state.wizardId, e)}
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanyWizard);
CompanyWizard.contextType = GlobalContext;