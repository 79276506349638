import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Systems from "./Systems";
import CreateSystem from "./CreateSystem";

export default function SystemsRouter() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match!.path}/updateSystem/:id`} render={() => <CreateSystem />} />
      <Route path={`${match!.path}/create`} render={() => <CreateSystem />} />
      <Route path={`${match!.path}`} render={() => <Systems />} />
    </Switch>
  );
}
