import React, { Component } from "react";
import { Row, Col, Button, Input } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/pro-solid-svg-icons";
import { getEntitiesAPI, getOptionsByUrlAPI, updateFieldValue } from "./DataProtectionAPI";
import DataProtectionChart from "./DataProtectionChart";

import TranslatableText from "../../../Language/TranslatableText";
import { toast } from "react-toastify";
import "./dataProtection.scss";
import Tippy from "@tippyjs/react";
import { GlobalContext } from "../../../contexts/global-context";
import { ScreenLoading } from "../../../components";




class DataProtection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customEntity: {},
      entities: [],
      tableLoader: true,
      loading: true,
      deleteLoader: false,
      deleteModal: false,
      dataProtectionOptionSet: [],
      entityId: '5cec08cd-54f8-45f0-45ed-08d829f9d820'
    };
  }

  componentDidMount() {
    this.populateEntities();
    getOptionsByUrlAPI('3e2307e5-7d75-4a83-86f7-08d80de51132')
      .then(res => {
        this.setState({
          dataProtectionOptionSet: res,
          loading: false
        });
      })
      .catch(() => {
        toast.error(
          this.context.languages["api_error"],
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          }
        );
        this.setState({ tableLoader: false });
      });
  }

  populateEntities = () => {
    this.setState({ tableLoader: true, entities: [] });
    getEntitiesAPI(this.state.entityId)
      .then(res => {
        console.log(res);
        if (res !== undefined && Array.isArray(res)) {
          this.setState({ entities: res, tableLoader: false });
        } else {
          this.setState({ tableLoader: false });
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          toast.error(
            this.context.languages["insufficient_permission"],
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 2000
            }
          );
        } else {
          toast.error(
            this.context.languages["api_error"],
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 2000
            }
          );
        }
        this.setState({ tableLoader: false });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleChange = (event, field, entryId) => {
    if (entryId === undefined) {
      return;
    }
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const data = {
      customFieldId: field.id,
      regardingObjectId: entryId,
      regardingObjectTypeId: field.regardingObjectTypeId,
      value: value
    };
    updateFieldValue(data)
      .then(res => {
        toast.success(
          this.context.languages["setting_updated"],
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          }
        );
        field.value = value;
        this.setState({
          [name]: value
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          toast.error(
            this.context.languages["insufficient_permission"],
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 2000
            }
          );
        } else {
          toast.error(
            this.context.languages["api_error"],
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 2000
            }
          );
        }
        this.setState({ cuLoader: false });
      });
  };

  CisStatusToBtnColorString(state) {
    switch (state) {
      case 1: return "gray";
      case 1: return "danger";
      case 2: return "warning";
      case 3: return "yellow";
      case 4: return "green";
      case 5: return "success";
      default: return "green";
    }
  }

  render() {
    let { entities, tableLoader } = this.state;
    return (
      <div className="entities-view">
        {tableLoader && (
          <ScreenLoading />
        )}
        <h1><TranslatableText translationKey="dpo.dataProtection.Header" /></h1>
        {!tableLoader ? (
          <DataProtectionChart data={entities} loading={this.state.loading} languages={this.context.languages} />
        ) : (
          ""
        )}
        <form method="post" className="container-fluid" id="cis-editEx-form">

          {entities.map((item, index) => (
            <div key={index}>

              <div className="form-group row d-flex flex-nowrap align-content-end">
                <label className="col-form-label">
                  {item.name}&nbsp;
                  <Tippy placement="top" content={item.description}>
                    <span className="dpoa-moreinfobutton" data-ciscscid={item.id}><i className="fal fa-info-circle dpoa-help" role="button" data-ciscscid={item.id}></i></span>
                  </Tippy>
                </label>
                <div className="align-self-center ml-auto">
                  <div className="btn-group" data-toggle="buttons">
                    <Input
                      type="select"
                      name={item.id}
                      id={item.id}
                      value={item.customFieldValues[0].value === null ? undefined : item.customFieldValues[0].value}
                      onChange={e => this.handleChange(e, item.customFieldValues[0], item.id)}
                    >
                      {this.state.dataProtectionOptionSet.map((e) => {
                        return (
                          <option key={e.id} value={e.id}>
                            {e.label}
                          </option>
                        );
                      })}
                    </Input>
                    <div className="btn-group ml-1" role="group">
                      <Button tag={Link} to={`/entity/attachments/${item.id}`}>
                        <FontAwesomeIcon icon={faPaperclip} /> <TranslatableText translationKey="dpo.dataProtection.Administer_Policies" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
          }
        </form>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}><TranslatableText translationKey="back" /></Button>
          </Col>
        </Row>
      </div >
    );
  }
}


export default withRouter(DataProtection);
DataProtection.contextType = GlobalContext;
