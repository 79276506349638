import React from 'react';
import { Redirect, Route } from "react-router-dom";
import { AccountLevel } from "../config/Levels";
import { usePlatformUserData } from "../contexts";

type PrivateRouteProps = {
  component: React.ElementType;
  accountLevel: AccountLevel;
  path: string;
};

const _PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  accountLevel,
  path,
  ...rest
}) => {
  const { currentOrgData } = usePlatformUserData();
  if (!Component) {
    return <Redirect to={{ pathname: "/" }} />;
  }
  return (
    <Route
      path={path}
      {...rest}
      render={props => 
        // check if route is restricted by role
        currentOrgData.accountLevel >= accountLevel ? (
        // authorised so return component
          <Component {...props} /> 
          ) : (
          // role not authorised so redirect to home page
           <Redirect to={{ pathname: "/" }} />
           )} />
  );
}

export const PrivateRoute = _PrivateRoute;