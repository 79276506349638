export const AccountLevels = {
  developer: "Developer",
  platformOwner: "Platform Owner",
  partner: "Partner",
  owner: "Owner",
  user: "User",
  person: "Person"
};

export const AccessLevels = {
  developer: 1025,
  platformOwner: 1020,
  partner: 1015,
  owner: 1010,
  user: 1005,
  person: 1000
};

export enum AccountLevel {
  Person = 1000,
  User = 1005,
  Owner = 1010,
  Partner = 1015,
  PlatformOwner = 1020,
  Developer = 1025,
}

export enum ClaimActionType {
  Write = 1001,
  Read = 1002,
  NoAccess = 9999
}

export enum ClaimScope {
  Organization = 1001,
  Section = 1002,
}

export const Actions = {
  write: 1001,
  read: 1002,
  noAccess: 9999,
  onUserRequest: 1007
};

export const ActionsString = {
  write: "write",
  read: "read",
  noAccess: "noAccess"
};
