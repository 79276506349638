import { Route, Switch, useRouteMatch } from "react-router-dom";
import DataProtection from "../views/dataProtection/DataProtection";
import RegnskabsElementer from "../views/regnskabsElementer/RegnskabsElementer";
import RiskAssesment from "../views/riskAssesment/RiskAssesment";

export default function PlatformRouter() {
  let { path } = useRouteMatch();

  return (
    <div className="parent-platform-view">
      <Switch>
        <Route path={`${path}/riskAssesment`}>
          <RiskAssesment />
        </Route>
        <Route path={`${path}/dataProtection`}>
          <DataProtection />
        </Route>
        <Route path={`${path}/regnskabsElementer`}>
          <RegnskabsElementer />
        </Route>
      </Switch>
    </div>
  );
}
