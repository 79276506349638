import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import TranslatableText from "../../../../Language/TranslatableText";

let UserInformation = ({ componentState, handleChange, validation }) => {
  return (
    <Row>
      <Col md={12}>
        <hr />
      </Col>
      <Col md={12}>
        <h4>
          <TranslatableText translationKey="company.first_user" />
        </h4>
        <p>
          <TranslatableText translationKey="company.first_user_description" />
        </p>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="username">
            <TranslatableText translationKey="name" />
          </Label>
          <Input
            type="text"
            name="username"
            id="username"
            className={
              componentState.useremail !== "" && componentState.username === ""
                ? "has-error"
                : ""
            }
            placeholder="Name"
            value={componentState.username}
            onChange={e => handleChange(null, e)}
          />
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="useremail">
            <TranslatableText translationKey="email" />
          </Label>
          <Input
            type="email"
            name="useremail"
            id="useremail"
            className={
              componentState.username !== "" && componentState.useremail === ""
                ? "has-error"
                : ""
            }
            placeholder="User Email"
            value={componentState.useremail}
            onChange={e => handleChange(null, e)}
          />
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="userPhone">
            <TranslatableText translationKey="phone" />
          </Label>
          <Input
            type="tel"
            name="userPhone"
            id="userPhone"
            placeholder="Telephone Number"
            value={componentState.userPhone}
            onChange={e => handleChange(null, e)}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default React.memo(UserInformation);
