import React from "react";
import { useParams } from "react-router-dom";
import ProcessDetails from "../../../../components/process-details";

type CompanyProcessDetailsParams = {
  id: string
}

const CompanyProcessDetails: React.FC = () => {
  const { id } = useParams<CompanyProcessDetailsParams>();

  return (
    <ProcessDetails
      paramsId={id}
    />
  )
}

export default React.memo(CompanyProcessDetails);