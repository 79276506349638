import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Page from "./Page";
import CreatePage from "./CreatePage";
import PageDetails from "./PageDetails";
import { withRouter } from "react-router-dom";
import AddPageTranslations from "./AddPageTranslations";
import PageTranslations from "./PageTranslations";

class PageRouter extends Component {
  render() {
    return (
      <div className="admin-wizard-page-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/create/:wizardId`}
            render={props => (
              <CreatePage {...props} language={this.context.language} />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/create`}
            render={props => (
              <CreatePage {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <PageDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreatePage {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/add/:pageId`}
            render={props => (
              <AddPageTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/update/:pageId/:translationId`}
            render={props => (
              <AddPageTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/:id`}
            render={props => (
              <PageTranslations {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/admin/wizard/page"
            render={props => (
              <Page {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(PageRouter);