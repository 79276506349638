import React, { Component } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faInfoCircle,
  faUsersClass
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { LanguageContext } from "../../../../contexts/language-context";
import TranslatableText from "../../../../Language/TranslatableText";
import Accent from "../../../../components/accent/Accent";
import { getTeamsAPI, deleteTeamByIdAPI } from "./teamsAPI";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";



const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class Teams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      teams: [],
      roleId: null,
      deleteModal: false,
      deleteLoader: false,
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "name",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "description",
          text: "description",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ]
    };
  }

  componentDidMount() {
    this.populateTeamList();
  }

  populateTeamList = () => {
    var self = this;
    this.setState({
      tableLoader: true,
      teams: []
    });
    getTeamsAPI(this.context.user.organizations.organizationId)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              {self.props.match.params.organizationId === undefined ? (
                <NavLink
                  tag={Link}
                  to={`${self.props.match.path}/details/${el.id}`}
                >
                  <Tippy placement="top" content="View Details">
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
              ) : (
                <NavLink
                  tag={Link}
                  to={`/company-admin/wizard/details/${el.id}/${self.props.match.params.organizationId}`}
                >
                  <Tippy placement="top" content="View Details">
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
              )}

              {self.props.match.params.organizationId === undefined ? (
                <NavLink
                  tag={Link}
                  to={`${self.props.match.path}/update/${el.id}`}
                >
                  <Tippy placement="top" content="Edit">
                    <span>
                      <FontAwesomeIcon icon={faEdit} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
              ) : (
                ""
              )}

              {self.props.match.params.organizationId === undefined ? (
                <NavLink onClick={e => self.openDeleteModal(el.id, e)}>
                  <Tippy placement="top" content="Remove">
                    <span>
                      <FontAwesomeIcon
                        style={{ color: "#d84a4a" }}
                        icon={faTrash}
                        size="1x"
                      />
                    </span>
                  </Tippy>
                </NavLink>
              ) : (
                ""
              )}
            </div>
          );
          return o;
        });
        this.setState({
          teams: data,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            roleId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      roleId: id
    });
  };

  deleteWizard = id => {
    this.setState({ deleteLoader: true });
    deleteTeamByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populateTeamList();
        this.setState({
          deleteModal: false,
          roleId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          roleId: null,
          deleteLoader: false
        });
      });
  };

  headerFormatter = column => {
    return <TranslatableText translationKey={column.text} />;
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let { tableLoader } = this.state;
    return (
      <div className="admin-partners-view">
        <Accent useIcon={true} icon={faUsersClass}>
          <TranslatableText translationKey="teams" />
        </Accent>
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={this.state.teams}
            columns={this.state.columns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          CSV
                        </ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        {this.props.match.params.organizationId ===
                        undefined ? (
                          <NavLink
                            className="button-primary"
                            tag={Link}
                            to={`${this.props.match.path}/create`}
                          >
                            <TranslatableText translationKey="create" />
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              Confirm Delete?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="teams.confirm_delete_team_description" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deleteWizard(this.state.roleId, e)}
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(Teams);
Teams.contextType = GlobalContext;