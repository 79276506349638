import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

export class AuthorizeService {

    client: Auth0Client | undefined;

    async getAccessToken() {
        if (this.client === undefined){
            this.client = await createAuth0Client({
                domain: "legaltech.eu.auth0.com",
                client_id: "EuFVIMtB0M8DN0rT91ojaqhu8Dzi3iwS",
                redirect_uri: window.location.origin,
                audience: "https://api.360-advisor.com/",
                scope: "read:current_user 360:demo",
                useRefreshTokens: true,
                cacheLocation: "localstorage"
            })
        }
        return this.client.getTokenSilently();
    }
}

const authService = new AuthorizeService();

export default authService;