import { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PartnerCompaniesRouter from "./views/companies/PartnerCompaniesRouter";
import PartnerUserRouter from "./views/users/PartnerUserRouter";
import AddProject from "../admin/views/projects/AddProject";
import CreateWizardTemplate from "../admin/views/wizard/CreateWizardTemplate";
import AddTenantCustomEntityTranslation from "../company-admin/views/custom-entities/AddTenantCustomEntityTranslation";
import CreateTenantCustomEntity from "../company-admin/views/custom-entities/CreateTenantCustomEntity";
import TenantCustomEntityDetails from "../company-admin/views/custom-entities/TenantCustomEntityDetails";
import AddCFTranslation from "../company-admin/views/custom-fields/AddCFTranslation";
import CFDetails from "../company-admin/views/custom-fields/CFDetails";
import CreateCF from "../company-admin/views/custom-fields/CreateCF";
import CreateTeam from "../company-admin/views/teams/CreateTeam";
import TeamDetails from "../company-admin/views/teams/TeamDetails";
import PartnerPage from "./PartnerPage";
import ProjectDetails from "./views/projects/ProjectDetails";
import CreatePartnerUser from "./views/users/CreatePartnerUser";
import { KdNewYearPage } from "./views/kdAddnewYear";
import ProjectStatus from "./views/kdProjectStatus";
import PartnerApprovals from "./views/kdApprovals";

export default function PartnerRouter() {
  let { path, url } = useRouteMatch();
  // useEffect(() => {
  //   console.debug(match)
  // }, [match?.params]);
  return (
    <div className="parent-partner-view">
      <div className="DisplayCompany d-sm-block d-none">
        <div>Partner</div>
      </div>
      <Switch>
        <Route
          path={`${path}/company/teams/details/:id/:organizationId`}
          render={props => (
            <TeamDetails {...props} />
          )}
        />
        <Route
          path={`${path}/company/teams/update/:id/:organizationId`}
          render={props => (
            <CreateTeam {...props} />
          )}
        />
        <Route
          path={`${path}/company/teams/create/:organizationId`}
          render={props => (
            <CreateTeam {...props} />
          )}
        />
        <Route
          path={`${path}/company/user/create/:organizationId`}
          render={props => (
            <CreatePartnerUser {...props} />
          )}
        />
        <Route
          path={`${path}/company/project/details/:projectId/:companyId`}
          render={() => <ProjectDetails />}
        />
        <Route
          path={`${path}/company/project/add/:organizationId/:partnerId`}
          render={props => (
            <AddProject {...props} />
          )}
        />
        <Route path={`${path}/company/project/newYear/:companyId/:partnerId`}>
          <KdNewYearPage />
        </Route>
        <Route
          path={`${path}/company/project/add/:organizationId`}
          render={props => (
            <AddProject {...props} />
          )}
        />
        <Route
          path={`${path}/company/project/update/:id/:organizationId`}
          render={props => (
            <AddProject {...props} />
          )}
        />
        <Route
          path={`${path}/company/project/module/details/:id/:organizationId`}
          render={props => (
            <TenantCustomEntityDetails {...props} />
          )}
        />
        <Route
          path={`${path}/company/project/module/update/:id`}
          render={props => (
            <CreateTenantCustomEntity {...props} />
          )}
        />
        <Route
          path={`${path}/company/project/module/create/:organizationId`}
          render={props => (
            <CreateTenantCustomEntity {...props} />
          )}
        />
        <Route
          path={`${path}/company/project/module/translation/add/:id`}
          render={props => (
            <AddTenantCustomEntityTranslation {...props} />
          )}
        />
        <Route
          path={`${path}/company/project/module/translation/update/:id/:translationId`}
          render={props => (
            <AddTenantCustomEntityTranslation {...props} />
          )}
        />

        <Route
          path={`${path}/company/project/template/update/:organizationId/:id`}
          render={props => (
            <CreateWizardTemplate {...props} />
          )}
        />
        <Route
          path={`${path}/company/project/template/create/:organizationId`}
          render={props => (
            <CreateWizardTemplate {...props} />
          )}
        />

        <Route
          path={`${path}/company/custom-field/update/:id/:regardingObjectId/:organizationId`}
          render={props => (
            <CreateCF {...props} />
          )}
        />
        <Route
          path={`${path}/company/custom-field/create/:regardingObjectId/:organizationId`}
          render={props => (
            <CreateCF {...props} />
          )}
        />
        <Route
          path={`${path}/company/custom-field/details/:id/:regardingObjectId/:organizationId`}
          render={props => (
            <CFDetails {...props} />
          )}
        />
        <Route
          path={`${path}/company/custom-field/translation/add/:id`}
          render={props => <AddCFTranslation {...props} />}
        />
        <Route
          path={`${path}/company/custom-field/translation/update/:id/:translationId`}
          render={props => <AddCFTranslation {...props} />}
        />
        <Route
          path={`${path}/user/:partnerId`}
          render={props => <PartnerUserRouter {...props} />}
        />
        <Route
          path={`${path}/company`}
          children={<PartnerCompaniesRouter />}
        />
        <Route
          path={`${path}/:partnerId/projectStatus`}
          children={<ProjectStatus />}
        />
        <Route
          path={`${path}/:partnerId/approvals`}
          children={<PartnerApprovals />}
        />
        <Route exact path={`${path}/:partnerId`}>
          <PartnerPage />
        </Route>
        <Route exact path={path}>
          <h3>Please select a Partner.</h3>
        </Route>
      </Switch>
    </div>
  );
}
