import React, { Component } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { getPersonsAPI, deletePersonByIdAPI } from "./personsAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faInfoCircle,
  faTrash,
  faUserFriends,
  faProjectDiagram,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { LanguageContext } from "../../../../contexts/language-context";
import TranslatableText from "../../../../Language/TranslatableText";
import Accent from "../../../../components/accent/Accent";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>No data available</span>
  </div>
);

class Persons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      deleteLoader: false,
      users: [],
      userId: null,
      deleteModal: false,
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "firstName",
          text: "persons.firstName",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "lastName",
          text: "persons.lastName",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "phone",
          text: "phone",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "email",
          text: "email",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "address",
          text: "address",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "responsibleName",
          text: "responsible",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "actions",
          text: "actions",
          csvExport: false,
          headerFormatter: this.headerFormatter,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/company-admin/flow-engine/${row.regardingObjectTypeId}/${row.id}`}
                >
                  <FontAwesomeIcon icon={faProjectDiagram} size="1x" />
                </NavLink>
                <NavLink
                  tag={Link}
                  to={`${this.props.match.path}/details/${row.id}`}
                >
                  <Tippy
                    placement="top"
                    content={<TranslatableText translationKey="details" />}
                  >
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink
                  tag={Link}
                  to={`${this.props.match.path}/update/${row.id}`}
                >
                  <Tippy
                    placement="top"
                    content={<TranslatableText translationKey="edit" />}
                  >
                    <span>
                      <FontAwesomeIcon icon={faEdit} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                  <Tippy
                    placement="top"
                    content={<TranslatableText translationKey="remove" />}
                  >
                    <span>
                      <FontAwesomeIcon
                        style={{ color: "#d84a4a" }}
                        icon={faTrash}
                        size="1x"
                      />
                    </span>
                  </Tippy>
                </NavLink>
              </div>
            );
          }
        }
      ],
      language: "en"
    };
  }

  componentDidMount() {
    this.populateUsersList();
  }
  populateUsersList = () => {
    var self = this;
    this.setState({ tableLoader: true, users: [] });
    getPersonsAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }

        this.setState({
          users: res,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            userId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      userId: id
    });
  };

  deleteUser = id => {
    this.setState({ deleteLoader: true });
    deletePersonByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populateUsersList();
        this.setState({
          deleteModal: false,
          userId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          userId: null,
          deleteLoader: false
        });
      });
  };

  headerFormatter = column => {
    return <TranslatableText translationKey={column.text} />;
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let { tableLoader, users, columns } = this.state;
    return (
      <div className="admin-partners-view">
        <Accent useIcon={true} icon={faUserFriends}>
          <TranslatableText translationKey="persons" />
        </Accent>
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={users}
            columns={columns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`${this.props.match.path}/create`}
                        >
                          <TranslatableText translationKey="create" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>Back</Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              <TranslatableText translationKey="confirm_delete" />?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="confirm_delete_string" />?
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deleteUser(this.state.userId, e)}
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(Persons);
Persons.contextType = GlobalContext;
