import { faGlobe } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button, Col, Form, FormGroup, Input, Label, NavLink, Row
} from "reactstrap";
import { getTextTranslationAPI } from "../../../../../../apiClients";
import { InlineLoading, ScreenLoading } from "../../../../../../components";
import Accent from "../../../../../../components/accent/Accent";
import FormValidator from "../../../../../../components/validator/FormValidator";
import "../../../../../../components/validator/FormValidator.scss";
import { Config } from "../../../../../../config/Config";
import { GlobalContext } from "../../../../../../contexts/global-context";
import TranslatableText from "../../../../../../Language/TranslatableText";
import Editor from './editor/Editor';
import {
  addQuestionTranslationAPI, getQuestionBaseTranslationsByIdAPI, getQuestionTranslationsAPI, getSingleTranslationAPI, isQuestionHtmlAPI, updateQuestionTranslationAPI
} from "./questionsAPI";

interface IState {
  title: string,
  descriptionShort: string,
  descriptionLong: string,
  html: string,
  name: string,
  language: string,
  questionId: string,
  referenceDisplayText: string,
  reference: string,
  translationName: string,
  loading: boolean,
  cuLoader: boolean,
  validation: any,
  translationObject: any[],
  translateLoader: boolean,
  isQuestionHtml: boolean,
  currentDescriptionLong: string,
  translating: {
    title: boolean,
    name: boolean,
    descriptionShort: boolean,
    descriptionLong: boolean,
    referenceDisplayText: boolean,
    reference: boolean
  },
  languageDropdown: any,
  baseLanguageDropdown: any,
  baseTranslation: {
    title: string,
    name: string,
    descriptionShort: string,
    descriptionLong: string,
    referenceDisplayText: string,
    reference: string,
    language: string,
  }
}
interface IProps {
  match: any,
  history: any
}


class AddQuestionTranslations extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    // @ts-ignore
    this.validator = new FormValidator([
      {
        field: "title",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      title: "",
      descriptionShort: "",
      descriptionLong: "",
      html: "",
      name: "",
      language: "",
      questionId: "",
      referenceDisplayText: "",
      reference: "",
      translationName: "",
      loading: false,
      cuLoader: false,
      // @ts-ignore
      validation: this.validator.valid(),
      translationObject: [],
      translateLoader: false,
      isQuestionHtml: false,
      currentDescriptionLong: "",
      translating: {
        title: false,
        name: false,
        descriptionShort: false,
        descriptionLong: false,
        referenceDisplayText: false,
        reference: false
      },
      languageDropdown: Config.languages,
      baseLanguageDropdown: Config.languages,
      baseTranslation: {
        title: "",
        name: "",
        descriptionShort: "",
        descriptionLong: "",
        referenceDisplayText: "",
        reference: "",
        language: ""
      }
    };

    // @ts-ignore
    this.submitted = false;
  }

  componentDidMount() {
    var selected_languages,
      available_languages = [];
    this.setState({ loading: true });
    isQuestionHtmlAPI(this.props.match.params.questionId)
      .then(res => {
        this.setState({ isQuestionHtml: res })
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
    getQuestionBaseTranslationsByIdAPI(this.props.match.params.questionId)
      .then(res => {
        this.setState({ currentDescriptionLong: res.descriptionLong })
        this.setState({
          baseTranslation: res,
          loading: false
        });
      })
      .catch(() => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
    if (this.props.match.params.questionId !== undefined) {
      if (this.props.match.params.translationId === undefined) {
        getQuestionTranslationsAPI(this.props.match.params.questionId)
          .then(res => {
            selected_languages = res.data.map(el => {
              return el.language;
            });
            available_languages = this.state.languageDropdown.filter(el => {
              if (selected_languages.includes(el.value)) {
                return false; // skip
              }
              return true;
            });
            this.setState({
              languageDropdown: available_languages
            });
          })
          .catch(() => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
          });
      }
    }
    if (this.props.match.params.translationId !== undefined) {
      this.setState({ loading: true });
      getSingleTranslationAPI(this.props.match.params.translationId)
        .then(res => {
          this.setState({
            title: res.title,
            descriptionShort: res.descriptionShort,
            descriptionLong: res.descriptionLong,
            language: res.language,
            referenceDisplayText:
              res.referenceDisplayText === undefined ||
                res.referenceDisplayText === null
                ? ""
                : res.referenceDisplayText,
            reference:
              res.reference === undefined || res.reference === null
                ? ""
                : res.reference,
            // @ts-ignore
            id: res.id,
            loading: false
          });
        })
        .catch(() => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  addTranslation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // @ts-ignore
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    // @ts-ignore
    this.submitted = true;

    const data = {
      title: this.state.title,
      descriptionShort: this.state.descriptionShort,
      descriptionLong: this.state.descriptionLong,
      language: this.state.language,
      referenceDisplayText: this.state.referenceDisplayText,
      reference: this.state.reference,
      questionId:
        this.props.match.params.questionId !== undefined
          ? this.props.match.params.questionId
          : "",
      id:
        this.props.match.params.translationId !== undefined
          ? this.props.match.params.translationId
          : "",
      userId: this.context.user.organizations.userId
    };
    if (this.props.match.params.translationId === undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        addQuestionTranslationAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(() => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateQuestionTranslationAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleEditor = (e, type: string) => {
    if (e == "<p><br></p>") {
      // @ts-ignore
      this.setState({ [type]: "" })
    } else {
      // @ts-ignore
      this.setState({ [type]: e })
    }
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    // @ts-ignore
    this.setState({
      [name]: value
    });
  };

  translateSingleField = field => {
    this.setState(prevState => ({
      translating: {
        ...prevState.translating,
        [field]: true
      }
    }));

    getTextTranslationAPI({
      to: this.state.language,
      text: this.state.baseTranslation[field]
    })
      .then(res => {
        // @ts-ignore
        this.setState(prevState => ({
          [field]: res.data,
          translating: {
            ...prevState.translating,
            [field]: false
          }
        }));
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState(prevState => ({
          translating: {
            ...prevState.translating,
            [field]: false
          }
        }));
      });
  };

  translateFields = () => {
    let self = this;
    Object.keys(this.state.baseTranslation).forEach(function (item) {
      console.log(item); // key
      console.log(self.state.baseTranslation[item]); // value
      if (
        item !== "id" &&
        item !== "language" &&
        item !== "templateRegardingObjectTypeId"
      ) {
        self.translateSingleField(item);
      }
    });
  };
  handleHtmlChange = (event) => {
    this.setState({ descriptionLong: event });
  }

  render() {
    let { loading } = this.state;
    // @ts-ignore
    let validation = this.submitted // if the form has been submitted at least once
      // @ts-ignore
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="add-question-translation-view">
        {loading && (
          <ScreenLoading />
        )}

        <Row>
          <Col md={12}>
            <Accent useIcon={false} left={false}>
              {this.props.match.params.translationId !== undefined
                ? <TranslatableText translationKey="update" />
                : <TranslatableText translationKey="create" />}{" "}
              <TranslatableText translationKey="company.translation_for" />{" "}
              {this.state.baseTranslation.title}
            </Accent>
          </Col>
        </Row>

        <Row className="panel">
          <Col md={12}>
            <Row>
              <Col md={6} className="panel-header">
                <span className="capitalize">
                  <TranslatableText translationKey="company.translate_from" />{" "}
                  {this.state.baseTranslation.language}
                </span>
              </Col>
              <Col md={6} className="panel-header space-between">
                <span className="capitalize">Translate To</span>
                <Button
                  disabled={this.state.language === ""}
                  outline
                  onClick={e => this.translateFields()}
                >
                  <TranslatableText translationKey="company.translate_to" />{" "}
                  {this.state.language
                    ? this.state.languageDropdown.find(
                      sel => sel.value === this.state.language
                    ).name
                    : "Select"}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col className="panel-separator" md={6}>
            <Row form>
              <Col md={12}>
                <FormGroup
                  className={validation.language.isInvalid ? "has-error" : ""}
                >
                  <Label for="base_language">
                    <TranslatableText translationKey="language" />
                  </Label>
                  <Input
                    type="select"
                    name="base_language"
                    id="base_language"
                    value={this.state.baseTranslation.language}
                    disabled={true}
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.baseLanguageDropdown.map((e, key) => {
                      return (
                        <option key={key} value={e.value}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Input>
                  <span className="help-block">
                    {validation.language.message}
                  </span>
                </FormGroup>
              </Col>
              <Col md={12}>
                {!this.state.isQuestionHtml &&
                  <FormGroup
                    className={validation.title.isInvalid ? "has-error" : ""}
                  >
                    <Label for="base_title">Title</Label>
                    <Input
                      type="text"
                      name="base_title"
                      id="base_title"
                      placeholder=""
                      disabled={true}
                      value={this.state.baseTranslation.title}
                    />
                    <span className="help-block">{validation.title.message}</span>
                  </FormGroup>
                }
              </Col>

              <Col md={12}>
                {!this.state.isQuestionHtml &&

                  <FormGroup>
                    <Label for="base_descriptionShort">
                      <TranslatableText translationKey="description_short" />
                    </Label>
                    <Input
                      type="textarea"
                      name="base_descriptionShort"
                      id="base_descriptionShort"
                      disabled={true}
                      value={this.state.baseTranslation.descriptionShort}
                    />
                  </FormGroup>
                }
              </Col>

              <Col>
                <FormGroup>
                  <Label for="base_descriptionLong">
                    {!this.state.isQuestionHtml &&
                      <TranslatableText translationKey="description_long" />
                    }
                    {this.state.isQuestionHtml &&
                      <p>HTML</p>
                    }
                  </Label>
                  {this.state.isQuestionHtml &&
                    <Input
                      type="textarea"
                      name="base_descriptionLong"
                      id="base_descriptionLong"
                      value={this.state.currentDescriptionLong}
                      disabled={true}
                    />
                  }
                  {!this.state.isQuestionHtml &&
                    <Editor initialValue={this.state.currentDescriptionLong} callback={e => this.handleEditor(e, "descriptionLong")} disabled={true} />
                  }


                </FormGroup>
              </Col>

              <Col md={12}>
                {!this.state.isQuestionHtml &&

                  <FormGroup>
                    <Label for="base_referenceDisplayText">
                      <TranslatableText translationKey="questions.reference_display_text" />
                    </Label>
                    <Input
                      type="text"
                      name="base_referenceDisplayText"
                      id="base_referenceDisplayText"
                      disabled={true}
                      value={this.state.baseTranslation.referenceDisplayText}
                    />
                  </FormGroup>
                }
              </Col>

              <Col>
                {!this.state.isQuestionHtml &&
                  <FormGroup>
                    <Label for="base_reference">
                      <TranslatableText translationKey="reference" />
                    </Label>
                    <Editor initialValue={this.state.baseTranslation.reference === undefined ||
                      this.state.baseTranslation.reference === null
                      ? ""
                      : this.state.baseTranslation.reference}
                      callback={e => this.handleEditor(e, "base_reference")} disabled={true} />

                  </FormGroup>
                }
              </Col>

              <Col className="space-between flex mtb-10" md={12}>
                <Button onClick={this.goBack}>
                  <TranslatableText translationKey="back" />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Form onSubmit={this.addTranslation}>
              <Row form>
                <Col md={12}>
                  <FormGroup
                    className={validation.language.isInvalid ? "has-error" : ""}
                  >
                    <Label for="language">
                      <TranslatableText translationKey="language" />
                    </Label>
                    <Input
                      type="select"
                      name="language"
                      id="language"
                      value={this.state.language}
                      onChange={this.handleChange}
                      disabled={this.props.match.params.translationId !== undefined}
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.languageDropdown.map((e, key) => {
                        return (
                          <option key={key} value={e.value}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                    <span className="help-block">
                      {validation.language.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  {!this.state.isQuestionHtml &&

                    <FormGroup
                      className={validation.title.isInvalid ? "has-error" : ""}
                    >
                      <Label className="flex space-between" for="title">
                        <span className="flex verticle-flex-end">
                          <TranslatableText translationKey="company.display_name" />
                        </span>
                        {this.state.translating.title === false ? (
                          <NavLink
                            className="no-padding translate"
                            onClick={e => this.translateSingleField("title")}
                            disabled={this.state.language === ""}
                          >
                            <Tippy placement="top" content="Translate">
                              <span>
                                <FontAwesomeIcon
                                  style={{ color: "var(--primary)" }}
                                  icon={faGlobe}
                                  size="1x"
                                />
                              </span>
                            </Tippy>
                          </NavLink>
                        ) : (
                          <InlineLoading />
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder=""
                        value={this.state.title}
                        onChange={this.handleChange}
                      />
                      <span className="help-block">
                        {validation.title.message}
                      </span>
                    </FormGroup>
                  }
                </Col>
                <Col md={12}>
                  {!this.state.isQuestionHtml &&

                    <FormGroup>
                      <Label
                        className="flex space-between"
                        for="descriptionShort"
                      >
                        <span className="flex verticle-flex-end">
                          <TranslatableText translationKey="description_short" />
                        </span>
                        {this.state.translating.descriptionShort === false ? (
                          <NavLink
                            className="no-padding translate"
                            onClick={e =>
                              this.translateSingleField("descriptionShort")
                            }
                            disabled={this.state.language === ""}
                          >
                            <Tippy placement="top" content="Translate">
                              <span>
                                <FontAwesomeIcon
                                  style={{ color: "var(--primary)" }}
                                  icon={faGlobe}
                                  size="1x"
                                />
                              </span>
                            </Tippy>
                          </NavLink>
                        ) : (
                          <InlineLoading />
                        )}
                      </Label>
                      <Input
                        type="textarea"
                        name="descriptionShort"
                        id="descriptionShort"
                        value={this.state.descriptionShort}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  }
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label className="flex space-between" for="descriptionLong">
                      <span className="flex verticle-flex-end">
                        {!this.state.isQuestionHtml &&
                          <TranslatableText translationKey="description_long" />
                        }
                        {this.state.isQuestionHtml &&
                          <p>HTML</p>
                        }
                      </span>
                      {this.state.translating.descriptionLong === false ? (
                        <NavLink
                          className="no-padding translate"
                          onClick={e =>
                            this.translateSingleField("descriptionLong")
                          }
                          disabled={this.state.language === ""}
                        >
                          <Tippy placement="top" content="Translate">
                            <span>
                              <FontAwesomeIcon
                                style={{ color: "var(--primary)" }}
                                icon={faGlobe}
                                size="1x"
                              />
                            </span>
                          </Tippy>
                        </NavLink>
                      ) : (
                        <InlineLoading />
                      )}
                    </Label>
                    {this.state.isQuestionHtml &&
                      <Input
                        type="textarea"
                        name="base_descriptionLong"
                        id="base_descriptionLong"
                        value={this.state.descriptionLong}
                        onChange={e => this.handleHtmlChange(e)}
                      />

                    }
                    {!this.state.isQuestionHtml &&
                      <Editor initialValue={this.state.descriptionLong} callback={e => this.handleEditor(e, "descriptionLong")} disabled={false} />
                    }


                  </FormGroup>
                </Col>

                <Col md={12}>
                  {!this.state.isQuestionHtml &&

                    <FormGroup>
                      <Label
                        className="flex space-between"
                        for="referenceDisplayText"
                      >
                        <span className="flex verticle-flex-end">
                          <TranslatableText translationKey="questions.reference_display_text" />
                        </span>
                        {this.state.translating.referenceDisplayText === false ? (
                          <NavLink
                            className="no-padding translate"
                            onClick={e =>
                              this.translateSingleField("referenceDisplayText")
                            }
                            disabled={this.state.language === ""}
                          >
                            <Tippy placement="top" content="Translate">
                              <span>
                                <FontAwesomeIcon
                                  style={{ color: "var(--primary)" }}
                                  icon={faGlobe}
                                  size="1x"
                                />
                              </span>
                            </Tippy>
                          </NavLink>
                        ) : (
                          <InlineLoading />
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="referenceDisplayText"
                        id="referenceDisplayText"
                        value={this.state.referenceDisplayText}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  }
                </Col>

                <Col md={12}>
                  {!this.state.isQuestionHtml &&

                    <FormGroup>
                      <Label className="flex space-between" for="reference">
                        <span className="flex verticle-flex-end">
                          <TranslatableText translationKey="reference" />
                        </span>
                        {this.state.translating.reference === false ? (
                          <NavLink
                            className="no-padding translate"
                            onClick={e =>
                              this.translateSingleField("reference")
                            }
                            disabled={this.state.language === ""}
                          >
                            <Tippy placement="top" content="Translate">
                              <span>
                                <FontAwesomeIcon
                                  style={{ color: "var(--primary)" }}
                                  icon={faGlobe}
                                  size="1x"
                                />
                              </span>
                            </Tippy>
                          </NavLink>
                        ) : (
                          <InlineLoading />
                        )}
                      </Label>
                      <Editor initialValue={this.state.reference} callback={e => this.handleEditor(e, "reference")} disabled={false} />
                    </FormGroup>
                  }
                </Col>

                <Col className="flex-end flex mtb-10" md={12}>
                  {this.state.cuLoader === false ? (
                    <Button>
                      {this.props.match.params.translationId !== undefined
                        ? <TranslatableText translationKey="update" />
                        : <TranslatableText translationKey="create" />}
                      {" "} Translation
                    </Button>
                  ) : (
                    <InlineLoading />
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(AddQuestionTranslations);
AddQuestionTranslations.contextType = GlobalContext;