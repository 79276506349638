import { faBook, faCalendarCheck, faFileAlt, faTachometerAlt, faTasks } from "@fortawesome/pro-solid-svg-icons";
import { NavMenuLink } from "../NavMenuLink";

export function BasisNavigation(menuItemClick: () => void) {
  return (
    <>
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faTachometerAlt}
        path='/'
        translationKey='dashboard'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faBook}
        path='/processes'
        translationKey='processes'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faFileAlt}
        path='/documents'
        translationKey='documents'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faCalendarCheck}
        path='/annual-cycle-of-work'
        translationKey='annualCycleOfWork'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faTasks}
        path='/tasks'
        translationKey='tasks'
      />
    </>
  );
}