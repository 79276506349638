import { faBook } from "@fortawesome/pro-solid-svg-icons";
import { AccountLevel } from "../../../../config/Levels";
import SecuredComponent from "../../../secured-component/SecuredComponent";
import { NavMenuLink } from "../NavMenuLink";

export function TristadNavigation(menuItemClick: () => void) {
  return (
    <>
      {/* <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faGavel}
        path='/'
        translationKey=''
      /> */}
      <SecuredComponent accountLevel={AccountLevel.Developer}>
        <NavMenuLink
          menuItemClick={menuItemClick}
          icon={faBook}
          path='/processes'
          translationKey='processes'
        />
      </SecuredComponent>
    </>
  );
}
