import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import TranslatableText from "../../../../Language/TranslatableText";

let BusinessInformation = ({
  componentState,
  handleChange,
  validation,
  removeLogo,
  id
}) => {
  return (
    <Row form>
      <Col md={4}>
        <FormGroup className={validation.name.isInvalid ? "has-error" : ""}>
          <Label for="name">
            <TranslatableText translationKey="name" />*
          </Label>
          <Input
            type="text"
            name="name"
            id="name"
            value={componentState.name}
            onChange={e => handleChange(null, e)}
            placeholder="Company Name"
          />
          <span className="help-block">{validation.name.message}</span>
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="phone">
            <TranslatableText translationKey="phone" />
          </Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            value={componentState.phone}
            onChange={e => handleChange(null, e)}
            placeholder="Company Phone No."
          />
        </FormGroup>
      </Col>
      <Col md={4} className={validation.email.isInvalid ? "has-error" : ""}>
        <FormGroup>
          <Label for="email">
            <TranslatableText translationKey="email" />*
          </Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={componentState.email}
            onChange={e => handleChange(null, e)}
            placeholder="Company Email Address"
          />
          <span className="help-block">{validation.email.message}</span>
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup className={validation.vat.isInvalid ? "has-error" : ""}>
          <Label for="vat">
            <TranslatableText translationKey="company.vat" />*
          </Label>
          <Input
            type="text"
            name="vat"
            id="vat"
            placeholder="Vat"
            value={componentState.vat}
            onChange={e => handleChange(null, e)}
          />
          <span className="help-block">{validation.vat.message}</span>
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="address">
            <TranslatableText translationKey="address" />
          </Label>
          <Input
            type="text"
            name="address"
            id="address"
            placeholder="Address"
            value={componentState.address}
            onChange={e => handleChange(null, e)}
          />
        </FormGroup>
      </Col>
      <Col md={4}>
        {componentState.logo !== "" ? (
          <div>
            <p className="label">
              <TranslatableText translationKey="logo" />{" "}
              <span className="small">
                (<TranslatableText translationKey="company.preview" />)
              </span>
            </p>
            <div className="image-container">
              <img
                className="image-max-width"
                src={componentState.logo}
                height="33"
              />
              <span className="remove-image" onClick={removeLogo}>
                <i className="fa fa-times"></i>
              </span>
            </div>
          </div>
        ) : (
          <FormGroup>
            <Label for="logo">
              <TranslatableText translationKey="upload" />{" "}
              <TranslatableText translationKey="logo" />{" "}
              <span className="red small">
                ( <TranslatableText translationKey="company.max_allowed_size" />{" "}
                <b>50Kb</b> )
              </span>
            </Label>
            <Input
              className="attachment-input"
              type="file"
              name="logo"
              id="logo"
              onChange={e => handleChange(null, e)}
            />
          </FormGroup>
        )}
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="postalCode">
            <TranslatableText translationKey="zip_code" />
          </Label>
          <Input
            type="text"
            name="postalCode"
            id="postalCode"
            value={componentState.postalCode}
            onChange={e => handleChange(null, e)}
            placeholder="zipcode"
          />
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="city">
            <TranslatableText translationKey="city" />
          </Label>
          <Input
            type="text"
            name="city"
            id="city"
            placeholder="City / Town"
            value={componentState.city}
            onChange={e => handleChange(null, e)}
          />
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup
          className={validation.countryCode.isInvalid ? "has-error" : ""}
        >
          <Label for="package">
            <TranslatableText translationKey="country_code" />*
          </Label>
          <Input
            type="select"
            name="countryCode"
            id="countryCode"
            value={componentState.countryCode}
            onChange={e => handleChange(null, e)}
          >
            <option value="">-- Vælg --</option>
            {componentState.countryCodeDropdown.map((e, key) => {
              return (
                <option key={key} value={e.value}>
                  {e.name}
                </option>
              );
            })}
          </Input>
          <span className="help-block">{validation.countryCode.message}</span>
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="dueDate">
            <TranslatableText translationKey="company.licens_expiration" />
          </Label>
          <DatePicker
            className="form-control datepicker"
            selected={componentState.licenseExpirationDatePicker}
            onChange={(date, e) => handleChange(date, e)}
            minDate={new Date()}
            dateFormat="dd-MM-yyyy"
            placeholderText="License Expiration"
          />
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="invoiceNumber">
            <TranslatableText translationKey="company.client_id" />
          </Label>
          <Input
            type="text"
            name="invoiceNumber"
            id="invoiceNumber"
            placeholder="Invoice Number"
            value={componentState.invoiceNumber}
            onChange={e => handleChange(null, e)}
          />
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="templateOrganization">
            <TranslatableText translationKey="company.template_organisation" />
          </Label>
          <Input
            type="select"
            name="templateOrganization"
            id="templateOrganization"
            value={componentState.templateOrganization}
            onChange={e => handleChange(null, e)}
            disabled={id !== undefined ? true : null}
          >
            <option value="">-- Vælg --</option>
            {componentState.templateOrganizationDropdown.map((e, key) => {
              return (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>
      {componentState.selectedOption?.label === "KD-Sys 360" && (
        <>
        <Col md={4}>
        </Col>
        <Col md={4}>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="selectedFiscalYear">
              Finansår
            </Label>
            <Input
              type="select"
              name="selectedFiscalYear"
              id="selectedFiscalYear"
              value={componentState.selectedFiscalYear}
              onChange={e => handleChange(null, e)}
            >
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </Input>
          </FormGroup>
        </Col>
        </>
      )}
    </Row>
  );
};

export default React.memo(BusinessInformation);
