import React, { useEffect, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { getEntitiesAPI } from "../../views/entity/entityAPI";
import { InlineLoading } from "../loading";



let MultiSelect = ({
  index,
  element,
  componentState,
  handleChange,
  handleTagboxChange,
  changeValidationMessage
}) => {
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(false);

  useEffect(() => {
    setLoader(true);
    getEntitiesAPI(element.lookupFieldReferenceTypeId)
      .then(res => {
        if (res !== undefined && Array.isArray(res)) {
          let entities = [];
          res.forEach(function (row) {
            entities.push({ label: row.name, value: row.id });
          });
          entities.sort((a, b) => {
            var textA = a.label.toUpperCase();
            var textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          setOptions(entities);
          setLoader(false);
        }
      })
      .catch(error => {
        setLoader(false);
      });
  }, []);

  return loader ? (
    <FormGroup className="flex align-loader">
      <InlineLoading />
    </FormGroup>
  ) : (
    <FormGroup
      className={
        element.displayType.toLowerCase() === "textarea" ? "full-flex" : ""
      }
    >
      <Label for={element.name}>
        <span className="capitalize">{element.displayText}</span>
      </Label>
      <div className="flex space-between flex-align">
        <Select
          isDisabled={componentState["lock"] ? true : null}
          isMulti
          value={componentState[element.id]}
          className="fullwidth"
          options={options}
          onChange={selectedOption =>
            handleTagboxChange(element.id, selectedOption)
          }
          hideSelectedOptions={true}
        />
      </div>
    </FormGroup>
  );
};

export default MultiSelect;
