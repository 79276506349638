import { faAngleDown, faAngleRight, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCity } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { Col, FormGroup, Label, NavLink, Row } from "reactstrap";
import { GetAllOrganizationsResponse } from "../../../apiClients";
import Accent from "../../../components/accent/Accent";
import TranslatableText from "../../../Language/TranslatableText";
import CompanyDetailsComponent from "../views/companies/components/CompanyDetailsComponent";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
    <div className="text-center">
        <span>
            <TranslatableText translationKey="no_data_available" />
        </span>
    </div>
);

export default function CompaniesList(
    partnerId: string,
    companies: GetAllOrganizationsResponse[],
    inActiveCompaniesCheckbox: boolean,
    HandleInActiveCheckbox: (checked: boolean) => void) {
    // const [showFiltered, setShowFiltered] = useState(false);

    const expandRow = {
        renderer: (row: GetAllOrganizationsResponse) => (
            <div className="p-2">
                <CompanyDetailsComponent
                    id={row.id}
                    showBackButton={false}
                />
            </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: false,
        expandHeaderColumnRenderer: () => (<div onClick={(e) => { e.stopPropagation() }}></div>),
        expandColumnRenderer: ({ expanded }: { expanded: boolean }) => {
            return expanded ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />;
        },
    };

    function headerFormatter(column, colIndex, { sortElement, filterElement }) {
        return (
            <div>
                <TranslatableText translationKey={column.text} /> {sortElement}
                {filterElement}
            </div>
        );
    }
    function logoFormatter(cell: any) {
        if (
            cell !== "" &&
            cell !== null &&
            cell !== undefined &&
            cell.startsWith("data:")
        ) {
            return <img className="image-max-width" src={cell} alt="" />;
        } else {
            return "";
        }
    }

    const tableColumns: Array<ColumnDescription<GetAllOrganizationsResponse>> =
        [
            {
                dataField: "id",
                text: "ID",
                hidden: true,
                searchable: false
            },
            {
                dataField: "name",
                text: "name",
                headerFormatter: headerFormatter,
                sort: true,
                headerStyle: () => {
                    return { width: "35%" };
                }
            },
            {
                dataField: "vat",
                text: "vat",
                headerFormatter: headerFormatter,
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: "address",
                text: "address",
                headerFormatter: headerFormatter,
                sort: true,
                searchable: false
            },
            {
                dataField: "logo",
                text: "Logo",
                headerFormatter: headerFormatter,
                formatter: logoFormatter,
                searchable: false
            },
            {
                dataField: "actions",
                text: "action",
                headerFormatter: headerFormatter,
                csvExport: false,
                searchable: false,
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    return (
                        <div className="actions">
                            <NavLink
                                tag={Link}
                                to={`/partner/company/details/${row.id}`}
                            >
                                <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                            </NavLink>
                        </div>
                    );
                }
            }
        ];

    return (<Row>
        <Col md={12}>
            <Accent useIcon={true} icon={faCity}>
                <TranslatableText translationKey="companies" />
            </Accent>
            <div className="table-container" style={{ 'marginTop': '-50px' }}>
                <ToolkitProvider
                    keyField="id"
                    bootstrap4
                    data={companies}
                    columns={tableColumns}
                    search
                    exportCSV={{
                        fileName: "custom.csv",
                        separator: ';',
                        ignoreHeader: false,
                        noAutoBOM: false
                    }}
                >
                    {props => (
                        <div>
                            <div className="flex flex-end">
                            </div>
                            <div className="flex space-between">
                                <div>
                                    <SearchBar {...props.searchProps} />
                                </div>
                                <div className="">
                                    <div className="flex space-between">
                                <FormGroup className={`custom-control custom-switch mr-3`}>

                                    <Label for={'inActiveCompanies'} className={'custom-control-label'}>
                                        <TranslatableText translationKey={'inActiveCompanies'} />
                                    </Label>
                                    <input type='checkbox'
                                        id={'inActiveCompanies'}
                                        name={'inActiveCompanies'}
                                        className={`custom-control-input`}
                                        checked={inActiveCompaniesCheckbox}
                                        onChange={(event) => { HandleInActiveCheckbox(event.target.checked) }}
                                    />
                                </FormGroup>
                                        {/* <div className="flex space-between flex-align mr-10">
                                            <div className="mr-10"><TranslatableText translationKey="show all" /></div>
                                            <div
                                                onClick={e =>
                                                    setShowFiltered(!showFiltered)
                                                }
                                                className={"search-toggle-btn"}
                                            >
                                                <span
                                                    className={`"ball", ${showFiltered ? "toggled" : ""}`}
                                                />
                                            </div>
                                        </div> */}
                                        <ExportCSVButton
                                            className="export-btn"
                                            {...props.csvProps}
                                        >
                                            CSV
                                        </ExportCSVButton>
                                        <div>
                                            <NavLink
                                                className="button-primary"
                                                tag={Link}
                                                to={`/partner/company/add/${partnerId}`}
                                            >
                                                <TranslatableText translationKey="create" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <BootstrapTable
                                classes="organization-list"
                                expandRow={expandRow}
                                noDataIndication={() => <NoDataIndication />}
                                pagination={paginationFactory({})}
                                rowClasses={(row, rowIndex) => !row.isActive ? "disabled-row" : ""}
                                {...props.baseProps}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </div>
        </Col>
    </Row>
    );
}