import React, { Component } from "react";
import { Row, Col, Button, NavLink } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { getCustomEntityTranslationsByIdAPI } from "./customEntitiesAPI";
import { LanguageContext } from "../../../../contexts/language-context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TranslatableText from "../../../../Language/TranslatableText";
import {
  faEdit,
  faTrash,
  faInfoCircle,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
//import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import Accent from "../../../../components/accent/Accent";
import {
  getRegardingCustomFieldsAPI,
  deleteCustomFieldByIdAPI
} from "../../../company-admin/views/custom-fields/customFieldsCompanyAPI";
import { Config } from "../../../../config/Config";
import { getCustomEntityByIdAPI } from "../../../company-admin/views/custom-entities/tenantCustomEntitiesAPI";
import { faChartNetwork } from "@fortawesome/pro-regular-svg-icons";
import Swal from "sweetalert2";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";




const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class CustomEntityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableLoader: false,
      customEntities: {},
      customFieldsData: [],
      customEntitiesTranslationsData: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "System Name",
          formatter: (cell, row) => {
            return (
              <div className="actions">
                {row.platormEntity ? (
                  <>
                    <FontAwesomeIcon
                      className="rs-5"
                      icon={faChartNetwork}
                      size="1x"
                    />{" "}
                    {row.name}
                  </>
                ) : (
                  <span className="ml-25">{row.name}</span>
                )}
              </div>
            );
          },
          sort: true
        },
        {
          dataField: "dataType",
          text: "Type",
          sort: true
        },
        {
          dataField: "displayType",
          text: "Display Type",
          sort: true
        },
        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/custom-fields/details/${row.id}/${row.regardingObjectTypeId}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
                <NavLink onClick={e => this.deleteCustomField(row.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
          }
        }
      ],
      customEntitiesTranslationColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "displayName",
          text: "Display Name",
          sort: true
        },
        {
          dataField: "pluralName",
          text: "Plural Name",
          sort: true
        },
        {
          dataField: "description",
          text: "Description",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          sort: true,
          formatter: (cell, row) => {
            let language = this.searchArray(cell, Config.languages, "value");
            if (language === undefined) {
              language = "dk";
            }
            return language.name;
          }
        },
        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/custom-entities/translation/update/${row.templateRegardingObjectTypeId}/${row.id}`}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
                <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
          }
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getCustomEntityByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            customEntities: res.data,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });

      this.populateEntityTranslations(this.props.match.params.id);

      this.populateCustomFields(this.props.match.params.id);
    }
  }

  populateEntityTranslations = entityID => {
    this.setState({
      translationTableLoader: true,
      customEntitiesTranslationsData: []
    });
    getCustomEntityTranslationsByIdAPI(entityID)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({
          customEntitiesTranslationsData: res,
          translationTableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ translationTableLoader: false });
      });
  };

  populateCustomFields = regardingObjectId => {
    this.setState({ tableLoader: true, customFields: [] });
    getRegardingCustomFieldsAPI(regardingObjectId)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ customFieldsData: res, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  deleteCustomField = id => {
    this.setState({ tableLoader: true }, () => {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.text"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.context.languages[
          "swal.are_you_sure.confirmButtonText"
        ],
        cancelButtonText: this.context.languages[
          "swal.are_you_sure.cancelButtonText"
        ]
      }).then(result => {
        if (result.value) {
          this.setState({ deleteLoader: true });
          deleteCustomFieldByIdAPI(id)
            .then(res => {
              this.populateCustomFields(this.props.match.params.id);
              this.setState({
                deleteLoader: false
              });
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
              this.setState({
                deleteLoader: false
              });
            });
          Swal.fire(
            this.context.languages["swal.deleted"],
            this.context.languages["swal.deleted_record_deleted"],
            "success"
          );
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState(
            {
              tableLoader: false
            },
            () => {
              Swal.fire(
                this.context.languages["swal.cancelled"],
                this.context.languages["swal.cancelled_your_record_is_safe"],
                "error"
              );
            }
          );
        }
      });
    });
  };

  search = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === key) {
        return array[i];
      }
    }
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            processId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      processId: id
    });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let {
      customFieldsData,
      customEntitiesTranslationsData,
      columns,
      customEntitiesTranslationColumns,
      tableLoader,
      translationTableLoader,
      loading
    } = this.state;
    return (
      <div className="admin-partner-details-view">
        <div className="relative">
          <NavLink
            className="absolute right edit"
            tag={Link}
            to={`/admin/custom-entities/update/${this.props.match.params.id}`}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </NavLink>
        </div>
        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              {loading ? (
                <InlineLoading />
              ) : (
                <span className="capitalize">
                  <TranslatableText translationKey="company.custom_entity" />:{" "}
                  {this.state.customEntities.name}
                </span>
              )}
            </Accent>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {loading ? (
              ""
            ) : (
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <TranslatableText translationKey="company.system_name" />
                    </td>
                    <td>{this.state.customEntities.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="notifications" />
                    </td>
                    <td>
                      {this.state.customEntities.notification ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="company.attachments" />
                    </td>
                    <td>
                      {this.state.customEntities.attachment ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="tasks" />
                    </td>
                    <td>{this.state.customEntities.task ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="company.menu" />
                    </td>
                    <td>
                      {this.state.customEntities.showInMenu ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="wizard" />
                    </td>
                    <td>{this.state.customEntities.wizard ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>
                    <TranslatableText translationKey="search_in_wizard" />
                    </td>
                    <td>{this.state.customEntities.searchInWizard ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="company.publish" />
                    </td>
                    <td>{this.state.customEntities.publish ? "Yes" : "No"}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="company.translations" />
            </Accent>
          </Col>
        </Row>

        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={customEntitiesTranslationsData}
            columns={customEntitiesTranslationColumns}
            search
            exportCSV={{
              fileName: "custom.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        {this.state.entity !== "" ? (
                          <NavLink
                            className="button-primary"
                            tag={Link}
                            to={`/admin/custom-entities/translation/add/${this.props.match.params.id}`}
                          >
                            <TranslatableText translationKey="create" />
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    translationTableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>

        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="custom_fields" />
            </Accent>
          </Col>
        </Row>

        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={customFieldsData}
            columns={columns}
            search
            exportCSV={{
              fileName: "custom.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        {this.state.entity !== "" ? (
                          <NavLink
                            className="button-primary"
                            tag={Link}
                            to={`/admin/custom-fields/create/${this.props.match.params.id}`}
                          >
                            <TranslatableText translationKey="create" />
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>

        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(CustomEntityDetails);
CustomEntityDetails.contextType = GlobalContext;