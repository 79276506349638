import React, { Component } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faCloudDownloadAlt,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import TranslatableText from "../../../../Language/TranslatableText";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { LanguageContext } from "../../../../contexts/language-context";
import {
  getWizardByIdAPI,
  getReportTemplatesOfWizardByIdAPI,
  deleteReportTemplateByIdAPI,
  downloadReportByIdAPI
} from "./companyWizardAPI";
import Accent from "../../../../components/accent/Accent";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class CompanyWizardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      name: "",
      templates: [],
      templateColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "description",
          text: "Description",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ],
      deleteLoader: false,
      deleteTemplateModal: false,
      reportTemplateId: null
    };

    this.downloadReportToast = null;
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getWizardByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.name
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ name: "-" });
        });

      if (this.props.match.params.organizationId !== undefined) {
        this.populateReportTemplates();
      }
    }
  }

  downloadFile = id => {
    this.downloadReportToast = toast.info(
      "Downloading report, please wait...",
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false
      }
    );
    downloadReportByIdAPI(id)
      .then(res => {
        toast.dismiss(this.downloadReportToast);
        var response = res.data;
        const url = window.URL.createObjectURL(
          new Blob([response], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        toast.dismiss(this.downloadReportToast);
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  populateReportTemplates = () => {
    var self = this;
    this.setState({ tableLoader: true, templates: [] });
    getReportTemplatesOfWizardByIdAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink onClick={e => self.downloadFile(el.id, e)}>
                <Tippy placement="top" content="Download">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "var(--primary)" }}
                      icon={faCloudDownloadAlt}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink
                tag={Link}
                to={`/company-admin/wizard/template/update/${self.props.match.params.id}/${el.id}/${self.props.match.params.organizationId}`}
              >
                <Tippy placement="top" content="Update">
                  <span>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink onClick={e => self.openDeleteTemplateModal(el.id, e)}>
                <Tippy placement="top" content="Remove">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
          return o;
        });
        this.setState({
          templates: data,
          language: this.context.language,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeleteTemplatePopup = () => {
    this.setState(
      prevState => ({
        deleteTemplateModal: !prevState.deleteTemplateModal
      }),
      () => {
        if (this.state.deleteTemplateModal === false) {
          this.setState({
            reportTemplateId: null
          });
          console.log("After Modal Dismissal", this.state.deleteTemplateModal);
        }
      }
    );
  };

  openDeleteTemplateModal = id => {
    this.setState({
      deleteTemplateModal: true,
      reportTemplateId: id
    });
  };

  deleteReportTemplate = id => {
    this.setState({ deleteLoader: true });
    deleteReportTemplateByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populateReportTemplates();
        this.setState({
          deleteTemplateModal: false,
          deleteLoader: false,
          reportTemplateId: null
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteTemplateModal: false,
          deleteLoader: false,
          reportTemplateId: null
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let { tableLoader } = this.state;
    return (
      <div className="user-details-view">
        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="report_templates_for" />{" "}
              {this.state.name}
            </Accent>
          </Col>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={this.state.templates}
                columns={this.state.templateColumns}
                search
                exportCSV={{
                  fileName: "data.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/company-admin/wizard/template/create/${this.props.match.params.id}/${this.props.match.params.organizationId}`}
                            >
                              <TranslatableText translationKey="create" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                      pagination={paginationFactory()}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>Back</Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteTemplateModal}
            toggle={this.toggleDeleteTemplatePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteTemplatePopup}>
              Confirm Delete?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="company.confirm_delete_template_description" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e =>
                    this.deleteReportTemplate(this.state.reportTemplateId, e)
                  }
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeleteTemplatePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanyWizardDetails);
CompanyWizardDetails.contextType = GlobalContext;