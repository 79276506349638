import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import AnswerOptionSet from "./AnswerOptionSet";
import CreateAnswerOptionSet from "./CreateAnswerOptionSet";
import AnswerOptionSetDetails from "./AnswerOptionSetDetails";
import CreateAnswerOptionSetValues from "./CreateAnswerOptionSetValues";
import AnswerOptionSetValuesTranslations from "./AnswerOptionSetValuesTranslations";
import AddAnswerOptionSetValuesTranslations from "./AddAnswerOptionSetValuesTranslations";
import { withRouter } from "react-router-dom";

class AnswerOptionSetRouter extends Component {
  render() {
    return (
      <div className="admin-wizard-AnswerOptionSet-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/create`}
            render={props => (
              <CreateAnswerOptionSet
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/translations/add/:answerOptionSetValueId`}
            render={props => (
              <AddAnswerOptionSetValuesTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/translations/update/:answerOptionSetValueId/:translationId`}
            render={props => (
              <AddAnswerOptionSetValuesTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/translations/:id`}
            render={props => (
              <AnswerOptionSetValuesTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/update/:answerOptionSetId/:answerOptionSetValueId`}
            render={props => (
              <CreateAnswerOptionSetValues
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/add/:answerOptionSetId`}
            render={props => (
              <CreateAnswerOptionSetValues
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <AnswerOptionSetDetails
                {...props}
                language={this.context.language}
              />
            )}
          />

          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreateAnswerOptionSet
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path="/admin/wizard/answeroptionset"
            render={props => (
              <AnswerOptionSet {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(AnswerOptionSetRouter);