import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Questions from "./Questions";
import CreateQuestion from "./CreateQuestion";
import QuestionDetails from "./QuestionDetails";
import AddQuestionTranslations from "./AddQuestionTranslations";
import QuestionTranslations from "./QuestionTranslations";
import { withRouter } from "react-router-dom";

class QuestionsRouter extends Component {
  render() {
    return (
      <div className="admin-wizard-page-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/create/:panelId`}
            render={props => (
              <CreateQuestion {...props} language={this.context.language} />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/create`}
            render={props => (
              <CreateQuestion {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <QuestionDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreateQuestion {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/add/:questionId`}
            render={props => (
              <AddQuestionTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/update/:questionId/:translationId`}
            render={props => (
              <AddQuestionTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/:id`}
            render={props => (
              <QuestionTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path="/admin/wizard/question"
            render={props => (
              <Questions {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(QuestionsRouter);