import { faUserNinja } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    CSVExport, Search
} from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { FlowengineHistoryLogsClient } from "../../../../apiClients";
import { InlineLoading } from "../../../../components";
import Accent from "../../../../components/accent/Accent";
import { Table_GetLocalStorage, Table_NoDataIndication, Table_SetLocalStorage } from "../../../../components/table";
import { getApprovalState } from "../../../../config/ApprovalFlowState";
import { GlobalContext } from "../../../../contexts/global-context";
import TranslatableText from "../../../../Language/TranslatableText";
import { getFlowEngineHistoryApproval } from "./flowsAPI";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
    <div className="text-center">
        <span>
            <TranslatableText translationKey="no_data_available" />
        </span>
    </div>
);

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <TranslatableText translationKey={column.text} /> {sortElement}
            {filterElement}
        </div>
    );
}

type FlowHistoryApprovalProps = {

}
type FlowHistoryApprovalParams = {
    regardingObjectTypeId: string,
    regardingObjectId: string,
    id: string,
    stepId: string
}
const FlowHistoryApproval: React.FC<FlowHistoryApprovalProps> = (props) => {
    const history = useHistory();
    // const { match } = props;
    const { regardingObjectTypeId, regardingObjectId, id, stepId } = useParams<FlowHistoryApprovalParams>();
    const { user, userRoles, languages } = useContext(GlobalContext);
    const _FlowengineHistoryLogsClient = new FlowengineHistoryLogsClient();
    const [tableLoader, setTableLoader] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [entityRecordName, setEntityRecordName] = useState<any>({});
    const [flows, setFlows] = useState<any>([]);
    const [columns, setColumns] = useState<any>([
        {
            dataField: "id",
            text: "ID",
            hidden: true
        },
        {
            dataField: "approverName",
            text: "history.approver_name",
            headerFormatter: headerFormatter,
            sort: true
        },
        {
            dataField: "userComments",
            text: "history.comments",
            headerFormatter: headerFormatter,
            sort: true
        },
        {
            dataField: "stateChangedOn",
            text: "history.status_updated",
            headerFormatter: headerFormatter,
            sort: true,
            formatter: (cell: any, row: any) => {
                return (
                    <div>
                        {dateFormatter(row.stateChangedOn)}
                    </div>
                );
            }
        },
        {
            dataField: "state",
            text: "history.status",
            headerFormatter: headerFormatter,
            sort: true,
            formatter: (cell: any, row: any) => {
                return (
                    <div>
                        {getApprovalState(row.state)}
                    </div>
                );
            }
        }
    ]);

    useEffect(() => {
        setTableLoader(true);
        populateFlowsTable();
    }, [])

    const dateFormatter = (date: any) => {
        return moment(date).format('HH:mm, DD MMM[.] YYYY');
    };

    const populateFlowsTable = () => {
        setTableLoader(true);
        setFlows([]);
        // this._FlowengineHistoryLogsClient.flowEngineHistoryLogs(
        getFlowEngineHistoryApproval(
            id,
            stepId
        )
            .then(res => {
                setFlows(Array.isArray(res) ? res : []);
                setTableLoader(false);
            })
            .catch(error => {
                toast.error(languages["api_error"], {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
                setTableLoader(false);
            });
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <div className="admin-flows-view">
            <Row>
                <Col md={12}>
                    <Accent useIcon={false}>
                        {loading ? (
                            <InlineLoading />
                        ) : (
                            <TranslatableText translationKey="history.flows_approval_history" />
                        )}
                    </Accent>
                </Col>
            </Row>
            <div className="table-container">
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={flows}
                    columns={columns}
                    search
                    exportCSV={{
                        fileName: "data.csv",
                        separator: user.language === 'dk' ? ';' : ',',
                        ignoreHeader: false,
                        noAutoBOM: false
                    }}
                >
                    {props => (
                        <div>
                            <div className="flex space-between">
                                <div>
                                    <SearchBar {...props.searchProps} />
                                </div>
                                <div>
                                    <div className="flex space-between">
                                        {userRoles.find((r: any) =>
                                            r.includes("Developer")
                                        ) !== undefined ? (
                                            <ExportCSVButton
                                                className="export-btn"
                                                {...props.csvProps}
                                            >
                                                <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <BootstrapTable
                                // selectRow={selectRow}
                                // expandRow={expandRow}
                                filter={filterFactory()}
                                classes="organization-list"
                                noDataIndication={() => tableLoader ? (<InlineLoading loading={tableLoader} />) : (<Table_NoDataIndication />)}
                                {...props.baseProps}
                                pagination={paginationFactory({ sizePerPage: Table_GetLocalStorage(), onSizePerPageChange: e => Table_SetLocalStorage(e) })} // OBS <-- implement localStorage here
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </div>
            <Row>
                <Col md={12}>
                    <Button onClick={goBack}>
                        <TranslatableText translationKey="back" />
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default FlowHistoryApproval;
