import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Flows from "./Flows";
import FlowsHistory from "./FlowsHistory";
import FlowHistoryDetails from "./FlowHistoryDetails";
import FlowHistoryApproval from "./FlowHistoryApproval";
import CreateFlow from "./CreateFlow";
import { withRouter } from "react-router-dom";

class FlowEngineRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-admin-system-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/update/:id/:regardingObjectTypeId/:regardingObjectId`}
            render={props => (
              <CreateFlow {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreateFlow {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/create/:regardingObjectTypeId/:regardingObjectId`}
            render={props => (
              <CreateFlow {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/create`}
            render={props => (
              <CreateFlow {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/history/:id/:regardingObjectId/:regardingObjectTypeId`}
            render={props => (
              <FlowsHistory {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/history-details/:id`}
            render={props => (
              <FlowHistoryDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/history-approval/:id/:stepId`}
            render={props => (
              <FlowHistoryApproval {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/company-admin/flow-engine/:regardingObjectTypeId/:regardingObjectId"
            render={props => (
              <Flows {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/company-admin/flow-engine"
            render={props => (
              <Flows {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(FlowEngineRouter);