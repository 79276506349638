import RealtorTile from "./tile/RealtorTile";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLanguage } from "../../../contexts/language-context";
import { GetAllSectionProcessesResponse, SectionsClient } from "../../../apiClients"
import { ScreenLoading } from "../../../components/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faBuilding } from "@fortawesome/pro-regular-svg-icons";

import DynamicTree from '../../../views/company-admin/views/sharepoint/Tree';
import { usePlatformUserData } from "../../../contexts/platform-user-context";
import { AccountLevel } from "../../../config/Levels";

export default function RealtorDashboard() {
    const organisationData = usePlatformUserData();
    const [isLoading, setIsLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState<GetAllSectionProcessesResponse[]>();
    const { languageData } = useLanguage();
    const _SectionClient = new SectionsClient();

    useEffect(() => {
        if (languageData.status === 'success') {
            (async () => {
                await _SectionClient.sectionsProcesses()
                    .then(data => {
                        setDashboardData(data);
                    })
                    .catch(error => {
                        toast.error(languageData.languageData["api_error"]);
                        console.debug(error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            })()
        } else if (languageData.status === 'loading') {
            setIsLoading(true);
        }
    }, [languageData.status]);

    if (isLoading) {
        return (
            <div className="center-align">
                <ScreenLoading />
            </div>
        );
    } else {
        return (
            <div className="admin-view">
                {(organisationData.currentOrgData.accountLevel >= AccountLevel.Partner) && (
                    <>
                        <div className={"flex space-between mr-20 accent-line"}>
                            <div className="flex accent-content">
                                <FontAwesomeIcon icon={faBuilding} size="5x" />
                                <h4>Tristad Kundevurdering</h4>
                                <p>Kun synlig for partnere</p>
                            </div>
                        </div>
                        <div className="card-deck">
                            {dashboardData!.map((section, index) => (
                                section.processes?.map((process, index) => (
                                    <div className="w-25" key={index}>
                                        <RealtorTile
                                            key={process.id}
                                            className="custom-tile"
                                            title={section.nameShort ?? ""}
                                            subTitle={process.nameShort ?? ""}
                                            linkTo={`processes/wizard/${process.wizardId}/${process.id}/${process.regardingObjectTypeId}`}
                                            icon={section.icon ?? ""}
                                            iconSize="5x"
                                            backgroundColor={section.descriptionShort ?? ""}
                                            textColor="#fff"
                                            disabled={false}
                                        />
                                    </div>
                                ))))}

                            <div className="w-25" key={'add'}>
                                <RealtorTile
                                    key={'addNew'}
                                    className="custom-tile"
                                    title={'Tilføj ny'}
                                    subTitle={'Risikovurdering'}
                                    linkTo={`company-admin/process/create`}
                                    icon={'file-plus'}
                                    iconSize="5x"
                                    backgroundColor={'var(--primary)'}
                                    textColor="#fff"
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <div className={"flex space-between mr-20 accent-line"}>
                            <div className="flex accent-content">
                                <h4>Synlig for kunder:</h4>
                            </div>
                        </div>
                    </>
                )}
                <div className={"flex space-between mr-20 accent-line"}>
                    <div className="flex accent-content">
                        <FontAwesomeIcon icon={faBook} size="5x" />
                        <h4>Dokumenter</h4>
                    </div>
                </div>
                <DynamicTree showCompanyInformation={true} />
            </div>
        );
    }
}