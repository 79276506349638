import { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Picker extends Component {
  onChange = date => {
    const { question } = this.props;
    question.value = date;
  };

  render() {
    return (
      <DatePicker
        disabled={this.props.question.isReadOnly}
        dateFormat="dd-MM-yyyy"
        onChange={this.onChange}
        selected={
          this.props.question.value === null ||
          this.props.question.value === undefined
            ? new Date()
            : new Date(this.props.question.value)
        }
      />
    );
  }
}

export const datePicker = {
  name: "datepicker",
  render: question => <Picker question={question} />,
  isFit: function (question) {
    return question.getType() === "text" && question.inputType === "date";
  }
};
