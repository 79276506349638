import axios from 'axios';
import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { Config } from "../config/Config";
import { GlobalContext } from './global-context';
import { usePlatformUser } from "./platform-user-context";

type Dispatch = (action: LanguageContextAction) => void

type LanguageContextType = { 
  languageData: LanguageLoadState; 
  language: string; 
  languages: LanguageReasource; 
  languagesSupported: LanguagesSupported; 
  languageDispatch: Dispatch,
  testingFunction: (languages: LanguageReasource) => void }
  | undefined

type LanguageLoadState =
  | { status: 'empty', languageData: LanguageReasource, language: string }
  | { status: 'loading', languageData: LanguageReasource, language: string }
  | { status: 'error', languageData: LanguageReasource, language: string, error: string }
  | { status: 'success', languageData: LanguageReasource, language: string }

type LanguageContextAction =
  | { type: 'request', language: string }
  | { type: 'success', results: LanguageReasource, language: string }
  | { type: 'failure', error: string };

export type LanguageReasource = { [key: string]: string }
type LanguagesSupported = { value: string, name: string }[]


function display(action: LanguageContextAction) {
  if (action.type === 'success') {
    console.log(action.results);
  }
}

//////////////////////////////////////////
//////////////// Conext Setup ////////////
//////////////////////////////////////////
const LanguageContext = createContext<LanguageContextType>(undefined)
LanguageContext.displayName = "LanguageContext";

function LanguageReducer(data: LanguageLoadState, action: LanguageContextAction): LanguageLoadState {
  switch (action.type) {
    case 'request':
      return { status: 'loading', languageData: data.languageData, language: data.language };
    case 'success':
      return { status: 'success', languageData: action.results, language: action.language };
    case 'failure':
      return { status: 'error', languageData: { '': '' }, language: data.language, error: action.error };
  }
}




/**
 * Use this context provider to get a hold of user information in the 360 Platform
 */
function LanguageProvider({ children }: { children?: ReactNode }) {
  const { organisationData } = usePlatformUser();
  const InitialState: LanguageLoadState = { status: 'empty', languageData: { '': '' }, language: organisationData.status === 'success' ? organisationData.data.defaultLanguage! : 'en' };
  const [languageData, dispatch] = useReducer(LanguageReducer, InitialState);
  const { setLanguagesAction } = useContext(GlobalContext);

  useEffect(() => {
    if (organisationData.status === 'success') {
      axios.get(`${Config.globalization_url}/Globalize?localeId=${organisationData.data.defaultLanguage}&resourceSet=${Config.resourceSet[0]}`)
        .then(({ data }) => data)
        .then(res => {
          setLanguagesAction(res);
          dispatch({ type: 'success', results: res, language: organisationData.data.defaultLanguage! });
        })
        .catch(err => {
          dispatch({ type: 'failure', error: err.message })
        });
    }
    // return () => {}
  }, [organisationData.status]);

  function testingFunction(languages: { [key: string]: string }) 
  {
    dispatch({ type: 'success', results: languages, language: 'da' });
  }

  const value: LanguageContextType = { 
    languageData, 
    language: languageData.language, 
    languages: languageData.languageData, 
    languagesSupported: Config.languages, 
    languageDispatch: dispatch,
    testingFunction 
  }
  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

//////////////////////////////////////////
//////////////// Consumers ///////////////
//////////////////////////////////////////
/**
 * Use this Class consumer to get a hold of user information in the 360 Platform
 */
function LanguageConsumer({ children }: { children: (props: LanguageContextType) => ReactNode }) {
  return (
    <LanguageContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error(typeof (LanguageConsumer) + ' must be used within a ' + typeof (LanguageProvider))
        }
        return children(context)
      }}
    </LanguageContext.Consumer>
  )
}

/**
 * Use fc consumer to get a hold of user information in the 360 Platform
 */
function useLanguage() {
  const context = useContext(LanguageContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return context
}
function useLanguageData() {
  const context = useContext(LanguageContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return context.languageData;
}

export { LanguageProvider, LanguageConsumer, useLanguage, useLanguageData, LanguageContext };

