import React, { Component } from "react";
import { NavLink, Row, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCity } from "@fortawesome/pro-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import { getSystems } from "../systems/systemsAPI";
import { getPartnerByIdAPI, getPartnerCompaniesAPI } from "./partnersAPI";
import "./Partners.scss";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import TranslatableText from "../../../../Language/TranslatableText";
import classnames from "classnames";
import { toast } from "react-toastify";
import Accent from "../../../../components/accent/Accent";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading } from "../../../../components";
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);


class PartnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      partners: [],
      allSystemIDs: [],
      loading: false,
      showFiltered: false,
      allOrganizations: [],
      filteredOrganizations: [],
      companies: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          filter: textFilter(),
          sort: true
        },
        {
          dataField: "vat",
          text: "Vat",
          filter: textFilter(),
          sort: true
        },
        {
          dataField: "partnerName",
          text: "Partner",
          sort: true
        },
        {
          dataField: "address",
          text: "Created At",
          sort: true
        },
        {
          dataField: "logo",
          text: "Logo",
          sort: true,
          formatter: this.logoFormatter
        },

        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/company/details/${row.id}/${this.props.match.params.id}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
              </div>
            );
          }
        }
      ]
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.populateCompanies(this.props.match.params.id);
    getSystems().then(res => {
      if (!Array.isArray(res)) {
        console.log("debug: ", res);
        return;
      }
      var partnerSystemIDs = [];
      res.forEach(function (row) {
        partnerSystemIDs.push({ label: row.name, value: row.id });
      });
      this.setState({ allSystemIDs: partnerSystemIDs });
    });

    if (this.props.match.params.id !== undefined) {
      getPartnerByIdAPI(this.props.match.params.id)
        .then(res => {
          var selected = res.data.systemIds.map(el => {
            var pair = { label: "", value: el };
            return pair;
          });
          this.setState({
            name: res.data.name,
            email: res.data.email,
            phone: parseInt(res.data.phone),
            vat: res.data.vat,
            address: res.data.address,
            postalCode: res.data.postalCode,
            city: res.data.city,
            countryCode: parseInt(res.data.countryCode),
            releasePhase: 0,
            logo: "string",
            selectedOption: selected,
            systemIds: selected.map(row => row.value),
            // systemNames: this.systemsFormatter(res.data.systemNames),
            id: this.props.match.params.id,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }

  populateCompanies = id => {
    getPartnerCompaniesAPI(id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        const filteredOrganizations = res.filter(item => {
          return item.isActive === true;
        });

        this.setState({
          allOrganizations: res,
          filteredOrganizations: filteredOrganizations,
          companies: filteredOrganizations,
          loading: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  systemsFormatter = cell => {
    return cell.map((item, index) => (
      <span key={index}>
        {item} {index < cell.length - 1 ? ",\u00A0" : ""}
      </span>
    ));
  };

  logoFormatter = cell => {
    if (
      cell !== "" &&
      cell !== null &&
      cell !== undefined &&
      cell.startsWith("data:")
    ) {
      return <img className="image-max-width" src={cell} />;
    } else {
      return "";
    }
  };

  rowClasses = (row, rowIndex) => {
    if (!row.isActive) {
      return "disabled-row";
    }
  };

  toggleButton = name => {
    let { allOrganizations, filteredOrganizations } = this.state;
    this.setState(
      {
        [name]: !this.state[name]
      },
      () => {
        if (this.state.showFiltered) {
          this.setState({ companies: allOrganizations });
        } else {
          this.setState({ companies: filteredOrganizations });
        }
      }
    );
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { loading, companies, columns } = this.state;
    return (
      <div className="admin-partner-details-view">
        {loading && (
          <ScreenLoading />
        )}
        <h1>
          <TranslatableText translationKey="company.partner_information" />
        </h1>
        <Row>
          <Col md={12}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>
                    <TranslatableText translationKey="company.element" />
                  </th>
                  <th>
                    <TranslatableText translationKey="company.value" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <TranslatableText translationKey="partner.sys_name" />
                  </td>
                  <td>{this.state.systemNames}</td>
                </tr>
                <tr>
                  <td>
                    <TranslatableText translationKey="phone" />
                  </td>
                  <td>{this.state.phone}</td>
                </tr>
                <tr>
                  <td>
                    <TranslatableText translationKey="email" />
                  </td>
                  <td>{this.state.email}</td>
                </tr>
                <tr>
                  <td>
                    <TranslatableText translationKey="address" />
                  </td>
                  <td>{this.state.address}</td>
                </tr>
                <tr>
                  <td>
                    <TranslatableText translationKey="country" />
                  </td>
                  <td>{this.state.countryCode}</td>
                </tr>
                <tr>
                  <td>
                    <TranslatableText translationKey="company.vat" />
                  </td>
                  <td>{this.state.vat}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accent useIcon={true} icon={faCity}>
              Companies
            </Accent>
          </Col>
          <Col>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={companies}
                columns={columns}
                search
                exportCSV={{
                  fileName: "custom.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex flex-end">
                      <div>
                        <div className="flex space-between">
                          <div className="flex space-between flex-align mr-10">
                            <div className="mr-10">Show all</div>
                            <div
                              onClick={e =>
                                this.toggleButton("showFiltered", e)
                              }
                              className={classnames("search-toggle-btn")}
                            >
                              <span
                                className={classnames(
                                  "ball",
                                  this.state.showFiltered ? "toggled" : ""
                                )}
                              />
                            </div>
                          </div>
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              CSV
                            </ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/admin/company/addFirm/${this.props.match.params.id}`}
                            >
                              <TranslatableText translationKey="company.add_company" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      classes="organization-list"
                      noDataIndication={() => <NoDataIndication />}
                      filter={filterFactory()}
                      pagination={paginationFactory()}
                      rowClasses={this.rowClasses}
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(PartnerDetails);
PartnerDetails.contextType = GlobalContext;
