import React, { Component, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { NavLink, Row, Col, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { getOrganizationFlowEngines, deleteFlowByIdAPI, getFlowEngineHistory } from "./flowsAPI";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit, faTrash,
    faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
    Search,
    CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Accent from "../../../../components/accent/Accent";
import { getEntityRecordNameAPI } from "../custom-entities/tenantCustomEntitiesAPI";
import TranslatableText from "../../../../Language/TranslatableText";
import {
    faCheckCircle,
    faInfoCircle
} from "@fortawesome/pro-regular-svg-icons";
import { FlowengineHistoryLogsClient } from "../../../../apiClients";
import { InlineLoading } from "../../../../components";
import { Table_GetLocalStorage, Table_NoDataIndication, Table_SetLocalStorage } from "../../../../components/table";
import filterFactory from "react-bootstrap-table2-filter";
import { useHistory } from 'react-router';
import moment from "moment";
import { GlobalContext } from "../../../../contexts/global-context";
import { getApprovalState } from "../../../../config/ApprovalFlowState";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
    <div className="text-center">
        <span>
            <TranslatableText translationKey="no_data_available" />
        </span>
    </div>
);

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <TranslatableText translationKey={column.text} /> {sortElement}
            {filterElement}
        </div>
    );
}

type FlowsHistoryProps = {

}
type FlowsHistoryParams = {
    regardingObjectTypeId: string,
    regardingObjectId: string,
    id: string
}
const FlowsHistory: React.FC<FlowsHistoryProps> = (props) => {
    const history = useHistory();
    // const { match } = props;
    const { regardingObjectTypeId, regardingObjectId, id } = useParams<FlowsHistoryParams>();
    const { user, userRoles, languages } = useContext(GlobalContext);
    const _FlowengineHistoryLogsClient = new FlowengineHistoryLogsClient();
    const [tableLoader, setTableLoader] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [entityRecordName, setEntityRecordName] = useState<any>({});
    const [flows, setFlows] = useState<any>([]);
    const [columns, setColumns] = useState<any>([
        {
            dataField: "id",
            text: "ID",
            hidden: true
        },
        {
            dataField: "triggerByName",
            text: "histroy.trigger_by",
            headerFormatter: headerFormatter,
            sort: true
        },
        {
            dataField: "triggeredDate",
            text: "history.trigger_at",
            headerFormatter: headerFormatter,
            sort: true,
            formatter: (cell: any, row: any) => {
                return (
                    <div>
                        {dateFormatter(row.triggeredDate)}
                    </div>
                );
            },
            headerStyle: () => {
                return { width: "16%" };
            },
        },
        {
            dataField: "state",
            text: "history.status",
            headerFormatter: headerFormatter,
            formatter: (cell: any, row: any) => {
                return (
                    <div>
                        {getApprovalState(row.state)}
                    </div>
                );
            }
        },
        {
            dataField: "actions",
            text: "actions",
            headerFormatter: headerFormatter,
            csvExport: false,
            formatter: (cell: any, row: any) => {
                return (
                    <div className="actions">
                        <NavLink
                            tag={Link}
                            to={`/company-admin/flow-engine/history-details/${row.id}`}
                        >
                            <Tippy placement="top" content="See details">
                                <span>
                                    <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                                </span>
                            </Tippy>
                        </NavLink>
                    </div>
                );
            }
        }
    ])

    useEffect(() => {
        setTableLoader(true);
        populateFlowsTable();
    }, [])

    const dateFormatter = (date: any) => {
        return moment(date).format('HH:mm, DD MMM[.] YYYY');
    };

    const populateFlowsTable = () => {
        setTableLoader(true);
        setFlows([]);
        // _FlowengineHistoryLogsClient.flowEngineHistoryLogs(
        getFlowEngineHistory(
            id
        )
            .then(res => {
                setFlows(Array.isArray(res) ? res : []);
                setTableLoader(false);
            })
            .catch(error => {
                toast.error(languages["api_error"], {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
                setTableLoader(false);
            });
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <div className="admin-flows-view">
            <Row>
                <Col md={12}>
                    <Accent useIcon={false}>
                        {loading ? (
                            <InlineLoading />
                        ) : (
                            <TranslatableText translationKey="history.flows_history" />
                        )}
                    </Accent>
                </Col>
            </Row>
            <div className="table-container">
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={flows}
                    columns={columns}
                    search
                    exportCSV={{
                        fileName: "data.csv",
                        separator: user.language === 'dk' ? ';' : ',',
                        ignoreHeader: false,
                        noAutoBOM: false
                    }}
                >
                    {props => (
                        <div>
                            <div className="flex space-between">
                                <div>
                                    <SearchBar {...props.searchProps} />
                                </div>
                                <div>
                                    <div className="flex space-between">
                                        {userRoles.find((r: any) =>
                                            r.includes("Developer")
                                        ) !== undefined ? (
                                            <ExportCSVButton
                                                className="export-btn"
                                                {...props.csvProps}
                                            >
                                                <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <BootstrapTable
                                // selectRow={selectRow}
                                // expandRow={expandRow}
                                filter={filterFactory()}
                                classes="organization-list"
                                noDataIndication={() => tableLoader ? (<InlineLoading loading={tableLoader} />) : (<Table_NoDataIndication />)}
                                {...props.baseProps}
                                pagination={paginationFactory({ sizePerPage: Table_GetLocalStorage(), onSizePerPageChange: e => Table_SetLocalStorage(e) })} // OBS <-- implement localStorage here
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </div>
            <Row>
                <Col md={12}>
                    <Button onClick={goBack}>
                        <TranslatableText translationKey="back" />
                    </Button>
                </Col>
            </Row>
        </div>
    );

}



export default FlowsHistory;
