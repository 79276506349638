import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  createPersonAPI,
  getPersonByIdAPI,
  updatePersonAPI
} from "./personsAPI";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import Select from "react-select";
import { toast } from "react-toastify";
import { getEntityCustomFieldsAPI } from "../custom-fields/customFieldsCompanyAPI";
import Fields from "../../../../components/fields/Fields";
import { getUsersAPI } from "../users/usersAPI";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../components";



// Global Variables
var customFieldsModel;

class CreatePerson extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "firstName",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "lastName",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: <TranslatableText translationKey="email_valid" />
      },
      {
        field: "phone",
        method: "isEmpty",
        validWhen: true,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "responsibleId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      address: "",
      organizationId: "",
      responsibleId: "",
      roleIds: [],
      responsibles: [],
      postRoleIds: [],
      customFields: [],
      selectedResponsibleId: [],
      loading: false,
      cuLoader: false,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    getUsersAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var responsibles = [];
        res.forEach(function (row) {
          responsibles.push({ label: row.name, value: row.id });
        });
        this.setState({ responsibles });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getPersonByIdAPI(this.props.match.params.id)
        .then(res => {
          let self = this;
          this.setState(
            {
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              phone: res.data.phone,
              email: res.data.email,
              address: res.data.address,
              responsibleId: res.data.responsibleId,
              customFields:
                res.data.customFieldValues === null ||
                res.data.customFieldValues === undefined
                  ? []
                  : res.data.customFieldValues,
              loading: false
            },
            () => {
              let userCustomFields =
                res.data.customFieldValues === null ||
                res.data.customFieldValues === undefined
                  ? []
                  : res.data.customFieldValues;
              userCustomFields.forEach(element => {
                if (element.displayTypeCode === 1007) {
                  self.setState({
                    [element.id]:
                      element.tagBoxValues === null ? "" : element.tagBoxValues
                  });
                } else {
                  self.setState({
                    [element.name]: element.value === null ? "" : element.value
                  });
                }
              });
            }
          );
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    } else {
      this.populateCustomFields(1007);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { responsibles, responsibleId } = this.state;
    if (
      responsibleId &&
      responsibles.length &&
      (prevState.responsibleId !== responsibleId ||
        prevState.responsibles !== responsibles)
    ) {
      const selectedResponsibleId = responsibles.filter(item => {
        return item.value === responsibleId;
      })[0];
      this.setState({ selectedResponsibleId });
    }
  }

  populateCustomFields = code => {
    this.setState({ loading: true, customFields: [] });
    getEntityCustomFieldsAPI(code)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ customFields: res, loading: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
  };

  createUser = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    let key,
      self = this;

    // Preparing custom fields model
    customFieldsModel = this.state.customFields;
    this.state.customFields.forEach((element, i) => {
      if (element.displayTypeCode === 1007) {
        key = element.id;
        customFieldsModel[i].value = JSON.stringify(
          Array.isArray(self.state[key])
            ? self.state[key].map(row => row.value)
            : []
        );
      } else {
        key = element.name;
        customFieldsModel[i].value = self.state[key];
      }
    });

    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      address: this.state.address,
      customFieldValues: customFieldsModel,
      responsibleId: this.state.responsibleId,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : ""
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updatePersonAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createPersonAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleTagboxChange = (id, selectedOptions) => {
    this.setState({
      [id]: selectedOptions
    });
  };

  handleSingleTagboxChange = (state, selectedStateName, selectedOption) => {
    this.setState({
      [state]: selectedOption.value,
      [selectedStateName]: selectedOption === null ? "" : selectedOption
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  changeValidationMessage = (message, event) => {
    let msg = message === null ? "This is a required field" : message;
    event.target.setCustomValidity(msg);
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    let { loading } = this.state;
    return (
      <div className="create-user-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          {this.props.match.params.id !== undefined ? (
            <TranslatableText translationKey="update" />
          ) : (
            <TranslatableText translationKey="create" />
          )}{" "}
          <TranslatableText translationKey="person" />
        </h3>
        <Form onSubmit={this.createUser}>
          <Row form>
            <Col md={4}>
              <FormGroup
                className={validation.firstName.isInvalid ? "has-error" : ""}
              >
                <Label for="firstName">
                  <TranslatableText translationKey="persons.firstName" />
                </Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder=""
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
                <span className="help-block">
                  {validation.firstName.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup
                className={validation.lastName.isInvalid ? "has-error" : ""}
              >
                <Label for="lastName">
                  <TranslatableText translationKey="persons.lastName" />
                </Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder=""
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
                <span className="help-block">
                  {validation.lastName.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup
                className={validation.email.isInvalid ? "has-error" : ""}
              >
                <Label for="email">
                  <TranslatableText translationKey="email" />
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder=""
                  value={this.state.email}
                  onChange={this.handleChange}
                  disabled={
                    this.props.match.params.id !== undefined ? true : null
                  }
                />
                <span className="help-block">{validation.email.message}</span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={validation.phone.isInvalid ? "has-error" : ""}
              >
                <Label for="phone">
                  <TranslatableText translationKey="phone" />
                </Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder=""
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.phone.message}</span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={
                  validation.responsibleId.isInvalid ? "has-error" : ""
                }
              >
                <Label for="role">
                  <TranslatableText translationKey="responsible" />
                </Label>
                <Select
                  value={
                    this.state.selectedResponsibleId === ""
                      ? null
                      : this.state.selectedResponsibleId
                  }
                  className="user-selectbox"
                  options={this.state.responsibles}
                  onChange={selectedOption =>
                    this.handleSingleTagboxChange(
                      "responsibleId",
                      "selectedResponsibleId",
                      selectedOption
                    )
                  }
                  hideSelectedOptions={false}
                />
                <span className="help-block">
                  {validation.responsibleId.message}
                </span>
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <Label for="address">
                  <TranslatableText translationKey="address" />
                </Label>
                <Input
                  type="textarea"
                  name="address"
                  id="address"
                  placeholder=""
                  value={this.state.address}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>

            <Fields
              customFields={this.state.customFields}
              componentState={this.state}
              handleChange={this.handleChange}
              handleTagboxChange={this.handleTagboxChange}
              changeValidationMessage={this.changeValidationMessage}
            />

            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined ? (
                    <TranslatableText translationKey="update" />
                  ) : (
                    <TranslatableText translationKey="create" />
                  )}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreatePerson);
CreatePerson.contextType = GlobalContext;
