import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Projects from "./Projects";
import ProjectDetails from "./ProjectDetails";
import AddProject from "./AddProject";
import { GlobalContext } from "../../../../contexts/global-context";

class ProjectsRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-partner-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/add`}
            render={props => (
              <AddProject {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id/:organizationId`}
            render={props => (
              <AddProject {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id/:organizationId`}
            render={props => (
              <ProjectDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/admin/projects"
            render={props => (
              <Projects {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(ProjectsRouter);
ProjectsRouter.contextType = GlobalContext;