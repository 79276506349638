import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import "survey-react/survey.min.css";
import TranslatableText from "../../Language/TranslatableText";
import "./SurveyWizard.scss";

type IProps = {
  match: any
}

export default function QuestionnaireThankYou(props: IProps) {
  return (
    <div className="container d-flex vh-100 vw-100">
      <div className="row h-100 justify-content-center align-items-center mx-auto">
        <Card className="w-50">
          <CardBody>
            <CardTitle tag="h5"><TranslatableText translationKey="questionnaire.thank_you" /></CardTitle>
            <CardText tag="p">
              <TranslatableText translationKey="questionnaire.thank_you_text" />
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}