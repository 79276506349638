import React, { Component } from "react";
import * as d3 from "d3";
import "./DataProtectionChart.scss"

interface ICscCisModel {
  cisCscId: string,
  title: string,
  description?: string,
  descriptionLong?: string,
  answer: number,
  remark?: string,
  order: number,
  fileId?: string,
  startAngle: number,
  endAngle: number,
  rotate: number,
  outerRadius: number,
  innerRadius: number
}

export interface ModuleEntities {
  id: string;
  notification: boolean;
  attachment: boolean;
  wizard: boolean;
  task: boolean;
  showInMenu: boolean;
  entityName?: null;
  responsible: string;
  parentId?: null;
  name: string;
  description: string;
  regardingObjectTypeId: string;
  userId: string;
  wizardId: string;
  customFieldValues: (CustomFieldValuesEntity)[];



  startAngle: number;
  endAngle: number;
  rotate: number;
  outerRadius: number;
  innerRadius: number;
}
export interface CustomFieldValuesEntity {
  id: string;
  dataTypeCode: number;
  displayTypeCode: number;
  dataType: string;
  displayType: string;
  name: string;
  value: string;
  displayText: string;
  customFieldDisplayTypeId: string;
  customFieldDataTypeId: string;
  regardingObjectTypeId: string;
  isRequired: boolean;
  validationMessage: string;
  lookupFieldReferenceTypeId?: null;
  entityName: string;
  tagBoxValues?: (null)[] | null;
}

interface IProps {
  callback: any,
  data: [any],
  loading: boolean,
  languages: any
}
interface IState {
  projectId: string,
  loading: boolean
}

// TODO FIX LANGUAGE
function convertIntToStatus(statusInt: string): string {
  switch (statusInt) {
    case 'b1f6c08c-40df-479f-10e9-08d80de52e79': return 'Ikke relevant';
    case '9d1348cb-eb60-4626-10ea-08d80de52e79': return 'Nej';
    case '1256b65f-b685-4d5b-10eb-08d80de52e79': return 'Under udarbejdelse';
    case '89101dd4-8d7e-4fd1-10ed-08d80de52e79': return 'Udarbejdet';
    case '5a04a532-1cea-4d29-10ee-08d80de52e79': return 'Implementeret';
    case '71b2685d-d67a-4758-10ef-08d80de52e79': return 'Testet';
    default: return 'Ikke relevant';
  }
}
function convertValueToInt(value: string): number {
  switch (value) {
    case 'b1f6c08c-40df-479f-10e9-08d80de52e79': return 0;
    case '9d1348cb-eb60-4626-10ea-08d80de52e79': return 1;
    case '1256b65f-b685-4d5b-10eb-08d80de52e79': return 2;
    case '89101dd4-8d7e-4fd1-10ed-08d80de52e79': return 3;
    case '5a04a532-1cea-4d29-10ee-08d80de52e79': return 4;
    case '71b2685d-d67a-4758-10ef-08d80de52e79': return 5;
    default: return 0;
  }
}
class DataProtectionChart extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.d3Chart(this.props.data);
  }

  componentDidUpdate(prevProps: IProps) {
    // if (this.props.data !== prevProps.data) {
      var svgs = document.querySelectorAll("#chart > svg");
      for (var i = svgs.length - 1; i >= 0; --i) {
        svgs[i].remove();
      }
      this.d3Chart(this.props.data);
    // }
  }

  d3Chart = (jsonData: [ModuleEntities]) => {
    

    var margin = 0,
      width = 600,
      height = 600,
      maxBarHeight = height / 2;

    var innerRadius = 0.2 * maxBarHeight; // innermost circle

    var svg = d3.select('#chart')
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("class", "chart")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    svg.append("circle")
      .attr("r", maxBarHeight)
      .classed("chart-area-circle", true);

    svg.append("circle")
      .attr("r", innerRadius)
      .classed("center-circle", true);

    var tooltip = d3.select("body")
      .append("div")
      .attr("class", "tooltip fade bs-tooltip-right show")
      .attr("role", "tooltip")
      .style("position", "absolute")
      .style("visibility", "hidden");
    var tooltipArrow = tooltip
      .append("div")
      .attr("class", "arrow")
      .style("top", "7px");

    var tooltipInner = tooltip
      .append("div")
      .attr("class", "tooltip-inner")
      .text("tooltipText");

    var arc = d3.arc()
      .startAngle(function (d) {
        return d.startAngle;
      })
      .endAngle(function (d) {
        return d.endAngle;
      })
      .innerRadius(innerRadius);

    var x_scale = d3.scaleLinear()
      .domain([0, 100])
      .range([innerRadius, maxBarHeight]);


    var y_scale = d3.scaleLinear()
      .domain([0, 100])
      .range([-innerRadius, -maxBarHeight]);

    var angle = 0,
      rotate = 0;

    jsonData.forEach(function (d, i) {
      // bars start and end angles
      d.startAngle = angle;
      angle += (2 * Math.PI) / jsonData.length;
      d.endAngle = angle;

      // y axis minor lines (i.e. questions) rotation
      d.rotate = rotate;
      rotate += 360 / jsonData.length;
    });

    /* bars */
    var bars = svg.selectAll("path.bar")
      .data(jsonData)
      .enter().append("path")
      .classed("bars", true)
      .each((d: ModuleEntities) => {
        d.outerRadius = innerRadius;
      })
      .attr("d", arc)
      .attr("fill", (d: ModuleEntities) => {
        switch (d.customFieldValues[0].value) {
          case 'b1f6c08c-40df-479f-10e9-08d80de52e79': return 'var(--gray-500)';
          case '9d1348cb-eb60-4626-10ea-08d80de52e79': return 'var(--danger)'; // danger
          case '1256b65f-b685-4d5b-10eb-08d80de52e79': return 'var(--warning)'; // warning
          case '89101dd4-8d7e-4fd1-10ed-08d80de52e79': return 'var(--yellow)'; // yellow
          case '5a04a532-1cea-4d29-10ee-08d80de52e79': return 'var(--green)'; // green
          case '71b2685d-d67a-4758-10ef-08d80de52e79': return 'var(--success)'; // succes
          default: return '';
        }
      })
      .on("mouseover", (d: ModuleEntities) => {
        // TODO FIX LANGUAGE
        tooltipInner.html(d.name + '<br />Status: ' + convertIntToStatus(d.customFieldValues[0].value));
        return tooltip.style("visibility", "visible");
      })
      // .on("mousemove", () => {
      //     return tooltip.style("top",
      //         ( d3Event.event.pageY - 10) + "px").style("left", (d3Event.event.pageX + 10) + "px");
      // })
      .on("mouseout", () => { return tooltip.style("visibility", "hidden"); });

    // Intro animation
    bars.transition().ease(d3.easeSinInOut).duration(250).delay((d: ModuleEntities, i: number) => {
      return i * 30;
    }).attrTween("d", (d, index): any => {
      var _i: any = x_scale(+ convertValueToInt(d.customFieldValues[0].value) * 20);
      var i = d3.interpolate(d.outerRadius, _i);
      return (t: number) => {
        d.outerRadius = i(t);
        return arc(d, index);
      };
    });

    // circles
    svg.selectAll("circle.x.minor")
      .data(y_scale.ticks(5))
      .enter().append("circle")
      .classed("gridlines minor", true)
      .attr("r", (d) => {
        var _i: any = x_scale(d);
        return _i;
      });

    // seperator lines
    svg.selectAll("line.y.minor")
      .data(jsonData)
      .enter().append("line")
      .classed("gridlines minor", true)
      .attr("y1", -innerRadius)
      .attr("y2", -maxBarHeight)
      .attr("transform", (d: ModuleEntities) => {
        return "rotate(" + (d.rotate) + ")";
      });
  }

  render() {
    return (
      <div id="chart" className="center"></div>
    );
  }
}


export default DataProtectionChart;
