import { faHatWizard, faInfoCircle, faPenNib } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormGroup, Input, Label } from "reactstrap";

import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import { CustomEntityValuesClient, CustomFieldsClient, CustomFieldValues, GetCustomEntityValueResponse, UpdateCustomFieldValueRequest } from "../../../apiClients";
import HandleCaught_API_Error from "../../../apiClients/api_caughtError";
import { ScreenLoading } from "../../../components";
import Accent from "../../../components/accent/Accent";
import "./RegnskabsElementer.scss";


export default function RegnskabsElementer() {
  const entityId = '38f8731a-3a7c-40bf-f168-08d84b420bd9';

  const _entityClient = new CustomEntityValuesClient();
  const _fieldClient = new CustomFieldsClient();

  const [loading, setIoading] = useState(true);
  const [entities, setEntities] = useState<GetCustomEntityValueResponse[]>([]);
  useEffect(() => {
    setIoading(true);
    Promise.all([
      _entityClient.customEntityValuesWithCustomField(entityId)
        .then(res => { setEntities(res); console.log(res); })
        .catch(error => { HandleCaught_API_Error(error); })
    ]).finally(() => {
      setIoading(false);
    })
  }, []);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>, field: CustomFieldValues, entryId: string) {
    if (entryId === undefined && field.lookupFieldReferenceLockState) {
      return;
    }
    console.log('field: ', field)
    const target = event.target;
    const value = target.type === "checkbox" ? String(target.checked) : target.value;
    const name = target.name;

    const data = new UpdateCustomFieldValueRequest({
      customFieldId: field.id,
      regardingObjectId: entryId,
      regardingObjectTypeId: field.regardingObjectTypeId,
      value: value
    });

    _fieldClient.updateCustomFieldValue(data)
      .then(res => {
        toast.success("Indstilling opdateret");
        changeCheckedState(entryId, field.id, res.value!);
        field.value = res.value;
      })
      .catch(error => { HandleCaught_API_Error(error); })
  }
  const changeCheckedState = (currentId: string, fieldId: string, state: string) => {
    // @ts-ignore
    setEntities(existingItems => {
      const itemIndex = existingItems.findIndex(item => item.id === currentId)
      const fieldIndex = existingItems[itemIndex]!.customFieldValues!.findIndex(item => item.id === fieldId)
      let fields = existingItems[itemIndex]!.customFieldValues;

      fields![fieldIndex].value = state;
      return [
        ...existingItems.slice(0, itemIndex),
        {
          ...existingItems[itemIndex],
          customFieldValues: fields,
        },
        ...existingItems.slice(itemIndex + 1),
      ]
    })
  }
  function handleWizardShortcut(entry: GetCustomEntityValueResponse) {
    let regardingObjectId = "";
    let regardingObjectTypeId = "";
    for (var i = 0; i <= entry.customFieldValues!.length; i++) {
      if (entry.customFieldValues![i].name === "ProccessShortcut") {
        if (entry.customFieldValues![i].value === null) {
          return;
        }
        regardingObjectId = entry.customFieldValues![i].value!;
        regardingObjectTypeId = entry.customFieldValues![i].lookupFieldReferenceTypeId!;
        return (
          <Link className="ml-1" to={`/wizard/${regardingObjectTypeId}/${regardingObjectId}`}>
            <FontAwesomeIcon
              style={{ color: "var(--primary)", display: 'inline-block' }}
              icon={faHatWizard}
              size="1x"
            />
          </Link>
        );
      }

    }


  }
  function isDisabled(entry: GetCustomEntityValueResponse): boolean {
    let customField = entry.customFieldValues?.find(x => x.name === "ProccessShortcut")
    if (customField === undefined) {
      return false;
    }
    return customField.lookupFieldReferenceLockState ?? false;

  }
  return (
    <div className="entities-view">
      {loading && (
        <ScreenLoading loading={loading} />
      )}
      <br />
      <Accent useIcon={true} icon={faPenNib}>
        Regnskabs Elementer
      </Accent>

      <form method="post" className="container-fluid" id="cis-editEx-form">
        <div className="form-group row d-flex flex-nowrap align-content-end">
          <label className="col-form-label col-4" />
          <div className="dynamic-fields col-8">
            <FormGroup>
              <div className="kreston-regnskab-switch custom-switch">
                <Label className="block">Væsentlig</Label>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="kreston-regnskab-switch custom-switch">
                <Label className="block">Betydende risiko for væsentlig fejlinformation</Label>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="kreston-regnskab-switch custom-switch">
                <Label className="block">Særskilt områdeplan</Label>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="kreston-regnskab-switch custom-switch">
                <Label className="block">Ydes der regnskabs assistance</Label>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="kreston-regnskab-switch custom-switch">
                <Label className="block">Opdatering af kundekendskab</Label>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="kreston-regnskab-switch custom-switch">
                <Label className="block">Er revisor enig i de forudsatte revisionsmål</Label>
              </div>
            </FormGroup>
          </div>
        </div>
        {entities!.sort((a, b) =>
            // @ts-ignore
             a?.customFieldValues?.find(f => f.name! === 'Order')?.value - b?.customFieldValues?.find(f => f.name! === 'Order')?.value
             ).map((entry, index) => (
          // {entities!.map((entry, index) => (
          <div key={index} className="RETablerow" style={isDisabled(entry) === true ? {backgroundColor: '#bae6cf'} : {} }>
            <div className="form-group row d-flex flex-nowrap align-content-end">
              <label className="col-form-label col-4 text-nowrap pt-3">
                <Tippy placement="top" content={entry.description}>
                  <span className="ml-1">
                    {entry.name}&nbsp;
                    <FontAwesomeIcon
                      style={{ color: "var(--primary)" }}
                      icon={faInfoCircle}
                      size="1x"
                    />
                    {handleWizardShortcut(entry)}
                  </span>
                </Tippy>

              </label>
              <div className="dynamic-fields col-8">

                {entry.customFieldValues!.map((field, findex) => (
                  field.displayType === 'TwoOption' &&
                  <FormGroup key={field.id}>
                    <div className="kreston-regnskab-switch custom-switch pt-2 ">
                      <Input
                        id={`entry;${entry.id};field;${field.id}`}
                        className="toggle"
                        type="checkbox"
                        name={`entry;${entry.id};field;${field.id}`}
                        checked={isChecked(field)}
                        onChange={e => handleChange(e, field, entry.id)}
                        disabled={isDisabled(entry)}
                      ></Input>

                      <Tippy placement="top" content={isDisabled(entry) == true ? entry.name + " er godkendt" : field.displayText} theme={isDisabled(entry) == true ? "approved" : ""}>
                        <label htmlFor={`entry;${entry.id};field;${field.id}`}>
                          <span className="on">Ja&nbsp;&nbsp;</span>
                          <span className="off">Nej</span>
                        </label>
                      </Tippy>
                    </div>
                  </FormGroup>
                ))
                }
              </div>
            </div>
          </div>
        ))
        }
      </form>
    </div >
  );

  function isChecked(field: CustomFieldValues): boolean {
    if (typeof field.value === "boolean") {
      return field.value;
    }
    if (field.value === "true") {
      return true;
    }
    return false;
  }
}
