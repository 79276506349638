import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TranslatableText from "../../../../Language/TranslatableText";
import {
  faArrowAltCircleLeft,
  faEdit,
  faTrash,
  faCloudDownloadAlt,
  faInfoCircle
} from "@fortawesome/pro-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { getCompanyByIdAPI } from "./companyAPI";
import Gap from "../../../../components/gap/Gap";
import Accent from "../../../../components/accent/Accent";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import {
  getReportTemplatesOfCompanyByIdAPI,
  deleteReportTemplateByIdAPI
} from "../wizard/wizardAPI";
import { getOrganizationProjectsAPI } from "../projects/projectsAPI";
import { toast } from "react-toastify";
import {
  faBoxFull,
  faChartNetwork,
  faUsers
} from "@fortawesome/pro-regular-svg-icons";
import { getTenantCustomEntitiesByOrganizationIdAPI } from "../../../company-admin/views/custom-entities/tenantCustomEntitiesAPI";
import { getCompanyUsersByIdAPI } from "../../../partner/views/users/partnerUsersAPI";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading, ScreenLoading } from "../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerDetails: {},
      loading: false,
      tableLoader: false,
      users: [],
      userColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name", //res[0].systems.map(row => row.name)
          text: "Name",
          sort: true
        },
        {
          dataField: "phone",
          text: "Phone ",
          sort: true
        },
        {
          dataField: "email",
          text: "Email ",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/company/user/update/${row.id}/${this.props.match.params.id}`}
                >
                  <Tippy placement="top" content="Edit">
                    <span>
                      <FontAwesomeIcon icon={faEdit} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
              </div>
            );
          }
        }
      ],
      templates: [],
      templateColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "description",
          text: "Description",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ],
      entities: [],
      entitiesColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
          formatter: (cell, row) => {
            return (
              <>
                {row.platormEntity ? (
                  <Tippy placement="top" content="Platform Module">
                    <div className="inline-block">
                      <span className="mr-10">
                        <FontAwesomeIcon icon={faChartNetwork} />
                      </span>
                      <span>{row.name}</span>
                    </div>
                  </Tippy>
                ) : (
                  <div>
                    <span className="width-20px mr-10"></span>
                    <span>{row.name}</span>
                  </div>
                )}
              </>
            );
          }
        },
        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/company/module/details/${row.id}/${this.props.match.params.id}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
                {!row.platormEntity ? (
                  <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </NavLink>
                ) : (
                  ""
                )}
              </div>
            );
          }
        }
      ],
      projects: [],
      projectsColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/company/project/details/${row.id}/${this.props.match.params.id}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
              </div>
            );
          }
        }
      ],
      deleteLoader: false,
      deleteTemplateModal: false,
      reportTemplateId: null
    };
  }
  componentDidMount() {
    console.log("id", this.props.match.params.id);
    this.setState({ loading: true });
    getCompanyByIdAPI(this.props.match.params.id)
      .then(res => {
        this.setState({ partnerDetails: res.data, loading: false }, () => {});
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
    this.populateProjects();
    this.populateCompanyUsers();
    this.populateReportTemplates();
    this.populateTenantCustomEntities();
  }

  populateCompanyUsers = () => {
    this.setState({ tableLoader: true, users: [] });
    getCompanyUsersByIdAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ users: res, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  populateProjects = () => {
    this.setState({ tableLoader: true, projects: [] });
    getOrganizationProjectsAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ projects: res, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  populateTenantCustomEntities = () => {
    this.setState({ tableLoader: true, entities: [] });
    getTenantCustomEntitiesByOrganizationIdAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ entities: res, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  populateReportTemplates = () => {
    var self = this;
    this.setState({ tableLoader: true, templates: [] });
    getReportTemplatesOfCompanyByIdAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink>
                <Tippy placement="top" content="Download Template">
                  <span>
                    <FontAwesomeIcon icon={faCloudDownloadAlt} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink
                tag={Link}
                to={`/admin/company/template/update/${self.props.match.params.id}/${el.id}`}
              >
                <Tippy placement="top" content="Update Template">
                  <span>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink onClick={e => self.openDeleteTemplateModal(el.id, e)}>
                <Tippy placement="top" content="Delete Template">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
          return o;
        });
        this.setState({
          templates: data,
          language: this.context.language,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeleteTemplatePopup = () => {
    this.setState(
      prevState => ({
        deleteTemplateModal: !prevState.deleteTemplateModal
      }),
      () => {
        if (this.state.deleteTemplateModal === false) {
          this.setState({
            reportTemplateId: null
          });
          console.log("After Modal Dismissal", this.state.deleteTemplateModal);
        }
      }
    );
  };

  openDeleteTemplateModal = id => {
    this.setState({
      deleteTemplateModal: true,
      reportTemplateId: id
    });
  };

  deleteReportTemplate = id => {
    this.setState({ deleteLoader: true });
    deleteReportTemplateByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populateReportTemplates();
        this.setState({
          deleteTemplateModal: false,
          deleteLoader: false,
          reportTemplateId: null
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteTemplateModal: false,
          deleteLoader: false,
          reportTemplateId: null
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let { loading, tableLoader, users, userColumns } = this.state;
    return (
      <div className="company-details-view">
        {loading && (
          <ScreenLoading />
        )}
        <div className="relative flex flex-align space-between">
          <h1>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              size="1x"
              onClick={this.goBack}
              className="cursor"
            />{" "}
            Company Information
          </h1>
          <NavLink
            className="edit"
            tag={Link}
            to={`/admin/company/update/${this.props.match.params.id}`}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </NavLink>
        </div>

        <Accent logo={this.state.partnerDetails.logo}>
          {this.state.partnerDetails.name}
        </Accent>
        <Gap size="50px" />
        <Row>
          <Col md={12}>
            <Table>
              <thead>
                <tr>
                  <th>Element</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(this.state.partnerDetails)
                  .filter(function (el) {
                    if (
                      el === "id" ||
                      el === "systemIds" ||
                      el === "logo" ||
                      el === "releasePhase" ||
                      el === "partnerId" ||
                      el === "name" ||
                      el === "phone" ||
                      el === "email" ||
                      el === "postalCode" ||
                      el === "dbConnectionString" ||
                      el === "isTemplate" ||
                      el === "accountRepresentatives"
                    ) {
                      return false; // skip
                    }
                    return true;
                  })
                  .map(key => (
                    <tr key={key}>
                      <td className="text-capitalize">{key}</td>
                      <td>{this.state.partnerDetails[key]}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Accent useIcon={true} icon={faUsers}>
              Users
            </Accent>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={users}
                columns={userColumns}
                search
                exportCSV={{
                  fileName: "data.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              CSV
                            </ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/admin/company/user/create/${this.props.match.params.id}`}
                            >
                              Create
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                      pagination={paginationFactory()}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Accent useIcon={true} icon={faBoxFull}>
              Projects
            </Accent>
          </Col>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={this.state.projects}
                columns={this.state.projectsColumns}
                search
                exportCSV={{
                  fileName: "custom.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              CSV
                            </ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          {this.props.match.params.partnerId !== undefined ? (
                            <div>
                              <NavLink
                                className="button-primary"
                                tag={Link}
                                to={`/admin/company/project/add/${this.props.match.params.id}/${this.props.match.params.partnerId}`}
                              >
                                Create
                              </NavLink>
                            </div>
                          ) : (
                            <div>
                              <NavLink
                                className="button-primary"
                                tag={Link}
                                to={`/admin/company/project/add/${this.props.match.params.id}`}
                              >
                                Create
                              </NavLink>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                      pagination={paginationFactory()}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteTemplateModal}
            toggle={this.toggleDeleteTemplatePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteTemplatePopup}>
              <TranslatableText translationKey="confirm_delete" />
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="company.confirm_delete_template" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e =>
                    this.deleteReportTemplate(this.state.reportTemplateId, e)
                  }
                >
                  Delete
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeleteTemplatePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanyDetails);
CompanyDetails.contextType = GlobalContext;
