import React, { Component } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  NavLink
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  addProcessTranslationAPI,
  getSingleTranslationAPI,
  updateProcessTranslationAPI,
  getProcessTranslationsAPI,
  getProcessBaseTranslationsByIdAPI
} from "./companyProcessAPI";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import { Config } from "../../../../config/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import Accent from "../../../../components/accent/Accent";
import { getTextTranslationAPI } from "../../../../apiClients";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../components";



class AddCompanyProcessTranslation extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "nameShort",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "descriptionShort",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      nameShort: "",
      nameLong: "",
      descriptionShort: "",
      descriptionLong: "",
      language: "",
      loading: false,
      cuLoader: false,
      validation: this.validator.valid(),
      name: "",
      translationObject: [],
      translateLoader: false,
      translating: {
        nameShort: false,
        nameLong: false,
        descriptionShort: false,
        descriptionLong: false
      },
      languageDropdown: Config.languages,
      baseLanguageDropdown: Config.languages,
      baseTranslation: {
        nameShort: false,
        nameLong: false,
        descriptionShort: "",
        descriptionLong: "",
        language: ""
      }
    };

    this.submitted = false;
  }
  componentDidMount() {
    if (this.props.match.params.processId !== undefined) {
      var selected_languages,
        available_languages = [];
      getProcessBaseTranslationsByIdAPI(this.props.match.params.processId)
        .then(res => {
          this.setState({
            baseTranslation: res,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });

      if (this.props.match.params.translationId === undefined) {
        getProcessTranslationsAPI(this.props.match.params.processId)
          .then(res => {
            selected_languages = res.data.map(el => {
              return el.language;
            });
            available_languages = this.state.languageDropdown.filter(el => {
              if (selected_languages.includes(el.value)) {
                return false; // skip
              }
              return true;
            });
            this.setState({
              languageDropdown: available_languages
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
          });
      }
    }
    if (this.props.match.params.translationId !== undefined) {
      this.setState({ loading: true });
      getSingleTranslationAPI(this.props.match.params.translationId)
        .then(res => {
          this.setState({
            nameShort: res.nameShort,
            nameLong: res.nameLong,
            descriptionShort: res.descriptionShort,
            descriptionLong: res.descriptionLong,
            language: res.language,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }
  goBack = () => {
    this.props.history.goBack();
  };

  addTranslation = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      ...this.state,
      processId:
        this.props.match.params.processId !== undefined
          ? this.props.match.params.processId
          : "",
      userId: this.context.user.organizations.userId,
      id:
        this.props.match.params.translationId !== undefined
          ? this.props.match.params.translationId
          : ""
    };
    if (this.props.match.params.translationId === undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        addProcessTranslationAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateProcessTranslationAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  translateSingleField = field => {
    this.setState(prevState => ({
      translating: {
        ...prevState.translating,
        [field]: true
      }
    }));

    getTextTranslationAPI({
      to: this.state.language,
      text: this.state.baseTranslation[field]
    })
      .then(res => {
        this.setState(prevState => ({
          [field]: res.data,
          translating: {
            ...prevState.translating,
            [field]: false
          }
        }));
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState(prevState => ({
          translating: {
            ...prevState.translating,
            [field]: false
          }
        }));
      });
  };

  translateFields = () => {
    let self = this;
    Object.keys(this.state.baseTranslation).forEach(function (item) {
      console.log(item); // key
      console.log(self.state.baseTranslation[item]); // value
      if (
        item !== "id" &&
        item !== "language" &&
        item !== "templateRegardingObjectTypeId"
      ) {
        self.translateSingleField(item);
      }
    });
  };

  render() {
    let { loading, name } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="process-translation-view">
        {loading && (
          <ScreenLoading />
        )}
        <Row>
          <Col md={12}>
            <Accent useIcon={false} left={false}>
              {this.props.match.params.translationId !== undefined
                ?  <TranslatableText translationKey="update" />
                : <TranslatableText translationKey="create" />}{" "}
              <TranslatableText translationKey="company.translation_for" />{" "}
              {this.state.baseTranslation.nameShort}
            </Accent>
          </Col>
        </Row>

        <Row className="panel">
          <Col md={12}>
            <Row>
              <Col md={6} className="panel-header">
                <span className="capitalize">
                  <TranslatableText translationKey="company.translate_from" />{" "}
                  {this.state.baseTranslation.language}
                </span>
              </Col>
              <Col md={6} className="panel-header space-between">
                <span className="capitalize">Translate To</span>
                <Button
                  disabled={this.state.language === "" ? true : null}
                  outline
                  onClick={e => this.translateFields(e)}
                >
                  <TranslatableText translationKey="company.translate_to" />{" "}
                  {this.state.language
                    ? this.state.languageDropdown.find(
                        sel => sel.value === this.state.language
                      )
                      ? this.state.languageDropdown.find(
                          sel => sel.value === this.state.language
                        ).name
                      : ""
                    : "Select"}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col className="panel-separator" md={6}>
            <Row form>
              <Col md={12}>
                <FormGroup
                  className={validation.language.isInvalid ? "has-error" : ""}
                >
                  <Label for="base_language">
                    <TranslatableText translationKey="language" />
                  </Label>
                  <Input
                    type="select"
                    name="base_language"
                    id="base_language"
                    value={this.state.baseTranslation.language}
                    disabled={true}
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.baseLanguageDropdown.map((e, key) => {
                      return (
                        <option key={key} value={e.value}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Input>
                  <span className="help-block">
                    {validation.language.message}
                  </span>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup
                  className={validation.nameShort.isInvalid ? "has-error" : ""}
                >
                  <Label for="base_nameShort">
                    <TranslatableText translationKey="name" />
                  </Label>
                  <Input
                    type="text"
                    name="base_nameShort"
                    id="base_nameShort"
                    placeholder=""
                    disabled={true}
                    value={this.state.baseTranslation.nameShort}
                  />
                  <span className="help-block">
                    {validation.nameShort.message}
                  </span>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="base_descriptionShort">
                    <TranslatableText translationKey="description" />
                  </Label>
                  <Input
                    type="textarea"
                    name="base_descriptionShort"
                    id="base_descriptionShort"
                    disabled={true}
                    value={this.state.baseTranslation.descriptionShort}
                  />
                </FormGroup>
              </Col>
              <Col className="space-between flex mtb-10" md={12}>
                <Button onClick={this.goBack}>
                  <TranslatableText translationKey="back" />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Form onSubmit={this.addTranslation}>
              <Row form>
                <Col md={12}>
                  <FormGroup
                    className={validation.language.isInvalid ? "has-error" : ""}
                  >
                    <Label for="language">
                      <TranslatableText translationKey="language" />
                    </Label>
                    <Input
                      type="select"
                      name="language"
                      id="language"
                      value={this.state.language}
                      onChange={this.handleChange}
                      disabled={
                        this.props.match.params.translationId !== undefined
                          ? true
                          : null
                      }
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.languageDropdown.map((e, key) => {
                        return (
                          <option key={key} value={e.value}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                    <span className="help-block">
                      {validation.language.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup
                    className={
                      validation.nameShort.isInvalid ? "has-error" : ""
                    }
                  >
                    <Label className="flex space-between" for="nameShort">
                      <span className="flex verticle-flex-end">
                        <TranslatableText translationKey="name" />
                      </span>
                      {this.state.translating.nameShort === false ? (
                        <NavLink
                          className="no-padding translate"
                          onClick={e =>
                            this.translateSingleField("nameShort", e)
                          }
                          disabled={this.state.language === "" ? true : null}
                        >
                          <Tippy placement="top" content="Translate">
                            <span>
                              <FontAwesomeIcon
                                style={{ color: "var(--primary)" }}
                                icon={faGlobe}
                                size="1x"
                              />
                            </span>
                          </Tippy>
                        </NavLink>
                      ) : (
                        <InlineLoading />
                      )}
                    </Label>
                    <Input
                      type="text"
                      name="nameShort"
                      id="nameShort"
                      placeholder=""
                      value={this.state.nameShort}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.nameShort.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label
                      className="flex space-between"
                      for="descriptionShort"
                    >
                      <span className="flex verticle-flex-end">
                        <TranslatableText translationKey="description" />
                      </span>
                      {this.state.translating.descriptionShort === false ? (
                        <NavLink
                          className="no-padding translate"
                          onClick={e =>
                            this.translateSingleField("descriptionShort", e)
                          }
                          disabled={this.state.language === "" ? true : null}
                        >
                          <Tippy placement="top" content="Translate">
                            <span>
                              <FontAwesomeIcon
                                style={{ color: "var(--primary)" }}
                                icon={faGlobe}
                                size="1x"
                              />
                            </span>
                          </Tippy>
                        </NavLink>
                      ) : (
                        <InlineLoading />
                      )}
                    </Label>
                    <Input
                      type="textarea"
                      name="descriptionShort"
                      id="descriptionShort"
                      value={this.state.descriptionShort}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="flex-end flex mtb-10" md={12}>
                  {this.state.cuLoader === false ? (
                    <Button>
                      {this.props.match.params.translationId !== undefined
                      ?  <TranslatableText translationKey="update" />
                      : <TranslatableText translationKey="create" />}
                      {" "} Translation
                    </Button>
                  ) : (
                    <InlineLoading />
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(AddCompanyProcessTranslation);
AddCompanyProcessTranslation.contextType = GlobalContext;