import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPowerOff, faBuilding, faRocket, faUserNinja } from '@fortawesome/pro-solid-svg-icons';
import TranslatableText from '../../../Language';
import { usePlatformUserData } from '../../../contexts';
// import preval from 'preval.macro';
import SecuredComponent from '../../secured-component/SecuredComponent';
import { AccountLevel } from '../../../config/Levels';
import { GetUserOrganizationsResponse, OrganizationsClient } from '../../../apiClients';
import { toast } from 'react-toastify';
import { PartnerMenuBtn } from './PartnerMenuBtn';
import { NavMenuFavoriteProjects } from './NavMenuFavoriteProjects';
import { Config } from '../../../config/Config';

type NavMenuSettingsProps = {
    settingsOpen: boolean;
    setSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    navigateTo: (view: string) => void;
    favoriteOrganizations: GetUserOrganizationsResponse;
    favoritePartners: GetUserOrganizationsResponse;
}

export function NavMenuSettingsDropdown(props: NavMenuSettingsProps) {

    const { logout, user } = useAuth0();
    const currentOrgData = usePlatformUserData();

    const _OrganizationsClient = new OrganizationsClient();
    function changeProject(organizationId: string) {
        _OrganizationsClient.changeProject(organizationId)
            .then(() => {
                // After Switching Organization - reload everything
                window.location.replace("/");
            })
            .catch(error => {
                toast.error(error.message);
            });
    }

    if (user && user["https://360-advisor.com/otp"] === "true") {
        return <NavItem>
            <Dropdown
                isOpen={props.settingsOpen}
                toggle={() => props.setSettingsOpen(!props.settingsOpen)}
            >
                <DropdownToggle caret>
                    <FontAwesomeIcon icon={faCog} />
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={() => logout({ returnTo: window.location.origin })}>
                        <FontAwesomeIcon icon={faPowerOff} color="var(--red)" />&nbsp;<TranslatableText translationKey="log_out" />
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </NavItem>;
    }

    return <NavItem>
        <Dropdown
            isOpen={props.settingsOpen}
            toggle={() => props.setSettingsOpen(!props.settingsOpen)}
        >
            <DropdownToggle caret>
                <FontAwesomeIcon icon={faCog} />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem onClick={() => props.navigateTo("/settings?tab=1")}>
                    <FontAwesomeIcon icon={faCog} /> <TranslatableText translationKey="settings" />
                </DropdownItem>
                <DropdownItem onClick={() => logout({ returnTo: window.location.origin })}>
                    <FontAwesomeIcon icon={faPowerOff} color="var(--red)" />&nbsp;<TranslatableText translationKey="log_out" />
                </DropdownItem>
                <DropdownItem divider />
                {currentOrgData.currentOrgData.systemSettings.enable_compane_admin && (
                    <DropdownItem onClick={() => props.navigateTo("/company-admin")}>
                        <FontAwesomeIcon icon={faBuilding} />&nbsp;<TranslatableText translationKey="company" /> <TranslatableText translationKey="settings" />
                    </DropdownItem>
                )}
                {!currentOrgData.currentOrgData.systemSettings.enable_compane_admin && (
                    <DropdownItem onClick={() => props.navigateTo("/settings?tab=1")}>
                        <FontAwesomeIcon icon={faBuilding} />&nbsp;<TranslatableText translationKey="companies" />
                    </DropdownItem>
                )}
                {NavMenuFavoriteProjects(currentOrgData.currentOrgData.organizationId, props.favoriteOrganizations.organizations!, changeProject, props.navigateTo)}
                {PartnerMenuBtn(props.navigateTo, props.favoritePartners)}
                <DropdownItem divider />
                <DropdownItem disabled>
                    <FontAwesomeIcon icon={faRocket} />&nbsp;%%BUILD_DATE%%.
                </DropdownItem>
                <SecuredComponent accountLevel={AccountLevel.Developer}>
                    <DropdownItem onClick={() => props.navigateTo("/admin")}>
                        <FontAwesomeIcon icon={faUserNinja} />&nbsp;<TranslatableText translationKey="admin" />
                    </DropdownItem>
                    <DropdownItem onClick={() => props.navigateTo("/entities/dpo_dataProcessor")}>
                        <FontAwesomeIcon icon={faUserNinja} />&nbsp;<TranslatableText translationKey="DPO - Ny databehandler" />
                    </DropdownItem>
                </SecuredComponent>
            </DropdownMenu>
        </Dropdown>
    </NavItem>;
}
