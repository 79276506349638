import { faCheckCircle, faCloudDownloadAlt, faFileArrowDown, faFileCode, faHatWizard, faInfoCircle, faPaperclip, faPaperPlane, faPencilAlt, faPlay, faProjectDiagram, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from "@tippyjs/react";
import localeDa from 'dayjs/locale/da';
import { PureComponent } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Link } from 'react-router-dom';
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, NavLink, Row } from "reactstrap";
import { ApproveTemplateAnswersClient, ApproveTemplateAnswersRequest, DeleteMultipleProcessesRequest, GetAllSectionProcessesResponse, GetSectionProcessResponse, IGetUserDefaultOrganizationResponse, ProcessesClient, ReportsClient, UpdateMultipleProcessesRequest } from '../../../apiClients';
import HandleCaught_API_Error from '../../../apiClients/api_caughtError';
import Accent from '../../../components/accent/Accent';
import { AccountLevel, Actions } from '../../../config/Levels';
import TranslatableText from "../../../Language/TranslatableText";
import { triggerApprovalFlowAPI } from '../../company-admin/views/approvals-flow/approvalsFlowAPI';
import { deleteProcessByIdAPI, getJsonSpecForReferenceObj } from '../../company-admin/views/process/companyProcessAPI';


type UserListForDropdown = {
    label: string;
    value: string;
}
interface IProps {
    data: GetAllSectionProcessesResponse,
    userData: IGetUserDefaultOrganizationResponse,
    usersDropdown: UserListForDropdown[],
    index: number,
    entityReport: string,
}
interface IState {
    deleteModal: boolean,
    responsibleModal: boolean,
    deleteMultipleModal: boolean,
    approveTemplateAnswersModal: boolean,
    processId: string | undefined,
    columns: any,
    responsibleUser: string,
    checkedProcesses: any[],
    deselectAll: boolean
}

function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <TranslatableText translationKey={column.text} /> {sortElement}
            {filterElement}
        </div>
    );
}

class SectionWithProcessList extends PureComponent<IProps, IState>  {
    constructor(props: IProps) {
        super(props);
        this.state = {
            deleteModal: false,
            responsibleModal: false,
            deleteMultipleModal: false,
            approveTemplateAnswersModal: false,
            processId: undefined,
            checkedProcesses: [],
            responsibleUser: "",
            deselectAll: false,
            columns: [
                {
                    dataField: "id",
                    text: "ID",
                    hidden: true
                },
                {
                    dataField: "nameShort",
                    text: "processes.table_header_process",
                    sort: true,
                    headerFormatter: headerFormatter,
                    headerStyle: () => {
                        return { width: "44%" };
                    },
                },
                {
                    dataField: "reportName",
                    text: "responsible",
                    headerFormatter: headerFormatter,
                    sort: true,
                    headerStyle: () => {
                        return { width: "18%" };
                    },
                },
                {
                    dataField: "status",
                    text: "Status",
                    headerFormatter: headerFormatter,
                    sort: true,
                    hidden: this.props.userData.system !== 'KD-Sys 360',
                    headerStyle: () => {
                        return { width: "10%" };
                    },
                    formatter: (cell: any, row: GetSectionProcessResponse) => {
                        if (row.state?.toLowerCase() === 'area') {
                            return (
                                <div className='center'>
                                    <Tippy placement="top" content="Afventer Godkendelse">
                                        <span>
                                            <FontAwesomeIcon icon={faPaperPlane} size="1x" />
                                        </span>
                                    </Tippy>
                                </div>
                            )
                        }
                        if (row.state?.toLowerCase() === 'good') {
                            return (
                                <div className='actions center'>
                                    <Tippy placement="top" content="Godkendt">
                                        <span className='mr-3'>
                                            <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                                        </span>
                                    </Tippy>
                                    {row.flowEngine ? (
                                        <NavLink
                                            onClick={e =>
                                                this.triggerApprovalFlow(
                                                    row.regardingObjectTypeId,
                                                    row.id,
                                                    Actions.onUserRequest
                                                )
                                            }
                                        >
                                            <Tippy placement="top" content="Start ny godkendelse">
                                                <span>
                                                    <FontAwesomeIcon icon={faPlay} size="1x" />
                                                </span>
                                            </Tippy>
                                        </NavLink>
                                    ) : (
                                        <NavLink>
                                            <Tippy placement="top" content="Ingen godkendelse">
                                                <span>
                                                    <FontAwesomeIcon
                                                        style={{ color: "#ccc" }}
                                                        icon={faPlay}
                                                        size="1x"
                                                    />
                                                </span>
                                            </Tippy>
                                        </NavLink>
                                    )}
                                </div>
                            )
                        }
                        if (row.state === 'red') {
                            return (
                                <div></div>
                            )
                        }
                        return ( //////////////////////////////////
                            <div className='center'>
                                {row.flowEngine ? (
                                    <NavLink
                                        onClick={e =>
                                            this.triggerApprovalFlow(
                                                row.regardingObjectTypeId,
                                                row.id,
                                                Actions.onUserRequest
                                            )
                                        }
                                    >
                                        <Tippy placement="top" content="Start ny godkendelse">
                                            <span>
                                                <FontAwesomeIcon icon={faPlay} size="1x" />
                                            </span>
                                        </Tippy>
                                    </NavLink>
                                ) : (
                                    <NavLink>
                                        <Tippy placement="top" content="Ingen godkendelse">
                                            <span>
                                                <FontAwesomeIcon
                                                    style={{ color: "#ccc" }}
                                                    icon={faPlay}
                                                    size="1x"
                                                />
                                            </span>
                                        </Tippy>
                                    </NavLink>
                                )}
                            </div>
                        )
                    }
                },
                {
                    dataField: "lastUpdated",
                    text: "processes.table_header_last_updated",
                    headerFormatter: headerFormatter,
                    sort: true,
                    formatter: this.dateFormatter,
                    headerStyle: () => {
                        return { width: "16%" };
                    },
                },
                {
                    dataField: "actions",
                    text: "actions",
                    headerFormatter: headerFormatter,
                    csvExport: false,
                    headerStyle: () => {
                        if (this.props.userData.system === 'KD-Sys 360') {
                            return { width: "10%" };
                        }
                        return { width: "23%" };
                    },
                    formatter: (cell: any, row: GetSectionProcessResponse) => {
                        if (row.state === 'red') {
                            return (
                                <div className="actions">
                                    {/* <NavLink><FontAwesomeIcon icon={faPaperclip} style={{ color: "#ccc" }}/></NavLink>
                                    <NavLink><FontAwesomeIcon icon={faPlay} style={{ color: "#ccc" }}/></NavLink>
                                    <NavLink><FontAwesomeIcon icon={faProjectDiagram} style={{ color: "#ccc" }}/></NavLink>
                                    <NavLink><FontAwesomeIcon icon={faInfoCircle} style={{ color: "#ccc" }}/></NavLink>
                                    <NavLink><FontAwesomeIcon icon={faCloudDownloadAlt} style={{ color: "#ccc" }}/></NavLink>
                                    <NavLink><FontAwesomeIcon icon={faPencilAlt} style={{ color: "#ccc" }}/></NavLink>
                                    <NavLink><FontAwesomeIcon icon={faTrash} style={{ color: "#ccc" }}/></NavLink> */}
                                </div>
                            )
                        }
                        if (this.props.userData.system === 'KD-Sys 360') {

                            return (
                                <div className="actions">
                                    <NavLink
                                        tag={Link}
                                        to={`processes/attachments/${row.id}`}
                                    >
                                        {row.attachmentExists &&
                                            <FontAwesomeIcon icon={faPaperclip} size="1x" />
                                        }
                                        {!row.attachmentExists &&
                                            <FontAwesomeIcon icon={faPaperclip} style={{ color: "#cccccc" }} size="1x" />
                                        }
                                    </NavLink>
                                    <NavLink
                                        tag={Link}
                                        to={`processes/wizard/${row.wizardId}/${row.id}/${row.regardingObjectTypeId}`}
                                    >
                                        <Tippy placement="top" content="Edit">
                                            <span>
                                                <FontAwesomeIcon icon={faHatWizard} size="1x" />
                                            </span>
                                        </Tippy>
                                    </NavLink>
                                    {(AccountLevel.Developer <= this.props.userData.accountLevel) && (
                                        <NavLink onClick={e => this.getReferenceObjectJson(row.id!, row.regardingObjectTypeId!)}>
                                            <Tippy placement="top" content="JSON Spec.">
                                                <span>
                                                    <FontAwesomeIcon icon={faFileCode} />
                                                </span>
                                            </Tippy>
                                        </NavLink>
                                    )}
                                </div>
                            );
                        }
                        return (
                            <div className="actions">
                                <NavLink
                                    tag={Link}
                                    to={`processes/attachments/${row.id}`}
                                >
                                    {row.attachmentExists &&
                                        <FontAwesomeIcon icon={faPaperclip} size="1x" />
                                    }
                                    {!row.attachmentExists &&
                                        <FontAwesomeIcon icon={faPaperclip} style={{ color: "#cccccc" }} size="1x" />
                                    }


                                </NavLink>
                                {row.flowEngine ? (
                                    <NavLink
                                        onClick={e =>
                                            this.triggerApprovalFlow(
                                                row.regardingObjectTypeId,
                                                row.id,
                                                Actions.onUserRequest
                                            )
                                        }
                                    >
                                        <Tippy placement="top" content="Trigger Flow">
                                            <span>
                                                <FontAwesomeIcon icon={faPlay} size="1x" />
                                            </span>
                                        </Tippy>
                                    </NavLink>
                                ) : (
                                    <NavLink>
                                        <Tippy placement="top" content="No Flow Attached">
                                            <span>
                                                <FontAwesomeIcon
                                                    style={{ color: "#ccc" }}
                                                    icon={faPlay}
                                                    size="1x"
                                                />
                                            </span>
                                        </Tippy>
                                    </NavLink>
                                )}
                                <NavLink
                                    tag={Link}
                                    to={`/company-admin/flow-engine/${row.regardingObjectTypeId}/${row.id}`}
                                >
                                    <FontAwesomeIcon icon={faProjectDiagram} size="1x" />
                                </NavLink>
                                <NavLink
                                    tag={Link}
                                    to={`processes/details/${row.id}`}
                                >
                                    <Tippy placement="top" content="See Details">
                                        <span>
                                            <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                                        </span>
                                    </Tippy>
                                </NavLink>
                                <NavLink
                                    onClick={e =>
                                        this.downloadFile(
                                            row.id!,
                                            row.regardingObjectTypeId!,
                                            row.nameShort!
                                        )
                                    }
                                >
                                    <Tippy placement="top" content="Download">
                                        <span>
                                            <FontAwesomeIcon
                                                style={{ color: "var(--primary)" }}
                                                icon={faCloudDownloadAlt}
                                                size="1x"
                                            />
                                        </span>
                                    </Tippy>
                                </NavLink>
                                <NavLink
                                    tag={Link}
                                    to={`processes/wizard/${row.wizardId}/${row.id}/${row.regardingObjectTypeId}`}
                                >
                                    <Tippy placement="top" content="Edit">
                                        <span>
                                            <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                                        </span>
                                    </Tippy>
                                </NavLink>
                                {(AccountLevel.Partner <= this.props.userData.accountLevel) && (
                                    <>
                                        <NavLink onClick={e => this.getReferenceObjectJson(row.id!, row.regardingObjectTypeId!)}>
                                            <Tippy placement="top" content="JSON Spec.">
                                                <span>
                                                    <FontAwesomeIcon icon={faFileCode} />
                                                </span>
                                            </Tippy>
                                        </NavLink>
                                        {this.props.entityReport && (
                                        <NavLink
                                            onClick={e =>
                                                this.downloadFileById(
                                                    row.id!,
                                                    row.regardingObjectTypeId!,
                                                    this.props.entityReport,
                                                    row.nameShort!
                                                )
                                            }
                                        >
                                            <Tippy placement="top" content="Download Question & Answers">
                                                <span>
                                                    <FontAwesomeIcon
                                                        style={{ color: "var(--primary)" }}
                                                        icon={faFileArrowDown}
                                                        size="1x"
                                                    />
                                                </span>
                                            </Tippy>
                                        </NavLink>)}
                                    </>
                                )}
                                <NavLink onClick={e => this.openDeleteModal(row.id!)}>
                                    <Tippy placement="top" content="Remove">
                                        <span>
                                            <FontAwesomeIcon
                                                style={{ color: "#d84a4a" }}
                                                icon={faTrash}
                                                size="1x"
                                            />
                                        </span>
                                    </Tippy>
                                </NavLink>
                            </div>
                        );
                    }
                },
                {
                    dataField: "checkbox",
                    text: "",
                    sort: false,
                    headerStyle: () => {
                        return { width: "5%" };
                    },
                    formatter: (cell: any, row: GetSectionProcessResponse) => {
                        return (
                            <div className="mb-4 ml-4">
                                <Input type="checkbox" className={'section-' + row.sectionId} onChange={() => this.handleCheckbox(row!)} />
                            </div>
                        )
                    },
                    hidden: !(AccountLevel.Partner <= this.props.userData.accountLevel)
                },
            ]
        };
    }

    render() {
        return (
            <div className="processes-section">
                <div key={this.props.index}>
                    <Accent useHtmlIcon={true} iconName={this.props.data.icon}>
                        {this.props.data.nameShort}
                    </Accent>

                    <div className="table-container">
                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            data={this.props.data.processes!}
                            columns={this.state.columns}
                        >
                            {props => (<>
                                <BootstrapTable
                                    striped
                                    hover
                                    rowClasses={this.rowStyling}
                                    {...props.baseProps}
                                />

                            </>
                            )}
                        </ToolkitProvider>
                        {(AccountLevel.Partner <= this.props.userData.accountLevel) ? (
                            <>
                                {!this.state.deselectAll &&
                                    <Button className="float-right mr-1" outline color="primary" size="sm" onClick={() => this.handleSelectAll(this.props.data.processes![0].sectionId)}>
                                        <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                                        <TranslatableText translationKey="processes.select_all" />
                                    </Button>
                                }
                                {this.state.deselectAll &&
                                    <Button className="float-right mr-1" outline color="primary" size="sm" onClick={() => this.handleDeselectAll(this.props.data.processes![0].sectionId)}>
                                        <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                                        <TranslatableText translationKey="processes.deselect_all" />
                                    </Button>
                                }
                                <Button className="float-right mr-1" outline color="success" size="sm" onClick={this.openResponsibleModal}>
                                    <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                                    <TranslatableText translationKey="processes.responsible" />
                                </Button>
                                {!(this.props.userData.system === 'KD-Sys 360') &&
                                    <>
                                        <Button className="float-right mr-1" outline color="warning" size="sm" onClick={this.openDeleteMultipleModal}>
                                            <FontAwesomeIcon icon={faTrash} size="1x" />
                                            <TranslatableText translationKey="processes.remove_selected_activities" />
                                        </Button>
                                        <Button className="float-right mr-1" outline color="primary" size="sm" onClick={this.handleApproveTemplateAnswersSubmit}>
                                            <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                                            <TranslatableText translationKey="processes.accept_standard" />
                                        </Button>
                                    </>
                                }
                            </>
                        ) : (
                            <></>
                        )}
                        <br />
                    </div>
                </div>

                <Modal
                    isOpen={this.state.deleteModal}
                    toggle={this.toggleDeletePopup}
                >
                    <ModalHeader toggle={this.toggleDeletePopup}>
                        <TranslatableText translationKey="confirm_delete" />
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col md={12}>
                                {this.state.checkedProcesses.length > 0 &&
                                    <p><TranslatableText translationKey="confirm_delete_multiple_processes" /></p>
                                }
                                {this.state.checkedProcesses.length == 0 &&
                                    <p><TranslatableText translationKey="confirm_delete_process" /></p>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.checkedProcesses.length > 0 &&
                            <Button
                                outline
                                className="redOutline"
                                onClick={e => this.deleteMultipleProcesses(this.state.checkedProcesses)}
                            >
                                <TranslatableText translationKey="delete_all" />
                            </Button>
                        }
                        {this.state.checkedProcesses.length === 0 &&
                            <Button
                                outline
                                className="redOutline"
                                onClick={e => this.deleteProcess(this.state.processId!)}
                            >
                                <TranslatableText translationKey="delete" />
                            </Button>
                        }
                        <Button outline onClick={this.toggleDeletePopup}>
                            <TranslatableText translationKey="cancel" />
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.responsibleModal}
                    toggle={this.toggleResponsiblePopup}
                >
                    <ModalHeader toggle={this.toggleResponsiblePopup}>
                        <TranslatableText translationKey="processes.responsible" />
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col md={12}>
                                <TranslatableText translationKey="processes.choose_responsible" />
                                <br />
                                <Select
                                    options={this.props.usersDropdown}
                                    onChange={selectedOptions => this.setState({ responsibleUser: selectedOptions!.value })}
                                    hideSelectedOptions={false}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            outline
                            color="success"
                            onClick={e => this.submitMultipleResponsible()}
                        >
                            <TranslatableText translationKey="confirm" />
                        </Button>{" "}
                        <Button outline onClick={this.toggleResponsiblePopup}>
                            <TranslatableText translationKey="cancel" />
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }

    rowStyling = (row: GetSectionProcessResponse, rowIndex: number): string => {
        switch (row.state?.toLowerCase()) {
            case 'red': // Used in KD for signing that the process is not in use
                return 'table-secondary';
            case 'good': // Used in KD for signing that the process is not in use
                return 'table-success';
            case 'area': // Used in KD for signing that the process is not in use
                return 'table-info';
            default:
                return '';
        }
    };


    handleCheckbox = (row: GetSectionProcessResponse) => {
        if (!this.state.checkedProcesses.some(i => i.id === row.id)) { // add item to array, if it does not exists
            var joined = this.state.checkedProcesses.concat(row)
            this.setState({ checkedProcesses: joined })
        } else { // remove item
            let array = [...this.state.checkedProcesses];
            let i = array.indexOf(row.id);
            array.splice(i, 1);
            this.setState({ checkedProcesses: array })
        }
    }
    handleSelectAll = (sectionId: string) => {
        let processList: any[] = [];  // change type - processrequest object

        if (this.props.data.processes !== undefined) {
            // manipulate checkboxes "checked" value
            this.setState({ deselectAll: true })
            let checkboxes = document.getElementsByClassName('section-' + sectionId);
            for (var n = 0; n < checkboxes.length; n++) {
                let box = checkboxes[n] as HTMLInputElement;
                box.checked = true;
            }

            for (let i = 0; i < this.props.data.processes.length; i++) {
                processList.push(this.props.data.processes[i])
            }
            this.setState({ checkedProcesses: processList })
        }
    }
    handleDeselectAll = (sectionId: string) => {
        if (this.props.data.processes !== undefined) {
            // empty state array
            this.setState({ checkedProcesses: [] })
            // manipulate checkboxes "checked" value
            this.setState({ deselectAll: false })
            let checkboxes = document.getElementsByClassName('section-' + sectionId);
            for (var n = 0; n < checkboxes.length; n++) {
                let box = checkboxes[n] as HTMLInputElement;
                box.checked = false;
            }
        }
    }
    submitMultipleResponsible = () => {
        if (this.state.responsibleUser.length > 0) {
            let idList: string[] = [];
            for (let i = 0; i < this.state.checkedProcesses.length; i++) {
                idList.push(this.state.checkedProcesses[i].id)
            }
            let updateMultipleProcessesRequest = new UpdateMultipleProcessesRequest()
            updateMultipleProcessesRequest.idList = idList;
            updateMultipleProcessesRequest.responsibleId = this.state.responsibleUser;

            let processesClient = new ProcessesClient();

            processesClient.multiplePut(updateMultipleProcessesRequest)
                .then(res => {
                    this.setState({
                        responsibleModal: false,
                        processId: undefined
                    });
                    window.location.reload();
                })
                .catch(error => {
                    HandleCaught_API_Error(error)
                });
        } else {
            toast.error("No responsible user selected");
        }
    }
    downloadFile = (id: string, typeId: string, name: string) => {
        var downloadReportToast = toast.info(
            "Downloading report, please wait...",
            {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: false
            }
        );
        let reportClient = new ReportsClient();
        reportClient
            .downloadEntityReportGet(typeId, id)
            .then(res => {
                toast.dismiss(downloadReportToast);
                var response = res.data;
                const url = window.URL.createObjectURL(
                    new Blob([response], { type: res.headers!["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                let result = name.replaceAll(".", "");
                link.setAttribute("download", result); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                toast.dismiss(downloadReportToast);
                HandleCaught_API_Error(error);
            });
    };
    downloadFileById = (id: string, typeId: string, reportId: string, name: string) => {
        var downloadReportToast = toast.info(
            "Downloading report, please wait...",
            {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: false
            }
        );
        let reportClient = new ReportsClient();
        reportClient
            .downloadEntityReportGetById(typeId, id, reportId)
            .then(res => {
                toast.dismiss(downloadReportToast);
                var response = res.data;
                const url = window.URL.createObjectURL(
                    new Blob([response], { type: res.headers!["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                let result = name.replaceAll(".", "");
                link.setAttribute("download", result); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                toast.dismiss(downloadReportToast);
                HandleCaught_API_Error(error);
            });
    };
    toggleDeletePopup = () => {
        this.setState(
            prevState => ({
                deleteModal: !prevState.deleteModal
            }),
            () => {
                if (this.state.deleteModal === false) {
                    this.setState({
                        processId: undefined
                    });
                }
            }
        );
    };
    toggleResponsiblePopup = () => {
        this.setState(
            prevState => ({
                responsibleModal: !prevState.responsibleModal
            })
        );
    };
    openResponsibleModal = () => {
        if (this.state.checkedProcesses.length > 0) {
            this.setState({
                responsibleModal: true
            });
        } else {
            toast.info(<TranslatableText translationKey="processes.no_process_selected" />);
        }
    };
    openDeleteModal = (id: string) => {
        this.setState({
            deleteModal: true,
            processId: id
        });
    };
    openDeleteMultipleModal = () => {
        if (this.state.checkedProcesses.length > 0) {
            this.setState({
                deleteModal: true,
            });
        } else {
            toast.info(<TranslatableText translationKey="processes.no_process_selected" />);
        }
    };
    handleApproveTemplateAnswersSubmit = () => {
        if (this.state.checkedProcesses.length > 0) {
            let ap = new ApproveTemplateAnswersClient();
            let idList: string[] = [];
            let approveTemplateAnswersRequest = new ApproveTemplateAnswersRequest();
            for (let i = 0; i < this.state.checkedProcesses.length; i++) {
                idList.push(this.state.checkedProcesses[i].id)
            }
            approveTemplateAnswersRequest.entities = idList;
            ap.approveTemplateAnswers(approveTemplateAnswersRequest)
                .then(() => {
                    toast.info(<TranslatableText translationKey="processes.approved_template_answers" />);
                    this.setState({ checkedProcesses: [] })
                    window.location.reload();
                }).catch(error => {
                    HandleCaught_API_Error(error)
                });
        } else {
            toast.info(<TranslatableText translationKey="processes.no_process_selected" />);
        }
    };
    deleteProcess = (id: string) => {
        deleteProcessByIdAPI(id)
            .then(res => {
                this.setState({
                    deleteModal: false,
                    processId: undefined
                });
                window.location.reload();
            })
            .catch(error => {
                this.setState({ deleteModal: false, processId: undefined });
                HandleCaught_API_Error(error)
            });
    };
    deleteMultipleProcesses = (ids: any[]) => {
        let idList: string[] = [];
        for (let i = 0; i < this.state.checkedProcesses.length; i++) {
            idList.push(this.state.checkedProcesses[i].id)
        }
        let deleteMultipleProcessesRequest = new DeleteMultipleProcessesRequest();
        deleteMultipleProcessesRequest.idList = idList;

        let processesClient = new ProcessesClient();
        processesClient.multipleDelete(deleteMultipleProcessesRequest)
            .then(res => {
                this.setState({
                    deleteModal: false,
                    processId: undefined
                });
                window.location.reload();
            })
            .catch(error => {
                this.setState({ deleteModal: false, processId: undefined });
                HandleCaught_API_Error(error);
            });
    }
    getReferenceObjectJson = (id: string, typeId: string) => {
        getJsonSpecForReferenceObj(id, typeId, this.props.userData.organizationId)
            .then(res => { })
            .catch(error => HandleCaught_API_Error(error));
    };
    dateFormatter = (cell: any, row: GetSectionProcessResponse) => {
        if (row.lastUpdated) {
            return (row.lastUpdated.locale(localeDa).format('HH:mm, DD MMM YYYY'));
        }
    };
    triggerApprovalFlow = (
        regardingObjectTypeId: string,
        regardingObjectId: string,
        actionType: number
    ) => {
        let data = {
            regardingObjectTypeId: regardingObjectTypeId,
            regardingObjectId: regardingObjectId,
            actionType: actionType
        };
        triggerApprovalFlowAPI(data)
            .then(() => { toast.info("Flow Triggered"); })
            .catch(error => HandleCaught_API_Error(error));
    };
}

export default SectionWithProcessList