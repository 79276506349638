import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// User API URL
const USERS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Persons`;

export const createPersonAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${USERS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update User API
export const updatePersonAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${USERS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get User By ID
export const getPersonByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all Users of an Organization
export const getPersonsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}/PersonList`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deletePersonByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${USERS_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};
