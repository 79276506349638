import React, { Component } from "react";
import { Row, Col, Button, NavLink } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import {
  getQuestionByIdAPI,
  getQuestionTranslationsAPI,
  deleteQuestionTranslationByIdAPI
} from "./questionsAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import TranslatableText from "../../../../../../Language/TranslatableText";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { LanguageContext } from "../../../../../../contexts/language-context";
import Accent from "../../../../../../components/accent/Accent";
import { toast } from "react-toastify";
import { Config } from "../../../../../../config/Config";
import Swal from "sweetalert2";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { InlineLoading } from "../../../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class QuestionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      order: "",
      panelName: "",
      deleteLoader: false,
      translations: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "descriptionShort",
          text: "Description",
          sort: true
        },
        {
          dataField: "referenceDisplayText",
          text: "Reference Display Text",
          sort: true
        },
        {
          dataField: "reference",
          text: "Reference",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          formatter: (cell, row) => {
            let language = this.searchArray(cell, Config.languages, "value");
            if (language === undefined) {
              language = "dk";
            }
            return language.name;
          },
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/wizard/question/translations/update/${this.props.match.params.id}/${row.id}`}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
                <NavLink onClick={e => this.deleteRecord(row.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
          }
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getQuestionByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.name,
            order: res.data.order,
            panelName: res.data.panelName
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({
            name: "-",
            order: "-",
            panelName: "-"
          });
        });
    }

    this.populateQuestionTranslations();
  }

  populateQuestionTranslations = () => {
    this.setState({ tableLoader: true, translations: [] });
    getQuestionTranslationsAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res.data)) {
          console.log("debug: ", res.data);
          return;
        }
        if (res.data.length > 0) {
          if (res.data.length == Config.languages.length) {
            this.setState({ isDisabled: true });
          } else {
            this.setState({ isDisabled: false });
          }
          this.setState({ translations: res.data, tableLoader: false });
        } else {
          this.setState({ translations: [], tableLoader: false });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  deleteRecord = id => {
    this.setState({ tableLoader: true, conditions: [] }, () => {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.delete_record"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.context.languages[
          "swal.are_you_sure.confirmButtonText"
        ],
        cancelButtonText: this.context.languages[
          "swal.are_you_sure.cancelButtonText"
        ]
      }).then(result => {
        if (result.value) {
          this.setState({ deleteLoader: true });
          deleteQuestionTranslationByIdAPI(
            id,
            this.context.user.organizations.userId
          )
            .then(res => {
              this.populateQuestionTranslations();
              this.setState({
                deleteLoader: false
              });
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
              this.setState({
                deleteLoader: false
              });
            });
          Swal.fire(
            this.context.languages["swal.deleted"],
            this.context.languages["swal.deleted_record_deleted"],
            "success"
          );
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState(
            {
              tableLoader: false
            },
            () => {
              Swal.fire(
                this.context.languages["swal.cancelled"],
                this.context.languages["swal.cancelled_your_record_is_safe"],
                "error"
              );
            }
          );
        }
      });
    });
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { translations, columns, isDisabled, tableLoader } = this.state;
    return (
      <div className="user-details-view">
        <div className="relative">
          <NavLink
            className="absolute right edit"
            tag={Link}
            to={`/admin/wizard/question/update/${this.props.match.params.id}`}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </NavLink>
        </div>
        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="questions.question_details" />
            </Accent>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {this.state.name !== "" ? (
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <TranslatableText translationKey="name" />
                    </td>
                    <td>{this.state.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="questions.panel_name" />
                    </td>
                    <td>{this.state.panelName}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="page.order" />
                    </td>
                    <td>{this.state.order}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="center-align">
                <InlineLoading />
              </div>
            )}
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="translations" />
            </Accent>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={translations}
                columns={columns}
                search
                exportCSV={{
                  fileName: "data.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/admin/wizard/question/translations/add/${this.props.match.params.id}`}
                              disabled={isDisabled ? true : null}
                            >
                              <TranslatableText translationKey="answeroptionset.add_translation" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(QuestionDetails);
QuestionDetails.contextType = GlobalContext;