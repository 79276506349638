import { faBallotCheck, faChartNetwork, faCity, faRankingStar } from "@fortawesome/pro-duotone-svg-icons";
import { faHandshake } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Collapse } from "reactstrap";
import { GetAllOrganizationsResponse, GetOrganizationResponse, GetUserResponse, OrganizationsClient, UsersClient } from "../../apiClients";
import { ScreenLoading } from "../../components";
import Accent from "../../components/accent/Accent";
import Tile from "../../components/tile/Tile";
import TranslatableText, { TranslatableTextString } from "../../Language/TranslatableText";
import CompaniesList from './components/CompaniesList';
import UserList from './components/UserList';
import { cvrapi, cvrApiResponse } from "./cvrApi";
import { organisationDetails } from "./views/companies/organisationDetails";

type PartnerParamsState = {
  organisationData: GetOrganizationResponse,
  cvrApiData: cvrApiResponse | undefined,
  companies: GetAllOrganizationsResponse[] | undefined,
  activeCompanies: GetAllOrganizationsResponse[] | undefined,
  inActiveCompanies: GetAllOrganizationsResponse[] | undefined,
  allUsers: GetUserResponse[] | undefined,
  partnerUsers: GetUserResponse[] | undefined,
  users: GetUserResponse[] | undefined,
  tableLoader: boolean
  tableLoaderUser: boolean
}

const initialState: PartnerParamsState = {
  organisationData: new GetOrganizationResponse(),
  cvrApiData: undefined,
  companies: undefined,
  activeCompanies: undefined,
  inActiveCompanies: undefined,
  allUsers: undefined,
  partnerUsers: undefined,
  users: undefined,
  tableLoader: true,
  tableLoaderUser: false,
};

type PartnerParams = { partnerId: string }
export default function PartnerPage() {
  const { partnerId } = useParams<PartnerParams>();
  let match = useRouteMatch();

  const [isOpen, setIsOpen] = useState(false);
  const [allUsersCheckbox, setAllUsersCheckbox] = useState<boolean>(false);
  const [inActiveCompaniesCheckbox, setInActiveCompaniesCheckbox] = useState<boolean>(false);
  const toggle = () => setIsOpen(!isOpen);

  const [
    { organisationData, cvrApiData, companies, inActiveCompanies, activeCompanies, allUsers, partnerUsers, users, tableLoader, tableLoaderUser },
    setState
  ] = useState(initialState);

  const _OrganizationsClient = new OrganizationsClient();
  const _UsersClient = new UsersClient();

  useEffect(() => {
    setState((prevState) => ({ ...prevState, cvrApiData: undefined }));
    setState((prevState) => ({ ...prevState, tableLoader: true }));
    Promise.all([
      _OrganizationsClient.organizations(partnerId)
        .then(res => {
          setState((prevState) => ({ ...prevState, organisationData: res }));
          if (res.countryCode == 'dk' && res.vat != undefined && res.vat.length == 8) {
            cvrapi(res.vat).then((res) => {
              setState((prevState) => ({ ...prevState, cvrApiData: res }));
            });
          }
        })
        .catch(error => { HandleCuaghtError(error); }),

      _OrganizationsClient.companiesGetByPartnerId(partnerId)
        .then(res => {
          setState((prevState) => ({ ...prevState, companies: res }));
          filterCompanies(res);
        })
        .catch(error => { HandleCuaghtError(error); }),

      _UsersClient.partnerAllUsersWithType(partnerId)
        .then(res => {
          setState((prevState) => ({ ...prevState, users: res, allUsers: res }));
          filterUsers(res)
        })
        .catch(error => { HandleCuaghtError(error); })
    ]).finally(() => {
      setState((prevState) => ({ ...prevState, tableLoader: false }));
    })
  }, [partnerId]);

  const HandleChangeLandOn = () => {
    setState((prevState) => ({ ...prevState, users: [], allUsers: [], tableLoaderUser: true }));
    _UsersClient.partnerAllUsersWithType(partnerId)
      .then(res => {
        setState((prevState) => ({ ...prevState, users: res, allUsers: res, tableLoaderUser: false }));
        filterUsers(res)
      })
      .catch(error => { HandleCuaghtError(error); })
  }

  function HandleCuaghtError(error: any) {
    toast.error(TranslatableTextString("api_error"));
    console.debug(error);
  }

  function HandleAllUsersCheckbox(checked: boolean) {
    setState((prevState) => ({ ...prevState, users: [], tableLoaderUser: true }));
    setTimeout(() => {
      setAllUsersCheckbox(checked);
      if (checked) {
        setState((prevState) => ({ ...prevState, users: prevState.allUsers, tableLoaderUser: false }));
      } else {
        setState((prevState) => ({ ...prevState, users: prevState.partnerUsers, tableLoaderUser: false }));
      }
    }, 500)
  }

  function HandleInActiveCheckbox(checked: boolean) {
    setState((prevState) => ({ ...prevState, companies: [], tableLoaderUser: true }));
    setTimeout(() => {
      setInActiveCompaniesCheckbox(checked);
      if (checked) {
        setState((prevState) => ({ ...prevState, companies: prevState.inActiveCompanies, tableLoaderUser: false }));
      } else {
        setState((prevState) => ({ ...prevState, companies: prevState.activeCompanies, tableLoaderUser: false }));
      }
    }, 500)
  }

  const filterUsers = (res: GetUserResponse[]) => {
    let tempPartner: GetUserResponse[] = []
    tempPartner = res?.filter((val: GetUserResponse) => {
      if (val.userType === "PartnerUser") {
        return true;
      } else {
        return false;
      }
    });
    setState((prevState) => ({ ...prevState, partnerUsers: tempPartner, users: tempPartner }));
  }

  const filterCompanies = (res: GetAllOrganizationsResponse[]) => {
    let tempActiveCompanies: GetAllOrganizationsResponse[] = []
    let tempInActiveCompanies: GetAllOrganizationsResponse[] = []
    res?.filter((val: GetAllOrganizationsResponse) => {
      if (val.isActive) {
        tempActiveCompanies.push(val);
      } else {
        tempInActiveCompanies.push(val);
      }
    });
    setState((prevState) => ({ ...prevState, companies: tempActiveCompanies, activeCompanies: tempActiveCompanies, inActiveCompanies: tempInActiveCompanies }));
  }

  if (tableLoader) {
    return (
      <ScreenLoading />
    );
  } else {
    return (
      <div className="admin-view">
        <Accent logo={organisationData.logo}>
          <div onClick={() => toggle()}>
            <TranslatableText translationKey="partner" />&nbsp;<FontAwesomeIcon icon={faHandshake} />&nbsp;{organisationData.name}
          </div>
        </Accent>
        <Collapse isOpen={isOpen}>
          <Card>
            <CardBody>
              {organisationDetails(organisationData, cvrApiData)}
            </CardBody>
          </Card>
        </Collapse>


        <div className="flex space-between tiles-container">
          <Tile
            className="custom-tile"
            title="Project Status"
            linkTo={`${match!.url}/projectStatus`}
            icon={faChartNetwork}
            iconSize="5x"
            backgroundColor="#17a2b8"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="approvals"
            linkTo={`${match!.url}/approvals`}
            icon={faBallotCheck}
            iconSize="5x"
            backgroundColor="#fd7e14"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="Brugerguide"
            externalLink={true}
            linkTo={`https://dpoa.sharepoint.com/:b:/s/KD-SYS/EVvQqwWEp3VGhV1KMRWmkNgBJ8t0HfV2qjPOfRVLepuq6A?e=SShnIz`}
            icon={faRankingStar}
            iconSize="5x"
            backgroundColor="#199cd8"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="Opret nyt firma"
            linkTo={`/partner/company/add/${partnerId}`}
            icon={faCity}
            iconSize="5x"
            backgroundColor="#43bbc6"
            textColor="#fff"
          />
        </div>


        {CompaniesList(partnerId, companies!, inActiveCompaniesCheckbox, HandleInActiveCheckbox)}
        {UserList(partnerId, users!, HandleChangeLandOn, tableLoaderUser, allUsersCheckbox, HandleAllUsersCheckbox)}
      </div >
    );
  }
}