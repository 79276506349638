import * as d3 from "d3";
import { Component } from "react";
import { RiskAssessmentModel } from "../../../apiClients";
import { GlobalContext } from "../../../contexts/global-context";

type IProps = {
  data: RiskAssessmentModel[] | undefined,
}
type IState = {
  load: boolean,
  chart: any,
  data: any,
}
type _margin = {
  top: number,
  right: number,
  bottom: number,
  left: number
}
// var data = data;
var riskJson: any = {};
var vis: any;
var margin: _margin = { top: 50, right: 0, bottom: 50, left: 80 },
  width: number = 430,
  height: number = 430,
  gridSize: number = Math.floor(width / 5),
  colors: any[] = [ // should be string[] ? 
    "#f8f9fa", // grey
    "#0CC964", // green
    "#ffc107",// orange
    "#fd7e14",// red orange
    "#dc3545", // red
  ],

  vulnerability: string[] = ['4', '3', '2', '1'],
  criticality: string[] = ['1', '2', '3', '4'],
  circlePercentage: number = 0;

class RiskChart extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      load: false,
      chart: null,
      data: {},
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.data !== prevProps.data) {
      riskJson = this.props.data;
      if (vis !== undefined) {
        var svgs = document.querySelectorAll("#chart > svg");
        for (var i = svgs.length - 1; i >= 0; --i) {
          svgs[i].remove();
        }
        vis.selectAll("path").remove();
        vis.selectAll("svg").remove();
        vis.selectAll("image").remove();
      }
      // Redraw chart End
      this.d3Chart();
    }
  }

  d3Chart = () => {
    vis = d3
    riskJson.forEach((item) => {
      circlePercentage = item.value > circlePercentage ? item.value : circlePercentage;
    });
    circlePercentage += (circlePercentage / 10);
    var colorScale = d3.scaleQuantile()
      .domain([0, 2, 3, 7, 9])
      .range(colors);

    var chart1 = d3.select("#chart").append("svg")
      .attr("width", margin.left + width + margin.right)
      .attr("height", margin.top + height + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    chart1
      .append("text")
      .text(this.context.languages["dpo_risk_Probability"])
      .attr("x", -(gridSize * 2))
      .attr("y", -(margin.left / 2))
      .style("text-anchor", "middle")
      .attr("transform", "rotate(-90)");

    chart1
      .append("text")
      .text(this.context.languages["dpo_risk_Impact"])
      .attr("x", gridSize * 2)
      .attr("y", (height - margin.top / 2))
      .style("text-anchor", "middle");

    chart1.append("g")
      .selectAll(".probabilityLabel")
      .data(vulnerability)
      .enter().append("text")
      .text(function (d) { return d; })
      .attr("x", 0)
      .attr("y", function (d, i) { return i * gridSize; })
      .style("text-anchor", "end")
      .attr("transform", "translate(-15," + gridSize / 1.8 + ")");

    chart1.append("g")
      .selectAll(".impactLabel")
      .data(criticality)
      .enter().append("text")
      .text(function (d) { return d; })
      .attr("x", function (d, i) { return i * gridSize; })
      .attr("y", function (d) { return height - margin.top; })
      .style("text-anchor", "middle")
      .attr("transform", "translate(" + gridSize / 2 + ", -10)");

    var gridGroup = chart1.append("g");

    var boxes = gridGroup
      .selectAll("rect")
      .data(riskJson)
      .enter()
      .append("rect");

    var boxesAttributes = boxes
      .attr("x", function (d: any) { return (d.criticality - 1) * gridSize; })
      .attr("y", function (d: any) { return (4 - d.vulnerability) * gridSize; })
      .attr("class", "hour bordered")
      .attr("width", gridSize)
      .attr("height", gridSize)
      .style("fill", "rgba(0,0,0,0)")

    boxesAttributes.transition().duration(2000)
      //@ts-ignore
      .style("fill", function (d: any) {
        return colorScale(d.criticality + d.vulnerability);
      });

    var circles = gridGroup
      .selectAll("circle")
      .data(riskJson).enter()
      .append("circle")
      .attr("cx", function (d: any) { return (d.criticality - 1) * gridSize + (gridSize / 2); })
      .attr("cy", function (d: any) { return (4 - d.vulnerability) * gridSize + (gridSize / 2); })
      .attr("r", function (d: any) { return calCircleRadius(d.value); })
      .style("fill", "rgba(0,0,0,0)")
      .style("stroke-width", 0)
      .style("stroke", "rgba(0,0,0,0)");


    circles.transition().duration(2000)
      .style("fill", function (d: any) {
        //@ts-ignore
        return d3.rgb(colorScale(d.criticality + d.vulnerability)).brighter(0.5).hex();
      })
      .style("stroke-width", 0.5)
      .style("stroke", "#343a40");

    var text = gridGroup
      .selectAll("text")
      .data(riskJson)
      .enter()
      .append("text")
      .attr("x", function (d: any) { return (d.criticality - 1) * gridSize + (gridSize / 2); })
      .attr("y", function (d: any) { return (4 - d.vulnerability) * gridSize + (gridSize / 2) + 8; })
      .text((d: any) => {
        return d.processes.length > 0 ? d.processes.length : "";
      })
      .style("fill", "rgba(0,0,0,0)")
      .attr("text-anchor", "middle")
      .style("font-size", 24)
      .style("stroke", "rgba(0,0,0,0)")
      .style("stroke-width", "0")
      .style("font-weight", 700);

    text.transition().duration(2000)
      .style("fill", function (d: any) {
        //@ts-ignore
        return invertColor(d3.rgb(colorScale(d.criticality + d.vulnerability)).brighter(0.5).hex(), true);
      })
      .style("stroke", "#000000")
      .style("stroke-width", "0.5");

    var toplayer = gridGroup
      .selectAll("invisRect")
      .data(riskJson)
      .enter()
      .append("rect")
      .attr("x", function (d: any) { return (d.criticality - 1) * gridSize; })
      .attr("y", function (d: any) { return (4 - d.vulnerability) * gridSize; })
      .attr("width", gridSize)
      .attr("height", gridSize)
      .style("fill", "rgba(0,0,0,0)")
      .on("mouseover", (d) => mouseover(d, this.context.languages["processes"]))
      .on("mouseout", mouseout);

    function invertColor(hex, bw) {
      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
          ? '#000000'
          : '#FFFFFF';
      }
      // invert color components
      var rs = (255 - r).toString(16),
        gs = (255 - g).toString(16),
        bs = (255 - b).toString(16);
      // pad each with zeros and return
      return "#" + padZero(rs) + padZero(gs) + padZero(bs);
    }

    function padZero(str, len = 2) {
      var zeros = new Array(len).join('0');
      return (zeros + str).slice(-len);
    }

    function mouseover(d, tooltipName) {
      d3.select("#tooltip")
        .style("left", (d3.event.pageX + 10) + "px")
        .style("top", (d3.event.pageY - 10) + "px")
        .select("#value");

      if (d.processes.length == 0) {
        d3.select("#tooltip").classed("hidden", true);
      } else {
        d3.select("#tooltip").classed("hidden", false)
          .html("<strong>" + tooltipName + ":</strong> <br/> <span style='color:#2ECC71'>" + formatProcesses(d.processes) + "</span>");
      }
    }

    function mouseout(d) {
      d3.select("#tooltip").classed("hidden", true);
    }

    function calCircleRadius(d) {
      var percentage = d / circlePercentage;
      percentage = percentage < 0.4 && percentage != 0 ? 0.4 : percentage;
      return percentage * (gridSize / 2)
    }

    function formatProcesses(processes) {
      var finalString = "";
      processes.forEach((process, index) => {
        var temp = process.name;
        if (index < processes.length) {
          temp += "<br/>"
        }
        finalString = finalString.concat(temp)
      });
      return finalString;
    }
  }

  render() {
    return (
      <>
        <div id="chart" className="center"></div>
        <div id="tooltip" className="hidden">
          <p><span id="value"></span></p>
        </div>
      </ >
    );
  }
}
export default RiskChart;
RiskChart.contextType = GlobalContext;