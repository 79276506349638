import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Wizard API URL
const WIZARD_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Wizards`;

// Create
export const createWizardAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${WIZARD_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update
export const updateWizardAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${WIZARD_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get by ID
export const getWizardByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all
export const getWizardsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteWizardByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${WIZARD_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Get Survey List
export const getWizardPagesByIdAPI = async (id, language) => {
  const token = await authService.getAccessToken();
  var headers = {
    Language: language,
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_API_URL}/${id}/PagesList`, { headers: headers })
    .then(({ data }) => data);
};

// Get pages of a specific wizard
export const getWizardPagesListByIdAPI = async (id, language) => {
  const token = await authService.getAccessToken();
  var headers = {
    Language: language,
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_API_URL}/${id}/Pages`, { headers: headers })
    .then(({ data }) => data);
};

// Duplicate Wizard
export const duplicateWizardAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${WIZARD_API_URL}/DuplicateWizard`, data, { headers: headers })
    .then(res => res);
};
// Translate Wizard
export const translateWizardAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${WIZARD_API_URL}/TranslateWizard`, data, { headers: headers })
    .then(res => res);
};

/*
 *
 * Report Templates for Admin
 *
 */

// Wizard Report Templates API URL
const WIZARD_TEMPLATES_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/ReportTemplates`;

// Post
export const createReportTemplateAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${WIZARD_TEMPLATES_URL}`, data, { headers: headers })
    .then(res => res);
};

// List of Templates against a single Wizard
export const getReportTemplatesOfWizardByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_TEMPLATES_URL}/${id}/WizardReportTemplates`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get single Wizard Report Template By ID
export const getReportTemplateByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_TEMPLATES_URL}/${id}`, { headers: headers })
    .then(({ data }) => data);
};

// Update
export const updateReportTemplateAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${WIZARD_TEMPLATES_URL}`, data, { headers: headers })
    .then(res => res);
};

// Delete By ID
export const deleteReportTemplateByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${WIZARD_TEMPLATES_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// List of Templates against a Company
export const getReportTemplatesOfCompanyByIdAPI = async organizationId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_TEMPLATES_URL}/${organizationId}/CompanyReportTemplates`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Download Report
export const downloadReportTemplateByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_TEMPLATES_URL}/DownloadReportTemplate/${id}`, {
      headers: headers,
      responseType: "blob"
    })
    .then(res => res);
};

// Get PageWizardEntities by page ID
export const getPageWizardEntitiesAPI = async (pageID, code) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_API_URL}/${pageID}/${code}/PageWizardEntities`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get PanelWizardEntities by panel ID
export const getPanelWizardEntitiesAPI = async (panelID, code) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_API_URL}/${panelID}/${code}/PanelWizardEntities`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get WizardEntities by wizard ID
export const getWizardEntitiesAPI = async (wizardID, code) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_API_URL}/${wizardID}/${code}/WizardEntities`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Download JSON spec for process.
export const getJsonSpecForWizard = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios({
    url: `${WIZARD_API_URL}/WizardJson/${id}`, //your url
    method: 'GET',
    responseType: 'blob', // important
    headers: headers
  }).then((response) => {
     const url = window.URL.createObjectURL(new Blob([response.data]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', 'wizardSpecification.json'); //or any other extension
     document.body.appendChild(link);
     link.click();
  });
}