import React, { createContext, useContext, useReducer } from 'react';
import {
    GetRegardingObjectTypeResponse, GetUserDefaultOrganizationResponse,
    HandleCaught_API_Error_Base, OrganizationsClient,
    RegardingObjectTypesClient, UserOrganization
} from '../apiClients';
import { LanguageReasource } from './language-context';


const initialState: GlobalContextState = {
    loading: true,
    userRoles: [],
    user: { language: "", organizations: new GetUserDefaultOrganizationResponse() },
    languages: { '': '' },
    favourite_partners: [],
    entities: [],
}

export const GlobalContext = createContext<GlobalContextType>({
    ...initialState,
    setRoles: (roles: string[]) => noProviderYet(),
    setLanguagesAction: (languages: LanguageReasource) => noProviderYet(),
    setOrganizationsAction: (res: GetUserDefaultOrganizationResponse) => noProviderYet(),
    setFavouritePartners: () => noProviderYet(),
    setEntities: () => noProviderYet()
});
function noProviderYet() {
    throw new Error(`Default context used unexpectedly, check you have a provider`);
}
GlobalContext.displayName = "GlobalContext";

export function useGlobalContext() {
    const context = useContext(GlobalContext)
    if (context === undefined) {
        throw new Error('useCount must be used within a CountProvider')
    }
    return context
}

export const GlobalProvider = (props: { children?: React.ReactNode }) => {
    // const queryClient = useQueryClient()
    // queryClient.resetQueries();
    const { children } = props;
    const [state, dispatch] = useReducer<(state: GlobalContextState, action: GlobalContextAction) => GlobalContextState>(AppReducer, initialState);
    const organizationsClient = new OrganizationsClient();
    const regardingObjectTypesClient = new RegardingObjectTypesClient();

    function setLoading(loading: boolean) {
        dispatch({
            type: 'LOADING',
            payload: loading
        })
    }

    function setRoles(roles: string[]) {
        setLoading(true)
        dispatch({
            type: 'ROLES',
            payload: roles
        })
        setLoading(false)
    }

    function setLanguagesAction(languages: LanguageReasource) {
        setLoading(true)
        dispatch({
            type: 'LANGUAGES',
            payload: languages
        })
        setLoading(false)
    }

    function setOrganizationsAction(res: GetUserDefaultOrganizationResponse) {
        setLoading(true)
        dispatch({
            type: 'ORGANIZATIONS',
            payload: res
        })
        setLoading(false)
    }

    function setFavouritePartners() {
        setLoading(true)
        organizationsClient.getUserFavoritePartners()
            .then(res => {
                if (!!res.organizations) {
                    dispatch({
                        type: 'FAVOURITE_PARTNERS',
                        payload: res.organizations
                    })
                }
                setLoading(false)
            })
            .catch(HandleCaught_API_Error_Base);
    }

    function setEntities() {
        setLoading(true)
        regardingObjectTypesClient.menuRegardingObjectTypes()
            .then(res => {
                dispatch({
                    type: 'ENTITIES',
                    payload: res
                })
                setLoading(false)
            })
            .catch(HandleCaught_API_Error_Base);
    }

    return (
        <GlobalContext.Provider value={{
            loading: state.loading,
            userRoles: state.userRoles,
            user: state.user,
            languages: state.languages,
            favourite_partners: state.favourite_partners,
            entities: state.entities,
            setRoles,
            setFavouritePartners,
            setOrganizationsAction,
            setLanguagesAction,
            setEntities
        }}>
            {children}
        </GlobalContext.Provider>
    )
}



/////////////////////////////////////////////
/////// Action Types ////////////////////////
/////////////////////////////////////////////
export type GlobalContextType = GlobalContextState & {
    setRoles: (roles: string[]) => void,
    setLanguagesAction: (languages: LanguageReasource) => void,
    setOrganizationsAction: (res: GetUserDefaultOrganizationResponse) => void,
    setFavouritePartners: () => void,
    setEntities: () => void
}

type GlobalContextAction =
    { type: 'LOADING', payload: boolean }
    | { type: 'ROLES', payload: string[] }
    | { type: 'LANGUAGES', payload: LanguageReasource }
    | { type: 'ORGANIZATIONS', payload: GetUserDefaultOrganizationResponse }
    | { type: 'FAVOURITE_PARTNERS', payload: UserOrganization[] }
    | { type: 'ENTITIES', payload: GetRegardingObjectTypeResponse[] }

type GlobalContextState =
    {
        loading: boolean,
        userRoles: string[],
        user: { language: string, organizations: GetUserDefaultOrganizationResponse },
        languages: LanguageReasource,
        favourite_partners: UserOrganization[],
        entities: GetRegardingObjectTypeResponse[],
    }

/////////////////////////////////////////////
/////// Reducer /////////////////////////////
/////////////////////////////////////////////
const AppReducer = (state: GlobalContextState, action: GlobalContextAction) => {
    switch (action.type) {
        case 'ROLES':
            return {
                ...state,
                userRoles: action.payload
            }
        case 'ORGANIZATIONS':
            return {
                ...state,
                user: {
                    organizations: action.payload,
                    language: action.payload.defaultLanguage ?? "en"
                }
            }
        case 'FAVOURITE_PARTNERS':
            return {
                ...state,
                favourite_partners: action.payload
            }
        case 'LOADING':
            return {
                ...state,
                loading: action.payload
            }
        case 'LANGUAGES':
            return {
                ...state,
                languages: action.payload
            }
        case 'ENTITIES':
            return {
                ...state,
                entities: action.payload
            }
        default:
            return state;
    }
}