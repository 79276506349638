import React, { Component } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  NavLink
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  addPanelTranslationAPI,
  getSingleTranslationAPI,
  updatePanelTranslationAPI,
  getPanelTranslationsAPI,
  getPanelBaseTranslationsByIdAPI
} from "./panelsAPI";
import FormValidator from "../../../../../../components/validator/FormValidator";
import "../../../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../../../Language/TranslatableText";
import { Config } from "../../../../../../config/Config";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import Accent from "../../../../../../components/accent/Accent";
import { getTextTranslationAPI } from "../../../../../../apiClients";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../../../components";



class AddPanelTranslations extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "title",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      title: "",
      name: "",
      description: "",
      language: "",
      panelId: "",
      loading: false,
      cuLoader: false,
      validation: this.validator.valid(),
      translating: {
        title: false,
        name: false,
        description: false
      },
      languageDropdown: Config.languages,
      baseLanguageDropdown: Config.languages,
      baseTranslation: {
        title: "",
        name: "",
        description: "",
        language: ""
      }
    };

    this.submitted = false;
  }
  componentDidMount() {
    var selected_languages,
      available_languages = [];
    this.setState({ loading: true });
    getPanelBaseTranslationsByIdAPI(this.props.match.params.panelId)
      .then(res => {
        this.setState({
          baseTranslation: res,
          loading: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
    if (this.props.match.params.panelId !== undefined) {
      if (this.props.match.params.translationId === undefined) {
        getPanelTranslationsAPI(this.props.match.params.panelId)
          .then(res => {
            selected_languages = res.data.map(el => {
              return el.language;
            });
            available_languages = this.state.languageDropdown.filter(el => {
              if (selected_languages.includes(el.value)) {
                return false; // skip
              }
              return true;
            });
            this.setState({
              languageDropdown: available_languages
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
          });
      }
    }
    if (this.props.match.params.translationId !== undefined) {
      this.setState({ loading: true });
      getSingleTranslationAPI(this.props.match.params.translationId)
        .then(res => {
          this.setState({
            title: res.title,
            description: res.description === null ? "" : res.description,
            language: res.language,
            panelId: res.panelId,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }
  goBack = () => {
    this.props.history.goBack();
  };

  addTranslation = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      ...this.state,
      panelId:
        this.props.match.params.panelId !== undefined
          ? this.props.match.params.panelId
          : "",
      id:
        this.props.match.params.translationId !== undefined
          ? this.props.match.params.translationId
          : "",
      userId: this.context.user.organizations.userId
    };
    if (this.props.match.params.translationId === undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        addPanelTranslationAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updatePanelTranslationAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  translateSingleField = field => {
    this.setState(prevState => ({
      translating: {
        ...prevState.translating,
        [field]: true
      }
    }));

    getTextTranslationAPI({
      to: this.state.language,
      text: this.state.baseTranslation[field]
    })
      .then(res => {
        this.setState(prevState => ({
          [field]: res.data,
          translating: {
            ...prevState.translating,
            [field]: false
          }
        }));
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState(prevState => ({
          translating: {
            ...prevState.translating,
            [field]: false
          }
        }));
      });
  };

  translateFields = () => {
    let self = this;
    Object.keys(this.state.baseTranslation).forEach(function (item) {
      console.log(item); // key
      console.log(self.state.baseTranslation[item]); // value
      if (
        item !== "id" &&
        item !== "language" &&
        item !== "templateRegardingObjectTypeId"
      ) {
        self.translateSingleField(item);
      }
    });
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-translation-view">
        {loading && (
          <ScreenLoading />
        )}
        <Row>
          <Col md={12}>
            <Accent useIcon={false} left={false}>
              {this.props.match.params.translationId !== undefined
                ?  <TranslatableText translationKey="update" />
                : <TranslatableText translationKey="create" />}{" "}
              <TranslatableText translationKey="company.translation_for" />{" "}
              {this.state.baseTranslation.title}
            </Accent>
          </Col>
        </Row>

        <Row className="panel">
          <Col md={12}>
            <Row>
              <Col md={6} className="panel-header">
                <span className="capitalize">
                  <TranslatableText translationKey="company.translate_from" />{" "}
                  {this.state.baseTranslation.language}
                </span>
              </Col>
              <Col md={6} className="panel-header space-between">
                <span className="capitalize">
                  <TranslatableText translationKey="company.translate_to" />
                </span>
                <Button
                  disabled={this.state.language === "" ? true : null}
                  outline
                  onClick={e => this.translateFields(e)}
                >
                  <TranslatableText translationKey="company.translate_to" />{" "}
                  {this.state.language
                    ? this.state.languageDropdown.find(
                        sel => sel.value === this.state.language
                      ).name
                    : "Select"}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col className="panel-separator" md={6}>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="base_language">
                    <TranslatableText translationKey="language" />
                  </Label>
                  <Input
                    type="select"
                    name="base_language"
                    id="base_language"
                    value={this.state.baseTranslation.language}
                    disabled={true}
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.baseLanguageDropdown.map((e, key) => {
                      return (
                        <option key={key} value={e.value}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="base_title">
                    <TranslatableText translationKey="title" />
                  </Label>
                  <Input
                    type="text"
                    name="base_title"
                    id="base_title"
                    placeholder=""
                    disabled={true}
                    value={this.state.baseTranslation.title}
                  />
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label for="base_description">
                    <TranslatableText translationKey="description" />{" "}
                  </Label>
                  <Input
                    type="textarea"
                    name="base_description"
                    id="base_description"
                    disabled={true}
                    value={this.state.baseTranslation.description}
                  />
                </FormGroup>
              </Col>

              <Col className="space-between flex mtb-10" md={12}>
                <Button onClick={this.goBack}>
                  <TranslatableText translationKey="back" />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Form onSubmit={this.addTranslation}>
              <Row form>
                <Col md={12}>
                  <FormGroup
                    className={validation.language.isInvalid ? "has-error" : ""}
                  >
                    <Label for="language">
                      <TranslatableText translationKey="language" />
                    </Label>
                    <Input
                      type="select"
                      name="language"
                      id="language"
                      value={this.state.language}
                      onChange={this.handleChange}
                      disabled={
                        this.props.match.params.translationId !== undefined
                          ? true
                          : null
                      }
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.languageDropdown.map((e, key) => {
                        return (
                          <option key={key} value={e.value}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                    <span className="help-block">
                      {validation.language.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup
                    className={validation.title.isInvalid ? "has-error" : ""}
                  >
                    <Label className="flex space-between" for="title">
                      <span className="flex verticle-flex-end">
                        <TranslatableText translationKey="title" />
                      </span>
                      {this.state.translating.title === false ? (
                        <NavLink
                          className="no-padding translate"
                          onClick={e => this.translateSingleField("title", e)}
                          disabled={this.state.language === "" ? true : null}
                        >
                          <Tippy placement="top" content="Translate">
                            <span>
                              <FontAwesomeIcon
                                style={{ color: "var(--primary)" }}
                                icon={faGlobe}
                                size="1x"
                              />
                            </span>
                          </Tippy>
                        </NavLink>
                      ) : (
                        <InlineLoading />
                      )}
                    </Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      placeholder=""
                      value={this.state.title}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.title.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label className="flex space-between" for="description">
                      <span className="flex verticle-flex-end">
                        <TranslatableText translationKey="description" />
                      </span>
                      {this.state.translating.description === false ? (
                        <NavLink
                          className="no-padding translate"
                          onClick={e =>
                            this.translateSingleField("description", e)
                          }
                          disabled={this.state.language === "" ? true : null}
                        >
                          <Tippy placement="top" content="Translate">
                            <span>
                              <FontAwesomeIcon
                                style={{ color: "var(--primary)" }}
                                icon={faGlobe}
                                size="1x"
                              />
                            </span>
                          </Tippy>
                        </NavLink>
                      ) : (
                        <InlineLoading />
                      )}
                    </Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      value={this.state.description}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>

                <Col className="flex-end flex mtb-10" md={12}>
                  {this.state.cuLoader === false ? (
                    <Button>
                      {this.props.match.params.translationId !== undefined
                      ?  <TranslatableText translationKey="update" />
                      : <TranslatableText translationKey="create" />}
                      {" "} Translation
                    </Button>
                  ) : (
                    <InlineLoading />
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(AddPanelTranslations);
AddPanelTranslations.contextType = GlobalContext;