import React, { useContext, useEffect, useState } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TranslatableText from "../../../../../../Language/TranslatableText";
import {
  faInfoCircle,
  faTrash,
  faQuestion
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import Accent from "../../../../../../components/accent/Accent";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { Table_HeaderFormatter, Table_NoDataIndication } from "../../../../../../components/table";
import { InlineLoading } from "../../../../../../components";
import { DeleteQuestionRequest, GetAllQuestionResponse, QuestionsClient } from "../../../../../../apiClients";
import { HandleCaught_API_Error_Base } from "../../../../../../apiClients/api_caughtError";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const defaultSorted = [
  {
    dataField: "order", // if dataField is not match to any column you defined, it will be ignored.
    order: "asc" // desc or asc
  }
];

type PartnerParamsState = {
  questions: GetAllQuestionResponse[],
  questionId: string | null,
  tableLoader: boolean
  deleteLoader: boolean
  deleteModal: boolean
}

const initialState: PartnerParamsState = {
  questions: [],
  questionId: null,
  tableLoader: true,
  deleteLoader: false,
  deleteModal: false
};

export default function Questions() {
  const [{ questions, questionId, tableLoader, deleteLoader, deleteModal }, setState] = useState(initialState);
  const history = useHistory();
  const _QuestionsClient = new QuestionsClient();
  const { user, userRoles } = useContext(GlobalContext);

  useEffect(() => {
    const el = document.getElementById('my-element');
    loadQuestions();
  }, []);

  function loadQuestions() {
    setState((prevState) => ({ ...prevState, questions: [], tableLoader: true }));
    Promise.all([
      _QuestionsClient.getAllQuestion()
        .then(res => {
          setState((prevState) => ({ ...prevState, questions: res }));
        })
        .catch(HandleCaught_API_Error_Base),
    ]).finally(() => {
      setState((prevState) => ({ ...prevState, tableLoader: false }));
    });
  }
  const tableColumns: Array<ColumnDescription<GetAllQuestionResponse>> =
    [
      {
        dataField: "id",
        text: "ID",
        hidden: true
      },
      {
        dataField: "questionCode",
        text: "Question Code",
        sort: true,
        classes: "recordID",
        headerStyle: () => {
          return { width: "18%" };
        }
      },
      {
        dataField: "name",
        text: "Name",
        headerFormatter: Table_HeaderFormatter,
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        }
      },
      {
        dataField: "wizardName",
        text: "Wizard",
        headerFormatter: Table_HeaderFormatter,
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        }
      },
      {
        dataField: "pageName",
        text: "Page",
        headerFormatter: Table_HeaderFormatter,
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        }
      },
      {
        dataField: "panelName",
        text: "Panel",
        headerFormatter: Table_HeaderFormatter,
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        }
      },
      {
        dataField: "answerOptionSet",
        text: "Answer Option Set",
        headerFormatter: Table_HeaderFormatter,
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        }
      },
      {
        dataField: "order",
        text: "Order",
        headerFormatter: Table_HeaderFormatter,
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        }
      },
      {
        dataField: "condition",
        text: "Condition",
        headerFormatter: Table_HeaderFormatter,
        sort: true,
        headerStyle: () => {
          return { width: "15%" };
        }
      },
      {
        dataField: "actions",
        text: "Actions",
        headerFormatter: Table_HeaderFormatter,
        csvExport: false,
        headerStyle: () => {
          return { width: "5%" };
        },
        formatter: (cell, row) => {
          return (
            // <div className="actions">
            //     <NavLink
            //         tag={Link}
            //         to={`/partner/company/details/${row.id}`}
            //     >
            //         <FontAwesomeIcon icon={faInfoCircle} size="1x" />
            //     </NavLink>
            // </div>

            <div className="actions">
              <NavLink
                tag={Link}
                to={`./question/details/${row.id}`}
              >
                <Tippy placement="top" content="See details">
                  <span>
                    <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink onClick={() => openDeleteModal(row.id)}>
                <Tippy placement="top" content="Remove">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
        }
      }
    ];


  const toggleDeletePopup = () => {
    setState((prevState) => ({ ...prevState, deleteModal: !prevState.deleteModal }));
    if (deleteModal === false) {
      setState((prevState) => ({ ...prevState, questionId: null }));
    }
  };

  const openDeleteModal = (id: string) => {
    setState((prevState) => ({ ...prevState, deleteModal: true, questionId: id }));
  };

  const deleteQuestion = (id: string) => {
    setState((prevState) => ({ ...prevState, deleteLoader: true }));
    _QuestionsClient.questionsDelete(new DeleteQuestionRequest({id}))
    .then(res => {
      loadQuestions();
    })
    .catch(HandleCaught_API_Error_Base)
    .finally(() => {
      setState((prevState) => ({ ...prevState, 
        deleteLoader: false,
        questionId: null,
        deleteModal: false }));
    })
  };

    return (
      <div className="panel-view" style={{width:"95vw", position:"absolute", left:"50%", transform:"translate3d(-50%, 0, 0)"}}>
        <Accent useIcon={true} icon={faQuestion}>
          <TranslatableText translationKey="page.questions" />
        </Accent>
        <div className="table-container">
          <ToolkitProvider
          bootstrap4
            keyField="id"
            data={questions}
            columns={tableColumns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          CSV
                        </ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`./question/create`}
                        >
                          <TranslatableText translationKey="create" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <Table_NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  // @ts-ignore
                  pagination={paginationFactory()}
                  // @ts-ignore
                  defaultSorted={defaultSorted}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={() => history.goBack()}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={deleteModal}
            toggle={toggleDeletePopup}
          >
            <ModalHeader toggle={toggleDeletePopup}>
              <TranslatableText translationKey="confirm_delete" />?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="panel.confirm_delete_question" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={() => deleteQuestion(questionId!)}
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={() => toggleDeletePopup()}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
}