import {faGavel} from "@fortawesome/pro-solid-svg-icons";
import LawTile from "./tile/LawTile";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLanguage } from "../../../contexts/language-context";
import { GetAllSectionProcessesResponse, SectionsClient } from "../../../apiClients"
import { ScreenLoading } from "../../../components/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/pro-regular-svg-icons";

import DynamicTree from '../../../views/company-admin/views/sharepoint/Tree';

export default function LawDashboard() {
    const [isLoading, setIsLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState<GetAllSectionProcessesResponse[]>();
    const { languageData } = useLanguage();
    const _SectionClient = new SectionsClient();

    useEffect(() => {
        if (languageData.status === 'success') {
            (async () => {
                await _SectionClient.sectionsProcesses()
                    .then(data => {
                        setDashboardData(data);
                    })
                    .catch(error => {
                        toast.error(languageData.languageData["api_error"]);
                        console.debug(error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            })()
        } else if (languageData.status === 'loading') {
            setIsLoading(true);
        }
    }, [languageData.status]);

    if (isLoading) {
        return (
            <div className="center-align">
                <ScreenLoading />
            </div>
        );
    } else {
        return (
            <div className="admin-view">
                <div className={"flex space-between mr-20 accent-line"}>
                    <div className="flex accent-content">
                        <FontAwesomeIcon icon={faGavel} size="5x" />
                        <h4>Digital Jura</h4>
                    </div>
                </div>
                <div className="card-deck">
                    {dashboardData!.map((section, index) => (
                        section.processes?.map((process, index) => (
                            <div className="w-25">
                                <LawTile
                                    key={process.id}
                                    className="custom-tile"
                                    title={section.nameShort ?? ""}
                                    subTitle={process.nameShort ?? ""}
                                    linkTo={`processes/wizard/${process.wizardId}/${process.id}/${process.regardingObjectTypeId}`}
                                    icon={section.icon ?? ""}
                                    iconSize="5x"
                                    backgroundColor={section.descriptionShort ?? ""}
                                    textColor="#fff"
                                    disabled={false}
                                />
                                
      {/* <NavLink
        tag={Link}
        className={"tile mb-3"}
        style={tileStyle}
        to={disabled ? "#" : linkTo}
      >
        <div className={classnames("", className)}>
          <h4>
            <TranslatableText  translationKey={title} />
          </h4>
          <p>{subTitle}</p>
          <div className="flex-container center">
            <FontAwesomeIcon icon={icon} size={iconSize} />
          </div>
        </div>
      </NavLink> */}
                            </div>

                        ))))}
                </div>
                <div className={"flex space-between mr-20 accent-line"}>
                    <div className="flex accent-content">
                        <FontAwesomeIcon icon={faBook} size="5x" />
                        <h4>Dokumenter</h4>
                    </div>
                </div>
                <DynamicTree showCompanyInformation={false} />
            </div>
        );
    }
}