import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { HandleCaught_API_Error_Base } from "../../apiClients";
import { getEntitiesAPI } from "../../views/entity/entityAPI";
import { InlineLoading } from "../loading";

let LookupField = ({
  index,
  element,
  componentState,
  handleChange,
  changeValidationMessage
}) => {
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getEntitiesAPI(element.lookupFieldReferenceTypeId)
      .then(res => {
        if (res !== undefined && Array.isArray(res)) {
          setOptions(res);
          setLoader(false);
        }
      })
      .catch(HandleCaught_API_Error_Base);
  }, []);

  return loader ? (
    <FormGroup className="flex align-loader">
      <InlineLoading />
    </FormGroup>
  ) : (
    <FormGroup
      className={
        element.displayType.toLowerCase() === "textarea" ? "full-flex" : ""
      }
    >
      <Label for={element.name}>
        <span className="capitalize">{element.displayText}</span>
      </Label>
      <div className="flex space-between flex-align">
        {/* <span className="bold mr-10 capitalize">{element.entityName}: </span> */}
        <Input
          type="select"
          name={element.name}
          id={element.name}
          value={componentState[element.name] || ""}
          onChange={e => handleChange(e, index)}
          required={element.isRequired}
          onInvalid={event =>
            changeValidationMessage(element.validationMessage, event)
          }
          onInput={event => changeValidationMessage("", event)}
          disabled={componentState["lock"] ? true : null}
        >
          <option value="">-- Vælg --</option>
          {options.map((option, key) => {
            return (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            );
          })}
        </Input>
      </div>
    </FormGroup>
  );
};

export default LookupField;
