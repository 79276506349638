import { useParams } from "react-router-dom";
import CompanyDetailsComponent from "./components/CompanyDetailsComponent";

type CompanyDetails = { id: string }
export default function CompanyDetails() {
  const { id } = useParams<CompanyDetails>();
  
  return (
    <CompanyDetailsComponent 
      id={id}
      showBackButton={true}
    />
  )
}