import Tippy from "@tippyjs/react";
import { useContext } from "react";
import { GlobalContext } from "../contexts/global-context";

export default function TranslatableText(props: {translationKey: string}) {
  return <>{TranslatableTextString(props.translationKey)}</>
  
  // return (
  //     <Tippy placement="bottom" content={props.translationKey}>
  //       <span>{TranslatableTextString(props.translationKey)}</span >
  //     </Tippy>
  // )
}
export function TranslatableTextString(translationKey: string) {
  const {languages} = useContext(GlobalContext);
  return languages[translationKey] ?? translationKey;
}