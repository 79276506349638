import { faCheckCircle, faPaperPlane, faCircleX, faCircleExclamation, faPersonCircleCheck, faPersonCircleXmark, faPersonCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import TranslatableText from "../Language";
import React from "react";

export enum ApprovalFlowStatus
{
    /** None has ever run. */
    None = 0,
    /** Submit show open state. */
    Submit = 1001,
    /** Approved show the complete. */
    Approved = 1002,
    /** Rejected show rejection from approvers. */
    Rejected = 1003,
    /** Cancel show canceled by user itself.. */
    Cancel = 1004,
    /** Shows this state of the flow engine when first user in the sequence approved the flow */
    Running = 1005,
    /** Approval approved by other user ( only used in <see cref="Enums.ApprovalFlowOrder.AnyOneuser"/> ) */
    ApproveByOther = 2002,
    /** Approval Rejected by other user */
    RejectedByOther = 2003,
    /** Approval Cancelled by other user */
    CancelByOther = 2004,
}

export const getApprovalState = (state: number | ApprovalFlowStatus) => {
    switch (state) {
        case 1001:
            return "Submitted";
        case 1002:
            return "Done";
        case 1003:
            return "Rejected";
        case 1004:
            return "Canceled";
        case 1005:
            return "Running";
        case 2002:
            return "ApprovedByOther";
        case 2003:
            return "RejectedByOther";
        case 2004:
            return "CancelByOther";
        default:
            return "-";
    }
}
export const getApprovalStateKd = (state: number | ApprovalFlowStatus) => {
    switch (state) {
        case 1001:
            return "Afventer";
        case 1002:
            return "Godkendt";
        case 1003:
            return "Afvist";
        case 1004:
            return "Stoppet";
        case 1005:
            return "Afventer";
        case 2002:
            return "Godkendt af anden";
        case 2003:
            return "Afvist af anden";
        case 2004:
            return "Stoppet af anden";
        default:
            return "-";
    }
}
/** Returns Icon with tooltip representing the state */
export function approvalStateVisualiser(state: number | ApprovalFlowStatus): React.ReactNode {
  let icon = faCheckCircle;
  let text = "";
  switch (state) {
    case 1001:
      text = "approvalState.Submitted";
      icon = faPaperPlane;
      break;
    case 1002:
      text = "approvalState.Approved";
      icon = faCheckCircle;
      break;
    case 1003:
      text = "approvalState.Rejected";
      icon = faCircleX;
      break;
    case 1004:
      text = "approvalState.Canceled";
      icon = faCircleExclamation;
      break;
    case 1005:
      text = "approvalState.Running";
      icon = faPaperPlane;
      break;
    case 2002:
      text = "approvalState.ApproveByOther";
      icon = faPersonCircleCheck;
      break;
    case 2003:
      text = "approvalState.RejectedByOther";
      icon = faPersonCircleXmark;
      break;
    case 2004:
      text = "approvalState.CancelByOther";
      icon = faPersonCircleExclamation;
      break;
    default:
      break;
  }
  return (
    <Tippy placement="top" content={<TranslatableText translationKey={text} />}>
      <span>
        <FontAwesomeIcon icon={icon} />
      </span>
    </Tippy>
  );
}