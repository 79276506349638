import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import CF from "./CF";
import CFDetails from "./CFDetails";
import CreateCF from "./CreateCF";
import { withRouter } from "react-router-dom";
import AddCFTranslation from "./AddCFTranslation";

function CFRouter(props) {
  return (
    <div className="parent-custom-fields-view">
      <Switch>
        <Route
          path={`${props.match.path}/translation/add/:id`}
          render={props => <AddCFTranslation {...props} />}
        />
        <Route
          path={`${props.match.path}/translation/update/:id/:translationId`}
          render={props => <AddCFTranslation {...props} />}
        />
        <Route
          path={`${props.match.path}/create/:regardingObjectId/:organizationId`}
          render={props => <CreateCF {...props} />}
        />
        <Route
          path={`${props.match.path}/create/:regardingObjectId`}
          render={props => <CreateCF {...props} />}
        />
        <Route
          path={`${props.match.path}/update/:id/:regardingObjectId/:organizationId`}
          render={props => <CreateCF {...props} />}
        />
        <Route
          path={`${props.match.path}/details/:id/:regardingObjectId`}
          render={props => <CFDetails {...props} />}
        />
        <Route
          path="/company-admin/custom-fields"
          render={props => <CF {...props} />}
        />
      </Switch>
    </div>
  );
}

export default withRouter(CFRouter);