import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Claim API URL
const TEMPLATE_CLAIMS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Claims`;

// Create
export const createClaimAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${TEMPLATE_CLAIMS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update
export const updateClaimAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${TEMPLATE_CLAIMS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get by ID
export const getClaimByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TEMPLATE_CLAIMS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all
export const getClaimsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TEMPLATE_CLAIMS_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteClaimByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${TEMPLATE_CLAIMS_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Lookup APIs for Claims

const LOOKUPS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/LookUps`;

// GetTemplateAction
export const getTemplateAction = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${LOOKUPS_API_URL}/GetAction`, { headers: headers })
    .then(({ data }) => data);
};

// GetTemplateRegardingObjectType
export const getTemplateRegardingObjectType = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${LOOKUPS_API_URL}/GetRegardingObjectType`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Association

// Role-Claim Association list API
export const getRolesByClaimIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TEMPLATE_CLAIMS_API_URL}/${id}/Roles`, { headers: headers })
    .then(({ data }) => data);
};
