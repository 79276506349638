import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React from "react";
import "./Accent.scss";

type AccentProps = {
  className?: string | undefined;
  left?: boolean | undefined;
  useIcon: boolean;
  icon: IconProp;
  useHtmlIcon?: undefined;
  iconName?: undefined;
  logo?: string | undefined;
  children: React.ReactNode;
} | {
  className?: string | undefined;
  left?: boolean | undefined;
  useIcon?: undefined;
  icon?: undefined;
  useHtmlIcon: boolean;
  iconName: string;
  logo?: string | undefined;
  children: React.ReactNode;
} | any;

let Accent = (props: AccentProps) => {
  return (
    <div
      className={classnames(
        "flex space-between mr-20 accent-line",
        props.className,
        props.left === true ? "left" : ""
      )}
    >
      <div className="flex accent-content">
        {props.useIcon === true ? (
          <FontAwesomeIcon icon={props.icon} size="5x" />
        ) : props.useHtmlIcon === true ? (
          <i className={classnames("fa-solid html-icon", `fa-${props.iconName}`)} />
        ) : (
          <img className="partner-logo" src={props.logo} alt="" />
        )}
        <h4>{props.children}</h4>
      </div>
    </div>
  );
};

export default Accent;
