import {
  faAngleDown,
  faAngleRight, faEdit, faInfoCircle,
  faProjectDiagram, faTrash
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button, Col, Modal, ModalBody,
  ModalFooter, ModalHeader, NavLink, Progress, Row
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  Comparator, textFilter
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import TranslatableText from "../../Language/TranslatableText";
import { deleteTaskByIdAPI, getTaskByIdV2API, getTasksWithPaginationAPI } from "./tasksAPI";

import { faMinusSquare, faPlusSquare } from "@fortawesome/pro-regular-svg-icons";
import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import { InlineLoading } from "../../components";
import Accent from "../../components/accent/Accent";
import { GlobalContext } from "../../contexts/global-context";


const NoDataIndication = () => (
  <div className="text-center">
    <span><TranslatableText translationKey="no_data_available" /></span>
  </div>
);
// Name 
// Responsible
// Status
// Progress
// Deadline
const columns = [
  {
    dataField: "name",
    text: "Navn",
    filter: textFilter()
  },
  {
    dataField: "users[0].name",
    text: "responsible",
    headerFormatter: column => {
      return <TranslatableText translationKey={column.text} />;
    },
    headerStyle: {
      width: '20%'
    }
  },
  {
    dataField: "status",
    text: "tasks.status",
    headerFormatter: column => {
      return <TranslatableText translationKey={column.text} />;
    },
    headerStyle: {
      width: '10%'
    }
  },
  {
    dataField: "progressPercentage",
    text: "tasks.progression",
    headerFormatter: column => {
      return <TranslatableText translationKey={column.text} />;
    },
    formatter: (cell, row) => {
      return <Progress value={cell} color="blue">{cell}&nbsp;%</Progress>;
      // HTML elemen in tippy....
      // return <Tippy placement="top" content={row.description}><span><Progress value={cell} color="blue">{cell}&nbsp;%</Progress></span></Tippy>;
    },
    headerStyle: {
      width: '16%'
    }
  },
  {
    dataField: "actualDueDate",
    text: "tasks.dueDate",
    headerFormatter: column => {
      return <TranslatableText translationKey={column.text} />;
    },
    formatter: (cell, row) => {
      var d = new Date(cell), // Convert the passed timestamp to milliseconds
        yyyy = d.getFullYear(),
        mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
        dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
        timestamp;

      // ie: 2014-03-24, 3:00 PM
      timestamp = yyyy + "-" + mm + "-" + dd;
      return <span>{timestamp}</span>;
    },
    headerStyle: {
      width: '10%'
    }
  },
  {
    dataField: "actions",
    text: "actions",
    headerFormatter: column => {
      return <TranslatableText translationKey={column.text} />;
    },
    headerStyle: {
      width: '14%'
    }
  }
];



const RemoteAll = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  tableLoader,
  path
}) => {
  const [currentRow, setCurrentRow] = useState([]);
  const columnsEntities = [
    {
      dataField: "entityType", //res[0].systems.map(row => row.name)
      text: "Entity",
      sort: true
    },
    {
      dataField: "entityName", //res[0].systems.map(row => row.name)
      text: "Entity Name",
      sort: true
    },
  ]
  const addEntryClick = (newElm) => {
    // setCurrentRow([...currentRow, newElm]);
    setCurrentRow(prevState => [...prevState, newElm]);
  };
  function handleOnExpand(row, isExpand, rowIndex, e) {
    if (isExpand) {
      getTaskByIdV2API(row.id)
        .then(res => {
          addEntryClick(res.data);
        })
        .catch(error => {
        });

    }
  }
  function handleOnExpandAll(isExpandAll, rows, event) {
    if (isExpandAll) {
      rows.map((row) => {
        getTaskByIdV2API(row.id)
          .then(res => {
            addEntryClick(res.data);
          })
          .catch(error => {
          });
      })
    }
  }
  const ExpandedRowRenderer = (currentRow, row) => {
    let currentIndex = currentRow.findIndex(elm => elm.id === row.id);
    currentIndex = (currentIndex === -1 ? currentRow.length : currentIndex);
    return (
      <div>
        {currentRow[currentIndex] === undefined ? (
          <div className="center">
            <InlineLoading />
          </div>
        ) : (
          <>
            {/* <p><b><TranslatableText translationKey="tasks.entity" />:</b>&nbsp;{currentRow[currentIndex].entityTypeName}</p>
            <p><b><TranslatableText translationKey="tasks.entityName" />:</b>&nbsp;{currentRow[currentIndex].entityName}</p> */}
            <p><b><TranslatableText translationKey="tasks.recurrenceType" />:</b>&nbsp;{currentRow[currentIndex].recurrenceType}</p>
            <p><b><TranslatableText translationKey="description" />:</b>&nbsp;<div dangerouslySetInnerHTML={{ __html: currentRow[currentIndex].description }} /></p>
            <p><b><TranslatableText translationKey="tasks.progression" />:</b>&nbsp;<div dangerouslySetInnerHTML={{ __html: currentRow[currentIndex].progression }} /></p>
            <Row>
              <Col>
                <div className="table-container">
                  <BootstrapTable
                    remote
                    keyField="id"
                    data={currentRow[currentIndex].taskEntityTypeAndName ? currentRow[currentIndex].taskEntityTypeAndName : []}
                    // data={[]}
                    columns={columnsEntities}
                    expandRow={expandRow}
                    // defaultSorted={defaultSorted}
                    filter={filterFactory()}
                    // pagination={paginationFactory({ page, sizePerPage, totalSize })}
                    noDataIndication={() =>
                    (
                      <NoDataIndication />
                    )
                    }
                  />
                </div>
              </Col>
            </Row>
          </>)}
      </div>
    )
  }
  const expandRow = {

    renderer: (row) => (
      ExpandedRowRenderer(currentRow, row)
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return isAnyExpands ? <FontAwesomeIcon icon={faMinusSquare} /> : <FontAwesomeIcon icon={faPlusSquare} />;
    },
    expandColumnRenderer: ({ expanded }) => {
      return expanded ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />;
    },
    onExpand: handleOnExpand,
    onExpandAll: handleOnExpandAll
  };

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc"
    }
  ];
  return (
    <>
      <div className="flex flex-end mb-10">
        <NavLink className="button-primary" tag={Link} to={`${path}/create`}>
          <TranslatableText translationKey="create" />
        </NavLink>
      </div>
      <div>
        <BootstrapTable
          remote
          keyField="id"
          data={data}
          columns={columns}
          expandRow={expandRow}
          defaultSorted={defaultSorted}
          filter={filterFactory()}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          noDataIndication={() =>
            tableLoader ? (
              <InlineLoading />
            ) : (
              <NoDataIndication />
            )
          }
          onTableChange={onTableChange}
        />
      </div>
    </>
  )
};

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableLoader: false,
      tasks: [],
      deleteModal: false,
      deleteLoader: false,
      taskId: null,
      seriesId: null,
      entityType: "",
      entityName: "",
      page: 1,
      totalSize: 0,
      sizePerPage: 10
    };
  }

  componentDidMount() {
    this.populateTasks(this.props.match.params.id);
  }

  timeFormatter = (cell, row) => {
    var d = new Date(cell), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
      dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
      timestamp;

    // ie: 2014-03-24, 3:00 PM
    timestamp = yyyy + "-" + mm + "-" + dd;
    return <span>{timestamp}</span>;
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            taskId: null,
            seriesId: null
          });
        }
      }
    );
  };

  openDeleteModal = (id, seriesId) => {
    this.setState({
      deleteModal: true,
      taskId: id,
      seriesId: seriesId
    });
  };

  populateTasks = id => {
    this.setState({ tableLoader: true, tasks: [] });
    getTasksWithPaginationAPI(this.state.page, this.state.sizePerPage)
      .then(res => {
        if (!Array.isArray(res.list)) {
          return;
        }
        var self = this;
        var data = res.list
          .filter(function (el) {
            if (el.name === "") {
              return false; // skip
            }
            return true;
          })
          .map(function (el) {
            var o = Object.assign({}, el);
            o.actions = (
              <div className="actions">
                <NavLink tag={Link} to={`/company-admin/flow-engine/${el.taskRegardingObjectTypeId}/${el.id}`}>
                  <Tippy placement="top" content={<TranslatableText translationKey="flow" />}>
                    <span>
                      <FontAwesomeIcon icon={faProjectDiagram} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink tag={Link} to={`/tasks/details/${el.id}`}>
                  <Tippy placement="top" content={<TranslatableText translationKey="details" />}>
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink tag={Link} to={`/tasks/update/${el.id}`}>
                  <Tippy placement="top" content={<TranslatableText translationKey="update" />}>
                    <span>
                      <FontAwesomeIcon icon={faEdit} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink onClick={e => self.openDeleteModal(el.id, el.seriesId, e)} >
                  <Tippy placement="top" content={<TranslatableText translationKey="delete" />}>
                    <span>
                      <FontAwesomeIcon style={{ color: "#d84a4a" }} icon={faTrash} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
              </div>
            );
            return o;
          });
        this.setState({
          tasks: data,
          tableLoader: false,
          totalSize: res.paging.total
        });
      })
      .catch(error => {
        toast.error(
          this.context.languages["api_error"],
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          }
        );
        this.setState({ tableLoader: false });
      });
  };
  goBack = () => {
    this.props.history.goBack();
  };

  deleteTask = (id, isSeries) => {
    this.setState({ deleteLoader: true });
    deleteTaskByIdAPI(id, isSeries)
      .then(res => {
        this.populateTasks(this.props.match.params.id);
        this.setState({
          deleteModal: false,
          taskId: null,
          seriesId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(
          this.context.languages["api_error"],
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          }
        );
        this.setState({
          deleteModal: false,
          taskId: null,
          seriesId: null,
          deleteLoader: false
        });
      });
  };

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    const currentIndex = (page - 1) * sizePerPage;
    this.setState({ tableLoader: true, tasks: [] });
    let textFilter = filters.name === undefined ? null : filters.name.filterVal;
    getTasksWithPaginationAPI(page, sizePerPage, textFilter)
      .then(res => {
        if (!Array.isArray(res.list)) {
          return;
        }
        var self = this;
        var data = res.list
          .filter(function (el) {
            if (el.name === "") {
              return false; // skip
            }
            return true;
          })
          .map(function (el) {
            var o = Object.assign({}, el);
            o.actions = (
              <div className="actions">
                <NavLink tag={Link} to={`/company-admin/flow-engine/${el.taskRegardingObjectTypeId}/${el.id}`}>
                  <Tippy placement="top" content={<TranslatableText translationKey="flow" />}>
                    <span>
                      <FontAwesomeIcon icon={faProjectDiagram} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink tag={Link} to={`/tasks/details/${el.id}`}>
                  <Tippy placement="top" content={<TranslatableText translationKey="details" />}>
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink tag={Link} to={`/tasks/update/${el.id}`}>
                  <Tippy placement="top" content={<TranslatableText translationKey="update" />}>
                    <span>
                      <FontAwesomeIcon icon={faEdit} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink onClick={e => self.openDeleteModal(el.id, el.seriesId, e)} >
                  <Tippy placement="top" content={<TranslatableText translationKey="delete" />}>
                    <span>
                      <FontAwesomeIcon style={{ color: "#d84a4a" }} icon={faTrash} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
              </div>
            );
            return o;
          });
        // Handle column filters
        data = data.filter(row => {
          let valid = true;
          for (const dataField in filters) {
            const { filterVal, filterType, comparator } = filters[dataField];

            if (filterType === "TEXT") {
              if (comparator === Comparator.LIKE) {
                valid =
                  row[dataField]
                    .toString()
                    .toLowerCase()
                    .indexOf(filterVal.toLowerCase()) > -1;
              } else {
                valid = row[dataField] === filterVal;
              }
            }
            if (!valid) break;
          }
          return valid;
        });

        this.setState(() => ({
          page,
          tableLoader: false,
          tasks: data,
          totalSize: res.paging.total,
          sizePerPage
        }));
      })
      .catch(error => {
        toast.error(
          this.context.languages["api_error"],
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          }
        );
        this.setState({ tableLoader: false });
      });
  };

  render() {
    let { tasks, tableLoader, sizePerPage, page } = this.state;
    return (
      <div className="tasks-view">
        <Accent useIcon={false}>
          <TranslatableText translationKey="tasks" />
        </Accent>
        <div className="table-container">
          <RemoteAll
            data={tasks}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={this.state.totalSize}
            onTableChange={this.handleTableChange}
            tableLoader={tableLoader}
            path={this.props.match.path}
          />
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeletePopup}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeletePopup}>
            <TranslatableText translationKey="confirm_delete" />?
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <p>
                  <TranslatableText translationKey="tasks.confirmDeleteTask" />
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {this.state.deleteLoader === false ? (
              <div>
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deleteTask(this.state.taskId, false, e)}
                >
                  <TranslatableText translationKey="tasks.deleteTask" />
                </Button>{" "}
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deleteTask(this.state.seriesId, true, e)}
                >
                  <TranslatableText translationKey="tasks.deleteSeries" />
                </Button>
              </div>
            ) : (
              <InlineLoading />
            )}{" "}
            <Button outline onClick={this.toggleDeletePopup}>
              <TranslatableText translationKey="cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Tasks);
Tasks.contextType =  GlobalContext;