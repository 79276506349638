import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { getPersonByIdAPI } from "./personsAPI";
import { toast } from "react-toastify";
import Accent from "../../../../components/accent/Accent";
import Gap from "../../../../components/gap/Gap";
import TranslatableText from "../../../../Language/TranslatableText";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";

class PersonDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: {},
      customFields: []
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getPersonByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            person: res.data,
            customFields:
              res.data.customFieldValues === null
                ? []
                : res.data.customFieldValues
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
    }
  }

  rolesFormatter = cell => {
    return cell.map((item, index) => (
      <span key={item.id}>
        {item.name} {index < cell.length - 1 ? ",\u00A0" : ""}
      </span>
    ));
  };

  arrayFormatter = fields => {
    if (fields !== null && fields !== "" && Array.isArray(fields)) {
      return fields.map((item, index) => (
        <span key={index}>
          {item.label} {index < fields.length - 1 ? ",\u00A0" : ""}
        </span>
      ));
    }
  };

  renderTableData = customField => {
    if (customField.displayTypeCode === 1007)
      if (customField.tagBoxValues.length > 0) {
        return customField.tagBoxValues.map((field, index) => (
          <tr key={index}>
            <td>{field.label}</td>
            <td>{field.description}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan="2">
              {" "}
              <TranslatableText translationKey="no_data_available" />
            </td>
          </tr>
        );
      }
  };
  renderTableHeader = () => {
    return (
      <tr className="thead">
        <th style={{width: '30%'}}>
          <TranslatableText translationKey="name" />
        </th>
        <th style={{width: '70%'}}>
          <TranslatableText translationKey="description" />
        </th>
      </tr>
    );
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div className="user-details-view">
        <h3>
          <TranslatableText translationKey="person" />{" "}
          <TranslatableText translationKey="information" />
        </h3>
        <Row>
          <Col md={12}>
            {this.state.person.firstName !== undefined ? (
              <>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <TranslatableText translationKey="persons.firstName" />
                      </td>
                      <td>{this.state.person.firstName}</td>
                    </tr>
                    <tr>
                      <td>
                        <TranslatableText translationKey="persons.lastName" />
                      </td>
                      <td>{this.state.person.lastName}</td>
                    </tr>
                    <tr>
                      <td>
                        <TranslatableText translationKey="email" />
                      </td>
                      <td>{this.state.person.email}</td>
                    </tr>
                    <tr>
                      <td>
                        <TranslatableText translationKey="phone" />
                      </td>
                      <td>{this.state.person.phone}</td>
                    </tr>
                    <tr>
                      <td>
                        <TranslatableText translationKey="responsible" />
                      </td>
                      <td>{this.state.person.responsibleName}</td>
                    </tr>
                    <tr>
                      <td>
                        <TranslatableText translationKey="address" />
                      </td>
                      <td>{this.state.person.address}</td>
                    </tr>
                    {this.state.customFields.map((element, key) =>
                      element.displayTypeCode !== 1007 ? (
                        <tr key={key}>
                          <td>{element.displayText}</td>
                          <td>
                            {element.value === true
                              ? "Yes"
                              : element.value === false
                              ? "No"
                              : element.displayTypeCode === 1007
                              ? this.arrayFormatter(element.tagBoxValues)
                              : element.value}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )
                    )}
                  </tbody>
                </table>
                <Gap size="50px" />
                <div className="custom-fields-section">
                  {this.state.customFields.map((customField, index) =>
                    customField.displayTypeCode === 1007 ? (
                      <div key={index}>
                        <Accent useHtmlIcon={false}>
                          {customField.displayText}
                        </Accent>
                        <table className="table table-bordered ">
                          <thead>{this.renderTableHeader()}</thead>
                          <tbody>{this.renderTableData(customField)}</tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </>
            ) : (
              <InlineLoading />
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(PersonDetails);
PersonDetails.contextType = GlobalContext;
