import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";
import MultiSlider, { Dot, Progress } from "react-multi-bar-slider";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Swal from "sweetalert2";
import { InlineLoading, ScreenLoading } from "../../components";
import Fields from "../../components/fields/Fields";
import Gap from "../../components/gap/Gap";
import ProgressWidget from "../../components/progress/ProgressWidget";
import FormValidator from "../../components/validator/FormValidator";
import "../../components/validator/FormValidator.scss";
import { GlobalContext } from "../../contexts/global-context";
import TranslatableText from "../../Language/TranslatableText";
import { getEntityCustomFieldsAPI } from "../admin/views/custom-fields/customFieldsAPI";
import Editor from "../admin/views/wizard/views/questions/editor/Editor";
import {
  cancelApprovalAPI,
  getApprovalFlowsProgressV1API, readyApprovalAPI
} from "../company-admin/views/approvals-flow/approvalsFlowAPI";
import { getTaskRegardingObjectTypesAPI } from "../company-admin/views/custom-entities/tenantCustomEntitiesAPI";
import { getSections } from "../company-admin/views/sections/companySectionsAPI";
import { getUsersAPI } from "../company-admin/views/users/usersAPI";
import { getProcessesByIdAPI } from "../dashboard/dashboardAPI";
import { getEntitiesAPI } from "../entity/entityAPI";
import {
  createTaskAPI, getAllRecurrenceTypeAPI, getTaskByIdAPI,
  updateTaskAPI,
  updateTaskStatusAPI
} from "./tasksAPI";



// Global Variables
var customFieldsModel;

class CreateTask extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "recurrenceTypeId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
      },
      {
        field: "selectedOption",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "description",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "dueTime",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "dueDate",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "endDate",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      approvers: [],
      selectedApprovalFlow: "",
      approvalFlowsDropdown: [],
      recurrenceTypeDropDown: [],
      name: "",
      description: "",
      dayOfWeek: "",
      dayOfMonth: "",
      monthOfYear: "",
      dueTime: "",
      endDate: "",
      dueDate: "",
      recurrenceTypeId: "",
      recurrenceTypeName: "",
      regardingObjectId: "",
      regardingObjectTypeId: "",
      progression: "",
      regardingObjectTypeIdDropdown: [],
      entityRecordDropdown: [],
      responsibleIds: [],
      selectedOption: "",
      entityName: "",
      entityTypeName: "",
      isVisibleDM: false,
      isVisibleDW: false,
      isVisibleY: false,
      isVisibleED: true,
      loading: false,
      cuLoader: false,
      taskCompleteLoader: false,
      taskCancelLoader: false,
      inProgressLoader: false,
      statusCode: -1,
      updateSeries: false,
      progress: 0,
      dragedProgress: 0,
      taskRegardingObjectTypeId: "",
      cancelApproval: false,
      approvalCancelLoader: false,
      cancelled: false,
      approvalFlowsDD: [],
      customFields: [],
      validation: this.validator.valid()
    };
    this.submitted = false;
  }
  componentDidMount() {
    getTaskRegardingObjectTypesAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        let entities = res.filter(function (el) {
          if (el.code === 1004) {
            return false; // skip
          }
          return true;
        });

        this.setState({
          regardingObjectTypeIdDropdown: Array.isArray(entities) ? entities : []
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    getUsersAPI()
      .then(res => {
        var userIdAPI = [];
        res.forEach(function (row) {
          userIdAPI.push({ label: row.name, value: row.id });
        });
        this.setState({ responsibleIds: userIdAPI });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
    }

    getAllRecurrenceTypeAPI()
      .then(res => {
        console.log("debug recurrenceType", res);
        this.setState(
          { recurrenceTypeDropDown: Array.isArray(res) ? res : [] },
          () => {
            if (this.props.match.params.id !== undefined) {
              getTaskByIdAPI(this.props.match.params.id)
                .then(res => {
                  // To populate multiselect dropdown we must require ID's and the Name to make an object
                  // Here is the correct way of doing it.
                  // API must return Name and ID
                  var selected = res.data.users.map(el => {
                    var pair = { label: el.name, value: el.id };
                    return pair;
                  });
                  let self = this;
                  var recurrenceType = this.search(
                    res.data.recurrenceTypeId,
                    this.state.recurrenceTypeDropDown
                  );
                  this.setState(
                    {
                      id: res.data.id,
                      name: res.data.name === null ? "" : res.data.name,
                      progression:
                        res.data.progression === null
                          ? ""
                          : res.data.progression,
                      dueTimeDatePicker: this.stringToTimestamp(
                        res.data.dueTime
                      ),
                      endDatePicker: this.dateFormatter(res.data.endDate),
                      dueDatePicker: this.dateFormatter(res.data.actualDueDate),
                      endDate: new Date(res.data.endDate).toISOString(),
                      dueDate: new Date(res.data.actualDueDate).toISOString(),
                      dueTime: res.data.dueTime,
                      dayOfWeek:
                        res.data.dayOfWeek === null ? "" : res.data.dayOfWeek,
                      dayOfMonth:
                        res.data.dayOfMonth === null ? "" : res.data.dayOfMonth,
                      monthOfYear:
                        res.data.monthOfYear === null
                          ? ""
                          : res.data.monthOfYear,
                      description:
                        res.data.description === null
                          ? ""
                          : res.data.description,
                      regardingObjectId:
                        res.data.regardingObjectId === null
                          ? ""
                          : res.data.regardingObjectId,
                      regardingObjectTypeId:
                        res.data.regardingObjectTypeId === null
                          ? ""
                          : res.data.regardingObjectTypeId,
                      recurrenceTypeId: res.data.recurrenceTypeId,
                      selectedOption: selected,
                      postUserIds: selected.map(row => row.value),
                      entityTypeName:
                        res.data.entityTypeName === null
                          ? ""
                          : res.data.entityTypeName,
                      entityName:
                        res.data.entityName === null ? "" : res.data.entityName,
                      statusCode: res.data.statusCode,
                      updateSeries: res.data.updateSeries,
                      taskRegardingObjectTypeId:
                        res.data.taskRegardingObjectTypeId,
                      progress:
                        res.data.progressPercentage === undefined ||
                          res.data.progressPercentage === null ||
                          res.data.progressPercentage === ""
                          ? 0
                          : res.data.progressPercentage,
                      customFields:
                        res.data.customFieldValues === null ||
                          res.data.customFieldValues === undefined
                          ? []
                          : res.data.customFieldValues,
                      loading: false
                    },
                    () => {
                      let userCustomFields =
                        res.data.customFieldValues === null ||
                          res.data.customFieldValues === undefined
                          ? []
                          : res.data.customFieldValues;
                      userCustomFields.forEach(element => {
                        if (element.displayTypeCode === 1007) {
                          self.setState({
                            [element.id]:
                              element.tagBoxValues === null
                                ? ""
                                : element.tagBoxValues
                          });
                        } else {
                          self.setState({
                            [element.name]:
                              element.value === null ? "" : element.value
                          });
                        }
                      });
                    }
                  );
                  if (
                    res.data.progressPercentage === 100 &&
                    res.data.statusCode === 1005
                  ) {
                    getApprovalFlowsProgressV1API(
                      res.data.taskRegardingObjectTypeId,
                      this.props.match.params.id
                    ).then(res => {
                      let list = res.approvalProgresses;
                      list.sort(function (l, r) {
                        return l.approvalFlowOrder - r.approvalFlowOrder;
                      });
                      this.setState({ approvers: list });
                    });
                  }
                  if (
                    recurrenceType !== undefined ||
                    recurrenceType !== null ||
                    recurrenceType !== ""
                  ) {
                    this.showFieldsOnRecurrenceType(recurrenceType.name.trim());
                  }
                })
                .catch(error => {
                  this.setState({ loading: false });
                });

              // TO bypaas the end-date validation on Task edit mode
              this.setState({ endDate: new Date().toISOString() });
            } else {
              this.populateCustomFields(1006);
            }
          }
        );
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
  }

  populateCustomFields = code => {
    this.setState({ loading: true, customFields: [] });
    getEntityCustomFieldsAPI(code)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ customFields: res, loading: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
  };

  handleTriggerTime = date => {
    this.setState({
      dueTimeDatePicker: date,
      dueTime: this.timeFormatter(date)
    });
  };

  handleEndDate = date => {
    var startDate = new Date(this.state.dueDate);
    var endDate = new Date(date);
    var days = Math.floor(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    switch (this.state.recurrenceTypeName.toLowerCase()) {
      case "daily":
        if (days >= 0) {
          this.setState({
            endDatePicker: date,
            endDate: date.toISOString()
          });
        } else {
        }
        break;
      case "weekly":
        if (days >= 7) {
          this.setState({
            endDatePicker: date,
            endDate: date.toISOString()
          });
        } else {
          toast.info(
            "For weekly: The difference should be greater than or equal to 7",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            }
          );
        }
        break;
      case "bi-weekly":
        if (days >= 14) {
          this.setState({
            endDatePicker: date,
            endDate: date.toISOString()
          });
        } else {
          toast.info(
            "For Bi-Weekly: The difference should be greater than or equal to 14",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            }
          );
        }
        break;
      case "monthly":
        if (days >= 28) {
          this.setState({
            endDatePicker: date,
            endDate: date.toISOString()
          });
        } else {
          toast.info(
            "For Monthly: The difference should be greater than or equal to 28",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            }
          );
        }
        break;
      case "bi-monthly":
        if (days >= 56) {
          this.setState({
            endDatePicker: date,
            endDate: date.toISOString()
          });
        } else {
          toast.info(
            "For Bi-Monthly: The difference should be greater than or equal to 56",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            }
          );
        }
        break;
      case "quarterly":
        if (days >= 84) {
          this.setState({
            endDatePicker: date,
            endDate: date.toISOString()
          });
        } else {
          toast.info(
            "For Quarterly: The difference should be greater than or equal to 84",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            }
          );
        }
        break;
      case "half-yearly":
        if (days >= 182) {
          this.setState({
            endDatePicker: date,
            endDate: date.toISOString()
          });
        } else {
          toast.info(
            "For Half-Yearly: The difference should be greater than or equal to 182",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            }
          );
        }
        break;
      case "yearly":
        if (days >= 365) {
          this.setState({
            endDatePicker: date,
            endDate: date.toISOString()
          });
        } else {
          toast.info(
            "For Yearly: The difference should be greater than or equal to 365",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            }
          );
        }
        break;
      default:
      // code block
    }
  };

  handleStartDate = date => {
    if (this.state.recurrenceTypeName === "Once") {
      this.setState({
        dueDatePicker: date,
        dueDate: date.toISOString(),
        endDatePicker: date,
        endDate: date.toISOString()
      });
    } else {
      if (this.props.match.params.id === undefined) {
        this.setState({
          dueDatePicker: date,
          dueDate: date.toISOString(),
          endDatePicker: "",
          endDate: ""
        });
      } else {
        this.setState({
          dueDatePicker: date,
          dueDate: date.toISOString()
        });
      }
    }
  };

  handleSlide = newProgress => {
    this.setState({ progress: newProgress });
  };

  onDragStart = progress => {
    this.setState({ dragedProgress: progress });
  };

  onDragStop = progress => {
    if (progress === 100) {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.complete_task"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.context.languages["swal.are_you_sure.yes"],
        cancelButtonText: this.context.languages["swal.are_you_sure.no"]
      }).then(result => {
        if (result.value) {
          let data = {
            task: {
              id:
                this.props.match.params.id !== undefined
                  ? this.props.match.params.id
                  : "",
              progressPercentage: this.state.progress,
              responsibleIds: this.state.postUserIds,
              dueTime: this.state.dueTime,
              actualDueDate: this.state.dueDate,
              description: this.state.description,
              progression: this.state.progression,
              updateSeries: this.state.updateSeries
            },
            regardingObjectTypeId: this.state.taskRegardingObjectTypeId,
            regardingObjectId: this.props.match.params.id
          };
          this.setState({ loading: true }, () => {
            readyApprovalAPI(data).then(res => {
              getApprovalFlowsProgressV1API(
                this.state.taskRegardingObjectTypeId,
                this.props.match.params.id
              ).then(approvers => {
                if (approvers.approvalProgresses.length > 0) {
                  let list = approvers.approvalProgresses;
                  list.sort(function (l, r) {
                    return l.approvalFlowOrder - r.approvalFlowOrder;
                  });
                  this.setState(
                    {
                      approvers: list,
                      loading: false,
                      statusCode: 1005
                    },
                    () => {
                      Swal.fire(
                        this.context.languages["swal.Success"],
                        this.context.languages["swal.success_waiting_approval"],
                        "success"
                      );
                    }
                  );
                }
              });
            });
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState({ progress: this.state.dragedProgress });
        }
      });
    } else if (
      progress < 100 &&
      this.state.statusCode !== 1004 &&
      this.state.statusCode !== 1002 &&
      this.state.statusCode !== 1003 &&
      this.state.statusCode !== 1005
    ) {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.task_status_progress"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.context.languages["swal.are_you_sure.yes"],
        cancelButtonText: this.context.languages["swal.are_you_sure.no"]
      }).then(result => {
        if (result.value) {
          // Maybe call the update task API here
          this.updateTaskStatus(1004, progress);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState({ progress: this.state.dragedProgress });
        }
      });
    }
  };

  search = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === key) {
        return array[i];
      }
    }
  };

  timeFormatter = timestamp => {
    var d = new Date(timestamp), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
      dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
      hh = d.getHours(),
      h = hh,
      min = ("0" + d.getMinutes()).slice(-2), // Add leading 0.
      sec = ("0" + d.getSeconds()).slice(-2), // Add leading 0.
      ampm = "AM",
      time;

    // ie: 3:00:00
    time = h + ":" + min + ":" + sec;
    return time;
  };

  dateFormatter = timestamp => {
    return new Date(timestamp);
  };

  stringToTimestamp = time => {
    var d = new Date(), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
      dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
      timestamp;

    // ie: 2014-03-24, 3:00 PM
    timestamp = yyyy + "-" + mm + "-" + dd + " " + time;
    return new Date(timestamp);
  };

  getType = type => {
    if (type === "assets") {
      return 1003;
    } else if (type === "processes") {
      return 1001;
    } else if (type === "sections") {
      return 1002;
    } else {
      return 0;
    }
  };
  toggleButton = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };
  goBack = () => {
    this.props.history.goBack();
  };

  createTask = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    let key,
      self = this;

    // Preparing custom fields model
    customFieldsModel = this.state.customFields;
    this.state.customFields.forEach((element, i) => {
      if (element.displayTypeCode === 1007) {
        key = element.id;
        customFieldsModel[i].value = JSON.stringify(
          Array.isArray(self.state[key])
            ? self.state[key].map(row => row.value)
            : []
        );
      } else {
        key = element.name;
        customFieldsModel[i].value = self.state[key];
      }
    });

    var data = {
      organizationId: this.context.user.organizations.organizationId,
      name: this.state.name,
      description: this.state.description,
      updateSeries: this.state.updateSeries,
      progression: this.state.progression,
      dayOfWeek: this.state.dayOfWeek,
      dayOfMonth: this.state.dayOfMonth,
      monthOfYear: this.state.monthOfYear,
      dueTime: this.state.dueTime,
      endDate: this.state.endDate,
      dueDate: this.state.dueDate,
      recurrenceTypeId: this.state.recurrenceTypeId,
      regardingObjectId: this.state.regardingObjectId,
      regardingObjectTypeId: this.state.regardingObjectTypeId,
      responsibleIds: this.state.postUserIds,
      customFieldValues: customFieldsModel,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      userId: this.context.user.organizations.userId
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        data = {
          id:
            this.props.match.params.id !== undefined
              ? this.props.match.params.id
              : "",
          progressPercentage: this.state.progress,
          responsibleIds: this.state.postUserIds,
          dueTime: this.state.dueTime,
          actualDueDate: this.state.dueDate,
          description: this.state.description,
          progression: this.state.progression,
          updateSeries: this.state.updateSeries,
          customFieldValues: customFieldsModel
        };
        this.setState({ cuLoader: true });
        updateTaskAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        if (
          this.state.regardingObjectTypeId !== "" &&
          this.state.regardingObjectId === ""
        ) {
          toast.info("Entity Name cannot be empty when Entity is selected!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 5000
          });
        } else {
          this.setState({ cuLoader: true });
          createTaskAPI(data)
            .then(res => {
              this.setState(
                {
                  cuLoader: false
                },
                () => {
                  this.goBack();
                }
              );
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
              this.setState({ cuLoader: false });
            });
        }
      }
    }
  };

  showFieldsOnRecurrenceType = selected => {
    switch (selected) {
      case "Once":
        this.setState({
          isVisibleDW: false,
          isVisibleDM: false,
          isVisibleED: false,
          isVisibleY: false
        });
        break;
      case "Daily":
        this.setState({
          isVisibleDW: false,
          isVisibleDM: false,
          isVisibleED: true,
          isVisibleY: false
        });
        break;
      case "Weekly":
        this.setState({
          isVisibleDW: true,
          isVisibleDM: false,
          isVisibleED: true,
          isVisibleY: false
        });
        break;
      case "Bi-Weekly":
        this.setState({
          isVisibleDW: true,
          isVisibleDM: false,
          isVisibleED: true,
          isVisibleY: false
        });
        break;
      case "Monthly":
      case "Bi-Monthly":
      case "Quarterly":
      case "Half-Yearly":
        this.setState({
          isVisibleDW: false,
          isVisibleDM: true,
          isVisibleED: true,
          isVisibleY: false
        });
        break;
      case "Yearly":
        this.setState({
          isVisibleDW: false,
          isVisibleDM: true,
          isVisibleED: true,
          isVisibleY: true
        });
        break;
      default:
        this.setState({
          isVisibleDW: false,
          isVisibleDM: false,
          isVisibleED: true
        });
    }
  };

  populateEntityRecordDropdown = (entity, value) => {
    switch (entity) {
      case 1001:
        getProcessesByIdAPI().then(res => {
          this.setState({ entityRecordDropdown: res });
        });
        break;
      case 1002:
        getSections().then(res => {
          this.setState({ entityRecordDropdown: res });
        });
        break;
      case 1000:
        getEntitiesAPI(value).then(res => {
          this.setState({ entityRecordDropdown: res });
        });
        break;
      default:
        this.setState({ entityRecordDropdown: [] });
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "recurrenceTypeId") {
      var selected = target.options[target.selectedIndex].text;
      this.setState({ recurrenceTypeName: selected }, () => {
        this.setState({
          dueDatePicker: "",
          dueDate: "",
          endDatePicker: "",
          endDate: ""
        });
      });
      this.showFieldsOnRecurrenceType(selected.trim());
    }

    if (name === "regardingObjectTypeId") {
      let code = target.options[target.selectedIndex].getAttribute("data-code");
      this.populateEntityRecordDropdown(parseInt(code), value);
    }

    this.setState({
      [name]: value
    });
  };

  handleSelectChange = selectedOption => {
    this.setState({
      postUserIds:
        selectedOption === null || selectedOption === undefined
          ? []
          : selectedOption.map(row => row.value),
      selectedOption: selectedOption === null ? "" : selectedOption
    });
  };

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  getColumnValue = () => {
    let { isVisibleDM, isVisibleDW, isVisibleY } = this.state;
    if (
      (isVisibleDM && !isVisibleDW && !isVisibleY) ||
      (!isVisibleDM && isVisibleDW && !isVisibleY)
    ) {
      return 8;
    } else if ((isVisibleDM && isVisibleDW) || isVisibleY) {
      return 4;
    } else {
      return 12;
    }
  };

  getColumnValueOnRTD = () => {
    let { isVisibleED } = this.state;
    if (isVisibleED) {
      return 4;
    } else {
      return 8;
    }
  };

  updateTaskStatus = (status, progress) => {
    const data = {
      id: this.props.match.params.id,
      tasksStatus: status,
      progressPercentage: status === 1004 && progress === 0 ? 1 : progress
    };
    if (status === 1002) {
      this.setState({ taskCompleteLoader: true });
    } else if (status === 1003) {
      this.setState({ taskCancelLoader: true });
    } else if (status === 1004) {
      this.setState({ inProgressLoader: true });
    }
    updateTaskStatusAPI(data).then(res => {
      if (status === 1002) {
        this.setState(
          {
            taskCompleteLoader: false,
            progress: 100,
            statusCode: status
          },
          () => {
            // this.goBack();
          }
        );
      } else if (status === 1003) {
        this.setState(
          {
            taskCancelLoader: false,
            progress: 0,
            statusCode: status
          },
          () => {
            // this.goBack();
          }
        );
      } else if (status === 1004) {
        this.setState(
          {
            inProgressLoader: false,
            statusCode: status,
            progress: this.state.progress === 0 ? 1 : this.state.progress
          },
          () => {
            // this.goBack();
          }
        );
      }
    });
  };

  cancelTask = status => {
    const data = {
      id: this.props.match.params.id,
      tasksStatus: status,
      progressPercentage: this.state.progress === 100 ? 99 : this.state.progress
    };
    this.setState({ taskCancelLoader: true });
    Swal.fire({
      title: this.context.languages["swal.are_you_sure"],
      text: this.context.languages["swal.are_you_sure.confirm_task_cancel"],
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: this.context.languages["swal.are_you_sure.no"],
      confirmButtonText: this.context.languages["swal.are_you_sure.yes"]
    }).then(result => {
      if (result.isConfirmed) {
        updateTaskStatusAPI(data).then(res => {
          this.setState({
            taskCancelLoader: false,
            progress: this.state.progress === 100 ? 99 : this.state.progress,
            statusCode: status
          });
        });
        Swal.fire(
          this.context.languages["swal.cancelled"],
          this.context.languages["swal.cancelled_task_cancelled"],
          "success"
        );
      } else {
        this.setState({ taskCancelLoader: false });
      }
    });
  };

  completeTask = status => {
    const data = {
      id: this.props.match.params.id,
      tasksStatus: status,
      progressPercentage: 100
    };
    this.setState({ taskCompleteLoader: true });
    Swal.fire({
      title: this.context.languages["swal.are_you_sure"],
      text: this.context.languages["swal.are_you_sure.complete_task"],
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.context.languages["swal.are_you_sure.yes"],
      cancelButtonText: this.context.languages["swal.are_you_sure.no"]
    }).then(result => {
      if (result.value) {
        this.setState({ progress: 100 });
        let data = {
          task: {
            id:
              this.props.match.params.id !== undefined
                ? this.props.match.params.id
                : "",
            progressPercentage: this.state.progress,
            responsibleIds: this.state.postUserIds,
            dueTime: this.state.dueTime,
            actualDueDate: this.state.dueDate,
            description: this.state.description,
            progression: this.state.progression,
            updateSeries: this.state.updateSeries
          },
          regardingObjectTypeId: this.state.taskRegardingObjectTypeId,
          regardingObjectId: this.props.match.params.id
        };
        readyApprovalAPI(data).then(res => {
          getApprovalFlowsProgressV1API(
            this.state.taskRegardingObjectTypeId,
            this.props.match.params.id
          ).then(approvers => {
            if (approvers.approvalProgresses.length === 0) {
              this.setState(
                {
                  taskCompleteLoader: false,
                  progress: 100,
                  statusCode: status
                },
                () => {
                  Swal.fire(
                    this.context.languages["swal.Success"],
                    this.context.languages["swal.success_task_completed"],
                    "success"
                  );
                }
              );
            } else {
              let list = approvers.approvalProgresses;
              list.sort(function (l, r) {
                return l.approvalFlowOrder - r.approvalFlowOrder;
              });
              this.setState(
                {
                  approvers: list,
                  loading: false,
                  statusCode: 1005,
                  taskCompleteLoader: false
                },
                () => {
                  Swal.fire(
                    this.context.languages["swal.Success"],
                    this.context.languages["swal.success_waiting_approval"],
                    "success"
                  );
                }
              );
            }
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.setState({ taskCompleteLoader: false });
        // Swal.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    });
    //}
  };

  handleTagboxChange = (id, selectedOptions) => {
    this.setState({
      [id]: selectedOptions
    });
  };

  changeValidationMessage = (message, event) => {
    let msg = message === null ? "This is a required field" : message;
    event.target.setCustomValidity(msg);
  };

  cancelApproval = e => {
    this.setState({ approvalCancelLoader: true }, () => {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.cancel_flow"],
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.context.languages["swal.are_you_sure.no"],
        confirmButtonText: this.context.languages["swal.are_you_sure.yes"]
      }).then(result => {
        if (result.isConfirmed) {
          let data = {
            regardingObjectTypeId: this.state.taskRegardingObjectTypeId,
            regardingObjectId: this.props.match.params.id
          };
          cancelApprovalAPI(data).then(res => {
            this.setState(
              {
                approvalCancelLoader: false,
                cancelled: true,
                statusCode: 1004,
                progress: this.state.progress === 100 ? 99 : this.state.progress
              },
              () => {
                Swal.fire(
                  this.context.languages["swal.cancelled"],
                  this.context.languages["swal.cancelled_approval_cancelled"],
                  "error"
                );
              }
            );
          });
        } else {
          this.setState({
            approvalCancelLoader: false
          });
        }
      });
    });
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-notification-view">
        {loading && (
          <ScreenLoading />
        )}
        {this.props.match.params.id !== undefined ? (
          <>
            {this.state.statusCode === 1005 ? (
              <ProgressWidget approvers={this.state.approvers} />
            ) : (
              <></>
            )}
            <Row>
              <Col md={12}>
                <h3 className="progress-header">
                  <TranslatableText translationKey="tasks.taskProgress" />{" "}
                  {this.state.progress}%
                </h3>
              </Col>
              <Col md={12}>
                <MultiSlider
                  readOnly={
                    this.state.statusCode === 1005 ||
                      this.state.statusCode === 1002 ||
                      this.state.statusCode === 1003
                      ? true
                      : false
                  }
                  classnName="task-progress"
                  width={"100%"}
                  height={20}
                  backgroundColor="#ccc"
                  equalColor="#42a5f5"
                  style={{ marginBottom: 40 }}
                  slidableZoneSize={40}
                  onSlide={this.handleSlide}
                  onDragStart={progress => this.onDragStart(progress)}
                  onDragStop={progress => this.onDragStop(progress)}
                  roundedCorners
                >
                  <Progress
                    height={20}
                    color="blue"
                    progress={this.state.progress}
                    style={{ overflow: "unset" }}
                  >
                    <Dot width={35} height={35} color="#1976d2" />
                  </Progress>
                </MultiSlider>
              </Col>
            </Row>
          </>
        ) : (
          <Gap size="20px" />
        )}

        <Row>
          <Col>
            <div className="flex space-between">
              <h3>
                {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" />
                  : <TranslatableText translationKey="create" />}{" "}
                <TranslatableText translationKey="task" />
              </h3>
              {this.props.match.params.id !== undefined ? (
                <div>
                  {!this.state.inProgressLoader &&
                    this.state.statusCode !== 1005 ? (
                    <Button
                      className={
                        this.state.statusCode === 1004 ? "orange-bg" : ""
                      }
                      outline
                      onClick={e =>
                        this.updateTaskStatus(1004, this.state.progress)
                      }
                      disabled={this.state.statusCode !== 1001 ? true : null}
                    >
                      <FontAwesomeIcon
                        icon={faClock}
                        size="1x"
                        className="cursor"
                      />
                      <TranslatableText translationKey="tasks.inProgress" />
                    </Button>
                  ) : this.state.statusCode === 1005 &&
                    !this.state.inProgressLoader ? (
                    <></>
                  ) : (
                    <div className="inline-block">
                      <InlineLoading />
                    </div>
                  )}{" "}
                  {this.state.statusCode === 1005 ? (
                    <Button className="orange-bg" outline disabled={true}>
                      <FontAwesomeIcon
                        icon={faClock}
                        size="1x"
                        disabled={true}
                        className="cursor"
                      />
                      <TranslatableText translationKey="tasks.waitingForApproval" />
                    </Button>
                  ) : (
                    <></>
                  )}{" "}
                  {!this.state.taskCompleteLoader &&
                    this.state.statusCode !== 1005 ? (
                    <Button
                      className={
                        this.state.statusCode === 1002 ? "green-bg" : ""
                      }
                      outline
                      onClick={e => this.completeTask(1002, e)}
                      disabled={
                        this.state.statusCode !== 1001 &&
                          this.state.statusCode !== 1004
                          ? true
                          : null
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="1x"
                        className="cursor"
                      />
                      {this.state.statusCode === 1002
                        ? "Completed"
                        : "Mark Complete"}
                    </Button>
                  ) : !this.state.taskCompleteLoader &&
                    this.state.statusCode === 1005 ? (
                    <></>
                  ) : (
                    <div className="inline-block">
                      <InlineLoading />
                    </div>
                  )}{" "}
                  {!this.state.taskCancelLoader &&
                    this.state.statusCode !== 1005 ? (
                    <Button
                      className={this.state.statusCode === 1003 ? "red-bg" : ""}
                      outline
                      onClick={e => this.cancelTask(1003, e)}
                      disabled={
                        this.state.statusCode !== 1001 &&
                          this.state.statusCode !== 1004
                          ? true
                          : null
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        size="1x"
                        className="cursor"
                      />
                      {this.state.statusCode === 1003
                        ? "Cancelled"
                        : "Mark Cancel"}
                    </Button>
                  ) : !this.state.taskCancelLoader &&
                    this.state.statusCode === 1005 ? (
                    <></>
                  ) : (
                    <div className="inline-block">
                      <InlineLoading />
                    </div>
                  )}
                  {this.state.statusCode === 1005 ? (
                    <span className="separator"></span>
                  ) : (
                    <></>
                  )}
                  {!this.state.approvalCancelLoader &&
                    this.state.statusCode === 1005 ? (
                    <Button outline onClick={e => this.cancelApproval(e)}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        size="1x"
                        className="cursor"
                      />
                      <TranslatableText translationKey="tasks.cancelApproval" />
                    </Button>
                  ) : !this.state.approvalCancelLoader &&
                    this.state.statusCode !== 1005 ? (
                    <></>
                  ) : (
                    <div className="inline-block">
                      <InlineLoading />
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
        <Form onSubmit={this.createTask}>
          <Row form className="top-container">
            <Col md={6}>
              {this.props.match.params.id !== undefined ? (
                <FormGroup
                  className={validation.name.isInvalid ? "has-error" : ""}
                >
                  <Label for="name">
                    <TranslatableText translationKey="tasks.entity" />
                  </Label>
                  <Input
                    type="text"
                    name="entityTypeName"
                    id="entityTypeName"
                    placeholder=""
                    value={this.state.entityTypeName}
                    onChange={this.handleChange}
                    disabled={
                      this.props.match.params.id !== undefined ||
                        this.state.statusCode === 1005 ||
                        this.state.statusCode === 1002
                        ? true
                        : null
                    }
                  />
                  <span className="help-block">{validation.name.message}</span>
                </FormGroup>
              ) : (
                <FormGroup>
                  <Label for="regardingObjectTypeId"><TranslatableText translationKey="tasks.entity" /></Label>
                  <Input
                    type="select"
                    name="regardingObjectTypeId"
                    id="regardingObjectTypeId"
                    value={this.state.regardingObjectTypeId}
                    onChange={this.handleChange}
                    disabled={
                      this.props.match.params.id !== undefined ||
                        this.state.statusCode === 1005 ||
                        this.state.statusCode === 1002
                        ? true
                        : null
                    }
                  >
                    <option value="">{this.context.languages["-- Select --"]}</option>
                    {this.state.regardingObjectTypeIdDropdown.map((e, key) => {
                      return (
                        <option key={key} data-code={e.code} value={e.id}>
                          {e.displayName}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              )}
            </Col>
            <Col md={6}>
              {this.props.match.params.id !== undefined ? (
                <FormGroup
                  className={validation.name.isInvalid ? "has-error" : ""}
                >
                  <Label for="name"><TranslatableText translationKey="tasks.entityName" /></Label>
                  <Input
                    type="text"
                    name="entityName"
                    id="entityName"
                    placeholder=""
                    value={this.state.entityName}
                    onChange={this.handleChange}
                    disabled={
                      this.props.match.params.id !== undefined ||
                        this.state.statusCode === 1005 ||
                        this.state.statusCode === 1002
                        ? true
                        : null
                    }
                  />
                  <span className="help-block">{validation.name.message}</span>
                </FormGroup>
              ) : (
                <FormGroup>
                  <Label for="regardingObjectId"><TranslatableText translationKey="tasks.entityName" /></Label>
                  <Input
                    type="select"
                    name="regardingObjectId"
                    id="regardingObjectId"
                    className={
                      this.state.regardingObjectTypeId !== "" &&
                        this.state.regardingObjectId === ""
                        ? "has-error"
                        : ""
                    }
                    value={this.state.regardingObjectId}
                    onChange={this.handleChange}
                    disabled={
                      this.props.match.params.id !== undefined ||
                        this.state.statusCode === 1005 ||
                        this.state.statusCode === 1002
                        ? true
                        : null
                    }
                  >
                    <option value="">{this.context.languages["-- Select --"]}</option>
                    {this.state.entityRecordDropdown.map((e, key) => {
                      return (
                        <option key={e.id} value={e.id}>
                          {e.name === undefined ? e.nameShort : e.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              )}
            </Col>
          </Row>

          <hr />

          <Row form>
            <Col className="multiselect-container" md={6}>
              <FormGroup
                className={
                  validation.selectedOption.isInvalid ? "has-error" : ""
                }
              >
                <Label for="sysname"><TranslatableText translationKey="tasks.responsable" /></Label>
                <Select
                  isMulti
                  value={
                    this.state.selectedOption === ""
                      ? null
                      : this.state.selectedOption
                  }
                  className="user-selectbox"
                  options={this.state.responsibleIds}
                  onChange={this.handleSelectChange}
                  placeholderButtonLabel="Select Users"
                  hideSelectedOptions={true}
                  isDisabled={
                    this.state.statusCode === 1005 ||
                      this.state.statusCode === 1002
                      ? true
                      : false
                  }
                />
                <span className="help-block">
                  {validation.selectedOption.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={
                  validation.recurrenceTypeId.isInvalid ? "has-error" : ""
                }
              >
                <Label for="recurrenceTypeId"><TranslatableText translationKey="tasks.recurrenceType" /></Label>
                <Input
                  type="select"
                  name="recurrenceTypeId"
                  id="recurrenceTypeId"
                  value={this.state.recurrenceTypeId}
                  onChange={this.handleChange}
                  disabled={
                    this.props.match.params.id !== undefined ||
                      this.state.statusCode === 1005 ||
                      this.state.statusCode === 1002
                      ? true
                      : null
                  }
                >
                  <option value="">{this.context.languages["-- Select --"]}</option>
                  {this.state.recurrenceTypeDropDown.map((e, key) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                <span className="help-block">
                  {validation.recurrenceTypeId.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={this.getColumnValue()}>
              <FormGroup
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name"><TranslatableText translationKey="tasks.name" /></Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                  disabled={
                    // this.props.match.params.id !== undefined ||
                    this.state.statusCode === 1005 ||
                      this.state.statusCode === 1002
                      ? true
                      : null
                  }
                />
                <span className="help-block">{validation.name.message}</span>
              </FormGroup>
            </Col>

            <Col
              className={classnames(this.state.isVisibleDW ? "show" : "hide")}
              md={4}
            >
              <FormGroup>
                <Label for="dayOfWeek"><TranslatableText translationKey="day_of_week" /></Label>
                <Input
                  type="select"
                  name="dayOfWeek"
                  id="dayOfWeek"
                  value={this.state.dayOfWeek}
                  onChange={this.handleChange}
                  disabled={
                    this.props.match.params.id !== undefined ||
                      this.state.statusCode === 1005 ||
                      this.state.statusCode === 1002
                      ? true
                      : null
                  }
                >
                  <option value="">{this.context.languages["-- Select --"]}</option>
                  <option value="1">{this.context.languages["monday"]}</option>
                  <option value="2">{this.context.languages["tuesday"]}</option>
                  <option value="3">{this.context.languages["wednesday"]}</option>
                  <option value="4">{this.context.languages["thursday"]}</option>
                  <option value="5">{this.context.languages["friday"]}</option>
                  <option value="6">{this.context.languages["saturday"]}</option>
                  <option value="7">{this.context.languages["sunday"]}</option>
                </Input>
              </FormGroup>
            </Col>
            <Col
              className={classnames(this.state.isVisibleDM ? "show" : "hide")}
              md={4}
            >
              <FormGroup>
                <Label for="dayOfMonth"><TranslatableText translationKey="day_of_month" /></Label>
                <Input
                  type="number"
                  name="dayOfMonth"
                  id="dayOfMonth"
                  placeholder=""
                  value={this.state.dayOfMonth}
                  onChange={this.handleChange}
                  disabled={
                    this.props.match.params.id !== undefined ||
                      this.state.statusCode === 1005 ||
                      this.state.statusCode === 1002
                      ? true
                      : null
                  }
                />
              </FormGroup>
            </Col>
            <Col
              className={classnames(this.state.isVisibleY ? "show" : "hide")}
              md={4}
            >
              <FormGroup>
                <Label for="monthOfYear"><TranslatableText translationKey="month_of_year" /></Label>
                <Input
                  type="select"
                  name="monthOfYear"
                  id="monthOfYear"
                  placeholder=""
                  value={this.state.monthOfYear}
                  onChange={this.handleChange}
                  disabled={
                    this.props.match.params.id !== undefined ||
                      this.state.statusCode === 1005 ||
                      this.state.statusCode === 1002
                      ? true
                      : null
                  }
                >
                  <option value="">{this.context.languages["-- Select --"]}</option>
                  <option value="1">{this.context.languages["january"]}</option>
                  <option value="2">{this.context.languages["febuary"]}</option>
                  <option value="3">{this.context.languages["march"]}</option>
                  <option value="4">{this.context.languages["april"]}</option>
                  <option value="5">{this.context.languages["may"]}</option>
                  <option value="6">{this.context.languages["june"]}</option>
                  <option value="7">{this.context.languages["july"]}</option>
                  <option value="8">{this.context.languages["august"]}</option>
                  <option value="9">{this.context.languages["september"]}</option>
                  <option value="10">{this.context.languages["october"]}</option>
                  <option value="11">{this.context.languages["november"]}</option>
                  <option value="12">{this.context.languages["december"]}</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={this.getColumnValueOnRTD()}>
              <FormGroup
                className={validation.dueTime.isInvalid ? "has-error" : ""}
              >
                <Label for="dueTime"><TranslatableText translationKey="tasks.dueTime" /></Label>
                <DatePicker
                  className="form-control datepicker timepicker"
                  selected={this.state.dueTimeDatePicker}
                  onChange={this.handleTriggerTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm:ss"
                  disabled={
                    this.state.statusCode === 1005 ||
                      this.state.statusCode === 1002
                      ? true
                      : false
                  }
                />
                <span className="help-block">{validation.dueTime.message}</span>
              </FormGroup>
            </Col>
            <Col
              md={
                this.props.match.params.id === undefined
                  ? 4
                  : !this.state.isVisibleDW &&
                    !this.state.isVisibleDM &&
                    !this.state.isVisibleED &&
                    !this.state.isVisibleY
                    ? 4
                    : 8
              }
            >
              <FormGroup
                className={validation.dueDate.isInvalid ? "has-error" : ""}
              >
                <Label for="dueDate"><TranslatableText translationKey="tasks.DueDate2" /></Label>
                <DatePicker
                  className="form-control datepicker"
                  selected={this.state.dueDatePicker}
                  onChange={this.handleStartDate}
                  minDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                  disabled={
                    this.state.recurrenceTypeId === "" ||
                      this.state.statusCode === 1005 ||
                      this.state.statusCode === 1002
                      ? true
                      : null
                  }
                />
                <span className="help-block">{validation.dueDate.message}</span>
              </FormGroup>
            </Col>
            {this.props.match.params.id === undefined ? (
              <Col
                className={classnames(this.state.isVisibleED ? "show" : "hide")}
                md={4}
              >
                <FormGroup
                  className={validation.endDate.isInvalid ? "has-error" : ""}
                >
                  <Label for="endDate"><TranslatableText translationKey="tasks.endDate" /></Label>
                  <DatePicker
                    className="form-control datepicker"
                    selected={this.state.endDatePicker}
                    onChange={this.handleEndDate}
                    minDate={
                      this.state.dueDatePicker === undefined
                        ? new Date()
                        : this.state.dueDatePicker
                    }
                    dateFormat="yyyy-MM-dd"
                    disabled={
                      this.state.recurrenceTypeId === "" ||
                        this.state.dueDate === "" ||
                        this.state.statusCode === 1005 ||
                        this.state.statusCode === 1002
                        ? true
                        : null
                    }
                  />
                  <span className="help-block">
                    {validation.endDate.message}
                  </span>
                </FormGroup>
              </Col>
            ) : (
              ""
            )}
            <Col md={12}>
              <FormGroup>
                <Label for="progression"><TranslatableText translationKey="tasks.progression" /></Label>
                <Editor initialValue={this.state.progression} callback={e => this.setState({ progression: e })}
                  disabled={this.state.statusCode === 1005 ||
                    this.state.statusCode === 1002
                    ? true
                    : false} />

              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup
                className={validation.description.isInvalid ? "has-error" : ""}
              >
                <div className="flex space-between">
                  <Label for="description"><TranslatableText translationKey="tasks.description" /></Label>
                </div>
                <Editor initialValue={this.state.description} callback={e => this.setState({ description: e })}
                  disabled={this.state.statusCode === 1005 ||
                    this.state.statusCode === 1002
                    ? true
                    : false} />
                <span className="help-block">
                  {validation.description.message}
                </span>
              </FormGroup>
            </Col>

            <Fields
              customFields={this.state.customFields}
              componentState={this.state}
              handleChange={this.handleChange}
              handleTagboxChange={this.handleTagboxChange}
              changeValidationMessage={this.changeValidationMessage}
            />

            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}><TranslatableText translationKey="back_to_list" /></Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ? <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
        <Gap size="50px" />
      </div>
    );
  }
}

export default withRouter(CreateTask);
CreateTask.contextType = GlobalContext;