import { faAngleDown, faAngleRight, faInfoCircle, faMinusSquare, faPlusSquare, faUserNinja } from "@fortawesome/pro-regular-svg-icons";
import { faCloudDownloadAlt, faEdit, faEnvelope, faFileCode, faHatWizard, faPaperclip, faPencilAlt, faPlay, faProjectDiagram, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import Tippy from "@tippyjs/react";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription, ExpandRowProps } from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, NavLink, Row } from "reactstrap";
import { CustomEntityValuesClient, DeleteCustomEntityValueRequest, GetCustomEntityValueResponse, GetRegardingObjectTypeResponse, ProcessesClient, RegardingObjectTypesClient, ReportsClient, UpdateMultipleProcessesRequest, UsersClient } from "../../../apiClients";
import HandleCaught_API_Error, { HandleCaught_API_Error_Base } from "../../../apiClients/api_caughtError";
import { InlineLoading } from "../../../components";
import { Table_GetLocalStorage, Table_HeaderFormatter, Table_NoDataIndication, Table_SetLocalStorage } from "../../../components/table";
import { AccountLevel, Actions } from "../../../config/Levels";
import { ResourceTypes } from "../../../config/ResourceTypes";
import { GlobalContext } from "../../../contexts/global-context";
import TranslatableText from "../../../Language/TranslatableText";
import { triggerApprovalFlowAPI } from "../../company-admin/views/approvals-flow/approvalsFlowAPI";
import { getJsonSpecForReferenceObj } from "../../company-admin/views/process/companyProcessAPI";
import { getUsersAPI } from '../../company-admin/views/users/usersAPI';
import { EntityExpandedRowRenderer } from "./EntityExpandedRowRenderer";

type Props = {
    regardingObjectTypeId: string,
}

const EntityList = (props: Props) => {
    const globalContext = useContext(GlobalContext);
    const { regardingObjectTypeId } = props;
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;
    const _CustomEntityValuesClient = new CustomEntityValuesClient();
    const _RegardingObjectTypesClient = new RegardingObjectTypesClient();
    const _UsersClient = new UsersClient();
    const [checkedEntities, setCheckedEntities] = useState<any>([]);
    const tableColumns: Array<ColumnDescription<GetCustomEntityValueResponse>> =
        [
            {
                dataField: "id",
                text: "ID",
                hidden: true
            },
            {
                dataField: "name",
                text: "name",
                headerFormatter: Table_HeaderFormatter,
                sort: true
            },
            {
                dataField: "description",
                text: "description",
                headerFormatter: Table_HeaderFormatter,
                sort: true
            },
            {
                dataField: "responsible",
                text: "responsible",
                headerFormatter: Table_HeaderFormatter,
                sort: true
            },
            {
                dataField: "actions",
                text: "action",
                headerFormatter: Table_HeaderFormatter,
                csvExport: false,
                formatter: (_cell, row) => {
                    return (
                        <div className="actions">
                            {row.attachment &&
                                <NavLink tag={Link} to={`/entity/attachments/${row.id}`}>
                                    <FontAwesomeIcon icon={faPaperclip} />
                                </NavLink>
                            }

                            {row.regardingObjectTypeId !== ResourceTypes.notes &&
                                <>
                                    <NavLink
                                        tag={Link}
                                        to={`/company-admin/flow-engine/${row.regardingObjectTypeId}/${row.id}`}
                                    >
                                        <FontAwesomeIcon icon={faProjectDiagram} />
                                    </NavLink>
                                    {row.flowEngine ? (
                                        <NavLink
                                            onClick={() => triggerApprovalFlow(row.regardingObjectTypeId, row.id, Actions.onUserRequest)}
                                        >
                                            <Tippy placement="top" content="Trigger Flow">
                                                <span>
                                                    <FontAwesomeIcon icon={faPlay} />
                                                </span>
                                            </Tippy>
                                        </NavLink>
                                    ) : (
                                        <NavLink>
                                            <Tippy placement="top" content="No Flow Attached">
                                                <span>
                                                    <FontAwesomeIcon
                                                        style={{ color: "#ccc" }}
                                                        icon={faPlay}
                                                    />
                                                </span>
                                            </Tippy>
                                        </NavLink>
                                    )}
                                </>
                            }

                            <NavLink tag={Link} to={`/entity/details/${row.id}`}>
                                <Tippy placement="top" content={<TranslatableText translationKey="information" />}>
                                    <span>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </span>
                                </Tippy>
                            </NavLink>

                            <NavLink
                                tag={Link}
                                to={`/entity/update/${row.regardingObjectTypeId}/${row.id}`}
                            >
                                <Tippy placement="top" content={<TranslatableText translationKey="edit" />}>
                                    <span>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </span>
                                </Tippy>
                            </NavLink>

                            <NavLink
                                onClick={() => downloadFile(row.id, row.regardingObjectTypeId, row.name ?? row.entityName!)}
                            >
                                <Tippy placement="top" content={<TranslatableText translationKey="Download" />}>
                                    <span>
                                        <FontAwesomeIcon icon={faCloudDownloadAlt} />
                                    </span>
                                </Tippy>
                            </NavLink>

                            {row.wizardId !== null &&
                                <NavLink
                                    tag={Link}
                                    to={`/entity/wizard/${row.wizardId}/${row.id}/${row.regardingObjectTypeId}`}
                                >
                                    <Tippy placement="top" content={<TranslatableText translationKey="edit wizard" />}>
                                        <span>
                                            <FontAwesomeIcon icon={faHatWizard} />
                                        </span>
                                    </Tippy>
                                </NavLink>
                            }

                            {row.wizardId !== null && regardingObjectTypesQuery.isFetched && regardingObjectTypesQuery.data?.externalQuestionnaire &&
                                <NavLink
                                    tag={Link}
                                    to={`/entity/wizard/${row.wizardId}/${row.id}/${row.regardingObjectTypeId}/invite`}
                                >
                                    <Tippy placement="top" content={<TranslatableText translationKey="questionnaire.invite_to_answer" />}>
                                        <span>
                                            <FontAwesomeIcon icon={faEnvelope} size="1x" />
                                        </span>
                                    </Tippy>
                                </NavLink>
                            }

                            {globalContext.user.organizations.accountLevel >= AccountLevel.Partner &&
                                <NavLink onClick={() => getReferenceObjectJson(row.id, row.regardingObjectTypeId)}>
                                    <Tippy placement="top" content="JSON Spec.">
                                        <span>
                                            <FontAwesomeIcon icon={faFileCode} />
                                        </span>
                                    </Tippy>
                                </NavLink>
                            }

                            {!row.lock &&
                                <NavLink onClick={() => openDeleteModal(row)}>
                                    <Tippy placement="top" content={<TranslatableText translationKey="delete" />}>
                                        <span>
                                            <FontAwesomeIcon icon={faTrash} style={{ color: "#d84a4a" }} />
                                        </span>
                                    </Tippy>
                                </NavLink>
                            }
                        </div>
                    );
                }
            },
        ];



    // Expand row -- Being
    // const [columns, setColumns] = useState<any[]>(tableColumns);
    const [currentRow, setCurrentRow] = useState<GetCustomEntityValueResponse[]>([]);
    //TableCheckBoxes
    // const [checkedEntities, setCheckedEntities] = useState<any>([]);
    const [deselectAll, setDeselectAll] = useState<any>(false);
    const [responsibleModal, setResponsibleModal] = useState<any>(false);
    const [responsibleUser, setResponsibleUser] = useState<string>("");

    const regardingObjectTypesQuery = useQuery(['regardingObjectTypes', regardingObjectTypeId], async () => {
        return _RegardingObjectTypesClient.regardingObjectTypes(regardingObjectTypeId);
    });
    const customEntityValuesQuery = useQuery(['customEntityValuesGetByRegardingObjectTypeId', regardingObjectTypeId], async () => {
        return _CustomEntityValuesClient.customEntityValuesGetByRegardingObjectTypeId(regardingObjectTypeId);
    });
    const users = useQuery(['userList'], async () => {
        return _UsersClient.userList();
    });

    if (customEntityValuesQuery.isError) {
        HandleCaught_API_Error(customEntityValuesQuery.error)
    }
    function RemoveEntityFromList(id: string) {
        customEntityValuesQuery.refetch();
        // customEntityValuesQuery.data = customEntityValuesQuery.data?.filter(item => item.id !== id);
    }

    function RemoveEntitiesFromList(ids: string[]) {
        customEntityValuesQuery.refetch();
        // let temp = entities;
        // for (let i = 0; i < ids.length; i++) {
        //   temp = temp.filter(item => item.id !== ids[i])
        // }
        // setState((prevState) => ({ ...prevState, entities: temp }));
    }
    const addEntryClick = (newElm: GetCustomEntityValueResponse) => {
        setCurrentRow(prevState => [...prevState, newElm]);
    };
    function handleOnExpand(row: GetCustomEntityValueResponse, isExpand: boolean, rowIndex: number, e: SyntheticEvent) {
        if (isExpand) {
            _CustomEntityValuesClient.customEntityValuesGet(row.id)
                .then(res => { addEntryClick(res); });
        }
    }
    function handleOnExpandAll(isExpandAll: boolean, rows: GetCustomEntityValueResponse[], event: SyntheticEvent) {
        if (isExpandAll) {
            rows.map((row: GetCustomEntityValueResponse) => {
                _CustomEntityValuesClient.customEntityValuesGet(row.id)
                    .then(res => { addEntryClick(res); });
            })
        }
    }

    const expandRow: ExpandRowProps<GetCustomEntityValueResponse, number> = {
        showExpandColumn: true,
        expandByColumnOnly: true,
        renderer: (row) => {
            return EntityExpandedRowRenderer(currentRow, row);
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return isAnyExpands ? <FontAwesomeIcon icon={faMinusSquare} /> : <FontAwesomeIcon icon={faPlusSquare} />;
        },
        expandColumnRenderer: ({ expanded }) => {
            return expanded ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />;
        },
        onExpand: handleOnExpand,
        onExpandAll: handleOnExpandAll
    };
    // Expand row -- End


    const selectRow = {
        mode: 'checkbox',
        hideSelectAll: false,
        clickToSelect: false,
        selectColumnPosition: 'right',
        // @ts-ignore
        onSelect: (row, isSelect, rowIndex, e) => {
            let temp = [...checkedEntities];
            if (e.target.checked) {
                temp.push(row)
            } else {
                temp = temp.filter((val) => { return val.id != row.id })
            }
            setCheckedEntities(temp);
        },
        // @ts-ignore
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                setCheckedEntities(rows);
            }
            else {
                setCheckedEntities([]);
            }
        }
    };

    const openResponsibleModal = () => {
        if (checkedEntities.length > 0) {
            setResponsibleModal(true)
        } else {
            toast.info(<TranslatableText translationKey="processes.no_process_selected" />);
        }
    };
    const toggleResponsiblePopup = () => {
        setResponsibleModal(!responsibleModal);
    };
    const submitMultipleResponsible = () => {
        if (responsibleUser.length > 0) {
            let idList: string[] = [];
            for (let i = 0; i < checkedEntities.length; i++) {
                idList.push(checkedEntities[i].id)
            }
            //change api call
            let updateMultipleProcessesRequest = new UpdateMultipleProcessesRequest()
            updateMultipleProcessesRequest.idList = idList;
            updateMultipleProcessesRequest.responsibleId = responsibleUser;
            //change api call
            _CustomEntityValuesClient.multiple(updateMultipleProcessesRequest)
                .then(res => {
                    setResponsibleModal(false);
                    setCheckedEntities([]);
                    window.location.reload();
                })
                .catch(HandleCaught_API_Error_Base);
        } else {
            toast.error("No responsible user selected", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
            });
        }
    }

    const getReferenceObjectJson = (id: string, typeId: string) => {
        getJsonSpecForReferenceObj(id, typeId, globalContext.user.organizations.organizationId)
            .catch(HandleCaught_API_Error_Base);
    };

    const triggerApprovalFlow = (
        regardingObjectTypeId: string,
        regardingObjectId: string,
        actionType: number
    ) => {
        let data = {
            regardingObjectTypeId: regardingObjectTypeId,
            regardingObjectId: regardingObjectId,
            actionType: actionType
        };
        triggerApprovalFlowAPI(data)
            .then(res => {
                toast.info("Flow Triggered");
            })
            .catch(HandleCaught_API_Error_Base);
    };
    const downloadFile = (id: string, typeId: string, name: string) => {
        var downloadReportToast = toast.info(
            "Downloading report, please wait...",
            {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: false
            }
        );
        let reportClient = new ReportsClient();
        reportClient
            .downloadEntityReportGet(typeId, id)
            .then(res => {
                toast.dismiss(downloadReportToast);
                var response = res.data;
                const url = window.URL.createObjectURL(
                    new Blob([response], { type: res.headers!["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                let result = name.replaceAll(".", "");
                link.setAttribute("download", result); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                toast.dismiss(downloadReportToast);
                HandleCaught_API_Error(error);
            });
    };


    // Delete Entity -- Begin
    const [deleteModalToggle, setDeleteModalToggle] = useState(false);
    const [deleteMultipleModalToggle, setDeleteMultipleModalToggle] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [deleteEntityId, setDeleteEntityId] = useState('');

    // @ts-ignore
    const openDeleteModal = (row) => {
        if (row) {
            setDeleteEntityId(row.id)
        }
        setDeleteModalToggle(!deleteModalToggle);
    }
    const openDeleteMultipleModal = () => {
        if (checkedEntities.length === 0) {
            return toast.info("Please Select.");
        }
        setDeleteMultipleModalToggle(!deleteModalToggle);
    }
    const toggleDeleteModal = () => setDeleteModalToggle(!deleteModalToggle);
    const toggleDeleteMultipleModal = () => setDeleteMultipleModalToggle(!deleteMultipleModalToggle);

    const deleteEntity = () => {
        setDeleteLoader(true);
        let data = new DeleteCustomEntityValueRequest();
        data.id = deleteEntityId;

        _CustomEntityValuesClient.customEntityValuesDelete(data)
            .then(res => {
                toast.info(<TranslatableText translationKey="entity deleted" />);
                RemoveEntityFromList(deleteEntityId);
            })
            .catch(error => HandleCaught_API_Error(error))
            .finally(() => {
                setDeleteLoader(false);
                setDeleteModalToggle(false);
                setDeleteEntityId('');
            })
    }

    const deleteMultipleEntity = () => {
        let ids = checkedEntities;
        let tempIds: string[] = []
        setDeleteLoader(true);
        for (let i = 0; i < ids.length; i++) {
            let data = new DeleteCustomEntityValueRequest();
            data.id = ids[i].id;
            Promise.all([
                _CustomEntityValuesClient.customEntityValuesDelete(data)
                    .then(res => {
                        tempIds.push(ids[i].id);
                        toast.info(<TranslatableText translationKey="entity deleted" />);
                    })
                    .catch(error => HandleCaught_API_Error(error))
                    .finally(() => {
                        setDeleteLoader(false);
                        setDeleteModalToggle(false);
                        setDeleteMultipleModalToggle(false);
                        setDeleteEntityId('');
                        setCheckedEntities([]);
                    })
            ]).finally(() => {
                for (let i = 0; i < tempIds.length; i++) {
                    RemoveEntitiesFromList(tempIds);
                }
            })
        }

    };
    return (
        <div className="table-container">
            <ToolkitProvider
                bootstrap4
                keyField="id"
                data={customEntityValuesQuery.data ?? []}
                columns={tableColumns}
                search
                exportCSV={{
                    fileName: "data.csv",
                    separator: globalContext.user.language === 'dk' ? ';' : ',',
                    ignoreHeader: false,
                    noAutoBOM: false
                }}
            >
                {props => (
                    <div>
                        <div className="flex space-between">
                            <div>
                                <SearchBar {...props.searchProps} />
                            </div>
                            <div>
                                <div className="flex space-between">
                                    {globalContext.user.organizations.accountLevel >= AccountLevel.Partner &&
                                        <ExportCSVButton
                                            className="export-btn"
                                            {...props.csvProps}
                                        >
                                            <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                                    }
                                    <div>
                                        <NavLink
                                            className="button-primary"
                                            tag={Link}
                                            to={`/entity/create/${regardingObjectTypeId}`}
                                        >
                                            <TranslatableText translationKey="create" />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <BootstrapTable
                            // @ts-ignore
                            selectRow={selectRow}
                            classes="organization-list"
                            expandRow={expandRow}
                            noDataIndication={() => customEntityValuesQuery.isFetching ? (<InlineLoading />) : (<Table_NoDataIndication />)}
                            filter={filterFactory()}
                            {...props.baseProps}
                            pagination={paginationFactory({ sizePerPage: Table_GetLocalStorage(), onSizePerPageChange: e => Table_SetLocalStorage(e) })} // OBS <-- implement localStorage here
                        // find out how to get the sizePerPage that the user chooses
                        // docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Pagination&selectedStory=Custom%20SizePerPage&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
                        />
                    </div>
                )}
            </ToolkitProvider>
            {(AccountLevel.Partner <= globalContext.user.organizations.accountLevel) && (
                <>
                    <Button className="float-right mr-1" outline color="success" size="sm" onClick={openResponsibleModal}>
                        <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                        <TranslatableText translationKey="processes.responsible" />
                    </Button>
                    <Button className="float-right mr-1" outline color="danger" size="sm" onClick={openDeleteMultipleModal}>
                        <FontAwesomeIcon icon={faTrash} size="1x" />
                        <TranslatableText translationKey="delete" />
                    </Button>
                </>
            )}
            <Modal
                isOpen={deleteModalToggle}
                toggle={toggleDeleteModal}
            >
                <ModalHeader toggle={toggleDeleteModal}>
                    <TranslatableText translationKey="entity.confirmDelete" />
                </ModalHeader>
                <ModalBody>
                    <Row form>
                        <Col md={12}>
                            <p>
                                <TranslatableText translationKey="entity.confirmDelete" />
                            </p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {deleteLoader ? (
                        <InlineLoading loading={deleteLoader} />
                    ) : (
                        <>
                            <Button
                                outline
                                className="redOutline"
                                onClick={() => deleteEntity()}
                            >
                                <TranslatableText translationKey="delete" />
                            </Button>

                        </>
                    )}&nbsp;
                    <Button outline onClick={() => toggleDeleteModal()}>
                        <TranslatableText translationKey="cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={deleteMultipleModalToggle}
                toggle={toggleDeleteMultipleModal}
            >
                <ModalHeader toggle={toggleDeleteMultipleModal}>
                    <TranslatableText translationKey="entity.confirmDelete" />
                </ModalHeader>
                <ModalBody>
                    <Row form>
                        <Col md={12}>
                            <p>
                                <TranslatableText translationKey="entity.confirmDelete" />
                            </p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {deleteLoader ? (
                        <InlineLoading loading={deleteLoader} />
                    ) : (
                        <>
                            <Button
                                outline
                                className="redOutline"
                                onClick={() => deleteMultipleEntity()}
                            >
                                <TranslatableText translationKey="delete_all" />
                            </Button>

                        </>
                    )}&nbsp;
                    <Button outline onClick={() => toggleDeleteMultipleModal()}>
                        <TranslatableText translationKey="cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={responsibleModal}
                toggle={toggleResponsiblePopup}
            >
                <ModalHeader toggle={toggleResponsiblePopup}>
                    <TranslatableText translationKey="processes.responsible" />
                </ModalHeader>
                <ModalBody>
                    <Row form>
                        <Col md={12}>
                            <TranslatableText translationKey="processes.choose_responsible" />
                            <br />
                            <Select
                                options={users.data?.map(user => ({ label: user.name, value: user.id }))}
                                onChange={selectedOptions => setResponsibleUser(selectedOptions!.value)}
                                hideSelectedOptions={false}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        outline
                        color="success"
                        onClick={e => submitMultipleResponsible()}
                    >
                        <TranslatableText translationKey="confirm" />
                    </Button>{" "}
                    <Button outline onClick={toggleResponsiblePopup}>
                        <TranslatableText translationKey="cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EntityList;