import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/pro-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { Table, Button } from "reactstrap";
import Logo from "../../../../resources/images/DPOAdvisor.svg";
import { getCompanyByIdAPI } from "./firmAPI";
import { toast } from "react-toastify";
import TranslatableText from "../../../../Language/TranslatableText";
import { GlobalContext } from "../../../../contexts/global-context";

class ViewFirmPartnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerDetails: {}
    };
  }
  componentDidMount() {
    getCompanyByIdAPI(this.props.match.params.id)
      .then(res => {
        this.setState({ partnerDetails: res.data });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  }
  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div className="partner-view">
        <h1>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            size="1x"
            onClick={this.goBack}
            className="cursor"
          />{" "}
          <TranslatableText translationKey="company.partner_information" />
        </h1>
        <hr />
        <div className="flex space-between mr-20 upload-logo-container">
          <img className="partner-logo" src={Logo} alt="" />
          <Button>
            <TranslatableText translationKey="company.change_logo" />
          </Button>
        </div>
        <div className="center-align">
          <h1>DPO Advisor</h1>
        </div>
        <Table>
          <thead>
            <tr>
              <th>
                <TranslatableText translationKey="company.element" />
              </th>
              <th>
                <TranslatableText translationKey="value" />
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(this.state.partnerDetails)
              .filter(function (el) {
                if (
                  el === "id" ||
                  el === "systemIds" ||
                  el === "logo" ||
                  el === "releasePhase"
                ) {
                  return false; // skip
                }
                return true;
              })
              .map(key => (
                <tr key={key}>
                  <td className="text-capitalize">{key}</td>
                  <td>{this.state.partnerDetails[key]}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default withRouter(ViewFirmPartnerDetails);
ViewFirmPartnerDetails.contextType = GlobalContext;