import * as React from 'react'

type Action = {type: 'increment'} | {type: 'decrement'}
type Dispatch = (action: Action) => void
type State = {count: number}
type CountProviderProps = {children?: React.ReactNode}
type ContextType = {state: State; dispatch: Dispatch} | undefined
type CountProviderPropsConsumer = {children: (props: ContextType) => React.ReactNode}

export const CountStateContext = React.createContext<ContextType>(undefined)
CountStateContext.displayName = "CountStateContext";


function countReducer(state: State, action: Action) {
  switch (action.type) {
    case 'increment': {
      return {count: state.count + 1}
    }
    case 'decrement': {
      return {count: state.count - 1}
    }
  }
}

/**
 * Use this conteaxt to get a hold of user information in the 360 Platform
 */
function CountProvider({children}: CountProviderProps) {
  const [state, dispatch] = React.useReducer(countReducer, {count: 200})
  // NOTE: memoize? http://kcd.im/optimize-context
  const value = {state, dispatch}
  return (
    <CountStateContext.Provider value={value}>
      {children}
    </CountStateContext.Provider>
  )
}

/**
 * Use this conteaxt to get a hold of user information in the 360 Platform
 */
function CountConsumer({children}: CountProviderPropsConsumer) {
  return (
    <CountStateContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('CountConsumer must be used within a CountProvider')
        }
        return children(context)
      }}
    </CountStateContext.Consumer>
  )
}

/**
 * Use this conteaxt to get a hold of user information in the 360 Platform
 */
function useCount() {
  const context = React.useContext(CountStateContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return context
}

export {CountProvider, CountConsumer, useCount}
