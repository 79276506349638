import axios from "axios";
import { Config } from "../../config/Config";
import authService from "../../components/api-authorization/AuthorizeService";

// Attachments API URL
const ATTACHMENTS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Attachments`;

// Post
export const createAttachmentAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${ATTACHMENTS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update API
export const updateAttachmentAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };

  return axios
    .put(`${ATTACHMENTS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get all RecurrenceType
export const getAllRecurrenceTypeAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };

  return axios
    .get(`${ATTACHMENTS_API_URL}/GetAllRecurrenceType`, { headers: headers })
    .then(({ data }) => data);
};

// Get By ID
export const getAttachmentByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ATTACHMENTS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get All
export const getAttachmentsAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ATTACHMENTS_API_URL}/${id}/List`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteAttachmentByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();

  return axios
    .delete(`${ATTACHMENTS_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Download File
export const downloadAttachmentByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };

  return axios
    .get(`${ATTACHMENTS_API_URL}/download/${id}`, {
      headers: headers,
      responseType: "blob"
    })
    .then(res => res);
};
