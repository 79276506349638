import React, { Component } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TranslatableText from "../../../../Language/TranslatableText";
import { faTrash, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
//import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import {
  getAllRegardingObjectTypesAPI,
  deleteCustomEntityByIdAPI
} from "./customEntitiesAPI";
import paginationFactory from "react-bootstrap-table2-paginator";

import { toast } from "react-toastify";
import authService from "../../../../components/api-authorization/AuthorizeService";
// import { fetchEntities } from "../../../../store/entityActions";
import { faBoxAlt } from "@fortawesome/pro-regular-svg-icons";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";



const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class CustomEntities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationId: "",
      tableLoader: false,
      entity: "",
      organizationDropdown: [],
      regardingObjectTypeIdDropdown: [],
      customFields: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          formatter: (cell, row) => {
            return (
              <div className="actions">
                {!row.type ? (
                  <>
                    <FontAwesomeIcon
                      className="rs-5"
                      icon={faBoxAlt}
                      size="1x"
                    />{" "}
                    {row.name}
                  </>
                ) : (
                  <span className="ml-25">{row.name}</span>
                )}
              </div>
            );
          },
          sort: true
        },
        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`${this.props.match.path}/details/${row.id}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
                {row.type ? (
                  <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </NavLink>
                ) : (
                  <></>
                )}
              </div>
            );
          }
        }
      ],
      deleteModal: false,
      deleteLoader: false,
      customEntityId: null
    };
  }
  componentDidMount() {
    this.populateCustomEntities();
  }

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            customEntityId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      customEntityId: id
    });
  };

  populateCustomEntities = () => {
    this.setState({ tableLoader: true, customFields: [] });
    getAllRegardingObjectTypesAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ customFields: res, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  deleteCustomEntity = id => {
    this.setState({ deleteLoader: true });
    deleteCustomEntityByIdAPI(id)
      .then(res => {
        // authService.getAccessToken().then(token => {
        //   this.props
        //     .dispatch(fetchEntities(token))
        //     .then(res => {
        //       this.populateCustomEntities();
        //       this.setState({
        //         deleteModal: false,
        //         customEntityId: null,
        //         deleteLoader: false
        //       });
        //     })
        //     .catch(() => {
        //       console.log("failed");
        //     });
        // });
        this.populateCustomEntities();
        this.setState({
          deleteModal: false,
          customEntityId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          customEntityId: null,
          deleteLoader: false
        });
      });
  };

  render() {
    let { customFields, columns, tableLoader } = this.state;
    return (
      <div className="admin-custom-fields-view">
        <h1>
          <TranslatableText translationKey="company.custom_entities" />
        </h1>
        <hr />

        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={customFields}
            columns={columns}
            search
            exportCSV={{
              fileName: "custom.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          CSV
                        </ExportCSVButton>
                      ) : (
                        <></>
                      )}

                      <NavLink
                        className="button-primary"
                        tag={Link}
                        to={`${this.props.match.path}/create`}
                      >
                        Create
                      </NavLink>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeletePopup}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeletePopup}>
            Confirm Delete?
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <p>
                  <TranslatableText translationKey="company.confirm_delete_entity" />
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {this.state.deleteLoader === false ? (
              <Button
                outline
                className="redOutline"
                onClick={e =>
                  this.deleteCustomEntity(this.state.customEntityId, e)
                }
              >
                <TranslatableText translationKey="delete" />
              </Button>
            ) : (
              <InlineLoading />
            )}{" "}
            <Button outline onClick={this.toggleDeletePopup}>
              <TranslatableText translationKey="cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.languageResources.language_resources,
  user: state.organizations,
  roles: state.userRoles,
  loading: state.organizations.loading,
  error: state.organizations.error
});

export default withRouter(CustomEntities);
CustomEntities.contextType = GlobalContext;