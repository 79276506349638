import React, { Component, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { NavLink, Row, Col, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { getOrganizationFlowEngines, deleteFlowByIdAPI } from "./flowsAPI";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit, faTrash,
    faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
    Search,
    CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Accent from "../../../../components/accent/Accent";
import { getEntityRecordNameAPI } from "../custom-entities/tenantCustomEntitiesAPI";
import TranslatableText from "../../../../Language/TranslatableText";
import {
    faCheckCircle,
    faInfoCircle
} from "@fortawesome/pro-regular-svg-icons";
import { useHistory } from 'react-router';
import { InlineLoading } from "../../../../components";
import { Table_GetLocalStorage, Table_NoDataIndication, Table_SetLocalStorage } from "../../../../components/table";
import filterFactory from "react-bootstrap-table2-filter";
import { GlobalContext } from "../../../../contexts/global-context";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
    <div className="text-center">
        <span>
            <TranslatableText translationKey="no_data_available" />
        </span>
    </div>
);
function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div>
            <TranslatableText translationKey={column.text} /> {sortElement}
            {filterElement}
        </div>
    );
}
type FlowsProps = {
    language: any,
    match: any
}
type FlowsParams = {
    regardingObjectTypeId: string,
    regardingObjectId: string
}
const Flows: React.FC<FlowsProps> = (props) => {
    const history = useHistory();
    const { match } = props;
    const { regardingObjectTypeId, regardingObjectId } = useParams<FlowsParams>();
    const { user, userRoles, languages } = useContext(GlobalContext);
    const [tableLoader, setTableLoader] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [entityRecordName, setEntityRecordName] = useState<any>({});
    const [flows, setFlows] = useState<any>([]);
    const [columns, setColumns] = useState<any>([
        {
            dataField: "id",
            text: "ID",
            hidden: true
        },
        {
            dataField: "name",
            text: "history.name",
            headerFormatter: headerFormatter,
            sort: true
        },
        {
            dataField: "description",
            text: "history.description",
            headerFormatter: headerFormatter,
        },
        {
            dataField: "actions",
            text: "actions",
            headerFormatter: headerFormatter,
            csvExport: false,
            formatter: (cell: any, row: any) => {
                return (
                    <div className="actions">
                        <NavLink
                            tag={Link}
                            to={`${match.path}/history/${row.id}/${row.regardingObjectId}/${row.regardingObjectTypeId}`}
                        >
                            <Tippy placement="top" content="See details">
                                <span>
                                    <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                                </span>
                            </Tippy>
                        </NavLink>
                        <NavLink
                            tag={Link}
                            to={
                                regardingObjectId !== undefined
                                    ? `/company-admin/flow-engine/update/${row.id}/${regardingObjectTypeId}/${regardingObjectId}`
                                    : `${match.path}/update/${row.id}`
                            }
                        >
                            <Tippy placement="top" content="Update">
                                <span>
                                    <FontAwesomeIcon icon={faEdit} size="1x" />
                                </span>
                            </Tippy>
                        </NavLink>
                        <NavLink onClick={e => deleteRecord(row.id)}>
                            <Tippy placement="top" content="Remove">
                                <span>
                                    <FontAwesomeIcon
                                        style={{ color: "#d84a4a" }}
                                        icon={faTrash}
                                        size="1x"
                                    />
                                </span>
                            </Tippy>
                        </NavLink>
                    </div>
                );
            }
        }
    ]);
    useEffect(() => {
        setTableLoader(false);
        populateFlowsTable();
        if (
            regardingObjectTypeId !== undefined &&
            regardingObjectId !== undefined
        ) {
            getEntityRecordName();
        }
    }, [])

    const populateFlowsTable = () => {
        setTableLoader(true);
        setFlows([]);
        getOrganizationFlowEngines(
            regardingObjectTypeId,
            regardingObjectId
        )
            .then(res => {
                if (!Array.isArray(res)) {
                    console.log("debug: ", res);
                    return;
                }
                setTableLoader(false);
                setFlows(res);
            })
            .catch(error => {
                toast.error(languages["api_error"], {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
                setTableLoader(false);
            });
    };

    const getEntityRecordName = () => {
        setLoading(true);
        getEntityRecordNameAPI(
            regardingObjectTypeId,
            regardingObjectId
        )
            .then(res => {
                setEntityRecordName(res);
                setLoading(false);
            })
            .catch(error => {
                toast.error(languages["api_error"], {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
                setLoading(false);
            });
    };

    const deleteRecord = (id: string) => {
        Swal.fire({
            title: languages["swal.are_you_sure"],
            text: languages["swal.are_you_sure.delete_record"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: languages[
                "swal.are_you_sure.confirmButtonText"
            ],
            cancelButtonText: languages[
                "swal.are_you_sure.cancelButtonText"
            ]
        }).then(result => {
            if (result.value) {
                deleteFlowByIdAPI(id).then(res => {
                    populateFlowsTable();
                    Swal.fire(
                        languages["swal.deleted"],
                        languages["swal.deleted_record_deleted"],
                        "success"
                    );
                });
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                setTableLoader(false);
                Swal.fire(
                    languages["swal.cancelled"],
                    languages["swal.cancelled_your_record_is_safe"],
                    "error"
                );
            }
        });
    };

    const goBack = () => {
        history.goBack();
    };

    // let { flows, columns, tableLoader, loading } = this.state;
    return (
        <div className="admin-flows-view">
            <Row>
                <Col md={12}>
                    <Accent useIcon={false}>
                        {loading ? (
                            <InlineLoading />
                        ) : regardingObjectTypeId !== undefined &&
                            regardingObjectId !== undefined ? (
                            `Flows for ${entityRecordName?.displayName}`
                        ) : (
                            <TranslatableText translationKey="history.flows" />
                        )}
                    </Accent>
                </Col>
            </Row>
            <div className="table-container">
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={flows}
                    columns={columns}
                    search
                    exportCSV={{
                        fileName: "data.csv",
                        separator: user.language === 'dk' ? ';' : ',',
                        ignoreHeader: false,
                        noAutoBOM: false
                    }}
                >
                    {props => (
                        <div>
                            <div className="flex space-between">
                                <div>
                                    <SearchBar {...props.searchProps} />
                                </div>
                                <div>
                                    <div className="flex space-between">
                                        {userRoles.find((r: any) =>
                                            r.includes("Developer")
                                        ) !== undefined ? (
                                            <ExportCSVButton
                                                className="export-btn"
                                                {...props.csvProps}
                                            >
                                                <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                                        ) : (
                                            <></>
                                        )}
                                        <div>
                                            <NavLink
                                                className="button-primary"
                                                tag={Link}
                                                to={
                                                    regardingObjectId !==
                                                        undefined
                                                        ? `/company-admin/flow-engine/create/${regardingObjectTypeId}/${regardingObjectId}`
                                                        : `${match.path}/create`
                                                }
                                            >
                                                <TranslatableText translationKey="create" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <BootstrapTable
                                // selectRow={selectRow}
                                // expandRow={expandRow}
                                filter={filterFactory()}
                                classes="organization-list"
                                noDataIndication={() => tableLoader ? (<InlineLoading loading={tableLoader} />) : (<Table_NoDataIndication />)}
                                {...props.baseProps}
                                pagination={paginationFactory({ sizePerPage: Table_GetLocalStorage(), onSizePerPageChange: e => Table_SetLocalStorage(e) })} // OBS <-- implement localStorage here
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </div>
            <Row>
                <Col md={12}>
                    <Button onClick={goBack}>
                        <TranslatableText translationKey="back" />
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default Flows;
