import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { AccountLevel } from '../config/Levels';
import { usePlatformUser } from "../contexts/platform-user-context";
import { getAppInsights } from "./appInsights/TelemetryService";
import { CurrentOrgNotActive } from "./currentOrgNotActive";
import { Loading } from "./loading/loading";
import NavMenu from "./navigation/NavMenu";

type LayoutProps = { children?: React.ReactNode }

function Layout(props: LayoutProps) {
  const { organisationData } = usePlatformUser();
  const appInsights = getAppInsights();
  let location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    console.debug('Layout load');
    return () => {
      console.debug('Layout unload');
    }
  }, [])

  const loadSupportBtn = () => {
    let exists = document.getElementById('ltd-support-btn-script');
    if (!!exists) {
      // console.debug('Support button already loaded');
      return;
    }
    console.debug('Support button enabled');
    const script = document.createElement("script");
    script.id = "ltd-support-btn-script";
    script.src = "https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js";
    script.async = true;
    script.onload = () => scriptLoaded();
    document.body.appendChild(script);
  }
  const scriptLoaded = () => {
    (window as any).FreshWidget.init("", { "loadOnEvent": 'immediate', "queryString": "&widgetType=popup&submitThanks=Tak+for+din+Feedback", "utf8": "✓", "widgetType": "popup", "buttonType": "text", "buttonText": "Support", "buttonColor": "white", "buttonBg": "#003399", "alignment": "2", "offset": "25vh", "submitThanks": "Tak for din Feedback", "formHeight": "500px", "url": "https://dpoadvisor.freshdesk.com" });
    setRefresh(!refresh);
  }

  if (organisationData.status === 'success') {
    // console.debug("organisationData.status - " + organisationData.status);
    if (appInsights !== undefined) {
      // console.debug("Telemetry - Setting account identification");
      appInsights?.setAuthenticatedUserContext(appInsights?.context.user.authenticatedId, organisationData.data.organizationId, true);
    }
    if (organisationData.data.systemSettings.enable_support_btn) {
      loadSupportBtn();
    }

    return (
      <>
        <div className="fixed-top">
          <NavMenu
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
          {(organisationData.data.accountLevel >= AccountLevel.Partner && organisationData.data.system !== 'Bruun Rasmussen') && (
            <>
              {(location.pathname.split('/')[1] !== 'admin' && location.pathname.split('/')[1] !== 'partner') &&
                <div className="DisplayCompanyNew d-sm-block d-none">
                  <div>{organisationData.data.fiscalYear} -  {organisationData.data.name}</div>
                </div>
              }
            </>
          )}
        </div>

        <div className={isOpen ? "header" : ""} onClick={() => isOpen ? setIsOpen(false) : null}>
          <Container style={{ marginTop: (location.pathname.split('/')[1] !== 'admin' && location.pathname.split('/')[1] !== 'partner') ? 85 : 70 + 'px' }}>
            {(organisationData.data.accountLevel < AccountLevel.Partner && organisationData.data.system !== 'Bruun Rasmussen') && (
              <>
                {(location.pathname.split('/')[1] !== 'admin' && location.pathname.split('/')[1] !== 'partner') &&
                  <div className="DisplayCompany d-sm-block d-none">
                    <div>{organisationData.data.name}</div>
                  </div>
                }
              </>
            )}
            {props.children}
          </Container>
        </div>
        {organisationData.data?.systemSettings.enable_guide_btn &&
          <div id="freshwidget-button"
            data-html2canvas-ignore="true"
            className="freshwidget-button fd-btn-right"
            style={{ display: "none", top: "25vh" }}>
            <a target="_blank" rel="noopener noreferrer" href={organisationData.data?.systemSettings.guide_url.includes("http") ? organisationData.data?.systemSettings.guide_url : "https://" + organisationData.data?.systemSettings.guide_url} className="freshwidget-theme"
              style={{ color: "white", backgroundColor: "var(--primary)", borderColor: "white" }}>
              Guide
            </a>
          </div>
        }
      </>
    );
  } else if (organisationData.status === 'error') {
    console.debug("organisationData.status - " + organisationData.status);
    return (
      <div className="loader">
        <h2>Oops!</h2>
        <p>It seems that there was an error.</p>
        <p>Please try again a little later.</p>
        <p>Or contact the support team at email: support (at) legaltechdenmark.com</p>

        <p>Error message: {organisationData.error}</p>
      </div>
    );
  } else if (organisationData.status === 'orgInvalid') {
    console.debug("organisationData.status - " + organisationData.status);
    return (
      <>
        <Container style={{ marginTop: 70 + 'px' }}>
          <CurrentOrgNotActive />
        </Container>
      </>
    );
  }
  // else if (organisationData.status === ('empty' || 'loading')) {
  //   console.debug('Layout empty || loading');
  //   // console.debug("organisationData.status - " + organisationData.status);
  //   return <Loading />
  // } 
  else {
    // console.debug("organisationData.status - defuelt");
    return <Loading />
  }
}

export default Layout;