import { faAt, faExternalLink, faMap, faPhone } from "@fortawesome/pro-regular-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Table } from "reactstrap";
import { GetOrganizationResponse } from "../../../../apiClients";
import TranslatableText from "../../../../Language/TranslatableText";
import { cvrApiResponse } from "../../cvrApi";

export function organisationDetails(organisationData: GetOrganizationResponse, cvrApiData: cvrApiResponse | undefined) {
  return <Row>
    <Col md={12}>
      <div className="partner-section white-bg">
        <Table>
          <tbody>
            <tr>
              <td className="w-25 text-capitalize bold">Logo</td>
              <td>
                <img src={organisationData.logo} height="50" alt="" />
              </td>
            </tr>
            <tr>
              <td className="w-25 text-capitalize bold"><TranslatableText translationKey="name" /></td>
              <td>{organisationData.name}</td>
            </tr>
            <tr>
              <td className="w-25 text-capitalize bold"><TranslatableText translationKey="email" /></td>
              <td><FontAwesomeIcon icon={faAt} />&nbsp;{organisationData.email}</td>
            </tr>
            <tr>
              <td className="w-25 text-capitalize bold"><TranslatableText translationKey="phone" /></td>
              <td><FontAwesomeIcon icon={faPhone} />&nbsp;{organisationData.phone}</td>
            </tr>
            <tr>
              <td className="w-25 text-capitalize bold"><TranslatableText translationKey="address" /></td>
              <td>{organisationData.address}, {organisationData.postalCode} {organisationData.city}&nbsp;{organisationData.address &&
                <a target="_blank" href={'https://www.google.dk/maps/place/' + organisationData.address + ' ' + organisationData.postalCode + ' ' + organisationData.city} rel="noreferrer">
                  <FontAwesomeIcon icon={faMap} />
                </a>}
              </td>
            </tr>
            <tr>
              <td className="w-25 text-capitalize bold"><TranslatableText translationKey="vat" /></td>
              <td>{organisationData.vat}&nbsp;{organisationData.countryCode == 'dk' &&
                <a target="_blank" href={'https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=' + organisationData.vat} rel="noreferrer">
                  <FontAwesomeIcon icon={faExternalLink} />
                </a>}
              </td>
            </tr>
            {cvrApiData &&
              <tr>
                <td className="w-25 text-capitalize bold">CVR Data - Ansatte</td>
                <td><FontAwesomeIcon icon={faUser} />&nbsp;{cvrApiData.employees}</td>
              </tr>}
          </tbody>
        </Table>
      </div>
    </Col>
  </Row>;
}
