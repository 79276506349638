import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  createCustomEntityAPI,
  getCustomEntityByIdAPI,
  updateCustomEntityAPI
} from "./tenantCustomEntitiesAPI";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import classnames from "classnames";
import authService from "../../../../components/api-authorization/AuthorizeService";
// import { fetchEntities } from "../../../../store/entityActions";
import { Config } from "../../../../config/Config";
import { getWizardsAPI } from "../../../admin/views/wizard/wizardAPI";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading } from "../../../../components";



class CreateTenantCustomEntity extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "displayName",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "pluralName",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      name: "",
      displayName: "",
      pluralName: "",
      language: this.context?.user.organizations.defaultLanguage,
      languageDropdown: Config.languages,
      description: "",
      validationMessage: "",
      entityLabel: "",
      notification: false,
      task: false,
      showInMenu: false,
      quickCreate: false,
      wizard: false,
      approvals: false,
      wizardId: "",
      wizardDropdown: [],
      attachment: false,
      publish: false,
      answerDisplayTypeDropdown: [],
      loading: false,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    getWizardsAPI()
      .then(res => {
        this.setState({ wizardDropdown: Array.isArray(res) ? res : [] });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getCustomEntityByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.name,
            notification: res.data.notification,
            attachment: res.data.attachment,
            wizard: res.data.wizard,
            wizardId: res.data.wizardId === null ? "" : res.data.wizardId,
            publish: res.data.publish,
            task: res.data.task,
            showInMenu: res.data.showInMenu,
            quickCreate: res.data.quickCreate,
            displayName: "0",
            pluralName: "0",
            language: "0",
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({
            loading: false
          });
        });
    }
  }

  toggleButton = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  createCustomEntity = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      name: this.state.name,
      displayName: this.state.displayName,
      description: this.state.description,
      pluralName: this.state.pluralName,
      language: this.state.language,
      notification: this.state.notification,
      task: this.state.task,
      showInMenu: this.state.showInMenu,
      quickCreate: this.state.quickCreate,
      attachment: this.state.attachment,
      wizard: this.state.wizard,
      wizardId: this.state.wizardId,
      publish: this.state.publish,
      organizationId:
        this.props.match.params.organizationId !== undefined
          ? this.props.match.params.organizationId
          : "",
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : ""
    };

    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ loading: true });
        updateCustomEntityAPI(data)
          .then(res => {
            // authService.getAccessToken().then(token => {
            //   this.props
            //     .dispatch(fetchEntities(token))
            //     .then(res => {
            //       this.setState({ loading: false }, () => {
            //         this.goBack();
            //       });
            //     })
            //     .catch(() => {
            //       console.log("failed");
            //     });
            // });
            this.setState({ loading: false }, () => {
              this.goBack();
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ loading: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ loading: true });
        createCustomEntityAPI(data)
          .then(res => {
            // authService.getAccessToken().then(token => {
            //   this.props
            //     .dispatch(fetchEntities(token))
            //     .then(res => {
            //       this.setState({ loading: false }, () => {
            //         this.goBack();
            //       });
            //     })
            //     .catch(() => {
            //       console.log("failed");
            //     });
            // });
            this.setState({ loading: false }, () => {
              this.goBack();
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ loading: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleRevisionDate = date => {
    this.setState({
      revisionDatePicker: date,
      revisionDate: date.toISOString()
    });
  };

  handleLastDate = date => {
    this.setState({
      lastDatePicker: date,
      lastTestDate: date.toISOString()
    });
  };

  trim = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let str = value.replace(/\s/g, "");
    this.setState({ name: str });
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-asset-view">
        {loading && (
          <ScreenLoading />
        )}
        <Row className="flex-align">
          <Col md={11}>
            <h4>
              {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" />
                : <TranslatableText translationKey="create" />}{" "}
              <TranslatableText translationKey="company.custom_entity" />
            </h4>
          </Col>
          <Col md={1}>
            <FormGroup className="align-right">
              <Label for="">
                <TranslatableText translationKey="company.publish" />
              </Label>
              <div
                onClick={e => this.toggleButton("publish", e)}
                className={classnames("search-toggle-btn")}
              >
                <span
                  className={classnames(
                    "ball",
                    this.state.publish ? "toggled" : ""
                  )}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Form onSubmit={this.createCustomEntity}>
          <Row className="panel" form>
            <Col className="panel-header" md={12}>
              <TranslatableText translationKey="page.system_information" />
            </Col>
            <Col className="panel-body" md={12}>
              <div
                className={
                  this.state.wizard
                    ? "flex flex-basis-2 space-between"
                    : "block"
                }
              >
                <FormGroup
                  className={validation.name.isInvalid ? "has-error" : ""}
                >
                  <Label for="name">
                    <TranslatableText translationKey="company.system_name" />
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder=""
                    value={this.state.name}
                    onChange={this.handleChange}
                    onBlur={this.trim}
                    disabled={
                      this.props.match.params.id !== undefined ? true : false
                    }
                  />
                  <span className="help-block">{validation.name.message}</span>
                </FormGroup>
                {this.state.wizard ? (
                  <FormGroup>
                    <Label for="wizardId">Wizard</Label>
                    <Input
                      type="select"
                      name="wizardId"
                      id="wizardId"
                      value={this.state.wizardId}
                      onChange={this.handleChange}
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.wizardDropdown.map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                ) : (
                  ""
                )}
              </div>
              <div className="flex flex-basis-5">
                <FormGroup className="align">
                  <Label for="">
                    <TranslatableText translationKey="notifications" />
                  </Label>
                  <div
                    onClick={e => this.toggleButton("notification", e)}
                    className={classnames("search-toggle-btn")}
                  >
                    <span
                      className={classnames(
                        "ball",
                        this.state.notification ? "toggled" : ""
                      )}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="align">
                  <Label for="">Attachment</Label>
                  <div
                    onClick={e => this.toggleButton("attachment", e)}
                    className={classnames("search-toggle-btn")}
                  >
                    <span
                      className={classnames(
                        "ball",
                        this.state.attachment ? "toggled" : ""
                      )}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="align">
                  <Label for="">
                    <TranslatableText translationKey="company.menu" />
                  </Label>
                  <div
                    onClick={e => this.toggleButton("showInMenu", e)}
                    className={classnames("search-toggle-btn")}
                  >
                    <span
                      className={classnames(
                        "ball",
                        this.state.showInMenu ? "toggled" : ""
                      )}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="align">
                  <Label for="">Wizard</Label>
                  <div
                    onClick={e => this.toggleButton("wizard", e)}
                    className={classnames("search-toggle-btn")}
                  >
                    <span
                      className={classnames(
                        "ball",
                        this.state.wizard ? "toggled" : ""
                      )}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="align">
                  <Label for="">
                    <TranslatableText translationKey="tasks" />
                  </Label>
                  <div
                    onClick={e => this.toggleButton("task", e)}
                    className={classnames("search-toggle-btn")}
                  >
                    <span
                      className={classnames(
                        "ball",
                        this.state.task ? "toggled" : ""
                      )}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="align">
                  <Label for="">
                    <TranslatableText translationKey="company.quick_create" />
                  </Label>
                  <div
                    onClick={e => this.toggleButton("quickCreate", e)}
                    className={classnames("search-toggle-btn")}
                  >
                    <span
                      className={classnames(
                        "ball",
                        this.state.quickCreate ? "toggled" : ""
                      )}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="align">
                  <Label for="">
                    <TranslatableText translationKey="approvals" />
                  </Label>
                  <div
                    onClick={e => this.toggleButton("approvals", e)}
                    className={classnames("search-toggle-btn")}
                  >
                    <span
                      className={classnames(
                        "ball",
                        this.state.approvals ? "toggled" : ""
                      )}
                    />
                  </div>
                </FormGroup>
                {this.state.approvals ? (
                  <FormGroup style={{ flexBasis: "20%" }}>
                    <Label for="approvalFlow">
                      <TranslatableText translationKey="approval_flows" />
                    </Label>
                    <Input
                      type="select"
                      name="approvalFlow"
                      id="approvalFlow"
                      value={this.state.approvalFlow}
                      onChange={this.handleChange}
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.wizardDropdown.map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
          {this.props.match.params.id === undefined ? (
            <Row className="panel" form>
              <Col className="panel-header" md={12}>
                <TranslatableText translationKey="custom_fields.display_information" />
              </Col>
              <Col className="panel-body" md={12}>
                <div className="flex space-between flex-basis-3">
                  <FormGroup
                    className={validation.name.isInvalid ? "has-error" : ""}
                  >
                    <Label for="displayName">
                      <TranslatableText translationKey="company.display_name" />
                    </Label>
                    <Input
                      type="text"
                      name="displayName"
                      id="displayName"
                      placeholder=""
                      value={this.state.displayName}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.displayName.message}
                    </span>
                  </FormGroup>
                  <FormGroup
                    className={validation.name.isInvalid ? "has-error" : ""}
                  >
                    <Label for="pluralName">
                      <TranslatableText translationKey="company.plural_name" />
                    </Label>
                    <Input
                      type="text"
                      name="pluralName"
                      id="pluralName"
                      placeholder=""
                      value={this.state.pluralName}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.pluralName.message}
                    </span>
                  </FormGroup>
                  <FormGroup
                    className={validation.language.isInvalid ? "has-error" : ""}
                  >
                    <Label for="language">
                      <TranslatableText translationKey="language" />
                    </Label>
                    <Input
                      type="select"
                      name="language"
                      id="language"
                      value={this.state.language}
                      onChange={this.handleChange}
                      disabled={
                        this.props.match.params.translationId !== undefined
                          ? true
                          : null
                      }
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.languageDropdown.map((e, key) => {
                        return (
                          <option key={key} value={e.value}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                    <span className="help-block">
                      {validation.language.message}
                    </span>
                  </FormGroup>
                </div>

                <FormGroup>
                  <Label for="description">
                    <TranslatableText translationKey="description" />
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    className="height-100"
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row>
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              <Button>
                {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" />
                  : <TranslatableText translationKey="create" />}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateTenantCustomEntity);
CreateTenantCustomEntity.contextType = GlobalContext;