import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// ApprovalFlow API URL
const APPROVALS_FLOW_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/ApprovalFlows`;
const APPROVALS_FLOW_HISTORY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/ApprovalFlowsHistory`;

// ApprovalFlow History
// Ready for Approval
export const readyApprovalAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${APPROVALS_FLOW_HISTORY_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Cancel Approval
export const cancelApprovalAPI = async data => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${APPROVALS_FLOW_HISTORY_API_URL}`, {
      data,
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Get all ApprovalFlows of an Organization
export const getApprovalFlowsProgressV1API = async (
  regardingObjectTypeId,
  regardingObjectId,
  id,
  dataVersion,
) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${APPROVALS_FLOW_API_URL}/${regardingObjectTypeId}/${regardingObjectId}/${id}/${dataVersion}/ApprovalFlowsProgressV1`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Get all approvals of a logged in user
export const getUserApprovalsListAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${APPROVALS_FLOW_API_URL}/GetUserApprovalFlows`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get all project approvals
export const getProjectApprovalsListAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${APPROVALS_FLOW_API_URL}/GetProjectApprovalFlows`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// User Approval Flow API
export const userApprovalFlowAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${APPROVALS_FLOW_HISTORY_API_URL}/ApproveApprovalFlow`, data, {
      headers: headers
    })
    .then(res => res);
};

// User Rejection Flow API
export const userRejectionFlowAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${APPROVALS_FLOW_HISTORY_API_URL}/CancelApprovalFlow`, data, {
      headers: headers
    })
    .then(res => res);
};

// User Approval Flow API DataVersion 1
export const userApprovalFlowAPIV1 = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${APPROVALS_FLOW_HISTORY_API_URL}/ApproveApprovalFlowV1`, data, {
      headers: headers
    })
    .then(res => res);
};

// User Rejection Flow API DataVersion 1
export const userRejectionFlowAPIV1 = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${APPROVALS_FLOW_HISTORY_API_URL}/CancelApprovalFlowV1`, data, {
      headers: headers
    })
    .then(res => res);
};

// Get all approvals of a logged in user
export const getApprovalsHistoryAPI = async regardingObjectId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${APPROVALS_FLOW_HISTORY_API_URL}/${regardingObjectId}/ApprovalFlowsHistory`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Trigger Approval Flow
export const triggerApprovalFlowAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${APPROVALS_FLOW_HISTORY_API_URL}/TriggerApprovalFlow`, data, {
      headers: headers
    })
    .then(res => res);
};
