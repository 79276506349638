import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Attachments from "./Attachments";
import CreateAttachment from "./CreateAttachment";
import AttachmentDetails from "./AttachmentDetails";
import { withRouter } from "react-router-dom";

class AttachmentsRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-attachment-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/create/:type/:regardingObjectId`}
            render={props => (
              <CreateAttachment {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:type/:regardingObjectId/:id`}
            render={props => (
              <CreateAttachment {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <AttachmentDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/attachments/:id"
            render={props => (
              <Attachments {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(AttachmentsRouter);