import { Component } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';
import HandleCaught_API_Error from '../../apiClients/api_caughtError';
import { LawSignUpRequest, SignUpClient } from '../../apiClients/api_client_public';
import { ScreenLoading } from '../../components';
import FormValidator from '../../components/validator/FormValidator';
import "../../components/validator/FormValidator.scss";

export class SignUp extends Component {
  validator = new FormValidator([
    {
      field: "name",
      method: "isEmpty",
      validWhen: false,
      message: "Felt påkrævet."
    },
    {
      field: "email",
      method: isEmail,
      validWhen: true,
      message: "Ikke en gyldig email."
    },
  ]);
  constructor(props: any) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      isLoading: false,
      completed: false,
      validation: this.validator.valid()
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  _signUpClient = new SignUpClient();

  signUp = () => {
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    if (!validation.isValid) {
      return;
    }
    this.setState({
      isLoading: true
    });
    let signUp = new LawSignUpRequest();
    // @ts-ignore
    signUp.name = this.state.name;
    // @ts-ignore
    signUp.email = this.state.email;
    // @ts-ignore
    signUp.phoneNo = this.state.phone;
    console.log(signUp);

    let _signUpClient = new SignUpClient();
    _signUpClient.lawSignUp(signUp)
        .then(res => {
            console.log(res)
            this.setState({
              completed: true
            });

        })
        .catch(err => HandleCaught_API_Error(err))
        .finally(() => {
            this.setState({
              isLoading: false
            });
        });
  };
  render() {
    // @ts-ignore
    let validation = this.completed // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      // @ts-ignore
      : this.state.validation;
      // @ts-ignore
    let { isLoading, completed } = this.state;
    return (
      <>
        {isLoading && (
          <ScreenLoading />
        )}
        {(!isLoading && !completed) && (
          <section className='vh-100'>
            <div className='mask d-flex align-items-center h-100 gradient-custom-3'>
              <div className='container h-100'>
                <div className='row d-flex justify-content-center align-items-center h-100'>
                  <div className='col-12 col-md-9 col-lg-7 col-xl-6'>
                    <div className='card' style={{ 'borderRadius': '15px' }}>
                      <div className='card-body p-5'>
                        <img src='oens_kvadrat.png' style={{ 'maxWidth': '100%' }} />
                        <h2 className='text-uppercase text-center'>Digital Jura</h2>
                        <h5 className='text-center'>Opret konto.</h5>

                        <Form>
                          <FormGroup
                            className={validation.name.isInvalid ? "has-error" : ""}
                          >
                            <Label for="signupName">Name *</Label>
                            <Input bsSize="lg" type="text" name="name" id="signupName" placeholder="Navn"
    // @ts-ignore
                              value={this.state.name}
                              onChange={this.handleInputChange} />
                              <span className="help-block">{validation.name.message}</span>
                          </FormGroup>
                          <FormGroup
                            className={validation.email.isInvalid ? "has-error" : ""}
                          >
                            <Label for="signupEmail">Email *</Label>
                            <Input bsSize="lg" type="email" name="email" id="signupEmail" placeholder="Email"
    // @ts-ignore
                              value={this.state.email}
                              onChange={this.handleInputChange} />
                              <span className="help-block">{validation.email.message}</span>
                          </FormGroup>
                          <FormGroup>
                            <Label for="signupPhoneno">Phone No.</Label>
                            <Input bsSize="lg" type="text" name="phone" id="signupPhoneno" placeholder="Telefon nr."
    // @ts-ignore
                              value={this.state.phone}
                              onChange={this.handleInputChange} />
                          </FormGroup>
                          <div className='d-flex justify-content-center'>
                            <Button color="primary" size="lg" block onClick={() => this.signUp()}>Registrer</Button>
                          </div>

                          <p className='text-center text-muted mt-5 mb-0'>Har du allerede en konto? <a href='/'
                            className='fw-bold text-body'><u>Login her</u></a></p>
                        </Form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {(!isLoading && completed) && (
          <section className='vh-100'>
            <div className='mask d-flex align-items-center h-100 gradient-custom-3'>
              <div className='container h-100'>
                <div className='row d-flex justify-content-center align-items-center h-100'>
                  <div className='col-12 col-md-9 col-lg-7 col-xl-6'>
                    <div className='card' style={{ 'borderRadius': '15px' }}>
                      <div className='card-body p-5'>
                        <img src='oens_kvadrat.png' style={{ 'maxWidth': '100%' }} />
                        <h2 className='text-uppercase text-center mb-5'>Digital Jura</h2>
                        <h3 className='text-center'>Konto oprettet.</h3>
                        <h5 className='text-center'>Tjek din email for login detaljer.</h5>
                          <p className='text-center text-muted mt-5 mb-0'><a href='/'
                            className='fw-bold text-body'><u>Login her</u></a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    )
  }
}
