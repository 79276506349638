import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, GlobalContextType } from "../contexts/global-context";

type EnableContextProps = {
    children?: any;
}
const EnableContext: React.FC<EnableContextProps> = (props) => {
    const { children } = props;
    const [isLoadingState, setIsLoadingState] = useState<boolean>(true);
    const { setRoles, setFavouritePartners, setEntities } = useContext(GlobalContext) as GlobalContextType;

    const { user } = useAuth0();
    const get = async () => {

        await Promise.all([
            setFavouritePartners(),
            setEntities(),
            setRoles(user!["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"])
        ]).then(res =>
            setIsLoadingState(false)
        );
    }
    useEffect(() => {
        get().then(res => {
            setIsLoadingState(false)
        })
    }, [])


    if (isLoadingState) {
        return <></>;

    } else {
        return (
            <>{children}</>
        );
    }
}

export default EnableContext;