import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import {
  getPanelByIdAPI,
  getPanelTranslationsAPI,
  deletePanelTranslationByIdAPI
} from "./panelsAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Config } from "../../../../../../config/Config";
import TranslatableText from "../../../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { InlineLoading } from "../../../../../../components";


const { SearchBar } = Search;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class PanelTranslations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      deleteLoader: false,
      translations: [],
      isDisabled: false,
      deleteModal: false,
      translationId: null,
      name: "",
      columns: [
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "description",
          text: "Description",
          sort: true
        },

        {
          dataField: "language",
          text: "Language",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions"
        }
      ]
    };
  }
  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getPanelByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.name
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
    }
    this.populatePanelTranslationsTable();
  }

  populatePanelTranslationsTable = () => {
    this.setState({ tableLoader: true, translations: [] });
    getPanelTranslationsAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res.data)) {
          console.log("debug: ", res.data);
          return;
        }
        if (res.data.length > 0) {
          var self = this;
          var data = res.data.map(function (el) {
            var o = Object.assign({}, el);
            o.actions = (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/wizard/panel/translations/update/${self.props.match.params.id}/${el.id}`}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
                <NavLink onClick={e => self.openDeleteModal(el.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
            return o;
          });
          console.log(data);
          if (res.data.length == Config.languages.length) {
            this.setState({ isDisabled: true });
          } else {
            this.setState({ isDisabled: false });
          }
          this.setState({ translations: data, tableLoader: false });
        } else {
          this.setState({ translations: [], tableLoader: false });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            translationId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      translationId: id
    });
  };

  deletePanelTranslation = id => {
    this.setState({ deleteLoader: true });
    deletePanelTranslationByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populatePanelTranslationsTable(this.props.match.params.id);
        this.setState({
          deleteModal: false,
          translationId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          translationId: null,
          deleteLoader: false
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { translations, columns, isDisabled, tableLoader } = this.state;
    return (
      <div className="create-translation-view">
        <h1 className="text-center">
          {this.state.name !== "" ? (
            <span>{this.state.name}</span>
          ) : (
            <InlineLoading />
          )}
        </h1>

        <Row form>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={translations}
                columns={columns}
                search
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`/admin/wizard/panel/translations/add/${this.props.match.params.id}`}
                          disabled={isDisabled ? true : null}
                        >
                          <TranslatableText translationKey="answeroptionset.add_translation" />
                        </NavLink>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
          <Col className="space-between flex" md={12}>
            <Button onClick={this.goBack}>
              {" "}
              <TranslatableText translationKey="back_to_list" />
            </Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              <TranslatableText translationKey="confirm_delete" />?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="answeroptionset.confirm_delete_translation" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e =>
                    this.deletePanelTranslation(this.state.translationId, e)
                  }
                >
                  Delete
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(PanelTranslations);
PanelTranslations.contextType = GlobalContext;