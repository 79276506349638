import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import REWrapper from "./REWrapper";

export default function WizardRouter() {
    let match = useRouteMatch();
    useEffect(() => {
        console.debug(match)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params]);
    return (
        <div className="parent-partner-view">
            <Switch>
                <Route
                    path={`${match.path}/:regardingObjectTypeId/:regardingObjectId`}
                    render={props => <REWrapper {...props} />} 
                />
            </Switch>
        </div>
    );
}
