import { faUnlink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport, Search
} from "react-bootstrap-table2-toolkit";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button, Col, Form,
  FormGroup, Input, Label, Modal, ModalBody, ModalHeader, NavLink, Row
} from "reactstrap";
import Swal from "sweetalert2";
import { OrganizationsClient } from "../../../../apiClients";
import HandleCaught_API_Error from "../../../../apiClients/api_caughtError";
import { InlineLoading } from "../../../../components";
import Accent from "../../../../components/accent/Accent";
import { GlobalContext } from "../../../../contexts/global-context";
import TranslatableText from "../../../../Language/TranslatableText";
import { unlinkUserByIdAPI } from "../../../admin/views/users/usersAPI";
import { createUserAPI, getPartnerUserDetailsByIdAPI } from "./partnerUsersAPI";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>No data available</span>
  </div>
);
const defaultSorted = [
  {
    dataField: "organizationName", // if dataField is not match to any column you defined, it will be ignored.
    order: "asc" // desc or asc
  }
];

class PartnerUserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      cuLoader: false,
      userCreationModal: false,
      user: {
        profileImage: ""
      },
      organizations: [],
      name: "",
      phone: "",
      email: "",
      organizationId: "",
      customFields: [],
      columns: [
        {
          dataField: "organizationId",
          text: "Organization Id",
          sort: true
        },
        {
          dataField: "organizationName", //res[0].systems.map(row => row.name)
          text: "Organization Name",
          sort: true
        },
        {
          dataField: "organizationType", //res[0].systems.map(row => row.name)
          text: "Organization Type",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink onClick={e => this.unlinkUser(row.organizationId, e)}>
                  <Tippy placement="top" content="Remove">
                    <span>
                      <FontAwesomeIcon
                        style={{ color: "#d84a4a" }}
                        icon={faUnlink}
                        size="1x"
                      />
                    </span>
                  </Tippy>
                </NavLink>
              </div>
            );
          }
        }
      ],
      organizationType: "",
      organizationTypeDropdown: [
        // { label: "Company", value: "company" },
        { label: "Partner", value: "partner" },
        { label: "Project", value: "project" }
      ],
      selectedEntity: {
        label: ""
      },
      entityDropdown: []
    };
  }

  componentDidMount() {
    console.log("this.props", this.props);
    if (this.props.match.params.id !== undefined) {
      this.getUserDetailsById(this.props.match.params.id);
    }
  }

  getUserDetailsById = id => {
    this.setState({ tableLoader: true, organizations: [] });
    getPartnerUserDetailsByIdAPI(id, this.props.match.params.partnerId)
      .then(res => {
        this.setState({
          user: res.data,
          name: res.data.name,
          email: res.data.email,
          organizations: res.data.organizations,
          phone: parseInt(res.data.phone),
          customFields:
            res.data.customFieldValues === null
              ? []
              : res.data.customFieldValues,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          name: "-",
          email: "-",
          phone: "-"
        });
      });
  };

  toggleUserCreationModal = () => {
    this.setState(prevState => ({
      userCreationModal: !prevState.userCreationModal
    }));
  };

  populateEntityDropdown = entity => {
    const _OrganizationsClient = new OrganizationsClient();
    if (entity === "partner") {
      // Get current partner info
      
      _OrganizationsClient.organizations(this.props.match.params.partnerId)
        .then(res => {
          let _array = [{ label: res.name, value: res.id }];
          this.setState({
            entityDropdown: _array,
            filteredPartners: _array
          });
        })
        .catch(error => { HandleCaught_API_Error(error); });

    } else if (entity === "company") {
      _OrganizationsClient.companiesGetByPartnerId(this.props.match.params.partnerId)
      // getPartnerCompaniesAPI(this.props.match.params.partnerId)
        .then(res => {
          if (!Array.isArray(res)) {
            console.log("debug: ", res);
            return;
          }
          const filteredCompanies = res.filter(item => {
            return item.isActive === true;
          });

          let companiesDropdown = [];
          res.forEach(function (row) {
            companiesDropdown.push({ label: row.name, value: row.id });
          });

          let filteredCompaniesDropdown = [];
          filteredCompanies.forEach(function (row) {
            filteredCompaniesDropdown.push({ label: row.name, value: row.id });
          });

          this.setState({
            entityDropdown: companiesDropdown,
            filteredCompanies: filteredCompaniesDropdown
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ tableLoader: false });
        });
    } else if (entity === "project") {
      _OrganizationsClient.partnerProjects(this.props.match.params.partnerId)
      // getParnerProjectsAPI(this.props.match.params.partnerId)
        .then(res => {
          if (!Array.isArray(res)) {
            console.log("debug: ", res);
            return;
          }
          const filteredProjects = res.filter(item => {
            return item.isActive === true;
          });

          let projectsDropdown = [];
          res.forEach(function (row) {
            projectsDropdown.push({ label: row.name, value: row.id });
          });

          let filteredProjectsDropdown = [];
          filteredProjects.forEach(function (row) {
            filteredProjectsDropdown.push({ label: row.name, value: row.id });
          });

          this.setState({
            entityDropdown: projectsDropdown,
            filteredProjects: filteredProjectsDropdown
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
    } else {
      this.setState({ entityDropdown: [] });
    }
  };

  handleSingleTagboxChange = (state, selectedStateName, selectedOption) => {
    if (state === "organizationType") {
      this.setState({
        selectedOrganizationEntity: null,
        selectedEntity: selectedOption
      });
      this.populateEntityDropdown(selectedOption.value);
    }
    this.setState({
      [state]: selectedOption.value,
      [selectedStateName]: selectedOption === null ? "" : selectedOption
    });
  };

  createUser = e => {
    e.preventDefault();
    this.setState({ cuLoader: true });
    let formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("phone", this.state.phone);
    formData.append("email", this.state.email);
    formData.append("organizationId", this.state.entity);
    if (this.state.user.profileImage !== (undefined || null) ) {
      formData.append(
        "file",
        this.dataURLtoFile(this.state.user.profileImage, "profile-image")
      );
    }

    createUserAPI(formData)
      .then(res => {
        this.setState(
          {
            cuLoader: false
          },
          () => {
            this.getUserDetailsById(this.props.match.params.id);
            this.toggleUserCreationModal();
          }
        );
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ cuLoader: false });
      });
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  unlinkUser = organizationId => {
    Swal.fire({
      title: this.context.languages["swal.are_you_sure"],
      text: this.context.languages["swal.are_you_sure.delete_record"],
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.context.languages[
        "swal.are_you_sure.confirmButtonText"
      ],
      cancelButtonText: this.context.languages[
        "swal.are_you_sure.cancelButtonText"
      ]
    }).then(result => {
      if (result.value) {
        unlinkUserByIdAPI(this.props.match.params.id, organizationId).then(
          res => {
            this.getUserDetailsById(this.props.match.params.id);
            Swal.fire(
              this.context.languages["swal.deleted"],
              this.context.languages["swal.deleted_record_deleted"],
              "success"
            );
          }
        );
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.setState(
          {
            tableLoader: false
          },
          () => {
            Swal.fire(
              this.context.languages["swal.cancelled"],
              this.context.languages["swal.cancelled_your_record_is_safe"],
              "error"
            );
          }
        );
      }
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let { tableLoader, organizations } = this.state;
    return (
      <div className="user-details-view">
        <Row>
          <Col>
            <Accent useIcon={false} logo={this.state.user.profileImage}>
              {this.state.name}
            </Accent>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {this.state.name !== "" ? (
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <TranslatableText translationKey="email" />
                    </td>
                    <td>{this.state.email}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="phone" />
                    </td>
                    <td>{this.state.phone}</td>
                  </tr>
                  {this.state.customFields.map((element, key) => {
                    return (
                      <tr key={key}>
                        <td>{element.fieldLabel}</td>
                        <td>
                          {element.value === true
                            ? "Yes"
                            : element.value === false
                            ? "No"
                            : element.value}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <InlineLoading />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="table-container">
              <ToolkitProvider
                keyField="organizationId"
                data={organizations}
                columns={this.state.columns}
                search
                exportCSV={{
                  fileName: "data.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              CSV
                            </ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <Button
                              className="button-primary"
                              onClick={this.toggleUserCreationModal}
                            >
                              Add user to
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                      pagination={paginationFactory()}
                      defaultSorted={defaultSorted}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.userCreationModal}
            toggle={this.toggleUserCreationModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleUserCreationModal}>
              Add {this.state.name} To:
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={this.createUser}>
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    <FormGroup>
                      <Label for="role">
                        <TranslatableText translationKey="organization_type" />
                      </Label>
                      <Select
                        value={
                          this.state.selectedOrganizationType === ""
                            ? null
                            : this.state.selectedOrganizationType
                        }
                        className="user-selectbox"
                        options={this.state.organizationTypeDropdown}
                        onChange={selectedOption =>
                          this.handleSingleTagboxChange(
                            "organizationType",
                            "selectedOrganizationType",
                            selectedOption
                          )
                        }
                        hideSelectedOptions={false}
                      />
                    </FormGroup>
                  </Col>

                  {this.state.organizationType !== "" ? (
                    <Col className="multiselect-container" md={12}>
                      <FormGroup>
                        <Label for="entity">
                          {this.state.selectedEntity.label}
                        </Label>
                        <Select
                          value={
                            this.state.selectedOrganizationEntity === ""
                              ? null
                              : this.state.selectedOrganizationEntity
                          }
                          className="user-selectbox"
                          options={this.state.entityDropdown}
                          onChange={selectedOption =>
                            this.handleSingleTagboxChange(
                              "entity",
                              "selectedOrganizationEntity",
                              selectedOption
                            )
                          }
                          hideSelectedOptions={false}
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    <Col className="multiselect-container" md={12}>
                      <FormGroup>
                        <Label className="" for="entity">
                          <TranslatableText translationKey="select_entity" />
                        </Label>
                        <Input disabled={true} type="select">
                          <option value="">-- Vælg --</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row form className="flex flex-end">
                  <Button outline onClick={this.toggleUserCreationModal}>
                    <TranslatableText translationKey="cancel" />
                  </Button>
                  {this.state.cuLoader === false ? (
                    <Button className="ml-10" outline>
                      <TranslatableText translationKey="add" />
                    </Button>
                  ) : (
                    <span className="ml-10">
                      <InlineLoading />
                    </span>
                  )}
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(PartnerUserDetails);
PartnerUserDetails.contextType = GlobalContext;