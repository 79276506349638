import { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Approvals from "./Approvals";
import EntityApprovalDetails from "./EntityApprovalDetails";

class ApprovalsRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-Entity-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/details/:wizardId/:regardingObjectId/:regardingObjectTypeId/:approvalId/:dataVersion/:icon?`}
          >
            <EntityApprovalDetails />
          </Route>
          <Route
            exact
            path="/approvals"
            render={() => <Approvals />}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(ApprovalsRouter);
