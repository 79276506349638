import classnames from "classnames";
import { FormGroup, Label } from "reactstrap";
import TranslatableText from "../../Language/TranslatableText";

let Toggle = ({
  className,
  label,
  toggleButton,
  stateName,
  innerClassName,
  componentState
}) => {
  return (
    <FormGroup className={className}>
      <Label for="">
        <TranslatableText translationKey={label} />
      </Label>
      <div
        onClick={e => toggleButton(stateName, e)}
        className={classnames(innerClassName)}
      >
        <span
          className={classnames(
            "ball",
            componentState[stateName] ? "toggled" : ""
          )}
        />
      </div>
    </FormGroup>
  );
};

export default Toggle;
