import axios from "axios";
import { Config } from "../../config/Config";
import authService from "../../components/api-authorization/AuthorizeService";

// Entity API URL
const ENTITY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/CustomEntityValues`;

export const createEntityAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };

  return axios
    .post(`${ENTITY_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update Entity API
export const updateEntityAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${ENTITY_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Entity By ID
export const getEntityByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ENTITY_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get All Entitiess by ID
export const getEntitiesAPI = async regardingObjectTypeId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ENTITY_API_URL}/${regardingObjectTypeId}/CustomEntityValues`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Delete Entity By ID
export const deleteEntityByIdAPI = async id => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${ENTITY_API_URL}`, {
      data: {
        id: id
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};
