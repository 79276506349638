import { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { AccessLevels } from "../config/Levels";
import PlatformRouter from "../platformSpecific/routes/PlatformRouter";
import AdminRouter from "../views/admin/AdminRouter";
import AnnualCycle from "../views/annual-cycle/AnnualCycle";
import AttachmentsRouter from "../views/attachments/AttachmentsRouter";
import CompanyAdminRouter from "../views/company-admin/CompanyAdminRouter";
import DynamicTree from "../views/company-admin/views/sharepoint/Tree";
import Dashboard from "../views/dashboard/Dashboard";
import DashboardRouter from "../views/dashboard/DashboardRouter";
import DocumentsRouter from "../views/documents/DocumentsRouter";
import EntityRouter from "../views/entity/EntityRouter";
import ApprovalsRouter from "../views/my-approvals/ApprovalsRouter";
import NotFound from "../views/notFound/NotFound";
import PartnerRouter from "../views/partner/PartnerRouter";
import ProcessesRouter from "../views/processes/ProcessesRouter";
import Settings from "../views/settings/Settings";
import TasksRouter from "../views/tasks/TasksRouter";
import Questionnaire from "../views/wizard/Questionnaire";
import QuestionnaireThankYou from "../views/wizard/QuestionnaireThankYou";
import { PrivateRoute } from "./PrivateRoute";

import LawSignUp from "../platformSpecific/views/lawSignUp/signUp";

import { GlobalContext } from "../contexts/global-context";
import LawDashboard from '../platformSpecific/views/law/lawyer';
import RealtorDashboard from "../platformSpecific/views/realtor/realtor";
import WizardRouter from "../views/wizard/WizardRouter";
import EntityWrapper from "../views/entity/EntityWrapper";
import CreateProcess from "../views/dashboard/views/CreateProcess";

class Routes extends Component {
  render() {
    if (this.props.auth0User && this.props.auth0User["https://360-advisor.com/otp"] === "true") {
      return (
        <Switch>
          <Route
            path={`/questionnaires/:questionnaire`}
            exact
            render={props => <Questionnaire {...props} />}
          />
          <Route
            path={`/questionnaires/:questionnaire/thank-you`}
            exact
            render={props => <QuestionnaireThankYou {...props} />}
          />
          <Route><NotFound /></Route>
        </Switch>
      );
    }

    if (this.context.user.organizations.system === 'Revisormappen') {

      return (
        <Switch>
          <Route
            exact
            path="/"
            render={props => (
              <DynamicTree {...props} showCompanyInformation={true} />
            )}
          />
          <Route
            path="/company-admin"
            render={props => <CompanyAdminRouter {...props} />}
          />
          <Route
            path="/partner"
            children={<PartnerRouter />}
          />
          <PrivateRoute
            path="/admin"
            accountLevel={AccessLevels.developer}
            component={AdminRouter}
          />
          <Route
            key={Math.random()}
            path="/settings"
            render={props => <Settings {...props} />}
          />
          <Route path="/platform">
            <PlatformRouter />
          </Route>
          <Route><NotFound /></Route>
        </Switch>
      );

    }
    else if (this.context.user.organizations.system === 'Digital Jura') {
      return (
        <Switch>
          <Route
            exact
            path="/"
            children={<LawDashboard />}
          />
          <Route
            path="/processes"
            render={props => <ProcessesRouter {...props} />}
          />
          <Route
            path="/signup"
            children={<LawSignUp />}
          />
          <Route
            path="/documents"
            render={props => <DocumentsRouter {...props} />}
          />
          <Route
            path="/company-admin"
            render={props => <CompanyAdminRouter {...props} />}
          />
          <Route
            path="/partner"
            children={<PartnerRouter />}
          />
          <PrivateRoute
            path="/admin"
            accountLevel={AccessLevels.developer}
            component={AdminRouter}
          />
          <Route
            key={Math.random()}
            path="/settings"
            render={props => <Settings {...props} />}
          />
          <Route path="/platform">
            <PlatformRouter />
          </Route>
          <Route><NotFound /></Route>
        </Switch>
      );

    }
    else if (this.context.user.organizations.system === 'Tristad') {
      return (
        <Switch>
          <Route
            exact
            path="/"
            children={<RealtorDashboard />}
          />
          <Route
            path="/processes"
            render={props => <ProcessesRouter {...props} />}
          />
          <Route
            path="/company-admin"
            render={props => <CompanyAdminRouter {...props} />}
          />
          <Route
            path="/partner"
            children={<PartnerRouter />}
          />
          <PrivateRoute
            path="/admin"
            accountLevel={AccessLevels.developer}
            component={AdminRouter}
          />
          <Route
            key={Math.random()}
            path="/settings"
            render={props => <Settings {...props} />}
          />
          <Route path="/platform">
            <PlatformRouter />
          </Route>
          <Route><NotFound /></Route>
        </Switch>
      );

    } else {
      return (
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route
            path={'/dashboard/process/create/:sectionId'}
            render={props => <CreateProcess {...props} />}
          />
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route
            path="/annual-cycle-of-work"
            render={props => (
              <AnnualCycle {...props} />
            )}
          />
          <Route
            path="/processes"
            render={props => <ProcessesRouter {...props} />}
          />
          <Route
            path="/documents"
            render={props => <DocumentsRouter {...props} />}
          />
          <Route
            path="/company-admin"
            render={props => <CompanyAdminRouter {...props} />}
          />
          <Route
            path="/partner"
            children={<PartnerRouter />}
          />
          <PrivateRoute
            path="/admin"
            accountLevel={AccessLevels.developer}
            component={AdminRouter}
          />
          <Route
            path="/settings"
            render={props => <Settings {...props} />}
          />
          <Route
            path="/attachments"
            render={props => <AttachmentsRouter {...props} />}
          />
          <Route path="/tasks" render={props => <TasksRouter {...props} />} />
          <Route
            path="/entity/:regardingObjectTypeId"
            render={props => <EntityRouter {...props} />}
          />
          <Route
            path="/approvals"
            render={props => <ApprovalsRouter {...props} />}
          />
          <Route
            path="/wizard"
            children={<WizardRouter />}
          />
          <Route path="/platform">
            <PlatformRouter />
          </Route>

          <Route
            path="/entities/:entityPageId"
            children={<EntityWrapper />}
          />
          <Route><NotFound /></Route>


        </Switch>
      );
    }
  }
}

export default Routes;
Routes.contextType = GlobalContext;