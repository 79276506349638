import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, NavLink, Row } from "reactstrap";
import { CustomEntityValuesClient, ProcessesClient } from "../../apiClients";
import { InlineLoading } from "../../components";
import Accent from "../../components/accent/Accent";
import Dendro from "../../components/diagrams/dendrodiagram/Dendro";
import Gap from "../../components/gap/Gap";
import { GlobalContext } from "../../contexts/global-context";
import TranslatableText from "../../Language/TranslatableText";
import { History } from 'history'
interface IProps {
  match: any,
  history: History,
}
interface IState {
  entityDetails: entityDetails,
  customFields: any[]
}
type entityDetails = {
  name: string,
  description: string,
  parentAssetId: string,
  ownerId: string,
  organizationId: string,
  id: string,
  regardingObjectTypeId: string
}
class EntityDetails extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      entityDetails: {
        name: "",
        description: "",
        parentAssetId: "",
        ownerId: "",
        organizationId: "",
        id: "",
        regardingObjectTypeId: ""
      },
      customFields: []
    };
    let _ProcessesClient = new ProcessesClient();
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      let _CustomEntityValuesClient = new CustomEntityValuesClient();

      _CustomEntityValuesClient.customEntityValuesGet(this.props.match.params.id)
        .then(res => {
          this.setState({
            // @ts-ignore
            entityDetails: res,
            // @ts-ignore
            customFields:
              res.customFieldValues === null
                ? []
                : res.customFieldValues
          });
        })
        .catch(error => {
          if (error.response !== undefined && error.response.status === 403) {
            toast.error(this.context.languages["insufficient_permission"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 2000
            });
          } else {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 2000
            });
          }
        });
    }
  }

  arrayFormatter = fields => {
    if (fields !== null && fields !== "" && Array.isArray(fields)) {
      return fields.map((item, index) => (
        <span key={index}>
          {item.label} {index < fields.length - 1 ? ",\u00A0" : ""}
        </span>
      ));
    }
  };

  renderTableData = customField => {
    if (customField.displayTypeCode === 1007)
      if (customField.tagBoxValues.length > 0) {
        return customField.tagBoxValues.map((field, index) => (
          <tr key={index}>
            <td>{field.label}</td>
            <td>
              {field.description !== undefined &&
                field.description !== "" &&
                field.description !== null
                ? field.description.replace(/<[^>]*>?/gm, "")
                : field.description}
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={2}>
              <TranslatableText translationKey="no_data_available" />
            </td>
          </tr>
        );
      }

  };
  renderTableHeader = () => {
    return (
      <tr className="thead">
        <th style={{ width: '30%' }}>
          <TranslatableText translationKey="name" />
        </th>
        <th style={{ width: '70%' }}>
          <TranslatableText translationKey="description" />
        </th>
      </tr>
    );
  };
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { entityDetails } = this.state;
    return (
      <div className="assets-details-view">
        <div className="relative">
          <NavLink
            className="absolute right edit"
            tag={Link}
            to={`/entity/update/${this.state.entityDetails.regardingObjectTypeId}/${this.state.entityDetails.id}`}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </NavLink>
        </div>
        {entityDetails.name !== "" ? (
          <h1>
            <span className="Capitalize">{entityDetails.name}</span>
          </h1>
        ) : (
          <InlineLoading />
        )}

        <hr />
        <Row>
          <Col md={12}>
            <h4>
              <TranslatableText translationKey="description" />
            </h4>
            {entityDetails.description !== "" ? (
              <p>{entityDetails.description}</p>
            ) : (
              <InlineLoading />
            )}
          </Col>
        </Row>
        {this.state.entityDetails.name !== "" && this.state.customFields.length > 0 ? (
          <Row>
            <Col xs={{ size: 3 }} md={{ size: 3 }} lg={{ size: 3 }}></Col>
            <Col xs={{ size: 8 }} md={{ size: 8 }} lg={{ size: 8 }}>
              <Dendro entity={this.state.entityDetails.name} data={this.state.customFields} />
            </Col>

          </Row>
        ) : (
          <InlineLoading />
        )}
        <Gap size="50px" />
        <Row>
          <Col md={12}>
            <h4>
              <TranslatableText translationKey="custom_fields" />
            </h4>
            <table className="table">
              <tbody>
                {this.state.customFields.map((element, key) =>
                  element.displayTypeCode !== 1007 ? (
                    <tr key={key}>
                      <td>{element.displayText}</td>
                      <td>
                        {element.value === true
                          ? "Yes"
                          : element.value === false
                            ? "No"
                            : element.displayTypeCode === 1007
                              ? this.arrayFormatter(element.tagBoxValues)
                              : element.value}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )
                )}
              </tbody>
            </table>
            <Gap size="50px" />
            <div className="custom-fields-section">
              {this.state.customFields.map((customField, index) =>
                customField.displayTypeCode === 1007 ? (
                  <div key={index}>
                    <Accent useHtmlIcon={false}>
                      {customField.displayText}
                    </Accent>
                    <table className="table table-bordered ">
                      <thead>{this.renderTableHeader()}</thead>
                      <tbody>{this.renderTableData(customField)}</tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )
              )}
            </div>
          </Col>
        </Row>


        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
// @ts-ignore
export default withRouter(EntityDetails);
EntityDetails.contextType = GlobalContext;