import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Persons from "./Persons";
import CreatePerson from "./CreatePerson";
import PersonDetails from "./PersonDetails";
import { withRouter } from "react-router-dom";

class PersonsRouter extends Component {
  render() {
    return (
      <div className="admin-process-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/create`}
            render={props => (
              <CreatePerson {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <PersonDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreatePerson {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/company-admin/persons"
            render={props => (
              <Persons {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(PersonsRouter);
