import { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Admin from "./Admin";
import CompanyRouter from "./views/company/CompanyRouter";
import CustomEntitiesRouter from "./views/custom-entities/CustomEntitiesRouter";
import CustomFieldsRouter from "./views/custom-fields/CustomFieldsRouter";
import PartnersRouter from "./views/partners/PartnersRouter";
import ProjectsRouter from "./views/projects/ProjectsRouter";
import SystemsRouter from "./views/systems/SystemsRouter";
import UsersRouter from "./views/users/UsersRouter";
import WizardRouter from "./views/wizard/WizardRouter";

class AdminRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-admin-view">
      <div className="DisplayCompany d-sm-block d-none">
        <div>Admin</div>
      </div>
        <Switch>
          <Route
            path={`${this.props.match.path}/partners`}
            render={props => <PartnersRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/company`}
            render={props => <CompanyRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/projects`}
            render={props => <ProjectsRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/systems`}
            render={props => <SystemsRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/wizard`}
            render={props => <WizardRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/users`}
            render={props => <UsersRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/custom-fields`}
            render={props => <CustomFieldsRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/custom-entities`}
            render={props => <CustomEntitiesRouter {...props} />}
          />
          <Route path="/admin" render={props => <Admin {...props} />} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(AdminRouter);