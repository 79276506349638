export const ResourceTypes: ResourceTypes = {
  process: "7eca674a-9b51-4aed-bd11-ea219c5c0d17",
  section: "7eca674a-9b51-4aed-bd11-ea219c5c8d17",
  users: "3eca674a-0b51-4aed-bd11-ea219c5c0d18",
  notes: "2eca174a-0b51-4aed-bd11-ea219c5c0d16",
  task: "1eca174a-0c51-4aed-bd01-ea209c5c0d13",
  flows: "6981e5d5-7b4e-4bdf-8538-d0854a072cc6",
  persons: "d53f5a11-4a9d-4146-ae36-547ab99dc127"
};
export type ResourceTypes = { [key: string]: string }
