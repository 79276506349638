import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { createTeamAPI, getTeamByIdAPI, updateTeamAPI } from "./teamsAPI";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../components";



class CreateTeam extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
      },
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="required_field" />
      }
    ]);

    this.state = {
      name: "",
      email: "",
      description: "",
      loading: false,
      cuLoader: false,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getTeamByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.name,
            email: res.data.email,
            description: res.data.description,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }
  createTeam = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      ...this.state,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      organizationId:
        this.props.match.params.organizationId === undefined
          ? this.context.user.organizations.organizationId
          : this.props.match.params.organizationId
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateTeamAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createTeamAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-team-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          {this.props.match.params.id !== undefined ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />} a{" "}
          <TranslatableText translationKey="team" />
        </h3>
        <Form onSubmit={this.createTeam}>
          <Row form>
            <Col md={6}>
              <FormGroup
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  <TranslatableText translationKey="name" />
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.name.message}</span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className={validation.name.email ? "has-error" : ""}>
                <Label for="name">
                  <TranslatableText translationKey="email" />
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder=""
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.email.message}</span>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="description">
                  <TranslatableText translationKey="description" />
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateTeam);
CreateTeam.contextType = GlobalContext;