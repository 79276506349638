import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// CustomFields API URL
const CUSTOM_FIELDS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/CustomFields`;

export const createCustomFieldAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${CUSTOM_FIELDS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update CustomField API
export const updateCustomFieldAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${CUSTOM_FIELDS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get CustomField By ID
export const getCustomFieldByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${CUSTOM_FIELDS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get All CustomFields
export const getCustomFieldsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${CUSTOM_FIELDS_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Get CustomFields of Regarding Object ID
export const getRegardingCustomFieldsAPI = async regardingObjectTypeId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${CUSTOM_FIELDS_API_URL}/${regardingObjectTypeId}/CustomFields`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get Boolean CustomFields of Regarding Object ID
export const getBooleanCustomFieldsAPI = async regardingObjectTypeId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${CUSTOM_FIELDS_API_URL}/${regardingObjectTypeId}/BooleanCustomFields`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Get particular CustomFields of Regarding Object ID
export const getOrganizationRegardingCustomFieldsAPI = async (
  regardingObjectTypeId,
  organizationId
) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${CUSTOM_FIELDS_API_URL}/${regardingObjectTypeId}/${organizationId}/CustomFields`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Get CustomFields of Entity by ID
export const getEntityCustomFieldsAPI = async code => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${CUSTOM_FIELDS_API_URL}/${code}/EntityCustomFields`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Delete CustomField By ID
export const deleteCustomFieldByIdAPI = async id => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${CUSTOM_FIELDS_API_URL}`, {
      data: {
        id: id
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// CustomField Process Association
export const CustomFieldProcessAssociationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${CUSTOM_FIELDS_API_URL}/CustomFieldProcessAssociation`, data, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get CustomField Processes
export const getCustomFieldProcesses = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${CUSTOM_FIELDS_API_URL}/${id}/Processes`, { headers: headers })
    .then(({ data }) => data);
};

// Delete a Process from an CustomField
export const deleteProcessCustomFieldAPI = async data => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${CUSTOM_FIELDS_API_URL}/DeleteCustomFieldProcessAssociation`, {
      data: data,
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Delete an CustomField from a Process
export const deleteCustomFieldProcessAPI = async data => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${CUSTOM_FIELDS_API_URL}/DeleteCustomFieldProcessAssociation`, {
      data: data,
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Lookup APIs

const LOOKUPS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/LookUps`;

// GetCustomFieldDataType
export const getCustomFieldDataType = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${LOOKUPS_API_URL}/GetCustomFieldDataType`, { headers: headers })
    .then(({ data }) => data);
};

// GetCustomFieldDisplayType
export const getCustomFieldDisplayType = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${LOOKUPS_API_URL}/GetCustomFieldDisplayType`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Translations
// Get Custom Field Translations By ID
export const getCustomFieldTranslationsByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${CUSTOM_FIELDS_API_URL}/${id}/CustomFieldTranslations`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get Custom Field Base Translation By ID
export const getCustomFieldBaseTranslationsByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${CUSTOM_FIELDS_API_URL}/DefaultCustomFieldTranslation/${id}`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Add translation for custom Field
export const addCustomFieldTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${CUSTOM_FIELDS_API_URL}/Translations`, data, {
      headers: headers
    })
    .then(res => res);
};

// Update translation for custom Field
export const updateCustomFieldTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${CUSTOM_FIELDS_API_URL}/Translations`, data, {
      headers: headers
    })
    .then(res => res);
};

// Get Single Custom Field Translation By ID
export const getSingleCustomFieldTranslationByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${CUSTOM_FIELDS_API_URL}/CustomFieldTranslation/${id}`, {
      headers: headers
    })
    .then(({ data }) => data);
};
