import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import CompanyWizard from "./CompanyWizard";
import CompanyWizardDetails from "./CompanyWizardDetails";
import CreateCompanyWizardTemplate from "./CreateCompanyWizardTemplate";
import { withRouter } from "react-router-dom";

class CompanyWizardRouter extends Component {
  render() {
    return (
      <div className="admin-wizard-view">
        <Switch>
          <Route
            path={`/company-admin/wizard/template/update/:wizardId/:id/:organizationId`}
            render={props => (
              <CreateCompanyWizardTemplate
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/company-admin/wizard/template/create/:wizardId/:organizationId`}
            render={props => (
              <CreateCompanyWizardTemplate
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/company-admin/wizard/details/:id/:organizationId`}
            render={props => (
              <CompanyWizardDetails
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path="/company-admin/wizard/:organizationId"
            render={props => (
              <CompanyWizard {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(CompanyWizardRouter);