import React, { Component } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faCloudDownloadAlt,
  faInfoCircle,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import TranslatableText from "../../Language/TranslatableText";
import {
  getAttachmentsAPI,
  deleteAttachmentByIdAPI,
  downloadAttachmentByIdAPI
} from "./attachmentsAPI";
import { getProcessByIdAPI } from "../company-admin/views/process/companyProcessAPI";
import { getSectionByIdAPI as getCompanySectionByIdAPI } from "../company-admin/views/sections/companySectionsAPI";
import { getEntityByIdAPI } from "../entity/entityAPI";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { GlobalContext } from "../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>No data available</span>
  </div>
);

class Attachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableLoader: false,
      deleteLoader: false,
      attachments: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "size",
          text: "Size",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ],
      deleteModal: false,
      attachmentId: null,
      entityType: "",
      entityName: ""
    };
  }
  componentDidMount() {
    var type = this.props.match.url.split("/")[1];
    if (
      this.props.match.url.split("/")[1] === "admin" ||
      this.props.match.url.split("/")[1] === "company-admin"
    ) {
      type = this.props.match.url.split("/")[2];
    }
    this.setState(
      {
        entityType: type
      },
      () => {
        switch (this.state.entityType) {
          case "sections":
            if (this.props.match.url.split("/")[1] === "admin") {
              // code block
            } else {
              getCompanySectionByIdAPI(this.props.match.params.id)
                .then(res => {
                  this.setState({
                    entityName: res.data.name
                  });
                })
                .catch(error => {
                  toast.error(this.context.languages["api_error"], {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                  });
                });
            }
            break;
          case "processes":
            getProcessByIdAPI(this.props.match.params.id)
              .then(res => {
                this.setState({
                  entityName: res.data.nameShort
                });
              })
              .catch(error => {
                toast.error(this.context.languages["api_error"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000
                });
              });
            break;
          case "entity":
            getEntityByIdAPI(this.props.match.params.id)
              .then(res => {
                this.setState({
                  entityName: res.data.name,
                  entityType:
                    res.data.entityName === null ? "" : res.data.entityName,
                  loading: false
                });
              })
              .catch(error => {
                toast.error(this.context.languages["api_error"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000
                });
                this.setState({ tableLoader: false });
              });
            break;
          default:
          // code block
        }
      }
    );

    this.populateAttachments(this.props.match.params.id);
  }

  downloadFile = id => {
    this.setState({ loading: true });
    downloadAttachmentByIdAPI(id)
      .then(res => {
        this.setState({ loading: false });
        var response = res.data;
        const url = window.URL.createObjectURL(
          new Blob([response], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            noficationId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      attachmentId: id
    });
  };

  populateAttachments = id => {
    this.setState({ tableLoader: true, attachments: [] });
    getAttachmentsAPI(id)
      .then(res => {
        if (!Array.isArray(res)) {
          return;
        }
        var self = this;
        var data = res
          .filter(function (el) {
            if (el.name === "") {
              return false; // skip
            }
            return true;
          })
          .map(function (el) {
            var o = Object.assign({}, el);
            o.actions = (
              <div className="actions">
                <NavLink tag={Link} to={`/attachments/details/${el.id}`}>
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
                {el.contentType !== null && el.contentType !== "" ? (
                  <NavLink onClick={e => self.downloadFile(el.id, e)}>
                    <FontAwesomeIcon
                      style={{ color: "var(--primary)" }}
                      icon={faCloudDownloadAlt}
                      size="1x"
                    />
                  </NavLink>
                ) : (
                  ""
                )}

                <NavLink
                  tag={Link}
                  to={`/attachments/update/${self.state.entityType}/${el.regardingObjectId}/${el.id}`}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
                <NavLink onClick={e => self.deleteRecord(el.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
            return o;
          });
        this.setState({ attachments: data, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ attachments: [], loading: false, tableLoader: false });
      });

  };
  goBack = () => {
    this.props.history.goBack();
  };

  deleteAttachment = id => {
    this.setState({ deleteLoader: true });
    deleteAttachmentByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populateAttachments(this.props.match.params.id);
        this.setState({
          deleteModal: false,
          attachmentId: null,
          deleteLoader: false
        });
        Swal.fire(
          this.context.languages["swal.deleted"],
          this.context.languages["swal.deleted_record_deleted"],
          "success"
        );
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ deleteLoader: false });
      });
  };

  deleteRecord = id => {
    this.setState({ tableLoader: false }, () => {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.delete_record"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.context.languages[
          "swal.are_you_sure.confirmButtonText"
        ],
        cancelButtonText: this.context.languages[
          "swal.are_you_sure.cancelButtonText"
        ]
      }).then(result => {
        if (result.value) {
          this.setState({ deleteLoader: true });
          this.deleteAttachment(id);
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState(
            {
              tableLoader: false
            },
            () => {
              Swal.fire(
                this.context.languages["swal.cancelled"],
                this.context.languages["swal.cancelled_your_record_is_safe"],
                "error"
              );
            }
          );
        }
      });
    });
  };

  render() {
    let { attachments, columns, loading, tableLoader } = this.state;
    return (
      <div className="admin-systems-view">
        {loading && (
          <ScreenLoading />
        )}
        <h1>
          <TranslatableText translationKey="company.attachment" />
        </h1>
        <Row>
          <Col md={12}>
            {this.state.entityName !== "" ? (
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="entity_type" />
                    </td>
                    <td className="capitalize">{this.state.entityType}</td>
                  </tr>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="tasks.entityName" />
                    </td>
                    <td>{this.state.entityName}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <InlineLoading />
            )}
          </Col>
        </Row>
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={attachments}
            columns={columns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`/attachments/create/${this.state.entityType.toLowerCase()}/${
                            this.props.match.params.id
                          }`}
                        >
                          <TranslatableText translationKey="create" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeletePopup}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeletePopup}>
            Confirm Delete?
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <p>
                  <TranslatableText translationKey="attachment.confirm_delete_attachment_description" />
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {this.state.deleteLoader === false ? (
              <Button
                outline
                className="redOutline"
                onClick={e => this.deleteAttachment(this.state.attachmentId, e)}
              >
                <TranslatableText translationKey="delete" />
              </Button>
            ) : (
              <InlineLoading />
            )}{" "}
            <Button outline onClick={this.toggleDeletePopup}>
              <TranslatableText translationKey="cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Attachments);
Attachments.contextType = GlobalContext;