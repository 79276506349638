import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Users from "./Users";
import CreateUser from "./CreateUser";
import UserDetails from "./UserDetails";
import { withRouter } from "react-router-dom";

class UsersRouter extends Component {
  render() {
    return (
      <div className="admin-process-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/create`}
            render={props => (
              <CreateUser {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <UserDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreateUser {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/company-admin/users"
            render={props => (
              <Users {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(UsersRouter);
