import React from 'react';
import { NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo_360 from "../../../resources/images/360Advisor.svg";
import Logo_KD from "../../../resources/images/kreston.png";
import Logo_TT from "../../../resources/images/ttrevisionWhite.svg";
import Logo_DPO from "../../../resources/images/DPOAdvisor.svg";
import Logo_Bruun from "../../../resources/images/bruunRasmussen.svg";
import Logo_CISO from "../../../resources/images/cisoadvisor.svg";
import Logo_LAW from "../../../resources/images/gavel.svg";
import Logo_Tristad from "../../../resources/images/tristad.svg";
import Logo_OENS from "../../../resources/images/oens.png";


export function SystemLogo(props: { systemName: string; partnerId: string }) {
    switch (props.systemName) {
        case 'DPO Advisor':
            changeFavicon('DPOfavicon.png');
            // addSupportBtn();
            return (
                <NavbarBrand tag={Link} to="/" className="brand">
                    <img className="logo" style={{ height: '32px' }} src={Logo_DPO} alt="" />
                </NavbarBrand>
            );
        case 'Bruun Rasmussen':
            return (
                <NavbarBrand tag={Link} to="/" className="brand">
                    <img className="logo" style={{ height: '32px' }} src={Logo_Bruun} alt="" />
                </NavbarBrand>
            );
        case 'Revisormappen':
            changeFavicon('KDfavicon.svg')
            return (
                <NavbarBrand tag={Link} to="/company-admin/sharepoint" className="brand">
                    {props.partnerId === 'e20fb242-ad00-481d-aefb-608ad54e857c' && <img className="logo" style={{ height: '32px' }} src={Logo_TT} alt="" />}
                    {props.partnerId === '4ef4bcbb-1cff-4dc7-ad18-a47e082b7856' && <img className="logo" style={{ height: '32px' }} src={Logo_KD} alt="" />}
                </NavbarBrand>
            );
        case 'KD-Sys 360':
            changeFavicon('KDfavicon.svg')
            return (
                <NavbarBrand tag={Link} to="/" className="brand">
                    <img className="logo" style={{ height: '32px' }} src={Logo_KD} alt="" />
                </NavbarBrand>
            );
        case 'Digital Jura':
            return (
                <NavbarBrand tag={Link} to="/" className="brand">
                    <img className="logo" style={{ height: '32px' }} src={Logo_OENS} alt="" />
                </NavbarBrand>
            );
        case 'Tristad':
            return (
                <NavbarBrand tag={Link} to="/" className="brand">
                    <img className="logo" style={{ height: '32px' }} src={Logo_Tristad} alt="" />
                </NavbarBrand>
            );
        case 'CISO Advisor':
            changeFavicon('cisofavicon.svg')
            return (
                <NavbarBrand tag={Link} to="/" className="brand">
                    <img className="logo" style={{ height: '32px' }} src={Logo_CISO} alt="" />
                </NavbarBrand>
            );
        default:
            return (
                <NavbarBrand tag={Link} to="/" className="brand">
                    <img className="logo" style={{ height: '32px' }} src={Logo_360} alt="" />
                </NavbarBrand>
            );
    }
}

function changeFavicon(iconPath: string) {
    let link: HTMLLinkElement = document.querySelector("#svgIcon")!;
    link.rel = 'shortcut icon';
    link.href = '/' + iconPath;
    // document.getElementsByTagName('head')[0].appendChild(link);
    document.head.appendChild(link);
}


// function addSupportBtn() {

//     affixScriptToHead("https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js", 
//     'function () { FreshWidget.init("", { "queryString": "&widgetType=popup&submitThanks=Tak+for+din+Feedback", "utf8": "✓", "widgetType": "popup", "buttonType": "text", "buttonText": "Support", "buttonColor": "white", "buttonBg": "#003399", "alignment": "2", "offset": "25vh", "submitThanks": "Tak for din Feedback", "formHeight": "500px", "url": "https://dpoadvisor.freshdesk.com" }); }');

//     // var freshJs = document.createElement("script");
//     // document.head.appendChild(freshJs);
//     // freshJs.src = 'https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js';
//     // // freshJs.type = 'text/javascript';

//     // var freshJsScript = document.createElement("script");
//     // document.head.appendChild(freshJsScript);
//     // // freshJsScript.type = 'text/javascript';
//     // freshJsScript.text = 'FreshWidget.init("", { "queryString": "&widgetType=popup&submitThanks=Tak+for+din+Feedback", "utf8": "✓", "widgetType": "popup", "buttonType": "text", "buttonText": "Support", "buttonColor": "white", "buttonBg": "#003399", "alignment": "2", "offset": "25vh", "submitThanks": "Tak for din Feedback", "formHeight": "500px", "url": "https://dpoadvisor.freshdesk.com" });'

// }

// function loadError(oError:string | Event) {
//     throw new URIError("The script " + oError + " didn't load correctly.");
// }

// function affixScriptToHead(url:string, onloadFunction:any) {
//     var newScript = document.createElement("script");
//     newScript.onerror = loadError;
//     if (onloadFunction) { newScript.onload = onloadFunction; }
//     document.head.appendChild(newScript);
//     newScript.src = url;
// }
{/* <link rel="icon" type="image/svg+xml" href="%PUBLIC_URL%/favicon.svg">
<link rel="icon" type="image/png" href="%PUBLIC_URL%/favicon.png"> */}