import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// User API URL
const USERS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Users`;

// Company API URL
const COMPANY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Organizations`;

export const createUserAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${USERS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update User API
export const updateUserAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${USERS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get User By ID
export const getUserByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all Useres
export const getUsersAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};
// Delete By ID
export const deleteUserByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${USERS_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Get all Companies
export const getCompaniesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Get User Detais By ID
export const getUserDetailsByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}/${id}/UserDetail`, { headers: headers })
    .then(res => res);
};

// Update User API
export const updatePlatformUserAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${USERS_API_URL}/UpdatePlatformUser`, data, { headers: headers })
    .then(res => res);
};

// Unlink a user By ID
export const unlinkUserByIdAPI = async (id, organizationId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${USERS_API_URL}/OrganizationUser`, {
      data: {
        id: id,
        OrganzationId: organizationId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};
