import React from "react";
import { Row } from "reactstrap";
import TranslatableText from "../../Language/TranslatableText";
import ReportHistory from "./ReportHistory";
import { usePlatformUserData } from "../../contexts";

export default function Documents() {
  const currentOrgData = usePlatformUserData();

  return (
    <div className="documents-view">
      <h1>
        <TranslatableText translationKey="documents" />
      </h1>
      <Row>
        {Array.isArray(currentOrgData.currentOrgData.systemSettings.reporting) ?
          currentOrgData.currentOrgData.systemSettings.reporting.map((id) => {
            return <ReportHistory key={id} typeID={id} />;
          })
          :
          <ReportHistory key={1} typeID={currentOrgData.currentOrgData.systemSettings.reporting} />
        }
      </Row>
    </div>
  );
}
