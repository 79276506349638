import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Systems API URL
const FLOW_ENGINE_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/FlowEngines`;
const API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}`;

export const createFlowEngineAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${FLOW_ENGINE_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update System API
export const updateFlowEngineAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${FLOW_ENGINE_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get System By ID
export const getFlowEngineByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${FLOW_ENGINE_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all systems
export const getOrganizationFlowEngines = async (
  regardingObjectTypeId,
  regardingObjectId
) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${FLOW_ENGINE_API_URL}/GetOrganizationFlowEngines?regardingObjectTypeId=${regardingObjectTypeId === undefined ? "" : regardingObjectTypeId
      }&regardingObjectId=${regardingObjectId === undefined ? "" : regardingObjectId
      }`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Delete By ID
export const deleteFlowByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .delete(`${FLOW_ENGINE_API_URL}`, {
      data: {
        id: id
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Get history
export const getFlowEngineHistory = async (
  id
) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${API_URL}/FlowengineHistoryLogs/FlowEngineHistoryLogs?FlowEngineID=${id === undefined ? "" : id}`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Get history details
export const getFlowEngineHistoryDetails = async (
  id
) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${API_URL}/FlowengineHistoryLogs/GetFlowEngineStepsHistoryLogs?FlowEngineHistoryLogID=${id === undefined ? "" : id}`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Get history approval
export const getFlowEngineHistoryApproval = async (
  id,
  stepId
) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${API_URL}/FlowengineHistoryLogs/GetApprovalFlowHistoryLogs?FlowEngineHistoryLogID=${id === undefined ? "" : id}&stepId=${stepId === undefined ? "" : stepId}`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};