import React, { ChangeEvent, useEffect, useState } from "react";
import "survey-react/survey.min.css";
import { Row, Col, Button, Input, NavLink, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./SurveyWizard.scss";
import TranslatableText from "../../Language/TranslatableText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router-dom";

import authService from "../../components/api-authorization/AuthorizeService";
import axios, { AxiosResponse } from "axios";
import { Config } from "../../config/Config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Accent from "../../components/accent/Accent";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import Tippy from "@tippyjs/react";
import { InlineLoading } from "../../components";

const { SearchBar } = Search;

const ENTITY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Survey`;



type IProps = {}

interface IInvitationData {
  email: string,
  wizardId: string, 
  recordId: string, 
  regardingObjectTypeId: string
}

interface Invitee {
  email: string,
  questionnaire: string,
  state: string
}

interface IWizardIdentifiers {
  wizardId: string, 
  recordId: string, 
  regardingObjectTypeId: string
}

const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

export default function SurveyInvite() {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [emailValidation, setEmailValidation] = useState('')
  const [loadingInvitees, setLoadingInvitees] = useState(true)
  const [invitees, setInvitees] = useState<Invitee[]>([])
  const [inviteModal, setInviteModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [reInviteModal, setReInviteModal] = useState(false)
  const [deleteEmail, setDeleteEmail] = useState('')
  const [reInviteEmail, setReInviteEmail] = useState('')

  const pathValues = history.location.pathname.split('/').filter(Boolean);
  const wizardId = pathValues[2];
  const recordId = pathValues[3];
  const regardingObjectTypeId = pathValues[4];

  const columns = [{
    dataField: 'email',
    text: 'Email'
  }, {
    dataField: 'questionnaire',
    text: 'Identifier'
  }, {
    dataField: 'state',
    text: 'State'
  }, {
    dataField: 'actions',
    text: 'Actions',
    formatter: (cell: any, row: any) => {
      return (
        <div className="actions">
          <NavLink onClick={() => startReInviteModal(row.email)} >
            <Tippy placement="top" content={<TranslatableText translationKey="questionnaire.reinvite_to_answer" />}>
              <span>
                <FontAwesomeIcon icon={faEnvelope} size="1x" />
              </span>
            </Tippy>
          </NavLink>
          <NavLink onClick={() => startDeleteModal(row.email)} >
          <Tippy placement="top" content={<TranslatableText translationKey="questionnaire.delete_invitation" />}>
              <span>
                <FontAwesomeIcon
                  style={{ color: "#d84a4a" }}
                  icon={faTrash}
                  size="1x"
                />
              </span>
            </Tippy>
          </NavLink>
        </div>
      );
    }
  }];

  useEffect(() => {
    getInvitees({wizardId, recordId, regardingObjectTypeId})
      .then(res => {
        setLoadingInvitees(false)
        setInvitees(res)
      });
  }, [])

  const handleInvite = async () => {
    if (email && email.indexOf('@') < 0) {
      setEmailValidation('questionnaire.email_invalid');
      return;
    }
    else if (email && email.indexOf('.') < 0) {
      setEmailValidation('questionnaire.email_invalid');
      return;
    }
    else if (!email) {
      setEmailValidation('questionnaire.email_invalid');
      return;
    }
    else {
      setEmailValidation('');
    }

    setInviteModal(false);

    await inviteToSurvey({email, wizardId, recordId, regardingObjectTypeId});
    const invitees = await getInvitees({wizardId, recordId, regardingObjectTypeId});

    setInvitees(invitees);

    setEmail('');
  }

  const handleResendInvite = async () => {
    await inviteToSurvey({email: reInviteEmail, wizardId, recordId, regardingObjectTypeId});

    setReInviteModal(false);
  }

  const HandleDelete = async () => {
    const token = await authService.getAccessToken();
    var headers = {
      Authorization: `Bearer ${token}`
    };

    await axios.delete(`${ENTITY_API_URL}`, 
    { 
      headers: headers,
      params: { email: deleteEmail, wizardId, recordId, regardingObjectTypeId }
    })

    setDeleteModal(!deleteModal)

    const invitees = await getInvitees({wizardId, recordId, regardingObjectTypeId});

    setInvitees(invitees);
  };

  const startDeleteModal = async (mail: string) => {
    setDeleteModal(true)
    setDeleteEmail(mail)
  }

  const startReInviteModal = async (mail: string) => {
    setReInviteModal(true)
    setReInviteEmail(mail)
  }

  const getInvitees = async (data: IWizardIdentifiers): Promise<Invitee[]> => {
    const token = await authService.getAccessToken();
    var headers = {
      Authorization: `Bearer ${token}`
    };
    
    var res = await axios
      .post<any, AxiosResponse<Invitee[]>>(`${ENTITY_API_URL}/Invitees`, data, { headers: headers });
    return res.data;
  } 

  const inviteToSurvey = async (data: IInvitationData) => {
    const token = await authService.getAccessToken();
    var headers = {
      Authorization: `Bearer ${token}`
    };
    return axios
      .post(`${ENTITY_API_URL}/Invite`, data, { headers: headers })
      .then(res => res);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Accent useIcon={false}>
            <TranslatableText translationKey="questionnaire.invite_to_answer" />
          </Accent>
        </Col>
      </Row>
      <Row>
      <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={invitees}
            columns={columns}
            search
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                        <Button
                          className="button-primary"
                          onClick={() => setInviteModal(true)}
                        >
                          <TranslatableText translationKey="questionnaire.invite" />
                        </Button>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  keyField="i901289"
                  noDataIndication={() =>
                    loadingInvitees ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  data={invitees}
                  columns={columns}
                  // @ts-ignore
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </Row>
      <Modal
        isOpen={inviteModal}
        toggle={() => setInviteModal(!inviteModal)}
      >
        <ModalHeader toggle={() => setInviteModal(!inviteModal)}>
          <TranslatableText translationKey="questionnaire.invite" />
        </ModalHeader>
        <ModalBody>
          <Row form>
            <Input
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />
            <span className="help-block">
              <TranslatableText translationKey={emailValidation} />
            </span>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
              outline
              className="greenOutline"
              onClick={() => handleInvite()}
            >
              <TranslatableText translationKey="questionnaire.invite" />
            </Button>
          <Button outline onClick={() => setInviteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
      >
        <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>
          <TranslatableText translationKey="questionnaire.confirm_delete" />
        </ModalHeader>
        <ModalBody>
          <Row form>
            <Col md={12}>
              <p>
                <TranslatableText translationKey="questionnaire.confirm_delete_text" />
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
              outline
              className="redOutline"
              onClick={() => HandleDelete()}
            >
              <TranslatableText translationKey="delete" />
            </Button>
          <Button outline onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={reInviteModal}
        toggle={() => setReInviteModal(!reInviteModal)}
      >
        <ModalHeader toggle={() => setReInviteModal(!reInviteModal)}>
          <TranslatableText translationKey="questionnaire.resend_invite" />
        </ModalHeader>
        <ModalBody>
          <Row form>
            <Col md={12}>
              <p>
                <TranslatableText translationKey="questionnaire.resend_invite_text" />
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
              outline
              className="greenOutline"
              onClick={async () => await handleResendInvite()}
            >
              <TranslatableText translationKey="questionnaire.resend" />
            </Button>
          <Button outline onClick={() => setReInviteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}