import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Button, Row, Col, NavLink } from "reactstrap";
import {
  getSectionByIdAPI,
  getSectionTranslationsAPI,
  deleteCompanySectionTranslationByIdAPI,
  translateSectionAPI
} from "./companySectionsAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "react-toastify";
import TranslatableText from "../../../../Language/TranslatableText";
import Accent from "../../../../components/accent/Accent";
import Gap from "../../../../components/gap/Gap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import { Config } from "../../../../config/Config";
import Swal from "sweetalert2";
import { InlineLoading } from "../../../../components";
import { GlobalContext } from "../../../../contexts/global-context";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class CompanySectionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionDetails: {},
      customFields: [],
      translations: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "nameShort",
          text: "Name",
          sort: true
        },
        {
          dataField: "descriptionShort",
          text: "Description",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          formatter: (cell, row) => {
            let language = this.searchArray(cell, Config.languages, "value");
            if (language === undefined) {
              language = "dk";
            }
            return language.name;
          },
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/company-admin/sections/translations/update/${this.props.match.params.id}/${row.id}`}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
                <NavLink onClick={e => this.deleteRecord(row.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
          }
        }
      ],
      tableLoader: false
    };
  }
  componentDidMount() {
    this.populateSectionTranslations();
    getSectionByIdAPI(this.props.match.params.id)
      .then(res => {
        this.setState({
          sectionDetails: res.data,
          customFields:
            res.data.customFieldValues === null
              ? []
              : res.data.customFieldValues
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  }

  arrayFormatter = fields => {
    if (fields !== null && fields !== "" && Array.isArray(fields)) {
      return fields.map((item, index) => (
        <span key={index}>
          {item.label} {index < fields.length - 1 ? ",\u00A0" : ""}
        </span>
      ));
    }
  };

  renderTableData = customField => {
    if (customField.displayTypeCode === 1007)
      if (customField.tagBoxValues.length > 0) {
        return customField.tagBoxValues.map((field, index) => (
          <tr key={index}>
            <td>{field.label}</td>
            <td>{field.description}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan="2">
              <TranslatableText translationKey="no_data_available" />
            </td>
          </tr>
        );
      }
  };
  renderTableHeader = () => {
    return (
      <tr className="thead">
        <th style={{width: '30%'}}>
          <TranslatableText translationKey="name" />
        </th>
        <th style={{width: '70%'}}>
          <TranslatableText translationKey="description" />
        </th>
      </tr>
    );
  };

  populateSectionTranslations = () => {
    this.setState({ translations: [], tableLoader: true });
    getSectionTranslationsAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res.data)) {
          console.log("debug: ", res.data);
          return;
        }
        if (res.data.length == Config.languages.length) {
          this.setState({ isDisabled: true });
        } else {
          this.setState({ isDisabled: false });
        }
        this.setState({ translations: res.data, tableLoader: false });
      })
      .catch(error => {
        this.setState({ tableLoader: false });
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  deleteRecord = id => {
    Swal.fire({
      title: this.context.languages["swal.are_you_sure"],
      text: this.context.languages["swal.are_you_sure.delete_record"],
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.context.languages[
        "swal.are_you_sure.confirmButtonText"
      ],
      cancelButtonText: this.context.languages[
        "swal.are_you_sure.cancelButtonText"
      ]
    }).then(result => {
      if (result.value) {
        deleteCompanySectionTranslationByIdAPI(id).then(res => {
          this.populateSectionTranslations();
          Swal.fire(
            this.context.languages["swal.deleted"],
            this.context.languages["swal.deleted_record_deleted"],
            "success"
          );
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.setState(
          {
            tableLoader: false
          },
          () => {
            Swal.fire(
              this.context.languages["swal.cancelled"],
              this.context.languages["swal.cancelled_your_record_is_safe"],
              "error"
            );
          }
        );
      }
    });
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  translateSection = id => {
    let data = {
      id: this.state.sectionId
    };
    this.setState({ loading: true });
    translateSectionAPI(data)
      .then(res => {
        this.setState({ loading: false }, () => {
          console.log(res);
          this.populateWizardList();
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let {
      sectionDetails,
      translations,
      columns,
      isDisabled,
      tableLoader
    } = this.state;
    return (
      <div className="process-details-view">
        <div className="relative">
          <NavLink
            className="absolute right edit"
            tag={Link}
            to={`/company-admin/sections/update/${this.props.match.params.id}`}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </NavLink>
        </div>
        <Row form>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="section_details" />
            </Accent>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                {JSON.stringify(sectionDetails) === JSON.stringify({}) ? (
                  <InlineLoading />
                ) : (
                  <>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <TranslatableText translationKey="name" />
                          </td>
                          <td>{this.state.sectionDetails.name}</td>
                        </tr>

                        {this.state.customFields
                          .filter(item => {
                            return item.displayTypeCode !== 1007;
                          })
                          .map((element, key) => (
                            <tr key={key}>
                              <td>{element.displayText}</td>
                              <td>
                                {element.value === true
                                  ? "Yes"
                                  : element.value === false
                                  ? "No"
                                  : element.displayTypeCode === 1007
                                  ? this.arrayFormatter(element.tagBoxValues)
                                  : element.value}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <Gap size="50px" />
                    <div className="custom-fields-section">
                      {this.state.customFields.map((customField, index) =>
                        customField.displayTypeCode === 1007 ? (
                          <div key={index}>
                            <Accent useHtmlIcon={false}>
                              {customField.displayText}
                            </Accent>
                            <table className="table table-bordered ">
                              <thead>{this.renderTableHeader()}</thead>
                              <tbody>{this.renderTableData(customField)}</tbody>
                            </table>
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <Accent useIcon={false}>
                  <TranslatableText translationKey="translations" />
                </Accent>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <div className="table-container">
                  <ToolkitProvider
                    keyField="id"
                    data={translations}
                    columns={columns}
                    search
                    exportCSV={{
                      fileName: "data.csv",
                      separator: this.context.user.language === 'dk' ? ';' : ',',
                      ignoreHeader: false,
                      noAutoBOM: false
                    }}
                  >
                    {props => (
                      <div>
                        <div className="flex space-between">
                          <div>
                            <SearchBar {...props.searchProps} />
                          </div>
                          <div>
                            <div className="flex space-between">
                              {this.context.userRoles.find(r =>
                                r.includes("Developer")
                              ) !== undefined ? (
                                <ExportCSVButton
                                  className="export-btn"
                                  {...props.csvProps}
                                >
                                  CSV
                                </ExportCSVButton>
                              ) : (
                                <></>
                              )}
                              <div>
                                <NavLink
                                  className="button-primary"
                                  tag={Link}
                                  to={`/company-admin/sections/translations/add/${this.props.match.params.id}`}
                                  disabled={isDisabled ? true : null}
                                >
                                  <TranslatableText translationKey="answeroptionset.add_translation" />
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <BootstrapTable
                          noDataIndication={() =>
                            tableLoader ? (
                              <InlineLoading />
                            ) : (
                              <NoDataIndication />
                            )
                          }
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="space-between flex" md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back_to_list" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(CompanySectionDetails);
CompanySectionDetails.contextType = GlobalContext;