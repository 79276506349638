import { faBooks, faBuilding, faClipboard, faCrosshairs, faFileAlt, faShieldCheck, faTachometerAlt } from "@fortawesome/pro-solid-svg-icons";
import { NavMenuLink } from "../NavMenuLink";

export function CISOAdvisorNavigation(menuItemClick: () => void) {
  return (
    <>
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faTachometerAlt}
        path='/'
        translationKey='dashboard'
      />
      {/* <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faBook}
        path='/processes'
        translationKey='ciso.processes'
      /> */}
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faBuilding}
        path='/entity/d339039c-e682-41cb-82c6-08d9cf861623'
        translationKey='ciso.processes'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faBooks}
        path='/entity/99a987d7-f819-4c55-de50-08d824c34b31'
        translationKey='assets'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faClipboard}
        path='/entity/eb6f3473-cdf2-4f12-cce6-08d98e34685f'
        translationKey='stakeholders'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faShieldCheck}
        path='/entity/ed8fc770-0f25-481d-cce5-08d98e34685f'
        translationKey='controls'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faShieldCheck}
        path='/entity/797dec3b-529d-472a-cce3-08d98e34685f'
        translationKey='vulnerabilities'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faShieldCheck}
        path='/entity/be30544d-f13c-44c1-cce4-08d98e34685f'
        translationKey='threats'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faCrosshairs}
        path='/platform/riskAssesment'
        translationKey='dpo.risk.Header'
      />
      {/* <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faCalendarCheck}
        path='/annual-cycle-of-work'
        translationKey='annualCycleOfWork'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faTasks}
        path='/tasks'
        translationKey='tasks'
      /> */}
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faFileAlt}
        path='/documents'
        translationKey='documents'
      />
    </>
  );
}
