import { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import CreatePartnerProjectUser from "./CreatePartnerProjectUser";
import CreatePartnerUser from "./CreatePartnerUser";
import PartnerUserDetails from "./PartnerUserDetails";

class PartnerUserRouter extends Component {
  render() {
    return (
      <div className="admin-process-view">
        <Switch>
          <Route
            path={`/partner/user/create/:partnerId`}
            render={props => (
              <CreatePartnerUser {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/partner/user/project/create/:partnerId`}
            render={props => (
              <CreatePartnerProjectUser {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/partner/user/details/:partnerId/:id`}
            render={props => (
              <PartnerUserDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/partner/user/update/:partnerId/:id`}
            render={props => (
              <CreatePartnerUser {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(PartnerUserRouter);