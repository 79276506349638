import React, { Component } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faHatWizard,
  faInfoCircle,
  faFileCode,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { LanguageContext } from "../../../../contexts/language-context";
import TranslatableText from "../../../../Language/TranslatableText";
import Accent from "../../../../components/accent/Accent";
import {
  getWizardsAPI,
  deleteWizardByIdAPI,
  duplicateWizardAPI,
  translateWizardAPI,
  getJsonSpecForWizard
} from "./wizardAPI";
import { toast } from "react-toastify";
import { faClone, faGlobe } from "@fortawesome/pro-regular-svg-icons";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../components";



const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);
const defaultSorted = [
  {
    dataField: "order", // if dataField is not match to any column you defined, it will be ignored.
    order: "asc" // desc or asc
  }
];

class Wizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableLoader: false,
      wizards: [],
      wizardId: null,
      deleteModal: false,
      deleteLoader: false,
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "name",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "order",
          text: "Order",
          sort: true
        },
        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                {this.props.match.params.organizationId === undefined ? (
                  <div>
                    <NavLink
                      tag={Link}
                      to={`${this.props.match.path}/details/${row.id}`}
                    >
                      <Tippy placement="top" content="View Details">
                        <span>
                          <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                        </span>
                      </Tippy>
                    </NavLink>
                    <NavLink onClick={e => this.copyWizard(row.id, e)}>
                      <Tippy placement="top" content="Copy Wizard">
                        <span>
                          <FontAwesomeIcon
                            style={{ color: "var(--primary)" }}
                            icon={faClone}
                            size="1x"
                          />
                        </span>
                      </Tippy>
                    </NavLink>
                    <NavLink onClick={e => this.translateWizard(row.id, e)}>
                      <Tippy placement="top" content="Translate Wizard">
                        <span>
                          <FontAwesomeIcon
                            style={{ color: "var(--primary)" }}
                            icon={faGlobe}
                            size="1x"
                          />
                        </span>
                      </Tippy>
                    </NavLink>
                    <NavLink
                      tag={Link}
                      to={`${this.props.match.path}/update/${row.id}`}
                    >
                      <Tippy placement="top" content="Edit">
                        <span>
                          <FontAwesomeIcon icon={faEdit} size="1x" />
                        </span>
                      </Tippy>
                    </NavLink>
                      <NavLink onClick={e => this.getWizardJson(row.id, e)}>
                        <Tippy placement="top" content="JSON Spec.">
                          <span>
                            <FontAwesomeIcon icon={faFileCode} />
                          </span>
                        </Tippy>
                      </NavLink>
                    <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                      <Tippy placement="top" content="Remove">
                        <span>
                          <FontAwesomeIcon
                            style={{ color: "#d84a4a" }}
                            icon={faTrash}
                            size="1x"
                          />
                        </span>
                      </Tippy>
                    </NavLink>
                  </div>
                ) : (
                  <NavLink
                    tag={Link}
                    to={`/company-admin/wizard/details/${row.id}/${this.props.match.params.organizationId}`}
                  >
                    <Tippy placement="top" content="View Details">
                      <span>
                        <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                      </span>
                    </Tippy>
                  </NavLink>
                )}
              </div>
            );
          }
        }
      ]
    };
  }

  componentDidMount() {
    this.populateWizardList();
  }

  populateWizardList = () => {
    this.setState({ tableLoader: true, wizards: [] });

    getWizardsAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({
          wizards: res,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  copyWizard = id => {
    let data = {
      id: id
    };
    this.setState({ loading: true });
    duplicateWizardAPI(data)
      .then(res => {
        this.setState({ loading: false }, () => {
          this.populateWizardList();
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };
  translateWizard = id => {
    let data = {
      id: id
    };
    this.setState({ loading: true });
    translateWizardAPI(data)
      .then(res => {
        this.setState({ loading: false }, () => {
          console.log(res);
          this.populateWizardList();
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            wizardId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      wizardId: id
    });
  };

  deleteWizard = id => {
    this.setState({ deleteLoader: true });
    deleteWizardByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populateWizardList();
        this.setState({
          deleteModal: false,
          wizardId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          wizardId: null,
          deleteLoader: false
        });
      });
  };

  getWizardJson = id => {
    this.setState({ loading: true });
    getJsonSpecForWizard(id)
      .then(res => {
        this.setState({
          loading: false
        });
      })
      .catch(error => {
        if (error.response.status === 403) {
          toast.error(this.context.languages["insufficient_permission"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          });
        } else {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          });
        }
        this.setState({ loading: false });
      });
  };

  headerFormatter = column => {
    return <TranslatableText translationKey={column.text} />;
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { tableLoader, loading } = this.state;
    return (
      <div className="admin-partners-view">
        {loading && (
          <ScreenLoading />
        )}
        <Accent useIcon={true} icon={faHatWizard}>
          Wizard
        </Accent>
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={this.state.wizards}
            columns={this.state.columns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                      <div>
                        {this.props.match.params.organizationId ===
                        undefined ? (
                          <NavLink
                            className="button-primary"
                            tag={Link}
                            to={`${this.props.match.path}/create`}
                          >
                            <TranslatableText translationKey="create" />
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              Confirm Delete?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="confirm_delete_wizard" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deleteWizard(this.state.wizardId, e)}
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(Wizard);
Wizard.contextType = GlobalContext;