import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faUnlink } from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { LanguageContext } from "../../../../contexts/language-context";
import {
  getTeamByIdAPI,
  teamUserAssociationAPI,
  getUsersByTeamIdAPI,
  getUsersByOrgAndTeamIdAPI,
  deleteTeamUserAssociationAPI
} from "./teamsAPI";
import Accent from "../../../../components/accent/Accent";
import Gap from "../../../../components/gap/Gap";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { getUsersAPI } from "../users/usersAPI";
import { getUsersByPartnerIdAPI } from "../../../partner/views/users/partnerUsersAPI";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";


const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class TeamDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      name: "",
      usersDropdown: [],
      searchableUsersDropdown: [],
      userId: "",
      userModal: false,
      usersData: [],
      usersColumns: [
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions"
        }
      ],
      usersTableLoader: false,
      deleteModal: false,
      deleteLoader: false,
      userRemovalId: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getTeamByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.name
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
      this.populateUsersTable((this.props.match.params.organizationId ?? this.context.user.organizations.organizationId), this.props.match.params.id);
    }
  }

  search = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === key) {
        return array[i];
      }
    }
  };

  populateUsersTable = (orgId, id) => {

    this.setState({ usersTableLoader: true, usersData: [] });
    getUsersByOrgAndTeamIdAPI(orgId, id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }

        var self = this;
        var claims = res;
        var claimsFilteredArray = [];
        if (this.props.match.url.split("/")[1] === "partner") {
          getUsersByPartnerIdAPI(this.props.match.params.organizationId)
            .then(response => {
              this.setState(
                {
                  searchableUsersDropdown: response
                },
                () => {
                  claimsFilteredArray = self.state.searchableUsersDropdown.filter(
                    el => {
                      if (self.search(el.userId, claims)) {
                        return false; // skip
                      }
                      return true;
                    }
                  );
                  this.setState({ usersDropdown: claimsFilteredArray });
                }
              );
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
            });
        } else {
          getUsersAPI()
            .then(response => {
              this.setState(
                {
                  searchableUsersDropdown: response
                },
                () => {
                  claimsFilteredArray = self.state.searchableUsersDropdown.filter(
                    el => {
                      if (self.search(el.id, claims)) {
                        return false; // skip
                      }
                      return true;
                    }
                  );
                  this.setState({ usersDropdown: claimsFilteredArray });
                }
              );
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
            });
        }

        var data = res
          .filter(function (el) {
            if (el.name === "") {
              return false; // skip
            }
            return true;
          })
          .map(function (el) {
            var o = Object.assign({}, el);
            o.actions = (
              <div className="actions">
                <Tippy placement="right" content="Remove">
                  <span>
                    <FontAwesomeIcon
                      className="app-icon"
                      icon={faUnlink}
                      size="1x"
                      style={{ color: "red" }}
                      onClick={e => self.openDeleteModal(el.id, e)}
                    />
                  </span>
                </Tippy>
              </div>
            );
            return o;
          });

        this.setState({
          usersData: data,
          usersTableLoader: false,
          loading: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false, usersTableLoader: false });
      });
  };

  addUser = e => {
    e.preventDefault();
    if (
      this.state.userId === "" ||
      this.state.userId === undefined ||
      this.state.userId === null
    ) {
      return false;
    }
    this.setState({ loading: true });
    const data = {
      teamId: this.props.match.params.id,
      userId: this.state.userId,
      organizationId:
        this.props.match.params.organizationId === undefined
          ? this.context.user.organizations.organizationId
          : this.props.match.params.organizationId
    };
    teamUserAssociationAPI(data)
      .then(res => {
        this.populateUsersTable((this.props.match.params.organizationId ?? this.context.user.organizations.organizationId), this.props.match.params.id);
        this.setState({ userId: "" });
        this.toggleClaimModal();
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false, userId: "" });
        this.toggleClaimModal();
      });
  };

  toggleClaimModal = () => {
    this.setState(prevState => ({
      userModal: !prevState.userModal
    }));
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            userRemovalId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      userRemovalId: id
    });
  };

  deleteUser = id => {
    var associationIds = {
      teamId: this.props.match.params.id,
      userId: id,
      organizationId:
        this.props.match.params.organizationId === undefined
          ? this.context.user.organizations.organizationId
          : this.props.match.params.organizationId
    };
    this.setState({ deleteLoader: true });
    deleteTeamUserAssociationAPI(associationIds)
      .then(res => {
        this.populateUsersTable((this.props.match.params.organizationId ?? this.context.user.organizations.organizationId), this.props.match.params.id);
        this.setState({
          deleteModal: false,
          deleteLoader: false,
          userRemovalId: null
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          deleteLoader: false,
          userRemovalId: null
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { usersData, usersColumns, usersTableLoader } = this.state;
    return (
      <div className="user-details-view">
        <section>
          <Row>
            <Col md={12}>
              {this.state.name !== "" ? (
                <Accent useIcon={false}>
                  <TranslatableText translationKey="users_for_the_team" /> "
                  {this.state.name}"
                </Accent>
              ) : (
                <Accent useIcon={false}>
                  <InlineLoading />
                </Accent>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="flex flex-end">
                <div>
                  <Button outline onClick={this.toggleClaimModal}>
                    <FontAwesomeIcon icon={faLink} size="1x" /> Add
                  </Button>
                </div>
              </div>
              <Gap size="10px" />
              <div className="table-container">
                <ToolkitProvider
                  keyField="id"
                  data={usersData}
                  columns={usersColumns}
                >
                  {props => (
                    <div>
                      <BootstrapTable
                        noDataIndication={() =>
                          usersTableLoader ? (
                            <InlineLoading />
                          ) : (
                            <NoDataIndication />
                          )
                        }
                        {...props.baseProps}
                        pagination={paginationFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        </section>

        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <div className="modals">
          <Modal
            isOpen={this.state.userModal}
            toggle={this.toggleClaimModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleClaimModal}>
              <TranslatableText translationKey="claims" />
            </ModalHeader>
            <ModalBody>
              <Form>
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    {this.props.match.url.split("/")[1] === "partner" ? (
                      <FormGroup>
                        <Label for="userId">
                          <TranslatableText translationKey="teams.add_user" />
                        </Label>
                        <Input
                          type="select"
                          name="userId"
                          id="userId"
                          value={this.state.userId}
                          onChange={this.handleChange}
                        >
                          <option value="">-- Vælg --</option>
                          {this.state.usersDropdown.map((e, key) => {
                            return (
                              <option key={e.id} value={e.userId}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <Label for="userId">
                          <TranslatableText translationKey="teams.add_user" />
                        </Label>
                        <Input
                          type="select"
                          name="userId"
                          id="userId"
                          value={this.state.userId}
                          onChange={this.handleChange}
                        >
                          <option value="">-- Vælg --</option>
                          {this.state.usersDropdown.map((e, key) => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <Row form className="flex flex-end">
                  <Button className="mr-10" outline onClick={this.addUser}>
                    <TranslatableText translationKey="add" />
                  </Button>
                  <Button outline onClick={this.toggleClaimModal}>
                    <TranslatableText translationKey="cancel" />
                  </Button>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              <TranslatableText translationKey="confirm_delete" />?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="teams.confirm_delete_user_description" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deleteUser(this.state.userRemovalId, e)}
                >
                  <TranslatableText translationKey="remove" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(TeamDetails);
TeamDetails.contextType = GlobalContext;