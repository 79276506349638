import axios from "axios";
import { Config } from "../../config/Config";
import authService from "../../components/api-authorization/AuthorizeService";

// Dashboard API URL
const DASHBOARD_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Dashboards`;

// Get by organization ID
export const getDashboardDataByIdAPI = async language => {
  const token = await authService.getAccessToken();
  var headers = {
    Language: language,
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${DASHBOARD_API_URL}/GetAllSectionsProcesses`, { headers: headers })
    .then(({ data }) => data);
};

// Get Processes
export const getProcessesByIdAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${DASHBOARD_API_URL}/GetDashboardProcesses`, {
      headers: headers
    })
    .then(({ data }) => data);
};
