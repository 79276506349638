import { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import CompanyAdmin from "./CompanyAdmin";
import CompanyClaimsRouter from "./views/claims/CompanyClaimsRouter";
import FirmRouter from "./views/company/FirmRouter";
import TenantCustomEntitiesRouter from "./views/custom-entities/TenantCustomEntitiesRouter";
import CFRouter from "./views/custom-fields/CFRouter";
import FlowEngineRouter from "./views/flow-engine/FlowEngineRouter";
import PersonsRouter from "./views/persons/PersonsRouter";
import CompanyProcessRouter from "./views/process/CompanyProcessRouter";
import CompanyRolesRouter from "./views/roles/CompanyRolesRouter";
import CompanySectionsRouter from "./views/sections/CompanySectionsRouter";
import DynamicTree from "./views/sharepoint/Tree";
import TeamsRouter from "./views/teams/TeamsRouter";
import UsersRouter from "./views/users/UsersRouter";
import CompanyWizardRouter from "./views/wizard/CompanyWizardRouter";

class CompanyAdminRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-admin-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/wizard/:organizationId`}
            render={props => <CompanyWizardRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/users`}
            render={props => <UsersRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/persons`}
            render={props => <PersonsRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/company`}
            render={props => <FirmRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/process`}
            render={props => <CompanyProcessRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/sections`}
            render={props => <CompanySectionsRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/claims`}
            render={props => <CompanyClaimsRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/roles`}
            render={props => <CompanyRolesRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/teams`}
            render={props => <TeamsRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/custom-fields`}
            render={props => <CFRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/custom-entities`}
            render={props => <TenantCustomEntitiesRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/flow-engine`}
            render={props => <FlowEngineRouter {...props} />}
          />
          <Route
            path={`${this.props.match.path}/sharepoint`}
            render={props => <DynamicTree {...props}  showCompanyInformation={true} />}
          />
          <Route
            path="/company-admin"
            render={props => <CompanyAdmin {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(CompanyAdminRouter);