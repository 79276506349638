import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { Col, Row } from "reactstrap";

type DetailsProps = {
    icon: string
    entityName: string,
    approvalName: string,
    approvalDescription: string,
}

const Details: React.FC<DetailsProps> = (props) => {
    const { entityName, approvalName, approvalDescription, icon } = props;

    return (
        <>
            <Row className="text-center">
                <Col>
                    <h2>{icon && <FontAwesomeIcon className="mr-1"
                        // @ts-ignore
                        icon={icon} size="1x" />} {entityName}</h2>
                    <h3>{approvalName}</h3>
                    <h4>{approvalDescription}</h4>
                </Col>
            </Row>
        </>
    )
}

export default Details;