import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TranslatableText from '../../../Language';
import { Link } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type NavMenuLinkProps = {
    menuItemClick: () => void;
    icon: IconDefinition;
    path: string;
    translationKey: string;
};
export function NavMenuLink(props: NavMenuLinkProps) {
    return <NavItem>
        <NavLink
            onClick={() => props.menuItemClick()}
            tag={Link}
            className="text-light"
            to={props.path}
        >
            <FontAwesomeIcon icon={props.icon} />&nbsp;
            <TranslatableText translationKey={props.translationKey} />
        </NavLink>
    </NavItem>;
}
