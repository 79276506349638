import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import CompanySections from "./CompanySections";
import CreateCompanySection from "./CreateCompanySection";
import CompanyTranslations from "./CompanyTranslations";
import AddCompanyTranslation from "./AddCompanyTranslation";
import Attachments from "../../../attachments/Attachments";
import { withRouter } from "react-router-dom";
import CompanySectionDetails from "./CompanySectionDetails";

class CompanySectionsRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-company-section-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreateCompanySection
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/create`}
            render={props => (
              <CreateCompanySection
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/add/:sectionId`}
            render={props => (
              <AddCompanyTranslation
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/update/:sectionId/:translationId`}
            render={props => (
              <AddCompanyTranslation
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/:id`}
            render={props => (
              <CompanyTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/attachments/:id`}
            render={props => (
              <Attachments {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <CompanySectionDetails
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path="/company-admin/sections"
            render={props => (
              <CompanySections {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(CompanySectionsRouter);
