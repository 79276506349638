import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TranslatableText from "../../../../Language/TranslatableText";
import {
  faArrowAltCircleLeft,
  faNewspaper,
  faEdit,
  faTrash,
  faCloudDownloadAlt,
  faInfoCircle
} from "@fortawesome/pro-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { getProjectByIdAPI } from "./projectsAPI";
import Gap from "../../../../components/gap/Gap";
import Accent from "../../../../components/accent/Accent";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import {
  getReportTemplatesOfCompanyByIdAPI,
  deleteReportTemplateByIdAPI
} from "../wizard/wizardAPI";
import { toast } from "react-toastify";
import {
  faBoxFull,
  faChartNetwork,
  faUsers
} from "@fortawesome/pro-regular-svg-icons";
import { getTenantCustomEntitiesByOrganizationIdAPI } from "../../../company-admin/views/custom-entities/tenantCustomEntitiesAPI";
import { getUsersByPartnerIdAPI } from "../../../partner/views/users/partnerUsersAPI";
import { getTeamsAPI } from "../../../company-admin/views/teams/teamsAPI";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading, ScreenLoading } from "../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerDetails: {},
      loading: false,
      tableLoader: false,
      teams: [],
      teamColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "email",
          text: "Email",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "description",
          text: "description",
          headerFormatter: this.headerFormatter,
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/${this.props.match.url.split("/")[1]
                    }/company/teams/details/${row.id}/${this.props.match.params.id
                    }`}
                >
                  <Tippy placement="top" content="View Details">
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>

                <NavLink
                  tag={Link}
                  to={`/${this.props.match.url.split("/")[1]
                    }/company/teams/update/${row.id}/${this.props.match.params.id
                    }`}
                >
                  <Tippy placement="top" content="Edit">
                    <span>
                      <FontAwesomeIcon icon={faEdit} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                  <Tippy placement="top" content="Remove">
                    <span>
                      <FontAwesomeIcon
                        style={{ color: "#d84a4a" }}
                        icon={faTrash}
                        size="1x"
                      />
                    </span>
                  </Tippy>
                </NavLink>
              </div>
            );
          }
        }
      ],
      users: [],
      userColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name", //res[0].systems.map(row => row.name)
          text: "Name",
          sort: true
        },
        {
          dataField: "phone",
          text: "Phone ",
          sort: true
        },
        {
          dataField: "email",
          text: "Email ",
          sort: true
        }
      ],
      templates: [],
      templateColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "description",
          text: "Description",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ],
      entities: [],
      entitiesColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
          formatter: (cell, row) => {
            return (
              <>
                {row.platormEntity ? (
                  <Tippy placement="top" content="Platform Module">
                    <div className="inline-block">
                      <span className="mr-10">
                        <FontAwesomeIcon icon={faChartNetwork} />
                      </span>
                      <span>{row.name}</span>
                    </div>
                  </Tippy>
                ) : (
                  <div>
                    <span className="width-20px mr-10"></span>
                    <span>{row.name}</span>
                  </div>
                )}
              </>
            );
          }
        },
        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/${this.props.match.url.split("/")[1]
                    }/company/project/module/details/${row.id}/${this.props.match.params.id
                    }`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
                {!row.platormEntity ? (
                  <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </NavLink>
                ) : (
                  ""
                )}
              </div>
            );
          }
        }
      ],
      deleteLoader: false,
      deleteTemplateModal: false,
      reportTemplateId: null
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    console.log("id", this.props.match.params.id);
    this.setState({ loading: true });
    getProjectByIdAPI(this.props.match.params.id)
      .then(res => {
        this.setState({ partnerDetails: res.data, loading: false }, () => { });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
    this.populateReportTemplates();
    this.populateProjectUsers();
    this.populateTenantCustomEntities();
    this.populateTeams();
  }

  populateTeams = () => {
    this.setState({
      tableLoader: true,
      teams: []
    });
    getTeamsAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({
          teams: res,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  populateProjectUsers = () => {
    this.setState({ tableLoader: true, users: [] });
    getUsersByPartnerIdAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ users: res, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  populateTenantCustomEntities = () => {
    this.setState({ tableLoader: true, entities: [] });
    getTenantCustomEntitiesByOrganizationIdAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ entities: res, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  populateReportTemplates = () => {
    var self = this;
    this.setState({ tableLoader: true, templates: [] });
    getReportTemplatesOfCompanyByIdAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink>
                <Tippy placement="top" content="Download Template">
                  <span>
                    <FontAwesomeIcon icon={faCloudDownloadAlt} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink
                tag={Link}
                to={`/${self.props.match.url.split("/")[1]
                  }/company/project/template/update/${self.props.match.params.id
                  }/${el.id}`}
              >
                <Tippy placement="top" content="Update Template">
                  <span>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink onClick={e => self.openDeleteTemplateModal(el.id, e)}>
                <Tippy placement="top" content="Delete Template">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
          return o;
        });
        this.setState({
          templates: data,
          language: this.context.language,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeleteTemplatePopup = () => {
    this.setState(
      prevState => ({
        deleteTemplateModal: !prevState.deleteTemplateModal
      }),
      () => {
        if (this.state.deleteTemplateModal === false) {
          this.setState({
            reportTemplateId: null
          });
          console.log("After Modal Dismissal", this.state.deleteTemplateModal);
        }
      }
    );
  };

  openDeleteTemplateModal = id => {
    this.setState({
      deleteTemplateModal: true,
      reportTemplateId: id
    });
  };

  deleteReportTemplate = id => {
    this.setState({ deleteLoader: true });
    deleteReportTemplateByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populateReportTemplates();
        this.setState({
          deleteTemplateModal: false,
          deleteLoader: false,
          reportTemplateId: null
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteTemplateModal: false,
          deleteLoader: false,
          reportTemplateId: null
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let {
      loading,
      tableLoader,
      users,
      userColumns,
      teamColumns,
      teams
    } = this.state;
    return (
      <div className="company-details-view">
        {loading && (
          <ScreenLoading />
        )}
        <div className="relative flex flex-align space-between">
          <h1>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              size="1x"
              onClick={this.goBack}
              className="cursor"
            />{" "}
            Project Details
          </h1>
          <NavLink
            className="edit"
            tag={Link}
            to={`/${this.props.match.url.split("/")[1]
              }/company/project/update/${this.props.match.params.id}/${this.props.match.params.organizationId
              }`}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </NavLink>
        </div>

        <Accent logo={this.state.partnerDetails.logo}>
          {this.state.partnerDetails.name}
        </Accent>
        <Gap size="50px" />
        <Row>
          <Col md={12}>
            <Table>
              <thead>
                <tr>
                  <th>Element</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(this.state.partnerDetails)
                  .filter(function (el) {
                    switch (el) {
                      case 'vat':
                      case 'address':
                      case 'city':
                      case 'invoiceNumber':
                      case 'licenseExpirationDate':
                        return true;
                        break;

                      default: return false
                        break;
                    }
                    return true;
                  })
                  .map(key => (
                    <tr key={key}>
                      <td className="text-capitalize">{key}</td>
                      <td>{this.state.partnerDetails[key]}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        {this.props.match.url.split("/")[1] === "partner" ? (
          <Row>
            <Col md={12}>
              <Accent useIcon={true} icon={faUsers}>
                Teams
              </Accent>
              <div className="table-container">
                <ToolkitProvider
                  keyField="id"
                  data={teams}
                  columns={teamColumns}
                  search
                  exportCSV={{
                    fileName: "data.csv",
                    separator: this.context.user.language === 'dk' ? ';' : ',',
                    ignoreHeader: false,
                    noAutoBOM: false
                  }}
                >
                  {props => (
                    <div>
                      <div className="flex space-between">
                        <div>
                          <SearchBar {...props.searchProps} />
                        </div>
                        <div>
                          <div className="flex space-between">
                            {this.context.userRoles.find(r =>
                              r.includes("Developer")
                            ) !== undefined ? (
                              <ExportCSVButton
                                className="export-btn"
                                {...props.csvProps}
                              >
                                CSV
                              </ExportCSVButton>
                            ) : (
                              <></>
                            )}
                            <div>
                              <NavLink
                                className="button-primary"
                                tag={Link}
                                to={`/${this.props.match.url.split("/")[1]
                                  }/company/teams/create/${this.props.match.params.id
                                  }`}
                              >
                                Create
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <BootstrapTable
                        noDataIndication={() =>
                          tableLoader ? (
                            <InlineLoading />
                          ) : (
                            <NoDataIndication />
                          )
                        }
                        {...props.baseProps}
                        pagination={paginationFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {this.props.match.url.split("/")[1] !== "company-admin" ? (
          <Row>
            <Col md={12}>
              <Accent useIcon={true} icon={faUsers}>
                Users
              </Accent>
              <div className="table-container">
                <ToolkitProvider
                  keyField="id"
                  data={users}
                  columns={userColumns}
                  search
                  exportCSV={{
                    fileName: "data.csv",
                    separator: this.context.user.language === 'dk' ? ';' : ',',
                    ignoreHeader: false,
                    noAutoBOM: false
                  }}
                >
                  {props => (
                    <div>
                      <div className="flex space-between">
                        <div>
                          <SearchBar {...props.searchProps} />
                        </div>
                        <div>
                          <div className="flex space-between">
                            {this.context.userRoles.find(r =>
                              r.includes("Developer")
                            ) !== undefined ? (
                              <ExportCSVButton
                                className="export-btn"
                                {...props.csvProps}
                              >
                                CSV
                              </ExportCSVButton>
                            ) : (
                              <></>
                            )}
                            <div>
                              <NavLink
                                className="button-primary"
                                tag={Link}
                                to={`/${this.props.match.url.split("/")[1]
                                  }/company/user/create/${this.props.match.params.id
                                  }`}
                              >
                                Create
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <BootstrapTable
                        noDataIndication={() =>
                          tableLoader ? (
                            <InlineLoading />
                          ) : (
                            <NoDataIndication />
                          )
                        }
                        {...props.baseProps}
                        pagination={paginationFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Row>
          <Col md={12}>
            <Accent useIcon={true} icon={faBoxFull}>
              Modules
            </Accent>
          </Col>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={this.state.entities}
                columns={this.state.entitiesColumns}
                search
                exportCSV={{
                  fileName: "custom.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              CSV
                            </ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/${this.props.match.url.split("/")[1]
                                }/company/project/module/create/${this.props.match.params.id
                                }`}
                            >
                              Create
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                      pagination={paginationFactory()}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accent useIcon={true} icon={faNewspaper}>
              Report Templates
            </Accent>
          </Col>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={this.state.templates}
                columns={this.state.templateColumns}
                search
                exportCSV={{
                  fileName: "custom.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              CSV
                            </ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/${this.props.match.url.split("/")[1]
                                }/company/project/template/create/${this.props.match.params.id
                                }`}
                            >
                              Create
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                      pagination={paginationFactory()}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteTemplateModal}
            toggle={this.toggleDeleteTemplatePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteTemplatePopup}>
              <TranslatableText translationKey="confirm_delete" />
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="company.confirm_delete_template" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e =>
                    this.deleteReportTemplate(this.state.reportTemplateId, e)
                  }
                >
                  Delete
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeleteTemplatePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(ProjectDetails);
ProjectDetails.contextType = GlobalContext;
