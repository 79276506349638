import { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { InlineLoading, ScreenLoading } from "../../../../components";
import Fields from "../../../../components/fields/Fields";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import { GlobalContext } from "../../../../contexts/global-context";
import TranslatableText from "../../../../Language/TranslatableText";
import { getPartnerCompaniesAPI } from "../../../admin/views/partners/partnersAPI";
import { getParnerProjectsAPI } from "../../../admin/views/projects/projectsAPI";
import {
  getUserDetailsByIdAPI,
  updatePlatformUserAPI
} from "../../../admin/views/users/usersAPI";
import { getEntityCustomFieldsAPI } from "../../../company-admin/views/custom-fields/customFieldsCompanyAPI";
import { getRolesAPI } from "../../../company-admin/views/roles/companyRolesAPI";
import { createUserAPI } from "./partnerUsersAPI";



// Global Variables
var customFieldsModel;

class CreatePartnerProjectUser extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
    ]);

    this.state = {
      name: "",
      phone: "",
      email: "",
      profileImage: "",
      organizationId: "",
      roleIds: [],
      postRoleIds: [],
      customFields: [],
      organizationType: "",
    //   organizationTypeDropdown: [
    //     // { label: "Company", value: "company" },
    //     // { label: "Partner", value: "partner" },
    //     { label: "Project", value: "project" }
    //   ],
      selectedEntity: {
        label: ""
      },
      entityDropdown: [],
      selectedOption: "",
      file: "",
      checkAttachment: 1,
      fileURL: "",
      loading: false,
      cuLoader: false,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    if (this.props.match.params.organizationId !== undefined) {
      this.setState({
        organizationType: "company",
        organizationId: this.props.match.params.organizationId,
        entity: this.props.match.params.organizationId
      });
    }
    getRolesAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var roles = [];
        res.forEach(function (row) {
          roles.push({ label: row.name, value: row.id });
        });
        this.setState({ roleIds: roles });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getUserDetailsByIdAPI(this.props.match.params.id)
        .then(res => {
          let self = this;

          this.setState(
            {
              name: res.data.name,
              phone: res.data.phone,
              email: res.data.email,
              entity: res.data.organizationId,
              profileImage:
                res.data.profileImage === null ||
                res.data.profileImage === undefined ||
                res.data.profileImage === ""
                  ? ""
                  : res.data.profileImage,
              file:
                res.data.profileImage === null ||
                res.data.profileImage === undefined ||
                res.data.profileImage === ""
                  ? ""
                  : this.dataURLtoFile(
                      res.data.profileImage,
                      res.data.profileImageName
                    ),
              organizationId: this.context.user.organizations.organizationId,
              customFields:
                res.data.customFieldValues === null ||
                res.data.customFieldValues === undefined
                  ? []
                  : res.data.customFieldValues,
              loading: false
            },
            () => {
              let userCustomFields =
                res.data.customFieldValues === null ||
                res.data.customFieldValues === undefined
                  ? []
                  : res.data.customFieldValues;
              userCustomFields.forEach(element => {
                if (element.displayTypeCode === 1007) {
                  self.setState({
                    [element.id]:
                      element.tagBoxValues === null ? "" : element.tagBoxValues
                  });
                } else {
                  self.setState({
                    [element.name]: element.value === null ? "" : element.value
                  });
                }
              });
            }
          );
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
      // To Bypass the organization & entity
      this.setState({
        entity: "0",
        organizationType: "0"
      });
    } else {
      this.populateCustomFields(1004);
    }
  }

  populateCustomFields = code => {
    this.setState({ loading: true, customFields: [] });
    getEntityCustomFieldsAPI(code)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ customFields: res, loading: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
  };

  createUser = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    let key,
      self = this;

    // Preparing custom fields model
    customFieldsModel = this.state.customFields;
    this.state.customFields.forEach((element, i) => {
      if (element.displayTypeCode === 1007) {
        key = element.id;
        customFieldsModel[i].value = JSON.stringify(
          Array.isArray(self.state[key])
            ? self.state[key].map(row => row.value)
            : []
        );
      } else {
        key = element.name;
        customFieldsModel[i].value = self.state[key];
      }
    });

    const data = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      organizationId: this.props.match.params.partnerId,
      customFieldValues: customFieldsModel,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : ""
    };

    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("customFieldValues", data.customFieldValues);
    formData.append("organizationId", data.organizationId);
    formData.append("file", this.state.file);
    formData.append("id", data.id);

    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updatePlatformUserAPI(formData)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createUserAPI(formData)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "organizationType") {
      this.setState({ selectedEntity: value });
      this.populateEntityDropdown(value);
    }

    if (target.type === "file") {
      const validImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml"
      ];

      if (target.files[0] !== undefined) {
        if (!validImageTypes.includes(target.files[0].type)) {
          alert("Only Images are allowed");
          target.value = "";
          return false;
        } else {
          if (target.files[0].size / 1024 <= 1000) {
            this.getBase64(target.files[0]);
          } else {
            alert("Max allowed size is 1000Kb");
            target.value = "";
            return false;
          }
        }
      }
      this.setState({ file: target.files[0] });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  populateEntityDropdown = entity => {
    if (entity === "company") {
      getPartnerCompaniesAPI(this.props.match.params.partnerId)
        .then(res => {
          if (!Array.isArray(res)) {
            console.log("debug: ", res);
            return;
          }
          const filteredCompanies = res.filter(item => {
            return item.isActive === true;
          });

          let companiesDropdown = [];
          res.forEach(function (row) {
            companiesDropdown.push({ label: row.name, value: row.id });
          });

          let filteredCompaniesDropdown = [];
          filteredCompanies.forEach(function (row) {
            filteredCompaniesDropdown.push({ label: row.name, value: row.id });
          });

          this.setState({
            entityDropdown: companiesDropdown,
            filteredCompanies: filteredCompaniesDropdown
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ tableLoader: false });
        });
    } else if (entity === "project") {
      getParnerProjectsAPI(this.props.match.params.partnerId)
        .then(res => {
          if (!Array.isArray(res)) {
            console.log("debug: ", res);
            return;
          }
          const filteredProjects = res.filter(item => {
            return item.isActive === true;
          });

          let projectsDropdown = [];
          res.forEach(function (row) {
            projectsDropdown.push({ label: row.name, value: row.id });
          });

          let filteredProjectsDropdown = [];
          filteredProjects.forEach(function (row) {
            filteredProjectsDropdown.push({ label: row.name, value: row.id });
          });

          this.setState({
            entityDropdown: projectsDropdown,
            filteredProjects: filteredProjectsDropdown
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
    } else {
      this.setState({ entityDropdown: [] });
    }
  };

  handleTagboxChange = (id, selectedOptions) => {
    this.setState({
      [id]: selectedOptions
    });
  };

  handleSingleTagboxChange = (state, selectedStateName, selectedOption) => {
    if (state === "organizationType") {
      this.setState({
        selectedOrganizationEntity: null,
        selectedEntity: selectedOption
      });
      this.populateEntityDropdown(selectedOption.value);
    }
    this.setState({
      [state]: selectedOption.value,
      [selectedStateName]: selectedOption === null ? "" : selectedOption
    });
  };

  handleSelectChange = selectedOption => {
    this.setState({
      postRoleIds:
        selectedOption === null || selectedOption === undefined
          ? []
          : selectedOption.map(row => row.value),
      selectedOption: selectedOption === null ? "" : selectedOption
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  changeValidationMessage = (message, event) => {
    let msg = message === null ? "This is a required field" : message;
    event.target.setCustomValidity(msg);
  };

  getBase64 = file => {
    var self = this;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      self.setState({
        profileImage: reader.result
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  removeProfileImage = () => {
    this.setState({ profileImage: "" });
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    let { loading } = this.state;
    return (
      <div className="create-user-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          {this.props.match.params.id !== undefined ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />} User
        </h3>
        <Form onSubmit={this.createUser}>
          <Row form>
            {/* {this.props.match.params.id === undefined &&
            this.props.match.params.organizationId === undefined ? (
              <>
                <Col md={6}>
                  <FormGroup
                    className={
                      validation.organizationType.isInvalid ? "has-error" : ""
                    }
                  >
                    <Label for="role">
                      <TranslatableText translationKey="organization_type" />
                    </Label>
                    <Select
                      value={
                        this.state.selectedOrganizationType === ""
                          ? null
                          : this.state.selectedOrganizationType
                      }
                      className="user-selectbox"
                      options={this.state.organizationTypeDropdown}
                      onChange={selectedOption =>
                        this.handleSingleTagboxChange(
                          "organizationType",
                          "selectedOrganizationType",
                          selectedOption
                        )
                      }
                      hideSelectedOptions={false}
                    />
                    <span className="help-block">
                      {validation.organizationType.message}
                    </span>
                  </FormGroup>
                </Col>
                {this.state.organizationType !== "" ? (
                  <Col md={6}>
                    <FormGroup
                      className={validation.entity.isInvalid ? "has-error" : ""}
                    >
                      <Label for="entity">
                        {this.state.selectedEntity.label}
                      </Label>
                      <Select
                        value={
                          this.state.selectedOrganizationEntity === ""
                            ? null
                            : this.state.selectedOrganizationEntity
                        }
                        className="user-selectbox"
                        options={this.state.entityDropdown}
                        onChange={selectedOption =>
                          this.handleSingleTagboxChange(
                            "entity",
                            "selectedOrganizationEntity",
                            selectedOption
                          )
                        }
                        hideSelectedOptions={false}
                      />
                      <span className="help-block">
                        {validation.entity.message}
                      </span>
                    </FormGroup>
                  </Col>
                ) : (
                  <Col md={6}>
                    <FormGroup>
                      <Label className="" for="entity">
                        <TranslatableText translationKey="select_entity" />
                      </Label>
                      <Input disabled={true} type="select">
                        <option value="">-- Vælg --</option>
                      </Input>
                    </FormGroup>
                  </Col>
                )}
              </>
            ) : (
              <></>
            )} */}

            <Col md={6}>
              <FormGroup
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  <TranslatableText translationKey="name" />
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.name.message}</span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={validation.email.isInvalid ? "has-error" : ""}
              >
                <Label for="email">
                  <TranslatableText translationKey="email" />
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder=""
                  value={this.state.email}
                  onChange={this.handleChange}
                  disabled={
                    this.props.match.params.id !== undefined ? true : null
                  }
                />
                <span className="help-block">{validation.email.message}</span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">
                  <TranslatableText translationKey="phone" />
                </Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder=""
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>

            {this.state.checkAttachment === 0 ? (
              <Col md={6}>
                <FormGroup>
                  <Label for="file">Profile Image</Label>
                  <InlineLoading />
                </FormGroup>
              </Col>
            ) : (
              <Col md={6}>
                {this.state.profileImage !== "" ? (
                  <div>
                    <p className="label">
                      Profile Image <span className="small">(Preview)</span>
                    </p>
                    <div className="image-container">
                      <img
                        className="image-max-width"
                        src={this.state.profileImage}
                        height="33"
                      />
                      <span
                        className="remove-image"
                        onClick={this.removeProfileImage}
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    </div>
                  </div>
                ) : (
                  <FormGroup>
                    <Label for="file">
                      Profile Image{" "}
                      <span className="red small">
                        ( Max allowed size is <b>50Kb</b> )
                      </span>
                    </Label>
                    <Input
                      className="attachment-input"
                      type="file"
                      name="file"
                      id="file"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                )}
              </Col>
            )}

            <Fields
              customFields={this.state.customFields}
              componentState={this.state}
              handleChange={this.handleChange}
              handleTagboxChange={this.handleTagboxChange}
              changeValidationMessage={this.changeValidationMessage}
            />

            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>Back to list</Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreatePartnerProjectUser);
CreatePartnerProjectUser.contextType = GlobalContext;