import React, { Component } from "react";
import { Button, Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  getAnswerOptionSetValuesByIdAPI,
  addAnswerOptionSetValuesTranslationAPI,
  updateAnswerOptionSetValuesTranslationAPI,
  getSingleTranslationAPI,
  getAnswerOptionSetValueTranslationsAPI
} from "./answeroptionsetAPI";
import FormValidator from "../../../../../../components/validator/FormValidator";
import "../../../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../../../Language/TranslatableText";

import { Config } from "../../../../../../config/Config";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../../../components";



class AddAnswerOptionSetValuesTranslations extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "displayText",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      name: "",
      displayText: "",
      language: "",
      loading: false,
      cuLoader: false,
      validation: this.validator.valid(),
      languageDropdown: Config.languages
    };

    this.submitted = false;
  }
  componentDidMount() {
    var selected_languages,
      available_languages = [];
    if (this.props.match.params.answerOptionSetValueId !== undefined) {
      getAnswerOptionSetValuesByIdAPI(
        this.props.match.params.answerOptionSetValueId
      )
        .then(res => {
          this.setState({
            name: res.data.value
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });

      if (this.props.match.params.translationId === undefined) {
        getAnswerOptionSetValueTranslationsAPI(
          this.props.match.params.answerOptionSetValueId
        )
          .then(res => {
            selected_languages = res.data.map(el => {
              return el.language;
            });
            available_languages = this.state.languageDropdown.filter(el => {
              if (selected_languages.includes(el.value)) {
                return false; // skip
              }
              return true;
            });
            this.setState({
              languageDropdown: available_languages
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
          });
      }
    }
    if (this.props.match.params.translationId !== undefined) {
      this.setState({ loading: true });
      getSingleTranslationAPI(this.props.match.params.translationId)
        .then(res => {
          this.setState({
            displayText: res.displayText,
            language: res.language,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  addTranslation = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      ...this.state,
      answerOptionSetValueId:
        this.props.match.params.answerOptionSetValueId !== undefined
          ? this.props.match.params.answerOptionSetValueId
          : "",
      id:
        this.props.match.params.translationId !== undefined
          ? this.props.match.params.translationId
          : "",
      userId: this.context.user.organizations.userId
    };
    if (this.props.match.params.translationId === undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        addAnswerOptionSetValuesTranslationAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateAnswerOptionSetValuesTranslationAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="add-translation-view">
        {loading && (
          <ScreenLoading />
        )}

        {this.state.name !== "" ? (
          <h3>
            {this.props.match.params.translationId !== undefined
              ?  <TranslatableText translationKey="update" />
              : <TranslatableText translationKey="create" />}{" "}
            <TranslatableText translationKey="company.translation_for" /> "
            <span className="capitalize">{this.state.name}</span>"
          </h3>
        ) : (
          <div className="left-align">
            <InlineLoading />
          </div>
        )}

        <Form onSubmit={this.addTranslation}>
          <Row form>
            <Col md={6}>
              <FormGroup
                className={validation.displayText.isInvalid ? "has-error" : ""}
              >
                <Label for="displayText">
                  <TranslatableText translationKey="answeroptionset.display_text" />
                </Label>
                <Input
                  type="text"
                  name="displayText"
                  id="displayText"
                  placeholder=""
                  value={this.state.displayText}
                  onChange={this.handleChange}
                />
                <span className="help-block">
                  {validation.displayText.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={validation.language.isInvalid ? "has-error" : ""}
              >
                <Label for="language">
                  <TranslatableText translationKey="text" />
                </Label>
                <Input
                  type="select"
                  name="language"
                  id="language"
                  value={this.state.language}
                  onChange={this.handleChange}
                  disabled={this.props.match.params.translationId ? true : null}
                >
                  <option value="">-- Vælg --</option>
                  {this.state.languageDropdown.map((e, key) => {
                    return (
                      <option key={key} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                <span className="help-block">
                  {validation.language.message}
                </span>
              </FormGroup>
            </Col>
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.translationId !== undefined
                    ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(AddAnswerOptionSetValuesTranslations);
AddAnswerOptionSetValuesTranslations.contextType = GlobalContext;