import React, { Component } from "react";
import "../company/Company.scss";
import { NavLink, Row, Col, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import TranslatableText from "../../../../Language/TranslatableText";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
//import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { getProjectsAPI } from "./projectsAPI";
import Gap from "../../../../components/gap/Gap";
import { toast } from "react-toastify";
import classnames from "classnames";
import ChangeProject from "../../../../components/utilities/changeProject/ChangeProject";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading } from "../../../../components";


const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showFiltered: false,
      allOrganizations: [],
      filteredOrganizations: [],
      companies: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          filter: textFilter(),
          sort: true
        },
        {
          dataField: "vat",
          text: "Vat",
          filter: textFilter(),
          sort: true
        },
        {
          dataField: "partnerName",
          text: "Partner",
          sort: true
        },
        {
          dataField: "address",
          text: "Created At",
          sort: true
        },
        {
          dataField: "logo",
          text: "Logo",
          sort: true,
          formatter: this.logoFormatter
        },

        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`${this.props.match.path}/details/${row.id}/${row.companyId}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
                <ChangeProject projectId={row.id} languages={this.context.languages} />
              </div>
            );
          }
        }
      ],
      companyID: 0
    };
  }
  componentDidMount() {
    this.setState({
      loading: true
    });
    getProjectsAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        const filteredOrganizations = res.filter(item => {
          return item.isActive === true;
        });

        this.setState({
          allOrganizations: res,
          filteredOrganizations: filteredOrganizations,
          companies: filteredOrganizations,
          loading: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  }

  logoFormatter = cell => {
    if (
      cell !== "" &&
      cell !== null &&
      cell !== undefined &&
      cell.startsWith("data:")
    ) {
      return <img className="image-max-width" src={cell} />;
    } else {
      return "";
    }
  };

  rowClasses = (row, rowIndex) => {
    if (!row.isActive) {
      return "disabled-row";
    }
  };

  toggleButton = name => {
    let { allOrganizations, filteredOrganizations } = this.state;
    this.setState(
      {
        [name]: !this.state[name]
      },
      () => {
        if (this.state.showFiltered) {
          this.setState({ companies: allOrganizations });
        } else {
          this.setState({ companies: filteredOrganizations });
        }
      }
    );
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { companies, columns, loading } = this.state;
    return (
      <div className="admin-company-view">
        {loading && (
          <ScreenLoading />
        )}
        <h1>
          <TranslatableText translationKey="projects" />
        </h1>
        <Gap size="15px" />
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={companies}
            columns={columns}
            search
            exportCSV={{
              fileName: "custom.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex flex-end">
                  <div>
                    <div className="flex space-between">
                      <div className="flex space-between flex-align mr-10">
                        <div className="mr-10">Show all</div>
                        <div
                          onClick={e => this.toggleButton("showFiltered", e)}
                          className={classnames("search-toggle-btn")}
                        >
                          <span
                            className={classnames(
                              "ball",
                              this.state.showFiltered ? "toggled" : ""
                            )}
                          />
                        </div>
                      </div>
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          CSV
                        </ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`${this.props.match.url}/add`}
                        >
                          {/* <TranslatableText translationKey="company.add_company" /> */}
                          Add Project
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  classes="organization-list"
                  noDataIndication={() => <NoDataIndication />}
                  filter={filterFactory()}
                  pagination={paginationFactory()}
                  rowClasses={this.rowClasses}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Projects);
Projects.contextType = GlobalContext;