import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import CompanyProcess from "./CompanyProcess";
import AddCompanyProcessTranslation from "./AddCompanyProcessTranslation";
import CompanyProcessTranslations from "./CompanyProcessTranslations";
import CreateCompanyProcess from "./CreateCompanyProcess";
import CompanyProcessDetails from "./CompanyProcessDetails";
import SurveyWizard from "../../../wizard/SurveyWizardNew";
import { withRouter } from "react-router-dom";

class CompanyProcessRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="admin-process-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/create/:sectionId`}
            render={props => (
              <CreateCompanyProcess
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/create`}
            render={props => (
              <CreateCompanyProcess
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <CompanyProcessDetails
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreateCompanyProcess
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/add/:processId`}
            render={props => (
              <AddCompanyProcessTranslation
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/update/:processId/:translationId`}
            render={props => (
              <AddCompanyProcessTranslation
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/:id`}
            render={props => (
              <CompanyProcessTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/wizard/:id/:recordId/:regardingObjectTypeId`}
            render={props => (
              <SurveyWizard {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/company-admin/process"
            render={props => (
              <CompanyProcess {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(CompanyProcessRouter);
