import { toast } from "react-toastify";
import { OrganizationsClient } from "../../../apiClients";

export function changeProject(organizationId: string, path: string = "/") {
  let _OrganizationsClient = new OrganizationsClient();
  _OrganizationsClient.changeProject(organizationId)
    .then(() => {
      // After Switching Organization - reload everything
      window.location.replace(path);
    })
    .catch(error => {
      toast.error(error.message);
    });
}
