import axios from "axios";
import { Config } from "../../config/Config";
import authService from "../../components/api-authorization/AuthorizeService";

// QuestionAnswers API URL
const QuestionAnswers_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/QuestionAnswers`;
const ExternalQuestionnaire_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Survey`;

export const setQuestionnaireHeaderForQuestionAnswers = (data) => {
  axios.defaults.headers.common['Questionnaire'] = data;
}

export const createQuestionAnswersAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .post(`${QuestionAnswers_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update QuestionAnswers API
// export const updateQuestionAnswersAPI = async data => {
//   const token = await authService.getAccessToken();
//   var headers = {
//     Authorization: `Bearer ${token}`
//   };
//   return axios
//     .put(`${QuestionAnswers_API_URL}`, data, { headers: headers })
//     .then(res => res);
// };

// Get QuestionAnswers By ID
export const getQuestionAnswersByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .get(`${QuestionAnswers_API_URL}/${id}`, { headers: headers })
    .then(({ data }) => data);
};

// Get OptionsByUrl API
export const getOptionsByUrlAPI = async url => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.get(`${url}`, { headers: headers }).then(({ data }) => data);
};

export const updateProgressStateForExternalQuestionnaire = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .put(`${ExternalQuestionnaire_API_URL}/State`, data, { headers: headers })
    .then(res => res);
}
