import axios from "axios";
import { Component } from "react";
import Select from "react-select";
import * as Survey from "survey-react";
import { InlineLoading } from "../../../components";
import { getOptionsByUrlAPI } from "../surveyWizardAPI";

class Tagbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      options: [],
      isLoading: false
    };
  }

  componentDidMount() {
    const { question } = this.props;
    if (!question.isExternalURL && question.optionsByUrl === undefined) {
      if (question.value === undefined) {
        var selected = [];
      } else if (question.value.some(element => element.value !== undefined)) {
        var selected = [...question.value];
      } else if (
        !question.value.some(element => element.value !== undefined) &&
        Array.isArray(question.value)
      ) {
        // This is the part where user goes back to the previous page
        var selected = question.tagBoxSelected;
      } else {
        var selected = [];
      }

      if (selected === null || selected === undefined) {
        var values = [];
      } else {
        var values = selected.map(row => {
          if (row.value !== undefined) {
            return row.value;
          } else {
            return row;
          }
        });
      }
      question.value = values;
      question.tagBoxSelected = selected;
      this.setState({ selected });
    }

    if (question.isExternalURL) {
      if (question.value !== undefined) {
        if (Array.isArray(question.value)) {
          var selected =
            question.value === undefined
              ? []
              : question.value.some(element => element.value !== undefined)
              ? question.value
              : [];
          var values = selected;
        } else {
          var selected =
            JSON.parse(question.value) === undefined
              ? []
              : JSON.parse(question.value).some(
                  element => element.value !== undefined
                )
              ? question.value
              : [];
          if (!Array.isArray(selected)) {
            selected = JSON.parse(selected);
          }
          var values = selected;
        }
      } else {
        var selected = [];
        var values = selected;
      }
      question.value = values;
      this.setState({ selected });
    }

    if (
      question.optionsByUrl !== "" &&
      question.optionsByUrl !== undefined &&
      (question.isExternalURL === false ||
        question.isExternalURL === undefined ||
        question.isExternalURL === null)
    ) {
      this.setState({ isLoading: true });
      getOptionsByUrlAPI(question.optionsByUrl)
        .then(res => {
          if (!Array.isArray(res)) {
            return;
          }
          if (!question.isExternalURL) {
            if (question.value === undefined || question.value === null) {
              var selected = [];
            } else if (
              question.value.some(element => element.value !== undefined)
            ) {
              var selected = [...question.value];
            } else if (
              !question.value.some(element => element.value !== undefined) &&
              Array.isArray(question.value)
            ) {
              var rows = [];
              var row;
              question.value.forEach(element => {
                row = this.searchArray(element, res, "id");
                rows.push(row);
              });
              var selected = [...rows];
            } else {
              var selected = [];
            }

            if (selected === null || selected === undefined) {
              var values = [];
            } else {
              var values = selected.map(row => {
                if (row.value !== undefined) {
                  return row.value;
                } else {
                  return row;
                }
              });
            }

            question.value = values;
          }
          this.setState({ options: res, selected: selected, isLoading: false });
        })
        .catch(error => {
          this.setState({ options: [] });
        });
    } else if (
      question.optionsByUrl !== "" &&
      question.optionsByUrl !== undefined &&
      question.isExternalURL === true
    ) {
      this.setState({ isLoading: true });
      var valueKey = question.valueKey;
      var titleKey = question.titleKey;
      axios.get(`${question.optionsByUrl}`).then(res => {
        const optionsByUrl = res.data;
        let options = optionsByUrl.map(function (item) {
          return {
            value: item[valueKey],
            label: item[titleKey]
          };
        });
        this.setState({ options, isLoading: false });
      });
    }
  }

  onChange = selectedOption => {
    const { question } = this.props;
    if (!question.isExternalURL) {
      if (this.props.question.isMulti) {
        var values =
          selectedOption === null || selectedOption === undefined
            ? []
            : selectedOption.map(row => row.value);
        question.value = values;
        question.tagBoxSelected = selectedOption;
      } else {
        question.value = [selectedOption.value];
      }
    } else {
      if (Array.isArray(selectedOption)) {
        question.value = selectedOption;
      } else {
        question.value = [selectedOption];
      }
    }
    this.setState({ selected: selectedOption });
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  render() {
    let { isLoading } = this.state;
    if (isLoading) {
      return (
        <InlineLoading />
      );
    } else {
      return (
        <Select
          isMulti={this.props.question.isMulti}
          isDisabled={this.props.question.isReadOnly}
          value={this.state.selected}
          name="tagbox"
          options={
            this.props.question.optionsByUrl !== "" &&
            this.props.question.optionsByUrl !== undefined
              ? this.state.options
              : this.props.question.options
          }
          onChange={this.onChange}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      );
    }
  }
}

export const tagbox = {
  name: "tagbox",
  render: question => <Tagbox question={question} />,
  isFit: function (question) {
    return question.getType() === "tagbox";
  },
  activatedByChanged: function (activatedBy) {
    //we do not need to check acticatedBy parameter, since we will use our widget for customType only
    //We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us
    Survey.JsonObject.metaData.addClass("tagbox", [], null, "text");
    //signaturepad is derived from "empty" class - basic question class
    //Survey.JsonObject.metaData.addClass("signaturepad", [], null, "empty");

    //Add new property(s)
    //For more information go to https://surveyjs.io/Examples/Builder/?id=addproperties#content-docs
    Survey.JsonObject.metaData.addProperties("tagbox", [{ name: "tagbox" }]);
    // Survey.JsonObject.metaData.addProperty("tagbox", { options: ["standard"] });
  }
};
