import React, { Component } from "react";
import "../../../admin/views/company/Company.scss";
import { NavLink, Table, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faEdit,
  faBoxFull
} from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import {
  getCompanyByIdAPI,
  getUserPartnerOrCompanyProjectsAPI
} from "./firmAPI";
import Gap from "../../../../components/gap/Gap";
import {
  getPartnerByIdAPI,
  getPartnerCompaniesAPI
} from "../../../admin/views/partners/partnersAPI";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import Accent from "../../../../components/accent/Accent";
import { Config } from "../../../../config/Config";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>No data available</span>
  </div>
);

class Firm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      partnerId: "710F2607-D945-47B4-BFC3-AE3472918BFC",
      companies: [],
      company: {
        name: "",
        vat: "",
        address: "",
        logo: "",
        id: ""
      },
      partner: {
        name: "",
        vat: "",
        address: "",
        logo: "",
        id: ""
      },
      columns: [
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "partnerName",
          text: "Partner",
          sort: true
        },
        {
          dataField: "address",
          text: "Created At",
          sort: true
        },
        {
          dataField: "logo",
          text: "Logo",
          sort: true,
          formatter: this.logoFormatter
        },
        {
          dataField: "actions",
          text: "Actions"
        }
      ],
      projects: [],
      projectsColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/company-admin/company/project/details/${row.id}/${this.context.user.organizations.companyId}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
              </div>
            );
          }
        }
      ],
      companyID: 0
    };
  }
  componentDidMount() {
    // To Do:
    // This organization API call getCompanyByIdAPI will be invoked only when the partner ID is not null
    // If it's null then getPartnerByIdAPI will be called with organization ID
    getCompanyByIdAPI(this.context.user.organizations.organizationId)
      .then(res => {
        this.setState({ company: res.data }, () => {
          if (this.state.company.partnerId !== null) {
            getPartnerByIdAPI(this.state.company.partnerId)
              .then(res => {
                this.setState({
                  partner: res.data
                });
              })
              .catch(error => {
                toast.error(this.context.languages["api_error"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000
                });
              });
            this.partnerCompanies(this.state.company.partnerId);
          } else {
            this.setState({
              partner: res.data
            });
          }
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    this.populateProjects();
  }

  populateProjects = () => {
    this.setState({ tableLoader: true, projects: [] });
    getUserPartnerOrCompanyProjectsAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ projects: res, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  partnerCompanies = partnerId => {
    this.setState({ tableLoader: true, companies: [] });
    getPartnerCompaniesAPI(partnerId)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var self = this;
        var data = res
          .filter(function (el) {
            if (el.name === "") {
              return false; // skip
            }
            return true;
          })
          .map(function (el) {
            var o = Object.assign({}, el);
            o.actions = (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`${self.props.match.path}/details/${el.id}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
                <NavLink
                  tag={Link}
                  to={`${self.props.match.path}/update/${el.id}`}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
              </div>
            );
            return o;
          });

        this.setState({ companies: data, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  logoFormatter = cell => {
    if (
      cell !== "" &&
      cell !== null &&
      cell !== undefined &&
      cell.startsWith("data:")
    ) {
      return <img className="image-max-width" src={cell} />;
    } else {
      return "";
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { companies, columns, tableLoader } = this.state;
    return (
      <div className="firm-view">
        <h1>
          {" "}
          <TranslatableText translationKey="company" />
        </h1>
        <hr />
        <Row>
          <Col md={6}>
            <div className="company-section white-bg">
              <h3>
                {" "}
                <TranslatableText translationKey="company.company_information" />
              </h3>
              {this.state.company.name !== "" ? (
                <Table>
                  <tbody>
                    <tr>
                      <td className="text-capitalize bold">
                        {" "}
                        <TranslatableText translationKey="logo" />
                      </td>
                      <td>
                        <img src={this.state.company.logo} height="50" />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-capitalize bold">
                        {" "}
                        <TranslatableText translationKey="name" />
                      </td>
                      <td>{this.state.company.name}</td>
                    </tr>
                    <tr>
                      <td className="text-capitalize bold">
                        {" "}
                        <TranslatableText translationKey="address" />
                      </td>
                      <td>{this.state.company.address}</td>
                    </tr>
                    <tr>
                      <td className="text-capitalize bold">
                        {" "}
                        <TranslatableText translationKey="company.vat" />
                      </td>
                      <td>{this.state.company.vat}</td>
                    </tr>
                    <tr>
                      <td className="text-capitalize bold">
                        {" "}
                        <TranslatableText translationKey="company.report_language" />
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="text-capitalize bold">
                        <TranslatableText translationKey="company.licens_expires_on" />
                      </td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <InlineLoading />
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="partner-section white-bg">
              <h3>
                <TranslatableText translationKey="company.partner_information" />
              </h3>
              {this.state.partner.name !== "" ? (
                <Table>
                  <tbody>
                    <tr>
                      <td className="text-capitalize bold">
                        <TranslatableText translationKey="logo" />
                      </td>
                      <td>
                        <img src={this.state.partner.logo} height="50" />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-capitalize bold">
                        <TranslatableText translationKey="name" />
                      </td>
                      <td>{this.state.partner.name}</td>
                    </tr>
                    <tr>
                      <td className="text-capitalize bold">
                        <TranslatableText translationKey="address" />
                      </td>
                      <td>{this.state.partner.address}</td>
                    </tr>
                    <tr>
                      <td className="text-capitalize bold">
                        <TranslatableText translationKey="company.vat" />
                      </td>
                      <td>{this.state.partner.vat}</td>
                    </tr>
                    <tr>
                      <td className="text-capitalize bold">
                        <TranslatableText translationKey="company.report_language" />
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="text-capitalize bold">
                        <TranslatableText translationKey="company.licens_expires_on" />
                      </td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
                <InlineLoading />
              )}
            </div>
          </Col>
        </Row>
        {Config.enable_projects_layer ? (
          <Row>
            <Col md={12}>
              <Accent useIcon={true} icon={faBoxFull}>
                Projects
              </Accent>
            </Col>
            <Col md={12}>
              <div className="table-container">
                <ToolkitProvider
                  keyField="id"
                  data={this.state.projects}
                  columns={this.state.projectsColumns}
                  search
                  exportCSV={{
                    fileName: "custom.csv",
                    separator: this.context.user.language === 'dk' ? ';' : ',',
                    ignoreHeader: false,
                    noAutoBOM: false
                  }}
                >
                  {props => (
                    <div>
                      <div className="flex space-between">
                        <div>
                          <SearchBar {...props.searchProps} />
                        </div>
                        <div>
                          <div className="flex space-between">
                            {this.context.userRoles.find(r =>
                              r.includes("Developer")
                            ) !== undefined ? (
                              <ExportCSVButton
                                className="export-btn"
                                {...props.csvProps}
                              >
                                CSV
                              </ExportCSVButton>
                            ) : (
                              <></>
                            )}
                            <div>
                              <NavLink
                                className="button-primary"
                                tag={Link}
                                to={`/company-admin/company/project/add/${this.context.user.organizations.companyId}/${this.context.user.organizations.partnerId}`}
                              >
                                Create
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <BootstrapTable
                        noDataIndication={() =>
                          tableLoader ? (
                            <InlineLoading />
                          ) : (
                            <NoDataIndication />
                          )
                        }
                        {...props.baseProps}
                        pagination={paginationFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Gap size="15px" />

        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              {" "}
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Firm;
Firm.contextType = GlobalContext;