import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Component } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { SectionsClient, TasksClient } from "../../apiClients";
import HandleCaught_API_Error from "../../apiClients/api_caughtError";
import { ScreenLoading } from "../../components";
import { GlobalContext } from "../../contexts/global-context";
import TranslatableText from "../../Language/TranslatableText";
import {
  getTasksByYearAPI
} from "../tasks/tasksAPI";
import AnnualCycleOfWork from "./AnnualCycleOfWork";



class AnnualCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "",
      loading: false,
      data: {},
      dataTest: {},
      yearDropdown: [],
      tasksDropdown: [],
      tasksList: [],
      sectionsDropdown: [],
      taskId: "",
      sectionId: ""
    };
    this._SectionsClient = new SectionsClient();
    this._TasksClient = new TasksClient();
  }

  componentDidMount() {
    this.setState({ loading: true, lang: this.context.language });
    var years = [];
    for (var i = 2018; i <= 2050; i++) {
      years.push({ id: i, name: i });
    }
    this.setState(
      {
        yearDropdown: years,
        year: new Date().getFullYear(),
        loading: false
      });

    // Populate Tasks dropdown
    this.getTasksByYear(new Date().getFullYear())

    // Populate Sections dropdown
    // this._SectionsClient.getAllOrganizationSections()
    // // getSections(new Date().getFullYear())
    //   .then(res => {
    //     this.setState({ sectionsDropdown: Array.isArray(res) ? res : [] });
    //   })
    // .catch(error => HandleCaught_API_Error(error));
  }

  getTasksByYear = (year) => {
    getTasksByYearAPI(year)
      .then(res => {
        let ans = this.prepareData(res);
        this.setState({ data: ans })
        this.setState({ tasksDropdown: Array.isArray(res) ? res : [], tasksList: Array.isArray(res) ? res : [] });
      })
      .catch(error => HandleCaught_API_Error(error));
  }

  getMonth = (/** @type {number} */ monthNumber) => moment(monthNumber, 'MM').format('MMMM');

  getTaskStatus(task) {
    let taskState = "Gray";
    if (moment(task.actualDueDate) <= moment() && moment() <= moment(task.actualDueDate)) {
      taskState = "CurrentWeek";
    }
    if (moment() <= moment(task.actualDueDate)) {
      taskState = "Upcoming";
    }
    if (task.status == "TaskInProgress") {
      taskState = "TaskInProgress";
    }
    if (moment(task.actualDueDate) <= moment()) {
      taskState = "TaskOverDue";
    }
    if (task.status == "TaskCompleted") {
      taskState = "TaskCompleted";
    }
    if (task.status == "Complete") {
      taskState = "Good";
    }
    if (task.statusCode == 1002) {
      taskState = "Good";
    }
    return taskState;
  }

  prepareData(res, taskId, sectionId) {
    let temp = {};
    //Year
    for (let i = 0; i < 1; i++) {
      temp.id = "00000000-0000-0000-0000-000000000000";
      temp.name = "root";
      temp.parentId = null;
      temp.type = "root";
      temp.state = null;
      temp.description = null;
      temp.icon = null;
      temp.size = null;
      temp.accessLevel = 0;
      temp.tags = [];
      temp.deadline = "0001-01-01T00:00:00+00:00";
      let tempChildrenMonth = [];

      //Month
      for (let j = 1; j <= 12; j++) {
        let tempMonth = {};

        tempMonth.monthNumber = j;
        tempMonth.id = "846aa9fe-d7c3-4a76-8bee-a0c4a1bea4b7";
        tempMonth.name = this.getMonth(j);
        tempMonth.parentId = "00000000-0000-0000-0000-000000000000";
        tempMonth.type = "Month";
        tempMonth.state = "Month";
        tempMonth.description = this.getMonth(j);
        tempMonth.icon = "calendar";
        tempMonth.size = null;
        tempMonth.accessLevel = 1;
        tempMonth.tags = [];
        tempMonth.deadline = "0001-01-01T00:00:00+00:00";
        let tempChildrenWeek = [];
        let getWeeks = this.getDaysInAMonth(moment(res[0].actualDueDate).format("YYYY"), j - 1);
        //week
        for (let k = 0; k < getWeeks.length; k++) {
          let weekTasks = res.filter((val) => {
            if (moment(val.actualDueDate).isoWeek() === getWeeks[k - 0][0].isoWeek()) {
              return true;
            }
          })
          let tempWeek = {};
          tempWeek.weekNumber = getWeeks[k - 0][0].isoWeek();
          tempWeek.id = "c6078425-b600-4b96-8a81-d8171769fbb7";
          tempWeek.name = getWeeks[k - 0][0].isoWeek().toString();
          tempWeek.parentId = "846aa9fe-d7c3-4a76-8bee-a0c4a1bea4b7";
          tempWeek.type = "Week";
          tempWeek.state = weekTasks.length > 0 ? this.getTaskStatus(weekTasks[0]) : 'Gray';
          tempWeek.description = "Week " + getWeeks[k - 0][0].isoWeek().toString();
          tempWeek.icon = "";
          tempWeek.size = null;
          tempWeek.accessLevel = 1;
          tempWeek.tags = [
            "00000000-0000-0000-0000-000000000000"
          ];
          tempWeek.deadline = "0001-01-01T00:00:00+00:00";
          let tempChildrenLast = [];

          //task
          if (weekTasks.length > 0) {
            for (let l = 0; l < weekTasks.length; l++) {
              let tempLast = {};

              tempLast.seriesId = weekTasks[l].seriesId;
              tempLast.id = weekTasks[l].id;
              tempLast.name = weekTasks[l].name;
              tempLast.parentId = null;
              tempLast.type = "Assignment";
              tempLast.state = this.getTaskStatus(weekTasks[l]);
              tempLast.description = weekTasks[l].description;
              tempLast.icon = null;
              tempLast.size = 1;
              tempLast.accessLevel = null;
              tempLast.tags = [];
              tempLast.deadline = weekTasks[l].actualDueDate;
              if (taskId) {
                if (taskId === weekTasks[l].id) {
                  tempChildrenLast.push(tempLast);
                }
              } else {
                if (sectionId) {
                  if (sectionId === weekTasks[l].regardingObjectId) {
                    tempChildrenLast.push(tempLast);
                  }
                } else {
                  tempChildrenLast.push(tempLast);
                }
              }
            }
          } else {
            for (let l = 0; l < 1; l++) {
              let tempLast = {};

              tempLast.seriesId = "00000000-0000-0000-0000-000000000000";
              tempLast.id = "00000000-0000-0000-0000-000000000000";
              tempLast.name = null;
              tempLast.parentId = null;
              tempLast.type = null;
              tempLast.state = "Gray";
              tempLast.description = null;
              tempLast.icon = null;
              tempLast.size = 1;
              tempLast.accessLevel = null;
              tempLast.tags = [];
              tempLast.deadline = "0001-01-01T00:00:00+00:00";

              tempChildrenLast.push(tempLast);
            }
          }
          tempWeek.children = tempChildrenLast;
          tempChildrenWeek.push(tempWeek);
        }
        tempMonth.children = tempChildrenWeek;
        tempChildrenMonth.push(tempMonth);
      }
      temp.children = tempChildrenMonth;
    }
    return temp;
  }

  getDaysInAMonth = (year = +Moment().format("YYYY"), month = +Moment().format("MM") - 1) => {
    const moment = extendMoment(Moment);
    const startDate = moment([year, month]);

    const firstDay = moment(startDate).startOf('month')
    const endDay = moment(startDate).endOf('month')

    const monthRange = moment.range(firstDay, endDay)
    const weeks = [];
    const days = Array.from(monthRange.by('day'));
    days.forEach(it => {
      if (!weeks.includes(it.week())) {
        weeks.push(it.week());
      }
    })

    const calendar = []
    weeks.forEach(week => {
      const firstWeekDay = moment([year, month]).week(week).day(1)
      const lastWeekDay = moment([year, month]).week(week).day(7)
      // const firstWeekDay = moment([year, month]).week(week).day(0);
      // const lastWeekDay = moment([year, month]).week(week).day(6);
      const weekRange = moment.range(firstWeekDay, lastWeekDay)
      calendar.push(Array.from(weekRange.by('day')));
    })

    return calendar;
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (target.name === "taskId") {
      let ans = this.prepareData(this.state.tasksList, value);
      this.setState({ data: ans, taskId: value, sectionId: "" });
    } else if (target.name === "sectionId") {
      let ans = this.prepareData(this.state.tasksList, null, value);
      this.setState({ data: ans, sectionId: value, taskId: "" });
    } else {
      this.setState({ year: value, sectionId: "", taskId: "" })
      this.getTasksByYear(value)
    }
  };

  render() {
    let { loading } = this.state;
    return (
      <div className="center-align">
        {loading && (
          <ScreenLoading />
        )}
        <div className="flex center">
          {/* <FormGroup className="mr-10">
            <Label className="float-left bold">
              <TranslatableText translationKey="annual_cycle.search_by_section" />
            </Label>
            <Input
              type="select"
              name="sectionId"
              id="sectionId"
              value={this.state.sectionId}
              onChange={this.handleChange}
              disabled={this.props.match.params.id !== undefined ? true : null}
            >
              <option value="">-- Search Section --</option>
              {this.state.sectionsDropdown.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup> */}

          <FormGroup className="mr-10">
            <Label className="float-left bold">
              <TranslatableText translationKey="annual_cycle.search_by_task" />
            </Label>
            <Input
              type="select"
              name="taskId"
              id="taskId"
              value={this.state.taskId}
              onChange={this.handleChange}
              disabled={this.props.match.params.id !== undefined ? true : null}
            >
              <option value="">{this.context.languages["-- Select --"]}</option>
              {this.state.tasksDropdown.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label className="float-left bold">
              <TranslatableText translationKey="annual_cycle.search_by_year" />
            </Label>
            <Input
              type="select"
              name="year"
              id="year"
              value={this.state.year}
              onChange={this.handleChange}
              disabled={this.props.match.params.id !== undefined ? true : null}
            >
              <option value="">{this.context.languages["-- Select --"]}</option>
              {this.state.yearDropdown.map((e, key) => {
                return (
                  <option key={key} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </div>
        <AnnualCycleOfWork data={this.state.data} />
      </div>
    );
  }
}

export default AnnualCycle;
AnnualCycle.contextType = GlobalContext;