import {
  faBullhorn,
  faCity, faCogs, faHatWizard, faUserFriends, faUsers, faUsersClass
} from "@fortawesome/pro-solid-svg-icons";
import { useRouteMatch } from "react-router-dom";
import Tile from "../../components/tile/Tile";
import { usePlatformUserData } from "../../contexts";
import TranslatableText from "../../Language/TranslatableText";

export default function CompanyAdmin() {
  let match = useRouteMatch();
  const currentOrgData = usePlatformUserData();



    return (
      <div className="admin-view">
        <h4 className="admin-title">
          <TranslatableText translationKey="company" />
        </h4>
        <div className="flex space-between tiles-container">
          <Tile
            className="custom-tile"
            title="company"
            subTitle="Manage Companies"
            linkTo={`${match!.url}/company`}
            icon={faCity}
            iconSize="5x"
            backgroundColor="#17a2b8"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="sections"
            subTitle="Manage Sections"
            linkTo={`${match!.url}/sections`}
            icon={faCity}
            iconSize="5x"
            backgroundColor="#fd7e14"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="masterdb_activity"
            subTitle="Manage Processes"
            linkTo={`${match!.url}/process`}
            icon={faBullhorn}
            iconSize="5x"
            backgroundColor="#3498db"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="wizard"
            subTitle="Manage Wizard"
            linkTo={`${match!.url}/wizard/${currentOrgData.currentOrgData.organizationId}`}
            icon={faHatWizard}
            iconSize="5x"
            backgroundColor="#3498db"
            textColor="#fff"
          />
        </div>
        {currentOrgData.currentOrgData.systemSettings.enable_flow_engine ? (
          <>
            <h4 className="admin-title">
              <TranslatableText translationKey="flows" />
            </h4>
            <div className="flex space-between tiles-container mt-20">
              <Tile
                className="custom-tile"
                title="flow_engine"
                subTitle="Manage Flow Engine"
                linkTo={`${match!.url}/flow-engine`}
                icon={faCogs}
                iconSize="5x"
                backgroundColor="#055388"
                textColor="#fff"
              />
              <Tile
                className="custom-tile"
                title="persons"
                subTitle="Manage General Users"
                linkTo={`${match!.url}/persons`}
                icon={faUserFriends}
                iconSize="5x"
                backgroundColor="#c1a342"
                textColor="#fff"
              />
            </div>
          </>
        ) : (
          <></>
        )}

        <h4 className="admin-title">
          <TranslatableText translationKey="users_roles_claims" />
        </h4>
        <div className="flex space-between tiles-container">
          <Tile
            className="custom-tile"
            title="teams"
            subTitle="Manage Teams"
            linkTo={`${match!.url}/teams`}
            icon={faUsersClass}
            iconSize="5x"
            backgroundColor="#17b89e"
            textColor="#fff"
          />
          <Tile
            className="custom-tile"
            title="users"
            subTitle="Manage Users"
            linkTo={`${match!.url}/users`}
            icon={faUsers}
            iconSize="5x"
            backgroundColor="#6f42c1"
            textColor="#fff"
          />
          <Tile
            className="custom-tile"
            title="roles"
            subTitle="Manage Roles"
            linkTo={`${match!.url}/roles`}
            icon={faUsers}
            iconSize="5x"
            backgroundColor="#17a2b8"
            textColor="#fff"
          />
        </div>
        {/* <h4 className="admin-title">
          <TranslatableText translationKey="custom_fields" />{" "}
          {currentOrgData.currentOrgData.systemSettings.enable_sharepoint_configuration
            ? "& SharePoint Configuration"
            : ""}
        </h4> */}
        <div className="flex space-between tiles-container">
          {/* <Tile
            className="custom-tile"
            title="custom_fields"
            subTitle="Manage Custom Fields"
            linkTo={`${match!.url}/custom-fields`}
            icon={faTh}
            iconSize="5x"
            backgroundColor="#9777ed"
            textColor="#fff"
          /> */}
          {currentOrgData.currentOrgData.systemSettings.enable_sharepoint_configuration && (
            <Tile
              className="custom-tile"
              title="sharepoint"
              subTitle="SharePoint"
              linkTo={`${match!.url}/sharepoint`}
              icon={faCogs}
              iconSize="5x"
              backgroundColor="#17b89e"
              textColor="#fff"
            />
          )}
        </div>
      </div>
    );
  }

