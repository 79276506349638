import React, { useState, useEffect } from "react";
import { NavLink, Row, Col, Button } from "reactstrap";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUserNinja } from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { toast } from "react-toastify";
import TranslatableText, { TranslatableTextString } from "../../../../Language/TranslatableText";
import { usePlatformUserData } from "../../../../contexts";
import { GetSystemsResponse, SystemsClient } from "../../../../apiClients";
import { ScreenLoading } from "../../../../components";
import SecuredComponent from "../../../../components/secured-component/SecuredComponent";
import { AccountLevel } from "../../../../config/Levels";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);


export default function Systems() {
  const history = useHistory();
  let match = useRouteMatch();
  const currentOrgData = usePlatformUserData();
  const [tableLoader, setTableLoader] = useState(true);
  const [systemData, setSystemData] = useState<GetSystemsResponse[]>();
  const SystemClient = new SystemsClient();


  const tableColumns: Array<ColumnDescription<GetSystemsResponse>> = [
    {
      dataField: "id",
      text: "ID",
      hidden: true
    },
    {
      dataField: "name",
      text: TranslatableTextString("name"),
      sort: true
    },
    {
      dataField: "url",
      text: TranslatableTextString("URL"),
      sort: true
    },
    {
      dataField: "publisher",
      text: TranslatableTextString("publisher"),
      sort: true
    },
    {
      dataField: "description",
      text: TranslatableTextString("description")
    },
    {
      dataField: "actions",
      text: TranslatableTextString("actions"),
      csvExport: false,
      formatter: (cell, row) => {
        return (
          <div>
            <NavLink
              tag={Link}
              to={`${match?.path}/updateSystem/${row.id}`}
            >
              <FontAwesomeIcon icon={faEdit} size="1x" />
            </NavLink>
          </div>
        );
      }
    }
  ];


  useEffect(() => {
    SystemClient.systemsGet()
      .then(res => {
        setSystemData(res);
        console.log(res)
      })
      .catch(error => {
        toast.error(TranslatableTextString("api_error"));
        console.debug(error);
      })
      .finally(() => {
        setTableLoader(false);
      });
  }, []);


  if (tableLoader || systemData === undefined) {
    return (
        <ScreenLoading />
    );
  } else {
    return (
      <div className="admin-systems-view">
        <h1>
          <TranslatableText translationKey="systems" />
        </h1>
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={systemData}
            columns={tableColumns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: currentOrgData.currentOrgData.defaultLanguage === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">

                      <SecuredComponent accountLevel={AccountLevel.Developer}>
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                      </SecuredComponent>
                      <div>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`${match?.url}/create`}
                        >
                          <TranslatableText translationKey="systems.create_system" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={<NoDataIndication />}
                  {...props.baseProps}
                  pagination={paginationFactory({})}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={() => history.goBack()}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

}
