import React, { Component } from "react";
import {
  getPageByIdAPI,
  getPagePanelsByIdAPI,
  getPageTranslationsAPI,
  deletePageTranslationByIdAPI
} from "./pageAPI";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TranslatableText from "../../../../../../Language/TranslatableText";
import {
  faEdit,
  faInfoCircle,
  faTrash
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import Accent from "../../../../../../components/accent/Accent";
import { deletePanelByIdAPI } from "../panels/panelsAPI";
import { toast } from "react-toastify";
import { Config } from "../../../../../../config/Config";
import Swal from "sweetalert2";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { InlineLoading } from "../../../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);
const defaultSorted = [
  {
    dataField: "order", // if dataField is not match to any column you defined, it will be ignored.
    order: "asc" // desc or asc
  }
];

class PageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      deleteLoader: false,
      wizardName: "",
      name: "",
      condition: "",
      order: 0,
      wizardId: "",
      panels: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          sort: true,
          classes: "recordID"
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "condition",
          text: "Condition",
          sort: true,
          classes: "recordID"
        },
        {
          dataField: "order",
          text: "Order",
          sort: true,
          classes: "monofont"
        },
        {
          dataField: "actions",
          text: "Actions",
          width: "10%",
          csvExport: false
        }
      ],
      panelId: null,
      translations: [],
      translationsColumns: [
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "description",
          text: "Description",
          sort: true
        },

        {
          dataField: "language",
          text: "Language",
          formatter: (cell, row) => {
            let language = this.searchArray(cell, Config.languages, "value");
            if (language === undefined) {
              language = "dk";
            }
            return language.name;
          },
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/wizard/page/translations/update/${this.props.match.params.id}/${row.id}`}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
                <NavLink onClick={e => this.deleteRecord(row.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
          }
        }
      ],
      isDisabled: false
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getPageByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            wizardName: res.data.wizardName,
            name: res.data.name,
            condition: res.data.condition,
            order: res.data.order,
            wizardId: res.data.wizardId
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
      this.populatePanelsList();
      this.populatePageTranslationsTable();
    }
  }

  populatePageTranslationsTable = () => {
    this.setState({ tableLoader: true, translations: [] });
    getPageTranslationsAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res.data)) {
          console.log("debug: ", res.data);
          return;
        }
        if (res.data.length > 0) {
          if (res.data.length == Config.languages.length) {
            this.setState({ isDisabled: true });
          } else {
            this.setState({ isDisabled: false });
          }
          this.setState({ translations: res.data, tableLoader: false });
        } else {
          this.setState({ translations: [], tableLoader: false });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  populatePanelsList = () => {
    var self = this;
    this.setState({ tableLoader: true, panels: [] });
    getPagePanelsByIdAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink tag={Link} to={`/admin/wizard/panel/details/${el.id}`}>
                <Tippy placement="top" content="See details">
                  <span>
                    <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink tag={Link} to={`/admin/wizard/panel/update/${el.id}`}>
                <Tippy placement="top" content="Update">
                  <span>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink onClick={e => self.openDeleteModal(el.id, e)}>
                <Tippy placement="top" content="Remove">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
          return o;
        });
        this.setState({
          panels: data,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            panelId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      panelId: id
    });
  };

  deletePanel = id => {
    this.setState({ deleteLoader: true });
    deletePanelByIdAPI(id)
      .then(res => {
        this.populatePanelsList();
        this.setState({
          deleteModal: false,
          panelId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          panelId: null,
          deleteLoader: false
        });
      });
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  deleteRecord = id => {
    this.setState({ tableLoader: true }, () => {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.delete_record"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.context.languages[
          "swal.are_you_sure.confirmButtonText"
        ],
        cancelButtonText: this.context.languages[
          "swal.are_you_sure.cancelButtonText"
        ]
      }).then(result => {
        if (result.value) {
          this.setState({ deleteLoader: true });
          deletePageTranslationByIdAPI(id, this.context.user.organizations.userId)
            .then(res => {
              this.populatePageTranslationsTable();
              this.setState({
                deleteLoader: false
              });
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
              this.setState({
                deleteLoader: false
              });
            });
          Swal.fire(
            this.context.languages["swal.deleted"],
            this.context.languages["swal.deleted_record_deleted"],
            "success"
          );
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState(
            {
              tableLoader: false
            },
            () => {
              Swal.fire(
                this.context.languages["swal.cancelled"],
                this.context.languages["swal.cancelled_your_record_is_safe"],
                "error"
              );
            }
          );
        }
      });
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let {
      tableLoader,
      translations,
      translationsColumns,
      isDisabled
    } = this.state;
    return (
      <div className="details-view">
        <div className="relative">
          <NavLink
            className="absolute right edit"
            tag={Link}
            to={`/admin/wizard/page/update/${this.props.match.params.id}`}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </NavLink>
        </div>
        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="page.page_details" />
            </Accent>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {this.state.name !== "" ? (
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="name" />
                    </td>
                    <td>{this.state.name}</td>
                  </tr>
                  <tr>
                    <td className="bold">Wizard</td>
                    <td>{this.state.wizardName}</td>
                  </tr>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="page.conditions" />
                    </td>
                    <td>{this.state.condition}</td>
                  </tr>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="page.order" />
                    </td>
                    <td>{this.state.order}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="center-align">
                <InlineLoading />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="page.list_of_panels_in" /> "
              {this.state.name}"
            </Accent>
            <div className="table-container auto-layout">
              <ToolkitProvider
                keyField="id"
                data={this.state.panels}
                columns={this.state.columns}
                search
                exportCSV={{
                  fileName: "custom.csv",
                  separator: ";",
                  ignoreHeader: true,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              CSV
                            </ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/admin/wizard/panel/create/${this.props.match.params.id}`}
                            >
                              <TranslatableText translationKey="create" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                      pagination={paginationFactory()}
                      defaultSorted={defaultSorted}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="page.translations" />
            </Accent>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={translations}
                columns={translationsColumns}
                search
                exportCSV={{
                  fileName: "data.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              CSV
                            </ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/admin/wizard/page/translations/add/${this.props.match.params.id}`}
                              disabled={isDisabled ? true : null}
                            >
                              <TranslatableText translationKey="answeroptionset.add_translation" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              {" "}
              <TranslatableText translationKey="back" />{" "}
            </Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              Confirm Delete?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    {" "}
                    <TranslatableText translationKey="page.confirm_delete_panel_description" />{" "}
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deletePanel(this.state.panelId, e)}
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(PageDetails);
PageDetails.contextType = GlobalContext;