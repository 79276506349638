import { CustomFieldValues, GetCustomEntityValueResponse } from "../../../apiClients";
import { InlineLoading } from "../../../components";
import Accent from "../../../components/accent/Accent";
import TranslatableText from "../../../Language/TranslatableText";

function renderTableHeader() {
    return (
        <tr className="thead">
            <th style={{ width: '30%' }}>
                <TranslatableText translationKey="name" />
            </th>
            <th style={{ width: '70%' }}>
                <TranslatableText translationKey="description" />
            </th>
        </tr>
    );
}

function renderTableData(customField: CustomFieldValues) {
    if (customField.displayTypeCode === 1007)
        if (Array.isArray(customField.tagBoxValues) && customField.tagBoxValues.length > 0) {
            return customField.tagBoxValues.map((field, index) => (
                <tr key={index}>
                    <td>{field.label}</td>
                    <td>
                        {field.description !== undefined &&
                            field.description !== "" &&
                            field.description !== null
                            ? field.description.replace(/<[^>]*>?/gm, "")
                            : field.description}
                    </td>
                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colSpan={2}>
                        <TranslatableText translationKey="no_data_available" />
                    </td>
                </tr>
            );
        }

}

export function EntityExpandedRowRenderer(currentRow: GetCustomEntityValueResponse[], row: GetCustomEntityValueResponse) {
    let currentIndex = currentRow.findIndex(elm => elm.id === row.id);
    currentIndex = (currentIndex === -1 ? currentRow.length : currentIndex);

    return (
        <div>
            {currentRow[currentIndex] === undefined ? (
                <div className="center">
                    <InlineLoading />
                </div>
            ) : (
                <>
                    <p><b><TranslatableText translationKey="name" /> :</b>&nbsp;{currentRow[currentIndex].name}</p>
                    <p><b><TranslatableText translationKey="description" /> :</b>&nbsp;{currentRow[currentIndex].description}</p>
                    {currentRow[currentIndex].customFieldValues?.map((element, key) => element.displayTypeCode !== 1007 ? (
                        <p key={key}><b>{element.displayText} :</b>&nbsp;{element.value}</p>
                    ) : (<></>))}

                    {currentRow[currentIndex].customFieldValues?.map((customField, index) => customField.displayTypeCode === 1007 ? (
                        <div key={index}>
                            <Accent useHtmlIcon={false}>
                                {customField.displayText}
                            </Accent>
                            <table className="table table-bordered ">
                                <thead>{renderTableHeader()}</thead>
                                <tbody>{renderTableData(customField)}</tbody>
                            </table>
                        </div>
                    ) : (<></>))}
                </>
            )}
        </div>
    );
}
