import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Panels from "./Panels";
import CreatePanel from "./CreatePanel";
import PanelDetails from "./PanelDetails";
import AddPanelTranslations from "./AddPanelTranslations";
import PanelTranslations from "./PanelTranslations";
import { withRouter } from "react-router-dom";

class PanelsRouter extends Component {
  render() {
    return (
      <div className="admin-wizard-page-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/create/:pageId`}
            render={props => (
              <CreatePanel {...props} language={this.context.language} />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/create`}
            render={props => (
              <CreatePanel {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <PanelDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreatePanel {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/add/:panelId`}
            render={props => (
              <AddPanelTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/update/:panelId/:translationId`}
            render={props => (
              <AddPanelTranslations
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/translations/:id`}
            render={props => (
              <PanelTranslations {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/admin/wizard/panel"
            render={props => (
              <Panels {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(PanelsRouter);