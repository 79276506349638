import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { UserClaimDto } from "../../apiClients";
import { AccessLevels, AccountLevel, ClaimActionType } from "../../config/Levels";
import { ResourceTypes } from "../../config/ResourceTypes";
import { usePlatformUser, usePlatformUserData } from "../../contexts";

type SecuredComponentProps = {
  children?: React.ReactNode,
  accountLevel?: AccountLevel,
  action?: ClaimActionType,
  resourceType?: string
}
export { AccountLevel, ClaimActionType } from "../../config/Levels";

export default function SecuredComponent(props: SecuredComponentProps) {
  const organisationData = usePlatformUserData();

  if (props.accountLevel !== undefined) {
    return checkAccountLevelAccess(organisationData.currentOrgData.accountLevel, props.accountLevel);
  } else if (props.action !== undefined && props.resourceType !== undefined) {
    return checkClaimAccess(
      organisationData.currentOrgData.claims!,
      props.action,
      props.resourceType,
      organisationData.currentOrgData.accountLevel
    );
  } else {
    return <></>
  }


  function checkClaimAccess(claims: UserClaimDto[], action: ClaimActionType, resourceType: string, userAccountLevel: AccountLevel) {
    
    return <>{props.children}</>;
    
    if (userAccountLevel <= AccessLevels.owner) {
      let row = claims.filter(item => item.regardingObjectTypeId === resourceType && item.action === action );

      if (row.length > 0) {
        return <>{props.children}</>
      }
    } else {
      return <>{props.children}</>
    }
    return <></>
  }

  function checkAccountLevelAccess(userAccountLevel: AccountLevel, accountLevel: AccountLevel) {
    if (userAccountLevel === accountLevel) {
      return <>{props.children}</>
    }
    return <></>
  }
}