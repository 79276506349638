import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Row, Col } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { ExtendedSystems, IExtendedSystems, SystemsClient } from "../../../../apiClients";
import { ScreenLoading } from "../../../../components";
import { FieldErrors, FieldValues, Path, SubmitHandler, useForm, UseFormRegister } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { useLanguageData } from "../../../../contexts";

type CreateSystemParams = {
  id: string | undefined;
};

type AnnoyingTypeDef = ("name" | "url" | "publisher" | "welcomeMailId" | "description" | "settings")[];

export default function CreateSystem() {
  const SystemClient = new SystemsClient();
  const history = useHistory();
  let { id } = useParams<CreateSystemParams>();
  const isAddMode = !id;
  const { languageData } = useLanguageData();
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [currentSystem, setCurrentSystem] = useState<ExtendedSystems>(new ExtendedSystems());
  const { register, handleSubmit, setValue, control, formState, getValues, watch } = useForm<IExtendedSystems>({
    defaultValues: currentSystem,
    mode: "onChange"
  });
  const { errors, isDirty } = formState;

  const onSubmit: SubmitHandler<ExtendedSystems> = data => saveSystem(data);

  React.useEffect(() => {
    const subscription = watch((value) => {
      setRefresh(true)
      setTimeout(() => {
        setRefresh(false)
      }, 1)
    });
    return () => subscription.unsubscribe();
  }, [watch])

  const setValueOptions = { shouldDirty: true, shouldValidate: true };
  useEffect(() => {
    if (isAddMode) {
      setIsLoading(false);
    } else {
      // @ts-ignore
      SystemClient.systemsGetById(id)
        .then(res => {
          const fields: AnnoyingTypeDef = ['name', 'url', 'description', 'welcomeMailId', 'publisher', 'settings'];
          fields.forEach(field => setValue(field, res[field], setValueOptions));

          let _dbSystem = ExtendedSystems.fromSystems(res);

          setValue('systemSettings.ShowLanguageSelect', _dbSystem.systemSettings.ShowLanguageSelect, setValueOptions);
          setValue('systemSettings.wizard_top_navigation', _dbSystem.systemSettings.wizard_top_navigation, setValueOptions);
          setValue('systemSettings.enable_modules_menu', _dbSystem.systemSettings.enable_modules_menu, setValueOptions);
          setValue('systemSettings.enable_compane_admin', _dbSystem.systemSettings.enable_compane_admin, setValueOptions);
          setValue('systemSettings.enable_approvals', _dbSystem.systemSettings.enable_approvals, setValueOptions);
          setValue('systemSettings.enable_flow_engine', _dbSystem.systemSettings.enable_flow_engine, setValueOptions);
          setValue('systemSettings.enable_sharepoint_configuration', _dbSystem.systemSettings.enable_sharepoint_configuration, setValueOptions);
          setValue('systemSettings.wizard_enable_save', _dbSystem.systemSettings.wizard_enable_save, setValueOptions);
          setValue('systemSettings.enable_search_to_wizard', _dbSystem.systemSettings.enable_search_to_wizard, setValueOptions);
          setValue('systemSettings.enable_save_finish_trigger_to_wizard', _dbSystem.systemSettings.enable_save_finish_trigger_to_wizard, setValueOptions);
          setValue('systemSettings.nav_menu_color', _dbSystem.systemSettings.nav_menu_color, setValueOptions);
          setValue('interWizardPageLevel', res.interWizardPageLevel, setValueOptions);
          setValue('interWizardPanelLevel', res.interWizardPanelLevel, setValueOptions);
          setValue('interWizardQuestionLevel', res.interWizardQuestionLevel, setValueOptions);
          setValue('systemSettings.enable_support_btn', _dbSystem.systemSettings.enable_support_btn, setValueOptions);
          setValue('systemSettings.enable_guide_btn', _dbSystem.systemSettings.enable_guide_btn, setValueOptions);
          setValue('systemSettings.guide_url', _dbSystem.systemSettings.guide_url, setValueOptions);
          setValue('systemSettings.entity_report', _dbSystem.systemSettings.entity_report, setValueOptions);

          console.debug(_dbSystem.systemSettings.reporting);
          if (Array.isArray(_dbSystem.systemSettings.reporting)) {
            _dbSystem.systemSettings.reporting = _dbSystem.systemSettings.reporting.join('\n');
          }
          setValue('systemSettings.reporting', _dbSystem.systemSettings.reporting, setValueOptions);
          setCurrentSystem(_dbSystem);
        })
        .catch(error => {
          toast.error(languageData["api_error"]);
          console.debug(error);
        })
        .finally(() => setIsLoading(false))
    }
  }, []);

  const goBack = () => history.goBack();

  function saveSystem(data: ExtendedSystems) {
    if (!Array.isArray(data.systemSettings.reporting)) {
      data.systemSettings.reporting = data.systemSettings.reporting.split('\n');
    }
    data.welcomeMailId = Number(data.welcomeMailId);
    data.settings = JSON.stringify(data.systemSettings);
    toast.info(languageData["api_saving"]);
    console.debug(data)
    if (isAddMode) {
      SystemClient.systemsPost(data)
        .catch(error => {
          toast.error(languageData["api_error"]);
          console.debug(error);
        })
        .finally(() => { toast.dismiss(); goBack() });
    } else {
      // @ts-ignore
      SystemClient.systemsPut(id, data)
        .catch(error => {
          toast.error(languageData["api_error"]);
          console.debug(error);
        })
        .finally(() => { toast.dismiss(); goBack() });
    }
  }

  if (isLoading) {
    return (<ScreenLoading />)
  } else {
    return <div className="create-systems-view">
      <h3>
        <TranslatableText translationKey={isAddMode ? 'create' : 'update'} />
        &nbsp;System
      </h3>
      <Form
        // @ts-ignore
        onSubmit={handleSubmit(onSubmit)}>
        <Row form>
          {FormInput(register, errors, "name", true)}
          {FormInput(register, errors, "url", true)}
          {FormInput(register, errors, "publisher")}
          {FormInput(register, errors, "systemSettings.nav_menu_color")}
          {FormInput(register, errors, "systemSettings.entity_report")}
          {FormInputNumber(register, errors, "welcomeMailId", false)}
          {getValues("systemSettings.enable_guide_btn") &&
            FormInput(register, errors, "systemSettings.guide_url", true)
          }
          {FormTextarea(register, errors, "description", false)}
          {FormInputCheckbox(register, errors, 'systemSettings.ShowLanguageSelect')}
          {FormInputCheckbox(register, errors, 'systemSettings.wizard_top_navigation')}
          {FormInputCheckbox(register, errors, 'systemSettings.enable_modules_menu')}
          {FormInputCheckbox(register, errors, 'systemSettings.enable_compane_admin')}
          {FormInputCheckbox(register, errors, 'systemSettings.enable_approvals')}
          {FormInputCheckbox(register, errors, 'systemSettings.enable_flow_engine')}
          {FormInputCheckbox(register, errors, 'systemSettings.enable_sharepoint_configuration')}
          {FormInputCheckbox(register, errors, 'systemSettings.wizard_enable_save')}
          {FormInputCheckbox(register, errors, 'systemSettings.enable_search_to_wizard')}
          {FormInputCheckbox(register, errors, 'systemSettings.enable_save_finish_trigger_to_wizard')}
          {FormInputCheckbox(register, errors, 'systemSettings.enable_support_btn')}
          {FormInputCheckbox(register, errors, 'systemSettings.enable_guide_btn')}
          {FormInputCheckbox(register, errors, 'interWizardPageLevel')}
          {FormInputCheckbox(register, errors, 'interWizardPanelLevel')}
          {FormInputCheckbox(register, errors, 'interWizardQuestionLevel')}

          {FormTextarea(register, errors, "systemSettings.reporting")}
          <Col className="space-between flex" md={12}>
            <Button onClick={() => goBack()}>
              <TranslatableText translationKey="back_to_list" />
            </Button>
            {/* <DevTool control={control} placement='top-right' /> */}
            <Button type='submit' disabled={!isDirty}>
              <FontAwesomeIcon icon={faSave} />&nbsp;<TranslatableText translationKey={isAddMode ? 'create' : 'update'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </div>;
  }
}

function FormInput<TFieldValues extends FieldValues>(register: UseFormRegister<TFieldValues>, errors: FieldErrors<TFieldValues>, name: Path<TFieldValues>, required = false) {
  return <Col md={4}>
    <FormGroup className={errors[name] !== undefined ? 'has-error' : ''}>
      <Label for={name.toString()}>
        <TranslatableText translationKey={name.toString()} />
      </Label>
      <input type='text' {...register(name, { required: required })} className={`form-control ${errors[name] ? 'is-invalid' : ''}`} />
      {/* <span className='help-block'>{errors[name]?.message}</span> */}
    </FormGroup>
  </Col>;
}
function FormInputNumber<TFieldValues extends FieldValues>(register: UseFormRegister<TFieldValues>, errors: FieldErrors<TFieldValues>, name: Path<TFieldValues>, required = false) {
  return <Col md={4}>
    <FormGroup className={errors[name] !== undefined ? 'has-error' : ''}>
      <Label for={name.toString()}>
        <TranslatableText translationKey={name.toString()} />
      </Label>
      <input type='number' {...register(name, { required: required })} className={`form-control ${errors[name] ? 'is-invalid' : ''}`} />
      {/* <span className='help-block'>{errors[name]?.message}</span> */}
    </FormGroup>
  </Col>;
}
function FormInputCheckbox<TFieldValues extends FieldValues>(register: UseFormRegister<TFieldValues>, errors: FieldErrors<TFieldValues>, name: Path<TFieldValues>, required = false) {
  return <Col md={4}>
    <FormGroup className={`custom-control custom-switch ${errors[name] !== undefined ? 'has-error' : ''}`}>

      <input type='checkbox'
        id={name.toString()}
        {...register(name, { required: required })}
        className={`custom-control-input ${errors[name] ? 'is-invalid' : ''}`}
      />
      <Label for={name.toString()} className={'custom-control-label'} style={{ lineBreak: 'anywhere' }}>
        <TranslatableText translationKey={name.toString()} />
      </Label>
      {/* <span className='help-block'>{errors[name]?.message}</span> */}
    </FormGroup>
  </Col>;
}
function FormTextarea<TFieldValues extends FieldValues>(register: UseFormRegister<TFieldValues>, errors: FieldErrors<TFieldValues>, name: Path<TFieldValues>, required = false) {
  return <Col md={12}>
    <FormGroup className={errors[name] !== undefined ? 'has-error' : ''} >
      <Label for={name.toString()}>
        <TranslatableText translationKey={name.toString()} />
      </Label>
      <textarea {...register(name, { required: required })} rows={4} className={`form-control ${errors[name] ? 'is-invalid' : ''}`} />
      {/* <span className='help-block'>{errors[name]?.message}</span> */}
    </FormGroup>
  </Col>;
}