import React from "react";
import { FormGroup, Label, Input, Col } from "reactstrap";
import LookupField from "./LookupField";
import MultiSelect from "./MultiSelect";

let Fields = ({
  componentState,
  customFields,
  changeValidationMessage,
  handleChange,
  handleTagboxChange
}) => {
  let field;
  return (
    <Col className="dynamic-fields" md={12}>
      {customFields.map((e, key) => {
        field =
          e.displayType.toLowerCase() === "twooption" ? (
            <FormGroup key={e.id}>
              <Label className="block">{e.displayText}</Label>
              <div className="custom-switch">
                <Input
                  id={`toggle-${e.name}`}
                  className="toggle"
                  type="checkbox"
                  name={e.name}
                  checked={
                    typeof componentState[e.name] === "boolean"
                      ? componentState[e.name]
                      : componentState[e.name] === "true"
                      ? true
                      : componentState[e.name] === "false"
                      ? false
                      : ""
                  }
                  onChange={e => handleChange(e, key)}
                  required={e.isRequired}
                  onInvalid={event =>
                    changeValidationMessage(e.validationMessage, event)
                  }
                  onInput={event => changeValidationMessage("", event)}
                  disabled={componentState["lock"] ? true : null}
                ></Input>
                <label htmlFor={`toggle-${e.name}`}>
                  {/* TODO: Language */}
                  <span className="on">Ja&nbsp;</span>
                  <span className="off">Nej</span>
                </label>
              </div>
            </FormGroup>
          ) : e.displayType.toLowerCase() === "lookup" ? (
            <LookupField
              index={key}
              key={e.id}
              element={e}
              componentState={componentState}
              handleChange={handleChange}
              changeValidationMessage={changeValidationMessage}
              changeValidationMessage={changeValidationMessage}
            />
          ) : e.displayType.toLowerCase() === "tagbox" ? (
            <MultiSelect
              index={key}
              key={e.id}
              element={e}
              componentState={componentState}
              handleTagboxChange={handleTagboxChange}
              changeValidationMessage={changeValidationMessage}
              changeValidationMessage={changeValidationMessage}
            />
          ) : (
            <FormGroup
              className={
                e.displayType.toLowerCase() === "textarea" ? "full-flex" : ""
              }
              key={e.id}
            >
              <Label for={e.name}>{e.displayText}</Label>
              <Input
                type={e.displayType.toLowerCase()}
                name={e.name}
                id={e.name}
                value={componentState[e.name] || ""}
                onChange={e => handleChange(e, key)}
                required={e.isRequired}
                onInvalid={event =>
                  changeValidationMessage(e.validationMessage, event)
                }
                onInput={event => changeValidationMessage("", event)}
                disabled={componentState["lock"] ? true : null}
              ></Input>
              <span className="help-block"></span>
            </FormGroup>
          );

        return field;
      })}
    </Col>
  );
};

export default Fields;
