import { BasisNavigation, DigitalAdvokatNavigation, DPOAdvisorNavigation, KDSysNavigation, RevisormappenNavigation, CISOAdvisorNavigation, BruunRasmussenNavigation, TristadNavigation } from './platform'

export function SystemNavigation(props: { systemName: string; menuItemClick: () => void }) {
    switch (props.systemName) {
        case 'DPO Advisor':
            return DPOAdvisorNavigation(props.menuItemClick);
        case 'Revisormappen':
            return RevisormappenNavigation(props.menuItemClick);
        case 'KD-Sys 360':
            return KDSysNavigation(props.menuItemClick);
        case 'Digital Jura':
            return DigitalAdvokatNavigation(props.menuItemClick);
        case 'Tristad':
            return TristadNavigation(props.menuItemClick);
        case 'CISO Advisor':
            return CISOAdvisorNavigation(props.menuItemClick);
        case 'Bruun Rasmussen':
            return BruunRasmussenNavigation(props.menuItemClick);
        default:
            return BasisNavigation(props.menuItemClick);
    }
}
