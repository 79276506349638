import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  createAttachmentAPI,
  getAttachmentByIdAPI,
  updateAttachmentAPI,
  downloadAttachmentByIdAPI
} from "./attachmentsAPI";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import FormValidator from "../../components/validator/FormValidator";
import "../../components/validator/FormValidator.scss";
import TranslatableText from "../../Language/TranslatableText";
import { toast } from "react-toastify";
import { GlobalContext } from "../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../components";



class CreateAttachment extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
      }
    ]);

    this.state = {
      file: "",
      name: "",
      description: "",
      contentType: "",
      size: 0,
      url: "",
      fileURL: "",
      expiresOn: "2019-09-29T08:26:56.253Z",
      regardingObjectId: "",
      regardingObjectTypeCode: "",
      loading: false,
      cuLoader: false,
      validation: this.validator.valid(),
      checkAttachment: 0
    };

    this.submitted = false;
  }
  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getAttachmentByIdAPI(this.props.match.params.id)
        .then(res => {
          if (res.data.contentType !== null && res.data.contentType !== "") {
            downloadAttachmentByIdAPI(this.props.match.params.id)
              .then(res => {
                var response = res.data;
                const url = window.URL.createObjectURL(
                  new Blob([response], { type: res.headers["content-type"] })
                );
                this.setState({ fileURL: url, checkAttachment: 1 });
              })
              .catch(error => {
                toast.error(this.context.languages["api_error"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000
                });
                this.setState({ checkAttachment: 1 });
              });
          } else {
            this.setState({ checkAttachment: 1 });
          }
          this.setState({
            id: res.data.id,
            name: res.data.name === null ? "" : res.data.name,
            contentType:
              res.data.contentType === null ? "" : res.data.contentType,
            description:
              res.data.description === null ? "" : res.data.description,
            size: res.data.size === null ? "" : res.data.size,
            url: res.data.url === null ? "" : res.data.url,
            expiresOn: res.data.expiresOn === null ? "" : res.data.expiresOn,
            regardingObjectId: res.data.regardingObjectId,
            regardingObjectTypeCode: this.getType(this.props.match.params.type),
            organizationId:
              res.data.organizationId === null ? "" : res.data.organizationId,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    } else {
      this.setState({ checkAttachment: 1 });
    }
  }
  handleStartDate = date => {
    this.setState({
      startDatePicker: date,
      startDate: date.toISOString()
    });
  };

  search = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === key) {
        return array[i];
      }
    }
  };

  dateFormatter = timestamp => {
    return new Date(timestamp);
  };

  stringToTimestamp = time => {
    var d = new Date(), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
      dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
      timestamp;

    // ie: 2014-03-24, 3:00 PM
    timestamp = yyyy + "-" + mm + "-" + dd + " " + time;
    return new Date(timestamp);
  };

  getType = type => {
    if (type === "assets") {
      return 1003;
    } else if (type === "processes") {
      return 1001;
    } else if (type === "sections") {
      return 1002;
    } else {
      return 1000;
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  createAttachment = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      name: this.state.name,
      description: this.state.description,
      contentType: this.state.contentType,
      size: this.state.size,
      url: this.state.url,
      expiresOn: this.state.expiresOn,
      organizationId: this.context?.user.organizations.organizationId,
      regardingObjectId: this.props.match.params.regardingObjectId,
      regardingObjectTypeCode: this.getType(this.props.match.params.type),
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      userId: this.context.user.organizations.userId
    };

    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("url", data.url);
    formData.append("expiresOn", data.expiresOn);
    formData.append("organizationId", data.organizationId);
    formData.append("regardingObjectId", data.regardingObjectId);
    formData.append("regardingObjectTypeCode", data.regardingObjectTypeCode.toString());
    formData.append("file", this.state.file);
    formData.append("id", data.id);
    formData.append("userId", data.userId);

    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateAttachmentAPI(formData)
          .then(res => {
            this.setState({ cuLoader: false }, () => {
              this.goBack();
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createAttachmentAPI(formData)
          .then(res => {
            this.setState({ cuLoader: false }, () => {
              this.goBack();
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.type === "file") {
      this.setState({
        file: target.files[0]
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-attachment-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" />
            : <TranslatableText translationKey="create" />}{" "}
          <TranslatableText translationKey="attachments.attachment" />
        </h3>
        <Form onSubmit={this.createAttachment}>
          <Row form>
            <Col md={12}>
              <FormGroup
                // @ts-ignore
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  <TranslatableText translationKey="name" />
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <span className="help-block">{
                  // @ts-ignore
                  validation.name.message}</span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="name">
                  <TranslatableText translationKey="url" />
                </Label>
                <Input
                  type="text"
                  name="url"
                  id="url"
                  placeholder=""
                  value={this.state.url}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            {this.state.checkAttachment === 0 ? (
              <Col md={6}>
                <FormGroup>
                  <Label for="file">
                    <TranslatableText translationKey="attachments.attachment" />
                  </Label>
                  <InlineLoading />
                </FormGroup>
              </Col>
            ) : (
              <Col md={6}>
                {this.state.fileURL !== "" ? (
                  <FormGroup>
                    <Label for="file">
                      <TranslatableText translationKey="attachments.attachment" />
                    </Label>
                    <a
                      className="attachment"
                      href={this.state.fileURL}
                      download={this.props.match.params.id}
                    >
                      <TranslatableText translationKey="attachments.download_attachment" />
                    </a>
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label for="file">
                      <TranslatableText translationKey="attachments.attach_file" />
                    </Label>
                    <Input
                      className="attachment-input"
                      type="file"
                      name="file"
                      id="file"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                )}
              </Col>
            )}

            <Col md={12}>
              <FormGroup>
                <Label for="description">
                  <TranslatableText translationKey="description" />
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  placeholder=""
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>

            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ? <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateAttachment);
CreateAttachment.contextType = GlobalContext;