import {
  faBoxFull,
  faBriefcase, faCaretDown, faCity, faColumns, faDesktop, faHandshake, faHatWizard, faNewspaper, faQuestionCircle, faTh, faUsers
} from "@fortawesome/pro-solid-svg-icons";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import Gap from "../../components/gap/Gap";
import Tile from "../../components/tile/Tile";
import TranslatableText from "../../Language/TranslatableText";
import "./Admin.scss";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="admin-view">
        <h4 className="admin-title">
          <TranslatableText translationKey="company_and_partner" />
        </h4>
        <div className="flex space-between tiles-container">
          <Tile
            className="custom-tile"
            title="partners"
            subTitle="Manage Partners"
            linkTo={`${this.props.match.url}/partners`}
            icon={faHandshake}
            iconSize="5x"
            backgroundColor="#3498db"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="company"
            subTitle="Manage Companies"
            linkTo={`${this.props.match.url}/company`}
            icon={faCity}
            iconSize="5x"
            backgroundColor="#17a2b8"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="projects"
            subTitle="Manage Projects"
            linkTo={`${this.props.match.url}/projects`}
            icon={faBriefcase}
            iconSize="5x"
            backgroundColor="#00bfad"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="systems"
            subTitle="Manage Systems"
            linkTo={`${this.props.match.url}/systems`}
            icon={faDesktop}
            iconSize="5x"
            backgroundColor="#3498db"
            textColor="#fff"
          />
        </div>
        <h4 className="admin-title">Wizard</h4>
        <div className="flex space-between tiles-container">
          <Tile
            className="custom-tile"
            title="wizard"
            subTitle="Manage Wizard"
            linkTo={`${this.props.match.url}/wizard`}
            icon={faHatWizard}
            iconSize="5x"
            backgroundColor="#3498db"
            textColor="#fff"
          />
          <Tile
            className="custom-tile"
            title="wizard_page"
            subTitle="Manage Wizard Pages"
            linkTo={`${this.props.match.url}/wizard/page`}
            icon={faNewspaper}
            iconSize="5x"
            backgroundColor="#3498db"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="wizard_panels"
            subTitle="Manage Wizard Panels"
            linkTo={`${this.props.match.url}/wizard/panel`}
            icon={faColumns}
            iconSize="5x"
            backgroundColor="#17a2b8"
            textColor="#fff"
          />

          <Tile
            className="custom-tile"
            title="wizard_question"
            subTitle="Manage Wizard Questions"
            linkTo={`${this.props.match.url}/wizard/question`}
            icon={faQuestionCircle}
            iconSize="5x"
            backgroundColor="#0aa753"
            textColor="#fff"
          />
        </div>
        <div className="flex space-between tiles-container mt-20">
          <Tile
            className="custom-tile"
            title="answer_option_set"
            subTitle="Answer Options Set"
            linkTo={`${this.props.match.url}/wizard/answeroptionset`}
            icon={faCaretDown}
            iconSize="5x"
            backgroundColor="#055388"
            textColor="#fff"
          />
          <Tile
            className="custom-tile"
            title="users"
            subTitle="Manage Users"
            linkTo={`${this.props.match.url}/users`}
            icon={faUsers}
            iconSize="5x"
            backgroundColor="#6f42c1"
            textColor="#fff"
          />
          <Tile
            className="custom-tile"
            title="custom_entities"
            subTitle="Manage Custom Entities"
            linkTo={`${this.props.match.url}/custom-entities`}
            icon={faBoxFull}
            iconSize="5x"
            backgroundColor="#77beed"
            textColor="#fff"
          />
          <Tile
            className="custom-tile"
            title="custom_fields"
            subTitle="Manage Custom Fields"
            linkTo={`${this.props.match.url}/custom-fields`}
            icon={faTh}
            iconSize="5x"
            backgroundColor="#9777ed"
            textColor="#fff"
          />
        </div>
        <Gap size="50px" />
      </div>
    );
  }
}

export default withRouter(Admin);
