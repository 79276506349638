import React, { Component } from "react";
import {
  Button,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { createPageAPI, getPageByIdAPI, updatePageAPI } from "./pageAPI";
import { getWizardEntitiesAPI, getWizardsAPI } from "../../wizardAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faTrash, faEdit } from "@fortawesome/pro-solid-svg-icons";
import FormValidator from "../../../../../../components/validator/FormValidator";
import "../../../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import {
  getQuestionsByWizardIdAPI,
  getPlatformEntitesAPI
} from "../questions/questionsAPI";
import Swal from "sweetalert2";
import Accent from "../../../../../../components/accent/Accent";
import { getEntitiesAPI } from "../../../../../entity/entityAPI";
import { getBooleanCustomFieldsAPI } from "../../../../../company-admin/views/custom-fields/customFieldsCompanyAPI";
import Select from "react-select";
import { Config } from "../../../../../../config/Config";
import ConditionsBuilder from "../common/conditionsBuilder/ConditionsBuilder";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../../../components";




let conditionsArray = [];

class CreatePage extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "wizardId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "order",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "title",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      groupId: -1,
      moduleConditions: [],
      groupOperator: "and",
      relationToWizardConditionOperator: "and",
      regardingObjectTypeIdDropdown: [],
      wizardDropdown: [],
      questionDropdown: [],
      questionsModal: false,
      question: "",
      name: "",
      condition: "",
      order: "",
      wizardId: "",
      conditions: [],
      columns: [
        {
          dataField: "moduleName",
          text: "Module",
          sort: true
        },
        {
          dataField: "entityRecordName",
          text: "Entity",
          sort: true
        },
        {
          dataField: "customFieldName",
          text: "Custom Field",
          sort: true
        },
        {
          dataField: "value",
          text: "Value",
          sort: true
        },
        {
          dataField: "actions",
          text: "Action",

          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink onClick={e => this.editRecord(row.id, e)}>
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
                <NavLink onClick={e => this.deleteRecord(row.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
          }
        }
      ],
      moduleDropdown: [],
      entityRecordDropdown: [],
      customFieldDropdown: [],
      conditionId: "",
      moduleId: "",
      moduleName: "",
      entityRecordId: "",
      entityRecordName: "",
      customFieldId: "",
      customFieldName: "",
      value: "",
      loading: false,
      tableLoader: false,
      cuLoader: false,
      validation: this.validator.valid(),
      copyConditionsModal: false,
      conditionType: "",
      selectedConditionType: "",
      conditionTypes: [
        { label: "Pages", value: "1002" },
        { label: "Panels", value: "1003" },
        { label: "Questions", value: "1004" }
      ],
      conditionEntity: "",
      conditionEntities: [],
      selectedConditionEntity: "",
      fetchingConditionEntities: false,
      updateCondition: false,
      recordId: "",
      conditionWizard: "",
      conditionWizards: [],
      selectedConditionWizard: "",
      title: "",
      description: "",
      questionsWizard: "",
      selectedQuestionsWizard: "",
      language: this.context?.user.organizations.defaultLanguage,
      languageDropdown: Config.languages
    };

    this.submitted = false;
  }

  componentWillUnmount() {
    conditionsArray = [];
  }

  componentDidMount() {
    this.populateEntities();
    getWizardsAPI()
      .then(res => {
        let wizards = res.map(wizard => ({
          label: wizard.name,
          value: wizard.id
        }));
        this.setState({
          wizardDropdown: Array.isArray(res) ? res : [],
          conditionWizards: wizards
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getPageByIdAPI(this.props.match.params.id)
        .then(res => {
          // Older version - will be removed in future
          // conditionsArray =
          //   res.data.moduleConditions === null ||
          //   res.data.moduleConditions === "" ||
          //   res.data.moduleConditions === undefined
          //     ? []
          //     : JSON.parse(res.data.moduleConditions);

          let modConditions =
            res.data.moduleConditionsV1 === null ||
            res.data.moduleConditionsV1 === "" ||
            res.data.moduleConditionsV1 === undefined
              ? []
              : JSON.parse(res.data.moduleConditionsV1);

          // Backward compatibilty
          const moduleConditions = [conditionsArray];
          const moduleConditionsJSON = moduleConditions.map(condition => {
            return {
              id: this.uuidv4(),
              children: condition,
              operator: "and"
            };
          });
          // Backward compatibilty

          this.setState({
            order: res.data.order,
            condition: res.data.condition,
            name: res.data.name,
            wizardId: res.data.wizardId,
            relationToWizardConditionOperator:
              res.data.relationToWizardConditionOperator,
            groupOperator:
              modConditions.length === 0 ? "and" : modConditions.operator,
            moduleConditions:
              modConditions.length === 0 ? [] : modConditions.groups,
            title: "0", // bypass validations incase of update since this field is not available to update
            language: this.context.user.organizations.defaultLanguage, // bypass validations incase of update since this field is not available to update
            loading: false
          });
          if (res.data.wizardId !== "" && res.data.wizardId !== null) {
            this.populateQuestionsDropdown(res.data.wizardId);
          }
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    } else if (this.props.match.params.wizardId !== undefined) {
      this.populateQuestionsDropdown(this.props.match.params.wizardId);
      this.setState({
        wizardId: this.props.match.params.wizardId
      });
    }
  }

  populateEntities = () => {
    getPlatformEntitesAPI()
      .then(res => {
        this.setState({
          regardingObjectTypeIdDropdown: Array.isArray(res) ? res : []
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  populateCustomFields = id => {
    this.setState({ customFieldDropdown: [], fetchingCustomFields: true });
    getBooleanCustomFieldsAPI(id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        if (res.length === 0) {
          this.setState({ fetchingCustomFields: false }, () => {
            Swal.fire({
              icon: "error",
              title: this.context.languages["swal.oops"],
              text: this.context.languages["swal.oops_entity_boolean"]
            });
          });
        } else {
          this.setState({
            customFieldDropdown: res,
            fetchingCustomFields: false
          });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  populateEntityRecords = id => {
    this.setState({ entityRecordDropdown: [], fetchingModuleRecords: true });
    getEntitiesAPI(id)
      .then(res => {
        if (res !== undefined && Array.isArray(res)) {
          if (res.length === 0) {
            this.setState(
              {
                fetchingModuleRecords: false
              },
              () => {
                Swal.fire({
                  icon: "error",
                  title: this.context.languages["swal.oops"],
                  text: this.context.languages["swal.oops.select_another"]
                });
              }
            );
          } else {
            this.setState({
              entityRecordDropdown: res,
              fetchingModuleRecords: false
            });
          }
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          toast.error(this.context.languages["insufficient_permission"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          });
        } else {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          });
        }
        this.setState({ tableLoader: false });
      });
  };

  editRecord = id => {
    this.setState(
      { loading: true, updateCondition: true, recordId: id },
      () => {
        let record = this.searchInnerArray(id, this.state.moduleConditions);
        this.populateEntityRecords(record.moduleId);
        this.populateCustomFields(record.moduleId);
        this.setState(
          {
            moduleId: record.moduleId,
            moduleName: record.moduleName,
            entityRecordId: record.entityRecordId,
            entityRecordName: record.entityRecordName,
            customFieldId: record.customFieldId,
            customFieldName: record.customFieldName,
            value: record.value,
            loading: false
          },
          () => {
            this.toggleConditionModal();
          }
        );
      }
    );
  };

  deleteRecord = id => {
    Swal.fire({
      title: this.context.languages["swal.are_you_sure"],
      text: this.context.languages["swal.are_you_sure.delete_record"],
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.context.languages[
        "swal.are_you_sure.confirmButtonText"
      ],
      cancelButtonText: this.context.languages[
        "swal.are_you_sure.cancelButtonText"
      ]
    }).then(result => {
      if (result.value) {
        let record = this.searchInnerArray(id, this.state.moduleConditions);
        let groups = [...this.state.moduleConditions];
        for (var i = 0; i < groups.length; i++) {
          for (var j = 0; j < groups[i].children.length; j++) {
            if (groups[i].children[j].id === id) {
              const index = groups[i].children.indexOf(record);
              if (index >= 0) {
                groups[i].children.splice(index, 1);
              }
            }
          }
        }

        this.setState({
          moduleConditions: groups,
          tableLoader: false
        });
        Swal.fire(
          this.context.languages["swal.deleted"],
          this.context.languages["swal.deleted_record_deleted"],
          "success"
        );
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.setState(
          {
            moduleConditions: this.state.moduleConditions,
            tableLoader: false
          },
          () => {
            Swal.fire(
              this.context.languages["swal.cancelled"],
              this.context.languages["swal.cancelled_your_record_is_safe"],
              "error"
            );
          }
        );
      }
    });
  };

  toggleWizardQuestionsModal = (type = "") => {
    this.setState(
      prevState => ({
        questionsModal: !prevState.questionsModal,
        type: type
      }),
      () => {
        if (!this.state.questionsModal) {
          if (Config.wizard_based_conditions) {
            this.setState({
              selectedQuestionsWizard: "",
              questionDropdown: [],
              question: "",
              type: ""
            });
          } else {
            this.setState({
              type: "",
              question: ""
            });
          }
        }
      }
    );
  };

  toggleConditionModal = groupId => {
    console.log("groupId.........", groupId);
    this.setState(
      prevState => ({
        conditionModal: !prevState.conditionModal,
        groupId: groupId
      }),
      () => {
        if (!this.state.conditionModal) {
          this.setState({
            value: "",
            moduleId: "",
            entityRecordId: "",
            customFieldId: "",
            entityRecordDropdown: [],
            customFieldDropdown: [],
            updateCondition: false,
            recordId: "",
            groupId: -1
          });
        }
      }
    );
  };

  search = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === key) {
        return array[i];
      }
    }
  };

  searchInnerArray = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < array[i].children.length; j++) {
        if (array[i].children[j].id === key) {
          return array[i].children[j];
        }
      }
    }
  };

  insertQuestionID = e => {
    e.preventDefault();
    var getConditionValue = this.state.condition;
    var setConditionValue = getConditionValue.concat(
      `{${this.state.question}}`
    );
    this.setState({ condition: setConditionValue, question: "" }, () => {
      this.toggleWizardQuestionsModal();
    });
  };

  populateQuestionsDropdown = wizardID => {
    getQuestionsByWizardIdAPI(wizardID)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        if (!Config.wizard_based_conditions) {
          this.setState({ questionDropdown: res });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  createPage = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    let moduleConditionsV1 = {
      groups: this.state.moduleConditions,
      operator: this.state.groupOperator
    };

    const data = {
      ...this.state,
      moduleConditions: JSON.stringify([]),
      moduleConditionsV1:
        this.state.moduleConditions.length === 0
          ? null
          : JSON.stringify(moduleConditionsV1),
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      userId: this.context.user.organizations.userId
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updatePageAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createPageAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "wizardId") {
      if (value !== "") {
        this.populateQuestionsDropdown(value);
      } else {
        if (!Config.wizard_based_conditions) {
          this.setState({ questionDropdown: [] });
        }
      }
    }

    this.setState({
      [name]: value
    });
  };

  handleConditionsChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "moduleId") {
      if (value !== "") {
        this.populateEntityRecords(value);
        let selected = this.search(
          value,
          this.state.regardingObjectTypeIdDropdown
        );
        this.setState({
          moduleName: selected.name
        });
      } else {
        this.setState({
          moduleName: ""
        });
      }
    }

    if (name === "entityRecordId") {
      this.populateCustomFields(this.state.moduleId);
      let selected = this.search(value, this.state.entityRecordDropdown);
      this.setState({
        entityRecordName: selected.name
      });
    }

    if (name === "customFieldId") {
      if (value !== "") {
        let selected = this.search(value, this.state.customFieldDropdown);
        this.setState({
          customFieldName: selected.name
        });
      } else {
        this.setState({
          customFieldName: ""
        });
      }
    }

    this.setState({
      [name]: value
    });
  };

  insertModuleConditions = e => {
    e.preventDefault();
    let groups = [...this.state.moduleConditions];
    if (
      this.state.moduleId !== "" &&
      this.state.entityRecordId !== "" &&
      this.state.customFieldId !== "" &&
      this.state.value !== ""
    ) {
      let condition = {
        id: !this.state.updateCondition ? this.uuidv4() : this.state.recordId,
        moduleId: this.state.moduleId,
        moduleName: this.state.moduleName,
        entityRecordId: this.state.entityRecordId,
        entityRecordName: this.state.entityRecordName,
        customFieldId: this.state.customFieldId,
        customFieldName: this.state.customFieldName,
        value: this.state.value
      };
      if (!this.state.updateCondition) {
        for (var i = 0; i < groups.length; i++) {
          if (this.state.groupId === groups[i].id) {
            groups[i].children.push(condition);
          }
        }

        // Previous way
        // groupSet = groups.map(group => ({
        //   ...group,
        //   children:
        //     this.state.groupId === group.id
        //       ? group.children.push(condition)
        //       : group.children
        // }));
      } else {
        for (var i = 0; i < groups.length; i++) {
          for (var j = 0; j < groups[i].children.length; j++) {
            if (groups[i].children[j].id === this.state.recordId) {
              groups[i].children[j] = condition;
            }
          }
        }
      }
      this.setState({ moduleConditions: groups, tableLoader: false }, () => {
        this.toggleConditionModal();
      });
    } else {
      Swal.fire({
        icon: "error",
        title: this.context.languages["swal.oops"],
        text: this.context.languages["swal.oops.all_fields_required"]
      });
    }
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  goBack = () => {
    this.props.history.goBack();
  };

  toggleCopyConditionsModal = () => {
    this.setState(
      prevState => ({
        copyConditionsModal: !prevState.copyConditionsModal
      }),
      () => {
        if (!this.state.copyConditionsModal) {
          this.setState({
            selectedConditionType: "",
            conditionType: "",
            conditionEntities: [],
            selectedConditionEntity: "",
            conditionEntity: "",
            conditionWizard: "",
            selectedConditionWizard: ""
          });
        }
      }
    );
  };

  handleTagboxChange = (state, selectedStateName, selectedOption) => {
    this.setState({
      [state]: selectedOption.value,
      [selectedStateName]: selectedOption === null ? "" : selectedOption
    });
    if (state === "conditionWizard") {
      this.setState({
        conditionEntities: [],
        selectedConditionEntity: "",
        conditionEntity: "",
        conditionType: "",
        selectedConditionType: ""
      });
    }
    if (state === "conditionType") {
      this.populateConditionsEntity(selectedOption.value);
    }
    if (state === "questionsWizard") {
      this.populateQuestionsByWizardID(selectedOption.value);
    }
  };

  populateQuestionsByWizardID = wizardID => {
    getQuestionsByWizardIdAPI(wizardID)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ questionDropdown: res });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  populateConditionsEntity = entity => {
    this.setState({ fetchingConditionEntities: true });
    getWizardEntitiesAPI(this.state.conditionWizard, entity)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        let entities = res.map(item => ({
          label: item.name,
          value:
            item.moduleConditions === null ? item.id : item.moduleConditions,
          isDisabled: item.moduleConditions === null ? true : false
        }));
        this.setState({
          conditionEntities: entities,
          fetchingConditionEntities: false
        });
      })
      .catch(error => {
        this.setState({ fetchingConditionEntities: false });
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  copyConditions = e => {
    e.preventDefault();
    conditionsArray = [];
    if (this.state.conditionType !== "" && this.state.conditionEntity !== "") {
      this.setState({ tableLoader: true, conditions: [] }, () => {
        conditionsArray = JSON.parse(this.state.conditionEntity);
        this.setState(
          { conditions: conditionsArray, tableLoader: false },
          () => {
            this.toggleCopyConditionsModal();
          }
        );
      });
    } else {
      Swal.fire({
        icon: "error",
        title: this.context.languages["swal.oops"],
        text: this.context.languages["swal.oops.all_fields_required"]
      });
    }
  };

  onBuilderChange = data => {
    console.log("Inside method: ", data);
  };

  renderTableData = records => {
    if (records.length > 0) {
      return records.map(record => (
        <tr key={record.id}>
          <td>{record.moduleName}</td>
          <td>{record.entityRecordName}</td>
          <td>{record.customFieldName}</td>
          <td>{record.value}</td>
          <td>
            <div className="actions">
              <NavLink onClick={e => this.editRecord(record.id, e)}>
                <FontAwesomeIcon icon={faEdit} size="1x" />
              </NavLink>
              <NavLink onClick={e => this.deleteRecord(record.id, e)}>
                <FontAwesomeIcon
                  style={{ color: "#d84a4a" }}
                  icon={faTrash}
                  size="1x"
                />
              </NavLink>
            </div>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="5">
            <TranslatableText translationKey="no_data_available" />
          </td>
        </tr>
      );
    }
  };
  renderTableHeader = () => {
    return (
      <tr className="thead">
        <th>
          <TranslatableText translationKey="module" />
        </th>
        <th>
          <TranslatableText translationKey="resource" />
        </th>
        <th>
          <TranslatableText translationKey="customField" />
        </th>
        <th style={{width: '5%'}}>
          <TranslatableText translationKey="value" />
        </th>
        <th style={{width: '5%'}}>
          <TranslatableText translationKey="actions" />
        </th>
      </tr>
    );
  };

  addGroup = e => {
    e.preventDefault();
    // [...] is to resolve the array mutation issue
    let groups = [...this.state.moduleConditions];
    groups.push({
      id: this.uuidv4(),
      children: [],
      operator: "and"
    });
    this.setState({ moduleConditions: groups });
  };

  deleteGroup = (index, e) => {
    e.preventDefault();
    let groups = this.state.moduleConditions;
    // let clonedGroups = [...groups];
    // let toDelete = clonedGroups[index];
    // this.setState({
    //   moduleConditions: clonedGroups.filter(item => item !== toDelete)
    // });
    const moduleConditions = groups.filter((item, i) => i !== index);
    this.setState({
      moduleConditions
    });
  };

  handleDynamicFieldChange = (groupId, e) => {
    const { name, value } = e.target;
    let moduleConditions = this.state.moduleConditions;
    for (var i = 0; i < moduleConditions.length; i++) {
      if (moduleConditions[i].id === groupId) {
        moduleConditions[i].operator = value;
      }
    }

    this.setState({
      moduleConditions
    });
  };

  render() {
    let { loading, tableLoader } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-partner-view">
        {loading && (
          <ScreenLoading />
        )}
        <Row>
          <Col>
            <h3>
              {this.props.match.params.id !== undefined ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}{" "}
              <TranslatableText translationKey="page" />
            </h3>
          </Col>
        </Row>
        <Form onSubmit={this.createPage}>
          <Row className="panel" form>
            <Col className="panel-header" md={12}>
              <TranslatableText translationKey="page.system_information" />
            </Col>
            <Col className="panel-body" md={12}>
              <Row form>
                <Col md={6}>
                  <FormGroup
                    className={validation.name.isInvalid ? "has-error" : ""}
                  >
                    <Label for="name">
                      <TranslatableText translationKey="name" />
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder=""
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.name.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup
                    className={validation.wizardId.isInvalid ? "has-error" : ""}
                  >
                    <Label for="wizardId">Wizard</Label>
                    <Input
                      type="select"
                      name="wizardId"
                      id="wizardId"
                      value={this.state.wizardId}
                      onChange={this.handleChange}
                      disabled={
                        this.props.match.params.wizardId !== undefined
                          ? true
                          : null
                      }
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.wizardDropdown.map((e, key) => {
                        return (
                          <option key={e.id} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                    <span className="help-block">
                      {validation.wizardId.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup
                    className={validation.order.isInvalid ? "has-error" : ""}
                  >
                    <Label for="order">
                      <TranslatableText translationKey="page.order" />
                    </Label>
                    <Input
                      type="number"
                      name="order"
                      id="order"
                      placeholder=""
                      value={this.state.order}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.order.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="condition">
                      <TranslatableText translationKey="page.condition" />
                    </Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="condition"
                        id="condition"
                        placeholder=""
                        value={this.state.condition}
                        onChange={this.handleChange}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          disabled={
                            this.state.wizardId === "" &&
                            !Config.wizard_based_conditions
                              ? true
                              : false
                          }
                          color="secondary"
                          onClick={e => this.toggleWizardQuestionsModal()}
                        >
                          <FontAwesomeIcon icon={faLink} size="1x" />{" "}
                          <TranslatableText translationKey="page.insert_question_id" />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <Accent useHtmlIcon={false}>
                    {" "}
                    <TranslatableText translationKey="page.module_conditions" />
                  </Accent>
                  <FormGroup>
                    <Label for="condition">
                      <TranslatableText translationKey="relation_to_wizard_condition" />
                    </Label>
                    <div className="flex">
                      <div className="stv-radio-buttons-wrapper two">
                        <span>
                          <input
                            type="radio"
                            className="stv-radio-button"
                            name="relationToWizardConditionOperator"
                            value="and"
                            id="wizard-condition-and-relation"
                            onChange={e => this.handleChange(e)}
                            checked={
                              this.state.relationToWizardConditionOperator ===
                              "and"
                            }
                          />
                          <Label for="wizard-condition-and-relation">
                            <TranslatableText translationKey="and" />
                          </Label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            className="stv-radio-button"
                            name="relationToWizardConditionOperator"
                            value="or"
                            id="wizard-condition-or-relation"
                            onChange={e => this.handleChange(e)}
                            checked={
                              this.state.relationToWizardConditionOperator ===
                              "or"
                            }
                          />
                          <Label for="wizard-condition-or-relation">
                            <TranslatableText translationKey="or" />
                          </Label>
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <ConditionsBuilder
                  handleChange={this.handleChange}
                  componentState={this.state}
                  addGroup={this.addGroup}
                  deleteGroup={this.deleteGroup}
                  handleDynamicFieldChange={this.handleDynamicFieldChange}
                  toggleCopyConditionsModal={this.toggleCopyConditionsModal}
                  toggleConditionModal={this.toggleConditionModal}
                  renderTableHeader={this.renderTableHeader}
                  renderTableData={this.renderTableData}
                />
              </Row>
            </Col>
          </Row>
          {this.props.match.params.id === undefined ? (
            <Row className="panel" form>
              <Col className="panel-header" md={12}>
                <TranslatableText translationKey="custom_fields.display_information" />
              </Col>
              <Col className="panel-body" md={12}>
                <Row form>
                  <Col md={8}>
                    <FormGroup
                      className={validation.title.isInvalid ? "has-error" : ""}
                    >
                      <Label className="flex space-between" for="title">
                        <span className="flex verticle-flex-end">
                          {" "}
                          <TranslatableText translationKey="title" />
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder=""
                        value={this.state.title}
                        onChange={this.handleChange}
                      />
                      <span className="help-block">
                        {validation.title.message}
                      </span>
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup
                      className={
                        validation.language.isInvalid ? "has-error" : ""
                      }
                    >
                      <Label for="language">
                        <TranslatableText translationKey="language" />
                      </Label>
                      <Input
                        type="select"
                        name="language"
                        id="language"
                        value={this.state.language}
                        onChange={this.handleChange}
                        disabled={
                          this.props.match.params.translationId !== undefined
                            ? true
                            : null
                        }
                      >
                        <option value="">-- Vælg --</option>
                        {this.state.languageDropdown.map((e, key) => {
                          return (
                            <option key={key} value={e.value}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Input>
                      <span className="help-block">
                        {validation.language.message}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label className="flex space-between" for="description">
                        <span>
                          <TranslatableText translationKey="description" />
                        </span>
                      </Label>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        placeholder=""
                        value={this.state.description}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
        <div className="modals">
          <Modal
            isOpen={this.state.questionsModal}
            toggle={this.toggleWizardQuestionsModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleWizardQuestionsModal}>
              <TranslatableText translationKey="page.questions" />
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={this.insertQuestionID}>
                <Row form>
                  {Config.wizard_based_conditions ? (
                    <Col className="multiselect-container" md={12}>
                      <FormGroup>
                        <Label for="question">Wizard</Label>
                        <Select
                          value={
                            this.state.selectedQuestionsWizard === ""
                              ? null
                              : this.state.selectedQuestionsWizard
                          }
                          className="user-selectbox"
                          options={this.state.conditionWizards}
                          onChange={selectedOption =>
                            this.handleTagboxChange(
                              "questionsWizard",
                              "selectedQuestionsWizard",
                              selectedOption
                            )
                          }
                          hideSelectedOptions={false}
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    <></>
                  )}
                  <Col className="multiselect-container" md={12}>
                    <FormGroup>
                      <Label for="question">
                        <TranslatableText translationKey="page.questions_id_description" />
                      </Label>
                      <Input
                        type="select"
                        name="question"
                        id="question"
                        value={this.state.question}
                        onChange={this.handleChange}
                      >
                        <option value="">-- Vælg --</option>
                        {this.state.questionDropdown.map((e, key) => {
                          return (
                            <option key={e.id} value={e.questionCode}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form className="flex flex-end">
                  <Button className="mr-10" outline>
                    <TranslatableText translationKey="page.insert" />
                  </Button>
                  <Button outline onClick={this.toggleWizardQuestionsModal}>
                    <TranslatableText translationKey="cancel" />
                  </Button>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.conditionModal}
            toggle={this.toggleConditionModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleConditionModal}>
              Add Condition
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={this.insertModuleConditions}>
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    <FormGroup>
                      <Label for="moduleId">
                        <TranslatableText translationKey="page.module" />
                      </Label>
                      <Input
                        type="select"
                        name="moduleId"
                        id="moduleId"
                        value={this.state.moduleId}
                        onChange={this.handleConditionsChange}
                        disabled={this.state.updateCondition ? true : null}
                      >
                        <option value="">-- Vælg --</option>
                        {this.state.regardingObjectTypeIdDropdown.map(
                          (e, key) => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.name}
                              </option>
                            );
                          }
                        )}
                      </Input>
                    </FormGroup>

                    {this.state.moduleId === "" ? (
                      <></>
                    ) : this.state.moduleId !== "" &&
                      this.state.entityRecordDropdown.length === 0 &&
                      this.state.fetchingModuleRecords ? (
                      <FormGroup>
                        <InlineLoading />
                      </FormGroup>
                    ) : !this.state.fetchingModuleRecords &&
                      this.state.moduleId !== "" &&
                      this.state.entityRecordDropdown.length === 0 ? (
                      <></>
                    ) : (
                      <FormGroup>
                        <Label for="entityRecordId">
                          <TranslatableText translationKey="entity" />
                        </Label>
                        <Input
                          type="select"
                          name="entityRecordId"
                          id="entityRecordId"
                          value={this.state.entityRecordId}
                          onChange={this.handleConditionsChange}
                          disabled={this.state.updateCondition ? true : null}
                        >
                          <option value="">-- Vælg --</option>
                          {this.state.entityRecordDropdown.map((e, key) => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    )}

                    {this.state.entityRecordId === "" ? (
                      <></>
                    ) : this.state.entityRecordId !== "" &&
                      this.state.customFieldDropdown.length === 0 &&
                      this.state.fetchingCustomFields ? (
                      <FormGroup>
                        <InlineLoading />
                      </FormGroup>
                    ) : !this.state.fetchingCustomFields &&
                      this.state.entityRecordId !== "" &&
                      this.state.customFieldDropdown.length === 0 ? (
                      <></>
                    ) : (
                      <FormGroup>
                        <Label for="customFieldId">Custom Field</Label>
                        <Input
                          type="select"
                          name="customFieldId"
                          id="customFieldId"
                          value={this.state.customFieldId}
                          onChange={this.handleConditionsChange}
                        >
                          <option value="">-- Vælg --</option>
                          {this.state.customFieldDropdown.map((e, key) => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    )}

                    {this.state.entityRecordId !== "" &&
                    this.state.moduleId !== "" &&
                    this.state.customFieldId !== "" ? (
                      <FormGroup>
                        <Label for="value">Value</Label>
                        <Input
                          type="select"
                          name="value"
                          id="value"
                          value={this.state.value}
                          onChange={this.handleConditionsChange}
                        >
                          <option value="">-- Vælg --</option>
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </Input>
                      </FormGroup>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row form className="flex flex-end">
                  {this.state.updateCondition ? (
                    <Button className="mr-10" outline>
                      <TranslatableText translationKey="udpate" />
                    </Button>
                  ) : (
                    <Button className="mr-10" outline>
                      <TranslatableText translationKey="add" />
                    </Button>
                  )}

                  <Button outline onClick={this.toggleConditionModal}>
                    <TranslatableText translationKey="cancel" />
                  </Button>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.copyConditionsModal}
            toggle={this.toggleCopyConditionsModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleCopyConditionsModal}>
              Copy Conditions
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={this.copyConditions}>
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    <FormGroup>
                      <Label for="question">Wizard</Label>
                      <Select
                        value={
                          this.state.selectedConditionWizard === ""
                            ? null
                            : this.state.selectedConditionWizard
                        }
                        className="user-selectbox"
                        options={this.state.conditionWizards}
                        onChange={selectedOption =>
                          this.handleTagboxChange(
                            "conditionWizard",
                            "selectedConditionWizard",
                            selectedOption
                          )
                        }
                        hideSelectedOptions={false}
                      />
                    </FormGroup>
                  </Col>
                  {this.state.conditionWizard === "" ? (
                    <></>
                  ) : (
                    <Col className="multiselect-container" md={12}>
                      <FormGroup>
                        <Label for="question">Type</Label>
                        <Select
                          value={
                            this.state.selectedConditionType === ""
                              ? null
                              : this.state.selectedConditionType
                          }
                          className="user-selectbox"
                          options={this.state.conditionTypes}
                          onChange={selectedOption =>
                            this.handleTagboxChange(
                              "conditionType",
                              "selectedConditionType",
                              selectedOption
                            )
                          }
                          hideSelectedOptions={false}
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {this.state.conditionType === "" ? (
                    <></>
                  ) : this.state.conditionType !== "" &&
                    this.state.conditionEntities.length === 0 &&
                    this.state.fetchingConditionEntities ? (
                    <Col className="multiselect-container" md={12}>
                      <FormGroup>
                        <InlineLoading />
                      </FormGroup>
                    </Col>
                  ) : !this.state.fetchingConditionEntities &&
                    this.state.conditionType !== "" &&
                    this.state.conditionEntities.length === 0 ? (
                    <></>
                  ) : (
                    <Col className="multiselect-container" md={12}>
                      <FormGroup>
                        <Label for="question">
                          <TranslatableText translationKey="entity" />
                        </Label>
                        <Select
                          value={
                            this.state.selectedConditionEntity === ""
                              ? null
                              : this.state.selectedConditionEntity
                          }
                          className="user-selectbox"
                          options={this.state.conditionEntities}
                          onChange={selectedOption =>
                            this.handleTagboxChange(
                              "conditionEntity",
                              "selectedConditionEntity",
                              selectedOption
                            )
                          }
                          hideSelectedOptions={false}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row form className="flex flex-end">
                  <Button className="mr-10" outline>
                    <TranslatableText translationKey="add" />
                  </Button>
                  <Button outline onClick={this.toggleCopyConditionsModal}>
                    <TranslatableText translationKey="cancel" />
                  </Button>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CreatePage);
CreatePage.contextType = GlobalContext;