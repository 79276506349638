import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import TranslatableText from "../../../../Language/TranslatableText";

let SharePointInformation = ({ componentState, handleConfigChange }) => {
  return (
    <Row>
      <Col md={12}>
        <hr />
      </Col>
      <Col md={12}>
        <h4>
          <TranslatableText translationKey="company.sharepoint.configuration" />
        </h4>
        <p>
          <TranslatableText translationKey="company.sharepoint.configuration_description" />
        </p>
      </Col>
      <Col md={12}>
        <FormGroup>
          <Label for="url">
            <TranslatableText translationKey="url" />
          </Label>
          <Input
            type="text"
            name="url"
            id="url"
            placeholder="URL"
            value={componentState.configuration.sharepointConfiguration.url}
            onChange={e => handleConfigChange(e)}
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label for="folderName">
            <TranslatableText translationKey="sharepoint.folderName" />
          </Label>
          <Input
            type="text"
            name="folderName"
            id="folderName"
            placeholder="Folder Name"
            value={
              componentState.configuration.sharepointConfiguration.folderName
            }
            onChange={e => handleConfigChange(e)}
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label for="tenantId">
            <TranslatableText translationKey="company.sharepoint.tenant_id" />
          </Label>
          <Input
            type="text"
            name="tenantId"
            id="tenantId"
            placeholder="Tenant ID"
            value={
              componentState.configuration.sharepointConfiguration.tenantId
            }
            onChange={e => handleConfigChange(e)}
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label for="clientId">
            <TranslatableText translationKey="company.sharepoint.client_id" />
          </Label>
          <Input
            type="text"
            name="clientId"
            id="clientId"
            placeholder="Client ID"
            value={
              componentState.configuration.sharepointConfiguration.clientId
            }
            onChange={e => handleConfigChange(e)}
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label for="clientSecret">
            <TranslatableText translationKey="company.sharepoint.client_secret" />
          </Label>
          <Input
            type="text"
            name="clientSecret"
            id="clientSecret"
            placeholder="Client Secret"
            value={
              componentState.configuration.sharepointConfiguration.clientSecret
            }
            onChange={e => handleConfigChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default React.memo(SharePointInformation);
