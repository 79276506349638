import React, { Component } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { getSections, deleteSectionByIdAPI } from "./companySectionsAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faTrash,
  faInfoCircle,
  faProjectDiagram
} from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import TranslatableText from "../../../../Language/TranslatableText";
import SecuredComponent from "../../../../components/secured-component/SecuredComponent";
import { toast } from "react-toastify";
import { ResourceTypes } from "../../../../config/ResourceTypes";
import { AccessLevels, Actions } from "../../../../config/Levels";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";



const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class CompanySections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      sectionId: null,
      deleteModal: false,
      deleteLoader: false,
      sections: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "order",
          text: "Order",
          sort: true
        },
        {
          dataField: "icon",
          text: "Icon",
          sort: true,
          formatter: this.iconFormatter
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ]
    };
  }

  componentDidMount() {
    this.populateSections();
  }

  populateSections = () => {
    this.setState({ tableLoader: true, sections: [] });
    getSections()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }

        var self = this;
        var data = res
          .filter(function (el) {
            if (el.name === "") {
              return false; // skip
            }
            return true;
          })
          .map(function (el) {
            var o = Object.assign({}, el);
            o.actions = (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`${self.props.match.path}/attachments/${el.id}`}
                >
                  <FontAwesomeIcon icon={faPaperclip} size="1x" />
                </NavLink>
                <NavLink
                  tag={Link}
                  to={`/company-admin/flow-engine/${el.regardingObjectTypeId}/${el.id}`}
                >
                  <FontAwesomeIcon icon={faProjectDiagram} size="1x" />
                </NavLink>
                <NavLink
                  tag={Link}
                  to={`${self.props.match.path}/details/${el.id}`}
                >
                  <Tippy placement="top" content="Details">
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <SecuredComponent
                  resourceType={el.regardingObjectTypeId}
                  action={Actions.write}
                >
                  <NavLink onClick={e => self.openDeleteModal(el.id, e)}>
                    <Tippy placement="top" content="Remove">
                      <span>
                        <FontAwesomeIcon
                          style={{ color: "#d84a4a" }}
                          icon={faTrash}
                          size="1x"
                        />
                      </span>
                    </Tippy>
                  </NavLink>
                </SecuredComponent>
              </div>
            );
            return o;
          });
        this.setState({ sections: data, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  iconFormatter = (cell, row) => {
    return (
      <span>
        <i className={`fas mr-10 fa-${cell}`}></i>
        {cell}
      </span>
    );
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            sectionId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      sectionId: id
    });
  };

  deleteSection = id => {
    this.setState({ deleteLoader: true });
    deleteSectionByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populateSections();
        this.setState({
          deleteModal: false,
          sectionId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          sectionId: null,
          deleteLoader: false
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { sections, columns, tableLoader } = this.state;
    return (
      <div className="admin-dept-view">
        <h1>Sections</h1>
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={sections}
            columns={columns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      <SecuredComponent accountLevel={AccessLevels.developer}>
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          CSV
                        </ExportCSVButton>
                      </SecuredComponent>
                      <div>
                        <SecuredComponent
                          resourceType={ResourceTypes.section}
                          action={Actions.write}
                        >
                          <NavLink
                            className="button-primary"
                            tag={Link}
                            to={`${this.props.match.path}/create`}
                          >
                            <TranslatableText translationKey="create_section" />
                          </NavLink>
                        </SecuredComponent>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              <TranslatableText translationKey="confirm_delete" />?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="confirm_delete_section_description" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deleteSection(this.state.sectionId, e)}
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanySections);
CompanySections.contextType = GlobalContext;
