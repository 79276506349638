import { faCloudDownloadAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { GetAllSectionProcessesResponse, HandleCaught_API_Error_Base, ReportsClient, SectionsClient, UsersClient } from "../../apiClients";
import { ScreenLoading } from "../../components";
import Accent from "../../components/accent/Accent";
import Gap from "../../components/gap/Gap";
import { usePlatformUserData } from "../../contexts";
import TranslatableText from "../../Language/TranslatableText";
import SectionWithProcessList from './components/section';

type UserListForDropdown = {
  label: string;
  value: string;
}

export default function Processes() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [sectionsProcesses, setSectionsProcesses] = useState<GetAllSectionProcessesResponse[]>([]);
  const [userList, setUserList] = useState<UserListForDropdown[]>([]);

  const currentOrgData = usePlatformUserData();
  let _downloadReportToast: React.ReactText;

  useEffect(() => {
    Promise.all([
      new SectionsClient().sectionsProcesses()
        .then(res => {
          setSectionsProcesses(Array.isArray(res) ? res : [])
        })
        .catch(HandleCaught_API_Error_Base),
      new UsersClient().userList()
        .then(res => {
          var usersIds: UserListForDropdown[] = [];
          res.forEach((row) => {
            usersIds.push({ label: row.name, value: row.id });
          });
          setUserList(usersIds);
        })
        .catch(HandleCaught_API_Error_Base)
    ])
      .catch(HandleCaught_API_Error_Base)
      .finally(() => { setIsLoading(false); })
  }, []);



  // getUsersAPI()
  // .then(res => {
  //     var usersIds: any[] = [];
  //     res.forEach(function (row: any) {
  //         usersIds.push({ label: row.name, value: row.id });
  //     });
  //     this.setState({ usersDropdown: usersIds });
  // })
  // .catch(error => HandleCaught_API_Error(error));








  function dowloadProjectReport() {
    _downloadReportToast = toast.info("Downloading report, please wait...");

    new ReportsClient().proejct(currentOrgData.currentOrgData.organizationId)
      .then(res => {
        var response = res.data;
        const url = window.URL.createObjectURL(
          new Blob([response], { type: res.headers!["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", res.fileName ?? "PrjectOverview.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(HandleCaught_API_Error_Base)
      .finally(() => toast.dismiss(_downloadReportToast));
  }

  if (isLoading) {
    return (
      <div className="center-align">
        <ScreenLoading />
      </div>
    );
  }
  return (
    <div className="admin-systems-view">
      {currentOrgData.currentOrgData.system === 'DPO Advisor' && (
        <Accent>
          <TranslatableText translationKey="processes.processes" />
        </Accent>
      )}
      {currentOrgData.currentOrgData.system !== 'DPO Advisor' && (
        <Accent>
          <TranslatableText translationKey="processes" />
        </Accent>
      )}
      {currentOrgData.currentOrgData.system !== 'KD-Sys 360' &&
        <>
          <Gap size="50px" />
          <Row>
            <Col md={2}>
              <p><TranslatableText translationKey="processes.processes_report" /></p>
            </Col>
            <Col md={10}>
              <Tippy placement="top" content={<TranslatableText translationKey="processes.download_tooltip" />}>
                <span>
                  <Button id="caret" color="primary"
                    onClick={e => dowloadProjectReport()}>
                    <FontAwesomeIcon icon={faCloudDownloadAlt} size="1x" /> <TranslatableText translationKey="processes.download" />
                  </Button>
                </span>
              </Tippy>
            </Col>
          </Row>
        </>
      }
      <Gap size="50px" />

      {sectionsProcesses.map((section, index) => (
        <SectionWithProcessList
          key={section.id}
          data={section}
          userData={currentOrgData.currentOrgData}
          usersDropdown={userList}
          index={index}
          entityReport={currentOrgData.currentOrgData.systemSettings.entity_report}
        />
      ))}

      <Row>
        <Col md={12}>
          <Button onClick={() => history.goBack()}>
            <TranslatableText translationKey="back" />
          </Button>
        </Col>
      </Row>
    </div>
  );
}
