import React, { Fragment } from 'react';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/pro-solid-svg-icons';
import { GetUserOrganizationsResponse } from '../../../apiClients';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';

export function PartnerMenuBtn(navigateTo: (view: string) => void, userFavoritePartners: GetUserOrganizationsResponse) {
    return (
        <Fragment>
            <DropdownItem onClick={() => navigateTo("/settings?tab=2")}>
                <FontAwesomeIcon icon={faHandshake} />&nbsp;Partner
            </DropdownItem>
            {userFavoritePartners.organizations!.map(
                (item) => {
                    return (
                        <DropdownItem
                            key={item.organizationId}
                            className={"company-item"}
                            onClick={() => navigateTo(`/partner/${item.organizationId}`)}
                        >
                            <div>
                                <FontAwesomeIcon icon={faCircle} />
                                &nbsp;{item.name}
                            </div>
                        </DropdownItem>
                    );
                }
            )}
        </Fragment>
    );
}
