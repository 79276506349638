import { faChartNetwork } from "@fortawesome/pro-duotone-svg-icons";
import {
    faArrowDownAZ,
    faArrowUpAZ,
    faCheckCircle
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import {
    Column,
    Table,
    ColumnDef,
    createColumnHelper,
    flexRender, getCoreRowModel,
    getExpandedRowModel, Row, useReactTable,
    FilterFn,
    SortingFn,
    sortingFns,
    ColumnFiltersState,
    getFilteredRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getPaginationRowModel,
    getSortedRowModel,
} from '@tanstack/react-table';
import Tippy from "@tippyjs/react";
import dayjs from "dayjs";
import localeDa from 'dayjs/locale/da';
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { KrestonClient } from "../../../apiClients";
import HandleCaught_API_Error from "../../../apiClients/api_caughtError";
import { ProjectStatusResponse } from "../../../apiClients/api_client_v2";
import { InlineLoading } from "../../../components";
import Accent from "../../../components/accent/Accent";


import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'
import { DebouncedInput, Filter } from "../../../components/table/tableFunctions";
import React from "react";
import { Button, ButtonGroup, NavLink } from "reactstrap";
import { changeProject } from "./changeProject";
import { faRandom } from "@fortawesome/pro-solid-svg-icons";


declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
    let dir = 0

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(
            rowA.columnFiltersMeta[columnId]?.itemRank!,
            rowB.columnFiltersMeta[columnId]?.itemRank!
        )
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}


type PartnerParams = { partnerId: string }
export default function ProjectStatus() {
    const _KrestonClient = new KrestonClient();
    const { partnerId } = useParams<PartnerParams>();
    const { isLoading, isError, data, error, } = useQuery(['projectsStatus', partnerId], async () => {
        return _KrestonClient.projectsStatus(partnerId);
    });

    if (isError) {
        HandleCaught_API_Error(error);
    }
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        []
    )
    const [globalFilter, setGlobalFilter] = React.useState('')


    const columnHelper = createColumnHelper<ProjectStatusResponse>();
    const columns = React.useMemo<ColumnDef<ProjectStatusResponse, any>[]>(
        () => [
            {
                accessorFn: row => row.name,
                id: 'name',
                cell: info => info.getValue(),
                header: () => <span>Projekt</span>,
                footer: props => props.column.id,
                enableSorting: true,
            },
            {
                accessorFn: row => row.fiscalYear,
                id: 'fiscalYear',
                cell: info => info.getValue(),
                header: () => <span>Finansår</span>,
                footer: props => props.column.id,
                enableSorting: true,
            },
            {
                accessorFn: row => {
                    let _x = row.processes?.filter(p => p.id === 'f2b26c63-5cf1-4299-e497-08d7b5e964d6')[0];
                    if (_x) {
                        let qAnswer = _x.questionAnswers?.filter(q => q.questionId === '83d81e4d-33b0-4618-704b-08d86a14fb29')[0];
                        if (qAnswer) {
                            return (qAnswer.answer);
                        }
                    }
                    return ('');
                },
                id: 'regnskabsklasse',
                enableSorting: true,
                enableColumnFilter: true,
                header: () => <span>Regnskabsklasse</span>,
                footer: props => props.column.id,
            },
            {
                accessorFn: row => {
                    let _x = row.processes?.filter(p => p.id === 'f2b26c63-5cf1-4299-e497-08d7b5e964d6')[0];
                    if (_x) {
                        let qAnswer = _x.questionAnswers?.filter(q => q.questionId === '2972c988-1511-4eb5-c51b-08d87ca76b22')[0];
                        if (qAnswer) {
                            return (qAnswer.answer);
                        }
                    }
                    return ('');
                },
                id: 'erklaeringstype',
                enableSorting: true,
                enableColumnFilter: true,
                header: () => <span>Erklæringstype</span>,
                footer: props => props.column.id,
            },
            {
                accessorFn: row => approvedStateAccessFn(row, 'f2b26c63-5cf1-4299-e497-08d7b5e964d6'),
                id: '2.1',
                enableSorting: true,
                enableColumnFilter: true,
                cell: ({ row }) => approvedState(row.original, 'f2b26c63-5cf1-4299-e497-08d7b5e964d6'),
                header: () => <span>2.1 Plan</span>,
                footer: props => props.column.id,
            },
            {
                accessorFn: row => approvedStateAccessFn(row, 'd47cdb11-5f4f-4d3a-bcf4-08d979159510'),
                id: '2.3.1',
                enableSorting: true,
                enableColumnFilter: true,
                cell: ({ row }) => {
                    let _x = row.original.processes?.filter(p => p.id === 'd47cdb11-5f4f-4d3a-bcf4-08d979159510')[0];
                    if (_x) {
                        let qAnswer = _x.questionAnswers?.filter(q => q.questionId === 'c2aee8ff-49e5-4f10-099d-08d9f1f2e3ec')[0];
                        if (qAnswer) {
                            return (<>{approvedState(row.original, 'd47cdb11-5f4f-4d3a-bcf4-08d979159510')}&nbsp;{qAnswer.answer}</>);
                        }
                    }
                    return (<></>);
                },
                header: () => <span>2.3.1 Hvidvask</span>,
                footer: props => props.column.id,
            },
            {
                accessorFn: row => approvedStateAccessFn(row, '42cdae8e-6460-4b51-fdbf-08d84e8adb36'),
                id: '3.1',
                enableSorting: true,
                enableColumnFilter: true,
                cell: ({ row }) => {
                    let _x = row.original.processes?.filter(p => p.id === 'f2b26c63-5cf1-4299-e497-08d7b5e964d6')[0];
                    if (_x) {
                        let qAnswer = _x.questionAnswers?.filter(q => q.questionId === '2972c988-1511-4eb5-c51b-08d87ca76b22')[0];
                        if (qAnswer && qAnswer.answer === 'Assistance med opstilling') {
                            return (
                                <Tippy placement="top" content="Konklusionsarket er ikke relevant ved Erklæringstypen: Assistance med opstilling">
                                    <span>
                                        Ikke Relevant
                                    </span>
                                </Tippy>);
                        }
                    }
                    return approvedState(row.original, '42cdae8e-6460-4b51-fdbf-08d84e8adb36')

                },
                header: () => <span>3.1 Konklusionsark</span>,
                footer: props => props.column.id,
            },
            columnHelper.display({
                maxSize: 2,
                id: 'actions',
                cell: props =>
                    <div className="actions">
                        <Tippy placement="top" content="Skift til dete projekt">
                            <span>
                                <NavLink onClick={e => changeProject(props.row.original.id)}>
                                    <FontAwesomeIcon icon={faRandom} size="1x" />
                                </NavLink>
                            </span>
                        </Tippy>
                    </div>,
            }),
        ],
        []
    )

    const table = useReactTable<ProjectStatusResponse>({
        data: data ?? [],
        columns,
        initialState: {
            pagination: {
                pageSize: 50
            }
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    return (
        <div className="panel-view" style={{ width: "75vw", position: "absolute", left: "50%", transform: "translate3d(-50%, 0, 0)" }}>
            <Accent useIcon={true} icon={faChartNetwork}>
                Project Status
            </Accent>

            <div className="table-container">

                <div className="p-2">
                    <div className="h-2" />
                    <table className="table table-bordered table-striped table-hover">

                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => {
                                        return (
                                            <th key={header.id} colSpan={header.colSpan}>
                                                {header.isPlaceholder ? null : (
                                                    <>
                                                        <div
                                                            {...{
                                                                className: header.column.getCanSort()
                                                                    ? 'cursor-pointer select-none'
                                                                    : '',
                                                                onClick: header.column.getToggleSortingHandler(),
                                                            }}
                                                        >
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {{
                                                                asc: <>&nbsp;<FontAwesomeIcon icon={faArrowDownAZ} /></>,
                                                                desc: <>&nbsp;<FontAwesomeIcon icon={faArrowUpAZ} /></>,
                                                            }[header.column.getIsSorted() as string] ?? null}
                                                        </div>
                                                        {header.column.getCanFilter() ? (
                                                            <div>
                                                                <Filter column={header.column} table={table} />
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        {isLoading && <tbody>
                            <tr>
                                <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                    <InlineLoading />
                                </ td>
                            </tr>
                        </tbody>}
                        {!isLoading && table.getRowModel().rows.length === 0 && <tbody>
                            <tr>
                                <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                                    <span>Du har ingen godkendelser for denne partner.</span>
                                </ td>
                            </tr>
                        </tbody>}
                        <tbody>
                            {table.getRowModel().rows.map(row => {
                                return (
                                    <Fragment key={row.id}>
                                        <tr>
                                            {/* first row is a normal row */}
                                            {row.getVisibleCells().map(cell => {
                                                return (
                                                    <td key={cell.id}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="h-2" />
                    <div className="d-flex justify-content-between gap-2">
                        <select
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                table.setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Vis {pageSize}
                                </option>
                            ))}
                        </select>
                        <span className="flex items-center gap-1">
                            <div>Side </div>
                            <strong>
                                {table.getState().pagination.pageIndex + 1} af{' '}
                                {table.getPageCount()}
                            </strong>
                        </span>
                        <ButtonGroup>
                            <Button
                                onClick={() => table.setPageIndex(0)}
                                disabled={!table.getCanPreviousPage()}>{'<<'}
                            </Button>
                            <Button
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}>{'<'}
                            </Button>
                            <Button
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}>
                                {'>'}
                            </Button>
                            <Button
                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                disabled={!table.getCanNextPage()}>
                                {'>>'}
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div>{table.getRowModel().rows.length} Rækker</div>
                    <div className="h-2" />
                </div>
            </div>
        </div>
    );
}

function approvedState(row: ProjectStatusResponse, processId: string) {
    let _x = row.processes?.filter(p => p.id === processId)[0];
    if (_x) {
        let approveDate = _x.flowEngineHistoryLogState ?? _x.approvalFlowState;
        if (approveDate) {
            return (
                <Tippy placement="top" content={<>Godkendt: {dateFormatter(approveDate)}</>}>
                    <span>
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                </Tippy>
            );
        }
    }
    return (<></>);
}
function approvedStateAccessFn(row: ProjectStatusResponse, processId: string) {
    let _x = row.processes?.filter(p => p.id === processId)[0];
    if (_x) {
        let approveDate = _x.flowEngineHistoryLogState ?? _x.approvalFlowState;
        if (approveDate) {
            return ('Godkendt');
        }
    }
    return ('Mangler godkendelse');
}

function dateFormatter(time: dayjs.Dayjs) {
    if (typeof time === 'string') {
        return dayjs(time).locale(localeDa).format('DD MMM YYYY');
    }
    return (time.locale(localeDa).format('DD MMM YYYY'));
}
