import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Systems API URL
const SYSTEMS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Systems`;

// Get all systems
export const getSystems = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${SYSTEMS_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};
