import React, { Component } from "react";
import { Row, Col, Button, Label, Alert } from "reactstrap";
import { withRouter } from "react-router-dom";
import { LanguageContext } from "../../../../contexts/language-context";
import { getRoleByIdAPI, getClaimsByRoleIdAPI } from "./companyRolesAPI";
import Accent from "../../../../components/accent/Accent";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { getTemplateRegardingObjectType } from "../claims/companyClaimsAPI";
import { getSections } from "../sections/companySectionsAPI";
import { ResourceTypes } from "../../../../config/ResourceTypes";
import {
  RoleClaimAssociationRequest,
  RolesClient
} from "../../../../apiClients";
import { Actions, ActionsString } from "../../../../config/Levels";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";




class CompanyRoleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      resourceLoader: false,
      sectionLoader: false,
      resourceClaims: [],
      sectionClaims: [],
      actionDropdown: [
        { code: Actions.noAccess, name: ActionsString.noAccess },
        { code: Actions.write, name: ActionsString.write },
        { code: Actions.read, name: ActionsString.read }
      ],
      cuLoader: false
    };
  }

  componentDidMount() {
    getRoleByIdAPI(this.props.match.params.id)
      .then(res => {
        this.setState({
          name: res.data.name
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
    this.populateClaims(this.props.match.params.id);
  }

  populateClaims = id => {
    this.setState({ resourceLoader: true, sectionLoader: true });
    getClaimsByRoleIdAPI(id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        let roleClaims = res;

        getSections().then(res => {
          let sectionClaims = res;
          sectionClaims.forEach(element => {
            let action = this.searchAction(element.id, roleClaims, "sectionId");
            action = action === undefined ? Actions.noAccess : action;
            element.action = action;
          });
          this.setState({
            sectionLoader: false,
            sectionClaims
          });
        });
        getTemplateRegardingObjectType().then(res => {
          let resourceClaims = res
            .sort(function (a, b) {
              var textA = a.displayName.toLowerCase();
              var textB = b.displayName.toLowerCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            // .filter(item => (item.id === ResourceTypes.process ? false : true));
          resourceClaims.forEach(element => {
            let action = this.searchAction(
              element.id,
              roleClaims,
              "regardingObjectTypeId"
            );
            action = action === undefined ? Actions.noAccess : action;
            element.action = action;
          });
          this.setState({
            resourceLoader: false,
            resourceClaims
          });
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false, claimsTableLoader: false });
      });
  };

  searchAction = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i].action;
      }
    }
  };

  searchRegardingObjectTypeID = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i].regardingObjectTypeId;
      }
    }
  };

  handleDynamicFieldChange = (idx, claimType, e) => {
    const { name, value } = e.target;
    const rows = [...this.state[claimType]];
    rows[idx].action = parseInt(value);
    this.setState(
      {
        [claimType]: rows
      },
      () => {
        let regardingObjectTypeId =
          claimType === "resourceClaims"
            ? name
            : this.searchRegardingObjectTypeID(
              name,
              this.state.sectionClaims,
              "id"
            );
        let sectionId = claimType === "resourceClaims" ? undefined : name;

        this.changePermissionsOnRole(
          this.props.match.params.id,
          regardingObjectTypeId,
          value,
          sectionId
        );
      }
    );
  };

  changePermissionsOnRole = (
    roleId,
    regardingObjectTypeId,
    action,
    sectionId
  ) => {
    this.downloadReportToast = toast.info(
      "Changing permissions, please wait...",
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false
      }
    );
    let req = new RoleClaimAssociationRequest({
      roleId: roleId,
      regardingObjectTyoeId: regardingObjectTypeId,
      sectionId: sectionId,
      action: action
    });
    let roleClient = new RolesClient();
    roleClient
      .roleClaimAssociation(req)
      .then(res => {
        toast.dismiss(this.downloadReportToast);
      })
      .catch(error => {
        toast.dismiss(this.downloadReportToast);
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { resourceClaims, sectionClaims } = this.state;
    return (
      <div className="user-details-view">
        <Row>
          <Col md={12}>
            {this.state.name !== "" ? (
              <Accent useIcon={false}>
                <TranslatableText translationKey="claims_for_the_role" /> "
                {this.state.name}"
              </Accent>
            ) : (
              <Accent useIcon={false}>
                <InlineLoading />
              </Accent>
            )}
          </Col>
          <Col md={12}>
            <Alert color="warning">
              <TranslatableText translationKey="rbac_still_wip" />
            </Alert>
          </Col>
        </Row>
        {!this.state.resourceLoader ? (
          <Row>
            <Col>
              <table className="claims-table">
                <thead>
                  <tr>
                    <th>
                      <TranslatableText translationKey="resources" />
                    </th>
                    <th>
                      <TranslatableText translationKey="action" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {resourceClaims.map((resource, idx) => {
                    return (
                      <tr key={idx}>
                        <td style={{width: '60%'}}>{resource.displayName}</td>
                        <td>
                          <div className="stv-radio-buttons-wrapper three">
                            {this.state.actionDropdown.map((element, key) => {
                              return (
                                <span key={key}>
                                  <input
                                    type="radio"
                                    className="stv-radio-button"
                                    name={resource.id}
                                    value={element.code}
                                    id={`button-${resource.id}-${key}`}
                                    onChange={e =>
                                      this.handleDynamicFieldChange(
                                        idx,
                                        "resourceClaims",
                                        e
                                      )
                                    }
                                    checked={resource.action === element.code}
                                  />
                                  <Label for={`button-${resource.id}-${key}`}>
                                    <TranslatableText translationKey={element.name} />
                                  </Label>
                                </span>
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        ) : (
          <InlineLoading />
        )}

        {!this.state.sectionLoader ? (
          <Row>
            <Col>
              <table className="claims-table">
                <thead>
                  <tr>
                    <th>
                      <TranslatableText translationKey="section" />
                    </th>
                    <th>
                      <TranslatableText translationKey="action" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sectionClaims.map((section, idx) => {
                    return (
                      <tr key={idx}>
                        <td style={{width: '60%'}}>{section.name}</td>
                        <td>
                          <div className="stv-radio-buttons-wrapper three">
                            {this.state.actionDropdown.map((element, key) => {
                              return (
                                <span key={key}>
                                  <input
                                    type="radio"
                                    className="stv-radio-button"
                                    name={section.id}
                                    value={element.code}
                                    id={`button-${section.id}-${key}`}
                                    onChange={e =>
                                      this.handleDynamicFieldChange(
                                        idx,
                                        "sectionClaims",
                                        e
                                      )
                                    }
                                    checked={section.action === element.code}
                                  />
                                  <Label for={`button-${section.id}-${key}`}>
                                    <TranslatableText
                                      translationKey={element.name}
                                    />
                                  </Label>
                                </span>
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        ) : (
          <InlineLoading />
        )}

        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(CompanyRoleDetails);
CompanyRoleDetails.contextType = GlobalContext;