import { faCloudDownloadAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import localeDa from 'dayjs/locale/da';
import { useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription, SortOrder } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Id, toast } from "react-toastify";
import { Button, Col, NavLink } from "reactstrap";
import Swal from "sweetalert2";
import { DeleteRequest, FileResponse, ReportHistoryResponse, ReportsClient, ReportsHistoryClient, ReportTemplatesClient } from "../../apiClients";
import HandleCaught_API_Error from "../../apiClients/api_caughtError";
import { InlineLoading } from "../../components";
import Accent from "../../components/accent/Accent";
import { Table_GetLocalStorage, Table_HeaderFormatter, Table_NoDataIndication, Table_SetLocalStorage } from "../../components/table";
import { AccountLevel } from "../../config/Levels";
import { useLanguage, usePlatformUserData } from "../../contexts";
import TranslatableText, { TranslatableTextString } from "../../Language/TranslatableText";




type ReportHistoryState = {
  loading: boolean,
  reportName: string,
  historyData: ReportHistoryResponse[]
}

type IProps = {
  typeID: string
}

type DefSort = [{
  dataField: any;
  order: SortOrder;
}]

export default function ReportHistory(props: IProps) {
  const _ReportTemplatesClient = new ReportTemplatesClient();
  const _ReportsHistoryClient = new ReportsHistoryClient();
  const _ReportsClient = new ReportsClient();

  const organisationData = usePlatformUserData();
  const { language } = useLanguage();
  const [toastRef, setToastRef] = useState<Id>('');

  const [
    { loading, reportName, historyData },
    setState
  ] = useState<ReportHistoryState>({
    loading: true,
    reportName: '',
    historyData: []
  });

  function RemoveEntityFromList(id: string) {
    setState((prevState) => ({ ...prevState, historyData: historyData.filter(item => item.id !== id) }));
  }

  useEffect(() => {
    setState((prevState) => ({ ...prevState, loading: true }));
    Promise.all([
      // Grab the name
      _ReportTemplatesClient.wizardReportTemplates(props.typeID)
        .then(res => {
            setState((prevState) => ({ ...prevState, reportName: res[0] === undefined ? "NA" : res[0].name! }));
        })
        .catch(error => HandleCaught_API_Error(error)),
      // Grab the history
      _ReportsHistoryClient.reportHistories(organisationData.currentOrgData.organizationId, props.typeID)
        .then(res => {
          setState((prevState) => ({ ...prevState, historyData: res}));
        })
        .catch(error => HandleCaught_API_Error(error)),
    ]).finally(() => {
      setState((prevState) => ({ ...prevState, loading: false }));
    })
  }, [props.typeID]);

  const dateFormatter = (cell: any, row: ReportHistoryResponse) => {
    if (row.createdOn) {
        return (row.createdOn.locale(localeDa).format('HH:mm, DD MMM YYYY'));
    } else {
      return '';
    }
};



  const defaultSorted: DefSort = [{
    dataField: 'createdOn',
    order: 'desc'
  }];

  const tableColumns: Array<ColumnDescription<ReportHistoryResponse>> = [
    {
      dataField: 'createdOn',
      text: "createdOn",
      formatter: dateFormatter,
      headerFormatter: Table_HeaderFormatter,
      sort: true,
      
    },
    {
      dataField: "actions",
      text: "actions",
      headerFormatter: Table_HeaderFormatter,
      csvExport: false,
      formatter: (cell, row) => {
        return (
          <div className="actions">
            <NavLink onClick={() => downloadSingleReport(row.id)}>
              <Tippy placement="top" content="Download">
                <span>
                  <FontAwesomeIcon
                    style={{ color: "var(--primary)" }}
                    icon={faCloudDownloadAlt}
                  />
                </span>
              </Tippy>
            </NavLink>
            <NavLink onClick={() => deleteReportHistory(row.id)}>
              <Tippy placement="top" content="Remove">
                <span>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                  />
                </span>
              </Tippy>
            </NavLink>
          </div>
        );
      }
    }
  ]

  function dowloadProjectReportById() {
    ShowDownloadingToast();
    _ReportsClient.projectReportByReportId(organisationData.currentOrgData.organizationId, props.typeID)
      .then(res => HandleFileDownload(res))
      .catch(error => HandleCaught_API_Error(error))
      .finally(() => HideDownloadToast());
  }

  function dowloadProjectReportJSONById() {
    ShowDownloadingToast();
    _ReportTemplatesClient.project(organisationData.currentOrgData.organizationId)
    .then(res => HandleFileDownload(res))
    .catch(error => HandleCaught_API_Error(error))
      .finally(() => HideDownloadToast());
  }

  function downloadSingleReport(reportHistoryId: string) {
    ShowDownloadingToast();
    _ReportsHistoryClient.download(reportHistoryId)
      .then(res => HandleFileDownload(res))
      .catch(error => HandleCaught_API_Error(error))
      .finally(() => HideDownloadToast());
  }

  function deleteReportHistory(reportHistoryId: string) {
    Swal.fire({
      title: TranslatableTextString("swal.are_you_sure"),
      text: TranslatableTextString("swal.are_you_sure.text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: TranslatableTextString("swal.are_you_sure.confirmButtonText"),
      cancelButtonText: TranslatableTextString("swal.are_you_sure.cancelButtonText")
    }).then(result => {
      if (result.value) {
        _ReportsHistoryClient.reportsHistory(new DeleteRequest({ id: reportHistoryId }))
          .then(() => {
            RemoveEntityFromList(reportHistoryId);
            Swal.fire(
              TranslatableTextString("swal.deleted"),
              TranslatableTextString("swal.deleted_record_deleted"),
              "success"
            );
          })
          .catch(error => HandleCaught_API_Error(error));
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          TranslatableTextString("swal.cancelled"),
          TranslatableTextString("swal.cancelled_your_record_is_safe"),
          "error"
        );
      }
    });
  }


  return (
    <>
      <Col md={12}>
        <Accent useIcon={false}>
          {reportName === "NA" ? (
            <TranslatableText translationKey="no_report_available_in_your_language" />
          ) : (
            reportName
          )}
        </Accent>
      </Col>
      {reportName !== "NA" ? (
        <Col md={12}>
          <div className="table-container">
            <ToolkitProvider
              bootstrap4
              keyField="id"
              data={historyData}
              columns={tableColumns}
              exportCSV={{
                fileName: "data.csv",
                separator: language === 'dk' ? ';' : ',',
                ignoreHeader: false,
                noAutoBOM: false
              }}
            >
              {props => (
                <div>
                  <div className="flex space-between">
                    <div>{/* <SearchBar {...props.searchProps} /> */}</div>
                    <div>
                      <div className="flex space-between">
                        <div>
                          <Button
                            className="button-primary"
                            onClick={() => dowloadProjectReportById()}
                          >
                            <TranslatableText translationKey="generate_and_download" />
                          </Button>

                          {organisationData.currentOrgData.accountLevel >= AccountLevel.PlatformOwner &&
                            <>&nbsp;
                              <Button
                                className="button-primary"
                                onClick={() => dowloadProjectReportJSONById()}
                              >
                                <TranslatableText translationKey="Download JSON" />
                              </Button>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <BootstrapTable
                    noDataIndication={() => loading ? (<InlineLoading loading={loading} />) : (<Table_NoDataIndication />)}
                    {...props.baseProps}
                    defaultSorted={ defaultSorted } 
                    pagination={paginationFactory({ sizePerPage: Table_GetLocalStorage(), onSizePerPageChange: e => Table_SetLocalStorage(e) })}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </Col>
      ) : (
        <></>
      )}
    </>
  );


  function HandleFileDownload(res: FileResponse) {
    const url = window.URL.createObjectURL(
      new Blob([res.data], { type: res.headers!["content-type"] })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", res.fileName ?? 'downloadedFile'); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  function HideDownloadToast() {
    toast.dismiss(toastRef);
  }

  function ShowDownloadingToast() {
    setToastRef(toast.info(
      "Downloader rapport, vent venligst...",
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false
      }
    ));
  }
}