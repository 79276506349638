import { useAuth0 } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { getAppInsights } from './components/appInsights/TelemetryService';
import EnableContext from './components/EnableContext';
import Layout from './components/Layout';
import { Loading } from './components/loading/loading';
import { GlobalContext, GlobalProvider } from './contexts/global-context';
import { LanguageProvider } from './contexts/language-context';
import { PlatformUserProvider, PlatformUserStateContext } from './contexts/platform-user-context';
import Routes from './routes/Routes';
import RequestNewLink from './views/wizard/RequestNewLink';
import HandleCaught_API_Error from "./apiClients/api_caughtError";

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/cjs/packages/react-query-devtools/src/devtools').then(d => ({
    default: d.ReactQueryDevtools,
  }))
)

const multiContextMap = { GlobalContext, PlatformUserStateContext };
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 5, // 2 seconds
      cacheTime: 1000 * 2,
      refetchOnWindowFocus: false,
      // refetchOnReconnect : false,
      onError: (error) => {
        HandleCaught_API_Error(error);
        console.error(error);
      }
    },
  },
})

export default function FortifyApp() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
  } = useAuth0();
  const history = useHistory();
  const appInsights = getAppInsights();
  const [showDevtools, setShowDevtools] = useState(false)
  useEffect(() => {
    // Update the document title using the browser API
    // This is to give super users indication of process state
    document.title = `Logged in ${user?.name}`;
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools(old => !old)
  }, [isLoading]);
  // console.log("envirement: ", process.env.NODE_ENV);

  if (error) {
    console.error("Error in auth:", error);
    return <div>Oops... {error.message}</div>;
  }
  if (isLoading) {
    return <Loading />
  }

  // Contexts
  // User
  // Menu items
  // Current Org
  // Language
  // Roles
  if (isAuthenticated) {
    if (user !== undefined && appInsights !== undefined) {
      console.debug("Telemetry - Setting user identification");
      appInsights?.setAuthenticatedUserContext(user.sub?.replaceAll('|', '/') ?? "", undefined, true);
    }
    return (
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <GlobalProvider>
          <PlatformUserProvider>
            <LanguageProvider>
              <EnableContext />
              <Layout>
                <Routes auth0User={user} history={history} />
              </Layout>
              <ToastContainer position='bottom-right' hideProgressBar={true} autoClose={3000} />
            </LanguageProvider>
          </PlatformUserProvider>
        </GlobalProvider>
        {showDevtools ? (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </React.Suspense>
        ) : null}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  } else {
    // This should not be necesary since this is a protected route
    // but requesting a new link is not authenticated, there code here
    let params = (new URL(window.location.toString())).searchParams;
    let questionnaire = params.get("questionnaire");

    if (window.location.toString().indexOf('/questionnaires/') > 0) {
      var pathArray = window.location.pathname.split('/');
      questionnaire = pathArray[pathArray.length - 1];
    }

    if (questionnaire && window.location.toString().indexOf('access_token') < 0) {
      // ALLOW REQUEST NEW CODE
      return <RequestNewLink questionnaire={questionnaire} />
    }

    loginWithRedirect();
    return <Loading />
  }
}