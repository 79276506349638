import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// User API URL
const USERS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Users`;

// Company API URL
const COMPANY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Organizations`;

export const createUserAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${USERS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update User API
export const updateUserAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${USERS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get User By ID
export const getUserByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all Users of an Organization
export const getUsersAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}/UserList`, { headers: headers })
    .then(({ data }) => data);
};

// Get User Detais By ID
export const getPartnerUserDetailsByIdAPI = async (userId, partnerId) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}/${userId}/${partnerId}/UserDetail`, {
      headers: headers
    })
    .then(res => res);
};

// Delete By ID
export const deleteUserByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .delete(`${USERS_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Get all Companies
export const getCompaniesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Get all Users of an Organization
export const getUsersByPartnerIdAPI = async organizationID => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}/${organizationID}/UserList`, { headers: headers })
    .then(({ data }) => data);
};

// Get Partner All Users
export const getPartnerAllUsersByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}/${id}/PartnerAllUsers`, { headers: headers })
    .then(({ data }) => data);
};

// Get Specific Users List
export const getCompanyUsersByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}/${id}/CompanyAllUsers`, { headers: headers })
    .then(({ data }) => data);
};
