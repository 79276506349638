import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import TranslatableText from "../../../../../../Language/TranslatableText";
import { Link, withRouter } from "react-router-dom";
import {
  getPanelByIdAPI,
  getPanelQuestions,
  getPanelTranslationsAPI,
  deletePanelTranslationByIdAPI
} from "./panelsAPI";
import {
  faInfoCircle,
  faTrash,
  faEdit,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import Gap from "../../../../../../components/gap/Gap";
import { deleteQuestionByIdAPI } from "../questions/questionsAPI";
import { LanguageContext } from "../../../../../../contexts/language-context";
import Accent from "../../../../../../components/accent/Accent";
import { toast } from "react-toastify";
import { Config } from "../../../../../../config/Config";
import Swal from "sweetalert2";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { InlineLoading } from "../../../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>No data available</span>
  </div>
);
const defaultSorted = [
  {
    dataField: "order", // if dataField is not match to any column you defined, it will be ignored.
    order: "asc" // desc or asc
  }
];

class PanelDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      tableLoader: false,
      deleteLoader: false,
      pageTitle: "",
      pageOrder: "",
      name: "",
      order: "",
      pageId: "",
      activitiesModal: false,
      deleteModal: false,
      panelquestionId: null,
      questionsDropdown: [],
      questionId: "",
      questionsData: [],
      panelColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "questionCode",
          text: "Question Code",
          sort: true,
          classes: "recordID"
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "order",
          text: "Order",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ],
      translations: [],
      translationsColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "title",
          text: "Title",
          sort: true
        },
        {
          dataField: "description",
          text: "Description",
          sort: true
        },

        {
          dataField: "language",
          text: "Language",
          formatter: (cell, row) => {
            let language = this.searchArray(cell, Config.languages, "value");
            if (language === undefined) {
              language = "dk";
            }
            return language.name;
          },
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/wizard/panel/translations/update/${this.props.match.params.id}/${row.id}`}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
                <NavLink onClick={e => this.deleteRecord(row.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
          }
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getPanelByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            pageTitle: res.data.pageTitle,
            pageOrder: res.data.pageOrder,
            name: res.data.name,
            order: res.data.order,
            pageId: res.data.pageId
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
    }

    this.populateQuestionsTable(
      this.props.match.params.id,
      this.context.language
    );

    this.populatePanelTranslationsTable();
  }

  populatePanelTranslationsTable = () => {
    this.setState({ tableLoader: true, translations: [] });
    getPanelTranslationsAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res.data)) {
          console.log("debug: ", res.data);
          return;
        }
        if (res.data.length > 0) {
          if (res.data.length == Config.languages.length) {
            this.setState({ isDisabled: true });
          } else {
            this.setState({ isDisabled: false });
          }
          this.setState({ translations: res.data, tableLoader: false });
        } else {
          this.setState({ translations: [], tableLoader: false });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            panelquestionId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      panelquestionId: id
    });
  };

  deletePanelQuestion = id => {
    this.setState({ deleteLoader: true });
    deleteQuestionByIdAPI(id)
      .then(res => {
        this.populateQuestionsTable(
          this.props.match.params.id,
          this.context.language
        );
        this.setState({
          deleteModal: false,
          panelquestionId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          panelquestionId: null,
          deleteLoader: false
        });
      });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  populateQuestionsTable = (id, lang) => {
    this.setState({ tableLoader: true, questionsData: [] });
    getPanelQuestions(id, lang)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var self = this;
        var data = res
          .filter(function (el) {
            if (el.name === "") {
              return false; // skip
            }
            return true;
          })
          .map(function (el) {
            var o = Object.assign({}, el);
            o.actions = (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`/admin/wizard/question/details/${el.questionId}`}
                >
                  <Tippy placement="top" content="See details">
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink
                  tag={Link}
                  to={`/admin/wizard/question/update/${el.questionId}`}
                >
                  <Tippy placement="top" content="Update">
                    <span>
                      <FontAwesomeIcon icon={faEdit} size="1x" />
                    </span>
                  </Tippy>
                </NavLink>
                <NavLink onClick={e => self.openDeleteModal(el.questionId, e)}>
                  <Tippy placement="top" content="Remove">
                    <span>
                      <FontAwesomeIcon
                        style={{ color: "#d84a4a" }}
                        icon={faTrash}
                        size="1x"
                      />
                    </span>
                  </Tippy>
                </NavLink>
              </div>
            );
            return o;
          });
        console.log(data);
        this.setState({ questionsData: data, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  deleteRecord = id => {
    this.setState({ tableLoader: true }, () => {
      Swal.fire({
        title: this.context.languages["swal.are_you_sure"],
        text: this.context.languages["swal.are_you_sure.delete_record"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.context.languages[
          "swal.are_you_sure.confirmButtonText"
        ],
        cancelButtonText: this.context.languages[
          "swal.are_you_sure.cancelButtonText"
        ]
      }).then(result => {
        if (result.value) {
          this.setState({ deleteLoader: true });
          deletePanelTranslationByIdAPI(
            id,
            this.context.user.organizations.userId
          )
            .then(res => {
              this.populatePanelTranslationsTable();
              this.setState({
                deleteLoader: false
              });
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
              this.setState({
                deleteLoader: false
              });
            });
          Swal.fire(
            this.context.languages["swal.deleted"],
            this.context.languages["swal.deleted_record_deleted"],
            "success"
          );
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.setState(
            {
              tableLoader: false
            },
            () => {
              Swal.fire(
                this.context.languages["swal.cancelled"],
                this.context.languages["swal.cancelled_your_record_is_safe"],
                "error"
              );
            }
          );
        }
      });
    });
  };

  render() {
    let {
      questionsData,
      panelColumns,
      tableLoader,
      translations,
      translationsColumns,
      isDisabled
    } = this.state;
    return (
      <div className="user-details-view">
        <div className="relative">
          <NavLink
            className="absolute right edit"
            tag={Link}
            to={`/admin/wizard/panel/update/${this.props.match.params.id}`}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </NavLink>
        </div>
        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="panel.panel_details" />
            </Accent>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {this.state.pageTitle !== "" ? (
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <TranslatableText translationKey="panel.page_title" />
                    </td>
                    <td>{this.state.pageTitle}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="panel.page_order" />
                    </td>
                    <td>{this.state.pageOrder}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="name" />
                    </td>
                    <td>{this.state.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="page.order" />
                    </td>
                    <td>{this.state.order}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="panel.page_id" />
                    </td>
                    <td>{this.state.pageId}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="center-align">
                <InlineLoading />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="panel.panel_questions" />
            </Accent>
            <Gap size="10px" />
            <div className="table-container">
              <ToolkitProvider
                keyField="questionId"
                data={questionsData}
                columns={panelColumns}
                search
                exportCSV={{
                  fileName: "data.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/admin/wizard/question/create/${this.props.match.params.id}`}
                            >
                              <TranslatableText translationKey="create" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                      pagination={paginationFactory()}
                      defaultSorted={defaultSorted}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="panel.panel_translations" />
            </Accent>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={translations}
                columns={translationsColumns}
                search
                exportCSV={{
                  fileName: "data.csv",
                  separator: this.context.user.language === 'dk' ? ';' : ',',
                  ignoreHeader: false,
                  noAutoBOM: false
                }}
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <div className="flex space-between">
                          {this.context.userRoles.find(r =>
                            r.includes("Developer")
                          ) !== undefined ? (
                            <ExportCSVButton
                              className="export-btn"
                              {...props.csvProps}
                            >
                              <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                          ) : (
                            <></>
                          )}
                          <div>
                            <NavLink
                              className="button-primary"
                              tag={Link}
                              to={`/admin/wizard/panel/translations/add/${this.props.match.params.id}`}
                              disabled={isDisabled ? true : null}
                            >
                              <TranslatableText translationKey="answeroptionset.add_translation" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>Back</Button>
          </Col>
        </Row>
        <div className="modals">
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              <TranslatableText translationKey="confirm_remove" />
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    {" "}
                    <TranslatableText translationKey="panel.confirm_delete_question" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e =>
                    this.deletePanelQuestion(this.state.panelquestionId, e)
                  }
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(PanelDetails);
PanelDetails.contextType = GlobalContext;