import { Systems, ISystems } from './api_client';

export class SystemSettings implements ISystemSettings {
    ShowLanguageSelect = true;
    wizard_top_navigation = false;
    enable_modules_menu = false;
    enable_compane_admin = true;
    enable_approvals = false;
    enable_flow_engine = false;
    enable_sharepoint_configuration = false;
    wizard_enable_save = false;
    enable_search_to_wizard = false;
    enable_save_finish_trigger_to_wizard = false;
    enable_support_btn = false;
    enable_guide_btn = false;
    nav_menu_color = "";
    guide_url = "";
    reporting: string[] | string = [];
    entity_report = "";

    constructor(data?: ISystemSettings) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.ShowLanguageSelect = _data["ShowLanguageSelect"];
            this.wizard_top_navigation = _data["wizard_top_navigation"];
            this.enable_modules_menu = _data["enable_modules_menu"];
            this.enable_compane_admin = _data["enable_compane_admin"];
            this.enable_approvals = _data["enable_approvals"];
            this.enable_flow_engine = _data["enable_flow_engine"];
            this.enable_sharepoint_configuration = _data["enable_sharepoint_configuration"];
            this.wizard_enable_save = _data["wizard_enable_save"];
            this.enable_search_to_wizard = _data["enable_search_to_wizard"]
            this.enable_save_finish_trigger_to_wizard= _data["enable_save_finish_trigger_to_wizard"]
            this.enable_support_btn = _data["enable_support_btn"]
            this.enable_guide_btn = _data["enable_guide_btn"]
            if (Array.isArray(_data["reporting"])) {
                this.reporting = [] as string[];
                for (let item of _data["reporting"])
                    this.reporting.push(item);
            }
            this.entity_report = _data["entity_report"]
        }
    }

    static fromJS(data: any): SystemSettings {
        data = typeof data === 'object' ? data : {};
        let result = new SystemSettings();
        result.init(data);
        return result;
    }
}
export interface ISystemSettings {
    ShowLanguageSelect: boolean
    wizard_top_navigation: boolean
    enable_modules_menu: boolean
    enable_compane_admin: boolean
    enable_approvals: boolean
    enable_flow_engine: boolean
    enable_sharepoint_configuration: boolean
    wizard_enable_save: boolean
    enable_search_to_wizard: boolean
    enable_save_finish_trigger_to_wizard: boolean
    enable_support_btn: boolean
    enable_guide_btn: boolean
    nav_menu_color: string
    guide_url: string
    reporting: string[] | string
    entity_report: string
}

export class ExtendedSystems extends Systems {
    systemSettings: SystemSettings = new SystemSettings();

    constructor(data?: ISystems) {
        super(data)
        if (data) {
            this.systemSettings = JSON.parse(this.settings!);
        }
    }

    toSystems(): Systems {
        let result = new Systems(this);
        result.settings = JSON.stringify(this.systemSettings);
        return result;
    }
    static fromSystems(_data: Systems): ExtendedSystems {
        let result = new ExtendedSystems();
        result.init(_data)
        result.systemSettings = JSON.parse(_data.settings!);
        return result;
    }
}

export interface IExtendedSystems extends ISystems {
    systemSettings: ISystemSettings
}

