import {
    faEdit, faTrash,
    faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {
    CSVExport, Search
} from "react-bootstrap-table2-toolkit";
import { useHistory } from 'react-router';
import { Link } from "react-router-dom";
import { Button, Col, NavLink, Row } from "reactstrap";
import Swal from 'sweetalert2';
import { GetProcessResponse, GetProcessTranslationResponse, ProcessesClient } from '../../apiClients';
import HandleCaught_API_Error from '../../apiClients/api_caughtError';
import { Config } from '../../config/Config';
import { LanguageContext } from '../../contexts';
import { GlobalContext } from '../../contexts/global-context';
import TranslatableText from '../../Language';
import Accent from '../accent/Accent';
import Dendro from '../diagrams/dendrodiagram/Dendro';
import Gap from '../gap/Gap';
import { InlineLoading } from '../loading';


type ProcessDetailsProps = {
    paramsId: string
}



const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
    <div className="text-center">
        <span>
            <TranslatableText translationKey="no_data_available" />
        </span>
    </div>
);

const renderTableHeader = () => {
    return (
        <tr className="thead">
            <th style={{width: '30%'}}>Name</th>
            <th style={{width: '70%'}}>Description</th>
        </tr>
    );
};

const searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
        if (array[i][selector] === key) {
            return array[i];
        }
    }
};

const renderTableData = customField => {
    if (customField.displayTypeCode === 1007)
        if (customField.tagBoxValues.length > 0) {
            return customField.tagBoxValues.map((field, index) => (
                <tr key={index}>
                    <td>{field.label}</td>
                    <td>{field.description}</td>
                </tr>
            ));
        } else {
            return (
                <tr>
                    <td colSpan={2}>
                        <TranslatableText translationKey="no_data_available" />
                    </td>
                </tr>
            );
        }
};

const ProcessDetails: React.FC<ProcessDetailsProps> = (props) => {

    const { paramsId } = props;
    const { user, userRoles, languages } = useContext(GlobalContext);
    const [processDetails, setProcessDetails] = useState<GetProcessResponse>(new GetProcessResponse());
    const [customFields, setCustomFields] = useState<any>([]);
    const [translations, setTranslations] = useState<GetProcessTranslationResponse[]>([]);

    const [isDisabled, setIsDisabled] = useState(false);
    const [tableLoader, setTableLoader] = useState(false);
    const language = useContext(LanguageContext);
    const history = useHistory();
    const _ProcessesClient = new ProcessesClient();
    const columnsData = [
        {
            dataField: "id",
            text: "ID",
            hidden: true
        },
        {
            dataField: "nameShort",
            text: "Name",
            sort: true
        },
        {
            dataField: "descriptionShort",
            text: "Description",
            sort: true
        },
        {
            dataField: "language",
            text: "Language",
            formatter: (cell, row) => {
                let language = searchArray(cell, Config.languages, "value");
                if (language === undefined) {
                    language = "dk";
                }
                return language.name;
            },
            sort: true
        },
        {
            dataField: "actions",
            text: "Actions",
            csvExport: false,
            formatter: (cell, row) => {
                return (
                    <div className="actions">
                        <NavLink
                            tag={Link}
                            to={`/company-admin/process/translations/update/${paramsId}/${row.id}`}
                        >
                            <FontAwesomeIcon icon={faEdit} size="1x" />
                        </NavLink>
                        <NavLink onClick={e => deleteRecord(row.id)}>
                            <FontAwesomeIcon
                                style={{ color: "#d84a4a" }}
                                icon={faTrash}
                                size="1x"
                            />
                        </NavLink>
                    </div>
                );
            }
        }
    ];
    const [columns, setColumns] = useState(columnsData);
    useEffect(() => {
        setColumns(columnsData);
    }, [languages])
    useEffect(() => {
        populateProcessTranslations()
        Promise.all([
            _ProcessesClient.processes(paramsId)
                .then(res => {
                    setProcessDetails(res)
                    setCustomFields(
                        res.customFieldValues === null
                            ? []
                            : res.customFieldValues)
                })
                .catch(error => {
                    HandleCaught_API_Error(error);
                })
        ])

    }, [paramsId]);
    const populateProcessTranslations = () => {
        setTableLoader(true);
        setTranslations([]);
        _ProcessesClient.processTranslations(paramsId)
            .then(res => {
                if (!Array.isArray(res)) {
                    console.log("debug: ", res);
                    return;
                }
                if (res.length == Config.languages.length) {
                    setIsDisabled(true)
                } else {
                    setIsDisabled(false)
                }
                setTranslations(res);
                setTableLoader(false);
            })
            .catch(error => {
                setTableLoader(false);
                HandleCaught_API_Error(error);
            });
    };
    const deleteRecord = id => {
        Swal.fire({
            // title: language?.languageData.languageData["swal.are_you_sure"],
            title: languages["swal.are_you_sure.delete_record"],
            text: languages["swal.are_you_sure.delete_record"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: languages[
                "swal.are_you_sure.confirmButtonText"
            ],
            cancelButtonText: languages[
                "swal.are_you_sure.cancelButtonText"
            ]
        }).then(result => {
            if (result.value) {
                _ProcessesClient.deleteProcessTranslation(id).then(res => {
                    populateProcessTranslations();
                    Swal.fire(
                        languages["swal.deleted"],
                        languages["swal.deleted_record_deleted"],
                        "success"
                    );
                });
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                setTableLoader(false)
                Swal.fire(
                    languages["swal.cancelled"],
                    languages["swal.cancelled_your_record_is_safe"],
                    "error"
                );

            }
        }).catch(error => {
            setTableLoader(false);
            HandleCaught_API_Error(error);
        });
    };

    const goBack = () => {
        history.goBack();
    };
    return (
        <>
            <div className="process-details-view">
                <div className="relative">
                    <NavLink
                        className="absolute right edit"
                        tag={Link}
                        to={`/company-admin/process/update/${paramsId}`}
                    >
                        <FontAwesomeIcon icon={faEdit} size="1x" />
                    </NavLink>
                </div>
                <Row form>
                    <Col md={12}>
                        <Accent useIcon={false}>{processDetails.nameShort}</Accent>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                                {JSON.stringify(processDetails) === JSON.stringify({}) ? (
                                    <InlineLoading />
                                ) : (
                                    <>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <TranslatableText translationKey="name" />
                                                    </td>
                                                    <td>{processDetails.name}</td>
                                                </tr>
                                                <tr>
                                                    <td><TranslatableText translationKey="processes.description" /></td>
                                                    <td dangerouslySetInnerHTML={{ __html: processDetails.descriptionShort! }} />
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <TranslatableText translationKey="responsible" />
                                                    </td>
                                                    <td>{processDetails.responsible}</td>
                                                </tr>
                                                {customFields.map((element, key) =>
                                                    element.displayTypeCode !== 1007 ? (
                                                        <tr key={key}>
                                                            <td>{element.displayText}</td>
                                                            <td>
                                                                {element.value === true
                                                                    ? "Yes"
                                                                    : element.value === false
                                                                        ? "No"
                                                                        : element.displayTypeCode === 1007
                                                                        // @ts-ignore
                                                                            ? this.arrayFormatter(element.tagBoxValues)
                                                                            : element.value}
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                        {processDetails.nameShort !== "" && customFields.length > 0 && (
                                            <Row>
                                                <Col xs={{ size: 3 }} md={{ size: 3 }} lg={{ size: 3 }}></Col>
                                                <Col xs={{ size: 8 }} md={{ size: 8 }} lg={{ size: 8 }}>
                                                    <Dendro entity={processDetails.nameShort} data={customFields} />
                                                </Col>

                                                <Gap size="50px" />
                                            </Row>
                                        )}
                                        <Gap size="50px" />
                                        <div className="custom-fields-section">
                                            {customFields.map((customField, index) =>
                                                customField.displayTypeCode === 1007 ? (
                                                    <div key={index}>
                                                        <Accent useHtmlIcon={false}>
                                                            {customField.displayText}
                                                        </Accent>
                                                        <table className="table table-bordered ">
                                                            <thead>{renderTableHeader()}</thead>
                                                            <tbody>{renderTableData(customField)}</tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    ""
                                                )
                                            )}
                                        </div>
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <Accent useIcon={false}>
                                    <TranslatableText translationKey="translations" />
                                </Accent>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <div className="table-container">
                                    <ToolkitProvider
                                        keyField="id"
                                        data={translations}
                                        columns={columns}
                                        search
                                        exportCSV={{
                                            fileName: "data.csv",
                                            separator: user.language === 'dk' ? ';' : ',',
                                            ignoreHeader: false,
                                            noAutoBOM: false
                                        }}
                                    >
                                        {props => (
                                            <div>
                                                <div className="flex space-between">
                                                    <div>
                                                        <SearchBar {...props.searchProps} />
                                                    </div>
                                                    <div>
                                                        <div className="flex space-between">
                                                            {userRoles.find(r =>
                                                                r.includes("Developer")
                                                            ) !== undefined ? (
                                                                <ExportCSVButton
                                                                    className="export-btn"
                                                                    {...props.csvProps}
                                                                >
                                                                    <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <div>
                                                                <NavLink
                                                                    className="button-primary"
                                                                    tag={Link}
                                                                    to={`/company-admin/process/translations/add/${paramsId}`}
                                                                    disabled={isDisabled}
                                                                >
                                                                    <TranslatableText translationKey="answeroptionset.add_translation" />
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <BootstrapTable
                                                    noDataIndication={() =>
                                                        tableLoader ? (
                                                            <InlineLoading />
                                                        ) : (
                                                            <NoDataIndication />
                                                        )
                                                    }
                                                    {...props.baseProps}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="space-between flex" md={12}>
                        <Button onClick={goBack}>
                            <TranslatableText translationKey="back_to_list" />
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ProcessDetails;