import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Company API URL
const COMPANY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Organizations`;

export const createPartnerAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${COMPANY_API_URL}/Partner`, data, { headers: headers })
    .then(res => res);
};

// Update Partner API
export const updatePartnerAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${COMPANY_API_URL}/Partner`, data, { headers: headers })
    .then(res => res);
};

// Get Partner By ID
export const getPartnerByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all partners
export const getPartners = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/Partners`, { headers: headers })
    .then(({ data }) => data);
};

// Get all Organizations of a partner
export const getPartnerCompaniesAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/${id}/Companies`, { headers: headers })
    .then(({ data }) => data);
};

// Switch default project
export const switchProject = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/ChangeProject/${id}`, { headers: headers })
    .then(res => res);
};
