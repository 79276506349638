import axios from "axios";
import { Config } from "../../../config/Config";
import authService from "../../../components/api-authorization/AuthorizeService";

// Company API URL
export const COMPANY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Organizations`;

// Switch default project
export const switchProject = async (id: string) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/ChangeProject/${id}`, { headers: headers })
    .then(res => res);
};
