import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faNewspaper,
  faEdit,
  faTrash
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import TranslatableText from "../../../../Language/TranslatableText";
import { withRouter, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { getCompanyByIdAPI } from "./firmAPI";
import Gap from "../../../../components/gap/Gap";
import Accent from "../../../../components/accent/Accent";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import {
  getReportTemplatesOfCompanyByIdAPI,
  deleteReportTemplateByIdAPI
} from "../../../admin/views/wizard/wizardAPI";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>No data available</span>
  </div>
);

class FirmDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      partnerDetails: {},
      templates: [],
      templateColumns: [
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "description",
          text: "Description",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions"
        }
      ],
      deleteLoader: false,
      deleteTemplateModal: false,
      reportTemplateId: null
    };
  }
  componentDidMount() {
    getCompanyByIdAPI(this.props.match.params.id)
      .then(res => {
        this.setState({ partnerDetails: res.data });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    this.populateReportTemplates();
  }

  populateReportTemplates = () => {
    var self = this;
    this.setState({ tableLoader: true, templates: [] });
    getReportTemplatesOfCompanyByIdAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink
                tag={Link}
                to={`/company-admin/company/template/update/${self.props.match.params.id}/${el.id}`}
              >
                <Tippy placement="top" content="Update Template">
                  <span>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink onClick={e => self.openDeleteTemplateModal(el.id, e)}>
                <Tippy placement="top" content="Delete Template">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
          return o;
        });
        this.setState({
          templates: data,
          language: this.context.language,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeleteTemplatePopup = () => {
    this.setState(
      prevState => ({
        deleteTemplateModal: !prevState.deleteTemplateModal
      }),
      () => {
        if (this.state.deleteTemplateModal === false) {
          this.setState({
            reportTemplateId: null
          });
          console.log("After Modal Dismissal", this.state.deleteTemplateModal);
        }
      }
    );
  };

  openDeleteTemplateModal = id => {
    this.setState({
      deleteTemplateModal: true,
      reportTemplateId: id
    });
  };

  deleteReportTemplate = id => {
    this.setState({ deleteLoader: true });
    deleteReportTemplateByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populateReportTemplates();
        this.setState({
          deleteTemplateModal: false,
          deleteLoader: false,
          reportTemplateId: null
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteTemplateModal: false,
          deleteLoader: false,
          reportTemplateId: null
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let { tableLoader } = this.state;
    return (
      <div className="partner-view">
        <h1>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            size="1x"
            onClick={this.goBack}
            className="cursor"
          />{" "}
          <TranslatableText translationKey="company.company_information" />
        </h1>
        <Accent logo={this.state.partnerDetails.logo}>
          {this.state.partnerDetails.name}
        </Accent>
        <Gap size="50px" />
        <Row>
          <Col md={12}>
            {JSON.stringify(this.state.partnerDetails) !==
              JSON.stringify({}) ? (
              <Table>
                <thead>
                  <tr>
                    <th>
                      <TranslatableText translationKey="company.element" />
                    </th>
                    <th>
                      <TranslatableText translationKey="value" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(this.state.partnerDetails)
                    .filter(function (el) {
                      if (
                        el === "id" ||
                        el === "systemIds" ||
                        el === "logo" ||
                        el === "releasePhase" ||
                        el === "partnerId" ||
                        el === "name" ||
                        el === "phone" ||
                        el === "email" ||
                        el === "postalCode" ||
                        el === "dbConnectionString" ||
                        el === "reportName"
                      ) {
                        return false; // skip
                      }
                      return true;
                    })
                    .map(key => (
                      <tr key={key}>
                        <td className="text-capitalize">{key}</td>
                        <td>{this.state.partnerDetails[key]}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              <div className="center-align">
                <InlineLoading />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accent useIcon={true} icon={faNewspaper}>
              <TranslatableText translationKey="report_templates" />
            </Accent>
          </Col>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={this.state.templates}
                columns={this.state.templateColumns}
                search
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`/company-admin/company/template/create/${this.props.match.params.id}`}
                        >
                          <TranslatableText translationKey="create" />
                        </NavLink>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                      pagination={paginationFactory()}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteTemplateModal}
            toggle={this.toggleDeleteTemplatePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteTemplatePopup}>
              <TranslatableText translationKey="confirm_delete" />?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="company.confirm_delete_template_description" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e =>
                    this.deleteReportTemplate(this.state.reportTemplateId, e)
                  }
                >
                  Delete
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeleteTemplatePopup}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(FirmDetails);
FirmDetails.contextType = GlobalContext;