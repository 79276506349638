import {
  faArrowDown,
  faArrowUp,
  faInfoCircle, faTrash, faWindowMaximize, faWindowMinimize
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import classnames from "classnames";
import { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Swal from "sweetalert2";
import { InlineLoading, ScreenLoading } from "../../../../components";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import { GlobalContext } from "../../../../contexts/global-context";
import TranslatableText from "../../../../Language/TranslatableText";
import Editor from "../../../admin/views/wizard/views/questions/editor/Editor";
import {
  getTemplateAction, getTemplateRegardingObjectType
} from "../../../company-admin/views/claims/companyClaimsAPI";
import { getEntitiesAPI } from "../../../entity/entityAPI";
import { getTeamsAPI } from "../teams/teamsAPI";
import { getUsersAPI } from "../users/usersAPI";
import {
  createFlowEngineAPI,
  getFlowEngineByIdAPI,
  updateFlowEngineAPI
} from "./flowsAPI";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);


class CreateFlow extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      name: "",
      description: "",
      regardingObjectId: "",
      regardingObjectsDropdown: [],
      regardingObjectTypeId: "",
      localRegardingObjectTypeId: "",
      actionIds: [],
      selectedActions: [],
      actionDropdown: [],
      filterableActionDropdown: [],
      selectedUsers: [],
      usersIdsDropdown: [],
      usersIds: [],
      users: [],
      teams: [],
      approvalOrderDropdown: [
        { name: "anyOrder", value: 1001 },
        { name: "inSequence", value: 1002 },
        { name: "anyOneUser", value: 1003 }
      ],
      entitiesDropdown: [],
      steps: [],
      selectedSteps: [],
      stepsOptions: [
        { value: "email", label: "Send Email" },
        { value: "api", label: "Call API" },
        { value: "approval", label: "Start Approval" },
        { value: "status", label: "Change Status" }
      ],
      stepType: {
        api: 1001,
        email: 1002,
        approval: 1003,
        status: 1004
      },
      apiMethods: {
        get: 1001,
        post: 1002,
        put: 1003
      },
      statusValues: {
        lock: 1001,
        unlock: 1002
      },
      flowSteps: [],
      flowEngineSteps: [],
      showApprovalFlowStep: false,
      loading: false,
      cuLoader: false,
      recurrenceTypeDropDown: [
        {
          id: "f9b181e4-ba7b-44ba-af4d-08d07462d16e",
          name: "Daily",
          code: 1001
        },
        {
          id: "f9b181e4-ba7b-44ba-af4d-08d72402d15e",
          name: "Weekly",
          code: 1002
        },
        {
          id: "f9b181e4-ba7b-44ba-af4d-08d75402d15e",
          name: "Monthly ",
          code: 1004
        },
        {
          id: "f9b181e4-ba7b-44ba-af4d-08d77462d16e",
          name: "Yearly",
          code: 1008
        }
      ],
      repeatOptions: [
        {
          name: "Repeat",
          value: 1001
        },
        {
          name: "Do not repeat",
          value: 1002
        }
      ],
      isVisibleDM: false,
      isVisibleDW: false,
      isVisibleY: false,
      isVisibleED: true,
      triggerType: "",
      timeTrigger: false,
      dayOfWeek: "",
      dayOfMonth: "",
      monthOfYear: "",
      triggerTime: "",
      repeat: "",
      endDate: "",
      startDate: "",
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    this.populateApprovers();
    this.populateRegardingObjectTypes();
    this.populateActions();

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getFlowEngineByIdAPI(this.props.match.params.id)
        .then(res => {
          let selectedActions = res.data.actions.map(el => {
            var pair = { label: el.name, value: el.id, code: el.code };
            return pair;
          });

          let flow_actions = this.searchArray(1006, selectedActions, "code");
          if (flow_actions !== undefined) {
            this.setState({ timeTrigger: true });
          } else {
            this.setState({ timeTrigger: false });
          }

          let recurrenceType = this.searchArray(
            res.data.recurrenceTypeId,
            this.state.recurrenceTypeDropDown,
            "id"
          );

          if (
            res.data.regardingObjectTypeId !== "" &&
            res.data.regardingObjectTypeId !== null
          ) {
            this.populateEntityRecords(res.data.regardingObjectTypeId);
          }
          this.setState({
            name: res.data.name,
            description: res.data.description,
            flowSteps: res.data.flowEngineSteps,
            localRegardingObjectTypeId: res.data.regardingObjectTypeId,
            regardingObjectTypeId: res.data.regardingObjectTypeId,
            regardingObjectId:
              res.data.regardingObjectId === null
                ? ""
                : res.data.regardingObjectId,
            selectedActions: selectedActions,
            actionIds: selectedActions.map(row => row.value),
            triggerTimeDatePicker:
              res.data.triggerTime === null
                ? ""
                : this.stringToTimestamp(res.data.triggerTime),
            endDatePicker:
              res.data.endDate === null
                ? ""
                : this.dateFormatter(res.data.endDate),
            startDatePicker:
              res.data.startDate === null
                ? ""
                : this.dateFormatter(res.data.startDate),
            endDate:
              res.data.endDate === null
                ? ""
                : new Date(res.data.endDate).toISOString(),
            startDate:
              res.data.startDate === null
                ? ""
                : new Date(res.data.startDate).toISOString(),
            triggerTime:
              res.data.triggerTime === null ? "" : res.data.triggerTime,
            dayOfWeek: res.data.dayOfWeek === null ? "" : res.data.dayOfWeek,
            dayOfMonth: res.data.dayOfMonth === null ? "" : res.data.dayOfMonth,
            monthOfYear:
              res.data.monthOfYear === null ? "" : res.data.monthOfYear,
            recurrenceTypeId:
              res.data.recurrenceTypeId === null
                ? ""
                : res.data.recurrenceTypeId,
            repeat: res.data.repeat === null ? "" : res.data.repeat,
            loading: false
          });
          if (
            recurrenceType !== undefined &&
            recurrenceType !== null &&
            recurrenceType !== ""
          ) {
            this.showFieldsOnRecurrenceType(recurrenceType.name.trim());
          }
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    } else {
      if (
        this.props.match.params.regardingObjectTypeId !== undefined &&
        this.props.match.params.regardingObjectId !== undefined
      ) {
        this.setState({
          regardingObjectTypeId: this.props.match.params.regardingObjectTypeId,
          regardingObjectId: this.props.match.params.regardingObjectId
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      entitiesDropdown,
      localRegardingObjectTypeId,
      filterableActionDropdown
    } = this.state;
    if (
      localRegardingObjectTypeId &&
      entitiesDropdown.length &&
      (prevState.localRegardingObjectTypeId !== localRegardingObjectTypeId ||
        prevState.entitiesDropdown !== entitiesDropdown)
    ) {
      const selectedRegardingObjectTypeId = entitiesDropdown.filter(item => {
        return item.value === localRegardingObjectTypeId;
      })[0];
      this.setState({ selectedRegardingObjectTypeId });

      // only to show/hide the approval flow step
      // will be removed in future
      if (
        selectedRegardingObjectTypeId.code === 1006 ||
        selectedRegardingObjectTypeId.code === 1000 ||
        selectedRegardingObjectTypeId.code === 1001
      ) {
        this.setState({ showApprovalFlowStep: true });
      } else {
        this.setState({ showApprovalFlowStep: false });
      }
      // only to show/hide the approval flow step
    }
    // only for handling cascaded dropdowns:
    if (
      localRegardingObjectTypeId &&
      entitiesDropdown.length &&
      (prevState.localRegardingObjectTypeId !== localRegardingObjectTypeId ||
        prevState.entitiesDropdown !== entitiesDropdown ||
        prevState.filterableActionDropdown !== filterableActionDropdown)
    ) {
      const selectedRegardingObjectTypeId = entitiesDropdown.filter(item => {
        return item.value === localRegardingObjectTypeId;
      })[0];
      console.log("filterableActionDropdown", filterableActionDropdown);
      let actions = [];
      if (selectedRegardingObjectTypeId.code === 1006) {
        actions = filterableActionDropdown.filter(item => {
          return item.code === 1005 || item.code === 1006;
        });
      } else if (selectedRegardingObjectTypeId.code === 1001) {
        actions = filterableActionDropdown.filter(item => {
          return item.code === 1001 || item.code === 1003 || item.code === 1006 || item.code === 3001;
        });
      } else {
        actions = filterableActionDropdown.filter(item => {
          return item.code === 1001 || item.code === 1003 || item.code === 1006;
        });
      }

      this.setState({
        actionDropdown: actions
      });
    }
    // only for handling cascaded dropdowns:

    // => Handle specific records and auto populate them
    if (
      entitiesDropdown.length &&
      filterableActionDropdown.length &&
      this.props.match.params.regardingObjectTypeId !== undefined &&
      this.props.match.params.id === undefined &&
      (prevState.entitiesDropdown !== entitiesDropdown ||
        prevState.filterableActionDropdown !== filterableActionDropdown)
    ) {
      console.log("here............");
      const selectedRegardingObjectTypeId = entitiesDropdown.filter(item => {
        return item.value === this.props.match.params.regardingObjectTypeId;
      })[0];
      this.setState(
        {
          selectedRegardingObjectTypeId: selectedRegardingObjectTypeId,
          regardingObjectId: this.props.match.params.regardingObjectId
        },
        () => {
          this.populateEntityRecords(
            this.props.match.params.regardingObjectTypeId
          );
          // Filter actions
          let actions = [];
          if (
            selectedRegardingObjectTypeId.code === 1006 ||
            selectedRegardingObjectTypeId.code === 1000 ||
            selectedRegardingObjectTypeId.code === 1001
          ) {
            actions = this.state.filterableActionDropdown.filter(item => {
              return (
                item.code === 1005 || item.code === 1006 || item.code === 1007
              );
            });
            //show approval step
            this.setState({ showApprovalFlowStep: true });
          } else {
            actions = this.state.filterableActionDropdown.filter(item => {
              return (
                item.code === 1001 ||
                item.code === 1003 ||
                item.code === 1006 ||
                item.code === 1007
              );
            });
            //hide approval step
            this.setState({ showApprovalFlowStep: false });
          }

          this.setState({
            actionDropdown: actions
          });
        }
      );
    }
    // => Handle specific records and auto populate them
  }

  populateEntityRecords = regardingObjectTypeId => {
    getEntitiesAPI(regardingObjectTypeId)
      .then(res => {
        if (res !== undefined && Array.isArray(res)) {
          this.setState({ regardingObjectsDropdown: res });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  populateApprovers = () => {
    let usersIds = [];
    let teams = [];
    getUsersAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        let sortedUsers = res.sort(function (a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        sortedUsers.forEach(function (row) {
          usersIds.push({ label: row.name, value: row.id });
        });
        getTeamsAPI(this.context.user.organizations.organizationId)
          .then(res => {
            if (!Array.isArray(res)) {
              console.log("debug: ", res);
              return;
            }
            let sortedTeams = res.sort(function (a, b) {
              var textA = a.name.toUpperCase();
              var textB = b.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            sortedTeams.forEach(function (row) {
              teams.push({ label: row.name, value: row.id });
            });

            let groupedOptions = [
              {
                label: "Teams",
                options: teams
              },
              {
                label: "Users",
                options: usersIds
              }
            ];

            this.setState({
              teams: teams,
              users: usersIds,
              usersIdsDropdown: groupedOptions
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
          });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  populateActions = () => {
    getTemplateAction().then(res => {
      if (!Array.isArray(res)) {
        console.log("debug: ", res);
        return;
      }
      let actions = [];
      res.forEach(function (row) {
        actions.push({ label: row.name, value: row.id, code: row.code });
      });
      this.setState({
        filterableActionDropdown: actions
      });
    });
  };

  populateRegardingObjectTypes = () => {
    getTemplateRegardingObjectType()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        let entities = [];
        res.forEach(function (row) {
          entities.push({
            label: row.displayName,
            value: row.id,
            code: row.code
          });
        });
        this.setState({ entitiesDropdown: entities });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          answerDisplayTypeDropdown: [],
          showEntityDropdown: true,
          entityLoader: false
        });
      });
  };

  deleteStep = selectedItem => {
    Swal.fire({
      title: this.context.languages["swal.are_you_sure"],
      text: this.context.languages["swal.are_you_sure.confirm_delete_step"],
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.context.languages[
        "swal.are_you_sure.confirmButtonText"
      ],
      cancelButtonText: this.context.languages[
        "swal.are_you_sure.cancelButtonText"
      ]
    }).then(result => {
      if (result.value) {
        const rows = this.state.flowSteps.filter(item => {
          return item.id !== selectedItem.id;
        });
        this.setState({ flowSteps: rows });
        Swal.fire(
          this.context.languages["swal.deleted"],
          this.context.languages["swal.deleted_record_deleted"],
          "success"
        );
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.context.languages["swal.cancelled"],
          this.context.languages["swal.cancelled_your_record_is_safe"],
          "error"
        );
      }
    });
  };

  togglePanel = (idx, selectedItem) => {
    const rows = [...this.state.flowSteps];
    rows[idx].fields.collapsed = !rows[idx].fields.collapsed;
    this.setState({
      flowSteps: rows
    });
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  handleTriggerTime = date => {
    this.setState({
      triggerTimeDatePicker: date,
      triggerTime: this.timeFormatter(date)
    });
  };

  handleEndDate = date => {
    this.setState({
      endDatePicker: date,
      endDate: date.toISOString()
    });
  };

  handleStartDate = date => {
    this.setState({
      startDatePicker: date,
      startDate: date.toISOString()
    });
  };

  showFieldsOnRecurrenceType = selected => {
    switch (selected) {
      case "Daily":
        this.setState({
          isVisibleDW: false,
          isVisibleDM: false,
          isVisibleED: true,
          isVisibleY: false
        });
        break;
      case "Weekly":
        this.setState({
          isVisibleDW: true,
          isVisibleDM: false,
          isVisibleED: true,
          isVisibleY: false
        });
        break;
      case "Bi-Weekly":
        this.setState({
          isVisibleDW: true,
          isVisibleDM: false,
          isVisibleED: true,
          isVisibleY: false
        });
        break;
      case "Monthly":
      case "Bi-Monthly":
      case "Quarterly":
      case "Half-Yearly":
        this.setState({
          isVisibleDW: false,
          isVisibleDM: true,
          isVisibleED: true,
          isVisibleY: false
        });
        break;
      case "Yearly":
        this.setState({
          isVisibleDW: false,
          isVisibleDM: true,
          isVisibleED: true,
          isVisibleY: true
        });
        break;
      default:
        this.setState({
          isVisibleDW: false,
          isVisibleDM: false,
          isVisibleED: true
        });
    }
  };

  getColumnValue = () => {
    let { isVisibleDM, isVisibleDW, isVisibleY } = this.state;
    if (
      (isVisibleDM && !isVisibleDW && !isVisibleY) ||
      (!isVisibleDM && isVisibleDW && !isVisibleY)
    ) {
      return 8;
    } else if ((isVisibleDM && isVisibleDW) || isVisibleY) {
      return 4;
    } else {
      return 12;
    }
  };

  getColumnValueOnRTD = () => {
    let { isVisibleED } = this.state;
    if (isVisibleED) {
      return 4;
    } else {
      return 8;
    }
  };

  setColumnValue = () => {
    let { isVisibleDW, isVisibleDM, isVisibleED, isVisibleY } = this.state;
    if (
      isVisibleDW === false &&
      isVisibleDM === false &&
      isVisibleED === true &&
      isVisibleY === false
    ) {
      //daily
      return 12;
    } else if (
      isVisibleDW === true &&
      isVisibleDM === false &&
      isVisibleED === true &&
      isVisibleY === false
    ) {
      //weekly
      return 8;
    } else if (
      isVisibleDW === false &&
      isVisibleDM === true &&
      isVisibleED === true &&
      isVisibleY === false
    ) {
      // monthly
      return 8;
    } else if (
      isVisibleDW === false &&
      isVisibleDM === true &&
      isVisibleED === true &&
      isVisibleY === true
    ) {
      // yearly
      return 4;
    } else {
      return 12;
    }
  };

  timeFormatter = timestamp => {
    var d = new Date(timestamp), // Convert the passed timestamp to milliseconds
      hh = d.getHours(),
      h = hh,
      min = ("0" + d.getMinutes()).slice(-2), // Add leading 0.
      sec = ("0" + d.getSeconds()).slice(-2), // Add leading 0.
      time;

    // ie: 3:00:00
    time = h + ":" + min + ":" + sec;
    return time;
  };

  dateFormatter = timestamp => {
    return new Date(timestamp);
  };

  stringToTimestamp = time => {
    var d = new Date(), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
      dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
      timestamp;

    // ie: 2014-03-24, 3:00 PM
    timestamp = yyyy + "-" + mm + "-" + dd + " " + time;
    return new Date(timestamp);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  createFlowEngine = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      name: this.state.name,
      regardingObjectTypeId: this.state.regardingObjectTypeId,
      regardingObjectId: this.state.regardingObjectId,
      description: this.state.description,
      actionIds: this.state.actionIds,
      flowEngineSteps: this.state.flowSteps,
      dayOfWeek: this.state.dayOfWeek,
      dayOfMonth: this.state.dayOfMonth,
      monthOfYear: this.state.monthOfYear,
      triggerTime: this.state.triggerTime,
      endDate: this.state.endDate,
      startDate: this.state.startDate,
      recurrenceTypeId: this.state.recurrenceTypeId,
      repeat: this.state.repeat === "" ? null : parseInt(this.state.repeat),
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : ""
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        if (
          (this.state.regardingObjectTypeId !== "" &&
            this.state.regardingObjectId === "" &&
            this.searchArray(1006, this.state.selectedActions, "code") !==
            undefined) ||
          (this.state.repeat === "" &&
            this.searchArray(1006, this.state.selectedActions, "code") !==
            undefined)
        ) {
          toast.error("Please select all required fields", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 5000
          });
        } else {
          this.setState({ cuLoader: true });
          updateFlowEngineAPI(data)
            .then(res => {
              this.setState(
                {
                  cuLoader: false
                },
                () => {
                  this.goBack();
                }
              );
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
              this.setState({ cuLoader: false });
            });
        }
      }
    } else {
      if (validation.isValid) {
        if (
          (this.state.regardingObjectTypeId !== "" &&
            this.state.regardingObjectId === "" &&
            this.searchArray(1006, this.state.selectedActions, "code") !==
            undefined) ||
          (this.state.repeat === "" &&
            this.searchArray(1006, this.state.selectedActions, "code") !==
            undefined)
        ) {
          toast.error("Please select all required fields", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 5000
          });
        } else {
          this.setState({ cuLoader: true });
          createFlowEngineAPI(data)
            .then(res => {
              this.setState(
                {
                  cuLoader: false
                },
                () => {
                  this.goBack();
                }
              );
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
              this.setState({ cuLoader: false });
            });
        }
      }
    }
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  searchValue = (val, array) => {
    if (array.indexOf(val) === -1) {
      return false;
    } else {
      return true;
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "recurrenceTypeId") {
      var selected = target.options[target.selectedIndex].text;
      this.showFieldsOnRecurrenceType(selected.trim());
    }

    this.setState({
      [name]: value
    });
  };

  handleSingleTagboxChange = (state, selectedStateName, selectedOption) => {
    this.setState({
      [state]:
        selectedOption === null || selectedOption === undefined
          ? ""
          : selectedOption.value,
      [selectedStateName]: selectedOption === null ? "" : selectedOption,
      selectedActions: [],
      actionIds: []
    });
    // Populate entity records dropdown
    this.populateEntityRecords(
      selectedOption === null || selectedOption === undefined
        ? ""
        : selectedOption.value
    );
    // Filter actions
    let actions = [];
    if (selectedOption.code === 1006) {
      actions = this.state.filterableActionDropdown.filter(item => {
        return item.code === 1005 || item.code === 1006 || item.code === 1007;
      });
    } else if (selectedOption.code === 1000) {
      actions = this.state.filterableActionDropdown.filter(item => {
        return (
          item.code === 1001 ||
          item.code === 1003 ||
          item.code === 1006 ||
          item.code === 1007 ||
          item.code === 3001
        );
      });
    } else {
      actions = this.state.filterableActionDropdown.filter(item => {
        return (
          item.code === 1001 ||
          item.code === 1003 ||
          item.code === 1006 ||
          item.code === 1007
        );
      });
    }

    /* For Processes and custom entities */
    if (
      selectedOption.code === 1000 ||
      selectedOption.code === 1001 ||
      selectedOption.code === 1006
    ) {
      //show approval step
      this.setState({ showApprovalFlowStep: true });
    } else {
      //hide approval step
      this.setState({ showApprovalFlowStep: false });
      //remove approval flow step if exists
      const rows = this.state.flowSteps.filter(item => {
        return item.stepType !== 1003;
      });
      this.setState({ flowSteps: rows });
      //remove approval flow step if exists
    }
    /* For processes and custom entities */

    this.setState({
      actionDropdown: actions
    });
  };

  handleMultiTagboxChange = (state, selectedStateName, selectedOption) => {
    this.setState({
      [state]:
        selectedOption === null || selectedOption === undefined
          ? []
          : selectedOption.map(row => row.value),
      [selectedStateName]: selectedOption === null ? "" : selectedOption
    });
    // handle time based only
    if (state === "actionIds" && selectedOption !== null) {
      let actions = this.searchArray(1006, selectedOption, "code");
      if (actions !== undefined) {
        this.setState({ timeTrigger: true });
        this.resetApproversDropdown();
        let groupedOptions = [
          {
            label: "Teams",
            options: []
          },
          {
            label: "Users",
            options: this.state.users
          }
        ];
        this.setState({ usersIdsDropdown: groupedOptions });
      } else {
        this.setState({ timeTrigger: false, repeat: "" });
        let groupedOptions = [
          {
            label: "Teams",
            options: this.state.teams
          },
          {
            label: "Users",
            options: this.state.users
          }
        ];
        this.setState({ usersIdsDropdown: groupedOptions });
      }
    }
    if (selectedOption === null) {
      this.setState({ timeTrigger: false, repeat: "" });
    }
    // handle time based only
  };

  handleDynamicTagboxChange = (
    idx,
    state,
    selectedStateName,
    selectedOption
  ) => {
    const rows = [...this.state.flowSteps];
    rows[idx].fields[state] =
      selectedOption === null || selectedOption === undefined
        ? []
        : selectedOption.map(row => row.value);
    rows[idx].fields[selectedStateName] =
      selectedOption === null ? "" : selectedOption;

    this.setState({
      flowSteps: rows
    });
  };

  handleApproversTagboxChange = (
    idx,
    state,
    selectedStateName,
    selectedOption
  ) => {
    let self = this;
    const rows = [...this.state.flowSteps];
    let teamIDs = [],
      userIDs = [];
    // implement a null, empty check here
    let sel = selectedOption === null ? [] : selectedOption;
    let IDs = sel.map(row => row.value);
    IDs.forEach(element => {
      let user = self.searchArray(element, self.state.users, "value");
      if (user !== undefined) {
        userIDs.push(element);
      }
      let team = self.searchArray(element, self.state.teams, "value");
      if (team !== undefined) {
        teamIDs.push(element);
      }
    });
    rows[idx].fields["usersIds"] = userIDs;
    rows[idx].fields["teamIds"] = teamIDs;
    rows[idx].fields[selectedStateName] =
      selectedOption === null ? "" : selectedOption;

    this.setState({
      flowSteps: rows
    });
  };

  resetApproversDropdown = () => {
    const rows = [...this.state.flowSteps];
    rows.forEach(row => {
      if (row.stepType === 1003) {
        row.fields["teamIds"] = [];
        row.fields["usersIds"] = [];
        row.fields["selectedUsers"] = [];
      }
    });
  };
  handleEditor = (e, idx) => {
    const data = e;
    const rows = [...this.state.flowSteps];
    rows[idx].fields.message = data;
    this.setState({ flowSteps: rows });
  }

  handleDynamicFieldChange = (idx, e) => {
    debugger;
    // const { name, value } = e.target;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const rows = [...this.state.flowSteps];
    rows[idx].fields[name] = value;
    console.log("rows[idx].fields", rows[idx].fields);

    // Remove Teams if inSequence is selected and re-enable it
    if (name === "approvalOrder") {
      rows[idx].fields["teamIds"] = [];
      rows[idx].fields["usersIds"] = [];
      rows[idx].fields["selectedUsers"] = [];
      this.setState({ usersIdsDropdown: [], flowSteps: rows }, () => {
        if (parseInt(value) === 1002) {
          let groupedOptions = [
            {
              label: "Teams",
              options: []
            },
            {
              label: "Users",
              options: this.state.users
            }
          ];

          this.setState({ usersIdsDropdown: groupedOptions });
        } else {
          //handle time based
          let actions = this.searchArray(
            1006,
            this.state.selectedActions,
            "code"
          );
          if (actions !== undefined) {
            var groupedOptions = [
              {
                label: "Teams",
                options: []
              },
              {
                label: "Users",
                options: this.state.users
              }
            ];
          } else {
            var groupedOptions = [
              {
                label: "Teams",
                options: this.state.teams
              },
              {
                label: "Users",
                options: this.state.users
              }
            ];
          }

          this.setState({ usersIdsDropdown: groupedOptions });
        }
      });
    }
    // Remove Teams if inSequence is selected and re-enable it
    this.setState({
      flowSteps: rows
    });
  };

  addStep = step => {
    if (step === "email") {
      const item = {
        id: this.uuidv4(),
        stepType: this.state.stepType[step],
        fields: {
          collapsed: false,
          subject: "",
          message: "",
          selectedReceivers: [],
          receiversIds: []
        }
      };

      this.setState({
        flowSteps: [...this.state.flowSteps, item]
      });
    } else if (step === "api") {
      const item = {
        id: this.uuidv4(),
        stepType: this.state.stepType[step],
        fields: {
          collapsed: false,
          apiURL: "",
          apiType: "",
          apiBody: ""
        }
      };

      this.setState({
        flowSteps: [...this.state.flowSteps, item]
      });
    } else if (step === "approval") {
      const item = {
        id: this.uuidv4(),
        stepType: this.state.stepType[step],
        fields: {
          collapsed: false,
          approvalOrder: "",
          name: "",
          description: "",
          usersIds: [],
          teamIds: [],
          selectedUsers: []
        }
      };

      this.setState({
        flowSteps: [...this.state.flowSteps, item]
      });
    } else if (step === "status") {
      const item = {
        id: this.uuidv4(),
        stepType: this.state.stepType[step],
        fields: {
          status: ""
        }
      };

      this.setState({
        flowSteps: [...this.state.flowSteps, item]
      });
    }
  };

  handleRemoveSpecificRow = selectedItem => {
    const rows = this.state.flowSteps.filter(item => {
      return item.id !== selectedItem.id;
    });
    this.setState({ flowSteps: rows });
  };

  moveDown = (idx, item) => {
    const rows = [...this.state.flowSteps];
    if (idx < rows.length - 1) {
      let el = rows[idx];
      rows[idx] = rows[idx + 1];
      rows[idx + 1] = el;
      this.setState({
        flowSteps: rows
      });
    }
  };

  moveUp = (idx, item) => {
    const rows = [...this.state.flowSteps];
    if (idx > 0) {
      let el = rows[idx];
      rows[idx] = rows[idx - 1];
      rows[idx - 1] = el;
      this.setState({
        flowSteps: rows
      });
    }
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-systems-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" /> : <TranslatableText translationKey="create" />} Flow
        </h3>
        <Form onSubmit={this.createFlowEngine}>
          <Row form>
            <Col md={12}>
              <FormGroup
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  <TranslatableText translationKey="name" />
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.name.message}</span>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="description">
                  <TranslatableText translationKey="description" />
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="role">
                  <TranslatableText translationKey="attach_entity" />
                </Label>
                <Select
                  value={
                    this.state.selectedRegardingObjectTypeId === ""
                      ? null
                      : this.state.selectedRegardingObjectTypeId
                  }
                  className={
                    this.props.match.params.regardingObjectTypeId !== undefined
                      ? "disabled-selectbox"
                      : "entity-selectbox"
                  }
                  options={this.state.entitiesDropdown}
                  onChange={selectedOption =>
                    this.handleSingleTagboxChange(
                      "regardingObjectTypeId",
                      "selectedRegardingObjectTypeId",
                      selectedOption
                    )
                  }
                  isDisabled={
                    this.props.match.params.regardingObjectTypeId !== undefined
                      ? true
                      : null
                  }
                  hideSelectedOptions={true}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="regardingObjectId">
                  <TranslatableText translationKey="entity_record" />
                </Label>
                <Input
                  type="select"
                  name="regardingObjectId"
                  id="regardingObjectId"
                  disabled={
                    this.props.match.params.regardingObjectId !== undefined
                      ? true
                      : null
                  }
                  className={
                    this.state.regardingObjectTypeId !== "" &&
                      this.state.regardingObjectId === "" &&
                      this.searchArray(
                        1006,
                        this.state.selectedActions,
                        "code"
                      ) !== undefined
                      ? "has-error"
                      : ""
                  }
                  value={this.state.regardingObjectId}
                  onChange={this.handleChange}
                >
                  <option value="">{this.context.languages["all"]}</option>
                  {this.state.regardingObjectsDropdown.map((e, key) => {
                    return (
                      <option key={key} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>

            <Col md={12}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="role">
                      <TranslatableText translationKey="starts_when" />
                    </Label>
                    <Select
                      isMulti
                      value={
                        this.state.selectedActions === ""
                          ? null
                          : this.state.selectedActions
                      }
                      className="user-selectbox"
                      options={this.state.actionDropdown}
                      onChange={selectedOption =>
                        this.handleMultiTagboxChange(
                          "actionIds",
                          "selectedActions",
                          selectedOption
                        )
                      }
                      hideSelectedOptions={true}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="repeat">
                      <TranslatableText translationKey="repeat" />
                    </Label>
                    <Input
                      type="select"
                      name="repeat"
                      id="repeat"
                      className={
                        this.state.repeat === "" &&
                          this.searchArray(
                            1006,
                            this.state.selectedActions,
                            "code"
                          ) !== undefined
                          ? "has-error"
                          : ""
                      }
                      value={this.state.repeat}
                      onChange={this.handleChange}
                      disabled={this.state.timeTrigger ? null : true}
                    >
                      <option value="">{this.context.languages["-- Select --"]}</option>
                      {this.state.repeatOptions.map((e, key) => {
                        return (
                          <option key={key} value={e.value}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              {this.state.timeTrigger && this.state.repeat == 1001 ? (
                <Row className="panel panel-padding" form>
                  <Col md={this.setColumnValue()}>
                    <FormGroup>
                      <Label for="recurrenceTypeId">
                        <TranslatableText translationKey="tasks.recurrenceType" />
                      </Label>
                      <Input
                        type="select"
                        name="recurrenceTypeId"
                        id="recurrenceTypeId"
                        value={this.state.recurrenceTypeId}
                        onChange={this.handleChange}
                      >
                        <option value="">{this.context.languages["-- Select --"]}</option>
                        {this.state.recurrenceTypeDropDown.map((e, key) => {
                          return (
                            <option key={e.id} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col
                    className={classnames(
                      this.state.isVisibleDW ? "show" : "hide"
                    )}
                    md={4}
                  >
                    <FormGroup>
                      <Label for="dayOfWeek">
                        <TranslatableText translationKey="day_of_week" />
                      </Label>
                      <Input
                        type="select"
                        name="dayOfWeek"
                        id="dayOfWeek"
                        value={this.state.dayOfWeek}
                        onChange={this.handleChange}
                      >
                        <option value="">{this.context.languages["-- Select --"]}</option>
                        <option value="1">{this.context.languages["monday"]}</option>
                        <option value="2">{this.context.languages["tuesday"]}</option>
                        <option value="3">{this.context.languages["wednesday"]}</option>
                        <option value="4">{this.context.languages["thursday"]}</option>
                        <option value="5">{this.context.languages["friday"]}</option>
                        <option value="6">{this.context.languages["saturday"]}</option>
                        <option value="7">{this.context.languages["sunday"]}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col
                    className={classnames(
                      this.state.isVisibleDM ? "show" : "hide"
                    )}
                    md={4}
                  >
                    <FormGroup>
                      <Label for="dayOfMonth">
                        <TranslatableText translationKey="day_of_month" />
                      </Label>
                      <Input
                        type="number"
                        name="dayOfMonth"
                        id="dayOfMonth"
                        placeholder=""
                        value={this.state.dayOfMonth}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    className={classnames(
                      this.state.isVisibleY ? "show" : "hide"
                    )}
                    md={4}
                  >
                    <FormGroup>
                      <Label for="monthOfYear">
                        <TranslatableText translationKey="month_of_year" />
                      </Label>
                      <Input
                        type="select"
                        name="monthOfYear"
                        id="monthOfYear"
                        placeholder=""
                        value={this.state.monthOfYear}
                        onChange={this.handleChange}
                      >
                        <option value="">{this.context.languages["-- Select --"]}</option>
                        <option value="1">{this.context.languages["january"]}</option>
                        <option value="2">{this.context.languages["febuary"]}</option>
                        <option value="3">{this.context.languages["march"]}</option>
                        <option value="4">{this.context.languages["april"]}</option>
                        <option value="5">{this.context.languages["may"]}</option>
                        <option value="6">{this.context.languages["june"]}</option>
                        <option value="7">{this.context.languages["july"]}</option>
                        <option value="8">{this.context.languages["august"]}</option>
                        <option value="9">{this.context.languages["september"]}</option>
                        <option value="10">{this.context.languages["october"]}</option>
                        <option value="11">{this.context.languages["november"]}</option>
                        <option value="12">{this.context.languages["december"]}</option>
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <Row form>
                      <Col md={2}>
                        <FormGroup>
                          <Label for="triggerTime">
                            <TranslatableText translationKey="trigger_time" />
                          </Label>
                          <DatePicker
                            className="form-control datepicker timepicker"
                            selected={this.state.triggerTimeDatePicker}
                            onChange={this.handleTriggerTime}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm:ss"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={5}>
                        <FormGroup>
                          <Label for="startDate">
                            <TranslatableText translationKey="start_date" />
                          </Label>
                          <DatePicker
                            className="form-control datepicker"
                            selected={this.state.startDatePicker}
                            onChange={this.handleStartDate}
                            dateFormat="yyyy-MM-dd"
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        className={classnames(
                          this.state.isVisibleED ? "show" : "hide"
                        )}
                        md={5}
                      >
                        <FormGroup>
                          <Label for="endDate">
                            <TranslatableText translationKey="end_date" />
                          </Label>
                          <DatePicker
                            className="form-control datepicker"
                            selected={this.state.endDatePicker}
                            onChange={this.handleEndDate}
                            dateFormat="yyyy-MM-dd"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <></>
              )}

              {this.state.timeTrigger && this.state.repeat == 1002 ? (
                <Row className="panel panel-padding" form>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="triggerTime">
                        <TranslatableText translationKey="time" />
                      </Label>
                      <DatePicker
                        className="form-control datepicker timepicker"
                        selected={this.state.triggerTimeDatePicker}
                        onChange={this.handleTriggerTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="HH:mm:ss"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={10}>
                    <FormGroup>
                      <Label for="startDate">
                        <TranslatableText translationKey="date" />
                      </Label>
                      <DatePicker
                        className="form-control datepicker"
                        selected={this.state.startDatePicker}
                        onChange={this.handleStartDate}
                        dateFormat="yyyy-MM-dd"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Col>

            {this.state.regardingObjectTypeId !== "" ? (
              <Col md={12}>
                <h4 className="form-heading">
                  <TranslatableText translationKey="steps" />
                </h4>
              </Col>
            ) : (
              <></>
            )}

            <Col md={12} className="steps-container">
              <Row form>
                {this.state.flowSteps.map((item, idx) =>
                  item.stepType === 1002 ? (
                    <Col md={12} key={item.id}>
                      <Row className="panel padding-20" form>
                        <Col md={12}>
                          <Row className="bottom-border">
                            <Col className="flex space-between" md={12}>
                              <h4 className="flex flex-align">
                                <span className="step-count">{idx + 1}</span>{" "}
                                <TranslatableText translationKey="notification" />{" "}
                                - <TranslatableText translationKey="email" />
                              </h4>

                              <div className="flex">
                                <div className="flex arrows mr-10">
                                  <span
                                    className="cursor mr-10"
                                    onClick={() => this.togglePanel(idx, item)}
                                  >
                                    {item.fields.collapsed ? (
                                      <FontAwesomeIcon
                                        icon={faWindowMaximize}
                                        size="1x"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faWindowMinimize}
                                        size="1x"
                                      />
                                    )}
                                  </span>
                                  {idx > 0 ? (
                                    <span
                                      className="cursor mr-5"
                                      onClick={() => this.moveUp(idx, item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faArrowUp}
                                        size="1x"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {idx < this.state.flowSteps.length - 1 ? (
                                    <span
                                      className="cursor"
                                      onClick={() => this.moveDown(idx, item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faArrowDown}
                                        size="1x"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <span
                                  className="remove-row cursor"
                                  onClick={() => this.deleteStep(item)}
                                >
                                  <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTrash}
                                    size="1x"
                                  />
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          md={12}
                          className={item.fields.collapsed ? "collapsed" : ""}
                        >
                          <FormGroup>
                            <Label for="role">
                              <TranslatableText translationKey="receivers" />
                            </Label>
                            <Select
                              isMulti
                              value={
                                item.fields.selectedReceivers === ""
                                  ? null
                                  : item.fields.selectedReceivers
                              }
                              className="user-selectbox"
                              options={this.state.users}
                              formatGroupLabel={formatGroupLabel}
                              onChange={selectedOption =>
                                this.handleDynamicTagboxChange(
                                  idx,
                                  "receiversIds",
                                  "selectedReceivers",
                                  selectedOption
                                )
                              }
                              hideSelectedOptions={true}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="subject">
                              <TranslatableText translationKey="subject" />
                            </Label>
                            <Input
                              type="text"
                              name="subject"
                              id="subject"
                              placeholder=""
                              value={item.fields.subject}
                              onChange={e =>
                                this.handleDynamicFieldChange(idx, e)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="message">
                              <TranslatableText translationKey="message" />
                            </Label>
                            <Editor initialValue={item.fields.message} callback={e => this.handleEditor(e, idx)} disabled={false} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  ) : item.stepType === 1001 ? (
                    <Col md={12} key={item.id}>
                      <Row className="panel padding-20" form>
                        <Col md={12}>
                          <Row className="bottom-border">
                            <Col className="flex space-between" md={12}>
                              <h4 className="flex flex-align">
                                <span className="step-count">{idx + 1}</span>{" "}
                                <TranslatableText translationKey="call_in_api" />
                              </h4>

                              <div className="flex">
                                <div className="flex arrows mr-10">
                                  <span
                                    className="cursor mr-10"
                                    onClick={() => this.togglePanel(idx, item)}
                                  >
                                    {item.fields.collapsed ? (
                                      <FontAwesomeIcon
                                        icon={faWindowMaximize}
                                        size="1x"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faWindowMinimize}
                                        size="1x"
                                      />
                                    )}
                                  </span>
                                  {idx > 0 ? (
                                    <span
                                      className="cursor mr-5"
                                      onClick={() => this.moveUp(idx, item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faArrowUp}
                                        size="1x"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {idx < this.state.flowSteps.length - 1 ? (
                                    <span
                                      className="cursor"
                                      onClick={() => this.moveDown(idx, item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faArrowDown}
                                        size="1x"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <span
                                  className="remove-row cursor"
                                  onClick={() => this.deleteStep(item)}
                                >
                                  <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTrash}
                                    size="1x"
                                  />
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          md={12}
                          className={item.fields.collapsed ? "collapsed" : ""}
                        >
                          <div className="flex">
                            <FormGroup>
                              <Label for="apiType">
                                <TranslatableText translationKey="method" />
                              </Label>
                              <Input
                                type="select"
                                name="apiType"
                                id="apiType"
                                value={item.fields.apiType}
                                onChange={e =>
                                  this.handleDynamicFieldChange(idx, e)
                                }
                              >
                                <option value="">
                                  {this.context.languages["-- Select --"]}
                                </option>
                                <option value={this.state.apiMethods.get}>
                                  {this.context.languages["GET"]}
                                </option>
                                <option value={this.state.apiMethods.post}>
                                  {this.context.languages["POST"]}
                                </option>
                                <option value={this.state.apiMethods.put}>
                                  {this.context.languages["PUT"]}
                                </option>
                              </Input>
                            </FormGroup>
                            <FormGroup className="flex-two">
                              <Label for="apiURL">
                                <TranslatableText translationKey="API URL" />
                              </Label>
                              <Input
                                type="text"
                                name="apiURL"
                                id="apiURL"
                                placeholder=""
                                value={item.fields.apiURL}
                                onChange={e =>
                                  this.handleDynamicFieldChange(idx, e)
                                }
                              />
                            </FormGroup>
                          </div>
                          {item.fields.apiType == this.state.apiMethods.post ||
                            item.fields.apiType == this.state.apiMethods.put ? (
                            <FormGroup>
                              <Label for="apiBody">
                                <TranslatableText translationKey="Body" />
                                {" "}
                                <Tippy
                                  placement="top"
                                  content="This field only accepts JSON"
                                >
                                  <span>
                                    <FontAwesomeIcon
                                      icon={faInfoCircle}
                                      size="1x"
                                    />
                                  </span>
                                </Tippy>
                              </Label>
                              <Input
                                type="textarea"
                                name="apiBody"
                                id="apiBody"
                                placeholder=""
                                value={item.fields.apiBody}
                                onChange={e =>
                                  this.handleDynamicFieldChange(idx, e)
                                }
                              />
                            </FormGroup>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  ) : item.stepType === 1003 ? (
                    <Col md={12} key={item.id}>
                      <Row className="panel padding-20" form>
                        <Col md={12}>
                          <Row className="bottom-border">
                            <Col className="flex space-between" md={12}>
                              <h4 className="flex flex-align">
                                <span className="step-count">{idx + 1}</span>{" "}
                                <TranslatableText translationKey="start_an_approval_flow" />
                              </h4>

                              <div className="flex">
                                <div className="flex arrows mr-10">
                                  <span
                                    className="cursor mr-10"
                                    onClick={() => this.togglePanel(idx, item)}
                                  >
                                    {item.fields.collapsed ? (
                                      <FontAwesomeIcon
                                        icon={faWindowMaximize}
                                        size="1x"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faWindowMinimize}
                                        size="1x"
                                      />
                                    )}
                                  </span>
                                  {idx > 0 ? (
                                    <span
                                      className="cursor mr-5"
                                      onClick={() => this.moveUp(idx, item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faArrowUp}
                                        size="1x"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {idx < this.state.flowSteps.length - 1 ? (
                                    <span
                                      className="cursor"
                                      onClick={() => this.moveDown(idx, item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faArrowDown}
                                        size="1x"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <span
                                  className="remove-row cursor"
                                  onClick={() => this.deleteStep(item)}
                                >
                                  <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTrash}
                                    size="1x"
                                  />
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          md={12}
                          className={item.fields.collapsed ? "collapsed" : ""}
                        >
                          <Row>
                            <Col md={12}>
                              <FormGroup>
                                <Label for="name">
                                  <TranslatableText translationKey="name" />
                                </Label>
                                <Input
                                  type="text"
                                  name="name"
                                  id="name"
                                  placeholder=""
                                  value={item.fields.name}
                                  onChange={e =>
                                    this.handleDynamicFieldChange(idx, e)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="approvalOrder">
                                  <TranslatableText translationKey="approval_order" />
                                </Label>
                                <Input
                                  type="select"
                                  name="approvalOrder"
                                  id="approvalOrder"
                                  value={item.fields.approvalOrder}
                                  onChange={e =>
                                    this.handleDynamicFieldChange(idx, e)
                                  }
                                >
                                  <option value="">{this.context.languages["-- Select --"]}</option>
                                  {this.state.approvalOrderDropdown.map(
                                    (e, key) => {
                                      return (
                                        <option key={key} value={e.value}>
                                          {this.context.languages[e.name]}
                                        </option>
                                      );
                                    }
                                  )}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="role">
                                  <TranslatableText translationKey="approvers" />
                                </Label>
                                <Select
                                  isMulti
                                  value={
                                    item.fields.selectedUsers === ""
                                      ? null
                                      : item.fields.selectedUsers
                                  }
                                  className="user-selectbox"
                                  options={this.state.usersIdsDropdown}
                                  onChange={selectedOption =>
                                    this.handleApproversTagboxChange(
                                      idx,
                                      "usersIds",
                                      "selectedUsers",
                                      selectedOption
                                    )
                                  }
                                  hideSelectedOptions={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>

                              <FormGroup key={"commentOnApprove-" + idx}>
                                <Label className="block"><TranslatableText translationKey="approveCommentsRequired" /></Label>
                                <div className="custom-switch">
                                  <Input
                                    id={"commentOnApprove-" + idx}
                                    className="toggle"
                                    type="checkbox"
                                    name={"commentOnApprove"}
                                    checked={item.fields.commentOnApprove}
                                    value={item.fields.commentOnApprove}
                                    onChange={e => {
                                      this.handleDynamicFieldChange(idx, e)
                                    }
                                    }
                                  ></Input>
                                  <label htmlFor={"commentOnApprove-" + idx}>
                                    <span className="on"><TranslatableText translationKey="swal.are_you_sure.yes" /></span>
                                    <span className="off"><TranslatableText translationKey="swal.are_you_sure.no" /></span>
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>

                              <FormGroup key={"commentOnReject-" + idx}>
                                <Label className="block"><TranslatableText translationKey="rejectCommentsRequired" /></Label>
                                <div className="custom-switch">
                                  <Input
                                    id={"commentOnReject-" + idx}
                                    className="toggle"
                                    type="checkbox"
                                    name={"commentOnReject"}
                                    checked={item.fields.commentOnReject}
                                    value={item.fields.commentOnReject}
                                    onChange={e => {
                                      this.handleDynamicFieldChange(idx, e)
                                    }
                                    }
                                  ></Input>
                                  <label htmlFor={"commentOnReject-" + idx}>
                                    <span className="on"><TranslatableText translationKey="swal.are_you_sure.yes" /></span>
                                    <span className="off"><TranslatableText translationKey="swal.are_you_sure.no" /></span>
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={4}>

                              <FormGroup key={"autoApproveForTriggerer-" + idx}>
                                <Label className="block"><TranslatableText translationKey="autoApprove" /></Label>
                                <div className="custom-switch">
                                  <Input
                                    id={"autoApproveForTriggerer-" + idx}
                                    className="toggle"
                                    type="checkbox"
                                    name={"autoApproveForTriggerer"}
                                    checked={item.fields.autoApproveForTriggerer}
                                    value={item.fields.autoApproveForTriggerer}
                                    onChange={e => {
                                      this.handleDynamicFieldChange(idx, e)
                                    }
                                    }
                                  ></Input>
                                  <label htmlFor={"autoApproveForTriggerer-" + idx}>
                                    <span className="on"><TranslatableText translationKey="swal.are_you_sure.yes" /></span>
                                    <span className="off"><TranslatableText translationKey="swal.are_you_sure.no" /></span>
                                  </label>
                                </div>
                              </FormGroup>
                            </Col>

                            <Col md={12}>
                              <FormGroup>
                                <Label for="description">
                                  <TranslatableText translationKey="description" />
                                </Label>
                                <Input
                                  type="textarea"
                                  name="description"
                                  id="description"
                                  placeholder=""
                                  value={item.fields.description}
                                  onChange={e =>
                                    this.handleDynamicFieldChange(idx, e)
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ) : item.stepType === 1004 ? (
                    <Col md={12} key={item.id}>
                      <Row className="panel padding-20" form>
                        <Col md={12}>
                          <Row className="bottom-border">
                            <Col className="flex space-between" md={12}>
                              <h4 className="flex flex-align">
                                <span className="step-count">{idx + 1}</span>{" "}
                                <TranslatableText translationKey="change_status" />
                              </h4>

                              <div className="flex">
                                <div className="flex arrows mr-10">
                                  <span
                                    className="cursor mr-10"
                                    onClick={() => this.togglePanel(idx, item)}
                                  >
                                    {item.fields.collapsed ? (
                                      <FontAwesomeIcon
                                        icon={faWindowMaximize}
                                        size="1x"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faWindowMinimize}
                                        size="1x"
                                      />
                                    )}
                                  </span>
                                  {idx > 0 ? (
                                    <span
                                      className="cursor mr-5"
                                      onClick={() => this.moveUp(idx, item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faArrowUp}
                                        size="1x"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {idx < this.state.flowSteps.length - 1 ? (
                                    <span
                                      className="cursor"
                                      onClick={() => this.moveDown(idx, item)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faArrowDown}
                                        size="1x"
                                      />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <span
                                  className="remove-row cursor"
                                  onClick={() => this.deleteStep(item)}
                                >
                                  <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTrash}
                                    size="1x"
                                  />
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          md={12}
                          className={item.fields.collapsed ? "collapsed" : ""}
                        >
                          <FormGroup>
                            <Label for="status">
                              <TranslatableText translationKey="lock_unlock_record" />
                            </Label>
                            <Input
                              type="select"
                              name="status"
                              id="status"
                              value={item.fields.status}
                              onChange={e =>
                                this.handleDynamicFieldChange(idx, e)
                              }
                            >
                              <option value="">
                                {this.context.languages["-- Select --"]}
                              </option>
                              <option value={this.state.statusValues.lock}>
                                {this.context.languages["lock"]}
                              </option>
                              <option value={this.state.statusValues.unlock}>
                                {this.context.languages["unlock"]}
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <></>
                  )
                )}
              </Row>
            </Col>
            {this.state.regardingObjectTypeId !== "" ? (
              <Col md={12}>
                <Row>
                  <Col md={12}>
                    <Button
                      onClick={e => this.addStep("email", e)}
                      className="mr-10"
                      outline
                    >
                      <TranslatableText translationKey="send_notification_email" />
                    </Button>
                    <Button
                      onClick={e => this.addStep("api", e)}
                      className="mr-10"
                      outline
                    >
                      <TranslatableText translationKey="call_api" />
                    </Button>
                    {this.state.showApprovalFlowStep ? (
                      <>
                        <Button
                          onClick={e => this.addStep("approval", e)}
                          className="mr-10"
                          outline
                        >
                          <TranslatableText translationKey="start_an_approval_flow" />
                        </Button>
                        <Button
                          onClick={e => this.addStep("status", e)}
                          className="mr-10"
                          outline
                        >
                          <TranslatableText translationKey="change_status" />
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
            ) : (
              <></>
            )}

            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ? <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateFlow);
CreateFlow.contextType = GlobalContext;