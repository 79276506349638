import { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Attachments from "../attachments/Attachments";
import SurveyInvite from "../wizard/SurveyInvite";
import SurveyWizard from "../wizard/SurveyWizardNew";
import CreateEntity from "./CreateEntity";
import EntityDetails from "./EntityDetails";
import EntityPage from './EntityPage';
import EntityWrapper from "./EntityWrapper";

class EntityRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-Entity-view">
        <Switch>
          <Route
            path={`/entity/create/:regardingObjectTypeId`}
            render={() => <CreateEntity />}
          />
          <Route
            path={`/entity/update/:regardingObjectTypeId/:id`}
            render={() => <CreateEntity />}
          />
          <Route
            path={`/entity/details/:id`}
            render={() => <EntityDetails />}
          />
          <Route
            path={`/entity/attachments/:id`}
            render={() => <Attachments />}
          />
          <Route
            path={`/entity/wizard/:id/:recordId/:regardingObjectTypeId/invite`}
            render={() => <SurveyInvite />}
          />
          <Route
            path={`/entity/wizard/:id/:recordId/:regardingObjectTypeId`}
            render={() => <SurveyWizard />}
          />
          <Route
            path="/entity/:regardingObjectTypeId"
            render={props => (
              <EntityPage
              key={this.props.match.params.regardingObjectTypeId}
                {...props}
              />
            )}
          /> 
        </Switch>
      </div>
    );
  }
}

export default withRouter(EntityRouter);