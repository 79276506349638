import React from "react";
import { Col, Label, Button } from "reactstrap";
import Tippy from "@tippyjs/react";
import TranslatableText from "../../../../../../../Language/TranslatableText";

let ConditionsBuilder = ({
  handleChange,
  componentState,
  addGroup,
  deleteGroup,
  handleDynamicFieldChange,
  toggleCopyConditionsModal,
  toggleConditionModal,
  renderTableHeader,
  renderTableData
}) => {
  return (
    <>
      <Col md={12}>
        <div className="processes-section primary-container">
          <section className="buttons-container flex space-between">
            <div className="left-controls">
              <div className="stv-radio-buttons-wrapper two">
                <span>
                  <input
                    type="radio"
                    className="stv-radio-button"
                    name="groupOperator"
                    value="and"
                    id="group-operator-and"
                    onChange={e => handleChange(e)}
                    checked={componentState.groupOperator === "and"}
                  />
                  <Label for="group-operator-and">
                    <TranslatableText translationKey="and" />
                  </Label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="stv-radio-button"
                    name="groupOperator"
                    value="or"
                    id="group-operator-or"
                    onChange={e => handleChange(e)}
                    checked={componentState.groupOperator === "or"}
                  />
                  <Label for="group-operator-or">
                    <TranslatableText translationKey="or" />
                  </Label>
                </span>
              </div>
            </div>
            <div className="right-controls">
              <Tippy placement="top" content="Coming Soon..!">
                <span>
                  <Button
                    className="button-primary mr-10"
                    onClick={toggleCopyConditionsModal}
                    disabled={true}
                  >
                    <TranslatableText translationKey="page.copy_conditions" />
                  </Button>
                </span>
              </Tippy>
              <Button className="button-primary" onClick={e => addGroup(e)}>
                <TranslatableText translationKey="addGroup" />
              </Button>
            </div>
          </section>
          <hr />
          {componentState.moduleConditions.map((condition, index) => (
            <div key={condition.id}>
              <div className="flex space-between controls-group">
                <div className="stv-radio-buttons-wrapper two">
                  <span>
                    <input
                      type="radio"
                      className="stv-radio-button"
                      name={`operator-${condition.id}-${index}`}
                      value="and"
                      id={`and-operator-${condition.id}-${index}`}
                      onChange={e => handleDynamicFieldChange(condition.id, e)}
                      checked={condition.operator === "and"}
                    />
                    <Label for={`and-operator-${condition.id}-${index}`}>
                      <TranslatableText translationKey="and" />
                    </Label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      className="stv-radio-button"
                      name={`operator-${condition.id}-${index}`}
                      value="or"
                      id={`or-operator-${condition.id}-${index}`}
                      onChange={e => handleDynamicFieldChange(condition.id, e)}
                      checked={condition.operator === "or"}
                    />
                    <Label for={`or-operator-${condition.id}-${index}`}>
                      <TranslatableText translationKey="or" />
                    </Label>
                  </span>
                </div>
                <div>
                  <Button
                    className="button-primary mr-10"
                    onClick={e => toggleConditionModal(condition.id)}
                  >
                    <TranslatableText translationKey="page.add_conditions" />
                  </Button>
                  <Button
                    className="button-primary delete-btn"
                    onClick={e => deleteGroup(index, e)}
                  >
                    <TranslatableText translationKey="removeGroup" />
                  </Button>
                </div>
              </div>
              <table className="table table-bordered ">
                <thead>{renderTableHeader()}</thead>
                <tbody>{renderTableData(condition.children)}</tbody>
              </table>
            </div>
          ))}
        </div>
      </Col>
    </>
  );
};

export default ConditionsBuilder;
