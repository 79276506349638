import { faRandom } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from "@tippyjs/react";
import { ChangeEvent, PureComponent } from 'react';
import { NavLink } from "reactstrap";
import HandleCaught_API_Error from '../../../apiClients/api_caughtError';
import TranslatableText from '../../../Language';
import { switchProject } from './ChangeProjectAPI';

interface IProps {
    callback: any,
    projectId: string,
    loading: boolean,
    languages: any
}
interface IState {
    projectId: string,
    loading: boolean
}

class ChangeProject extends PureComponent<IProps, IState>  {
    constructor(props: IProps) {
        super(props);
        this.state = {
            projectId: '',
            loading: false,
        }
    }
    
    handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ 
            projectId: this.props.projectId,
            loading: this.props.loading
        });
    }
    changeProject = (id: string) => {
        this.setState({ loading: true });
        switchProject(id)
            .then(() => window.location.replace("/") )
            .catch(error => HandleCaught_API_Error(error));
    }

    render() {
        return (
            <Tippy placement="top" content={<TranslatableText translationKey="change_project" />}>
                <span>
                    <NavLink onClick={e => this.changeProject(this.props.projectId)}>
                        <FontAwesomeIcon icon={faRandom} size="1x" />
                    </NavLink>
                </span>
            </Tippy>
        );
    }
}

export default ChangeProject