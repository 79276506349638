import React, { useEffect, useState } from 'react';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faLaptopHouse } from '@fortawesome/pro-light-svg-icons';
import { GetUserOrganizationsResponse, OrganizationsClient } from '../../apiClients';
import { useAuth0 } from '@auth0/auth0-react';
import HandleCaught_API_Error from '../../apiClients/api_caughtError';
import { InlineLoading } from '../loading';

export function CurrentOrgNotActive() {

  const { user } = useAuth0();
  const _OrganizationsClient = new OrganizationsClient();

  const [isLoading, setIsLoading] = useState(true);
  const [organisationData, setorganisationData] = useState<GetUserOrganizationsResponse>();

  useEffect(() => {
    Promise.all([
      _OrganizationsClient.getUserFavoriteOrganizations()
        .then(res => { setorganisationData(res); })
        .catch(error => { HandleCaught_API_Error(error); })
    ]).finally(() => {
      setIsLoading(false);
    })
  }, []);
  function changeProject(organizationId: string) {
    _OrganizationsClient.changeProject(organizationId)
      .then(() => { window.location.replace("/"); })
      .catch(error => { HandleCaught_API_Error(error.message); });
  }

  return (
    <>
      <h2>Hi, {user?.name}</h2>
      <p>The Project that you were viewing the last time you were here is no longer available.</p>
      <p>Please select anothher to continue:</p>
      {isLoading && (
        <InlineLoading />
      )}
      {!isLoading && (
        <>
          {organisationData?.organizations?.map(
            (item, index) => {
              return (
                <DropdownItem
                  key={item.organizationId}
                  className={`company-item`}
                  onClick={() => changeProject(item.organizationId)}
                >
                  <div>
                    <FontAwesomeIcon icon={faCircle} />
                    &nbsp;{item.name}
                  </div>
                </DropdownItem>
              );
            }
          )}
        </>
      )}
    </>
  );
}
