import { faArrowAltCircleLeft, faBuilding, faEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, NavLink, Row } from "reactstrap";
import { GetAllOrganizationsResponse, GetOrganizationResponse, GetUserResponse, OrganizationsClient, UsersClient } from "../../../../../apiClients";
import { InlineLoading } from "../../../../../components";
import Accent from "../../../../../components/accent/Accent";
import Gap from "../../../../../components/gap/Gap";
import TranslatableText, { TranslatableTextString } from "../../../../../Language/TranslatableText";
import { cvrapi, cvrApiResponse } from "../../../cvrApi";
import ProjectsList from '../components/ProjectsList';
import { organisationDetails } from "../organisationDetails";

type CompanyDetailsComponentState = {
  organisationData: GetOrganizationResponse,
  cvrApiData: cvrApiResponse | undefined,
  projects: GetAllOrganizationsResponse[] | undefined,
  activeProjects: GetAllOrganizationsResponse[] | undefined,
  inActiveProjects: GetAllOrganizationsResponse[] | undefined,
  users: GetUserResponse[] | undefined,
  tableLoader: boolean
}

const initialState: CompanyDetailsComponentState = {
  organisationData: new GetOrganizationResponse(),
  cvrApiData: undefined,
  projects: undefined,
  activeProjects: undefined,
  inActiveProjects: undefined,
  users: undefined,
  tableLoader: true
};

type CompanyDetailsComponentProps = {
  id: string,
  showBackButton: boolean,
}

type CompanyDetailsComponent = { id: string }
const CompanyDetailsComponent: React.FC<CompanyDetailsComponentProps> = (props) => {
  // const { id } = useParams<CompanyDetailsComponent>();
  const { id, showBackButton } = props;
  const [
    { organisationData, cvrApiData, projects, activeProjects, inActiveProjects, users, tableLoader },
    setState
  ] = useState(initialState);

  const [inActiveProjectsCheckbox, setInActiveProjectsCheckbox] = useState<boolean>(false);
  const _OrganizationsClient = new OrganizationsClient();
  const _UsersClient = new UsersClient();
  const history = useHistory();

  useEffect(() => {
    Promise.all([
      _OrganizationsClient.organizations(id)
        .then(res => {
          setState((prevState) => ({ ...prevState, organisationData: res }));
          if (res.countryCode == 'dk' && res.vat != undefined && res.vat.length == 8) {
            cvrapi(res.vat).then((res) => {
              setState((prevState) => ({ ...prevState, cvrApiData: res }));
            });
          }
        })
        .catch(error => { HandleCuaghtError(error); }),
      _OrganizationsClient.projectsGetByCompanyId(id)
        .then(res => {
          filterProject(res);
        })
        .catch(error => { HandleCuaghtError(error); }),
      _UsersClient.accountRepresentativeList(id)
        .then(res => {
          setState((prevState) => ({ ...prevState, users: res }));
        })
        .catch(error => { HandleCuaghtError(error); }),

    ]).finally(() => {
      setState((prevState) => ({ ...prevState, tableLoader: false }));
    })
  }, []);

  
  const filterProject = (res: GetAllOrganizationsResponse[]) => {
    let tempActive: GetAllOrganizationsResponse[] = []
    let tempInActive: GetAllOrganizationsResponse[] = []
    res?.filter((val: GetAllOrganizationsResponse) => {
      if (val.isActive) {
        tempActive.push(val);
      } else {
        tempInActive.push(val);
      }
    });
    setState((prevState) => ({ ...prevState, projects: tempActive, activeProjects: tempActive, inActiveProjects: tempInActive }));
  }


  
  function HandleInActiveCheckbox(checked: boolean) {
    setState((prevState) => ({ ...prevState, projects: [], tableLoaderUser: true }));
    setTimeout(() => {
      setInActiveProjectsCheckbox(checked);
      if (checked) {
        setState((prevState) => ({ ...prevState, projects: prevState.inActiveProjects, tableLoaderUser: false }));
      } else {
        setState((prevState) => ({ ...prevState, projects: prevState.activeProjects, tableLoaderUser: false }));
      }
    }, 100)
  }

  function changeProject(organizationId: string) {
    setState((prevState) => ({ ...prevState, tableLoader: true }));
    _OrganizationsClient.changeProject(organizationId)
      .then(() => {
        // After Switching Organization - reload everything
        window.location.replace("/");
      })
      .catch(error => {
        toast.error(error.message);
        setState((prevState) => ({ ...prevState, tableLoader: false }));
      });
  }

  function HandleCuaghtError(error: any) {
    toast.error(TranslatableTextString("api_error"));
    console.debug(error);
  }

  if (tableLoader) {
    return (
      <div className="center">
        <InlineLoading />
      </div>
    );
  } else {
    return (

      <div className="admin-view">
        <div className="relative flex flex-align space-between">
          <h1>
            {showBackButton &&
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                size="1x"
                onClick={() => history.goBack()}
                className="cursor"
              />
            }
            &nbsp;
            <TranslatableText translationKey="company" /> <TranslatableText translationKey="details" />
          </h1>
          <NavLink
            className="edit"
            tag={Link}
            to={`/partner/company/update/${organisationData.partnerId}/${organisationData.id}`}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </NavLink>
        </div>
        <Accent logo={organisationData.logo}>
          <TranslatableText translationKey="company" />&nbsp;<FontAwesomeIcon icon={faBuilding} />&nbsp;{organisationData.name}
        </Accent>
        {organisationDetails(organisationData, cvrApiData)}

        <Gap size="50px" />
        <Row>
          <Col md={12}><div className="partner-section white-bg">
            <h3>
              <TranslatableText translationKey="account_responsible" />
            </h3>
            {users?.length !== 0 && (
              <div className="flex vertical-table">

                <div className="flex flex-column table-header">
                  <div className="image-cell">-</div>
                  <div>
                    <TranslatableText translationKey="name" />
                  </div>
                  <div>
                    <TranslatableText translationKey="email" />
                  </div>
                  <div>
                    <TranslatableText translationKey="phone" />
                  </div>
                </div>
                <div className="flex flex-two table-body">
                  {users?.map(
                    (representative, index) => (
                      <div
                        key={index}
                        className="flex flex-one flex-column table-columns"
                      >
                        <div className="user-image-cell">
                          <img
                            src={representative.profileImage}
                            height="50"
                          />
                        </div>
                        <div>{representative.name}</div>
                        <div><a href={"mailto:" + representative.email}>{representative.email}</a></div>
                        <div>{representative.phone}</div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}

            {users?.length == 0 && (
              <div className="flex vertical-table">
                <div className="flex flex-column table-header">
                  <div>
                    <TranslatableText translationKey="account_responsible.none_selected" />
                  </div>
                </div>
              </div>
            )}
          </div>
          </Col>
        </Row>
        {ProjectsList(id, organisationData.partnerId!, changeProject, projects! ,showBackButton, inActiveProjectsCheckbox, HandleInActiveCheckbox)}
      </div >

    );
  }
}

export default CompanyDetailsComponent;