import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import {
  createUserAPI,
  getUserDetailsByIdAPI,
  updatePlatformUserAPI
} from "./usersAPI";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import { getProjectsAPI } from "../projects/projectsAPI";
import { getCompaniesAPI } from "../company/companyAPI";
import { getPartners } from "../partners/partnersAPI";
import { toast } from "react-toastify";
import { getEntityCustomFieldsAPI } from "../custom-fields/customFieldsAPI";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../components";



// Global Variables
var customFieldsModel;

class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="email_empty" />
      },
      {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: <TranslatableText translationKey="email_valid" />
      },
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
      },
      {
        field: "entity",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "organizationType",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      organizationType: "",
      organizationTypeDropdown: [
        { label: "Partner", value: "partner" },
        { label: "Company", value: "company" },
        { label: "Project", value: "project" }
      ],
      selectedEntity: "",
      entityDropdown: [],
      name: "",
      phone: "",
      email: "",
      entity: "",
      profileImage: "",
      file: "",
      organizationId: "",
      customFields: [],
      loading: false,
      checkAttachment: 1,
      validation: this.validator.valid()
    };
    this.submitted = false;
  }
  componentDidMount() {
    if (this.props.match.params.organizationId !== undefined) {
      this.setState({
        organizationType: "company",
        organizationId: this.props.match.params.organizationId,
        entity: this.props.match.params.organizationId
      });
    }
    getCompaniesAPI()
      .then(res => {
        this.setState({ organizationDropdown: Array.isArray(res) ? res : [] });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getUserDetailsByIdAPI(this.props.match.params.id)
        .then(res => {
          let self = this;
          this.setState(
            {
              name: res.data.name,
              phone: res.data.phone,
              email: res.data.email,
              entity: res.data.organizationId,
              profileImage:
                res.data.profileImage === null ||
                  res.data.profileImage === undefined ||
                  res.data.profileImage === ""
                  ? ""
                  : res.data.profileImage,
              file:
                res.data.profileImage === null ||
                  res.data.profileImage === undefined ||
                  res.data.profileImage === ""
                  ? ""
                  : this.dataURLtoFile(
                    res.data.profileImage,
                    res.data.profileImageName
                  ),
              customFields:
                res.data.customFieldValues === null
                  ? []
                  : res.data.customFieldValues,
              loading: false
            },
            () => {
              let userCustomFields =
                res.data.customFieldValues === null
                  ? []
                  : res.data.customFieldValues;
              userCustomFields.forEach(element => {
                self.setState({
                  [element.name]: element.value === null ? "" : element.value
                });
              });
            }
          );
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
      // To Bypass the organization & entity
      this.setState({
        entity: "0",
        organizationType: "0"
      });
    } else {
      this.populateCustomFields(1004);
    }
  }

  populateCustomFields = code => {
    this.setState({ loading: true, customFields: [] });
    getEntityCustomFieldsAPI(code)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ customFields: res, loading: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
  };

  getBase64 = file => {
    var self = this;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      self.setState({
        profileImage: reader.result
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  removeProfileImage = () => {
    this.setState({ profileImage: "" });
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  createUser = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    let key,
      self = this;

    // Preparing custom fields model
    customFieldsModel = this.state.customFields;
    this.state.customFields.forEach((element, i) => {
      key = element.name;
      customFieldsModel[i].value = self.state[key];
      console.log("values are", self.state[key]);
    });

    const data = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      organizationId: this.state.entity,
      customFieldValues: customFieldsModel,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : ""
    };

    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("customFieldValues", data.customFieldValues);
    formData.append("organizationId", data.organizationId);
    formData.append("file", this.state.file);
    formData.append("id", data.id);

    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ loading: true });
        updatePlatformUserAPI(formData)
          .then(res => {
            this.setState({ loading: false }, () => {
              this.goBack();
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ loading: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ loading: true });
        createUserAPI(formData)
          .then(res => {
            this.setState({ loading: false }, () => {
              this.goBack();
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ loading: false });
          });
      }
    }
  };

  handleChange = (event, i) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "organizationType") {
      this.setState({ selectedEntity: value });
      this.populateEntityDropdown(value);
    }

    if (target.type === "file") {
      const validImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml"
      ];

      if (target.files[0] !== undefined) {
        if (!validImageTypes.includes(target.files[0].type)) {
          alert("Only Images are allowed");
          target.value = "";
          return false;
        } else {
          if (target.files[0].size / 1024 <= 1000) {
            this.getBase64(target.files[0]);
          } else {
            alert("Max allowed size is 1000Kb");
            target.value = "";
            return false;
          }
        }
      }
      this.setState({ file: target.files[0] });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  handleSingleTagboxChange = (state, selectedStateName, selectedOption) => {
    if (state === "organizationType") {
      this.setState({
        selectedOrganizationEntity: null,
        selectedEntity: selectedOption
      });
      this.populateEntityDropdown(selectedOption.value);
    }
    this.setState({
      [state]: selectedOption.value,
      [selectedStateName]: selectedOption === null ? "" : selectedOption
    });
  };

  populateEntityDropdown = entity => {
    if (entity === "partner") {
      getPartners()
        .then(res => {
          if (!Array.isArray(res)) {
            console.log("debug: ", res);
            return;
          }
          const filteredPartners = res.filter(item => {
            return item.isActive === true;
          });

          let partnersDropdown = [];
          res.forEach(function (row) {
            partnersDropdown.push({ label: row.name, value: row.id });
          });

          let filteredPartnersDropdown = [];
          filteredPartners.forEach(function (row) {
            filteredPartnersDropdown.push({ label: row.name, value: row.id });
          });

          this.setState({
            entityDropdown: partnersDropdown,
            filteredPartners: filteredPartnersDropdown
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ tableLoader: false });
        });
    } else if (entity === "company") {
      getCompaniesAPI()
        .then(res => {
          if (!Array.isArray(res)) {
            console.log("debug: ", res);
            return;
          }
          const filteredCompanies = res.filter(item => {
            return item.isActive === true;
          });

          let companiesDropdown = [];
          res.forEach(function (row) {
            companiesDropdown.push({ label: row.name, value: row.id });
          });

          let filteredCompaniesDropdown = [];
          filteredCompanies.forEach(function (row) {
            filteredCompaniesDropdown.push({ label: row.name, value: row.id });
          });

          this.setState({
            entityDropdown: companiesDropdown,
            filteredCompanies: filteredCompaniesDropdown
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ tableLoader: false });
        });
    } else if (entity === "project") {
      getProjectsAPI()
        .then(res => {
          if (!Array.isArray(res)) {
            console.log("debug: ", res);
            return;
          }
          const filteredProjects = res.filter(item => {
            return item.isActive === true;
          });

          let projectsDropdown = [];
          res.forEach(function (row) {
            projectsDropdown.push({ label: row.name, value: row.id });
          });

          let filteredProjectsDropdown = [];
          filteredProjects.forEach(function (row) {
            filteredProjectsDropdown.push({ label: row.name, value: row.id });
          });

          this.setState({
            entityDropdown: projectsDropdown,
            filteredProjects: filteredProjectsDropdown
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
    } else {
      this.setState({ entityDropdown: [] });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  changeValidationMessage = (message, event) => {
    let msg = message === null ? "This is a required field" : message;
    event.target.setCustomValidity(msg);
  };

  render() {
    let text, radio, field;
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-user-view">
        {loading ? (
          <ScreenLoading />
        ) : (
          <div>
            <h3>
              {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" />
                : <TranslatableText translationKey="create" />}{" "}
              <TranslatableText translationKey="user" />
            </h3>
            <Form onSubmit={this.createUser}>
              <Row form>
                {this.props.match.params.id === undefined &&
                  this.props.match.params.organizationId === undefined ? (
                  <>
                    <Col md={6}>
                      <FormGroup
                        className={
                          validation.organizationType.isInvalid
                            ? "has-error"
                            : ""
                        }
                      >
                        <Label for="role">
                          <TranslatableText translationKey="organization_type" />
                        </Label>
                        <Select
                          value={
                            this.state.selectedOrganizationType === ""
                              ? null
                              : this.state.selectedOrganizationType
                          }
                          className="user-selectbox"
                          options={this.state.organizationTypeDropdown}
                          onChange={selectedOption =>
                            this.handleSingleTagboxChange(
                              "organizationType",
                              "selectedOrganizationType",
                              selectedOption
                            )
                          }
                          hideSelectedOptions={false}
                        />
                        <span className="help-block">
                          {validation.organizationType.message}
                        </span>
                      </FormGroup>
                    </Col>
                    {this.state.organizationType !== "" ? (
                      <Col md={6}>
                        <FormGroup
                          className={
                            validation.entity.isInvalid ? "has-error" : ""
                          }
                        >
                          <Label for="entity">
                            {this.state.selectedEntity.label}
                          </Label>
                          <Select
                            value={
                              this.state.selectedOrganizationEntity === ""
                                ? null
                                : this.state.selectedOrganizationEntity
                            }
                            className="user-selectbox"
                            options={this.state.entityDropdown}
                            onChange={selectedOption =>
                              this.handleSingleTagboxChange(
                                "entity",
                                "selectedOrganizationEntity",
                                selectedOption
                              )
                            }
                            hideSelectedOptions={false}
                          />
                          <span className="help-block">
                            {validation.entity.message}
                          </span>
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col md={6}>
                        <FormGroup>
                          <Label className="" for="entity">
                            <TranslatableText translationKey="select_entity" />
                          </Label>
                          <Input disabled={true} type="select">
                            <option value="">-- Vælg --</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <Col md={6}>
                  <FormGroup
                    className={validation.name.isInvalid ? "has-error" : ""}
                  >
                    <Label for="name">
                      <TranslatableText translationKey="name" />
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder=""
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.name.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={validation.email.isInvalid ? "has-error" : ""}
                  >
                    <Label for="email">
                      <TranslatableText translationKey="email" />
                    </Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder=""
                      value={this.state.email}
                      onChange={this.handleChange}
                      disabled={
                        this.props.match.params.id !== undefined ? true : null
                      }
                    />
                    <span className="help-block">
                      {validation.email.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="phone">
                      <TranslatableText translationKey="phone" />
                    </Label>
                    <Input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder=""
                      value={this.state.phone}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>

                {this.state.checkAttachment === 0 ? (
                  <Col md={6}>
                    <FormGroup>
                      <Label for="file">Profile Image</Label>
                      <InlineLoading />
                    </FormGroup>
                  </Col>
                ) : (
                  <Col md={6}>
                    {this.state.profileImage !== "" ? (
                      <div>
                        <p className="label">
                          Profile Image <span className="small">(Preview)</span>
                        </p>
                        <div className="image-container">
                          <img
                            className="image-max-width"
                            src={this.state.profileImage}
                            height="33"
                          />
                          <span
                            className="remove-image"
                            onClick={this.removeProfileImage}
                          >
                            <i className="fa fa-times"></i>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <FormGroup>
                        <Label for="file">
                          Profile Image{" "}
                          <span className="red small">
                            ( Max allowed size is <b>50Kb</b> )
                          </span>
                        </Label>
                        <Input
                          className="attachment-input"
                          type="file"
                          name="file"
                          id="file"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    )}
                  </Col>
                )}

                <Col className="dynamic-fields" md={12}>
                  {this.state.customFields.map((e, key) => {
                    field =
                      e.displayType.toLowerCase() === "twooption" ? (
                        <FormGroup key={e.id}>
                          <Label className="block">{e.fieldLabel}</Label>
                          <div className="custom-switch">
                            <Input
                              id={`toggle-${e.name}`}
                              className="toggle"
                              type="checkbox"
                              name={e.name}
                              checked={this.state[e.name] || ""}
                              value={this.state[e.name] || ""}
                              onChange={e => this.handleChange(e, key)}
                              required={e.isRequired}
                              onInvalid={event =>
                                this.changeValidationMessage(
                                  e.validationMessage,
                                  event
                                )
                              }
                              onInput={event =>
                                this.changeValidationMessage("", event)
                              }
                            ></Input>
                            <label htmlFor={`toggle-${e.name}`}>
                              <span className="on">Yes</span>
                              <span className="off">No</span>
                            </label>
                          </div>
                        </FormGroup>
                      ) : (
                        <FormGroup
                          className={
                            e.displayType.toLowerCase() === "textarea"
                              ? "full-flex"
                              : ""
                          }
                          key={e.id}
                        >
                          <Label for={e.name}>{e.fieldLabel}</Label>
                          <Input
                            type={e.displayType.toLowerCase()}
                            name={e.name}
                            id={e.name}
                            value={this.state[e.name] || ""}
                            onChange={e => this.handleChange(e, key)}
                            required={e.isRequired}
                            onInvalid={event =>
                              this.changeValidationMessage(
                                e.validationMessage,
                                event
                              )
                            }
                            onInput={event =>
                              this.changeValidationMessage("", event)
                            }
                          ></Input>
                          <span className="help-block"></span>
                        </FormGroup>
                      );

                    return field;
                  })}
                </Col>
                <Col className="space-between flex" md={12}>
                  <Button onClick={this.goBack}>
                    <TranslatableText translationKey="back_to_list" />
                  </Button>
                  <Button>
                    {this.props.match.params.id !== undefined
                      ? <TranslatableText translationKey="update" />
                      : <TranslatableText translationKey="create" />}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(CreateUser);
CreateUser.contextType = GlobalContext;