import React from "react";
import { Switch, Route } from "react-router-dom";
import CustomFields from "./CustomFields";
import CustomFieldDetails from "./CustomFieldDetails";
import CreateCustomField from "./CreateCustomField";
import { withRouter } from "react-router-dom";
import AddCustomFieldTranslation from "./AddCustomFieldTranslation";

let CustomFieldsRouter = props => {
  return (
    <div className="parent-custom-fields-view">
      <Switch>
        <Route
          path={`${props.match.path}/translation/add/:id`}
          render={props => <AddCustomFieldTranslation {...props} />}
        />
        <Route
          path={`${props.match.path}/translation/update/:id/:translationId`}
          render={props => <AddCustomFieldTranslation {...props} />}
        />
        <Route
          path={`${props.match.path}/create/:regardingObjectId`}
          render={props => <CreateCustomField {...props} />}
        />
        <Route
          path={`${props.match.path}/update/:id/:regardingObjectId`}
          render={props => <CreateCustomField {...props} />}
        />
        <Route
          path={`${props.match.path}/details/:id/:regardingObjectId`}
          render={props => <CustomFieldDetails {...props} />}
        />
        <Route
          path="/admin/custom-fields"
          render={props => <CustomFields {...props} />}
        />
      </Switch>
    </div>
  );
};

export default withRouter(CustomFieldsRouter);