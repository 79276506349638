import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faUnlink } from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { LanguageContext } from "../../../../contexts/language-context";
import {
  templateRoleClaimAssociation,
  deleteRoleClaimAssociationAPI,
  getRolesAPI
} from "../roles/companyRolesAPI";
import Accent from "../../../../components/accent/Accent";
import Gap from "../../../../components/gap/Gap";
import { getClaimByIdAPI, getRolesByClaimIdAPI } from "./companyClaimsAPI";
import { toast } from "react-toastify";
import TranslatableText from "../../../../Language/TranslatableText";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";


const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class CompanyClaimDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      name: "",
      rolesDropdown: [],
      searchableRolessDropdown: [],
      roleId: "",
      rolesModal: false,
      rolesData: [],
      rolesColumns: [
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions"
        }
      ],
      rolesTableLoader: false,
      deleteModal: false,
      deleteLoader: false,
      roleRemovalId: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getClaimByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.name
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ name: "-" });
        });
      this.populateRolesTable(this.props.match.params.id);
    }
  }

  search = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === key) {
        return array[i];
      }
    }
  };

  populateRolesTable = id => {
    this.setState({ rolesTableLoader: true, rolesData: [] });
    getRolesByClaimIdAPI(id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var self = this;
        var claims = res;
        var claimsFilteredArray = [];

        getRolesAPI().then(response => {
          this.setState(
            {
              searchableRolessDropdown: response
            },
            () => {
              claimsFilteredArray = self.state.searchableRolessDropdown.filter(
                el => {
                  if (self.search(el.id, claims)) {
                    return false; // skip
                  }
                  return true;
                }
              );
              this.setState({ rolesDropdown: claimsFilteredArray });
            }
          );
        });

        var data = res
          .filter(function (el) {
            if (el.name === "") {
              return false; // skip
            }
            return true;
          })
          .map(function (el) {
            var o = Object.assign({}, el);
            o.actions = (
              <div className="actions">
                <Tippy placement="right" content="Remove">
                  <span>
                    <FontAwesomeIcon
                      className="app-icon"
                      icon={faUnlink}
                      size="1x"
                      style={{ color: "red" }}
                      onClick={e => self.openDeleteModal(el.id, e)}
                    />
                  </span>
                </Tippy>
              </div>
            );
            return o;
          });

        this.setState({
          rolesData: data,
          rolesTableLoader: false,
          loading: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ rolesTableLoader: false, loading: false });
      });
  };

  addRole = e => {
    e.preventDefault();
    if (
      this.state.roleId === "" ||
      this.state.roleId === undefined ||
      this.state.roleId === null
    ) {
      return false;
    }
    this.setState({ loading: true });
    const data = {
      roleId: this.state.roleId,
      claimId: this.props.match.params.id
    };
    templateRoleClaimAssociation(data)
      .then(res => {
        if (res.data.message === "Success") {
          this.populateRolesTable(this.props.match.params.id);
          this.setState({ roleId: "" });
          this.toggleRoleModal();
        } else {
          this.setState({ loading: false, roleId: "" });
          this.toggleRoleModal();
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false, roleId: "" });
        this.toggleRoleModal();
      });
  };

  toggleRoleModal = () => {
    this.setState(prevState => ({
      rolesModal: !prevState.rolesModal
    }));
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            roleRemovalId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      roleRemovalId: id
    });
  };

  deleteClaim = id => {
    var associationIds = {
      roleId: id,
      claimId: this.props.match.params.id
    };
    this.setState({ deleteLoader: true });
    deleteRoleClaimAssociationAPI(associationIds)
      .then(res => {
        this.populateRolesTable(this.props.match.params.id);
        this.setState({
          deleteModal: false,
          deleteLoader: false,
          roleRemovalId: null
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          deleteLoader: false,
          roleRemovalId: null
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { rolesData, rolesColumns, rolesTableLoader } = this.state;
    return (
      <div className="user-details-view">
        <section>
          <Row>
            <Col md={12}>
              {this.state.name !== "" ? (
                <Accent useIcon={false}>
                  <TranslatableText translationKey="claims.roles_for_the_claim" />{" "}
                  "{this.state.name}"
                </Accent>
              ) : (
                <Accent useIcon={false}>
                  <InlineLoading />
                </Accent>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="flex flex-end">
                <div>
                  <Button outline onClick={this.toggleRoleModal}>
                    <FontAwesomeIcon icon={faLink} size="1x" />{" "}
                    <TranslatableText translationKey="add" />
                  </Button>
                </div>
              </div>
              <Gap size="10px" />
              <div className="table-container">
                <ToolkitProvider
                  keyField="id"
                  data={rolesData}
                  columns={rolesColumns}
                >
                  {props => (
                    <div>
                      <BootstrapTable
                        noDataIndication={() =>
                          rolesTableLoader ? (
                            <InlineLoading />
                          ) : (
                            <NoDataIndication />
                          )
                        }
                        {...props.baseProps}
                        pagination={paginationFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        </section>

        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>Back</Button>
          </Col>
        </Row>
        <div className="modals">
          <Modal
            isOpen={this.state.rolesModal}
            toggle={this.toggleRoleModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleRoleModal}>
              <TranslatableText translationKey="roles" />
            </ModalHeader>
            <ModalBody>
              <Form>
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    <FormGroup>
                      <Label for="roleId">
                        <TranslatableText translationKey="claims.add_role" />
                      </Label>
                      <Input
                        type="select"
                        name="roleId"
                        id="roleId"
                        value={this.state.roleId}
                        onChange={this.handleChange}
                      >
                        <option value="">-- Vælg --</option>
                        {this.state.rolesDropdown.map((e, key) => {
                          return (
                            <option key={e.id} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form className="flex flex-end">
                  <Button className="mr-10" outline onClick={this.addRole}>
                    <TranslatableText translationKey="add" />
                  </Button>
                  <Button outline onClick={this.toggleRoleModal}>
                    <TranslatableText translationKey="cancel" />
                  </Button>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              Confirm Delete?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="claims.confirm_delete_role_description" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deleteClaim(this.state.roleRemovalId, e)}
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanyClaimDetails);
CompanyClaimDetails.contextType = GlobalContext;