import { faHatWizard, faInfoCircle, faPencilAlt, faPlay, faProjectDiagram, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import Tippy from "@tippyjs/react";
import { useContext } from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, NavLink, Row } from "reactstrap";
import { GetSectionProcessResponse, HandleCaught_API_Error_Base, SectionsClient } from "../../../../apiClients";
import SecuredComponent from "../../../../components/secured-component/SecuredComponent";
import { Table_GetLocalStorage, Table_SetLocalStorage, Table_HeaderFormatter, Table_NoDataIndication } from "../../../../components/table";
import { AccountLevel, Actions } from "../../../../config/Levels";
import { ResourceTypes } from "../../../../config/ResourceTypes";
import { GlobalContext } from "../../../../contexts/global-context";
import TranslatableText from "../../../../Language/TranslatableText";
import { triggerApprovalFlowAPI } from "../approvals-flow/approvalsFlowAPI";
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


export default function ProcessList() {
  const { user } = useContext(GlobalContext);
  const { path } = useRouteMatch();
  const history = useHistory();

  const sectionsClient = new SectionsClient();
  const processes = useQuery(['sectionsProcesses', "extracted"], async () => {
    return sectionsClient.sectionsProcesses()
      .then(res => {
        return res.flatMap(section => {
          return section.processes !== undefined ? section.processes : [];
        })
      });
  }, { staleTime: 1000 * 60 * 1 });

  const tableColumns: Array<ColumnDescription<GetSectionProcessResponse>> = [
    {
      dataField: "id",
      text: "ID",
      hidden: true
    },
    {
      dataField: "sectionName",
      text: "Section",
      sort: true
    },
    {
      dataField: "name", //res[0].systems.map(row => row.name)
      text: "name",
      headerFormatter: Table_HeaderFormatter,
      sort: true
    },
    {
      dataField: "order",
      text: "Order",
      sort: true
    },
    {
      dataField: "actions",
      text: "Actions",
      csvExport: false,
      formatter: (_cell, row) => {
        return (
          <div className="actions">
            <NavLink
              tag={Link}
              to={`/company-admin/flow-engine/${row.regardingObjectTypeId}/${row.id}`}
            >
              <FontAwesomeIcon icon={faProjectDiagram} size="1x" />
            </NavLink>
            {row.flowEngine ? (
              <NavLink
                onClick={() =>
                  triggerApprovalFlow(
                    row.regardingObjectTypeId,
                    row.id,
                    Actions.onUserRequest
                  )
                }
              >
                <Tippy placement="top" content="Trigger Flow">
                  <span>
                    <FontAwesomeIcon icon={faPlay} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
            ) : (
              <NavLink>
                <Tippy placement="top" content="No Flow Attached">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#ccc" }}
                      icon={faPlay}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            )}
            <NavLink
              tag={Link}
              to={`${path}/details/${row.id}`}
            >
              <Tippy placement="top" content="Details">
                <span>
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </span>
              </Tippy>
            </NavLink>
            <NavLink
              tag={Link}
              to={`processes/wizard/${row.wizardId}/${row.id}/${row.regardingObjectTypeId}`}
            >
              <Tippy placement="top" content="Edit">
                <span>
                  <FontAwesomeIcon icon={faHatWizard} size="1x" />
                </span>
              </Tippy>
            </NavLink>
            <NavLink
              tag={Link}
              to={`${path}/update/${row.id}`}
            >
              <Tippy placement="top" content="Edit">
                <span>
                  <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                </span>
              </Tippy>
            </NavLink>

            {!row.lock ? (
              <NavLink onClick={() => openDeleteModal(row.id)}>
                <Tippy placement="top" content="Delete">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            ) : (
              <></>
            )}
          </div>
        );
      }
    }
  ]

  const openDeleteModal = id => {
  };

  // const deleteProcess = id => {
  //   this.setState({ deleteLoader: true });
  //   deleteProcessByIdAPI(id)
  //     .then(res => this.populateProcessesList())
  //     .catch(HandleCaught_API_Error_Base)
  //     .finally(() => {
  //       this.setState({
  //         deleteModal: false,
  //         processId: null,
  //         deleteLoader: false
  //       });
  //     });
  // };

  const triggerApprovalFlow = (
    regardingObjectTypeId,
    regardingObjectId,
    actionType
  ) => {
    let data = {
      regardingObjectTypeId: regardingObjectTypeId,
      regardingObjectId: regardingObjectId,
      actionType: actionType
    };
    triggerApprovalFlowAPI(data)
      .then(() => {
        toast.info("Flow Triggered");
      })
      .catch(HandleCaught_API_Error_Base);
  };

  return (
    <div className="admin-partners-view">
      <h1>Process</h1>
      <hr />
      <div className="table-container">
        <ToolkitProvider
          keyField="id"
          data={processes.data ?? []}
          columns={tableColumns}
          search
          exportCSV={{
            fileName: "data.csv",
            separator: user.language === 'dk' ? ';' : ',',
            ignoreHeader: false,
            noAutoBOM: false
          }}
        >
          {props => (
            <div>
              <div className="flex space-between">
                <div>
                  <SearchBar {...props.searchProps} />
                </div>
                <div>
                  <div className="flex space-between">
                    <SecuredComponent accountLevel={AccountLevel.Developer}>
                      <ExportCSVButton className="export-btn" {...props.csvProps}>CSV</ExportCSVButton>
                    </ SecuredComponent>
                    <div>
                      <SecuredComponent resourceType={ResourceTypes.process} action={Actions.write}>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`${path}/create`}
                        >
                          <TranslatableText translationKey="create" />
                        </NavLink>
                      </SecuredComponent>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <BootstrapTable
                noDataIndication={() => <Table_NoDataIndication />}
                {...props.baseProps}
                // @ts-ignore
                pagination={paginationFactory({ sizePerPage: Table_GetLocalStorage(), onSizePerPageChange: e => Table_SetLocalStorage(e) })} // OBS <-- implement localStorage here

              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <Row>
        <Col md={12}>
          <Button onClick={() => history.goBack()}>
            <TranslatableText translationKey="back" />
          </Button>
        </Col>
      </Row>
      <div>



        {/* <Modal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeletePopup}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeletePopup}>
            <TranslatableText translationKey="confirm_delete" />?
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <p>
                  <TranslatableText translationKey="confirm_delete_process" />
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {this.state.deleteLoader === false ? (
              <Button
                outline
                className="redOutline"
                onClick={e => this.deleteProcess(this.state.processId, e)}
              >
                <TranslatableText translationKey="delete" />
              </Button>
            ) : (
              <InlineLoading />
            )}{" "}
            <Button outline onClick={this.toggleDeletePopup}>
              <TranslatableText translationKey="cancel" />
            </Button>
          </ModalFooter>
        </Modal> */}
      </div>
    </div>
  );
}