import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  createProcessAPI,
  getProcessByIdAPI,
  updateProcessAPI
} from "./companyProcessAPI";
import { getSections } from "../sections/companySectionsAPI";
import { getWizardsAPI } from "../../../admin/views/wizard/wizardAPI";
import { getUsersAPI } from "../users/usersAPI";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { getEntityCustomFieldsAPI } from "../../../admin/views/custom-fields/customFieldsAPI";
import Fields from "../../../../components/fields/Fields";
import { Config } from "../../../../config/Config";
import Select from "react-select";
import { AccountLevel } from "../../../../config/Levels";
import { PlatformUserStateContext } from "../../../../contexts";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../components";



// Global Variables
var customFieldsModel;

class CreateCompanyProcess extends Component {
  static contextType = PlatformUserStateContext
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "sectionId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "wizardId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "order",
        method: "isEmpty",
        validWhen: false,
        // Todo: Add Properkey for message
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      nameShort: "",
      nameLong: "",
      descriptionShort: "",
      descriptionLong: "",
      language: this.context?.user.organizations.defaultLanguage,
      languageDropdown: Config.languages,
      sectionDropdown: [],
      wizardDropdown: [],
      usersDropdown: [],
      customFields: [],
      name: "",
      sectionId: "",
      wizardId: "1668280a-1505-4151-33dc-08d7db8b2d5c",
      responsibleId: "",
      selectedResponsibleId: [],
      responsibleRequired: false,
      order: "",
      loading: false,
      cuLoader: false,
      validation: this.validator.valid(),
    };

    this.submitted = false;
  }
  componentDidMount() {
    getSections()
      .then(res => {
        this.setState({ sectionDropdown: Array.isArray(res) ? res : [] });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    getWizardsAPI()
      .then(res => {
        this.setState({ wizardDropdown: Array.isArray(res) ? res : [] });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    getUsersAPI()
      .then(res => {
        var usersIds = [];
        res.forEach(function (row) {
          usersIds.push({ label: row.name, value: row.id });
        });
        this.setState({ usersDropdown: usersIds });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    if (this.props.match.params.sectionId !== undefined) {
      this.setState({
        sectionId: this.props.match.params.sectionId
      });
    }

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getProcessByIdAPI(this.props.match.params.id)
        .then(res => {
          let self = this;
          this.setState(
            {
              name: res.data.name,
              sectionId: res.data.sectionId,
              wizardId: res.data.wizardId,
              responsibleId: res.data.responsibleId,
              order: res.data.order,
              lock: res.data.lock,
              customFields:
                res.data.customFieldValues === null ||
                res.data.customFieldValues === undefined
                  ? []
                  : res.data.customFieldValues,
              loading: false,
              descriptionShort: "0", // bypass validations incase of update since this field is not available to update
              language: this.context.user.organizations.defaultLanguage // bypass validations incase of update since this field is not available to update
            },
            () => {
              let userCustomFields =
                res.data.customFieldValues === null ||
                res.data.customFieldValues === undefined
                  ? []
                  : res.data.customFieldValues;
              userCustomFields.forEach(element => {
                if (element.displayTypeCode === 1007) {
                  self.setState({
                    [element.id]:
                      element.tagBoxValues === null ? "" : element.tagBoxValues
                  });
                } else {
                  self.setState({
                    [element.name]: element.value === null ? "" : element.value
                  });
                }
              });
            }
          );
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    } else {
      this.populateCustomFields(1001);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { usersDropdown, responsibleId } = this.state;
    if (
      responsibleId &&
      usersDropdown.length &&
      (prevState.responsibleId !== responsibleId ||
        prevState.usersDropdown !== usersDropdown)
    ) {
      const selectedResponsibleId = usersDropdown.filter(item => {
        return item.value === responsibleId;
      })[0];
      this.setState({ selectedResponsibleId });
    }
  }

  populateCustomFields = code => {
    this.setState({ loading: true, customFields: [] });
    getEntityCustomFieldsAPI(code)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ customFields: res, loading: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
  };

  createProcess = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    let key,
      self = this;

    // Preparing custom fields model
    customFieldsModel = this.state.customFields;
    this.state.customFields.forEach((element, i) => {
      if (element.displayTypeCode === 1007) {
        key = element.id;
        customFieldsModel[i].value = JSON.stringify(
          Array.isArray(self.state[key])
            ? self.state[key].map(row => row.value)
            : []
        );
      } else {
        key = element.name;
        customFieldsModel[i].value = self.state[key];
      }
    });

    const data = {
      name: this.state.name,
      nameShort: this.state.name,
      descriptionShort: this.state.descriptionShort,
      language: this.state.language,
      sectionId: this.state.sectionId,
      wizardId: this.state.wizardId,
      responsibleId: this.state.responsibleId,
      order: this.state.order,
      customFieldValues: customFieldsModel,
      userId: this.context.user.organizations.userId,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : ""
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        if (
          this.context.userRoles.find(r => r.includes("Developer")) !==
            undefined ||
          (this.context.userRoles.find(r => r.includes("Developer")) ===
            undefined &&
            this.state.responsibleId !== "")
        ) {
          this.setState({ cuLoader: true });
          updateProcessAPI(data)
            .then(res => {
              this.setState(
                {
                  cuLoader: false
                },
                () => {
                  this.goBack();
                }
              );
            })
            .catch(error => {
              if (error.response.status === 403) {
                toast.error(this.context.languages["insufficient_permission"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 2000
                });
              } else {
                toast.error(this.context.languages["api_error"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 2000
                });
              }
              this.setState({ cuLoader: false });
            });
        } else {
          this.setState({
            responsibleRequired: true
          });
        }
      }
    } else {
      if (validation.isValid) {
        if (
          this.context.userRoles.find(r => r.includes("Developer")) !==
            undefined ||
          (this.context.userRoles.find(r => r.includes("Developer")) ===
            undefined &&
            this.state.responsibleId !== "")
        ) {
          this.setState({ cuLoader: true });
          createProcessAPI(data)
            .then(res => {
              this.setState(
                {
                  cuLoader: false
                },
                () => {
                  this.goBack();
                }
              );
            })
            .catch(error => {
              if (error.response.status === 403) {
                toast.error(this.context.languages["insufficient_permission"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 2000
                });
              } else {
                toast.error(this.context.languages["api_error"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 2000
                });
              }
              this.setState({ cuLoader: false });
            });
        } else {
          this.setState({
            responsibleRequired: true
          });
        }
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleTagboxChange = (id, selectedOptions) => {
    this.setState({
      [id]: selectedOptions
    });
  };

  handleSingleTagboxChange = (state, selectedStateName, selectedOption) => {
    this.setState({
      [state]:
        selectedOption === null || selectedOption === undefined
          ? ""
          : selectedOption.value,
      [selectedStateName]: selectedOption === null ? "" : selectedOption
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  changeValidationMessage = (message, event) => {
    let msg = message === null ? "This is a required field" : message;
    event.target.setCustomValidity(msg);
  };

  render() {
    let text, radio, field;
    let { loading, lock } = this.state;
    const organisationData = this.context.user.organizations;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-company-process-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          <TranslatableText translationKey="process" />
        </h3>
        <Form onSubmit={this.createProcess}>
          <Row form>
            <Col md={6}>
              <FormGroup
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  <TranslatableText translationKey="name" />
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="namet"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                  disabled={lock ? true : null}
                />
                <span className="help-block">{validation.name.message}</span>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup
                className={validation.sectionId.isInvalid ? "has-error" : ""}
              >
                <Label for="sectionId">
                  {" "}
                  <TranslatableText translationKey="section" />
                </Label>
                <Input
                  type="select"
                  name="sectionId"
                  id="sectionId"
                  value={this.state.sectionId}
                  onChange={this.handleChange}
                  disabled={
                    this.props.match.params.sectionId !== undefined ||
                    lock === true
                      ? true
                      : null
                  }
                >
                  <option value="">-- Vælg --</option>
                  {this.state.sectionDropdown.map((e, key) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                <span className="help-block">
                  {validation.sectionId.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup
                className={validation.order.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  {" "}
                  <TranslatableText translationKey="page.order" />
                </Label>
                <Input
                  type="number"
                  name="order"
                  id="order"
                  placeholder=""
                  value={this.state.order}
                  onChange={this.handleChange}
                  disabled={lock ? true : null}
                />
                <span className="help-block">{validation.order.message}</span>
              </FormGroup>
            </Col>
            <Col md={this.props.match.params.id === undefined ? 4 : 6}>
              <FormGroup
                className={validation.wizardId.isInvalid ? "has-error" : ""}
              >
                <Label for="wizardId">Wizard</Label>
                <Input
                  type="select"
                  name="wizardId"
                  id="wizardId"
                  value={this.state.wizardId}
                  onChange={this.handleChange}
                  disabled={(lock ||  organisationData.accountLevel < AccountLevel.Partner)   ? true : null}
                >
                  <option value="">-- Vælg --</option>
                  {this.state.wizardDropdown.map((e, key) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                <span className="help-block">
                  {validation.wizardId.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={this.props.match.params.id === undefined ? 4 : 6}>
              <FormGroup
                className={
                  this.context.userRoles.find(r =>
                    r.includes("Developer")
                  ) === undefined &&
                  this.state.responsibleId === "" &&
                  this.state.responsibleRequired
                    ? "has-error"
                    : "no-error"
                }
              >
                <Label for="role">
                  {" "}
                  <TranslatableText translationKey="responsible" />
                </Label>
                <Select
                  isDisabled={lock ? true : null}
                  value={
                    this.state.selectedResponsibleId === ""
                      ? null
                      : this.state.selectedResponsibleId
                  }
                  className="user-selectbox"
                  options={this.state.usersDropdown}
                  onChange={selectedOption =>
                    this.handleSingleTagboxChange(
                      "responsibleId",
                      "selectedResponsibleId",
                      selectedOption
                    )
                  }
                  hideSelectedOptions={false}
                />
                {this.context.userRoles.find(r =>
                  r.includes("Developer")
                ) === undefined &&
                this.state.responsibleId === "" &&
                this.state.responsibleRequired ? (
                  <span className="help-block">
                    <TranslatableText translationKey="requiredField" />
                  </span>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
            {this.props.match.params.id === undefined ? (
              <Col md={4}>
                <FormGroup
                  className={validation.language.isInvalid ? "has-error" : ""}
                >
                  <Label for="language">
                    {" "}
                    <TranslatableText translationKey="language" />
                  </Label>
                  <Input
                    type="select"
                    name="language"
                    id="language"
                    value={this.state.language}
                    onChange={this.handleChange}
                    disabled={
                      this.props.match.params.translationId !== undefined ||
                      lock === true
                        ? true
                        : null
                    }
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.languageDropdown.map((e, key) => {
                      return (
                        <option key={key} value={e.value}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Input>
                  <span className="help-block">
                    {validation.language.message}
                  </span>
                </FormGroup>
              </Col>
            ) : (
              <></>
            )}
            {this.props.match.params.id === undefined ? (
              <Col md={12}>
                <FormGroup>
                  <Label for="descriptionShort">
                    {" "}
                    <TranslatableText translationKey="description" />
                  </Label>
                  <Input
                    type="textarea"
                    name="descriptionShort"
                    id="descriptionShort"
                    placeholder=""
                    value={this.state.descriptionShort}
                    onChange={this.handleChange}
                    disabled={lock ? true : null}
                  />
                </FormGroup>
              </Col>
            ) : (
              <></>
            )}

            <Fields
              customFields={this.state.customFields}
              componentState={this.state}
              handleChange={this.handleChange}
              handleTagboxChange={this.handleTagboxChange}
              changeValidationMessage={this.changeValidationMessage}
            />
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                {" "}
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateCompanyProcess);
CreateCompanyProcess.contextType = GlobalContext;