import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faTimes, faUnlink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {
    CSVExport, Search
} from "react-bootstrap-table2-toolkit";
import DatePicker from "react-datepicker";
import MultiSlider, { Dot, Progress } from "react-multi-bar-slider";
import { useHistory, useParams } from 'react-router';
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, NavLink, Row } from "reactstrap";
import Swal from "sweetalert2";
import { InlineLoading, ScreenLoading } from '../../components';
import Gap from "../../components/gap/Gap";
import ProgressWidget from "../../components/progress/ProgressWidget";
import FormValidator from '../../components/validator/FormValidator';
import { GlobalContext } from '../../contexts/global-context';
import TranslatableText from '../../Language';
import Editor from "../admin/views/wizard/views/questions/editor/Editor";
import {
    cancelApprovalAPI,
    getApprovalFlowsProgressV1API, readyApprovalAPI
} from "../company-admin/views/approvals-flow/approvalsFlowAPI";
import { getTaskRegardingObjectTypesAPI } from '../company-admin/views/custom-entities/tenantCustomEntitiesAPI';
import { getEntityCustomFieldsAPI } from '../company-admin/views/custom-fields/customFieldsCompanyAPI';
import { getSections } from '../company-admin/views/sections/companySectionsAPI';
import { getUsersAPI } from "../company-admin/views/users/usersAPI";
import { getProcessesByIdAPI } from '../dashboard/dashboardAPI';
import { getEntitiesAPI } from '../entity/entityAPI';
import RepeatText from './RepeatText';
import { createTaskV2API, getTaskByIdV2API, updateTaskStatusAPI, updateTaskV2API } from './tasksAPI';



const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
    <div className="text-center">
        <span>No data available</span>
    </div>
);
const defaultSorted = [
    {
        dataField: "organizationName", // if dataField is not match to any column you defined, it will be ignored.
        order: "asc" // desc or asc
    }
];

const validator = new FormValidator([
    {
        field: "recurrenceTypeId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
    },
    {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
    },
    {
        field: "selectedOption",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
    },
    {
        field: "description",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
    },
    {
        field: "dueDateTime",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
    },
    {
        field: "endDate",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
    },
]);

const validatorEntity = new FormValidator([
    {
        field: "regardingObjectTypeId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
    },
    {
        field: "regardingObjectId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
    }
]);

type CreateTaskV2Props = {
    language: any
}

export type DaysType = {
    title: string,
    displayName: string,
    active: boolean,
}

const DAYSLIST = [
    {
        title: "Monday",
        displayName: "M",
        active: true,
    },
    {
        title: "Tuesday",
        displayName: "T",
        active: true,
    },
    {
        title: "Wednesday",
        displayName: "W",
        active: true,
    },
    {
        title: "Thursday",
        displayName: "T",
        active: true,
    },
    {
        title: "Friday",
        displayName: "F",
        active: true,
    },
    {
        title: "Saturday",
        displayName: "S",
        active: false,
    },
    {
        title: "Sunday",
        displayName: "S",
        active: false,
    },
]

const RecurrenceType = [
    {
        id: "1009",
        name: "Once"
    },
    {
        id: "1001",
        name: "Daily"
    },
    {
        id: "1002",
        name: "Weekly"
    },
    {
        id: "1004",
        name: "Monthly"
    },
    {
        id: "1008",
        name: "Yearly"
    },
]
const getRecurrenceTypeId = (name) => {
    var temp = RecurrenceType.filter(val => {
        if (val.name === name) {
            return true;
        } else {
            return false;
        }
    })
    return temp[0].id;
}

type CreateTaskV2Params = {
    id: string | undefined;
};

const CreateTaskV2: React.FC<CreateTaskV2Props> = (props) => {
    const { language } = props;
    const { id } = useParams<CreateTaskV2Params>();
    const { user, userRoles, languages } = useContext(GlobalContext);
    const firstUpdate = useRef(true);
    const firstUpdateEntity = useRef(true);
    const [loading, setLoading] = useState<any>(false);
    const [approvalCancelLoader, setApprovalCancelLoader] = useState<any>(false);
    const [taskCompleteLoader, setTaskCompleteLoader] = useState<any>(false);
    const [taskCancelLoader, setTaskCancelLoader] = useState<any>(false);
    const [inProgressLoader, setInProgressLoader] = useState<any>(false);
    const [cancelled, setCancelled] = useState<any>(false);
    const [description, setDescription] = useState<any>("");
    const [progression, setProgression] = useState<any>("");
    const [dragedProgress, setDragedProgress] = useState<any>("");
    const [statusCode, setStatusCode] = useState<any>("");
    const [validation, setValidation] = useState<any>(validator.valid());
    const [validationEntity, setValidationEntity] = useState<any>(validatorEntity.valid());
    const [selectedOption, setSelectedOption] = useState<any>("");
    const [responsibleIds, setResponsibleIds] = useState<any>([]);
    const [recurrenceTypeDropDown, setRecurrenceTypeDropDown] = useState<any>(RecurrenceType);
    const [approvers, setApprovers] = useState<any>([]);
    const [customFields, setCustomFields] = useState<any>([]);
    const [regardingObjectTypeIdDropdown, setRegardingObjectTypeIdDropdown] = useState<any>([]);
    const [dueDatePicker, setDueDatePicker] = useState<any>(new Date());
    const [recurrenceTypeId, setRecurrenceTypeId] = useState<any>("1009");
    const [dueTimeDatePicker, setDueTimeDatePicker] = useState<any>(null);
    const [dueDateTimeDatePicker, setDueDateTimeDatePicker] = useState<Date>(new Date());
    const [endDatePicker, setEndDatePicker] = useState<any>("");
    const [dueDate, setDueDate] = useState<any>(new Date());
    const [endDate, setEndDate] = useState<any>(null);
    const [dueTime, setDueTime] = useState<any>(null);
    const [dayOfWeek, setDayOfWeek] = useState<any>(null);
    const [progress, setProgress] = useState<any>(null);
    const [taskRegardingObjectTypeId, setTaskRegardingObjectTypeId] = useState<any>(null);
    const [updateSeries, setUpdateSeries] = useState<any>(null);
    const [entityName, setEntityName] = useState<any>(null);
    const [entityRecordDropdown, setEntityRecordDropdown] = useState<any>([]);
    const [recurrenceTypeName, setRecurrenceTypeName] = useState<any>(null);
    const [entityTypeName, SetEntityTypeName] = useState<any>(null);
    const [postUserIds, setPostUserIds] = useState<any>(null);
    const [regardingObjectId, setRegardingObjectId] = useState<any>("");
    const [regardingObjectTypeId, setRegardingObjectTypeId] = useState<any>("");
    const [monthOfYear, setMonthOfYear] = useState<any>(null);
    const [dayOfMonth, setDayOfMonth] = useState<any>(null);
    const [name, setName] = useState<any>("");
    const [entityCreationModal, setEntityCreationModal] = useState<any>(null);
    const [repeatCreationModal, setRepeatCreationModal] = useState<any>(null);
    // const [entities, setEntites] = useState<any>([]);
    const [repeatNumbers, setRepeatNumbers] = useState<any>(Array.from({ length: 20 }, (x, i) => i + 1));
    const [repeatNumber, setRepeatNumber] = useState<string>("1");
    const [days, setDays] = useState<DaysType[]>(DAYSLIST);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [occursRadio, setOccursRadio] = useState<string>("day"); // date or  day
    const [entities, setEntites] = useState<any>([]);
    const columnsConst = [
        {
            dataField: "entityType", //res[0].systems.map(row => row.name)
            text: "Type",
            sort: true
        },
        {
            dataField: "entityName", //res[0].systems.map(row => row.name)
            text: "Navn",
            sort: true
        },
        {
            dataField: "actions",
            text: "Handlinger",
            csvExport: false,
            attrs: { width: 50, class: "EditRow" },
            formatExtraData: entities,
            formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
                return (
                    <div className="actions">
                        {(id === "" || id === undefined) &&
                            <NavLink
                                onClick={() => removeToEntityList(row)}
                            >
                                <Tippy placement="top" content="Remove">
                                    <span>
                                        <FontAwesomeIcon
                                            style={{ color: "#d84a4a" }}
                                            icon={faUnlink}
                                            size="1x"
                                        />
                                    </span>
                                </Tippy>
                            </NavLink>
                        }
                    </div>
                );
            }
        }
    ]

    const history = useHistory();

    useEffect(() => {
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var c = new Date(year + 1, month, day);
        setEndDatePicker(c)
        getTaskRegardingObjectTypesAPI()
            .then(res => {
                if (!Array.isArray(res)) {
                    console.log("debug: ", res);
                    return;
                }
                let entities = res.filter(function (el) {
                    if (el.code === 1004) {
                        return false; // skip
                    }
                    return true;
                });
                setRegardingObjectTypeIdDropdown(Array.isArray(entities) ? entities : [])
            })
            .catch(error => {
                toast.error(language["api_error"], {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
            });

        getUsersAPI()
            .then(res => {
                var userIdAPI: any[] = [];
                res.forEach(function (row: any) {
                    userIdAPI.push({ label: row.name, value: row.id });
                });
                setResponsibleIds(userIdAPI)
            })
            .catch(error => {
                toast.error(language["api_error"], {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
            });

        if (id !== undefined) {
            setLoading(true);
        }

        if (id !== undefined) {
            getTaskByIdV2API(id)
                .then(res => {
                    //New Data
                    setEntites(res.data.taskEntityTypeAndName)

                    //New Data

                    // To populate multiselect dropdown we must require ID's and the Name to make an object
                    // Here is the correct way of doing it.
                    // API must return Name and ID
                    var selected = res.data.users.map((el: any) => {
                        var pair = { label: el.name, value: el.id };
                        return pair;
                    });
                    setSelectedOption(selected)
                    let self = this;
                    var recurrenceType = search(
                        res.data.recurrenceTypeId,
                        recurrenceTypeDropDown
                    );


                    // id: res.data.id,
                    setName(res.data.name === null ? "" : res.data.name)
                    setProgression(res.data.progression === null
                        ? ""
                        : res.data.progression)
                    setDueTimeDatePicker(stringToTimestamp(res.data.dueTime))
                    setEndDatePicker(dateFormatter(res.data.endDate))
                    setDueDatePicker(dateFormatter(res.data.actualDueDate))
                    setEndDate(new Date(res.data.endDate).toISOString())
                    setDueDate(new Date(res.data.actualDueDate).toISOString())
                    setDueDateTimeDatePicker(new Date(res.data.actualDueDate))
                    setDueTime(res.data.dueTime)
                    setDayOfWeek(res.data.dayOfWeek === null ? "" : res.data.dayOfWeek)
                    setDayOfMonth(res.data.dayOfMonth === null ? "" : res.data.dayOfMonth)
                    setMonthOfYear(res.data.monthOfYear === null
                        ? ""
                        : res.data.monthOfYear)
                    setDescription(res.data.description === null
                        ? ""
                        : res.data.description)
                    setRegardingObjectId(res.data.regardingObjectId === null
                        ? ""
                        : res.data.regardingObjectId)
                    setRegardingObjectTypeId(res.data.regardingObjectTypeId === null
                        ? ""
                        : res.data.regardingObjectTypeId)
                    setRecurrenceTypeId(getRecurrenceTypeId(res.data.recurrenceType))
                    setPostUserIds(selected.map((row: any) => row.value))
                    SetEntityTypeName(res.data.entityTypeName === null
                        ? ""
                        : res.data.entityTypeName)
                    setEntityName(res.data.entityName === null ? "" : res.data.entityName)
                    setStatusCode(res.data.statusCode)
                    setUpdateSeries(res.data.updateSeries)
                    setTaskRegardingObjectTypeId(res.data.taskRegardingObjectTypeId)
                    setProgress(res.data.progressPercentage === undefined ||
                        res.data.progressPercentage === null ||
                        res.data.progressPercentage === ""
                        ? 0
                        : res.data.progressPercentage)
                    setCustomFields(res.data.customFieldValues === null ||
                        res.data.customFieldValues === undefined
                        ? []
                        : res.data.customFieldValues)
                    setLoading(false);


                    let userCustomFields =
                        res.data.customFieldValues === null ||
                            res.data.customFieldValues === undefined
                            ? []
                            : res.data.customFieldValues;
                    userCustomFields.forEach((element: any) => {
                        if (element.displayTypeCode === 1007) {
                            // self.setState({
                            //     [element.id]:
                            //         element.tagBoxValues === null
                            //             ? ""
                            //             : element.tagBoxValues
                            // });
                        } else {
                            // self.setState({
                            //     [element.name]:
                            //         element.value === null ? "" : element.value
                            // });
                        }
                    });

                    if (
                        res.data.progressPercentage === 100 &&
                        res.data.statusCode === 1005
                    ) {
                        getApprovalFlowsProgressV1API(
                            res.data.taskRegardingObjectTypeId,
                            id
                        ).then(res => {
                            let list = res.approvalProgresses;
                            list.sort(function (l: any, r: any) {
                                return l.approvalFlowOrder - r.approvalFlowOrder;
                            });
                            setApprovers(list);
                        });
                    }
                    // if (
                    //     recurrenceType !== undefined ||
                    //     recurrenceType !== null ||
                    //     recurrenceType !== ""
                    // ) {
                    //     showFieldsOnRecurrenceType(recurrenceType.name.trim());
                    // }
                })
                .catch(error => {
                    setLoading(false);
                });

            // TO bypaas the end-date validation on Task edit mode
            setEndDate(new Date().toISOString());
        } else {
            populateCustomFields(1006);
        }


    }, [])

    const dateFormatter = (timestamp: any) => {
        return new Date(timestamp);
    };

    const stringToTimestamp = (time: any) => {
        var d = new Date(), // Convert the passed timestamp to milliseconds
            yyyy = d.getFullYear(),
            mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
            dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
            timestamp;

        // ie: 2014-03-24, 3:00 PM
        timestamp = yyyy + "-" + mm + "-" + dd + " " + time;
        return new Date(timestamp);
    };

    const populateCustomFields = (code: any) => {
        setLoading(true);
        setCustomFields([]);
        getEntityCustomFieldsAPI(code)
            .then(res => {
                if (!Array.isArray(res)) {
                    console.log("debug: ", res);
                    return;
                }
                setLoading(false);
                setCustomFields(res);
            })
            .catch(error => {
                toast.error(language["api_error"], {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
                setLoading(false);
            });
    };

    const updateTaskStatus = (status: any, progress: any) => {
        const data = {
            id: id,
            tasksStatus: status,
            progressPercentage: status === 1004 && progress === 0 ? 1 : progress
        };
        if (status === 1002) {
            setTaskCompleteLoader(true);
        } else if (status === 1003) {
            setTaskCancelLoader(true);
        } else if (status === 1004) {
            setInProgressLoader(true);
        }
        updateTaskStatusAPI(data).then(res => {
            if (status === 1002) {
                setTaskCompleteLoader(false);
                setProgress(100);
                setStatusCode(status);
            } else if (status === 1003) {
                setTaskCancelLoader(false);
                setProgress(0);
                setStatusCode(status);
            } else if (status === 1004) {
                setInProgressLoader(false);
                setStatusCode(status);
                setProgress(progress === 0 ? 1 : progress)
            }
        });
    };

    const completeTask = status => {
        const data = {
            id: id,
            tasksStatus: status,
            progressPercentage: 100
        };
        setTaskCompleteLoader(true);
        Swal.fire({
            title: languages["swal.are_you_sure"],
            text: languages["swal.are_you_sure.complete_task"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: languages["swal.are_you_sure.yes"],
            cancelButtonText: languages["swal.are_you_sure.no"]
        }).then(result => {
            if (result.value) {
                setProgress(100);
                let data = {
                    task: {
                        id:
                            id !== undefined
                                ? id
                                : "",
                        progressPercentage: progress,
                        responsibleIds: postUserIds,
                        dueTime: dueTime,
                        actualDueDate: dueDate,
                        description: description,
                        progression: progression,
                        updateSeries: updateSeries
                    },
                    regardingObjectTypeId: taskRegardingObjectTypeId,
                    regardingObjectId: id
                };
                readyApprovalAPI(data).then(res => {
                    getApprovalFlowsProgressV1API(
                        taskRegardingObjectTypeId,
                        id
                    ).then(approvers => {
                        if (approvers.approvalProgresses.length === 0) {
                            setTaskCompleteLoader(false);
                            setProgress(100);
                            setLoading(false);
                            setStatusCode(status);
                            Swal.fire(
                                languages["swal.Success"],
                                languages["swal.success_task_completed"],
                                "success"
                            );

                        } else {
                            let list = approvers.approvalProgresses;
                            list.sort(function (l, r) {
                                return l.approvalFlowOrder - r.approvalFlowOrder;
                            });
                            setApprovers(list);
                            setLoading(false);
                            setStatusCode(1005);
                            setTaskCompleteLoader(false);
                            Swal.fire(
                                languages["swal.Success"],
                                languages["swal.success_waiting_approval"],
                                "success"
                            );

                        }
                    });
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                setTaskCompleteLoader(false);
            }
        });
        //}
    };

    const cancelTask = status => {
        const data = {
            id: id,
            tasksStatus: status,
            progressPercentage: progress === 100 ? 99 : progress
        };
        setTaskCancelLoader(true);
        Swal.fire({
            title: languages["swal.are_you_sure"],
            text: languages["swal.are_you_sure.confirm_task_cancel"],
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: languages["swal.are_you_sure.no"],
            confirmButtonText: languages["swal.are_you_sure.yes"]
        }).then(result => {
            if (result.isConfirmed) {
                updateTaskStatusAPI(data).then(res => {
                    setTaskCancelLoader(false);
                    setProgress(progress === 100 ? 99 : progress);
                    setStatusCode(status);
                });
                Swal.fire(
                    languages["swal.cancelled"],
                    languages["swal.cancelled_task_cancelled"],
                    "success"
                );
            } else {
                setTaskCancelLoader(false);
            }
        });
    };

    const cancelApproval = e => {
        setApprovalCancelLoader(true);
        Swal.fire({
            title: languages["swal.are_you_sure"],
            text: languages["swal.are_you_sure.cancel_flow"],
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: languages["swal.are_you_sure.no"],
            confirmButtonText: languages["swal.are_you_sure.yes"]
        }).then(result => {
            if (result.isConfirmed) {
                let data = {
                    regardingObjectTypeId: taskRegardingObjectTypeId,
                    regardingObjectId: id
                };
                cancelApprovalAPI(data).then(res => {
                    setApprovalCancelLoader(false);
                    setCancelled(true);
                    setStatusCode(1004);
                    setProgress(progress === 100 ? 99 : progress);
                    Swal.fire(
                        languages["swal.cancelled"],
                        languages["swal.cancelled_approval_cancelled"],
                        "error"
                    );
                });
            } else {
                setApprovalCancelLoader(false);
            }
        });
    };

    const handleSlide = (newProgress: any) => {
        setProgress(newProgress);
    };

    const onDragStart = (progress: any) => {
        setDragedProgress(progress);
    };

    const onDragStop = (progress: any) => {
        if (progress === 100) {
            Swal.fire({
                title: languages["swal.are_you_sure"],
                text: languages["swal.are_you_sure.complete_task"],
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: languages["swal.are_you_sure.yes"],
                cancelButtonText: languages["swal.are_you_sure.no"]
            }).then(result => {
                if (result.value) {
                    let data = {
                        task: {
                            id:
                                id !== undefined
                                    ? id
                                    : "",
                            progressPercentage: progress,
                            responsibleIds: postUserIds,
                            dueTime: dueTime,
                            actualDueDate: dueDate,
                            description: description,
                            progression: progression,
                            updateSeries: updateSeries
                        },
                        regardingObjectTypeId: taskRegardingObjectTypeId,
                        regardingObjectId: id
                    };
                    setLoading(true);
                    readyApprovalAPI(data).then(res => {
                        getApprovalFlowsProgressV1API(
                            taskRegardingObjectTypeId,
                            id
                        ).then(approvers => {
                            if (approvers.approvalProgresses.length > 0) {
                                let list = approvers.approvalProgresses;
                                list.sort(function (l, r) {
                                    return l.approvalFlowOrder - r.approvalFlowOrder;
                                });
                                setApprovers(list);
                                setLoading(false);
                                setStatusCode(1005);
                                Swal.fire(
                                    languages["swal.Success"],
                                    languages["swal.success_waiting_approval"],
                                    "success"
                                );
                            } else {
                                setLoading(false);
                                setStatusCode(1002);
                                setTaskCompleteLoader(false);
                                Swal.fire(
                                    languages["swal.Success"],
                                    languages["swal.success_task_completed"],
                                    "success"
                                );
                            }
                        });
                    });
                    setLoading(false);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    setProgress(dragedProgress);
                }
            });
        } else if (
            progress < 100 &&
            statusCode !== 1004 &&
            statusCode !== 1002 &&
            statusCode !== 1003 &&
            statusCode !== 1005
        ) {
            Swal.fire({
                title: languages["swal.are_you_sure"],
                text: languages["swal.are_you_sure.task_status_progress"],
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: languages["swal.are_you_sure.yes"],
                cancelButtonText: languages["swal.are_you_sure.no"]
            }).then(result => {
                if (result.value) {
                    // Maybe call the update task API here
                    updateTaskStatus(1004, progress);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    setProgress(dragedProgress);
                }
            });
        }
    };

    const search = (key: any, array: any) => {
        for (var i = 0; i < array.length; i++) {
            if (array[i].id === key) {
                return array[i];
            }
        }
    };

    const handleTriggerDateTime = (date: Date) => {
        setDueDateTimeDatePicker(date)
    };

    const handleEndDate = (date: Date) => {
        setEndDatePicker(date);
    }

    const goBack = () => {
        history.goBack();
    };

    const populateEntityRecordDropdown = (entity: any, value: any) => {
        switch (entity) {
            case 1001:
                getProcessesByIdAPI().then(res => {
                    setEntityRecordDropdown(res);
                });
                break;
            case 1002:
                getSections().then(res => {
                    setEntityRecordDropdown(res);
                });
                break;
            case 1000:
                getEntitiesAPI(value).then(res => {
                    setEntityRecordDropdown(res);
                });
                break;
            default:
                setEntityRecordDropdown([]);
        }
    };

    const handleSelectChange = (selectedOption: any) => {
        setPostUserIds(
            selectedOption === null || selectedOption === undefined
                ? []
                : selectedOption.map((row: any) => row.value))
        setSelectedOption(selectedOption === null ? "" : selectedOption)

    };

    const handleChangeRegardingObjectTypeId = (event: any) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;


        if (name === "regardingObjectTypeId") {
            let code = target.options[target.selectedIndex].getAttribute("data-code");
            populateEntityRecordDropdown(parseInt(code), value);
        }
        setRegardingObjectTypeId(value)
    };

    const handleChangeRecurrenceTypeId = (event: any, toggle: boolean) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (name === "recurrenceTypeId") {
            var selected = target.options[target.selectedIndex].text;
            setRecurrenceTypeId(event.target.value)
            setRecurrenceTypeName(selected)
            toggleRepeatCreationModal(toggle)
            if (event.target.value === "1004" || event.target.value === "1008") {
                days.map((val: DaysType) => {
                    if (moment(dueDatePicker).format("dddd") === val.title) {
                        val.active = true;
                    }
                    else {
                        val.active = false;
                    }
                })
            }
            if (event.target.value === "1008") {
                setRepeatNumber("1");
            }
        }
    };

    const toggleEntityCreationModal = () => {
        if (!entityCreationModal) {
            setRegardingObjectId("");
            setRegardingObjectTypeId("");
        }
        setEntityCreationModal(!entityCreationModal);
    }

    const toggleRepeatCreationModal = (toggle?: boolean) => {
        if (toggle) {
            setRepeatCreationModal(toggle);
        } else {
            setRepeatCreationModal(!repeatCreationModal);
        }
    }
    useLayoutEffect(() => {
        if (firstUpdateEntity.current) {
            firstUpdateEntity.current = false;
            return;
        }
        addToEntityValidation();
    }, [regardingObjectId, regardingObjectTypeId])

    const addToEntityValidation = () => {
        let valid = false;
        const validation = validatorEntity.validate(
            {
                regardingObjectId: regardingObjectId,
                regardingObjectTypeId: regardingObjectTypeId
            }
        );
        setValidationEntity(validation);
        if (!validation.isValid) {
            valid = false;
        } else {
            valid = true;
        }
        return valid;
    }
    const addToEntityList = () => {
        if (!addToEntityValidation()) {
            return;
        }
        let tempEntity = entityRecordDropdown.filter((val: any) => {
            if (val.id === regardingObjectId) {
                return true;
            } else {
                return false;
            }
        })
        let tempEnityName = regardingObjectTypeIdDropdown.filter((val: any) => {
            if (val.id === regardingObjectTypeId) {
                return true;
            } else {
                return false;
            }
        })
        let alreadyExists = entities.filter((val: any) => {
            if (val.regardingObjectId === regardingObjectId) {
                return true;
            } else {
                return false;
            }
        })
        let newEntities = [...entities, {
            entityName: tempEntity[0].name === undefined ? tempEntity[0].nameShort : tempEntity[0].name,
            entityType: tempEnityName[0].name === undefined ? tempEnityName[0].nameShort : tempEnityName[0].name,
            regardingObjectId: regardingObjectId,
            regardingObjectTypeId: regardingObjectTypeId,

        }];
        if (alreadyExists.length === 0) {
            setEntites(newEntities);
        }
        toggleEntityCreationModal();
    }

    const removeToEntityList = (row: any) => {
        let temp = entities.filter((val: any) => {
            if (val.regardingObjectId !== row.regardingObjectId) {
                return true;
            } else {
                return false;
            }
        })
        setEntites(temp);
    }

    const toggleDays = (title: string) => {
        let temp = days;
        temp.map((val: DaysType) => {
            if (title === val.title) {
                val.active = !val.active;
            }
        })
        setDays(temp);
        setRefresh(!refresh);
    }

    const checkRepeatRecurrence = () => {
        let show = false;
        if (repeatNumber === "1" || recurrenceTypeId === "1009" || recurrenceTypeId === "1001" || recurrenceTypeId === "1002") {
            //Once
            //recurrenceType === recurrenceTypeId
            if (recurrenceTypeId === "1009" || recurrenceTypeId === "1001" || recurrenceTypeId === "1002") {
                show = true;
            }
        }
        return show;
    }

    function weekOfMonth(input = moment()) {
        const firstDayOfMonth = input.clone().startOf('month');
        const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

        const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');
        let number = Math.ceil((input.date() + offset) / 7);
        return number;

    }

    const numberToWord = (number: number) => {
        let val = "";
        switch (number) {
            case 1:
                val = "first";
                break;
            case 2:
                val = "second";
                break;
            case 3:
                val = "third";
                break;
            case 4:
                val = "forth";
                break;
            case 5:
                val = "fifth";
                break;
            default:
                val = "";
                break;
        }
        return val;
    }

    const handleOccursRadio = (event) => {
        setOccursRadio(event.target.value);
    }

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        createTaskValidation();
    }, [description, name, selectedOption])


    const createTaskValidation = () => {
        let valid = false;
        const validation = validator.validate(
            {
                recurrenceTypeId: recurrenceTypeId,
                selectedOption: selectedOption,
                description: description,
                name: name,
                dueDateTime: dueDateTimeDatePicker,
                endDate: endDatePicker,

            }
        );
        setValidation(validation);
        if (!validation.isValid) {
            valid = false;
        } else {
            valid = true;
        }
        return valid;
    }

    const createTask = (e) => {
        e.preventDefault();
        if (!createTaskValidation()) {
            return;
        }
        // Preparing custom fields model
        let customFieldsModel = customFields;
        customFields.forEach((element: any, i: any) => {
            if (element.displayTypeCode === 1007) {
                let key = element.id;
                customFieldsModel[i].value = JSON.stringify(
                    Array.isArray(customFields[key])
                        ? customFields[key].map((row: any) => row.value)
                        : []
                );
            } else {
                let key = element.name;
                customFieldsModel[i].value = customFields[key];
            }
        });
        let tempDaysOfWeek: string[] = []
        days.map((val: DaysType) => {
            if (val.active) {
                tempDaysOfWeek.push(val.title)
            }
        })
        let tempResponsibleIds: string[] = [];
        selectedOption.map((val) => {
            tempResponsibleIds.push(val.value)
        })
        let data = {
            organizationId: user.organizations.organizationId,
            name: name,
            description: description,
            // updateSeries: this.state.updateSeries,
            progression: progression,
            // dayOfWeek: this.state.dayOfWeek,
            // dayOfMonth: this.state.dayOfMonth,
            // monthOfYear: this.state.monthOfYear,
            // dueTime: this.state.dueTime,
            endDate: moment(endDatePicker).toISOString(),
            dueDate: moment(dueDateTimeDatePicker).toISOString(),
            actualDueDate: moment(dueDateTimeDatePicker).toISOString(),
            // recurrenceTypeId: this.state.recurrenceTypeId,
            // regardingObjectId: this.state.regardingObjectId,
            // regardingObjectTypeId: this.state.regardingObjectTypeId,
            responsibleIds: tempResponsibleIds,
            customFieldValues: customFieldsModel,
            id: id !== undefined
                ? id
                : "",
            userId: user.organizations.userId,

            //new
            daysOfWeek: tempDaysOfWeek,
            recurrenceTypeCode: recurrenceTypeId,
            repeat: repeatNumber,
            occurenceBy: occursRadio,
            weekOfTheMonth: weekOfMonth(moment(dueDateTimeDatePicker)),
            dayOfTheMonth: weekOfMonth(moment(dueDateTimeDatePicker)),
            dayNameOfTheMonth: moment(dueDateTimeDatePicker).format("dddd"),
            taskEntitiesList: entities

        }

        if (id !== undefined) {
            if (validation.isValid) {
                setLoading(true);
                updateTaskV2API(data)
                    .then(res => {
                        setLoading(false);
                        goBack();
                    })
                    .catch(error => {
                        toast.error(languages["api_error"], {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            hideProgressBar: true,
                            autoClose: 3000
                        });
                        setLoading(false);
                    });
            }
        } else {
            if (validation.isValid) {
                setLoading(true);
                createTaskV2API(data)
                    .then(res => {
                        setLoading(false);
                        goBack();
                    })
                    .catch(error => {
                        toast.error(languages["api_error"], {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            hideProgressBar: true,
                            autoClose: 3000
                        });
                        setLoading(false);
                    });
            }
        }

    }

    return (
        <div className="create-notification-view">
            {loading && (
                <ScreenLoading />
            )}
            {id !== undefined ? (
                <>
                    {statusCode === 1005 ? (
                        // @ts-ignore
                        <ProgressWidget approvers={approvers} />
                    ) : (
                        <></>
                    )}
                    <Row>
                        <Col md={12}>
                            <h3 className="progress-header">
                                <TranslatableText translationKey="tasks.taskProgress" />{" "}
                                {progress}%
                            </h3>
                        </Col>
                        <Col md={12}>
                            <MultiSlider
                                readOnly={
                                    statusCode === 1005 ||
                                        statusCode === 1002 ||
                                        statusCode === 1003
                                        ? true
                                        : false
                                }
                                classnName="task-progress"
                                width={"100%"}
                                height={20}
                                backgroundColor="#ccc"
                                equalColor="#42a5f5"
                                style={{ marginBottom: 40 }}
                                slidableZoneSize={40}
                                onSlide={handleSlide}
                                onDragStart={progress => onDragStart(progress)}
                                onDragStop={progress => onDragStop(progress)}
                                roundedCorners
                            >
                                <Progress
                                    height={20}
                                    color="blue"
                                    progress={progress}
                                    style={{ overflow: "unset" }}
                                >
                                    <Dot width={35} height={35} color="#1976d2" />
                                </Progress>
                            </MultiSlider>
                        </Col>
                    </Row>
                </>
            ) : (
                <Gap size="20px" />
            )}
            <Row>
                <Col>
                    <div className="flex space-between">
                        <h3>
                            {id !== undefined ? <TranslatableText translationKey="update" />
                                : <TranslatableText translationKey="create" />}{" "}
                            <TranslatableText translationKey="task" />
                        </h3>
                        {id !== undefined ? (
                            <div>
                                {!inProgressLoader &&
                                    statusCode !== 1005 ? (
                                    <Button
                                        className={
                                            statusCode === 1004 ? "orange-bg" : ""
                                        }
                                        outline
                                        onClick={e =>
                                            updateTaskStatus(1004, progress)
                                        }
                                        disabled={statusCode !== 1001}
                                    >
                                        <FontAwesomeIcon
                                            icon={faClock}
                                            size="1x"
                                            className="cursor"
                                        />
                                        <TranslatableText translationKey="tasks.inProgress" />
                                    </Button>
                                ) : statusCode === 1005 &&
                                    !inProgressLoader ? (
                                    <></>
                                ) : (
                                    <div className="inline-block">
                                        <InlineLoading />
                                    </div>
                                )}{" "}
                                {statusCode === 1005 ? (
                                    <Button className="orange-bg" outline disabled={true}>
                                        <FontAwesomeIcon
                                            icon={faClock}
                                            size="1x"
                                            // @ts-ignore
                                            disabled={true}
                                            className="cursor"
                                        />
                                        <TranslatableText translationKey="tasks.waitingForApproval" />
                                    </Button>
                                ) : (
                                    <></>
                                )}{" "}
                                {!taskCompleteLoader &&
                                    statusCode !== 1005 ? (
                                    <Button
                                        className={
                                            statusCode === 1002 ? "green-bg" : ""
                                        }
                                        outline
                                        onClick={e => completeTask(1002)}
                                        disabled={statusCode !== 1001 && statusCode !== 1004}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            size="1x"
                                            className="cursor"
                                        />
                                        {statusCode === 1002
                                            ? "Completed"
                                            : "Mark Complete"}
                                    </Button>
                                ) : !taskCompleteLoader &&
                                    statusCode === 1005 ? (
                                    <></>
                                ) : (
                                    <div className="inline-block">
                                        <InlineLoading />
                                    </div>
                                )}{" "}
                                {!taskCancelLoader &&
                                    statusCode !== 1005 ? (
                                    <Button
                                        className={statusCode === 1003 ? "red-bg" : ""}
                                        outline
                                        onClick={e => cancelTask(1003)}
                                        disabled={statusCode !== 1001 && statusCode !== 1004}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            size="1x"
                                            className="cursor"
                                        />
                                        {statusCode === 1003
                                            ? "Cancelled"
                                            : "Mark Cancel"}
                                    </Button>
                                ) : !taskCancelLoader &&
                                    statusCode === 1005 ? (
                                    <></>
                                ) : (
                                    <div className="inline-block">
                                        <InlineLoading />
                                    </div>
                                )}
                                {statusCode === 1005 ? (
                                    <span className="separator"></span>
                                ) : (
                                    <></>
                                )}
                                {!approvalCancelLoader &&
                                    statusCode === 1005 ? (
                                    <Button outline onClick={e => cancelApproval(e)}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            size="1x"
                                            className="cursor"
                                        />
                                        <TranslatableText translationKey="tasks.cancelApproval" />
                                    </Button>
                                ) : !approvalCancelLoader &&
                                    statusCode !== 1005 ? (
                                    <></>
                                ) : (
                                    <div className="inline-block">
                                        <InlineLoading />
                                    </div>
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </Col>
            </Row>
            <Form onSubmit={createTask}>
                <Row form>
                    <Col md={12}>
                        <FormGroup
                            className={validation.name.isInvalid ? "has-error" : ""}
                        >
                            <Label for="name"><TranslatableText translationKey="name" /></Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                placeholder=""
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                disabled={
                                    statusCode === 1005 ||
                                        statusCode === 1002
                                        ? true
                                        : false
                                }
                            />
                            <span className="help-block">{validation.name.message}</span>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup
                            className={validation.description.isInvalid ? "has-error" : ""}
                        >
                            <div className="flex space-between">
                                <Label for="description"><TranslatableText translationKey="description" /></Label>
                            </div>
                            <Editor initialValue={description} callback={e => setDescription(e)}
                                disabled={
                                    statusCode === 1005 ||
                                        statusCode === 1002
                                        ? true
                                        : false
                                } />
                            <span className="help-block">
                                {validation.description.message}
                            </span>
                        </FormGroup>
                    </Col>
                    <Col className="multiselect-container" md={12}>
                        <FormGroup
                            className={
                                validation.selectedOption.isInvalid ? "has-error" : ""
                            }
                        >
                            <Label for="sysname"><TranslatableText translationKey="responsible" /></Label>
                            <Select
                                isMulti
                                value={
                                    selectedOption === ""
                                        ? null
                                        : selectedOption
                                }
                                className="user-selectbox"
                                options={responsibleIds}
                                onChange={handleSelectChange}
                                placeholder="Select Users"
                                hideSelectedOptions={true}
                                isDisabled={
                                    statusCode === 1005 ||
                                        statusCode === 1002
                                        ? true
                                        : false
                                }
                            />
                            <span className="help-block">
                                {validation.selectedOption.message}
                            </span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup
                            className={validation.dueDateTime.isInvalid ? "has-error" : ""}
                        >
                            <Label for="dueDateTime"><TranslatableText translationKey="tasks.dueDateTime" /></Label>
                            <DatePicker
                                className="form-control datepicker timepicker"
                                selected={dueDateTimeDatePicker}
                                onChange={handleTriggerDateTime}
                                showTimeSelect
                                timeIntervals={15}
                                timeCaption="Time"
                                minDate={new Date()}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                disabled={
                                    statusCode === 1005 ||
                                        statusCode === 1002
                                        ? true
                                        : false
                                }
                            />
                            <span className="help-block">{validation.dueDateTime.message}</span>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup
                            className={
                                validation.recurrenceTypeId.isInvalid ? "has-error" : ""
                            }
                        >
                            <Label for="recurrenceTypeId"><TranslatableText translationKey="tasks.recurrenceType" /></Label>
                            <Input
                                type="select"
                                name="recurrenceTypeId"
                                id="recurrenceTypeId"
                                value={recurrenceTypeId}
                                onChange={(event) => handleChangeRecurrenceTypeId(event, true)}
                                disabled={
                                    statusCode === 1005 ||
                                        statusCode === 1002
                                        ? true
                                        : false
                                }
                            >
                                {recurrenceTypeDropDown.map((e: any, key: any) => {
                                    return (
                                        <option key={e.id} value={e.id}>
                                            {e.name}
                                        </option>
                                    );
                                })}
                            </Input>
                            <span className="help-block">
                                {validation.recurrenceTypeId.message}
                            </span>
                        </FormGroup>
                    </Col>
                    {recurrenceTypeId &&
                        <Col md={12}>
                            <RepeatText
                                days={days}
                                endDate={endDatePicker}
                                repeatNumber={repeatNumber}
                                recurrenceType={recurrenceTypeId}
                            />
                        </Col>
                    }
                </Row>

                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="progression"><TranslatableText translationKey="tasks.progression" /></Label>
                            <Editor initialValue={progression} callback={e => setProgression(e)}
                                disabled={
                                    statusCode === 1005 ||
                                        statusCode === 1002
                                        ? true
                                        : false
                                } />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="table-container">
                            <ToolkitProvider
                                bootstrap4
                                keyField="regardingObjectId"
                                data={entities}
                                columns={columnsConst}
                                search
                                exportCSV={{
                                    fileName: "data.csv",
                                    separator: language === 'dk' ? ';' : ',',
                                    ignoreHeader: false,
                                    noAutoBOM: false
                                }}
                            >
                                {props => (
                                    <div>
                                        <div className="flex flex-end">
                                            <Button
                                                className="button-primary"
                                                onClick={toggleEntityCreationModal}
                                            >
                                                <TranslatableText translationKey="addEntity" />
                                            </Button>
                                        </div>
                                        <hr />
                                        <BootstrapTable
                                            noDataIndication={() =>
                                                loading ? (
                                                    <InlineLoading />
                                                ) : (
                                                    <NoDataIndication />
                                                )
                                            }
                                            {...props.baseProps}
                                            // @ts-ignore
                                            defaultSorted={defaultSorted}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="space-between flex" md={12}>
                        <Button onClick={goBack}><TranslatableText translationKey="back_to_list" /></Button>
                        {loading === false ? (
                            <Button>
                                {id !== undefined
                                    ? <TranslatableText translationKey="update" />
                                    : <TranslatableText translationKey="create" />}
                            </Button>
                        ) : (
                            <InlineLoading />
                        )}
                    </Col>
                </Row>
            </Form>
            <div>
                <Modal
                    isOpen={entityCreationModal}
                    toggle={toggleEntityCreationModal}
                // className={className}
                >
                    <ModalHeader toggle={toggleEntityCreationModal}>
                        Add {name} To:
                    </ModalHeader>
                    <ModalBody>
                        <Form
                        // onSubmit={this.createUser}
                        >
                            <Row form>


                                <Col className="multiselect-container" md={12}>
                                    <FormGroup>
                                        <Label for="regardingObjectTypeId"><TranslatableText translationKey="entity" /></Label>
                                        <Input
                                            type="select"
                                            name="regardingObjectTypeId"
                                            id="regardingObjectTypeId"
                                            value={regardingObjectTypeId}
                                            onChange={handleChangeRegardingObjectTypeId}
                                            disabled={id !== undefined || statusCode === 1005 || statusCode === 1002}
                                        >
                                            <option value="">{languages["-- Select --"]}</option>
                                            {regardingObjectTypeIdDropdown.map((e: any, key: any) => {
                                                return (
                                                    <option key={key} data-code={e.code} value={e.id}>
                                                        {e.displayName}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                        <span className="help-block">
                                            {validationEntity.regardingObjectTypeId.message}
                                        </span>
                                    </FormGroup>
                                </Col>
                                <Col className="multiselect-container" md={12}>
                                    <FormGroup>
                                        <Label for="regardingObjectId"><TranslatableText translationKey="tasks.entityName" /></Label>
                                        <Input
                                            type="select"
                                            name="regardingObjectId"
                                            id="regardingObjectId"
                                            className={
                                                regardingObjectTypeId !== "" &&
                                                    regardingObjectId === ""
                                                    ? "has-error"
                                                    : ""
                                            }
                                            value={regardingObjectId}
                                            onChange={(event) => setRegardingObjectId(event.target.value)}
                                            disabled={id !== undefined || statusCode === 1005 || statusCode === 1002}
                                        >
                                            <option value="">{languages["-- Select --"]}</option>
                                            {entityRecordDropdown.map((e: any, key: any) => {
                                                return (
                                                    <option key={e.id} value={e.id}>
                                                        {e.name === undefined ? e.nameShort : e.name}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                        <span className="help-block">
                                            {validationEntity.regardingObjectId.message}
                                        </span>
                                    </FormGroup>
                                </Col>


                            </Row>
                            <Row form className="flex flex-end">
                                {loading === false ? (
                                    <Button className="mr-10" outline onClick={addToEntityList}>
                                        <TranslatableText translationKey="add" />
                                    </Button>
                                ) : (
                                    <span className="mr-10">
                                        <InlineLoading />
                                    </span>
                                )}

                                <Button outline onClick={toggleEntityCreationModal}>
                                    <TranslatableText translationKey="cancel" />
                                </Button>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={repeatCreationModal}
                    toggle={() => toggleRepeatCreationModal(false)}
                >
                    <ModalHeader toggle={() => toggleRepeatCreationModal(false)}>
                        <TranslatableText translationKey="Repeat" />
                    </ModalHeader>
                    <ModalBody>
                        <Form
                        // onSubmit={this.createUser}
                        >
                            <Row form>


                                <Col className="multiselect-container" md={6}>
                                    <FormGroup
                                        className={validation.dueDateTime.isInvalid ? "has-error" : ""}
                                    >
                                        <Label for="dueDateTime"><TranslatableText translationKey="tasks.dueDateTime" /></Label>
                                        <DatePicker
                                            className="form-control datepicker"
                                            selected={dueDateTimeDatePicker}
                                            onChange={handleTriggerDateTime}
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            minDate={new Date()}
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            disabled={
                                                recurrenceTypeId === "" ||
                                                    statusCode === 1005 ||
                                                    statusCode === 1002
                                                    ? true
                                                    : null
                                            }
                                        />
                                        <span className="help-block">{validation.dueDateTime.message}</span>
                                    </FormGroup>
                                </Col>
                                <Col
                                    // className={classnames(this.state.isVisibleED ? "show" : "hide")}
                                    md={6}
                                >
                                    <FormGroup
                                        className={validation.endDate.isInvalid ? "has-error" : ""}
                                    >
                                        <Label for="endDate"><TranslatableText translationKey="tasks.endDate" /></Label>
                                        <DatePicker
                                            className="form-control datepicker"
                                            selected={endDatePicker}
                                            onChange={handleEndDate}
                                            minDate={
                                                dueDatePicker === undefined
                                                    ? new Date()
                                                    : dueDatePicker
                                            }
                                            dateFormat="yyyy-MM-dd"
                                        />
                                        <span className="help-block">
                                            {validation.endDate.message}
                                        </span>
                                    </FormGroup>
                                </Col>
                                <Col className="multiselect-container" md={6}>
                                    <FormGroup>
                                        <Label for="repeatNumber"><TranslatableText translationKey="tasks.repeatNumber" /></Label>
                                        <Input
                                            type="select"
                                            name="repeatNumber"
                                            id="repeatNumber"
                                            className={
                                                repeatNumber !== "" &&
                                                    repeatNumber === ""
                                                    ? "has-error"
                                                    : ""
                                            }
                                            value={repeatNumber}
                                            onChange={(event) => setRepeatNumber(event.target.value)}
                                            disabled={
                                                recurrenceTypeId === "1008"
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <option value="" disabled>{languages["-- Select --"]}</option>
                                            {repeatNumbers.map((e: any, key: any) => {
                                                return (
                                                    <option key={e} value={e}>
                                                        {e}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col className="multiselect-container" md={6}>
                                    <FormGroup
                                        className={
                                            validation.recurrenceTypeId.isInvalid ? "has-error" : ""
                                        }
                                    >

                                        <Label for="recurrenceTypeId"><TranslatableText translationKey="tasks.recurrenceType" /></Label>
                                        <Input
                                            type="select"
                                            name="recurrenceTypeId"
                                            id="recurrenceTypeId"
                                            value={recurrenceTypeId}
                                            onChange={(event) => handleChangeRecurrenceTypeId(event, true)}
                                        >
                                            {recurrenceTypeDropDown.map((e: any, key: any) => {
                                                return (
                                                    <option key={e.id} value={e.id}>
                                                        {e.name}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                        <span className="help-block">
                                            {validation.recurrenceTypeId.message}
                                        </span>
                                    </FormGroup>
                                </Col>


                            </Row>
                            {checkRepeatRecurrence() ?
                                <Row>
                                    <Col md={12}>
                                        <div className='d-flex justify-content-around w-100'>
                                            {days.map((val: DaysType) => (
                                                <div onClick={() => toggleDays(val.title)} className={`days-icon ${val.active ? 'days-icon-active' : 'days-icon-inactive'}`}>
                                                    <span>
                                                        {val.displayName}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <div className="d-flex flex-column mt-2">
                                                <span>
                                                    <input
                                                        type="radio"
                                                        // className="stv-radio-button"
                                                        name="day"
                                                        value="Date"
                                                        id="date"
                                                        onChange={handleOccursRadio}
                                                        checked={occursRadio === "Date" ? true : false}
                                                    />
                                                    <Label for="dayNumber" className='ml-2'>
                                                        {recurrenceTypeId === "1004" &&
                                                            <>On day {moment(dueDateTimeDatePicker).format("DD")}</>
                                                        }
                                                        {recurrenceTypeId === "1008" &&
                                                            <>On {moment(dueDateTimeDatePicker).format("MMM")} {moment(dueDateTimeDatePicker).format("DD")}</>
                                                        }

                                                    </Label>
                                                </span>
                                                {weekOfMonth(moment(dueDateTimeDatePicker)) < 4 &&
                                                    <span>
                                                        <input
                                                            type="radio"
                                                            // className="stv-radio-button"
                                                            name="day"
                                                            value="Day"
                                                            id="day"
                                                            onChange={handleOccursRadio}
                                                            checked={occursRadio === "Day" ? true : false}
                                                        />
                                                        <Label for="day" className='ml-2'>

                                                            {recurrenceTypeId === "1004" &&
                                                                <>On the {numberToWord(weekOfMonth(moment(dueDateTimeDatePicker)))} {moment(dueDateTimeDatePicker).format("dddd")}</>
                                                            }
                                                            {recurrenceTypeId === "1008" &&
                                                                <>On the {numberToWord(weekOfMonth(moment(dueDateTimeDatePicker)))} {moment(dueDateTimeDatePicker).format("dddd")} of {moment(dueDateTimeDatePicker).format("MMM")}</>
                                                            }
                                                        </Label>
                                                    </span>}
                                                {weekOfMonth(moment(dueDateTimeDatePicker)) === 4 &&
                                                    <>
                                                        <span>
                                                            <input
                                                                type="radio"
                                                                // className="stv-radio-button"
                                                                name="day"
                                                                value="Day"
                                                                id="day"
                                                                onChange={handleOccursRadio}
                                                                checked={occursRadio === "Day" ? true : false}
                                                            />
                                                            <Label for="day" className='ml-2'>

                                                                {recurrenceTypeId === "1004" &&
                                                                    <>On the {numberToWord(weekOfMonth(moment(dueDateTimeDatePicker)))} {moment(dueDateTimeDatePicker).format("dddd")}</>
                                                                }
                                                                {recurrenceTypeId === "1008" &&
                                                                    <>On the {numberToWord(weekOfMonth(moment(dueDateTimeDatePicker)))} {moment(dueDateTimeDatePicker).format("dddd")} of {moment(dueDateTimeDatePicker).format("MMM")}</>
                                                                }
                                                            </Label>
                                                        </span>
                                                        <span>
                                                            <input
                                                                type="radio"
                                                                // className="stv-radio-button"
                                                                name="day"
                                                                value="DayLast"
                                                                id="dayLast"
                                                                onChange={handleOccursRadio}
                                                                checked={occursRadio === "DayLast" ? true : false}
                                                            />
                                                            <Label for="day" className='ml-2'>

                                                                {recurrenceTypeId === "1004" &&
                                                                    <>On the last {moment(dueDateTimeDatePicker).format("dddd")}</>
                                                                }
                                                                {recurrenceTypeId === "1008" &&
                                                                    <>On the last {moment(dueDateTimeDatePicker).format("dddd")} of {moment(dueDateTimeDatePicker).format("MMM")}</>
                                                                }
                                                            </Label>
                                                        </span>
                                                    </>
                                                }
                                                {weekOfMonth(moment(dueDateTimeDatePicker)) > 4 &&
                                                    <>
                                                        <span>
                                                            <input
                                                                type="radio"
                                                                // className="stv-radio-button"
                                                                name="day"
                                                                value="DayLast"
                                                                id="dayLast"
                                                                onChange={handleOccursRadio}
                                                                checked={occursRadio === "DayLast" ? true : false}
                                                            />
                                                            <Label for="day" className='ml-2'>

                                                                {recurrenceTypeId === "1004" &&
                                                                    <>On the last {moment(dueDateTimeDatePicker).format("dddd")}</>
                                                                }
                                                                {recurrenceTypeId === "1008" &&
                                                                    <>On the last {moment(dueDateTimeDatePicker).format("dddd")} of {moment(dueDateTimeDatePicker).format("MMM")}</>
                                                                }
                                                            </Label>
                                                        </span>
                                                    </>
                                                }

                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col>
                                    <RepeatText
                                        days={days}
                                        endDate={endDatePicker}
                                        repeatNumber={repeatNumber}
                                        recurrenceType={recurrenceTypeId}
                                    />
                                </Col>
                            </Row>
                            <Row form className="flex flex-end">
                                {loading === false ? (
                                    <Button className="mr-10" outline onClick={() => toggleRepeatCreationModal(false)}>
                                        <TranslatableText translationKey="add" />
                                    </Button>
                                ) : (
                                    <span className="mr-10">
                                        <InlineLoading />
                                    </span>
                                )}

                                <Button outline onClick={() => toggleRepeatCreationModal(false)}>
                                    <TranslatableText translationKey="cancel" />
                                </Button>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        </div>
    )
}

export default CreateTaskV2;