import { faNotEqual, faTachometerAlt } from "@fortawesome/pro-solid-svg-icons";
import { NavMenuLink } from "../NavMenuLink";

export function BruunRasmussenNavigation(menuItemClick: () => void) {
  return (
    <>
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faTachometerAlt}
        path='/'
        translationKey='dashboard'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faNotEqual}
        path='/entity/88044a25-1475-48d0-59ff-08d9af528687'
        translationKey='AML'
      />
    </>
  );
}