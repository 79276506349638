import axios from "axios";
import { Config } from "../config/Config";
import authService from "../components/api-authorization/AuthorizeService";

// Translations API URL
const TRANSLATIONS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Translations`;
const TRANSLATIONS_API_STRING = "TextTranslation";

// Create
export const getTextTranslationAPI = async (data: any)  => {
  const token = await authService.getAccessToken();
  let headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${TRANSLATIONS_API_URL}/${TRANSLATIONS_API_STRING}`, data, {
      headers: headers
    })
    .then(res => res);
};
