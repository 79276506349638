import React, { Component } from "react";
import * as d3 from "d3";
import "./mapStyle.css";

class Dendro extends Component {

  componentDidMount() {
    var w = 600
    var h = 400
    let data = this.handleProps();
    let svg = d3.select("#map")
    let width = +svg.attr("width");
    let height = +svg.attr("height");

    let transform = d3.zoomIdentity.translate(width / 2, height / 2);

    let g = svg
      .append("g")
      .attr("transform", transform)
    let cluster = d3.cluster().size([360, width / 2 - 120]);

    let root = d3.hierarchy(data);

    cluster(root);

    g
      .selectAll(".link")
      .data(root.descendants().slice(1))
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", d => {
        return (
          "M" +
          project(d.x, d.y) +
          "C" +
          project(d.x, (d.y + d.parent.y) / 2) +
          " " +
          project(d.parent.x, (d.y + d.parent.y) / 2) +
          " " +
          project(d.parent.x, d.parent.y)
        );
      });

    let node = g
      .selectAll(".node")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", d => {
        return "node" + (d.children ? " node--internal" : " node--leaf");
      })
      .attr("transform", d => {
        return "translate(" + project(d.x, d.y) + ")";
      });

    node.append("circle").attr("r", 3.5);



    node
      .append("text")
      .attr("dy", "0.31em")
      .attr("x", d => {
        return d.x < 180 === !d.children ? 6 : -6;
      })
      .style("text-anchor", d => {
        return d.x < 180 === !d.children ? "start" : "end";
      })
      .attr("transform", d => {
        // return "rotate(" + (d.x < 180 ? d.x - 90 : d.x + 90) + ")";
        return "rotate(" + (d.x < 180 ? d.x - 100 : d.x + 100) + ")";
      })
      .on("mouseover", (d) => this.mouseover(d, d.data?.name))
      .on("mouseout", this.mouseout)
      .text(d => {
        if (d.data.name) {
          return ( d.data.name.length > 25 ? d.data.name.substring(0, 19) + "..." : d.data.name )
        } else {
          return ( "")
        }

      });

    function project(x, y) {
      // let angle = (x - 75) / 180 * Math.PI,
      let angle = (x - 75) / 180 * Math.PI,
        radius = y;
      return [radius * Math.cos(angle), radius * Math.sin(angle)];
    }
  }

  handleProps() {
    if (this.props.data.length > 0 && this.props.entity !== "") {
      let props = this.props;

      let orgArray = props.data;
      let composedArray = [];
      for (var i = 0; i < orgArray.length; i++) {
        if (orgArray[i].displayTypeCode != 1007) {
          continue;
        }
        var entityName = orgArray[i].displayText;
        var entities = [];

        for (var n = 0; n < orgArray[i].tagBoxValues.length; n++) {
          let trimmedLabel = orgArray[i].tagBoxValues[n].label?.length > 19 ?
            orgArray[i].tagBoxValues[n].label?.substring(0, 19) + "..." : orgArray[i].tagBoxValues[n].label
          // remove <p></p> from string
          let trimmedDescription = orgArray[i].tagBoxValues[n].description?.slice(3, -4).length > 0 ?
            orgArray[i].tagBoxValues[n].description.slice(3, -4) : undefined

          entities.push(
            {
              name: trimmedLabel,
              description: trimmedDescription,
              size: n + 1 * 20 // arbitrary number
            }
          )
        }
        if (orgArray[i].tagBoxValues.length == 0) {
          entities.push(
            {
              name: 'No available data',
              description: undefined,
              size: i + 1 * 20 // arbitrary number
            }
          )
        }
        composedArray.push(
          {
            name: entityName,
            children: entities
          },
        )
      }
      let finalArray =
      {
        name: props.entity,
        children: composedArray
      };
      return finalArray
    } else {
      this.handleNoData();
    }
  }
  handleNoData() {
    alert('handleNoData')
    return {
      name: "no data available",
      children: [
        {
          name: "no data available",
          children: [
            {
              name: "no data available",
              size: 100,
            },
            {
              name: "no data available",
              size: 200,
            },
            {
              name: "no data available",
              size: 300,
            },
          ]
        },
      ]
    }
  }
  mouseover(d, tooltipName) {
    d3.select("#tooltip")
      .style("left", (d3.event.pageX / 2) + "px")
      .style("top", (d3.event.pageY / 2) + "px")
      .select("#value");

    if (d.data.description !== undefined) {
      d3.select("#tooltip").classed("hidden", false)
        .html("<strong>" + tooltipName + ":</strong> <br/> <span style='color:#2ECC71'>" + d.data.description + "</span>");
    } else if (tooltipName.length > 25 ) {
      d3.select("#tooltip").classed("hidden", false)
        .html("<strong>" + tooltipName + "</strong>");
    } else {
      d3.select("#tooltip").classed("hidden", true);
    }
  }

  mouseout(d) {
    d3.select("#tooltip").classed("hidden", true);
  }
  // renders the actual graph.
  render() {
    return (<>
      <div className="Map">
        <svg width="600" height="600" id="map" />
      </div>
      <div id="tooltip" className="hidden">
        <p><span id="value"></span></p>
      </div>
    <h5>Diagram</h5>
    </>
    );
  }

  collapse = d => {
    if (d.children) {
      d._children = d.children;
      d._children.forEach(this.collapse);
      d.children = null;
    }
  };
}

export default Dendro;
