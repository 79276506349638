import axios from "axios";
import { Config } from "../../../config/Config";
import authService from "../../../components/api-authorization/AuthorizeService";

// Entity API URL
const ENTITY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/CustomEntityValues`;
const FIELD_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/CustomFields`;
const API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}`;

// Get All Entitiess by ID ------------------------------------------------------
export const getEntitiesAPI = async regardingObjectTypeId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ENTITY_API_URL}/${regardingObjectTypeId}/CustomEntityValuesWithCustomField`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get OptionsByUrl API------------------------------------------------------
export const getOptionsByUrlAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${API_URL}/AnswerOptionSets/${id}/TagboxAnswerOptionSetValues`, {
      headers: headers
    })
    .then(({ data }) => data);
};


// Update Field API------------------------------------------------------
export const updateFieldValue = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${FIELD_API_URL}/UpdateCustomFieldValue`, data, { headers: headers })
    .then(res => res);
};