import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Teams API URL
const TEAMS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Teams`;

// Create
export const createTeamAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${TEAMS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update
export const updateTeamAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${TEAMS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get by ID
export const getTeamByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TEAMS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all
export const getTeamsAPI = async organizationId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TEAMS_API_URL}/${organizationId}/Teams`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteTeamByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${TEAMS_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Team-User Association

export const teamUserAssociationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${TEAMS_API_URL}/TeamUserAssociation`, data, {
      headers: headers
    })
    .then(res => res);
};

// Team-User Association list API
export const getUsersByTeamIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TEAMS_API_URL}/${id}/TeamUsers`, { headers: headers })
    .then(({ data }) => data);
};
// Team-User Association list API for partners
export const getUsersByOrgAndTeamIdAPI = async (orgId, teamId) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TEAMS_API_URL}/${orgId}/${teamId}/PartnerTeamUsers`, { headers: headers })
    .then(({ data }) => data);
};

// Delete Association
export const deleteTeamUserAssociationAPI = async data => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${TEAMS_API_URL}/TeamUserAssociation`, {
      data: data,
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};
