import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  createAnswerOptionSetValuesAPI,
  getAnswerOptionSetValuesByIdAPI,
  updateAnswerOptionSetValuesAPI
} from "./answeroptionsetAPI";
import FormValidator from "../../../../../../components/validator/FormValidator";
import "../../../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../../../components";



class CreateAnswerOptionSetValues extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "value",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      value: "",
      loading: false,
      cuLoader: false,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    if (this.props.match.params.answerOptionSetValueId !== undefined) {
      this.setState({ loading: true });
      getAnswerOptionSetValuesByIdAPI(
        this.props.match.params.answerOptionSetValueId
      )
        .then(res => {
          this.setState({
            value: res.data.value,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }
  createAnswerOptionSetValues = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      ...this.state,
      id:
        this.props.match.params.answerOptionSetValueId !== undefined
          ? this.props.match.params.answerOptionSetValueId
          : "",
      answerOptionSetId:
        this.props.match.params.answerOptionSetId !== undefined
          ? this.props.match.params.answerOptionSetId
          : "",
      userId: this.context.user.organizations.userId
    };
    if (this.props.match.params.answerOptionSetValueId !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateAnswerOptionSetValuesAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createAnswerOptionSetValuesAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-answeroptionsetvalues-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          {this.props.match.params.answerOptionSetValueId !== undefined
            ?  <TranslatableText translationKey="update" />
            : <TranslatableText translationKey="create" />}{" "}
          Answer Option Set Values
        </h3>
        <Form onSubmit={this.createAnswerOptionSetValues}>
          <Row form>
            <Col md={12}>
              <FormGroup
                className={validation.value.isInvalid ? "has-error" : ""}
              >
                <Label for="value">
                  <TranslatableText translationKey="value" />
                </Label>
                <Input
                  type="text"
                  name="value"
                  id="value"
                  placeholder=""
                  value={this.state.value}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.value.message}</span>
              </FormGroup>
            </Col>

            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.answerOptionSetValueId !== undefined
                    ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateAnswerOptionSetValues);
CreateAnswerOptionSetValues.contextType = GlobalContext;