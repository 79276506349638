import { faArrowAltCircleLeft, faAt, faEdit, faExternalLink, faMap, faPhone, faToolbox } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, NavLink, Row, Table } from "reactstrap";
import { GetUserResponse, OrganizationsClient, TeamsClient, UsersClient } from "../../../../apiClients";
import { HandleCaught_API_Error_Base } from "../../../../apiClients/api_caughtError";
import { InlineLoading } from "../../../../components";
import Accent from "../../../../components/accent/Accent";
import Gap from "../../../../components/gap/Gap";
import TranslatableText from "../../../../Language/TranslatableText";
import { createUserAPI } from "../users/partnerUsersAPI";
import ProjectUserList from './components/ProjectUserList';
import ProjectTeamsList from './components/TeamsList';



type DropdownOptions = {
  label: string,
  value: string
}

type ProjectDetailsState = {
  allUsersDropdown: DropdownOptions[] | undefined,
  selectedUser: DropdownOptions | undefined,
  userCreationModal: boolean,
  cuLoader: boolean,
}

const initialState: ProjectDetailsState = {
  allUsersDropdown: [],
  selectedUser: undefined,
  userCreationModal: false,
  cuLoader: false,
};

type ProjectDetailsParams = { projectId: string, companyId: string }
export default function ProjectDetails() {
  const { projectId, companyId } = useParams<ProjectDetailsParams>();
  const history = useHistory();
  const [allUsersCheckbox, setAllUsersCheckbox] = useState<boolean>(false);
  const [
    { userCreationModal, selectedUser, cuLoader, allUsersDropdown },
    setState
  ] = useState(initialState);

  const _OrganizationsClient = new OrganizationsClient();
  const _UsersClient = new UsersClient();
  const _TeamsClient = new TeamsClient();

  const organization = useQuery(['organization', projectId], async () => {
    return _OrganizationsClient.organizations(projectId)
  });
  const partnerId = organization.data?.partnerId;
  const accountResponsible = useQuery(['accountResponsible', companyId], async () => {
    return _UsersClient.accountRepresentativeList(companyId)
  });
  const allUsersFromPartner = useQuery(['partnerUsers', partnerId], async () => {
    return _UsersClient.partnerAllUsersWithType(partnerId!)
  }, { cacheTime: 60 * 3 * 1000, enabled: !!partnerId });
  const organizationTeams = useQuery(['organizationTeams', projectId], async () => {
    return _TeamsClient.teamsGet(projectId);
  }, { cacheTime: 60 * 3 * 1000 });
  const organizationUsers = useQuery(['organizationUsers', projectId], async () => {
    return _UsersClient.userListGetByOrgId(projectId);
  }, { cacheTime: 60 * 3 * 1000, refetchOnMount: true });




  const setDropDownOptions = (isAllUsers: boolean, res?: GetUserResponse[]) => {
    let temp: DropdownOptions[] = [];
    let tempAllUsers = res ?? allUsersFromPartner.data;
    if (Array.isArray(tempAllUsers)) {
      tempAllUsers.forEach((val) => {
        // tempAllUsers.map(val => {
        if (isAllUsers) {
          temp.push({ value: val.id, label: val.name });
        } else {
          if (val.userType === "PartnerUser") {
            temp.push({ value: val.id, label: val.name });
          }
        }
      })
    }
    setState((prevState) => ({ ...prevState, allUsersDropdown: temp }));
  }

  const handleSelectedOption = (selectedStateName: string, selectedOption: DropdownOptions | null) => {
    setState((prevState) => ({ ...prevState, [selectedStateName]: selectedOption }));
  };

  const dataURLtoFile = (dataurl: string, filename: string) => {
    let arr = dataurl.split(","),
      // @ts-ignore
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const toggleUserCreationModal = () => {
    setDropDownOptions(false, allUsersFromPartner.data);
    setState((prevState) => ({ ...prevState, userCreationModal: !userCreationModal }));
  };

  const createUser = (e: any) => {
    e.preventDefault();
    if (!selectedUser) {
      return;
    }
    setState((prevState) => ({ ...prevState, cuLoader: true }));
    let filteredUser: any = null;
    allUsersFromPartner.data?.map(val => {
      if (val.id === selectedUser?.value) {
        filteredUser = val;
      }
    })
    // Preparing custom fields model
    let customFieldValues = filteredUser.customFieldValues;
    filteredUser.customFieldValues.forEach((element: any, i: any) => {
      if (element.displayTypeCode === 1007) {
        let key = element.id;
        customFieldValues[i].value = JSON.stringify(
          Array.isArray(filteredUser[key])
            ? filteredUser[key].map((row: any) => row.value)
            : []
        );
      } else {
        let key = element.name;
        customFieldValues[i].value = filteredUser[key];
      }
    });
    const data = {
      name: filteredUser.name,
      phone: filteredUser.phone,
      email: filteredUser.email,
      organizationId: projectId,
      customFieldValues: customFieldValues,
      id: projectId,
      file: filteredUser.profileImage !== (undefined || null) ?
        { fileName: filteredUser.fileName, data: dataURLtoFile(filteredUser.profileImage, "profile-image") }
        :
        undefined
    };
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("customFieldValues", data.customFieldValues);
    formData.append("organizationId", projectId);
    if (filteredUser.profileImage !== (undefined || null)) {
      formData.append(
        "file",
        dataURLtoFile(filteredUser.profileImage, "profile-image")
      );
    }

    createUserAPI(formData)
      .then(() => {
        organizationUsers.refetch();
        toggleUserCreationModal();
      })
      .catch(HandleCaught_API_Error_Base)
      .finally(() => {
        setState((prevState) => ({ ...prevState, cuLoader: false }));
      });
  };

  function HandleAllUsersCheckbox(checked: boolean) {
    setAllUsersCheckbox(checked);
    setDropDownOptions(checked);
  }

  return (
    <div className="admin-view">
      <div className="relative flex flex-align space-between">
        <h1>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            size="1x"
            onClick={() => history.goBack()}
            className="cursor"
          />
          <TranslatableText translationKey="Project" /> <TranslatableText translationKey="details" />
        </h1>
        <NavLink
          className="edit"
          tag={Link}
          to={`/partner/company/project/update/${projectId}/${companyId}`}
        >
          <FontAwesomeIcon icon={faEdit} size="1x" />
        </NavLink>
      </div>
      {organization.status === 'loading' ? (
        <InlineLoading />
      ) : organization.status === "error" ? (
        <span>Error:</span>
      ) : (
        <>
          <Accent logo={organization.data?.logo}>
            <TranslatableText translationKey="Project" />&nbsp;<FontAwesomeIcon icon={faToolbox} />&nbsp;{organization.data?.name}
          </Accent>
          <Row>
            <Col md={12}>
              <div className="partner-section white-bg">
                <Table>
                  <tbody>
                    <tr>
                      <td className="w-25 text-capitalize bold">Logo</td>
                      <td>
                        <img src={organization.data?.logo} height="50" alt="" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25 text-capitalize bold"><TranslatableText translationKey="name" /></td>
                      <td>{organization.data?.name}</td>
                    </tr>
                    <tr>
                      <td className="w-25 text-capitalize bold"><TranslatableText translationKey="InvoiceNumber" /></td>
                      <td><FontAwesomeIcon icon={faPhone} />&nbsp;{organization.data?.invoiceNumber}</td>
                    </tr>
                    <tr>
                      <td className="w-25 text-capitalize bold"><TranslatableText translationKey="email" /></td>
                      <td><FontAwesomeIcon icon={faAt} />&nbsp;{organization.data?.email}</td>
                    </tr>
                    <tr>
                      <td className="w-25 text-capitalize bold"><TranslatableText translationKey="phone" /></td>
                      <td><FontAwesomeIcon icon={faPhone} />&nbsp;{organization.data?.phone}</td>
                    </tr>
                    <tr>
                      <td className="w-25 text-capitalize bold"><TranslatableText translationKey="address" /></td>
                      <td>{organization.data?.address}, {organization.data?.postalCode} {organization.data?.city}&nbsp;{organization.data?.address &&
                        <a target="_blank" href={'https://www.google.dk/maps/place/' + organization.data?.address + ' ' + organization.data?.postalCode + ' ' + organization.data?.city} rel="noreferrer">
                          <FontAwesomeIcon icon={faMap} />
                        </a>}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25 text-capitalize bold"><TranslatableText translationKey="vat" /></td>
                      <td>{organization.data?.vat}&nbsp;{organization.data?.countryCode == 'dk' &&
                        <a target="_blank" href={'https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=' + organization.data?.vat} rel="noreferrer">
                          <FontAwesomeIcon icon={faExternalLink} />
                        </a>}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </>
      )}

      <Gap size="50px" />
      {accountResponsible.status === 'loading' ? (
        <InlineLoading />
      ) : accountResponsible.status === "error" ? (
        <span>Error:</span>
      ) : (
        <>
          <Row>
            <Col md={12}><div className="partner-section white-bg">
              <h3>
                <TranslatableText translationKey="company" /> <TranslatableText translationKey="account_responsible" />
              </h3>
              {accountResponsible.data?.length !== 0 && (
                <div className="flex vertical-table">

                  <div className="flex flex-column table-header">
                    <div className="image-cell">-</div>
                    <div>
                      <TranslatableText translationKey="name" />
                    </div>
                    <div>
                      <TranslatableText translationKey="email" />
                    </div>
                    <div>
                      <TranslatableText translationKey="phone" />
                    </div>
                  </div>
                  <div className="flex flex-two table-body">
                    {accountResponsible.data?.map(
                      (representative, index) => (
                        <div
                          key={index}
                          className="flex flex-one flex-column table-columns"
                        >
                          <div className="user-image-cell">
                            <img
                              src={representative.profileImage}
                              height="50"
                            />
                          </div>
                          <div>{representative.name}</div>
                          <div><a href={"mailto:" + representative.email}>{representative.email}</a></div>
                          <div>{representative.phone}</div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {accountResponsible.data?.length == 0 && (
                <div className="flex vertical-table">
                  <div className="flex flex-column table-header">
                    <div>
                      <TranslatableText translationKey="account_responsible.none_selected" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            </Col>
          </Row>
        </>
      )}
      {organizationTeams.status === 'loading' ? (
        <InlineLoading />
      ) : organizationTeams.status === "error" ? (
        <span>Error:</span>
      ) : (
        ProjectTeamsList(projectId, organizationTeams.data)
      )}

      {organizationUsers.status === 'loading' ? (
        <InlineLoading />
      ) : organizationUsers.status === "error" ? (
        <span>Error:</span>
      ) : (
        ProjectUserList(projectId, organizationUsers.data, toggleUserCreationModal)
      )}
      <div>
        <Modal
          isOpen={userCreationModal}
          toggle={toggleUserCreationModal}
        >
          <ModalHeader toggle={toggleUserCreationModal}>
            Add User:
          </ModalHeader>
          <ModalBody>
            <div className="flex flex-end">
              <FormGroup className={`custom-control custom-switch`}>

                <input type='checkbox'
                  id={'allUsers'}
                  name={'allUsers'}
                  className={`custom-control-input`}
                  checked={allUsersCheckbox}
                  onChange={(event) => { HandleAllUsersCheckbox(event.target.checked) }}
                />
                <Label for={'allUsers'} className={'custom-control-label'}>
                  <TranslatableText translationKey={'all_users'} />
                </Label>
              </FormGroup>
            </div>
            <Form onSubmit={createUser}>
              <Row form>
                <Col className="multiselect-container" md={12}>
                  <FormGroup>
                    <Label for="role">
                      <TranslatableText translationKey="users" />
                    </Label>
                    <Select
                      value={selectedUser}
                      name={"selectedUser"}
                      className="user-selectbox"
                      options={allUsersDropdown}
                      onChange={selectedOption =>
                        handleSelectedOption(
                          "selectedUser",
                          selectedOption
                        )
                      }
                      hideSelectedOptions={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form className="flex flex-end">
                <Button outline onClick={toggleUserCreationModal}>
                  <TranslatableText translationKey="cancel" />
                </Button>
                {cuLoader === false ? (
                  <Button className="ml-10" outline>
                    <TranslatableText translationKey="add" />
                  </Button>
                ) : (
                  <span className="ml-10">
                    <InlineLoading />
                  </span>
                )}
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </div >
  );
}
