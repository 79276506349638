import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faTrophy
} from "@fortawesome/pro-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import {
  createCompanyAPI,
  updateCompanyAPI,
  getCompanyByIdAPI
} from "./firmAPI";
import { getPartners } from "../../../admin/views/partners/partnersAPI";
import { getSystems } from "../../../admin/views/systems/systemsAPI";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { Config } from "../../../../config/Config";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../components";



class AddFirm extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="email_empty" />
      },
      {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: <TranslatableText translationKey="email_valid" />
      },
      {
        field: "useremail",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="email_empty" />
      },
      {
        field: "partnerId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
      },
      {
        field: "vat",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "selectedOption",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "username",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "userPhone",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      loading: false,
      cuLoader: false,
      partnerDetails: {},
      partnersDropDown: [],
      templatesDropdown: [],
      allSystemIDs: [],
      systemIds: [],
      selectedOption: "",
      partnerId: "",
      name: "",
      phone: "",
      email: "",
      vat: "",
      address: "",
      postalCode: "",
      city: "",
      countryCode: "",
      releasePhase: 0,
      logo: "",
      username: "",
      useremail: "",
      userPhone: "",
      templateId: "",
      validation: this.validator.valid(),
      countryCodeDropdown: Config.languages
    };

    this.submitted = false;
  }
  componentDidMount() {
    getPartners()
      .then(res => {
        this.setState({ partnersDropDown: Array.isArray(res) ? res : [] });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    getSystems()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var partnerSystemIDs = [];
        res.forEach(function (row) {
          partnerSystemIDs.push({ label: row.name, value: row.id });
        });
        this.setState({ allSystemIDs: partnerSystemIDs });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getCompanyByIdAPI(this.props.match.params.id)
        .then(res => {
          var selected = res.data.systemIds.map(el => {
            var pair = { label: "", value: el };
            return pair;
          });
          this.setState({
            partnerId: res.data.partnerId,
            name: res.data.name,
            email: res.data.email,
            phone: parseInt(res.data.phone),
            vat: res.data.vat,
            address: res.data.address,
            postalCode: res.data.postalCode,
            city: res.data.city,
            countryCode: parseInt(res.data.countryCode),
            releasePhase: 0,
            logo: res.data.logo,
            selectedOption: selected,
            systemIds: selected.map(row => row.value),
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });

      // To Bypass the First User validations while updating the Company record
      this.setState({
        useremail: "0",
        username: "0",
        userPhone: "0",
        templateId: "0"
      });
    }
  }

  getBase64 = file => {
    var self = this;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      self.setState({
        logo: reader.result
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.type === "file") {
      const validImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml"
      ];

      if (target.files[0] !== undefined) {
        if (!validImageTypes.includes(target.files[0].type)) {
          alert("Only Images are allowed");
          target.value = "";
          return false;
        } else {
          if (target.files[0].size / 1024 <= 1000) {
            this.getBase64(target.files[0]);
          } else {
            alert("Max allowed size is 1000Kb");
            target.value = "";
            return false;
          }
        }
      }
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  handleSelectChange = selectedOption => {
    this.setState({
      systemIds: selectedOption.map(row => row.value),
      selectedOption: selectedOption
    });
  };
  createCompany = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      partnerId: this.state.partnerId,
      systemIds: this.state.systemIds,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      vat: this.state.vat,
      address: this.state.address,
      postalCode: this.state.postalCode,
      city: this.state.city,
      countryCode: this.state.countryCode,
      releasePhase: 0,
      logo: this.state.logo,
      userId: this.context.user.organizations.userId,
      templateId: this.state.templateId,
      username: this.state.username,
      useremail: this.state.useremail,
      userPhone: this.state.userPhone
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateCompanyAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createCompanyAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  removeLogo = () => {
    this.setState({ logo: "" });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    let { loading } = this.state;
    return (
      <div className="partner-view">
        {loading && (
          <ScreenLoading />
        )}
        <h1>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            size="1x"
            onClick={this.goBack}
            className="cursor"
          />{" "}
          {this.props.match.params.id !== undefined ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}{" "}
          <TranslatableText translationKey="company" />
        </h1>
        <Form onSubmit={this.createCompany}>
          <div className="top-container">
            <Row form>
              <Col className="multiselect-container" md={12}>
                <h4>Partner</h4>
                <FormGroup
                  className={validation.partnerId.isInvalid ? "has-error" : ""}
                >
                  <Label for="partnerId">
                    <TranslatableText translationKey="company.partner_as_company" />
                  </Label>
                  <Input
                    type="select"
                    name="partnerId"
                    id="partnerId"
                    value={this.state.partnerId}
                    onChange={this.handleChange}
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.partnersDropDown.map((e, key) => {
                      return (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Input>
                  <span className="help-block">
                    {validation.partnerId.message}
                  </span>
                </FormGroup>
                <FormGroup
                  className={
                    validation.selectedOption.isInvalid ? "has-error" : ""
                  }
                >
                  <Label for="sysname">
                    <TranslatableText translationKey="company_system.names" />
                  </Label>
                  <Input
                    type="select"
                    name="systemId"
                    id="systemId"
                    value={this.state.selectedOption[0]?.value}
                    onChange={(event) => { this.handleSelectChange([{ value: event.target.value, label: event.target.textContent }]) }}
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.allSystemIDs.map((e, key) => {
                      return (
                        <option key={e.value} value={e.value} label={e.label}>
                          {e.label}
                        </option>
                      );
                    })}
                  </Input>
                  <span className="help-block">
                    {validation.selectedOption.message}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <hr />
          </div>
          <div>
            <Row>
              <Col md={12}>
                <h4>
                  <TranslatableText translationKey="company.business" />
                </h4>
                <p>
                  <TranslatableText translationKey="company.enter_company_information" />
                </p>
              </Col>
            </Row>
            <Row form>
              <Col md={4}>
                <FormGroup
                  className={validation.name.isInvalid ? "has-error" : ""}
                >
                  <Label for="name">
                    <TranslatableText translationKey="name" />
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    placeholder="Company Name"
                  />
                  <span className="help-block">{validation.name.message}</span>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="phone">
                    <TranslatableText translationKey="phone" />
                  </Label>
                  <Input
                    type="number"
                    name="phone"
                    id="phone"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    placeholder="Company Phone No."
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup
                  className={validation.email.isInvalid ? "has-error" : ""}
                >
                  <Label for="email">
                    <TranslatableText translationKey="email" />
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Company Email Address"
                  />
                  <span className="help-block">{validation.email.message}</span>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup
                  className={validation.vat.isInvalid ? "has-error" : ""}
                >
                  <Label for="cvr">
                    <TranslatableText translationKey="company.vat" />
                  </Label>
                  <Input
                    type="text"
                    name="vat"
                    id="vat"
                    placeholder="Vat"
                    value={this.state.vat}
                    onChange={this.handleChange}
                  />
                  <span className="help-block">{validation.vat.message}</span>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="address">
                    <TranslatableText translationKey="address" />
                  </Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={this.state.address}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                {this.state.logo !== "" ? (
                  <div>
                    <p className="label">
                      <TranslatableText translationKey="logo" />{" "}
                      <span className="small">
                        (<TranslatableText translationKey="company.preview" />)
                      </span>
                    </p>
                    <div className="image-container">
                      <img
                        className="image-max-width"
                        src={this.state.logo}
                        height="33"
                      />
                      <span className="remove-image" onClick={this.removeLogo}>
                        <i className="fa fa-times"></i>
                      </span>
                    </div>
                  </div>
                ) : (
                  <FormGroup>
                    <Label for="logo">
                      <TranslatableText translationKey="company.upload_logo" />{" "}
                      <span className="red small">
                        ({" "}
                        <TranslatableText translationKey="company_max_allowed_size" />{" "}
                        <b>50Kb</b> )
                      </span>
                    </Label>
                    <Input
                      className="attachment-input"
                      type="file"
                      name="logo"
                      id="logo"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                )}
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="postalCode">
                    <TranslatableText translationKey="zip_code" />
                  </Label>
                  <Input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    value={this.state.postalCode}
                    onChange={this.handleChange}
                    placeholder="zipcode"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="city">
                    <TranslatableText translationKey="city_town" />
                  </Label>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    placeholder="City / Town"
                    value={this.state.city}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="package">
                    <TranslatableText translationKey="country_code" />
                  </Label>
                  <Input
                    type="select"
                    name="countryCode"
                    id="countryCode"
                    value={this.state.countryCode}
                    onChange={this.handleChange}
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.countryCodeDropdown.map((e, key) => {
                      return (
                        <option key={key} value={e.value}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            {this.props.match.params.id === undefined ? (
              <Row form>
                <Col md={12}>
                  <hr />
                </Col>
                <Col md={12}>
                  <h4>
                    <TranslatableText translationKey="company.first_user" />
                  </h4>
                  <p>
                    <TranslatableText translationKey="company.first_user_description" />
                  </p>
                </Col>
                <Col md={4}>
                  <FormGroup
                    className={validation.username.isInvalid ? "has-error" : ""}
                  >
                    <Label for="username">
                      <TranslatableText translationKey="name" />
                    </Label>
                    <Input
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Name"
                      value={this.state.username}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.username.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup
                    className={
                      validation.useremail.isInvalid ? "has-error" : ""
                    }
                  >
                    <Label for="useremail">
                      <TranslatableText translationKey="email" />
                    </Label>
                    <Input
                      type="email"
                      name="useremail"
                      id="useremail"
                      placeholder="user email"
                      value={this.state.useremail}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.useremail.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup
                    className={
                      validation.userPhone.isInvalid ? "has-error" : ""
                    }
                  >
                    <Label for="userPhone">
                      <TranslatableText translationKey="company.telephone_number" />
                    </Label>
                    <Input
                      type="tel"
                      name="userPhone"
                      id="userPhone"
                      placeholder="Telephone Number"
                      value={this.state.userPhone}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.userPhone.message}
                    </span>
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row form>
              <Col md={12} className="right-align">
                {this.state.cuLoader === false ? (
                  <Button>
                    <FontAwesomeIcon
                      icon={faTrophy}
                      size="1x"
                      className="cursor"
                    />{" "}
                    {this.props.match.params.id !== undefined
                      ?  <TranslatableText translationKey="update" />
                      : <TranslatableText translationKey="create" />}
                  </Button>
                ) : (
                  <InlineLoading />
                )}

                <div className="gap50" />
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(AddFirm);
AddFirm.contextType = GlobalContext;