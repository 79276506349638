import React, { Component } from "react";
import { NavLink, Row, Col, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { getPartners } from "./partnersAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faInfoCircle,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
//import "bootstrap/dist/css/bootstrap.min.css";

import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";



const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableLoader: false,
      partners: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "systems", //res[0].systems.map(row => row.name)
          text: "Sys Name",
          sort: true,
          formatter: this.systemsFormatter
        },
        {
          dataField: "logo",
          text: "Logo",
          formatter: this.logoFormatter,
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ]
    };
  }

  componentDidMount() {
    this.setState({
      tableLoader: true
    });
    getPartners()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var self = this;
        var data = res
          .filter(function (el) {
            if (el.name === "") {
              return false; // skip
            }
            return true;
          })
          .map(function (el) {
            var o = Object.assign({}, el);
            o.actions = (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`${self.props.match.path}/details/${el.id}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
                <NavLink
                  tag={Link}
                  to={`${self.props.match.path}/update/${el.id}`}
                >
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
              </div>
            );
            return o;
          });
        this.setState({ partners: data, loading: false, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  }

  systemsFormatter = (cell, row) => {
    return cell.map((item, index) => (
      <span key={item.id}>
        {item.name} {index < cell.length - 1 ? ",\u00A0" : ""}
      </span>
    ));
  };

  logoFormatter = cell => {
    if (
      cell !== "" &&
      cell !== null &&
      cell !== undefined &&
      cell.startsWith("data:")
    ) {
      return <img className="image-max-width" src={cell} />;
    } else {
      return "";
    }
  };

  rowClasses = (row, rowIndex) => {
    if (!row.isActive) {
      return "disabled-row";
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let { tableLoader } = this.state;
    return (
      <div className="admin-partners-view">
        <h1>
          <TranslatableText translationKey="partners" />
        </h1>
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={this.state.partners}
            columns={this.state.columns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`${this.props.match.path}/create`}
                        >
                          <TranslatableText translationKey="partners.add_new" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  rowClasses={this.rowClasses}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.languageResources.language_resources,
  user: state.organizations,
  roles: state.userRoles,
  loading: state.organizations.loading,
  error: state.organizations.error
});

export default withRouter(Partners);
Partners.contextType = GlobalContext;
