import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import AddFirm from "./AddFirm";
import Firm from "./Firm";
import FirmDetails from "./FirmDetails";
import ViewFirmPartnerDetails from "./ViewFirmPartnerDetails";
import CreateWizardTemplate from "../../../admin/views/wizard/CreateWizardTemplate";
import { withRouter } from "react-router-dom";
import ProjectDetails from "../../../admin/views/projects/ProjectDetails";
import AddProject from "../../../admin/views/projects/AddProject";
import TenantCustomEntityDetails from "../custom-entities/TenantCustomEntityDetails";
import CreateTenantCustomEntity from "../custom-entities/CreateTenantCustomEntity";
import AddTenantCustomEntityTranslation from "../custom-entities/AddTenantCustomEntityTranslation";
import CreateCF from "../custom-fields/CreateCF";
import CFDetails from "../custom-fields/CFDetails";
import AddCFTranslation from "../custom-fields/AddCFTranslation";

class FirmRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-partner-view">
        <Switch>
          <Route
            path={`/company-admin/company/project/details/:id/:organizationId`}
            render={props => (
              <ProjectDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/company-admin/company/project/add/:organizationId/:partnerId`}
            render={props => (
              <AddProject {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/company-admin/company/project/add/:organizationId`}
            render={props => (
              <AddProject {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/company-admin/company/project/update/:id/:organizationId`}
            render={props => (
              <AddProject {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/company-admin/company/project/module/details/:id/:organizationId`}
            render={props => (
              <TenantCustomEntityDetails
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/company-admin/company/project/module/update/:id`}
            render={props => (
              <CreateTenantCustomEntity
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/company-admin/company/project/module/create/:organizationId`}
            render={props => (
              <CreateTenantCustomEntity
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/company-admin/company/project/module/translation/add/:id`}
            render={props => (
              <AddTenantCustomEntityTranslation
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/company-admin/company/project/module/translation/update/:id/:translationId`}
            render={props => (
              <AddTenantCustomEntityTranslation
                {...props}
                language={this.context.language}
              />
            )}
          />

          <Route
            path={`/company-admin/company/project/template/update/:organizationId/:id`}
            render={props => (
              <CreateWizardTemplate
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/company-admin/company/project/template/create/:organizationId`}
            render={props => (
              <CreateWizardTemplate
                {...props}
                language={this.context.language}
              />
            )}
          />

          <Route
            path={`/company-admin/company/custom-field/update/:id/:regardingObjectId/:organizationId`}
            render={props => (
              <CreateCF {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/company-admin/company/custom-field/create/:regardingObjectId/:organizationId`}
            render={props => (
              <CreateCF {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/company-admin/company/custom-field/details/:id/:regardingObjectId/:organizationId`}
            render={props => (
              <CFDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/company-admin/company/custom-field/translation/add/:id`}
            render={props => <AddCFTranslation {...props} />}
          />
          <Route
            path={`/company-admin/company/custom-field/translation/update/:id/:translationId`}
            render={props => <AddCFTranslation {...props} />}
          />

          <Route
            path={`${this.props.match.path}/ViewFirmPartnerDetails/:id`}
            render={props => (
              <ViewFirmPartnerDetails
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/addFirm`}
            render={props => (
              <AddFirm {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <AddFirm {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <FirmDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/company-admin/company"
            render={props => (
              <Firm {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(FirmRouter);
