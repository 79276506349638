import axios from "axios";
import authService from "../../components/api-authorization/AuthorizeService";
import { Config } from "../../config/Config";

// TASKS API URL
const TASKS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Tasks`;
// TASKS V2 API URL
const TASKS_V2_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/TasksV`;
// Lookup APIs
const LOOKUPS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/LookUps`;

export const createTaskAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${TASKS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

export const createTaskV2API = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${TASKS_V2_API_URL}/CreateTaskVersion2`, data, { headers: headers })
    .then(res => res);
};

// Update Task API
export const updateTaskAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${TASKS_API_URL}/UpdateTask`, data, { headers: headers })
    .then(res => res);
};
// Update Task API
export const updateTaskV2API = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${TASKS_V2_API_URL}/UpdateTask`, data, { headers: headers })
    .then(res => res);
};

// Update Task Status API
export const updateTaskStatusAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${TASKS_API_URL}/UpdateTaskStatus`, data, { headers: headers })
    .then(res => res);
};

// Get Task By ID
export const getTaskByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TASKS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};
// Get Task By ID
export const getTaskByIdV2API = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TASKS_V2_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get All TASKS
export const getTasksAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TASKS_API_URL}/OrganizationTasks`, { headers: headers })
    .then(({ data }) => data);
};

// GetAnnualCycleOfWorkTasks
export const getAnnualCycleOfWorkTasksAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TASKS_API_URL}/GetAnnualCycleOfWorkTasks`, { headers: headers })
    .then(({ data }) => data);
};

// Get All TASKS with pagination
export const getTasksWithPaginationAPI = async (pageNo, limit, filter) => {
  let textFilter =
    filter === undefined || filter === null || filter === "" ? "" : filter;
  console.log("filter", textFilter);
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${TASKS_API_URL}/OrganizationTasks?pageNo=${pageNo}&limit=${limit}&textFilter=${textFilter}`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Delete Task By ID
export const deleteTaskByIdAPI = async (id, isSeries) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .delete(`${TASKS_API_URL}`, {
      data: {
        id: id,
        isSeries: isSeries
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Move to Users
const USERS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Users`;
export const getUsersAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${USERS_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Get Annual Cycle of Work By Year
export const getAnnualCycleOfWorkByYearAPI = async (
  year,
  taskId,
  sectionId
) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${TASKS_API_URL}/OrganizationAnnualTasks?year=${year}&taskId=${taskId}&sectionId=${sectionId}`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Get All Tasks by Year
export const getTasksByYearAPI = async year => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TASKS_API_URL}/AnnualTasks?year=${year}`, { headers: headers })
    .then(({ data }) => data);
};

// Get all RecurrenceType
export const getAllRecurrenceTypeAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${LOOKUPS_API_URL}/GetAllRecurrenceType`, { headers: headers })
    .then(({ data }) => data);
};
