import Axios from "axios";

export async function cvrapi(vat: string) {
  return Axios.get<cvrApiResponse>('https://cvrapi.dk/api?search=' + vat + '&country=dk')
    .then(res => {
      return res.data;
    });
}

export type cvrApiResponseProductionunit = {
  pno: number;
  main: boolean;
  name: string;
  address: string;
  zipcode: number;
  city: string;
  cityname?: any;
  protected: boolean;
  phone?: any;
  email?: any;
  fax?: any;
  startdate: string;
  enddate?: any;
  employees?: any;
  addressco?: any;
  industrycode: number;
  industrydesc: string;
};

export type cvrApiResponse = {
  vat: number;
  name: string;
  address: string;
  zipcode: number;
  city: string;
  cityname?: any;
  protected: boolean;
  phone: number;
  email?: any;
  fax?: any;
  startdate: string;
  enddate?: any;
  employees?: any;
  addressco?: any;
  industrycode: number;
  industrydesc: string;
  companycode: number;
  companydesc: string;
  creditstartdate?: any;
  creditbankrupt: boolean;
  creditstatus?: any;
  owners?: any;
  productionunits: cvrApiResponseProductionunit[];
  t: number;
  version: number;
};
