import { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import CreateTask from "./CreateTask";
import CreateTaskV2 from "./CreatTaskV2";
import TaskDetails from "./TaskDetails";
import Tasks from "./Tasks";

class TasksRouter extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="parent-tasks-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/create/:id?`}
            render={props => (
              <CreateTaskV2 {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/createV2/:id?`}
            render={props => (
              <CreateTaskV2 {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreateTaskV2 {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <TaskDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/tasks"
            render={props => (
              <Tasks {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(TasksRouter);