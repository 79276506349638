import { faBooks } from "@fortawesome/pro-solid-svg-icons";
import { NavMenuLink } from "../NavMenuLink";

export function RevisormappenNavigation(menuItemClick: () => void) {
  return (
    <>
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faBooks}
        path='/'
        translationKey='Revisormappen'
      />
    </>
  );
  }
  