import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Button, Col, Modal, ModalBody,
    ModalFooter, ModalHeader, Row
} from "reactstrap";
import TranslatableText from "../../Language";


export function RouterPrompt(props: { when: any; onOK: any; onLeaveSave: any; onLeaveNotSave: any; onCancel: any; title: any; okText: any; cancelText: any; }) {
    const { when, onOK, onLeaveSave, onLeaveNotSave, onCancel, title, okText, cancelText } = props;

    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        if (when) {
            history.block((prompt) => {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return "true";
            });
        } else {
            history.block(() => { });
        }

        return () => {
            history.block(() => { });
        };
    }, [history, when]);

    const handleOK = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onOK());
            if (canRoute) {
                history.block(() => { });
                history.push(currentPath);
            }
        }
    }, [currentPath, history, onOK]);
    const handleLeaveSave = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onLeaveSave());
            if (canRoute) {
                history.block(() => { });
                history.push(currentPath);
            }
        }
    }, [currentPath, history, onOK]);

    const handleLeaveNotSave = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onLeaveNotSave());
            if (canRoute) {
                history.block(() => { });
                history.push(currentPath);
            }
        }
    }, [currentPath, history, onOK]);

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => { });
                history.push(currentPath);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    return showPrompt ? (
        <div>
            <Modal
                isOpen={showPrompt}
                toggle={handleCancel}
            >
                <ModalHeader
                    toggle={handleCancel}
                >
                    <TranslatableText translationKey="prompt.leaving_page" />
                </ModalHeader>
                <ModalBody>
                    <Row form>
                        <Col md={12}>
                            <p>
                                <TranslatableText translationKey={title} />
                            </p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        outline
                        onClick={handleLeaveSave}
                    >
                        <TranslatableText translationKey="prompt.save_before_leaving" />
                    </Button>
                    <Button
                        color="warning"
                        outline
                        onClick={handleLeaveNotSave}
                    >
                        <TranslatableText translationKey="prompt.leave_without_saving" />
                    </Button>
                    <Button onClick={handleCancel}>
                        <TranslatableText translationKey="prompt.go_back" />
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    ) : null;
}