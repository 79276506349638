import React from "react";
import { useParams } from "react-router-dom";
import ProcessDetails from "../../components/process-details";

type ProcessesDetailsParams = {
  id: string
}

type ProcessesDetailsProps = {

}

const ProcessesDetails: React.FC<ProcessesDetailsProps> = () => {
  const { id } = useParams<ProcessesDetailsParams>();

  return (
    <ProcessDetails
      paramsId={id}
    />
  )

}

export default React.memo(ProcessesDetails);
