import React from "react";
import { Switch, Route } from "react-router-dom";
import CustomEntities from "./CustomEntities";
import CustomEntityDetails from "./CustomEntityDetails";
import CreateCustomEntity from "./CreateCustomEntity";
import { withRouter } from "react-router-dom";
import AddCustomEntityTranslation from "./AddCustomEntityTranslation";

function CustomEntitiesRouter(props) {
  return (
    <div className="parent-custom-fields-view">
      <Switch>
        <Route
          path={`${props.match.path}/translation/add/:id`}
          render={props => <AddCustomEntityTranslation {...props} />}
        />
        <Route
          path={`${props.match.path}/translation/update/:id/:translationId`}
          render={props => <AddCustomEntityTranslation {...props} />}
        />
        <Route
          path={`${props.match.path}/create/:organizationId`}
          render={props => <CreateCustomEntity {...props} />}
        />
        <Route
          path={`${props.match.path}/create`}
          render={props => <CreateCustomEntity {...props} />}
        />
        <Route
          path={`${props.match.path}/update/:id`}
          render={props => <CreateCustomEntity {...props} />}
        />
        <Route
          path={`${props.match.path}/details/:id/:organizationId`}
          render={props => <CustomEntityDetails {...props} />}
        />
        <Route
          path={`${props.match.path}/details/:id`}
          render={props => <CustomEntityDetails {...props} />}
        />
        <Route
          path="/admin/custom-entities"
          render={props => <CustomEntities {...props} />}
        />
      </Switch>
    </div>
  );
}

export default withRouter(CustomEntitiesRouter);