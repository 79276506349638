import React from "react";
import "./Tile.scss";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import TranslatableText from "../../../../Language";

type RealtorTileProps = {
  backgroundColor: string,
  textColor: string,
  className: string,
  title: string,
  subTitle: string,
  icon: string,
  iconSize: string,
  linkTo: string,
  disabled: boolean
}

export default function realtorTile(props: RealtorTileProps) {
  const tileStyle = {
    backgroundColor: props.backgroundColor,
    color: props.textColor
  };
  return (
      <div className={classnames("mb-3 lawTile", props.className)} style={tileStyle}>
    <NavLink
      tag={Link}
      className={classnames(props.disabled ? "disabled" : "")}
      to={props.disabled ? "#" :props.linkTo}
    >
        <h4>
          <TranslatableText translationKey={props.title} />
        </h4>
        <p>{props.subTitle}</p>
        <div className="flex-container center">
          <FontAwesomeIcon 
            // @ts-ignore
            icon={props.icon} size={props.iconSize} />
        </div>
    </NavLink>
        {/* <div className="d-flex justify-content-between">
          <NavLink
            tag={Link}
            className={"center pb-0"}
            to={props.disabled ? "#" : props.linkTo}
          >
            <FontAwesomeIcon icon="eye" />
            <p className={"mb-0"}><TranslatableText translationKey={"view"} /></p>
          </NavLink>
          <NavLink
            tag={Link}
            className={"center pb-0"}
            to={props.disabled ? "#" : props.linkTo + "?mode=lock"}
          >
            <FontAwesomeIcon icon="pen-alt" />
            <p className={"mb-0"}><TranslatableText translationKey={"edit"} /></p>
          </NavLink>
          <NavLink
            tag={Link}
            className={"center pb-0"}
            // to={props.disabled ? "#" : props.linkTo}
            to={"#"}
          >
            <FontAwesomeIcon icon="rotate-backward" />
            <p className={"mb-0"}><TranslatableText translationKey={"reset"} /></p>
          </NavLink>
        </div> */}
      </div>
  );
}
