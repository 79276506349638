import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import CreateTeam from "./CreateTeam";
import TeamDetails from "./TeamDetails";
import Teams from "./Teams";

class TeamsRouter extends Component {
  render() {
    return (
      <div className="admin-wizard-view">
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}/update/:id`}
            render={props => <CreateTeam {...props} />}
          />
          <Route
            path={`${this.props.match.path}/create`}
            render={props => <CreateTeam {...props} />}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => <TeamDetails {...props} />}
          />
          <Route
            path="/company-admin/teams"
            render={props => <Teams {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(TeamsRouter);