import { faBook, faClipboard, faFileAlt, faNotEqual, faPenNib, faTachometerAlt } from "@fortawesome/pro-solid-svg-icons";
import { AccountLevel } from "../../../../config/Levels";
import { usePlatformUserData } from "../../../../contexts";
import { NavMenuLink } from "../NavMenuLink";

export function KDSysNavigation(menuItemClick: () => void) {
  const currentOrgData = usePlatformUserData();
  return (
    <>
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faTachometerAlt}
        path='/'
        translationKey='dashboard'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faBook}
        path='/processes'
        translationKey='processes'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faPenNib}
        path='/platform/regnskabsElementer'
        translationKey='Regnskabs Elementer'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faNotEqual}
        path='/entity/fc01788a-fdd4-48ae-e2a9-08d89698d79c'
        translationKey='Differencer'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faClipboard}
        path='/entity/2eca174a-0b51-4aed-bd11-ea219c5c0d16'
        translationKey='Noter'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faFileAlt}
        path='/documents'
        translationKey='documents'
      />
      {(AccountLevel.Developer <= currentOrgData.currentOrgData.accountLevel) && (
        <NavMenuLink
          menuItemClick={menuItemClick}
          icon={faPenNib}
          path='/entity/38f8731a-3a7c-40bf-f168-08d84b420bd9'
          translationKey='RE - Admin'
        />
      )}
      {/* <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faCalendarCheck}
        path='/annual-cycle-of-work'
        translationKey='annualCycleOfWork'
      />
      <NavMenuLink
        menuItemClick={menuItemClick}
        icon={faTasks}
        path='/tasks'
        translationKey='tasks'
      /> */}
    </>
  );
}
