import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  createAnswerOptionSetAPI,
  getAnswerOptionSetByIdAPI,
  updateAnswerOptionSetAPI,
  getAnswerTypesAPI,
  getAnswerDisplayTypesAPI
} from "./answeroptionsetAPI";
import FormValidator from "../../../../../../components/validator/FormValidator";
import "../../../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../../../Language/TranslatableText";

import { toast } from "react-toastify";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../../../components";



class CreateAnswerOptionSet extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "answerTypeId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "answerDisplayTypeId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      answerDisplayTypeDropdown: [],
      searchableDisplayTypeDropdown: [],
      answerTypeDropdown: [],
      name: "",
      answerTypeId: "",
      answerDisplayTypeId: "",
      apiUrl: "",
      titleName: "",
      valueName: "",
      isChoicesByURL: false,
      loading: false,
      cuLoader: false,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    getAnswerDisplayTypesAPI()
      .then(res => {
        this.setState({
          searchableDisplayTypeDropdown: Array.isArray(res) ? res : []
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    getAnswerTypesAPI()
      .then(res => {
        this.setState({ answerTypeDropdown: Array.isArray(res) ? res : [] });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getAnswerOptionSetByIdAPI(this.props.match.params.id)
        .then(res => {
          getAnswerDisplayTypesAPI()
            .then(res => {
              this.setState({
                answerDisplayTypeDropdown: Array.isArray(res) ? res : []
              });
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
            });
          this.setState(
            {
              name: res.data.name,
              answerTypeId: res.data.answerTypeId,
              answerDisplayTypeId: res.data.answerDisplayTypeId,
              apiUrl: res.data.apiUrl,
              titleName: res.data.titleName,
              valueName: res.data.valueName,
              loading: false
            },
            () => {
              if (res.data.apiUrl !== null && res.data.apiUrl !== "") {
                this.setState({ isChoicesByURL: true });
              }
            }
          );
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }
  createAnswerOptionSet = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      ...this.state,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      userId: this.context.user.organizations.userId
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateAnswerOptionSetAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createAnswerOptionSetAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name == "answerTypeId") {
      var answerDisplayTypeArray = [];
      var selected = target.options[target.selectedIndex].text;
      var displayTypeArray = this.state.searchableDisplayTypeDropdown;
      if (selected == "Two Option") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1006;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected == "Option Set") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return (
            displayType.code === 1006 ||
            displayType.code === 1002 ||
            displayType.code === 1013
          );
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected == "Multiselect Option") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1005 || displayType.code === 1003;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected == "Entity Records") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1003 || displayType.code === 1002;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected == "Single Line Text") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return (
            displayType.code === 1001 ||
            displayType.code === 1004 ||
            displayType.code === 1010 ||
            displayType.code === 1011 ||
            displayType.code === 1012
          );
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected == "Yes Or No with Remarks") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1007;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected == "Date User with Remarks") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1008;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected == "Date User with Remarks And Comments") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1014 || displayType.code === 1015;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected == "Expression") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1009;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected == "Choices By URL") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1003 || displayType.code === 1002;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: true
        });
      } else if (selected == "File") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1016;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected === "Accounting") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return (
            displayType.code === 3000 ||
            displayType.code === 3001 ||
            displayType.code === 3002 ||
            displayType.code === 3003 ||
            displayType.code === 3009 ||
            displayType.code === 3010 ||
            displayType.code === 3011 ||
            displayType.code === 3012
          );
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      } else if (selected === "Law") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return (
            displayType.code === 5000 ||
            displayType.code === 5001 ||
            displayType.code === 5002 ||
            displayType.code === 5003
          );
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });

      } else if (selected === "HTML") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return (
            displayType.code === 1020
          );
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          isChoicesByURL: false
        });
      }
      else {
        this.setState({ answerDisplayTypeDropdown: [], isChoicesByURL: false });
      }
    }

    this.setState({
      [name]: value
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-answeroptionset-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" />
            : <TranslatableText translationKey="create" />}{" "}
          <TranslatableText translationKey="answer_option_set" />
        </h3>
        <Form onSubmit={this.createAnswerOptionSet}>
          <Row form>
            <Col md={12}>
              <FormGroup
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  <TranslatableText translationKey="name" />
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.name.message}</span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={validation.answerTypeId.isInvalid ? "has-error" : ""}
              >
                <Label for="answerTypeId">
                  <TranslatableText translationKey="answeroptionset.answer_type" />
                </Label>
                <Input
                  type="select"
                  name="answerTypeId"
                  id="answerTypeId"
                  value={this.state.answerTypeId}
                  onChange={this.handleChange}
                >
                  <option value="">-- Vælg --</option>
                  {this.state.answerTypeDropdown.map((e, key) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                <span className="help-block">
                  {validation.answerTypeId.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={
                  validation.answerDisplayTypeId.isInvalid ? "has-error" : ""
                }
              >
                <Label for="answerDisplayTypeId">
                  <TranslatableText translationKey="custom_fields.display_type" />
                </Label>
                <Input
                  type="select"
                  name="answerDisplayTypeId"
                  id="answerDisplayTypeId"
                  value={this.state.answerDisplayTypeId}
                  onChange={this.handleChange}
                  disabled={
                    this.props.match.params.id !== undefined ? true : null
                  }
                >
                  <option value="">-- Vælg --</option>
                  {this.state.answerDisplayTypeDropdown.map((e, key) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                <span className="help-block">
                  {validation.answerDisplayTypeId.message}
                </span>
              </FormGroup>
            </Col>
          </Row>
          {this.state.isChoicesByURL ? (
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="apiUrl">
                    <TranslatableText translationKey="api_url" />
                  </Label>
                  <Input
                    type="text"
                    name="apiUrl"
                    id="apiUrl"
                    placeholder=""
                    value={this.state.apiUrl}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="titleName">
                    <TranslatableText translationKey="title_name" />
                  </Label>
                  <Input
                    type="text"
                    name="titleName"
                    id="titleName"
                    placeholder=""
                    value={this.state.titleName}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="valueName">
                    <TranslatableText translationKey="value_name" />
                  </Label>
                  <Input
                    type="text"
                    name="valueName"
                    id="valueName"
                    placeholder=""
                    value={this.state.valueName}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row form>
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (

                <Button>
                  {this.props.match.params.id !== undefined
                    ? <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />
                  }
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateAnswerOptionSet);
CreateAnswerOptionSet.contextType = GlobalContext;