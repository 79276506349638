import React, { useEffect, useState } from "react";
import { ScreenLoading } from "../../components/loading";
import { HandleCaught_API_Error_Base, CustomEntityValuesClient, GetCustomEntityValueResponse, ProcessesClient, GetProcessResponse } from "../../apiClients";
import { ResourceTypes } from "../../config/ResourceTypes";
import SurveyWizardNew from "./SurveyWizardNew";
import "survey-react/survey.min.css";
import "./SurveyWizard.scss";

type IProps = {
    match: any,
}

export interface ISurveyIdentifiers {
    wizardId: string,
    entityId: string,
    regardingObjectTypeId: string,
    questionnaire: string
}

export default function REWrapper(props: IProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [surveyIdentifiers, setSurveyIdentifiers] = useState<ISurveyIdentifiers>(
        { wizardId: "", entityId: "", regardingObjectTypeId: "", questionnaire: "" });

    const processClient = new ProcessesClient();
    const customEntityValuesClient = new CustomEntityValuesClient();
    const regardingObjectTypeId = props.match.params.regardingObjectTypeId;
    const regardingObjectId = props.match.params.regardingObjectId;
    useEffect(() => {
        setIsLoading(true);
        if (props.match.params.regardingObjectTypeId === ResourceTypes.process) {
            processClient.processes(props.match.params.regardingObjectId)
                .then(applySurveyIndentifier)
                .catch(HandleCaught_API_Error_Base)
                .finally(() => setIsLoading(false));
        } else {
            customEntityValuesClient.customEntityValuesGet(props.match.params.regardingObjectId)
                .then(applySurveyIndentifier)
                .catch(HandleCaught_API_Error_Base)
                .finally(() => setIsLoading(false));
        }
    }, [regardingObjectTypeId, regardingObjectId]);

    const applySurveyIndentifier = (res: GetCustomEntityValueResponse | GetProcessResponse): void => {
        setSurveyIdentifiers({
            wizardId: res.wizardId!,
            entityId: props.match.params.regardingObjectId,
            regardingObjectTypeId: props.match.params.regardingObjectTypeId,
            questionnaire: ""
        });
    };

    if (isLoading) {
        return (
            <div className="center-align">
                <ScreenLoading />
            </div>
        );
    }

    // @ts-ignore
    return (<SurveyWizardNew surveyIdentifiers={surveyIdentifiers} isOtpAccess={false} />)
}