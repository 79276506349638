import React, { Component } from "react";
import { Row, Col, Button, NavLink } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import {
  getCustomFieldByIdAPI,
  deleteProcessCustomFieldAPI,
  getCustomFieldTranslationsByIdAPI
} from "./customFieldsCompanyAPI";
import { LanguageContext } from "../../../../contexts/language-context";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import Accent from "../../../../components/accent/Accent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faUserNinja } from "@fortawesome/pro-solid-svg-icons";
import TranslatableText from "../../../../Language/TranslatableText";
import { Config } from "../../../../config/Config";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>No data available</span>
  </div>
);

class CFDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      translationTableLoader: false,
      customFields: {},
      customEntitiesTranslationsData: [],
      customEntitiesTranslationColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "displayText",
          text: "Display Text",
          sort: true
        },
        {
          dataField: "validationMessage",
          text: "Validation Message",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          sort: true,
          formatter: (cell, row) => {
            let language = this.searchArray(cell, Config.languages, "value");
            if (language === undefined) {
              language = "dk";
            }
            return language.name;
          }
        },
        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                {this.props.match.url.split("/")[1] === "admin" ? (
                  <>
                    <NavLink
                      tag={Link}
                      to={`/admin/company/custom-field/translation/update/${row.customFieldId}/${row.id}`}
                    >
                      <FontAwesomeIcon icon={faEdit} size="1x" />
                    </NavLink>
                    <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                      <FontAwesomeIcon
                        style={{ color: "#d84a4a" }}
                        icon={faTrash}
                        size="1x"
                      />
                    </NavLink>
                  </>
                ) : (
                  <>
                    <NavLink
                      tag={Link}
                      to={`/company-admin/custom-fields/translation/update/${row.customFieldId}/${row.id}`}
                    >
                      <FontAwesomeIcon icon={faEdit} size="1x" />
                    </NavLink>
                    <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                      <FontAwesomeIcon
                        style={{ color: "#d84a4a" }}
                        icon={faTrash}
                        size="1x"
                      />
                    </NavLink>
                  </>
                )}
              </div>
            );
          }
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getCustomFieldByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            customFields: res.data,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ tableLoader: false });
        });
    }

    this.populateCustomFieldTranslations(this.props.match.params.id);
  }

  populateCustomFieldTranslations = customFieldID => {
    this.setState({
      translationTableLoader: true,
      customEntitiesTranslationsData: []
    });
    getCustomFieldTranslationsByIdAPI(customFieldID)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({
          customEntitiesTranslationsData: res,
          translationTableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ translationTableLoader: false });
      });
  };

  search = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === key) {
        return array[i];
      }
    }
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            processId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      processId: id
    });
  };

  deleteCustomFieldProcess = id => {
    var data = {
      regardingObjectId: id,
      assetId: this.props.match.params.id
    };
    deleteProcessCustomFieldAPI(data).then(res => {
      this.populateProcessesTable(this.props.match.params.id);
      this.setState({
        deleteModal: false,
        processId: null
      });
    });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };
  toggleDataProcessModal = () => {
    this.setState(prevState => ({
      dataProcessModal: !prevState.dataProcessModal
    }));
  };
  toggleActivitiesModal = () => {
    this.setState(prevState => ({
      activitiesModal: !prevState.activitiesModal
    }));
  };

  populateProcessesTable = id => { };

  addProcess = e => {
    e.preventDefault();
    if (
      this.state.process === "" ||
      this.state.process === undefined ||
      this.state.process === null
    ) {
      return false;
    }
    const data = {
      assetId: this.props.match.params.id,
      regardingObjectId: this.state.process
    };
  };

  getProcesses = () => { };

  lastUpdated = date => {
    if (date !== undefined) {
      return new Date(date).toUTCString();
    }
  };
  render() {
    let {
      loading,
      customEntitiesTranslationsData,
      customEntitiesTranslationColumns,
      translationTableLoader
    } = this.state;
    return (
      <div className="admin-partner-details-view">
        {this.state.customFields.dataType === "Many to Many Lookup" ? (
          <></>
        ) : (
          <div className="relative">
            {this.props.match.url.split("/")[1] === "admin" && !loading ? (
              <NavLink
                className="absolute right edit"
                tag={Link}
                to={`/admin/company/custom-field/update/${this.props.match.params.id}/${this.props.match.params.regardingObjectId}/${this.props.match.params.organizationId}`}
              >
                <FontAwesomeIcon icon={faEdit} size="1x" />
              </NavLink>
            ) : (
              <NavLink
                className="absolute right edit"
                tag={Link}
                to={`/company-admin/custom-fields/update/${this.props.match.params.id}/${this.props.match.params.regardingObjectId}/${this.props.user.organizations.organizationId}`}
              >
                <FontAwesomeIcon icon={faEdit} size="1x" />
              </NavLink>
            )}
          </div>
        )}

        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              {loading ? (
                <InlineLoading />
              ) : (
                <span className="capitalize">
                  <TranslatableText translationKey="custom_field" />:{" "}
                  {this.state.customFields.name}
                </span>
              )}
            </Accent>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            {JSON.stringify(this.state.customFields) === "{}" ? (
              <InlineLoading />
            ) : (
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <TranslatableText translationKey="name" />
                    </td>
                    <td>{this.state.customFields.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="type" />
                    </td>
                    <td>{this.state.customFields.dataType}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="custom_fields.display_type" />
                    </td>
                    <td>{this.state.customFields.displayType}</td>
                  </tr>
                  <tr>
                    <td>
                      <TranslatableText translationKey="required" />
                    </td>
                    <td>
                      {this.state.customFields.isRequired === true
                        ? "Yes"
                        : "No"}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Accent useIcon={false}>
              <TranslatableText translationKey="translations" />
            </Accent>
          </Col>
        </Row>

        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={customEntitiesTranslationsData}
            columns={customEntitiesTranslationColumns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        {this.state.entity !== "" &&
                          this.props.match.url.split("/")[1] !== "admin" ? (
                          <NavLink
                            className="button-primary"
                            tag={Link}
                            to={`/company-admin/custom-fields/translation/add/${this.props.match.params.id}`}
                          >
                            <TranslatableText translationKey="create" />
                          </NavLink>
                        ) : this.state.entity !== "" &&
                          this.props.match.url.split("/")[1] === "admin" ? (
                          <NavLink
                            className="button-primary"
                            tag={Link}
                            to={`/admin/company/custom-field/translation/add/${this.props.match.params.id}`}
                          >
                            <TranslatableText translationKey="create" />
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    translationTableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>

        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(CFDetails);
CFDetails.contextType = GlobalContext;
