import { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Attachments from "../attachments/Attachments";
import SurveyWizard from "../wizard/SurveyWizardNew";
import Processes from "./Processes";
import ProcessesDetails from "./ProcessesDetails";

class ProcessesRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-processes-view">
        <Switch>
          <Route
            path={`${this.props.match.path}/attachments/:id`}
            render={() => <Attachments />}
          />
          <Route
            exact
            path={`${this.props.match.path}/wizard/:id/:recordId/:regardingObjectTypeId`}
            render={() => <SurveyWizard />}
          />
          <Route
            path={`${this.props.match.path}/wizard/:id/:recordId/:regardingObjectTypeId/:pageId`}
            render={() => <SurveyWizard />}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={() => <ProcessesDetails />}
          />
          <Route path="/processes" render={() => <Processes />} />
          
        </Switch>
      </div>
    );
  }
}

export default withRouter(ProcessesRouter);