import {
  faFile, faFilePdf, faFileWord
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import Tooltip from "rc-tooltip";
import Tree from "rc-tree";
import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import {
  Button, Col, Form,
  FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Table
} from "reactstrap";
import TranslatableText from "../../../../Language/TranslatableText";
import {
  createFolderAPI,
  deleteAssetAPI, downloadSharePointFileByIdAPI, downloadSharePointRootFolderAPI, getFoldersByIdAPI, getRootFolderItemsAPI, uploadFilesAPI
} from "./sharepointAPI";

import {
  faDownload, faFileUpload, faFolderOpen, faFolderPlus, faHome
} from "@fortawesome/pro-solid-svg-icons";
import Swal from "sweetalert2";
import HandleCaught_API_Error from "../../../../apiClients/api_caughtError";
import { InlineLoading } from "../../../../components";
import Gap from "../../../../components/gap/Gap";
import { GlobalContext } from "../../../../contexts/global-context";
import { getCompanyByIdAPI } from "../company/firmAPI";
import { getAccountRepresentativeListAPI } from "../users/usersAPI";
import "./tree.scss";
import { SharePointClient } from "../../../../apiClients/api_client_sharepoint";
import dayjs from "dayjs";
import localeDa from 'dayjs/locale/da';



function setLeaf(treeData, curKey, level) {
  const loopLeaf = (data, lev) => {
    const l = lev - 1;
    data.forEach(item => {
      if (
        item.key.length > curKey.length
          ? item.key.indexOf(curKey) !== 0
          : curKey.indexOf(item.key) !== 0
      ) {
        return;
      }
      if (item.children) {
        loopLeaf(item.children, l);
      } else if (l < 1) {
        // eslint-disable-next-line no-param-reassign
        item.isLeaf = true;
      }
    });
  };
  loopLeaf(treeData, level + 1);
}

function getNewTreeData(
  treeData,
  curKey,
  child,
  level,
  refresh = false,
  itemUniqueId
) {
  const loop = data => {
    if (level < 1 || curKey.length - 3 > level * 2) return;
    if (level < 1) return;
    data.forEach(item => {
      if (!refresh) {
        if (curKey.indexOf(item.key) === 0) {
          if (item.children) {
            loop(item.children);
          } else {
            // eslint-disable-next-line no-param-reassign
            item.children = child;
          }
        }
      } else {
        if (curKey.indexOf(item.key) === 0) {
          if (item.children && item.UniqueId !== itemUniqueId) {
            loop(item.children);
          } else {
            // eslint-disable-next-line no-param-reassign
            item.children = child;
          }
        }
      }
    });
  };
  loop(treeData);
  setLeaf(treeData, curKey, level);
}

class DynamicTree extends React.Component {
  state = {
    folderName: "",
    files: [],
    treeData: [],
    loading: false,
    checkedKeys: [],
    serverData: [],
    items: [],
    uploadModal: false,
    createModal: false,
    cuLoader: false,
    selectedItem: {},
    accountRepresentativeList: [],
    loadRepresentativeData: false,
    downloading: false,
    isContext: false,
    isOnSelect: false,
    company: {
      name: "",
      email: "",
      phone: "",
      vat: "",
      address: "",
      logo: ""
    },
    partner: {
      name: "",
      email: "",
      phone: "",
      vat: "",
      address: "",
      logo: ""
    }
  };
  _sharepointClient = new SharePointClient();

  componentDidMount() {
    // Make sure no files are selected
    this.renderRootFolderItems();

    //Get Company Informations
    this.getCompanyInfo();

    //Get Account Representative List
    this.getAccountRepresentativeList();

    // For context menu
    this.getContainer();
  }

  componentWillUnmount() {
    if (this.cmContainer) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
    }
  }

  getCompanyInfo = () => {
    getCompanyByIdAPI(this.context.user.organizations.organizationId)
      .then(res => {
        this.setState({ company: res.data }, () => { });
      })
      .catch(error => {
        HandleCaught_API_Error(error);
        this.setState({ loading: false });
      });
  };

  getAccountRepresentativeList = () => {
    this.setState({ loadRepresentativeData: true });
    getAccountRepresentativeListAPI(
      this.context.user.organizations.companyId
    )
      .then(res => {
        this.setState({
          accountRepresentativeList: res,
          loadRepresentativeData: false
        });
      })
      .catch(error => {
        HandleCaught_API_Error(error);
        this.setState({ loading: false });
      });
  };


  dateFormatter = (time) => {
    return (time.locale(localeDa).format('YYYY DD MMM'));
  };

  parseData = res => {
    let folders = res.Folders;
    let files = res.Files;
    console.log(this.context.user.organizations.system);
    folders.sort(sortTree(this.context.user.organizations.system));
    files.sort(sortTree(this.context.user.organizations.system));
    folders.forEach((element, index) => {
      Object.assign(element, { title: element.Name });
      Object.assign(element, { key: `0-${index}` });
      Object.assign(element, { className: "folder" });
      Object.assign(element, { isLeaf: element.ItemCount === 0 });
    });
    files.forEach((element, index) => {
      // console.log(element.TimeLastModified);
      // var dataModified = this.dateFormatter(dayjs(element.TimeLastModified));
      // Object.assign(element, { title: dataModified +' - ' + element.Name });
      Object.assign(element, { title: element.Name });
      Object.assign(element, { key: `0-${index + 100}` });
      Object.assign(element, { isLeaf: true });
      Object.assign(element, {
        icon: function (element) {
          let ext = element.Name.split(".");
          let extension = ext[ext.length - 1];
          extension = extension.toLowerCase();
          switch (extension) {
            case "pdf":
              return <FontAwesomeIcon icon={faFilePdf} size="1x" />;
            case "docx":
              return <FontAwesomeIcon icon={faFileWord} size="1x" />;
            default:
              return <FontAwesomeIcon icon={faFile} size="1x" />;
          }
        }
      });
    });
    let data = folders.concat(files);
    return data;
  };

  renderRootFolderItems = () => {
    this.setState({ loading: true, items: [] });
    getRootFolderItemsAPI()
      .then(res => {
        let data = this.parseData(res);
        this.setState({
          items: res,
          folders: res.Folders,
          files: res.Files,
          treeData: data,
          loading: false
        });
      })
      .catch(error => {
        HandleCaught_API_Error(error);
        this.setState({ loading: false });
      });
  };

  onSelect = (info, e) => {
    console.log("selected", info, e);
    if (!e.node.selected) {
      this.setState({ selectedItem: e.node, isOnSelect: true });
    } else {
      this.setState({ selectedItem: {}, isOnSelect: false });
    }
  };

  onRightClick = info => {
    if (info.node === undefined) {
      return;
    }
    console.log("right click", info.node);
    this.setState({ selectedItem: info.node });
    // if (info.node["odata.type"] === "SP.File") {
    this.renderCm(info);
    // }
  };

  renderCm(info) {
    console.log("info---------------", info);
    if (this.toolTip) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      this.toolTip = null;
    }
    this.toolTip = (
      <Tooltip
        trigger="click"
        placement="bottomRight"
        prefixCls="rc-tree-contextmenu"
        defaultVisible
        afterVisibleChange={this.afterVisibleChange}
        overlay={
          <div className="context">
            {info.node["odata.type"] === "SP.File" ? (
              <>
                <div className="context_item">
                  <div
                    className="inner_item"
                    onClick={e => this.downloadFile(true, e)}
                  >
                    <FontAwesomeIcon icon={faDownload} size="1x" />{" "}
                    {this.context.languages["download"]}
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* <div className="context_item">
                  <div
                    className="inner_item"
                    onClick={e => this.toggleCreateModal(true, e)}
                  >
                    <FontAwesomeIcon icon={faFolderPlus} size="1x" />{" "}
                    {this.context.languages["create"]}
                  </div>
                </div>
                  <div className="context_item">
                  <div
                    className="inner_item"
                    onClick={e => this.toggleUploadModal(true, e)}
                  >
                    <FontAwesomeIcon icon={faFileUpload} size="1x" />{" "}
                    {this.context.languages["upload"]}
                  </div>
                </div> */}
              </>
            )}
            {/* <div className="context_item">
              <div
                className="inner_item"
                onClick={e => this.deleteAsset(true, e)}
              >
                <FontAwesomeIcon icon={faTrash} size="1x" />{" "}
                {this.context.languages["delete"]}
              </div>
            </div> */}
          </div>
        }
      >
        <span />
      </Tooltip>
    );

    const container = this.getContainer();
    Object.assign(this.cmContainer.style, {
      position: "absolute",
      left: `${info.event.pageX + 150}px`,
      top: `${info.event.pageY}px`
    });

    ReactDOM.render(this.toolTip, container);
  }

  getContainer() {
    if (!this.cmContainer) {
      this.cmContainer = document.createElement("div");
      document.body.appendChild(this.cmContainer);
    }
    return this.cmContainer;
  }

  afterVisibleChange = visibility => {
    console.log("afterVisibleChange", visibility);
    if (!visibility) {
      this.setState({ selectedItem: {} });
    }
  };

  onCheck = checkedKeys => {
    console.log(checkedKeys);
    this.setState({
      checkedKeys
    });
  };

  onLoadData = (treeNode, refresh = false) => {
    console.log("load data...");
    let data = [];
    return new Promise(resolve => {
      getFoldersByIdAPI(treeNode.UniqueId)
        .then(res => {
          let folders = res.Folders;
          let files = res.Files;
          folders.sort(sortTree());
          files.sort(sortTree());
          const key = treeNode.key;
          folders.forEach((element, i) => {
            Object.assign(element, { title: element.Name });
            Object.assign(element, { key: `${key}-${i}` });
            Object.assign(element, { className: "folder" });
            Object.assign(element, { isLeaf: element.ItemCount === 0 });
          });
          files.forEach((element, i) => {
            Object.assign(element, { title: element.Name });
            Object.assign(element, { key: `${key}-${i + 50}` });
            Object.assign(element, { isLeaf: true });
            Object.assign(element, {
              icon: function (element) {
                let ext = element.Name.split(".");
                let extension = ext[ext.length - 1];
                extension = extension.toLowerCase();

                switch (extension) {
                  case "pdf":
                    return <FontAwesomeIcon icon={faFilePdf} size="1x" />;
                    break;
                  case "docx":
                    return <FontAwesomeIcon icon={faFileWord} size="1x" />;
                    break;
                  default:
                    return <FontAwesomeIcon icon={faFile} size="1x" />;
                }
              }
            });
          });
          data = folders.concat(files);
          const treeData = [...this.state.treeData];
          getNewTreeData(
            treeData,
            treeNode.key,
            data,
            100,
            refresh,
            treeNode.UniqueId
          );
          this.setState({ treeData }, () => {
            resolve();
          });
        })
        .catch(error => {
          console.log("getFoldersByIdAPI", error);
        });
    });
  };

  toggleUploadModal = (isContext = false, e) => {
    this.setState({ isContext });
    if (this.toolTip) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      this.toolTip = null;
    }
    this.setState(
      prevState => ({
        uploadModal: !prevState.uploadModal,
        files: []
      }),
      () => {
        if (!this.state.uploadModal) {
          this.setState({ files: [] });
          if (this.state.isContext && !this.state.isOnSelect) {
            this.setState({ selectedItem: {} });
          }
        }
      }
    );
  };

  toggleCreateModal = (isContext = false, e) => {
    this.setState({ isContext });
    if (this.toolTip) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      this.toolTip = null;
    }
    this.setState(
      prevState => ({
        createModal: !prevState.createModal
      }),
      () => {
        if (!this.state.createModal) {
          this.setState({ folderName: "" });
          if (this.state.isContext && !this.state.isOnSelect) {
            this.setState({ selectedItem: {} });
          }
        }
      }
    );
  };

  uploadFiles = e => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("uniqueId", this.state.selectedItem.UniqueId);
    for (const [key, value] of Object.entries(this.state.files)) {
      formData.append("files", value);
    }
    this.setState({ cuLoader: true });
    uploadFilesAPI(formData)
      .then(res => {
        if (res.length === 0) {
          this.onLoadData(this.state.selectedItem, true);
          this.setState({ cuLoader: false }, () => {
            this.toggleUploadModal(this.state.isContext);
            Swal.fire(
              this.context.languages["swal.Success"],
              this.context.languages["swal.success_file_uploaded"],
              "success"
            );
          });
        } else {
          let message = "";
          res.forEach(element => {
            message += `<p>${element}</p>`;
          });
          Swal.fire("Alert", message, "warning").then(result => {
            if (result.isConfirmed) {
              this.setState({ cuLoader: false }, () => {
                this.onLoadData(this.state.selectedItem, true);
                this.toggleUploadModal(this.state.isContext);
              });
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ cuLoader: false });
      });
  };

  createFolder = e => {
    e.preventDefault();
    const data = {
      folderName: this.state.folderName,
      serverRelativeUrl: this.state.selectedItem.ServerRelativeUrl
    };
    this.setState({ cuLoader: true });
    createFolderAPI(data)
      .then(res => {
        if (res.length === 0) {
          this.onLoadData(this.state.selectedItem, true);
          this.setState({ cuLoader: false }, () => {
            this.toggleCreateModal(this.state.isContext);
            Swal.fire(
              this.context.languages["swal.Success"],
              this.context.languages["swal.success_folder_created"],
              "success"
            );
          });
        } else {
          let message = "";
          res.forEach(element => {
            message += `<p>${element}</p>`;
          });
          Swal.fire("Alert", message, "warning").then(result => {
            if (result.isConfirmed) {
              this.setState({ cuLoader: false }, () => {
                this.toggleCreateModal(this.state.isContext);
              });
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ cuLoader: false });
      });
  };

  deleteAsset = (isContext = false, e) => {
    this.setState({ isContext });
    if (this.toolTip) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      this.toolTip = null;
    }
    Swal.fire({
      title: this.context.languages["swal.are_you_sure"],
      text:
        this.context.languages["swal.are_you_sure.confirm_delete"] +
        ` ${this.state.selectedItem.title}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.context.languages[
        "swal.are_you_sure.confirmButtonText"
      ],
      cancelButtonText: this.context.languages[
        "swal.are_you_sure.cancelButtonText"
      ]
    }).then(result => {
      if (result.value) {
        deleteAssetAPI(this.state.selectedItem.UniqueId).then(res => {
          if (res.error !== undefined) {
            Swal.fire(
              "Error",
              `Couldn't delete ${this.state.selectedItem.title} at the moment, it might be open in your system.`,
              "error"
            );
          } else {
            const treeData = [...this.state.treeData];
            this.deleteFile(
              treeData,
              this.state.selectedItem.key,
              100,
              false,
              this.state.selectedItem.UniqueId
            );
          }
        });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.setState(
          {
            tableLoader: false
          },
          () => {
            if (isContext && !this.state.isOnSelect) {
              this.setState({ selectedItem: {} });
            }
            Swal.fire(
              "Cancelled",
              `${this.state.selectedItem.title} is safe :)`,
              "error"
            );
          }
        );
      }
    });
  };

  deleteFile = (treeData, curKey, level, refresh = false, itemUniqueId) => {
    const loop = data => {
      if (level < 1 || curKey.length - 3 > level * 2) return;
      if (level < 1) return;
      data.forEach(item => {
        if (curKey.indexOf(item.key) === 0) {
          if (item.UniqueId === itemUniqueId) {
            //root level elements
            let children = treeData.filter(element => {
              return element.UniqueId != itemUniqueId;
            });
            this.setState({ treeData: children, selectedItem: {} });
          } else {
            if (item.children) {
              if (
                this.searchArray(itemUniqueId, item.children, "UniqueId") !==
                undefined
              ) {
                let children = item.children.filter(element => {
                  return element.UniqueId != itemUniqueId;
                });
                item.children = children;
                this.setState({ treeData, selectedItem: {} });
              } else {
                loop(item.children);
              }
            } else {
            }
          }
        }
      });
    };
    loop(treeData);
    setLeaf(treeData, curKey, level);
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  viewFile = e => {
    if (
      this.state.selectedItem.isLeaf &&
      this.state.selectedItem.LinkingUri !== undefined &&
      this.state.selectedItem.LinkingUri !== null
    ) {
      this.openInNewTab(this.state.selectedItem.LinkingUri);
    } else if (
      this.state.selectedItem.isLeaf &&
      (this.state.selectedItem.LinkingUri === undefined ||
        this.state.selectedItem.LinkingUri === null)
    ) {
      let baseURL = this.state.selectedItem["odata.id"].split("sites");
      let fileURL = baseURL[0] + this.state.selectedItem.ServerRelativeUrl;
      this.openInNewTab(fileURL);
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.type === "file") {
      this.setState({
        files: target.files
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  downloadFile = (isContext = false, id) => {
    if (this.toolTip) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      this.toolTip = null;
    }
    this.setState({ downloading: true });
    this._sharepointClient.downloadFile(this.state.selectedItem.UniqueId)
      .then(res => {
        this.HandleFileDownload(res);
      })
      .catch(error => {
        HandleCaught_API_Error(error);
      }).finally(() => {
        this.setState({ downloading: false });
        if (isContext && !this.state.isOnSelect) {
          this.setState({ selectedItem: {} });
        }
      });
  };

  downloadRootFolder = (isContext = false, id) => {
    if (this.toolTip) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      this.toolTip = null;
    }
    this.setState({ downloading: true });
    this._sharepointClient.downloadRootFolder()
      .then(res => {
        this.HandleFileDownload(res);
      })
      .catch(error => {
        HandleCaught_API_Error(error);
      }).finally(() => {
        this.setState({ downloading: false });
        if (isContext && !this.state.isOnSelect) {
          this.setState({ selectedItem: {} });
        }
      });
  };
  HandleFileDownload(res) {
    const url = window.URL.createObjectURL(
      new Blob([res.data], { type: res.headers["content-type"] })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", res.fileName ?? 'downloadedFile'); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  openInNewTab = url => {
    var win = window.open(url, "_blank");
    win.focus();
  };


  switcherIcon = obj => {
    if (obj.isLeaf) {
      return <FontAwesomeIcon icon={faFolderOpen} size="1x" />
    }
    return <FontAwesomeIcon icon={faFolderPlus} size="1x" />
  };

  render() {
    const {
      treeData,
      loading,
      selectedItem,
      accountRepresentativeList,
      loadRepresentativeData
    } = this.state;

    return (
      <>
        {this.props.showCompanyInformation && (
          <Row>
            <Col md={6}>
              <div className="company-section white-bg">
                <h3>
                  <TranslatableText translationKey="company" />{" "}
                  <TranslatableText translationKey="information" />
                </h3>
                {this.state.company.name !== "" ? (
                  <Table>
                    <tbody>
                      <tr>
                        <td className="text-capitalize bold">
                          <TranslatableText translationKey="logo" />
                        </td>
                        <td>
                          <img src={this.state.company.logo} style={{ maxHeight: '100%', maxWidth: '100%' }} />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-capitalize bold">
                          <TranslatableText translationKey="name" />
                        </td>
                        <td>{this.state.company.name}</td>
                      </tr>
                      <tr>
                        <td className="text-capitalize bold">
                          <TranslatableText translationKey="address" />
                        </td>
                        <td>{this.state.company.address}</td>
                      </tr>
                      <tr>
                        <td className="text-capitalize bold">
                          <TranslatableText translationKey="vat" />
                        </td>
                        <td>{this.state.company.vat}</td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <InlineLoading />
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="partner-section white-bg">
                {accountRepresentativeList.length !== 0 &&
                  !loadRepresentativeData ? (
                  <>
                    <h3>
                      <TranslatableText translationKey="contact" />
                    </h3>
                    <div className="flex vertical-table">
                      <div className="flex flex-column table-header">
                        <div className="user-image-cell"></div>
                        <div>
                          <TranslatableText translationKey="name" />
                        </div>
                        <div>
                          <TranslatableText translationKey="email" />
                        </div>
                        <div>
                          <TranslatableText translationKey="phone" />
                        </div>
                      </div>
                      <div className="flex flex-two table-body">
                        {accountRepresentativeList.map(
                          (representative, index) => (
                            <div
                              key={index}
                              className="flex flex-one flex-column table-columns"
                            >
                              <div className="user-image-cell" style={{ padding: "0.1rem 0.75rem" }}>
                                <img
                                  src={representative.profileImage}
                                  height="95"
                                />
                              </div>
                              <div>{representative.name}</div>
                              <div><a href={"mailto:" + representative.email}>{representative.email}</a></div>
                              <div>{representative.phone}</div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </>
                ) : loadRepresentativeData ? (
                  <InlineLoading />
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        )}
        <div className="relative">
          {loading ? (
            <></>
          ) : (
            <h4 className="root-breadcrumb">
              <FontAwesomeIcon icon={faHome} size="1x" />&nbsp;&gt;&nbsp;
              <span className="root-folder-name">{this.state.items.Name}</span>
            </h4>
          )}

          <div className="absolute right action-btns">
            {this.state.downloading ? (
              <InlineLoading />
            ) : (
              <>
                <Tippy placement="top" content={this.context.languages["revisormappen.select_upload_folder"]}>
                  <span>
                    <Button
                      disabled={!CheckIfSelectedIsUploadFolder(selectedItem)}
                      outline={!CheckIfSelectedIsUploadFolder(selectedItem)}
                      color="info"
                      className="action-btn"
                      onClick={e => this.toggleUploadModal(false, e)}
                    >
                      <FontAwesomeIcon icon={faFileUpload} size="1x" />
                      <TranslatableText translationKey="upload" />
                    </Button>
                  </span>
                </Tippy>
                <Tippy placement="top" content={this.context.languages["revisormappen.choose_file_to_download"]}>
                  <span className="download-container">
                    <Button
                      outline={selectedItem["odata.type"] !== "SP.File"}
                      // outline={selectedItem["odata.type"] !== "SP.File" && selectedItem["odata.type"] !== "SP.Folder" }
                      color="info"
                      className="action-btn"
                      onClick={e => this.downloadFile(false, e)}
                      disabled={selectedItem["odata.type"] !== "SP.File"}
                      // disabled={selectedItem["odata.type"] !== "SP.File" && selectedItem["odata.type"] !== "SP.Folder" }
                    >
                      <FontAwesomeIcon icon={faDownload} size="1x" />
                      <TranslatableText translationKey="download" />
                    </Button>
                  </span>
                </Tippy>

                <Button
                  color="info"
                  className="action-btn"
                  onClick={e => this.downloadRootFolder(false, e)}
                >
                  <FontAwesomeIcon icon={faDownload} size="1x" />
                  <TranslatableText translationKey="downloadFolder" />
                </Button>
              </>
            )}
          </div>
          {loading ? (
            <InlineLoading />
          ) : (
            <Tree
              className={'customIcon'}
              onRightClick={this.onRightClick}
              onDoubleClick={this.onRightClick}
              onSelect={this.onSelect}
              onCheck={this.onCheck}
              checkable={false}
              checkedKeys={this.state.checkedKeys}
              loadData={this.onLoadData}
              treeData={treeData}
            // switcherIcon={this.switcherIcon}
            />
          )}

          <div className="modals">
            <Modal
              isOpen={this.state.uploadModal}
              toggle={this.toggleUploadModal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggleUploadModal}>
                {selectedItem.title}
              </ModalHeader>
              <ModalBody>
                <Form onSubmit={this.uploadFiles}>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="files">
                          <TranslatableText translationKey="attachFiles" />
                        </Label>
                        <Input
                          className="attachment-input"
                          type="file"
                          name="files"
                          id="files"
                          onChange={this.handleChange}
                          multiple
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form className="flex flex-end flex-vertical-align">
                    {this.state.cuLoader === false ? (
                      <Button
                        outline
                        className="mr-10"
                        disabled={this.state.files.length === 0 ? true : null}
                      >
                        <TranslatableText translationKey="upload" />
                      </Button>
                    ) : (
                      <InlineLoading />
                    )}

                    <Button
                      outline
                      onClick={e =>
                        this.toggleUploadModal(this.state.isContext, e)
                      }
                    >
                      <TranslatableText translationKey="cancel" />
                    </Button>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.createModal}
              toggle={this.toggleCreateModal}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggleCreateModal}>
                {selectedItem.title}
              </ModalHeader>
              <ModalBody>
                <Form onSubmit={this.createFolder}>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="folderName">
                          <TranslatableText translationKey="sharepoint.folderName" />
                        </Label>
                        <Input
                          type="text"
                          name="folderName"
                          id="folderName"
                          placeholder=""
                          value={this.state.folderName}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form className="flex flex-end flex-vertical-align">
                    {this.state.cuLoader === false ? (
                      <Button
                        outline
                        className="mr-10"
                        disabled={this.state.folderName === "" ? true : null}
                      >
                        <TranslatableText translationKey="create" />
                      </Button>
                    ) : (
                      <InlineLoading />
                    )}

                    <Button
                      outline
                      onClick={e =>
                        this.toggleCreateModal(this.state.isContext, e)
                      }
                    >
                      <TranslatableText translationKey="cancel" />
                    </Button>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </div>
        </div>
        <Gap size="100px" />
      </>
    );
  }
}

export default withRouter(DynamicTree);
DynamicTree.contextType = GlobalContext;

function sortTree(system) {
  return (a, b) => {
    if (a.Name === "Upload") {
      return -1;
    }
    if (b.Name === "Upload") {
      return 1;
    }
    if (system === "Revisormappen") {
      if (!isNaN(a.Name.substr(0, 4)) && !isNaN(b.Name.substr(0, 4))) {
        console.debug("NaN 04", a.Name, b.Name);
        return new Intl.Collator('en-GB', { numeric: true, sensitivity: 'base' }).compare(b.Name, a.Name)
      }
    }
    return new Intl.Collator('en-GB', { numeric: true, sensitivity: 'base' }).compare(a.Name, b.Name)
  };
}

function CheckIfSelectedIsUploadFolder(selectedItem) {
  return selectedItem["odata.type"] === "SP.Folder" && selectedItem["Name"] === "Upload";
}