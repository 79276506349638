import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  createClaimAPI,
  getClaimByIdAPI,
  updateClaimAPI,
  getTemplateRegardingObjectType
} from "./companyClaimsAPI";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import { SyncLoader, PulseLoader } from "react-spinners";
import { toast } from "react-toastify";
import { getSections } from "../sections/companySectionsAPI";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../components";




class CreateCompanyClaim extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
      },
      {
        field: "regardingObjectTypeId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "action",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "scope",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      name: "",
      description: "",
      regardingObjectTypeId: "",
      action: "",
      scope: "",
      sectionId: "",
      regardingObjectTypeIdDropdown: [],
      actionDropdown: [
        { code: 1001, name: "Read" },
        { code: 1002, name: "Write" }
      ],
      scopeDropdown: [
        { value: 1001, name: "Organization" },
        { value: 1002, name: "Section" }
      ],
      sectionDropdown: [],
      loading: false,
      cuLoader: false,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    getSections().then(res => {
      this.setState({ sectionDropdown: Array.isArray(res) ? res : [] });
    });
    getTemplateRegardingObjectType().then(res => {
      this.setState({
        regardingObjectTypeIdDropdown: Array.isArray(res) ? res : []
      });
    });

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getClaimByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.name,
            description: res.data.description,
            regardingObjectTypeId: res.data.regardingObjectTypeId,
            action: res.data.action,
            sectionId: res.data.scope == 1001 ? "" : res.data.sectionId,
            scope: res.data.scope,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }
  createClaim = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      name: this.state.name,
      description: this.state.description,
      regardingObjectTypeId: this.state.regardingObjectTypeId,
      action: this.state.action,
      sectionId: this.state.scope == 1001 ? "" : this.state.sectionId,
      scope: this.state.scope,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : ""
    };

    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateClaimAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                toast.success("Claim updated successfully!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 2000
                });
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createClaimAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                toast.success("Claim created successfully!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 2000
                });
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-wizard-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          {this.props.match.params.id !== undefined ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />} a
          role claim
        </h3>
        <Form onSubmit={this.createClaim}>
          <Row form>
            <Col md={5}>
              <FormGroup
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  {" "}
                  <TranslatableText translationKey="name" />
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.name.message}</span>
              </FormGroup>
            </Col>

            <Col md={5}>
              <FormGroup
                className={
                  validation.regardingObjectTypeId.isInvalid ? "has-error" : ""
                }
              >
                <Label for="regardingObjectTypeId">
                  {" "}
                  <TranslatableText translationKey="entity" />
                </Label>
                <Input
                  type="select"
                  name="regardingObjectTypeId"
                  id="regardingObjectTypeId"
                  value={this.state.regardingObjectTypeId}
                  onChange={this.handleChange}
                >
                  <option value="">-- Vælg --</option>
                  {this.state.regardingObjectTypeIdDropdown.map((e, key) => {
                    return (
                      <option key={key} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                <span className="help-block">
                  {validation.regardingObjectTypeId.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup
                className={validation.action.isInvalid ? "has-error" : ""}
              >
                <Label for="action">
                  <TranslatableText translationKey="action" />
                </Label>
                <div className="stv-radio-buttons-wrapper">
                  {this.state.actionDropdown.map((element, key) => {
                    return (
                      <span key={key}>
                        <input
                          type="radio"
                          className="stv-radio-button"
                          name="action"
                          value={element.code}
                          id={`button${key}`}
                          onChange={this.handleChange}
                          checked={element.code == this.state.action}
                        />
                        <Label for={`button${key}`}>{element.name}</Label>
                      </span>
                    );
                  })}
                </div>
                <span className="help-block">{validation.action.message}</span>
              </FormGroup>
            </Col>
            <Col md={this.state.scope == 1002 ? 6 : 12}>
              <FormGroup
                className={validation.scope.isInvalid ? "has-error" : ""}
              >
                <Label for="scope">
                  <TranslatableText translationKey="scope" />
                </Label>
                <Input
                  type="select"
                  name="scope"
                  id="scope"
                  value={this.state.scope}
                  onChange={this.handleChange}
                >
                  <option value="">-- Vælg --</option>
                  {this.state.scopeDropdown.map((e, key) => {
                    return (
                      <option key={key} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                <span className="help-block">{validation.scope.message}</span>
              </FormGroup>
            </Col>
            <Col className={this.state.scope == 1002 ? "show" : "hide"} md={6}>
              {Array.isArray(this.state.sectionDropdown) ? (
                <FormGroup>
                  <Label for="sectionId">
                    <TranslatableText translationKey="section" />
                  </Label>
                  <Input
                    type="select"
                    name="sectionId"
                    id="sectionId"
                    value={this.state.sectionId}
                    onChange={this.handleChange}
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.sectionDropdown.map((e, key) => {
                      return (
                        <option key={key} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              ) : (
                ""
              )}
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="description">
                  <TranslatableText translationKey="description" />
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>

            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.languageResources.language_resources,
  user: state.organizations,
  roles: state.userRoles,
  loading: state.organizations.loading,
  error: state.organizations.error
});

export default withRouter(CreateCompanyClaim);
CreateCompanyClaim.contextType = GlobalContext;