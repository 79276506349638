import { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { ApprovalsProgress } from "../../apiClients";
import { ApprovalFlowStatus } from "../../config/ApprovalFlowState";
import TranslatableText from "../../Language";
import "./progress.scss";

type ProgressWidgetProps = {
  approvers: ApprovalsProgress[];
  approvalOrder: number;
};

let ProgressWidget = ({ approvers, approvalOrder }: ProgressWidgetProps) => {
  if (approvers.length === 0) {
    return <></>;
  }
  const getOrder = (approvalOrder: number) => {
    switch (approvalOrder) {
      case 1001:
        return "anyOrder"
      case 1002:
        return "inSequence"
      case 1003:
        return "anyOneUser"
      default:
        return ""
    }
  }

  const getStateClass = (approvalState: ApprovalFlowStatus) => {
    switch (approvalState) {
      case 1001:
      case 1005:
        return "submitted"
      case 1002:
      case 2002:
        return "done"
      case 1003:
      case 2003:
        return "rejected"
      case 1004:
      case 2004:
        return "canceled"
      default:
        return ""
    }
  }

  return (
    <Row>
      <Col md={12} className="mb-3 text-center">
        <h3 className="center-align progress-header mb-0">
          <TranslatableText translationKey="approvals.progress" />
        </h3>
        <span className="center-align">
        <TranslatableText translationKey="approvals.flowType" />: <TranslatableText translationKey={getOrder(approvalOrder)} />
        </span>
      </Col>
      <Col md={12}>
        <div id="container">
          <div className="step-wizard" role="navigation">
            <div className="progress">
              <div className="progressbar empty"></div>
              <div id="prog" className="progressbar"></div>
            </div>
            <ul>
              {approvers.map((approver, index) => (
                // Only do this if items have no stable IDs
                <li
                  key={index}
                  className={getStateClass(approver.approvalFlowState)}
                >
                  <button id={`step${index + 1}`}>
                    <div className="step">{approvalOrder !== 1003 && index + 1}</div>
                    <div className="title">{approver.userName}</div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ProgressWidget;
