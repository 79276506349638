import React, { Component } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { getPanelsAPI, deletePanelByIdAPI } from "./panelsAPI";
import TranslatableText from "../../../../../../Language/TranslatableText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faInfoCircle,
  faTrash,
  faHatWizard
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { LanguageContext } from "../../../../../../contexts/language-context";
import Accent from "../../../../../../components/accent/Accent";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../../../contexts/global-context";
import { InlineLoading } from "../../../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);
const defaultSorted = [
  {
    dataField: "order", // if dataField is not match to any column you defined, it will be ignored.
    order: "asc" // desc or asc
  }
];

class Panels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      deleteLoader: false,
      deleteModal: false,
      panels: [],
      panelId: null,
      columns: [
        {
          dataField: "id",
          text: "ID",
          sort: true,
          classes: "recordID"
        },
        {
          dataField: "pageTitle",
          text: "Page Title",
          sort: true
        },
        {
          dataField: "pageOrder",
          text: "Page Order",
          sort: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "condition",
          text: "Condition",
          sort: true
        },
        {
          dataField: "order",
          text: "Order",
          sort: true
        },
        {
          dataField: "pageId",
          text: "Page Id",
          sort: true,
          classes: "recordID"
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ]
    };
  }

  componentDidMount() {
    this.populatePanelsList();
  }

  populatePanelsList = () => {
    var self = this;
    this.setState({ tableLoader: true, panels: [] });
    getPanelsAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink
                tag={Link}
                to={`${self.props.match.path}/details/${el.id}`}
              >
                <Tippy placement="top" content="See details">
                  <span>
                    <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink
                tag={Link}
                to={`${self.props.match.path}/update/${el.id}`}
              >
                <Tippy placement="top" content="Update">
                  <span>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink onClick={e => self.openDeleteModal(el.id, e)}>
                <Tippy placement="top" content="Remove">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
          return o;
        });
        this.setState({
          panels: data,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            panelId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      panelId: id
    });
  };

  deletePanel = id => {
    this.setState({ deleteLoader: true });
    deletePanelByIdAPI(id, this.context.user.organizations.userId)
      .then(res => {
        this.populatePanelsList();
        this.setState({
          deleteModal: false,
          panelId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          panelId: null,
          deleteLoader: false
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { tableLoader } = this.state;
    return (
      <div className="panel-view">
        <Accent useIcon={true} icon={faHatWizard}>
          <TranslatableText translationKey="panels" />
        </Accent>
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={this.state.panels}
            columns={this.state.columns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          CSV
                        </ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`${this.props.match.path}/create`}
                        >
                          <TranslatableText translationKey="create" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              <TranslatableText translationKey="confirm_delete" />?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    {" "}
                    <TranslatableText translationKey="page.confirm_delete_panel_description" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deletePanel(this.state.panelId, e)}
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(Panels);
Panels.contextType = GlobalContext;