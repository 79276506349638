import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// CustomEntitys API URL
export const ORGANIZATION_CUSTOM_ENTITIES_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/RegardingObjectTypes`;

export const createCustomEntityAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${ORGANIZATION_CUSTOM_ENTITIES_API_URL}`, data, {
      headers: headers
    })
    .then(res => res);
};

// Update CustomEntity API
export const updateCustomEntityAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${ORGANIZATION_CUSTOM_ENTITIES_API_URL}`, data, {
      headers: headers
    })
    .then(res => res);
};

// Get CustomEntity By ID
export const getCustomEntityByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get getTenantCustomEntities By OrganizationId API
export const getTenantCustomEntitiesByOrganizationIdAPI = async organizationID => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/${organizationID}/RegardingObjectTypes`,
      { headers: headers }
    )
    .then(({ data }) => data);
};

// Get CustomEntitys of Regarding Object ID
export const getPlatformCustomEntityAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/GetCustomTemplateRegardingObjectType`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Get CustomEntities of an Organization for Menu
export const getAllOrganizationCustomEntitiesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/MenuRegardingObjectTypes`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Delete CustomEntity By ID
export const deleteCustomEntityByIdAPI = async id => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${ORGANIZATION_CUSTOM_ENTITIES_API_URL}`, {
      data: {
        id: id
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Get entities of TaskRegardingObjectTypes
export const getTaskRegardingObjectTypesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/TaskRegardingObjectTypes`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Translations

// Get Custom Entity Translations By ID
export const getCustomEntityTranslationsByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/${id}/RegardingObjectTypeTranslations`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Get Custom Entity Base Translation By ID
export const getCustomEntityBaseTranslationsByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/DefaulfRegardingObjectTypeTranslation/${id}`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Add translation for custom entity
export const addCustomEntityTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/Translations`, data, {
      headers: headers
    })
    .then(res => res);
};

// Update translation for custom entity
export const updateCustomEntityTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/Translations`, data, {
      headers: headers
    })
    .then(res => res);
};

// Get Single Custom Entity Translation By ID
export const getSingleCustomEntityTranslationByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/RegardingObjectTypeTranslation/${id}`,
      {
        headers: headers
      }
    )
    .then(({ data }) => data);
};

// Get Entity Name using Regarding Object Type and Regarding Object ID
export const getEntityRecordNameAPI = async (
  regardingObjectTypeId,
  regardingObjectId
) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${ORGANIZATION_CUSTOM_ENTITIES_API_URL}/${regardingObjectTypeId}/${regardingObjectId}`,
      { headers: headers }
    )
    .then(({ data }) => data);
};
