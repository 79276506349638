import * as React from 'react'
import { GetUserDefaultOrganizationResponse, IGetUserDefaultOrganizationResponse, OrganizationsClient } from '../apiClients'
import { GlobalContext } from './global-context';

type Dispatch = (action: PlatformUserContextAction) => void

type PlatformUserContextType = { organisationData: PlatformUserLoadState; dispatch: Dispatch } | undefined

type PlatformUserLoadState =
  | { status: 'empty' }
  | { status: 'loading' }
  | { status: 'error', error: string }
  | { status: 'orgInvalid', error: string }
  | { status: 'success', data: IGetUserDefaultOrganizationResponse }

type PlatformUserContextAction =
  | { type: 'request' }
  | { type: 'success', results: IGetUserDefaultOrganizationResponse }
  | { type: 'failure', error: string }
  | { type: 'orgInvalid', error: string };

function display(action: PlatformUserContextAction) {
  if (action.type === 'success') {
    console.log(action.results);
  }
}

//////////////////////////////////////////
//////////////// Conext Setup ////////////
//////////////////////////////////////////
export const PlatformUserStateContext = React.createContext<PlatformUserContextType>(undefined);
PlatformUserStateContext.displayName = "PlatformUserStateContext";

function platformUserReducer(user: PlatformUserLoadState, action: PlatformUserContextAction): PlatformUserLoadState {
  switch (action.type) {
    case 'request':
      return { status: 'loading' };
    case 'success':
      return { status: 'success', data: action.results };
    case 'failure':
      return { status: 'error', error: action.error };
    case 'orgInvalid':
      return { status: 'orgInvalid', error: action.error };
  }
}
/**
 * Use this context provider to get a hold of user information in the 360 Platform
 */
const organizationsClient = new OrganizationsClient();
function PlatformUserProvider({ children }: { children?: React.ReactNode }) {
  const [organisationData, dispatch] = React.useReducer(platformUserReducer, { status: 'empty' });
  const { setOrganizationsAction } = React.useContext(GlobalContext);

  React.useEffect(() => {
    dispatch({ type: 'request' });
    organizationsClient.getUserDefaultOrganization()
      .then(res => {
        setOrganizationsAction(res);
        dispatch({ type: 'success', results: res });
      })
      .catch(err => {
        if (err.status === 409) {
          dispatch({ type: 'orgInvalid', error: err.message })
        } else {
          dispatch({ type: 'failure', error: err.message })
        }
      });
    // return () => {}
  }, []);

  const value = { organisationData, dispatch }
  return (
    <PlatformUserStateContext.Provider value={value}>
      {children}
    </PlatformUserStateContext.Provider>
  )
}

//////////////////////////////////////////
//////////////// Consumers ///////////////
//////////////////////////////////////////
/**
 * Use this Class consumer to get a hold of user information in the 360 Platform
 */
function PlatformUserConsumer({ children }: { children: (props: PlatformUserContextType) => React.ReactNode }) {
  return (
    <PlatformUserStateContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error(typeof (PlatformUserConsumer) + ' must be used within a ' + typeof (PlatformUserProvider))
        }
        return children(context)
      }}
    </PlatformUserStateContext.Consumer>
  )
}

/**
 * Use fc consumer to get a hold of user information in the 360 Platform
 */
function usePlatformUser() {
  const context = React.useContext(PlatformUserStateContext)
  if (context === undefined) {
    throw new Error('usePlatformUser must be used within a Provider')
  }
  return context
}
function usePlatformUserData() {
  const context = React.useContext(PlatformUserStateContext)
  if (context === undefined) {
    throw new Error('usePlatformUser must be used within a Provider')
  }
  if (context.organisationData.status === 'success') {
    return { currentOrgData: context.organisationData.data, userDispatch: context.dispatch };
  } else {
    return { currentOrgData: new GetUserDefaultOrganizationResponse(), userDispatch: context.dispatch };
  }
}

export { PlatformUserProvider, PlatformUserConsumer, usePlatformUser, usePlatformUserData }
