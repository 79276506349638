import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import AddCompany from "./AddCompany";
import Company from "./Company";
import CompanyDetails from "./CompanyDetails";
import ViewPartnerDetails from "./ViewPartnerDetails";
import CreateWizardTemplate from "../wizard/CreateWizardTemplate";
import TenantCustomEntityDetails from "../../../company-admin/views/custom-entities/TenantCustomEntityDetails";
import CreateTenantCustomEntity from "../../../company-admin/views/custom-entities/CreateTenantCustomEntity";
import CreateCF from "../../../company-admin/views/custom-fields/CreateCF";
import CFDetails from "../../../company-admin/views/custom-fields/CFDetails";
import AddCFTranslation from "../../../company-admin/views/custom-fields/AddCFTranslation";
import AddTenantCustomEntityTranslation from "../../../company-admin/views/custom-entities/AddTenantCustomEntityTranslation";
import ProjectDetails from "../projects/ProjectDetails";
import AddProject from "../projects/AddProject";
import CreateUser from "../users/CreateUser";

class CompanyRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-partner-view">
        <Switch>
          <Route
            path={`/admin/company/user/update/:id/:organizationId`}
            render={props => (
              <CreateUser {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/admin/company/user/create/:organizationId`}
            render={props => (
              <CreateUser {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/admin/company/project/details/:id/:organizationId`}
            render={props => (
              <ProjectDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/admin/company/project/add/:organizationId/:partnerId`}
            render={props => (
              <AddProject {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/admin/company/project/add/:organizationId`}
            render={props => (
              <AddProject {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/admin/company/project/update/:id/:organizationId`}
            render={props => (
              <AddProject {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/admin/company/project/module/details/:id/:organizationId`}
            render={props => (
              <TenantCustomEntityDetails
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/admin/company/project/module/update/:id`}
            render={props => (
              <CreateTenantCustomEntity
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/admin/company/project/module/create/:organizationId`}
            render={props => (
              <CreateTenantCustomEntity
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/admin/company/project/module/translation/add/:id`}
            render={props => (
              <AddTenantCustomEntityTranslation
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/admin/company/project/module/translation/update/:id/:translationId`}
            render={props => (
              <AddTenantCustomEntityTranslation
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/admin/company/custom-field/update/:id/:regardingObjectId/:organizationId`}
            render={props => (
              <CreateCF {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/admin/company/custom-field/create/:regardingObjectId/:organizationId`}
            render={props => (
              <CreateCF {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/admin/company/custom-field/details/:id/:regardingObjectId/:organizationId`}
            render={props => (
              <CFDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`/admin/company/custom-field/translation/add/:id`}
            render={props => <AddCFTranslation {...props} />}
          />
          <Route
            path={`/admin/company/custom-field/translation/update/:id/:translationId`}
            render={props => <AddCFTranslation {...props} />}
          />

          <Route
            path={`/admin/company/project/template/update/:organizationId/:id`}
            render={props => (
              <CreateWizardTemplate
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/admin/company/project/template/create/:organizationId`}
            render={props => (
              <CreateWizardTemplate
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`/admin/company/addFirm/:partnerId`}
            render={props => (
              <AddCompany {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/viewPartnerDetails/:id`}
            render={props => (
              <ViewPartnerDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/addFirm`}
            render={props => (
              <AddCompany {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <AddCompany {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id/:partnerId`}
            render={props => (
              <CompanyDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <CompanyDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/admin/company"
            render={props => (
              <Company {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(CompanyRouter);