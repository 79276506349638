import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "reactstrap";
import { InlineLoading } from "../../../components";
import TranslatableText from "../../../Language";

let ApprovalButtons = ({ approvalFlowState, rejectFlow, approveFlow, loading }) => {
  return (
    <Row>
      <Col>
        <div className="flex justify-content-center space-between">
          {loading ? (<InlineLoading />) : (
            <>
              <Button
                className={approvalFlowState === (1003 || 2003) ? "orange-bg" : ""}
                color="danger"
                onClick={(e) => rejectFlow(e)}
                disabled={approvalFlowState === (1003 || 2003) || approvalFlowState === (1002 || 2002)}
              >
                <FontAwesomeIcon icon={faTimes} size="1x" className="cursor" />
                <TranslatableText translationKey="reject" />
              </Button>
              &nbsp;
              <Button
                className={approvalFlowState === (1002 || 2002) ? "orange-bg" : ""}
                color="success"
                onClick={e => approveFlow(e)}
                disabled={approvalFlowState === (1003 || 2003) || approvalFlowState === (1002 || 2002)}
              >
                <FontAwesomeIcon icon={faCheck} size="1x" className="cursor" />
                <TranslatableText translationKey="approve" />
              </Button>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ApprovalButtons;
