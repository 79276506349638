import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Wizard API URL
const WIZARD_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Wizards`;

// Get by ID
export const getWizardByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all
export const getWizardsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

/*
 *
 * Report Templates for Company Admin
 *
 */

// Wizard Report Templates API URL
const WIZARD_TEMPLATES_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/CompanyWizardReportTemplates`;

// Post
export const createReportTemplateAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${WIZARD_TEMPLATES_URL}`, data, { headers: headers })
    .then(res => res);
};

// List of Templates against a single Wizard
export const getReportTemplatesOfWizardByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_TEMPLATES_URL}/${id}/WizardReportTemplates`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get single Wizard Report Template By ID
export const getReportTemplateByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_TEMPLATES_URL}/${id}`, { headers: headers })
    .then(({ data }) => data);
};

// Update
export const updateReportTemplateAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${WIZARD_TEMPLATES_URL}`, data, { headers: headers })
    .then(res => res);
};

// Delete By ID
export const deleteReportTemplateByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${WIZARD_TEMPLATES_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Download Report
export const downloadReportByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${WIZARD_TEMPLATES_URL}/DownloadCompanyWizardReportTemplate/${id}`, {
      headers: headers,
      responseType: "blob"
    })
    .then(res => res);
};
