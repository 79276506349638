import { faHandPaper } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { DashboardsClient } from "../../apiClients";
import HandleCaught_API_Error from "../../apiClients/api_caughtError";
import { ScreenLoading } from "../../components/loading";
import TranslatableText from "../../Language";
import DashboardChart from "./DashboardChart";

export default function Dashboard() {
  const DashboardClient = new DashboardsClient();
  const { isLoading, isError, data, error, } = useQuery(['dashboard'], async () => {
    return DashboardClient.getAllSectionsProcesses();
  });
  if (isError) {
    HandleCaught_API_Error(error);
  }

  if (isLoading) {
    return (
      <div className="center-align">
        <ScreenLoading />
      </div>
    );
  } else if (isError) {
    return (
      <div className="center-align pt-4">
        <FontAwesomeIcon icon={faHandPaper} color={'var(--danger)'} size="6x" />
        <h1><TranslatableText translationKey={error instanceof AxiosError && error.status === 403 ? "no_access" : "api_error"} /></h1>
      </div>
    );
  } else {
    return (
      <div className="center-align">
        <DashboardChart data={data} />
      </div>
    );
  }
}
