import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Process API URL
const PROCESSES_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Processes`;

// Process API URL
const SECTION_PROCESSES_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Sections`;

// Reports API URL
const REPORTS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Reports`;

// Report Templates API URL
const REPORT_TEMPLATE_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/ReportTemplates`;

// Process Translations API URL
const ADMIN_PROCESS_TRANSLATION_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Processes/Translations`;

const PROCESS_TRANSLATIONS_API_STRING = "ProcessTranslations";
const PROCESS_TRANSLATION_API_STRING = "ProcessTranslation";

export const createProcessAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${PROCESSES_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update Process API
export const updateProcessAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${PROCESSES_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Process By ID
export const getProcessByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROCESSES_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all Processes
export const getProcessAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROCESSES_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Get all Section Processes
export const getSectionsProcessesAPI = async language => {
  const token = await authService.getAccessToken();
  var headers = {
    Language: language,
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${SECTION_PROCESSES_API_URL}/SectionsProcesses`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteProcessByIdAPI = async id => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${PROCESSES_API_URL}`, {
      data: {
        id: id
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Get all Process associated Assets By ID
export const getProcessAssetsByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROCESSES_API_URL}/${id}/ProcessAssets`, { headers: headers })
    .then(({ data }) => data);
};

// Reports

// Generate Report
export const generateProcessReportAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${REPORTS_API_URL}/CreateUpdateSingleProcessReport`, data, {
      headers: headers
    })
    .then(res => res);
};

// Download Report
export const downloadReportByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${REPORTS_API_URL}/downloadSingleProcessReport/${id}`, {
      headers: headers,
      responseType: "blob"
    })
    .then(res => res);
};

// Company Reports

// Generate
export const generateCompanyReportAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    "content-type": "multipart/form-data",
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${REPORTS_API_URL}/CreateUpdateCompanyReport`, data, {
      headers: headers
    })
    .then(res => res);
};

// Download
export const downloadCompanyReportByIdAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${REPORTS_API_URL}/downloadOrganizationReport`, {
      headers: headers,
      responseType: "blob"
    })
    .then(res => res);
};

// Get Translations against an ID
export const getTranslations = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROCESSES_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Add Translation for a Process
export const addProcessTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${ADMIN_PROCESS_TRANSLATION_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Specific Process Translations
export const getProcessTranslationsAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROCESSES_API_URL}/${id}/${PROCESS_TRANSLATIONS_API_STRING}`, {
      headers: headers
    })
    .then(res => res);
};

// Update Process Translation API
export const updateProcessTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${ADMIN_PROCESS_TRANSLATION_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Single Translation By ID
export const getSingleTranslationAPI = async translationId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${PROCESSES_API_URL}/${PROCESS_TRANSLATION_API_STRING}/${translationId}`,
      { headers: headers }
    )
    .then(({ data }) => data);
};

// Delete By ID
export const deleteCompanyProcessTranslationByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${PROCESSES_API_URL}/DeleteProcessTranslation`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Download JSON spec for process.
export const getJsonSpecForReferenceObj = async (id, typeId, orgId) => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios({
    url: `${REPORT_TEMPLATE_API_URL}/ReportJsonMachine/${id}/${typeId}/${orgId}`, //your url
    method: "GET",
    responseType: "blob", // important
    headers: headers
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "entitySpecification.json"); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};

// Base Translation
export const getProcessBaseTranslationsByIdAPI = async processId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROCESSES_API_URL}/${processId}/ProcessDefaultTranslation`, {
      headers: headers
    })
    .then(({ data }) => data);
};
