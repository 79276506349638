import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import {
  getSectionByIdAPI,
  getSectionTranslationsAPI,
  deleteCompanySectionTranslationByIdAPI,
  translateSectionAPI
} from "./companySectionsAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faGlobe } from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Config } from "../../../../config/Config";
import { toast } from "react-toastify";
import TranslatableText from "../../../../Language/TranslatableText";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>No data available</span>
  </div>
);

class CompanyTranslations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      tableLoader: false,
      deleteModal: false,
      deleteLoader: false,
      sectionId: this.props.match.params.id,
      isDisabled: false,
      translations: [],
      columns: [
        {
          dataField: "nameShort",
          text: "Name Short",
          sort: true
        },
        {
          dataField: "nameLong",
          text: "Name Long",
          sort: true
        },
        {
          dataField: "descriptionShort",
          text: "Description Short",
          sort: true
        },
        {
          dataField: "descriptionLong",
          text: "Description Long",
          sort: true
        },
        {
          dataField: "language",
          text: "Language",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions"
        }
      ]
    };
  }
  componentDidMount() {
    console.log(this.props);
    if (this.props.match.params.id !== undefined) {
      getSectionByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.data.name
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ name: "-", sectionId: this.props.match.params.id });
        });
    }
    this.populateSectionTranslations();
  }

  populateSectionTranslations = () => {
    this.setState({ tableLoader: true, translations: [] });
    getSectionTranslationsAPI(this.props.match.params.id)
      .then(res => {
        if (!Array.isArray(res.data)) {
          console.log("debug: ", res.data);
          return;
        }
        var self = this;
        var data = res.data.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink
                tag={Link}
                to={`/company-admin/sections/translations/update/${self.props.match.params.id}/${el.id}`}
              >
                <FontAwesomeIcon icon={faEdit} size="1x" />
              </NavLink>
              <NavLink onClick={e => self.openDeleteModal(el.id, e)}>
                <FontAwesomeIcon
                  style={{ color: "#d84a4a" }}
                  icon={faTrash}
                  size="1x"
                />
              </NavLink>
            </div>
          );
          return o;
        });
        if (res.data.length == Config.languages.length) {
          this.setState({ isDisabled: true });
        } else {
          this.setState({ isDisabled: false });
        }
        this.setState({ translations: data, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            sectionId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };
  translateSection = id => {
    let data = {
      id: this.state.sectionId
    };
    this.setState({ loading: true });
    translateSectionAPI(data)
      .then(res => {
        this.setState({ loading: false }, () => {
          console.log(res);
          this.populateWizardList();
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      sectionId: id
    });
  };

  deleteSectionTranslation = id => {
    this.setState({ deleteLoader: true });
    deleteCompanySectionTranslationByIdAPI(
      id,
      this.context.user.organizations.userId
    )
      .then(res => {
        this.populateSectionTranslations();
        this.setState({
          deleteModal: false,
          sectionId: null,
          deleteLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          sectionId: null,
          deleteLoader: false
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  render() {
    let { translations, columns, isDisabled, tableLoader } = this.state;
    return (
      <div className="create-partner-view">
        {this.state.name !== "" ? (
          <h1 className="text-center">{this.state.name}</h1>
        ) : (
          <div className="text-center">
            <InlineLoading />
          </div>
        )}
        <Row form>
          <Col md={12}>
            <div className="table-container">
              <ToolkitProvider
                keyField="id"
                data={translations}
                columns={columns}
                search
              >
                {props => (
                  <div>
                    <div className="flex space-between">
                      <div>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div>
                        <NavLink
                          onClick={e =>
                            this.translateSection(this.state.sectionId, e)
                          }
                          className="button-primary text-white"
                          disabled={isDisabled ? true : null}
                        >
                          <FontAwesomeIcon
                            style={{ color: "var(--white)" }}
                            icon={faGlobe}
                            size="1x"
                          />{" "}
                          &nbsp;{" "}
                          <TranslatableText translationKey="sections.translate_to_missing_languages" />
                        </NavLink>
                      </div>
                      <div>
                        <NavLink
                          className="button-primary"
                          tag={Link}
                          to={`/company-admin/sections/translations/add/${this.props.match.params.id}`}
                          disabled={isDisabled ? true : null}
                        >
                          <TranslatableText translationKey="translations.add_new_translation" />
                        </NavLink>
                      </div>
                    </div>
                    <hr />
                    <BootstrapTable
                      noDataIndication={() =>
                        tableLoader ? (
                          <InlineLoading />
                        ) : (
                          <NoDataIndication />
                        )
                      }
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Col>
          <Col className="space-between flex" md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back_to_list" />
            </Button>
          </Col>
        </Row>
        <div>
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              <TranslatableText translationKey="confirm_delete" />?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="answeroptionset.confirm_delete_translation" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e =>
                    this.deleteSectionTranslation(this.state.sectionId, e)
                  }
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanyTranslations);
CompanyTranslations.contextType = GlobalContext;