import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import TranslatableText from "../../Language/TranslatableText";

export function Table_NoDataIndication() {
    return (
        <div className="text-center">
            <span>
                <TranslatableText translationKey="no_data_available" />
            </span>
        </div>
    )
}

export function Table_HeaderFormatter(column: ColumnDescription, colIndex: number, { sortElement, filterElement }: { sortElement; filterElement; }) {
    return (
        <div>
            <TranslatableText translationKey={column.text} /> {sortElement}
            {filterElement}
        </div>
    );
}

export function Table_SetLocalStorage(e: any) {
    localStorage.setItem('ElementsInList', e)
}

export function Table_GetLocalStorage() {
    let size = localStorage.getItem('ElementsInList');
    if (size != undefined) {
        return Number(size);
    } else {
        return 10;
    }
}