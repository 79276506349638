import { faEdit, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { faUsersClass } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { Col, NavLink, Row } from "reactstrap";
import { GetTeamResponse } from "../../../../../apiClients";
import Accent from "../../../../../components/accent/Accent";
import TranslatableText from "../../../../../Language/TranslatableText";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

export default function ProjectTeamsList(projectId: string, teams: GetTeamResponse[]) {
  function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
      <div>
        <TranslatableText translationKey={column.text} /> {sortElement}
        {filterElement}
      </div>
    );
  }
  const tableColumns: Array<ColumnDescription<GetTeamResponse>> =
    [

      {
        dataField: "id",
        text: "ID",
        hidden: true
      },
      {
        dataField: "name",
        text: "name",
        headerFormatter: headerFormatter,
        sort: true
      },
      {
        dataField: "email",
        text: "email",
        headerFormatter: headerFormatter,
        sort: true
      },
      {
        dataField: "description",
        text: "description",
        headerFormatter: headerFormatter,
        sort: true
      },
      {
        dataField: "actions",
        text: "actions",
        csvExport: false,
        formatter: (cell, row) => {
          return (
            <div className="actions">
              <NavLink
                tag={Link}
                to={`/partner/company/teams/details/${row.id}/${projectId}`}
              >
                <Tippy placement="top" content="View Details">
                  <span>
                    <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                  </span>
                </Tippy>
              </NavLink>

              <NavLink
                tag={Link}
                to={`/partner/company/teams/update/${row.id}/${projectId}`}
              >
                <Tippy placement="top" content="Edit">
                  <span>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              {/* <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                <Tippy placement="top" content="Remove">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink> */}
            </div>
          );
        }
      }


      // {
      //   dataField: "id",
      //   text: "ID",
      //   hidden: true
      // },
      // {
      //   dataField: "name", //res[0].systems.map(row => row.name)
      //   text: "name",
      //   headerFormatter: headerFormatter,
      //   sort: true
      // },
      // {
      //   dataField: "phone",
      //   text: "phone",
      //   headerFormatter: headerFormatter,
      //   sort: true
      // },
      // {
      //   dataField: "email",
      //   text: "email",
      //   headerFormatter: headerFormatter,
      //   sort: true
      // },
      // {
      //   dataField: "actions",
      //   text: "actions",
      //   headerFormatter: headerFormatter,
      //   csvExport: false,
      //   formatter: (cell, row) => {
      //     return (
      //       <div className="actions">
      //         <NavLink
      //           tag={Link}
      //           to={`/partner/user/details/${prjectId}/${row.id}`}
      //         >
      //           <Tippy placement="top" content="See details">
      //             <span>
      //               <FontAwesomeIcon icon={faInfoCircle} size="1x" />
      //             </span>
      //           </Tippy>
      //         </NavLink>
      //         <NavLink
      //           tag={Link}
      //           to={`/partner/user/update/${prjectId}/${row.id}`}
      //         >
      //           <Tippy placement="top" content="Edit">
      //             <span>
      //               <FontAwesomeIcon icon={faEdit} size="1x" />
      //             </span>
      //           </Tippy>
      //         </NavLink>
      //       </div>
      //     );
      //   }
      // }
    ];

  return (
    <Row>
      <Col md={12}>
        <Accent useIcon={true} icon={faUsersClass}>
          <TranslatableText translationKey="teams" />
        </Accent>
        <div className="table-container" style={{ 'marginTop': '-50px' }}>
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={teams}
            columns={tableColumns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: ';',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      <ExportCSVButton
                        className="export-btn"
                        {...props.csvProps}
                      >
                        CSV
                      </ExportCSVButton>
                      {/* <div>
                                                <NavLink
                                                    className="button-primary"
                                                    tag={Link}
                                                    to={`/partner/company/user/create/${prjectId}`}
                                                >
                                                    <TranslatableText translationKey="create" />
                                                </NavLink>
                                            </div> */}
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() => <NoDataIndication />}
                  {...props.baseProps}
                  pagination={paginationFactory({})}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </Col>
    </Row>
  );
}