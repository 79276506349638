import React, { useContext, useEffect } from 'react';
import { TabPane } from "reactstrap";
import ToolkitProvider, {
    Search,
    CSVExport
} from "react-bootstrap-table2-toolkit";
import TranslatableText from "../../../Language/TranslatableText";
import BootstrapTable from "react-bootstrap-table-next";
import { GlobalContext } from '../../../contexts/global-context';
import { InlineLoading } from '../../loading';

type TabPaneSettingsTableProps = {
    tabId: any,
    title: "string",
    data: any,
    columns: any,
    tableLoader: any,
    onLoad: any,
}

const TabPaneSettingsTable: React.FC<TabPaneSettingsTableProps> = (props) => {
    const { data, columns, tableLoader, tabId, onLoad, title } = props;
    const { user, userRoles } = useContext(GlobalContext);

    
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;
    const NoDataIndication = () => (
        <div className="text-center">
            <span>
                <TranslatableText translationKey="no_data_available" />
            </span>
        </div>
    );

    useEffect(() => {
        onLoad();
    }, [])

    return (
        <>
            <TabPane tabId={tabId}>
                <div className="table-container">
                    <ToolkitProvider
                        bootstrap4={true}
                        keyField="organizationId"
                        data={data}
                        columns={columns}
                        search
                        exportCSV={{
                            fileName: "data.csv",
                            separator: user.language === 'dk' ? ';' : ',',
                            ignoreHeader: false,
                            noAutoBOM: false
                        }}
                    >
                        {props => (
                            <div>
                                <div className="flex space-between">
                                    <div>
                                        <h4>
                                            {title}
                                        </h4>
                                    </div>
                                    <div>
                                        <div className="flex space-between">
                                            {userRoles.find(r =>
                                                r.includes("Developer")
                                            ) !== undefined ? (
                                                <ExportCSVButton
                                                    className="export-btn"
                                                    {...props.csvProps}
                                                >
                                                    CSV
                                                </ExportCSVButton>
                                            ) : (
                                                <></>
                                            )}
                                            <div>
                                                <SearchBar {...props.searchProps} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <BootstrapTable
                                    noDataIndication={() =>
                                        tableLoader ? (
                                            <InlineLoading />
                                        ) : (
                                            <NoDataIndication />
                                        )
                                    }
                                    {...props.baseProps}
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                </div>
            </TabPane>
        </>
    )
}

export default React.memo(TabPaneSettingsTable);