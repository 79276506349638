import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IProps {
    initialValue: string,
    callback: any,
    disabled: boolean
}

const Editor = (props: IProps) => {
    
    const modules = {
        toolbar: [
            [{ header: "1" }, { header: "2" }, { header: [3, 4, 5] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, "link", "image"],
        ]
    };
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "blockquote",
        "list",
        "bullet",
        "link",
        "image",
    ];

    const handleChange = (e: any) => {
        props.callback(e)
    }

    return (
        <>
            <ReactQuill
                style={{ background: 'white' }}
                theme="snow"
                modules={modules}
                formats={formats}
                placeholder={props.initialValue}
                value={props.initialValue}
                onChange={e => handleChange(e)}
                readOnly={props.disabled}
            />
        </>
    );




};
export default Editor;