import * as d3 from "d3";
import React, { Component } from "react";
// import { data } from "./live";
import * as fontawesome from "@fortawesome/fontawesome-svg-core";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit, faLock, faProjectDiagram,
  faSquare, faTrash, faTrashAlt
} from "@fortawesome/pro-solid-svg-icons";
import extend from "extend";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select2-wrapper/css/select2.css";
import { toast } from "react-toastify";
import {
  Button, Col, Modal, ModalBody,
  ModalFooter, ModalHeader, Row
} from "reactstrap";
import { Actions } from "../../config/Levels";
import { GlobalContext } from "../../contexts/global-context";
import TranslatableText from "../../Language/TranslatableText";
import { triggerApprovalFlowAPI } from "../company-admin/views/approvals-flow/approvalsFlowAPI";
import { deleteProcessByIdAPI } from "../company-admin/views/process/companyProcessAPI";
import "./dashboard.scss";
import { getProcessesByIdAPI } from "./dashboardAPI";
import { fas } from "@fortawesome/pro-solid-svg-icons";

// Global variable Self
var self;

// var data = data;
var sunburstModelJSON = {},
  jsonInit = {},
  jsonActive = {};

// Dimensions of sunburst.
var width = 750;
var height = 600;
var radius = Math.min(width, height) / 2;

const depTransitionTime = 650;
const actTransitionTime = 450;

// Breadcrumb dimensions: width, height, spacing, width of tip/tail.
var b = {
  w: 175,
  h: 30,
  s: 3,
  t: 10
};

// Mapping of step names to colors.
var colors = {
  home: "#3498db",
  product: "#7b615c",
  search: "#de783b",
  account: "#6ab975",
  other: "#a173d1",
  end: "#bbbbbb",
  Area: "#3498db", // Department
  Success: "#0aa753",
  Good: "#0aa753",
  Add: "#eaeaea",
  "N/A": "#3498db",
  Warning: "#f39c12",
  Template: "#3498db",
  Bad: "red",
  No: "#ccc",
  Risk1: "#0aa753",
  Risk2: "var(--yellow)",
  Risk3: "var(--orange)",
  Risk4: "var(--danger)",
  red: "#ccc",
  Gray: "var(--gray-300)",
  Edge: "var(--edge)"
};

var AccessActionLevel = {
  Delete: 1,
  Create: 2,
  Edit: 3,
  Read: 4
};

var rootNode, node, path, vis, partition, arc;

// Total size of all segments; we set this later, after loading the data.
var totalSize = 0;

var bZoomed = false;
var bZooming = false;
var bModal = false;
var bCenter = false;
var objZoomed = {};
var x = d3.scaleLinear().range([0, 2 * Math.PI]);
var y = d3.scaleSqrt().range([0, radius]);
var modalCircle;

fontawesome.library.add(fas);
class DashboardChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      chart: null,
      data: {},
      selectedOption: null,
      organizationId: this.context?.user.organizations.organizationId,
      processesDropdown: [],
      deleteModal: false,
      processId: null
    };
    this.selectRef = React.createRef();
  }
  shortcutSearch() {
    let self = this;
    window.onkeydown = function (e) {
      if (e.altKey && e.keyCode == 83) {
        e.preventDefault();
        self.selectRef?.current.focus()
        return false;
      }
    }
  }
  componentDidMount() {
    this.shortcutSearch();
    self = this;
    getProcessesByIdAPI()
      .then(res => {
        var dropdownOptions = res.map(el => {
          return { label: el.nameShort, value: el.id };
        });
        this.setState({
          processesDropdown: dropdownOptions
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 2000
        });
      })
      .finally(this.initDrawing());
  }

  initDrawing = () => {
    sunburstModelJSON = this.props.data;
    jsonInit = sunburstModelJSON;
    jsonActive = jsonInit;
    // Redraw chart Start
    this.modalExit();
    if (vis !== undefined) {
      var svgs = document.querySelectorAll("#chart > svg");
      for (var i = svgs.length - 1; i >= 0; --i) {
        svgs[i].remove();
      }
      vis.exit().remove();
      vis.selectAll("path").remove();
      vis.selectAll("svg").remove();
      vis.selectAll("image").remove();
    }
    // Redraw chart End
    this.sunburst();
    d3.select("#modal-exit").on("click", this.modalExit);
    d3.select("#modalFill-exit").on("click", this.modalExit);
  }

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            noficationId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      processId: id
    });
  };

  triggerApprovalFlow = process => {
    if (!process.flowEngine) {
      return;
    }
    console.log("triggering...");
    let data = {
      regardingObjectTypeId: process.regardingObjectTypeId,
      regardingObjectId: process.id,
      actionType: Actions.onUserRequest
    };
    triggerApprovalFlowAPI(data)
      .then(res => {
        toast.info("Flow Triggered", {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 2000
        });
        this.setState({ loading: false });
      });
  };

  deleteProcess = id => {
    this.setState({ loading: true });
    deleteProcessByIdAPI(id)
      .then(res => {
        this.setState({
          deleteModal: false,
          processId: null,
          loading: false
        });
        window.location.reload();
        // vis.selectAll("path").remove();
        // this.modalExit();
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 2000
        });
        this.setState({ loading: false });
      });
  };

  handleSelectChange = selectedOption => {
    let self = this;
    this.setState({ selectedOption }, () => {
      if (this.context.user.organizations.systemSettings && this.context.user.organizations.system === "KD-Sys 360") {
        this.props.data.children.map((v1) => {
          v1.children.map((v2) => {
            if (v2.id === selectedOption[0]?.value) {
              self.props.history.push(
                "/processes/wizard/" +
                v2.processingRecordId +
                "/" +
                v2.id +
                "/" +
                v2.regardingObjectTypeId
              );
            }
          })
        })
      } else {
        this.runFilter();
      }
    });
  };

  setStyle = (objId, propertyObject) => {
    var elem = document.getElementById(objId);
    for (var property in propertyObject)
      elem.style[property] = propertyObject[property];
  };

  modalExit = () => {
    d3.select("#modal").attr("class", "deactive");
    d3.select("#modal").attr("class", null);
    d3.select("#modalFill").attr("class", "deactive");
    d3.select("#modalFill").attr("class", null);
    if (modalCircle !== undefined) {
      modalCircle
        .transition()
        .duration(actTransitionTime)
        .attr("r", 159)
        .style("stroke-width", "0px")
        .on("end", d => {
          d3.select(d).remove();
          d3.select("#info-conatiner").attr("class", null);
          bModal = false;
        });
    }
  };

  isTemplateOrNewActivity = d => {
    if (d.parent != null) {
      if (d.parent.data.state !== "N/A" && d.parent.data.state !== "Template") {
        return false;
      }
    }
    return true;
  };

  runFilter = () => {
    rootNode.sum(function (d) {
      return self.rootSum(d);
    });

    vis.selectAll("image").remove();
    vis.selectAll("path").data(partition(rootNode).descendants());
    var n = 0;
    vis
      .selectAll("path")
      .transition()
      .duration(750)
      .attrTween("d", this.arcTweenData)
      .on("start", function () {
        bZooming = true;
        n++;
      })
      .on("end", function () {
        n--;
        if (!n) {
          self.addIcons(true, true);
          bZooming = false;
        }
      });
  };

  rootSum = d => {
    var _tagInput = "";
    if (this.state.selectedOption !== null) {
      _tagInput = this.state.selectedOption.map(row => row.value);
    } else {
      _tagInput = "";
    }

    if (
      d.type == "Process" && _tagInput instanceof Array
        ? _tagInput.length
        : 0 > 0
    ) {
      let parent = this.getParent(d.parentId);
      if (parent.tags) {
        var bFound = true;

        if (_tagInput instanceof Array) {
          _tagInput.forEach(function (value) {
            var pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
            if (pattern.test(value.toLowerCase()) === true) {
              if (parent.tags.indexOf(value.toLowerCase()) == -1) {
                bFound = false;
                return;
              }
            } else {
              if (!parent.name.toLowerCase().includes(value.toLowerCase())) {
                bFound = false;
                return;
              }
            }
          });
        }

        if (bFound) {
          return 1;
        } else {
          return 0;
        }
      }
    }
    return d.size == undefined ? 0 : d.size;
  };

  addIcons = (bDepartment, bActivity) => {
    // add icons

    path
      .append("svg:image")
      .attr("transform", function (d) {
        if (bDepartment && d.data.type == "Department") {
          var x = arc.centroid(d)[0] - 24 / 2;
          var y = arc.centroid(d)[1] - 24 / 2;
          return "translate(" + x + "," + y + ")";
        }
        if (bActivity && d.data.type == "Activity" && d.data.state == "Add") {
          var x = arc.centroid(d)[0] - 16 / 2;
          var y = arc.centroid(d)[1] - 16 / 2;
          return "translate(" + x + "," + y + ")";
        }
        return null;
      })
      .attr("xlink:href", function (d) {
        if (bDepartment && d.data.type == "Department") {
          return (
            "data:image/svg+xml;charset=UTF-8," +
            fontawesome.icon(
              fontawesome.findIconDefinition({ prefix:'fas',
                iconName: d.data.icon
              }),
              {
                styles: { color: "white" }
              }
            ).html[0]
          );
        }
        if (bActivity && d.data.type == "Activity" && d.data.state == "Add") {
          return (
            "data:image/svg+xml;charset=UTF-8," +
            fontawesome.icon(
              fontawesome.findIconDefinition({ prefix:'fas',
                iconName: "plus"
              })
            ).html[0]
          );
        }
        return null;
      })
      .attr("width", function (d) {
        if (bDepartment && d.data.type == "Department") {
          return 24;
        }
        if (bActivity && d.data.type == "Activity" && d.data.state == "Add") {
          return 16;
        }
        return 0;
      })
      .attr("height", function (d) {
        if (bDepartment && d.data.type == "Department") {
          return 24;
        }
        if (bActivity && d.data.type == "Activity" && d.data.state == "Add") {
          return 16;
        }
        return 0;
      })
      .style("opacity", 0)
      .style("pointer-events", "none");

    vis.selectAll("image").transition().duration(200).style("opacity", 1);
  };

  getParent = parentId => {
    var bFound = false;
    var oFound = jsonInit;
    jsonInit.children.forEach(d => {
      if (d.id == parentId) {
        bFound = true;
        oFound = d;
        return;
      }

      d.children.forEach(a => {
        if (a.id == parentId) {
          bFound = true;
          oFound = a;
          return;
        }
        if (a.children !== undefined && a.children !== null) {
          a.children.forEach(p => {
            if (p.id == parentId) {
              bFound = true;
              oFound = p;
              return;
            }
          });
        }

        if (bFound) {
          return;
        }
      });

      if (bFound) {
        return;
      }
    });
    return oFound;
  };

  arcTweenData = (a, i) => {
    // (a.x0s ? a.x0s : 0) -- grab the prev saved x0 or set to 0 (for 1st time through)
    // avoids the stash() and allows the sunburst to grow into being
    var oi = d3.interpolate(
      { x0: a.data.x0s ? a.data.x0s : 0, x1: a.data.x1s ? a.data.x1s : 0 },
      a
    );
    return t => arc(oi(t));
  };

  sunburst = () => {
    vis = d3
      .select("#chart")
      .append("svg:svg")
      .attr("width", width)
      .attr("height", height)
      .append("svg:g")
      .attr("id", "container")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    partition = d3.partition();

    // Calculate the d path for forEach slice.
    arc = d3
      .arc()
      .startAngle(d => {
        return Math.max(0, Math.min(2 * Math.PI, x(d.x0)));
      })
      .endAngle(d => {
        return Math.max(0, Math.min(2 * Math.PI, x(d.x1)));
      })
      .innerRadius(d => {
        return Math.max(0, y(d.y0));
      })
      .outerRadius(d => {
        return Math.max(0, y(d.y1));
      });
    // Basic setup of page elements.
    initializeBreadcrumbTrail();
    drawLegend();
    d3.select("#togglelegend").on("click", toggleLegend);

    createVisualization(jsonActive, true);

    let dpoFaCloudDownload = fontawesome.icon(
      fontawesome.findIconDefinition({ prefix:'fas',
        iconName: "cloud-download-alt"
      })
    ).html[0];
    let dpoFaTrashAlt = iconCreator("trash-alt", "red");
    let dpoFaEdit = iconCreator("edit", "primary");

    function iconCreator(name, type) {
      return fontawesome.icon(
        fontawesome.findIconDefinition({ prefix:'fas', iconName: name }),
        {
          styles: {
            color: "var(--" + type + ")"
          }
        }
      ).html[0];
    }

    function createVisualization(json, bTransition) {
      jsonActive = json;

      // Bounding circle underneath the sunburst, to make it easier to detect
      // when the mouse leaves the parent g.
      vis
        .append("svg:circle")
        .attr("r", radius)
        .style("opacity", 0)
        .on("mouseover", mouseovercenter);

      // Turn the data into a d3 hierarchy and calculate the sums.
      rootNode = d3.hierarchy(json).sum(d => {
        return self.rootSum(d);
      });
      node = rootNode;

      var nodes = partition(rootNode).descendants();
      path = vis
        .data([json])
        .selectAll("path")
        .data(nodes)
        .enter()
        .append("svg:g");

      if (bTransition) {
        path
          .append("svg:path")
          .attr("display", function (d) {
            return d.depth ? null : "none";
          })
          .attr("d", "")
          .attr("fill-rule", "evenodd")
          .style("fill", function (d) {
            return colors[d.data.state];
          })
          .style("opacity", 1)
          .on("click", click)
          .on("mouseover", mouseover);

        setTimeout(() => {
          var n = 0;
          vis
            .selectAll("path")
            .transition()
            .duration(550)
            .attrTween("d", self.arcTweenData)
            .on("start", function () {
              bZooming = true;
              n++;
            })
            .on("end", function () {
              n--;
              if (!n) {
                self.addIcons(true, true);
                bZooming = false;
              }
            });
        }, 25);
      } else {
        path
          .append("svg:path")
          .attr("display", function (d) {
            return d.depth ? null : "none";
          })
          .attr("d", arc)
          .attr("fill-rule", "evenodd")
          .style("fill", function (d) {
            return colors[d.data.state];
            // return colors["Add"];
          })
          .style("opacity", 1)
          .on("click", click)
          .on("mouseover", mouseover);
      }
      // Add the mouseleave handler to the bounding circle.
      d3.select("#container").on("mouseleave", mouseleave);
    }

    function click(d) {
      if (d.data.type == "Department") {
        if (bZoomed) {
          bZoomed = false;
          var n = 0;
          vis.selectAll("image").remove();
          vis.selectAll("path").remove();
          createVisualization(jsonInit, false);
          vis
            .selectAll("path")
            .transition()
            .duration(0)
            .attrTween("d", arcTweenZoom(objZoomed))
            .on("start", function () {
              bZooming = true;
              n++;
            })
            .on("end", function () {
              n--;
              if (!n) {
                var nn = 0;
                vis
                  .selectAll("path")
                  .transition()
                  .duration(depTransitionTime)
                  .attrTween("d", arcTweenZoom(rootNode))
                  .on("start", function () {
                    nn++;
                  })
                  .on("end", function () {
                    nn--;
                    if (!nn) {
                      self.addIcons(true, true);
                      bZooming = false;
                    }
                  });
              }
            });
        } else {
          bZoomed = true;
          objZoomed = d;
          var n = 0;
          vis.selectAll("image").remove();
          vis
            .selectAll("path")
            .transition()
            .duration(depTransitionTime)
            .attrTween("d", arcTweenZoom(d))
            .on("start", function () {
              bZooming = true;
              n++;
            })
            .on("end", function () {
              n--;
              if (!n) {
                x = d3.scaleLinear().range([0, 2 * Math.PI]);
                y = d3.scaleSqrt().range([0, radius]);
                var jsonArea = extend(true, {}, jsonInit);
                for (var i = jsonArea.children.length; i--;) {
                  if (jsonArea.children[i].name != d.data.name)
                    jsonArea.children.splice(i, 1);
                }
                vis.selectAll("path").remove();
                createVisualization(jsonArea, false);
                self.addIcons(false, true);
                bZooming = false;
              }
            });
        }
      } else if (d.data.type == "Activity" && d.data.state == "Add") {
        self.props.history.push(
          "/dashboard/process/create/" + d.parent.data.id
        );
      } else if (
        d.data.type == "Activity" &&
        (d.data.state == "N/A" || d.data.state == "Template")
      ) {
        // clickOnNewOrTemplateActivity(d);
      } else if (d.data.type == "Activity") {
        //IF KD
        if (self.context.user.organizations.system === 'KD-Sys 360') {
          // self.props.history.push(
          //   "/processes/wizard/" +
          //   d.data.processingRecordId +
          //   "/" +
          //   d.data.id +
          //   "/" +
          //   d.data.regardingObjectTypeId
          // );

          //For REWrapper
          self.props.history.push(
            "/wizard/" +
            d.data.regardingObjectTypeId +
            "/" +
            d.data.id
          );
        }



        bModal = true;
        modalCircle = vis
          .append("svg:circle")
          .attr("r", 169)
          .style("fill", "var(--light)")
          .style("stroke", colors[d.data.state])
          .style("stroke-width", "1px");
        modalCircle
          .transition()
          .duration(actTransitionTime)
          .attr("r", radius)
          .style("stroke-width", "2px");

        d3.select("#modal-info").attr("class", "active");
        d3.select("#modal-activity").text(d.data.name);
        d3.select("#modal-department").text(d.ancestors()[1].data.name);
        d3.select("#modal-info-responsible").text("");
        d3.select("#modal-info-description").text("");
        // self.setStyle("modal", { visibiliy: "visible", opacity: "1" });

        // Hide the details:
        d3.select("#departmentLogo").style("visibility", "hidden");
        d3.select("#department").style("visibility", "hidden");
        d3.select("#activity").style("visibility", "hidden");
        d3.select("#description").style("visibility", "hidden");

        // Make popup visible
        d3.select("#modal").attr("class", "active");

        if (d.data.accessLevel <= AccessActionLevel.Edit) {
          d3.select("#modal-edit")
            .html(
              fontawesome.layer(push => {
                push(fontawesome.icon(faEdit));
              }).html[0]
            )
            .on("click", function () {
              self.props.history.push(
                "/processes/wizard/" +
                d.data.processingRecordId +
                "/" +
                d.data.id +
                "/" +
                d.data.regardingObjectTypeId
              );
            });

          d3.select("#modal-remove")
            .html(
              fontawesome.layer(push => {
                push(fontawesome.icon(faTrash));
              }).html[0]
            )
            .on("click", function () {
              if (!d.data.lock) {
                self.openDeleteModal(d.data.id);
              } else {
                toast.error("Resource Locked!", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 4000
                });
              }
            });

          d3.select("#modal-notifications")
            .html(
              fontawesome.layer(push => {
                push(fontawesome.icon(faProjectDiagram));
              }).html[0]
            )
            .on("click", function () {
              self.props.history.push(
                "/company-admin/flow-engine/" +
                d.data.regardingObjectTypeId +
                "/" +
                d.data.id
              );
            });
        } else {
          d3.select("#modal-notifications").html(
            fontawesome.layer(push => {
              push(fontawesome.icon(faProjectDiagram));
              push(
                fontawesome.icon(faSquare, {
                  transform: { size: 8, y: 5.8, x: 4.4 },
                  classes: ["fa-inverse"]
                })
              );
              push(
                fontawesome.icon(faLock, {
                  transform: { size: 7, y: 6, x: 4.5 }
                })
              );
            }).html[0]
          );

          d3.select("#modal-edit").html(
            fontawesome.layer(push => {
              push(fontawesome.icon(faEdit));
              push(
                fontawesome.icon(faSquare, {
                  transform: { size: 8, y: 5.8, x: 4.4 },
                  classes: ["fa-inverse"]
                })
              );
              push(
                fontawesome.icon(faLock, {
                  transform: { size: 7, y: 6, x: 4.5 }
                })
              );
            }).html[0]
          );
          d3.select("#modal-remove").html(
            fontawesome.layer(push => {
              push(fontawesome.icon(faTrashAlt));
              push(
                fontawesome.icon(faSquare, {
                  transform: { size: 8, y: 5.8, x: 4.4 },
                  classes: ["fa-inverse"]
                })
              );
              push(
                fontawesome.icon(faLock, {
                  transform: { size: 7, y: 6, x: 4.5 }
                })
              );
            }).html[0]
          );
          d3.select("#btn-remove-activity").attr("class", "d-none");
        }

        d3.select("#modal-info-span").on("click", function () {
          self.props.history.push("/processes/details/" + d.data.id);
        });

        d3.select("#modal-files-span").on("click", function () {
          self.props.history.push("/processes/attachments/" + d.data.id);
        });

        d3.select("#modal-assets-span").on("click", function () {
          self.props.history.push("/processes/details/" + d.data.id);
        });
        d3.select("#modal-flow-span").on("click", function () {
          console.log("Trigger flow................", d.data);
          self.triggerApprovalFlow(d.data);
        });
      } else if (d.data.type == "Process") {
        if (!self.isTemplateOrNewActivity(d)) {
          switch (d.data.name) {
            case "Databehandler":
            case "Data Processor":
              self.props.history.push("/processes/wizard/" + d.data.parentId);
              break;
            case "Videreanvendelse":
            case "Further processing":
              self.props.history.push("/processes/wizard/" + d.data.parentId);
              break;
            case "Videregivelse":
            case "Overførsel til tredjelande":
            case "Disclosure":
            case "Disclosure to third countries":
              self.props.history.push("/processes/wizard/" + d.data.parentId);
              break;
            case "Konsekvensvurdering":
            case "Risikovurdering":
            case "Risk Assesment":
              self.props.history.push("/processes/wizard/" + d.data.parentId);
              break;
            default:
          }
        } else if (d.parent != null) {
          // clickOnNewOrTemplateActivity(d.parent);
        }
      }
    }

    // When zooming: interpolate the scales.
    function arcTweenZoom(d) {
      var xd = d3.interpolate(x.domain(), [d.x0, d.x1]),
        yd = d3.interpolate(y.domain(), [d.y0, 1]), // [d.y0, 1]
        yr = d3.interpolate(y.range(), [d.y0 ? 170 : 0, radius]);
      return function (d, i) {
        return i
          ? function (t) {
            return arc(d);
          }
          : function (t) {
            x.domain(xd(t));
            y.domain(yd(t)).range(yr(t));
            return arc(d);
          };
      };
    }

    function mouseovercenter() {
      // TODO: check this functionality as this has a bug
      // if (bCenter) {
      //   return;
      // }
      // bCenter = true;
      // mouseleave();
    }

    initializeBreadcrumbTrail();
    drawLegend();

    /* End */

    // Click method

    function mouseover(d) {
      var $ = "";
      if (d === undefined) {
        return;
      }

      bCenter = false;

      if (bZooming || typeof d.data.type === "undefined") return;

      d3.select("#taginput").style("display", "none");
      d3.select("#taginputtitle").style("display", "none");

      if (d.data.type == "Department") {
        d3.select("#department").text(d.data.name).style("visibility", "");

        d3.select("#description")
          .text(d.data.description)
          .style("visibility", "");

        d3.select("#departmentLogo")
          .attr("class", `fa fa-${d.data.icon}`)
          .style("visibility", "");

        d3.select("#activity").text("").style("visibility", "hidden");

        // Needs review before removing

        // $("#center .process")
        //   .text("")
        //   .css("display", "none")
        //   .css("visibility", "hidden");
        // $("#center .description")
        //   .text(d.data.description)
        //   .css("display", "block")
        //   .css("visibility", "");
      } else if (d.data.type == "Activity") {
        d3.select("#department")
          .text(d.ancestors()[1].data.name)
          .style("visibility", "");

        d3.select("#activity").text(d.data.name).style("visibility", "");

        if (d.data.description !== null) {
          d3.select("#description")
            .text(d.data.description.replace(/<[^>]*>?/gm, ""))
            .style("visibility", "");
        } else {
          d3.select("#description").text("").style("visibility", "");
        }

        // Needs review before removing
        // $("#center .process")
        //   .text("")
        //   .css("display", "none")
        //   .css("visibility", "hidden");
      } else if (d.data.type == "Process") {
        d3.select("#department")
          .text(d.ancestors()[2].data.name)
          .style("visibility", "")
          .style("display", "block")
          .style("visibility", "");

        d3.select("#activity")
          .text(d.ancestors()[1].data.name)
          .style("display", "block")
          .style("visibility", "");

        if (d.data.description !== null) {
          d3.select("#description")
            .text(d.data.description)
            .style("display", "block")
            .style("visibility", "");
        }

        // Needs review
        // $("#center .process")
        //   .text(d.data.name)
        //   .css("display", "block")
        //   .css("visibility", "");
      }

      var sequenceArray = d.ancestors().reverse();
      sequenceArray.shift(); // remove root node from the array
      updateBreadcrumbs(sequenceArray);

      // Fade all the segments.
      d3.selectAll("path").style("opacity", 1);

      //Then highlight only those that are an ancestor of the current segment.
      vis
        .selectAll("path")
        .filter(function (node) {
          return sequenceArray.indexOf(node) >= 0;
        })
        .style("opacity", 0.6);
    }

    // Restore everything to full opacity when moving off the visualization.
    function mouseleave(d) {
      if (bZooming || bModal) {
        return;
      }
      d3.select("#taginput").style("display", "block");
      d3.select("#taginputtitle").style("display", "block");
      d3.select("#department").style("visibility", "hidden");
      d3.select("#description").style("visibility", "hidden");
      d3.select("#departmentLogo").style("visibility", "hidden");

      // Hide the breadcrumb trail
      d3.select("#trail").style("visibility", "hidden");

      // Deactivate all segments during transition.
      d3.selectAll("path").on("mouseover", null);

      // Transition forEach segment to full opacity and then reactivate it.
      d3.selectAll("path")
        .transition()
        .duration(0)
        .style("opacity", 1)
        .on("end", function () {
          d3.select(this).on("mouseover", mouseover);
        });

      d3.select("#explanation").style("visibility", "hidden");
      d3.select("#department").style("visibility", "hidden");
      d3.select("#description").style("visibility", "hidden");
      d3.select("#activity").style("visibility", "hidden");
      d3.select("#departmentLogo").style("visibility", "hidden");
    }

    function initializeBreadcrumbTrail() {
      // Add the svg area.
      var trail = d3
        .select("#sequence")
        .append("svg:svg")
        .attr("width", width)
        .attr("height", 50)
        .attr("id", "trail");
    }

    // Generate a string that describes the points of a breadcrumb polygon.
    function breadcrumbPoints(d, i) {
      var points = [];
      if (i == 1) {
        points.push("0,0");
        points.push(b.w + 150 + ",0");
        points.push(b.w + 150 + b.t + "," + b.h / 2);
        points.push(b.w + 150 + "," + b.h);
        points.push("0," + b.h);
        points.push(b.t + "," + b.h / 2);
      } else {
        points.push("0,0");
        points.push(b.w + ",0");
        points.push(b.w + b.t + "," + b.h / 2);
        points.push(b.w + "," + b.h);
        points.push("0," + b.h);
        if (i > 0) {
          // Leftmost breadcrumb; don't include 6th vertex.
          points.push(b.t + "," + b.h / 2);
        }
      }
      return points.join(" ");
    }

    // Update the breadcrumb trail to show the current sequence and percentage.
    function updateBreadcrumbs(nodeArray) {
      // Data join; key function combines name and depth (= position in sequence).
      var trail = d3
        .select("#trail")
        .selectAll("g")
        .data(nodeArray, d => {
          return d.data.name + d.depth;
        });

      // Remove exiting nodes.
      trail.exit().remove();

      // Add breadcrumb and label for entering nodes.
      var entering = trail.enter().append("svg:g");

      entering
        .append("svg:polygon")
        .attr("points", breadcrumbPoints)
        .style("fill", function (d) {
          return colors[d.data.state];
        });

      entering
        .append("svg:text")
        .attr("x", function (d, i) {
          if (i == 1) {
            return (b.w + b.t) / 2 + 75;
          } else {
            return (b.w + b.t) / 2;
          }
        })
        .attr("y", b.h / 2)
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .text(function (d) {
          return sliceActivityTitle(d.data.name);
        })
        .style("fill", function (d) {
          if (d.data.state == "Add" || d.data.state == "No") {
            return "#000000";
          } else {
            return colors[d.data.state];
          }
        });

      // Merge enter and update selections; set position for all nodes.
      entering.merge(trail).attr("transform", function (d, i) {
        if (i > 1) {
          return "translate(" + (i * (b.w + b.s) + 150) + ", 0)";
        } else {
          return "translate(" + i * (b.w + b.s) + ", 0)";
        }
      });

      // Make the breadcrumb trail visible, if it's hidden.
      d3.select("#trail").style("visibility", "");
    }

    function sliceActivityTitle(title) {
      if (title === null) {
        return "";
      }
      return title.length < 40 ? title : title.slice(0, 41) + "...";
    }

    function drawLegend() {
      // Dimensions of legend item: width, height, spacing, radius of rounded rect.
      var li = {
        w: 75,
        h: 30,
        s: 3,
        r: 3
      };

      var legend = d3
        .select("#legend")
        .append("svg:svg")
        .attr("width", li.w)
        .attr("height", d3.keys(colors).length * (li.h + li.s));

      var g = legend
        .selectAll("g")
        .data(d3.entries(colors))
        .enter()
        .append("svg:g")
        .attr("transform", function (d, i) {
          return "translate(0," + i * (li.h + li.s) + ")";
        });

      g.append("svg:rect")
        .attr("rx", li.r)
        .attr("ry", li.r)
        .attr("width", li.w)
        .attr("height", li.h)
        .style("fill", function (d) {
          return d.value;
        });

      g.append("svg:text")
        .attr("x", li.w / 2)
        .attr("y", li.h / 2)
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .text(function (d) {
          return d.key;
        });
    }

    function toggleLegend() {
      var legend = d3.select("#legend");
      if (legend.style("visibility") == "hidden") {
        legend.style("visibility", "");
      } else {
        legend.style("visibility", "hidden");
      }
    }

    // Take a 2-column CSV and transform it into a hierarchical structure suitable
    // for a partition layout. The first column is a sequence of step names, from
    // root to leaf, separated by hyphens. The second column is a count of how
    // often that sequence occurred.
    function buildHierarchy(csv) {
      var root = { name: "root", children: [] };
      for (var i = 0; i < csv.length; i++) {
        var sequence = csv[i][0];
        var size = +csv[i][1];
        if (isNaN(size)) {
          // e.g. if this is a header row
          continue;
        }
        var parts = sequence.split("-");
        var currentNode = root;
        for (var j = 0; j < parts.length; j++) {
          var children = currentNode["children"];
          var nodeName = parts[j];
          var childNode;
          if (j + 1 < parts.length) {
            // Not yet at the end of the sequence; move down the tree.
            var foundChild = false;
            for (var k = 0; k < children.length; k++) {
              if (children[k]["name"] == nodeName) {
                childNode = children[k];
                foundChild = true;
                break;
              }
            }
            // If we don't already have a child node for this branch, create it.
            if (!foundChild) {
              childNode = { name: nodeName, children: [] };
              children.push(childNode);
            }
            currentNode = childNode;
          } else {
            // RforEached the end of the sequence; create a leaf node.
            childNode = { name: nodeName, size: size };
            children.push(childNode);
          }
        }
      }
      return root;
    }
  };
  render() {
    let { selectedOption } = this.state;

    return (

      <div>
        <div id="main">
          <div id="sequence" />
          <div id="chart">
            <div id="info-container">
              <i id="departmentLogo" style={{ visibility: "hidden" }} />
              <div
                id="department"
                className="title"
                style={{ visibility: "hidden" }}
              />
              <div
                id="activity"
                className="title"
                style={{ visibility: "hidden" }}
              />
              <div
                id="process"
                className="title"
                style={{ visibility: "hidden" }}
              />
              <div id="description" style={{ visibility: "hidden" }} />
              <div className="tagSearch">
                <div id="taginputtitle">&nbsp;</div>
                <div id="taginput">
                  <Select
                    ref={this.selectRef}
                    value={selectedOption}
                    closeMenuOnSelect={false}
                    onChange={this.handleSelectChange}
                    isMulti
                    options={this.state.processesDropdown}
                  />
                </div>
              </div>
            </div>
            <div id="modal">
              <div id="modal-exit" className="button exit">
                <span>
                  <i className="fa fa-times-circle"></i>
                </span>
              </div>
              <div id="modal-department"></div>
              <div id="modal-activity"></div>
              <div id="modal-details">
                <div id="modal-info">
                  <span>
                    <span id="modal-info-responsible"></span>
                  </span>
                  <br />
                  <span>
                    <span id="modal-info-description"></span>
                  </span>
                </div>
                <div className="files"></div>
                <div className="assets"></div>
              </div>
              <div
                id="modal-edit"
                className="button edit"
                data-title="Edit"
                data-toggle="tooltip"
              ></div>
              <div
                id="modal-remove"
                className="button remove"
                data-title="Remove"
                data-toggle="tooltip"
              ></div>
              <div className="button info">
                <span
                  id="modal-info-span"
                  data-title="Details"
                  data-toggle="tooltip"
                >
                  <i className="fa fa-fw fa-info-circle"></i>
                </span>
              </div>
              <div className="button files">
                <span
                  id="modal-files-span"
                  data-title="Attachments"
                  data-toggle="tooltip"
                >
                  <i className="fa fa-copy"></i>
                </span>
              </div>
              <div className="button report">
                <span
                  id="modal-report-span"
                  data-title="Reports"
                  data-toggle="tooltip"
                >
                  <i className="fa fa-file-pdf"></i>
                </span>
              </div>
              <div
                id="modal-notifications"
                className="button notifications"
                data-title="Flow"
                data-toggle="tooltip"
              ></div>
              <div className="button flow">
                <span
                  id="modal-flow-span"
                  data-title="Trigger Flow"
                  data-toggle="tooltip"
                >
                  <i className="fa fa-play"></i>
                </span>
              </div>
              {/* <div className="button assets">
                <span
                  id="modal-assets-span"
                  data-title="Assets"
                  data-toggle="tooltip"
                >
                  <i className="fa fa-server"></i>
                </span>
              </div> */}
            </div>
          </div>
        </div>
        <div id="sidebar">
          <input type="checkbox" id="togglelegend" />{" "}
          <TranslatableText translationKey="legend" />
          <br />
          <div id="legend" style={{ visibility: "hidden" }} />
        </div>
        <div className="popup">
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              Confirm Delete?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="confirm_delete_process" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                outline
                className="redOutline"
                onClick={e => this.deleteProcess(this.state.processId, e)}
              >
                <TranslatableText translationKey="delete" />
              </Button>{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardChart);
DashboardChart.contextType = GlobalContext;