import { faBars, faCheckCircle, faFire } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Collapse, Navbar, NavItem, NavLink } from 'reactstrap';
import { ScreenLoading } from '../';
import { usePlatformUserData } from '../../contexts';
import TranslatableText, { LanguageSelector } from '../../Language';
import "./NavMenu.scss";

import { useAuth0 } from '@auth0/auth0-react';

import { GetRegardingObjectTypeResponse, GetUserOrganizationsResponse, OrganizationsClient, RegardingObjectTypesClient } from '../../apiClients';
import { AccountLevel } from '../../config/Levels';
import { SystemLogo } from './components/SystemLogo';
import { NavMenuSettingsDropdown } from './components/NavMenuSettingsDropdown';
import { SystemNavigation } from './components/SystemNavigation';
import { NavMenuModulesDropdown } from './components/NavMenuModulesDropdown';



export default function NavMenu(props: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void }) {
    const history = useHistory();
    const currentOrgData = usePlatformUserData();
    const _OrganizationsClient = new OrganizationsClient();
    const { isOpen, setIsOpen } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [customEntitiesDropDownOpen, setCustomEntitiesDropDownOpen] = useState(false);
    const [regardingObjectTypes, setRegardingObjectTypes] = useState<GetRegardingObjectTypeResponse[]>([]);
    const [userFavoriteOrganizations, setUserFavoriteOrganizations] = useState<GetUserOrganizationsResponse>(new GetUserOrganizationsResponse());
    const [userFavoritePartners, setUserFavoritePartners] = useState<GetUserOrganizationsResponse>(new GetUserOrganizationsResponse());

    const _RegardingObjectTypesClient = new RegardingObjectTypesClient();

    const { user } = useAuth0();

    useEffect(() => {        // Update the document title using the browser API
        document.title = `${currentOrgData.currentOrgData.system}`;
        Promise.all([
            _RegardingObjectTypesClient.menuRegardingObjectTypes().then(res => { setRegardingObjectTypes(res); }),
            _OrganizationsClient.getUserFavoriteOrganizations().then(res => { setUserFavoriteOrganizations(res); }),
            _OrganizationsClient.getUserFavoritePartners().then(res => { setUserFavoritePartners(res); })
        ]).finally(() => { setIsLoading(false) })
    }, []);


    function navigateTo(view: string) {
        menuItemClick();
        history.push(view);
    }

    function menuItemClick() {
        setIsOpen(false);
        setMobileOpen(false);
        setSettingsOpen(false);
        setCustomEntitiesDropDownOpen(false);
    }


    return (
        <>
            {isLoading ? (<ScreenLoading />) : (
                <>
                    {(user!["https://360-advisor.com/otp"] === "true") ? (
                        OTPMenu()
                    ) : (
                        RegularMenu()
                    )}
                </>
            )}
        </>
    )

    function RegularMenu() {
        return <header>
            <Navbar className="navbar-expand-lg navbar-toggleable-lg border-bottom box-shadow" light>
                <SystemLogo systemName={currentOrgData.currentOrgData.system!} partnerId={currentOrgData.currentOrgData.partnerId!} />
                <Button outline color="secondary" className="navbar-btn" onClick={() => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faBars} />
                </Button>
                <Collapse className="d-lg-inline-flex flex-one" isOpen={isOpen} navbar>
                    <ul className="navbar-nav flex-align flex-one flex-grow middle">
                        <SystemNavigation
                            systemName={currentOrgData.currentOrgData.system!}
                            menuItemClick={menuItemClick} />
                        {(regardingObjectTypes.length > 0 && currentOrgData.currentOrgData.systemSettings.enable_modules_menu) && (
                            NavMenuModulesDropdown(customEntitiesDropDownOpen, setCustomEntitiesDropDownOpen, regardingObjectTypes, navigateTo)
                        )}
                    </ul>
                    <ul className="navbar-nav flex-grow end">
                        {currentOrgData.currentOrgData.systemSettings.enable_approvals && (
                            <NavItem>
                                <NavLink
                                    onClick={() => menuItemClick()}
                                    tag={Link}
                                    className="text-light"
                                    to='/approvals'
                                >
                                    <FontAwesomeIcon icon={faCheckCircle} />&nbsp;
                                    <TranslatableText translationKey={'approvals'} />
                                </NavLink>
                            </NavItem>
                        )}
                        {(currentOrgData.currentOrgData.systemSettings.ShowLanguageSelect ||
                            AccountLevel.Developer <= currentOrgData.currentOrgData.accountLevel) && (
                                <LanguageSelector />
                        )}
                        {currentOrgData.currentOrgData.system === 'DPO Advisor' && (
                            <NavItem>
                                <NavLink
                                    onClick={() => menuItemClick()}
                                    tag={Link}
                                    className="text-light"
                                    to="/entity/8c1e9b7b-cbd7-468e-607a-08d8f38dd1eb"
                                >
                                    <FontAwesomeIcon icon={faFire} color="red" />&nbsp;
                                    <TranslatableText translationKey={'Data Breach'} />
                                </NavLink>
                            </NavItem>
                        )}
                        <NavMenuSettingsDropdown
                            settingsOpen={settingsOpen}
                            setSettingsOpen={setSettingsOpen}
                            navigateTo={navigateTo}
                            favoriteOrganizations={userFavoriteOrganizations}
                            favoritePartners={userFavoritePartners} />
                    </ul>
                </Collapse>
            </Navbar>
        </header>;
    }

    function OTPMenu() {
        return <header>
            <Navbar className="navbar-expand-lg navbar-toggleable-lg border-bottom box-shadow" light>
                <SystemLogo systemName={currentOrgData.currentOrgData.system!} partnerId={currentOrgData.currentOrgData.partnerId!} />
                <Button outline color="secondary" className="navbar-btn" onClick={() => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faBars} />
                </Button>
                <Collapse className="d-lg-inline-flex flex-one" isOpen={isOpen} navbar>
                    <ul className="navbar-nav flex-align flex-one flex-grow middle"></ul>
                    <ul className="navbar-nav flex-grow end"></ul>
                    <NavMenuSettingsDropdown
                        settingsOpen={settingsOpen}
                        setSettingsOpen={setSettingsOpen}
                        navigateTo={navigateTo}
                        favoriteOrganizations={userFavoriteOrganizations}
                        favoritePartners={userFavoritePartners} />
                </Collapse>
            </Navbar>
        </header>;
    }
}