import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { getAttachmentByIdAPI } from "./attachmentsAPI";
import TranslatableText from "../../Language/TranslatableText";
import { toast } from "react-toastify";
import { GlobalContext } from "../../contexts/global-context";
import { InlineLoading } from "../../components";

class AttachmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      description: "",
      contentType: "",
      size: 0,
      url: "",
      expiresOn: "2019-09-29T09:38:30.366Z",
      regardingObjectId: "",
      regardingObjectTypeCode: 0,
      organizationId: ""
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      getAttachmentByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            id: res.data.id,
            id: res.data.id,
            name: res.data.name,
            description: res.data.description,
            contentType: res.data.contentType,
            size: res.data.size,
            url: res.data.url,
            expiresOn: res.data.expiresOn,
            regardingObjectId: res.data.regardingObjectId,
            regardingObjectTypeCode: res.data.regardingObjectTypeCode,
            organizationId: res.data.organizationId
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ tableLoader: false });
        });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let { loading } = this.state;
    return (
      <div className="attachments-details-view">
        <h3>Details</h3>
        {this.state.name !== "" ? (
          <Row>
            <Col md={12}>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="name" />
                    </td>
                    <td>{this.state.name}</td>
                  </tr>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="size" />
                    </td>
                    <td>{this.state.size}</td>
                  </tr>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="type" />
                    </td>
                    <td>{this.state.contentType}</td>
                  </tr>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="url" />
                    </td>
                    <td>{this.state.url}</td>
                  </tr>
                  <tr>
                    <td className="bold">
                      <TranslatableText translationKey="description" />
                    </td>
                    <td>{this.state.description}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        ) : (
          <InlineLoading />
        )}

        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(AttachmentDetails);
AttachmentDetails.contextType =  GlobalContext;