import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Company API URL
const COMPANY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Organizations`;

// Create Company API
export const createCompanyAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${COMPANY_API_URL}/Company`, data, { headers: headers })
    .then(res => res);
};

// Update Company API
export const updateCompanyAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${COMPANY_API_URL}/Company`, data, { headers: headers })
    .then(res => res);
};

// Get Company By ID
export const getCompanyByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all Companies
export const getCompaniesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${COMPANY_API_URL}/Companies`, { headers: headers })
    .then(({ data }) => data);
};
