import React from 'react';
import { DaysType } from './CreatTaskV2';
import moment from 'moment';

type RepeatTextProps = {
    days: DaysType[],
    endDate: Date,
    repeatNumber: string,
    recurrenceType: string,
}

const RepeatText: React.FC<RepeatTextProps> = (props) => {
    const { days, endDate, repeatNumber, recurrenceType } = props;
    const recurrenceTypeValue = () => {
        let tempType = ""
        if (recurrenceType === "1001") {
            tempType = repeatNumber === "1" ? "Day" : "Days"
        }
        else if (recurrenceType === "1002") {
            tempType = repeatNumber === "1" ? "Week" : "Weeks"
        }
        else if (recurrenceType === "1004") {
            tempType = repeatNumber === "1" ? "Month" : "Months"
        }
        else if (recurrenceType === "1008") {
            tempType = "Year"
        }
        return tempType;
    }
    let str = `Occurs every ${repeatNumber} ${recurrenceTypeValue()} on, `;
    let isActiveCount = 0;
    days.map((val: DaysType) => {
        if (val.active) {
            isActiveCount = isActiveCount + 1;
        }
    })

    let tempDays: string[] = []
    days.map((val: DaysType) => {
        if (val.active) {
            tempDays.push(val.title)
        }
    })
    let tempStr = `Occurs every ${repeatNumber} ${recurrenceTypeValue()} on, `;
    tempDays.map((val: string) => {
        tempStr += val + ", ";
    })
    str = tempStr;

    return (
        <>
            {recurrenceType === "1009" ?
                <div className='max-width-466'>
                    Occurs Once
                </div>
                :
                <div className='max-width-466'>
                    {str}
                    <span>until </span>
                    <span className="color-cornflowerblue">{moment(endDate).format("MMM DD[,] yyyy ")}</span>
                </div>
            }
        </>
    )
}

export default RepeatText;