import { faAngleDown, faAngleRight, faInfoCircle, faMinusSquare, faPlusSquare } from "@fortawesome/pro-regular-svg-icons";
import { faPencilAlt, faRandom, faToolbox } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { Button, Col, FormGroup, Label, NavLink, Row } from "reactstrap";
import { GetAllOrganizationsResponse } from "../../../../../apiClients";
import Accent from "../../../../../components/accent/Accent";
import SecuredComponent from "../../../../../components/secured-component/SecuredComponent";
import { AccountLevel } from "../../../../../config/Levels";
import TranslatableText from "../../../../../Language/TranslatableText";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
    <div className="text-center">
        <span>
            <TranslatableText translationKey="no_data_available" />
        </span>
    </div>
);

export default function ProjectsList(
    companyId: string,
    partnerId: string,
    changeProject: (organizationId: string) => void,
    companies: GetAllOrganizationsResponse[],
    showBackButton: boolean,
    inActiveProjectsCheckbox: boolean,
    HandleInActiveCheckbox: (checked: boolean) => void) {
    // const [showFiltered, setShowFiltered] = useState(false);

    const expandRow = {
        renderer: (row: GetAllOrganizationsResponse) => (
            <div>
                <p><b><TranslatableText translationKey="email" />:</b>&nbsp;{row.email}</p>
                <p><b><TranslatableText translationKey="address" />:</b>&nbsp;{row.address}</p>
            </div>
        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }: { isAnyExpands: boolean }) => {
            return isAnyExpands ? <FontAwesomeIcon icon={faMinusSquare} /> : <FontAwesomeIcon icon={faPlusSquare} />;
        },
        expandColumnRenderer: ({ expanded }: { expanded: boolean }) => {
            return expanded ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />;
        }
    };

    function headerFormatter(column, colIndex, { sortElement, filterElement }) {
        return (
            <div>
                <TranslatableText translationKey={column.text} /> {sortElement}
                {filterElement}
            </div>
        );
    }
    function logoFormatter(cell: any) {
        if (
            cell !== "" &&
            cell !== null &&
            cell !== undefined &&
            cell.startsWith("data:")
        ) {
            return <img className="image-max-width" src={cell} alt="" />;
        } else {
            return "";
        }
    }

    const tableColumns: Array<ColumnDescription<GetAllOrganizationsResponse>> =
        [
            {
                dataField: "id",
                text: "ID",
                hidden: true,
                searchable: false
            },
            {
                dataField: "name",
                text: "name",
                headerFormatter: headerFormatter,
                sort: true,
                headerStyle: () => {
                    return { width: "35%" };
                }
            },
            {
                dataField: "fiscalYear",
                text: "Regnskabsår",
                headerFormatter: headerFormatter,
                sort: true,
                headerStyle: () => {
                    return { width: "12%" };
                }
            },
            {
                dataField: "vat",
                text: "vat",
                headerFormatter: headerFormatter,
                sort: true,
                headerStyle: () => {
                    return { width: "14%" };
                }
            },
            {
                dataField: "systems",
                text: "Feature",
                sort: true,
                searchable: false,
                headerFormatter: headerFormatter,
                formatter: (cell, row) => {
                    return (row.systems![0]).name!
                },
                headerStyle: () => {
                    return { width: "11%" };
                }
            },
            {
                dataField: "logo",
                text: "Logo",
                searchable: false,
                headerFormatter: headerFormatter,
                formatter: logoFormatter,
                headerStyle: () => {
                    return { width: "18%" };
                }
            },
            {
                dataField: "actions",
                text: "action",
                searchable: false,
                headerFormatter: headerFormatter,
                csvExport: false,
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    return (
                        <div className="actions">
                            <NavLink
                                tag={Link}
                                to={`/partner/company/project/details/${row.id}/${row.companyId}`}
                            >
                                <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                            </NavLink>
                            <Tippy placement="top" content="Rediger">
                                <span>
                                    <NavLink
                                        tag={Link}
                                        to={`/partner/company/project/update/${row.id}/${row.companyId}`}
                                    >
                                        <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                                    </NavLink>
                                </span>
                            </Tippy>
                            <Tippy placement="top" content="Skift til dete projekt">
                                <span>
                                    <NavLink onClick={e => changeProject(row.id)}>
                                        <FontAwesomeIcon icon={faRandom} size="1x" />
                                    </NavLink>
                                </span>
                            </Tippy>
                        </div>
                    );
                }
            }
        ];

    return (<Row>
        <Col md={12}>
            <Accent useIcon={true} icon={faToolbox}>
                <TranslatableText translationKey="projects" />
            </Accent>
            <div className="table-container" style={{ 'marginTop': '-50px' }}>
                <ToolkitProvider
                    keyField="id"
                    bootstrap4
                    data={companies}
                    columns={tableColumns}
                    search
                    exportCSV={{
                        fileName: "custom.csv",
                        separator: ';',
                        ignoreHeader: false,
                        noAutoBOM: false
                    }}
                >
                    {props => (
                        <div>
                            <div className="flex space-between">
                                <div>
                                    <SearchBar {...props.searchProps} />
                                </div>
                                <div>
                                    <div className="flex space-between">
                                        <FormGroup className={`custom-control custom-switch mr-3`}>
                                            <Label for={'inActiveProjects'} className={'custom-control-label'}>
                                                <TranslatableText translationKey={'inActiveProjects'} />
                                            </Label>
                                            <input type='checkbox'
                                                id={'inActiveProjects'}
                                                name={'inActiveProjects'}
                                                className={`custom-control-input`}
                                                checked={inActiveProjectsCheckbox}
                                                onChange={(event) => HandleInActiveCheckbox(event.target.checked)}
                                            />
                                        </FormGroup>
                                        <ExportCSVButton
                                            className="export-btn"
                                            {...props.csvProps}
                                        >
                                            CSV
                                        </ExportCSVButton>
                                        <Button
                                            className="button-primary"
                                            tag={Link}
                                            to={`/partner/company/project/add/${companyId}/${partnerId}`}
                                        >
                                            <TranslatableText translationKey="create" />
                                        </Button>
                                        {companies.some(p => p.systems![0].name === "KD-Sys 360") &&
                                            <Button
                                                className="button-primary ml-2"
                                                tag={Link}
                                                to={`/partner/company/project/newYear/${companyId}/${partnerId}`}
                                            >
                                                <TranslatableText translationKey="kd.createNewYear" />
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <BootstrapTable
                                classes="organization-list"
                                expandRow={expandRow}
                                noDataIndication={() => <NoDataIndication />}
                                // @ts-ignore
                                pagination={showBackButton ? paginationFactory({}) : null}
                                rowClasses={(row, rowIndex) => !row.isActive ? "disabled-row" : ""}
                                {...props.baseProps}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </div>
        </Col>
    </Row>
    );
}