import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import CreateCompanyClaim from "./CreateCompanyClaim";
import CompanyClaimDetails from "./CompanyClaimDetails";
import { withRouter } from "react-router-dom";
import CompanyClaims from "./CompanyClaims";
import Roles from "./Roles";

class CompanyClaimsRouter extends Component {
  render() {
    return (
      <div className="admin-claims-view">
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}/update/:id`}
            render={props => <CreateCompanyClaim {...props} />}
          />
          <Route
            path={`${this.props.match.path}/create`}
            render={props => <CreateCompanyClaim {...props} />}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => <CompanyClaimDetails {...props} />}
          />
          <Route
            path={`${this.props.match.path}/roles`}
            render={props => <Roles {...props} />}
          />
          <Route
            path="/company-admin/claims"
            render={props => <CompanyClaims {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(CompanyClaimsRouter);
