import { faArrowAltCircleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Form, Row } from "reactstrap";
import { InlineLoading, ScreenLoading } from "../../../../components";
import Toggle from "../../../../components/toggle/Toggle";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import { Config } from "../../../../config/Config";
import { GlobalContext } from "../../../../contexts/global-context";
import TranslatableText from "../../../../Language/TranslatableText";
import BusinessInformation from "../../../admin/common/business-information/BusinessInformation";
import PartnerInformation from "../../../admin/common/partner-information/PartnerInformation";
import SharePointInformation from "../../../admin/common/sharepoint-information/SharePointInformation";
import UserInformation from "../../../admin/common/user-information/UserInformation";
import { getPartners } from "../../../admin/views/partners/partnersAPI";
import { getTemplateProjectsAPI } from "../../../admin/views/projects/projectsAPI";
import { getSystems } from "../../../admin/views/systems/systemsAPI";
import { getUsersByPartnerIdAPI } from "../users/partnerUsersAPI";
import {
  createCompanyAPI, getCompanyByIdAPI, updateCompanyAPI
} from "./partnerCompaniesAPI";



class AddPartnerCompany extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="email_empty" />
      },
      {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: <TranslatableText translationKey="email_valid" />
      },
      {
        field: "partnerId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
      },
      {
        field: "vat",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "countryCode",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "selectedOption",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      cuLoader: false,
      loading: false,
      isTemplate: false,
      partnerDetails: {},
      partnersDropDown: [],
      templatesDropdown: [],
      templateOrganizationDropdown: [],
      systems: [],
      allSystemIDs: [],
      systemIds: [],
      selectedOption: "",
      selectedAccountRepresentatives: [],
      accountRepresentatives: [],
      partnerId: "",
      name: "",
      phone: "",
      email: "",
      vat: "",
      address: "",
      postalCode: "",
      city: "",
      countryCode: "dk",
      releasePhase: 0,
      logo: "",
      username: "",
      useremail: "",
      userPhone: "",
      templateId: "",
      templateOrganization: "",
      selectedFiscalYear: 2020,
      licenseExpirationDate: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).toISOString(),
      licenseExpirationDatePicker: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ),
      invoiceNumber: "",
      isActive: true,
      validation: this.validator.valid(),
      countryCodeDropdown: Config.languages,
      configuration: {
        sharepointConfiguration: {
          url: "",
          clientId: "",
          clientSecret: "",
          tenantId: "",
          folderName: ""
        }
      }
    };

    this.submitted = false;
  }
  componentDidMount() {
    // populate users
    this.getUsersList();

    if (this.props.match.params.partnerId !== undefined) {
      this.setState({
        partnerId: this.props.match.params.partnerId
      });
    }
    getPartners()
      .then(res => {
        this.setState({ partnersDropDown: Array.isArray(res) ? res : [] });
        var partner = res.find(e => e.id === this.state.partnerId);
        if (partner.configuration !== null && partner.configuration !== undefined) {
          this.setState({
            configuration: JSON.parse(partner.configuration)
          });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    let getSystemsPromise = new Promise((resolve, reject) => {
      getSystems()
        .then(res => {
          if (!Array.isArray(res)) {
            console.log("debug: ", res);
            return;
          }
          var partnerSystemIDs = [];
          
          res.forEach((row) => {
            if (row.name === 'Revisormappen' && 
            ( this.props.match.params.partnerId !== 'e20fb242-ad00-481d-aefb-608ad54e857c'
            && this.props.match.params.partnerId !== '4ef4bcbb-1cff-4dc7-ad18-a47e082b7856'
            && this.props.match.params.partnerId !== '8e4b6812-999e-47cc-8ebe-97cddbb851a4'
            && this.props.match.params.partnerId !== '43567114-cdf9-430c-b95b-3ecec7d3e1a0' )) {

            } else {
              partnerSystemIDs.push({ label: row.name, value: row.id });
            }
          });
          this.setState(
            { allSystemIDs: partnerSystemIDs, systems: res },
            () => {
              resolve({ allSystemIDs: partnerSystemIDs, systems: res });
            }
          );
          if (this.state.selectedOption === undefined) {
            this.setState({selectedOption: this.state.systems[0]})
          }
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
        });
    });

    getTemplateProjectsAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ templateOrganizationDropdown: res, loading: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });

    if (this.props.match.params.id !== undefined) {
      this.setState({
        loading: true
      });
      getCompanyByIdAPI(this.props.match.params.id)
        .then(res => {
          Promise.all([getSystemsPromise]).then(values => {
            let selectedSystem = this.searchArray(
              res.data.systemIds[0],
              this.state.systems,
              "id"
            );
            if (selectedSystem === undefined) {
              selectedSystem = this.state.systems[0]
            }
            let selected = [
              {
                label: selectedSystem.name,
                value: selectedSystem.id
              }
            ];
            this.setState({
              partnerId: res.data.partnerId === null ? "" : res.data.partnerId,
              name: res.data.name,
              email: res.data.email,
              phone:
                res.data.phone === "" || res.data.phone === null
                  ? ""
                  : res.data.phone,
              vat: res.data.vat,
              address: res.data.address,
              postalCode: res.data.postalCode,
              city: res.data.city,
              countryCode:
                res.data.countryCode === '12' ? 'dk' : res.data.countryCode === "" ? 'dk' : res.data.countryCode,
              releasePhase: 0,
              logo: res.data.logo,
              selectedOption: selected,
              systemIds: selected.map(row => row.value),
              templateOrganization:
                res.data.templateOrganization === null
                  ? ""
                  : res.data.templateOrganization,
              invoiceNumber:
                res.data.invoiceNumber === null ? "" : res.data.invoiceNumber,
              licenseExpirationDatePicker: this.dateFormatter(
                res.data.licenseExpirationDate
              ),
              licenseExpirationDate: new Date(
                res.data.licenseExpirationDate
              ).toISOString(),
              isTemplate: res.data.isTemplate,
              isActive: res.data.isActive,
              configuration:
                res.data.configuration === null
                  ? this.state.configuration
                  : JSON.parse(res.data.configuration),
              accountRepresentatives: res.data.accountRepresentatives.map(
                row => row.value
              ),
              selectedAccountRepresentatives: res.data.accountRepresentatives,
              selectedFiscalYears: res.data.fiscalYear,
              loading: false
            });
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });

      // To Bypass the First User validations while updating the Company record
      this.setState({
        useremail: "0",
        username: "0",
        userPhone: "0",
        templateId: "0"
      });
    }
  }

  getUsersList = () => {
    getUsersByPartnerIdAPI(this.props.match.params.partnerId).then(res => {
      if (!Array.isArray(res)) {
        console.log("debug: ", res);
        return;
      }
      var users = [];
      res.forEach(function (row) {
        users.push({ label: row.name, value: row.b2CId });
      });
      this.setState({
        accountRepresentativeDropdown: users
      });
    });
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  getBase64 = file => {
    var self = this;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      self.setState({
        logo: reader.result
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  toggleButton = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  dateFormatter = timestamp => {
    return new Date(timestamp);
  };

  handleChange = (date = null, event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.type === "file") {
      const validImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml"
      ];

      if (target.files[0] !== undefined) {
        if (!validImageTypes.includes(target.files[0].type)) {
          alert("Only Images are allowed");
          target.value = "";
          return false;
        } else {
          if (target.files[0].size / 1024 <= 1000) {
            this.getBase64(target.files[0]);
          } else {
            alert("Max allowed size is 1000Kb");
            target.value = "";
            return false;
          }
        }
      }
    } else if (date !== null) {
      this.setState({
        licenseExpirationDatePicker: date,
        licenseExpirationDate: date.toISOString()
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  handleConfigChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    // update object
    let configuration = { ...this.state.configuration };
    configuration["sharepointConfiguration"][name] = value;
    this.setState({ configuration });
  };

  handleSelectChange = selectedOption => {
    this.setState({
      systemIds: [selectedOption.value],
      selectedOption: selectedOption
    });
  };

  handleMultiTagboxChange = (state, selectedStateName, selectedOption) => {
    this.setState({
      [state]:
        selectedOption === null || selectedOption === undefined
          ? []
          : selectedOption.map(row => row.value),
      [selectedStateName]: selectedOption === null ? "" : selectedOption
    });
  };

  createCompany = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      partnerId: this.state.partnerId,
      systemIds: this.state.systemIds,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      vat: this.state.vat,
      address: this.state.address,
      postalCode: this.state.postalCode,
      city: this.state.city,
      countryCode: this.state.countryCode,
      releasePhase: 0,
      logo: this.state.logo,
      templateId: this.state.templateId,
      templateOrganization: this.state.templateOrganization,
      licenseExpirationDate: this.state.licenseExpirationDate,
      fiscalYear: this.state.selectedFiscalYear,
      invoiceNumber: this.state.invoiceNumber,
      isTemplate: this.state.isTemplate,
      isActive: this.state.isActive,
      configuration: JSON.stringify(this.state.configuration),
      username: this.state.username,
      useremail: this.state.useremail,
      userPhone: this.state.userPhone,
      accountRepresentatives: this.state.accountRepresentatives
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateCompanyAPI(data)
          .then(res => {
            this.setState({ cuLoader: false }, () => {
              this.goBack();
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        if (
          (this.state.username !== "" && this.state.useremail === "") ||
          (this.state.username === "" && this.state.useremail !== "")
        ) {
          toast.info("Please fill user creation form!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 5000
          });
        } else {
          this.setState({ cuLoader: true });
          createCompanyAPI(data)
            .then(res => {
              this.setState({ cuLoader: false }, () => {
                this.goBack();
              });
            })
            .catch(error => {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
              });
              this.setState({ cuLoader: false });
            });
        }
      }
    }
  };

  removeLogo = () => {
    this.setState({ logo: "" });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    if (this.state.loading) {
      return (
        <ScreenLoading />
      )
    } else {
      return (
        <div className="partner-view">
          <div className="flex space-between">
            <h1>
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                size="1x"
                onClick={this.goBack}
                className="cursor"
              />{" "}
              {this.props.match.params.id !== undefined ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}{" "}
              Company
            </h1>
            {this.props.match.params.id !== undefined ? (
              <Toggle
                className="align-right mr-10"
                label="company.is_active"
                toggleButton={this.toggleButton}
                stateName="isActive"
                innerClassName="search-toggle-btn"
                componentState={this.state}
              />
            ) : (
              <></>
            )}
          </div>

          <Form onSubmit={this.createCompany}>
            <div className="top-container">
              <PartnerInformation
                componentState={this.state}
                handleChange={this.handleChange}
                handleMultiTagboxChange={this.handleMultiTagboxChange}
                handleSelectChange={this.handleSelectChange}
                validation={validation}
                id={this.props.match.params.id}
                renderAccountRepresentative={true}
                renderCompanies={false}
                partnerId={this.props.match.params.partnerId}
              />
              <hr />
            </div>
            <div>
              <Row>
                <Col className="flex space-between" md={12}>
                  <div>
                    <h4>
                      <TranslatableText translationKey="company.business" />
                    </h4>
                    <p>
                      <TranslatableText translationKey="company.enter_company_information" />
                    </p>
                  </div>
                  <Toggle
                    className="align-right"
                    label="company.template_organisation"
                    toggleButton={this.toggleButton}
                    stateName="isTemplate"
                    innerClassName="search-toggle-btn"
                    componentState={this.state}
                  />
                </Col>
              </Row>
              <BusinessInformation
                componentState={this.state}
                handleChange={this.handleChange}
                validation={validation}
                removeLogo={this.removeLogo}
                id={this.props.match.params.id}
              />
              {this.props.match.params.id === undefined ? (
                <UserInformation
                  componentState={this.state}
                  handleChange={this.handleChange}
                  validation={validation}
                />
              ) : (
                ""
              )}
              {this.state.selectedOption.label === 'Revisormappen'
               || this.state.selectedOption.label === 'Digital Jura' 
               || this.state.selectedOption.label === 'Tristad'? (
                <SharePointInformation
                  componentState={this.state}
                  handleConfigChange={this.handleConfigChange}
                />
              ) : (
                <></>
              )}
              <Row form>
                <Col className="flex-end flex" md={12}>
                  {this.state.cuLoader === false ? (
                    <Button>
                      {this.props.match.params.id !== undefined ? (
                        <TranslatableText translationKey="update" />
                      ) : (
                        <TranslatableText translationKey="create" />
                      )}
                    </Button>
                  ) : (
                    <InlineLoading />
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      );
    }
  }
}

export default withRouter(AddPartnerCompany);
AddPartnerCompany.contextType = GlobalContext;