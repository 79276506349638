// @ts-nocheck
import React, { useState } from "react";
import { useHistory } from 'react-router';
import { useParams, withRouter } from "react-router-dom";
import {
    Alert,
    Button, Col, Modal, ModalBody,
    ModalFooter, ModalHeader, Row
} from "reactstrap";
import showdown from "showdown";
import * as Survey from "survey-react";
import "survey-react/survey.min.css";
import { CustomEntityValuesClient, GetCustomEntityValueResponse, GetPageListResponse, GetProcessResponse, GetRegardingObjectQuestionAnswersResponse, GetRegardingObjectTypeResponse, ProcessesClient, QuestionAnswersClient, RegardingObjectTypesClient, WizardsClient } from "../../apiClients";
import HandleCaught_API_Error, { HandleCaught_API_Error_Base } from "../../apiClients/api_caughtError";
import { ScreenLoading } from "../../components";
import authService from "../../components/api-authorization/AuthorizeService";
import { RouterPrompt } from "../../components/router-prompt";
import { Config } from "../../config/Config";
import { ResourceTypes } from "../../config/ResourceTypes";
import { usePlatformUserData } from "../../contexts";
import TranslatableText from "../../Language/TranslatableText";
import { ISurveyIdentifiers } from "./REWrapper";
import SurveyNavigation from './SurveyNavigation';
import "./SurveyWizard.scss";
import {
    createQuestionAnswersAPI, updateProgressStateForExternalQuestionnaire
} from "./surveyWizardAPI";
import { datePicker } from "./widgets/Picker";
import { tagbox } from "./widgets/Tagbox";
import imageCompression from 'browser-image-compression';

Survey.Serializer.addProperty("question", "descriptionLong:text");
Survey.Serializer.addProperty("question", "answerDisplayTypeCode:text");
Survey.Serializer.addProperty("question", "answerTypeCode:text");
Survey.Serializer.addProperty("question", "options:array");
Survey.Serializer.addProperty("question", "optionsByUrl:text");
Survey.Serializer.addProperty("question", "tagBoxSelected:array");
Survey.Serializer.addProperty("question", "questionId:text");
Survey.Serializer.addProperty("question", "isMulti:boolean");
Survey.Serializer.addProperty("question", "isExternalURL:boolean");
Survey.Serializer.addProperty("question", "isSeen:boolean");
Survey.Serializer.addProperty("question", "valueKey:string");
Survey.Serializer.addProperty("question", "titleKey:string");
Survey.Serializer.addProperty("question", "referenceDisplayText:string");
Survey.Serializer.addProperty("question", "reference:text");
Survey.CustomWidgetCollection.Instance.addCustomWidget(datePicker);
Survey.CustomWidgetCollection.Instance.addCustomWidget(tagbox);

// Kreston vaesentlighed line calculation.
Survey.FunctionFactory.Instance.register("kdv", (params) => { return Math.round(Math.abs(Math.round(params[0] * (params[1] / 100))) / 1000); });
// Round
Survey.FunctionFactory.Instance.register("round", (params) => { return Math.round(params[0]); });
// Round
Survey.FunctionFactory.Instance.register("count", (params) => { return params.length; });


type SurveyWizardProps = {
    surveyIdentifiers: ISurveyIdentifiers;
    mode: string | undefined;
    className: string | undefined;
    match: string | undefined;
    isOtpAccess: boolean | undefined;
}
type SurveyWizardParams = {
    id: string | undefined;
    recordId: string | undefined;
    regardingObjectTypeId: string | undefined;
    pageId: string | undefined;
};

const SurveyWizardNew: React.FC<SurveyWizardProps> = (props) => {
    const { surveyIdentifiers, mode, className, match, isOtpAccess } = props;
    const { id, recordId, regardingObjectTypeId, pageId } = useParams<SurveyWizardParams>();
    const currentOrgData = usePlatformUserData();
    const history = useHistory();

    const [lock, setLock] = useState<boolean>(false);
    const [flowEngine, setFlowEngine] = useState<boolean>(false);
    const [populateData, setPopulateData] = useState({});
    const [surveyJsonData, setSurveyJsonData] = useState({});
    const [loading, setLoading] = useState<boolean>(false);
    const [model, setModel] = useState<Survey.Model | null>(null);
    const [loadingNav, setLoadingNav] = useState<boolean>(false);
    const [moreInfoModal, setMoreInfoModal] = useState<boolean>(false);
    const [completingSurvey, setCompletingSurvey] = useState<boolean>(false);
    const [modalDescription, setModalDescription] = useState<string>("");
    const [css, setCss] = useState({
        progressBar: "bg-primary sv_main sv_progress_bar",
        navigationButton: "btn btn-primary",
        rating: {
            root: "btn-group float-right mt-1",
            item: "btn btn-sm btn-outline-primary",
            selected: "active",
            minText: "sv_q_rating_min_text",
            itemText: "sv_q_rating_item_text",
            maxText: "sv_q_rating_max_text"
        },
        matrixdynamic: {
            iconRemove: "fas fa-trash"
        }
    });
    const [isFirstPage, setIsFirstPage] = useState<boolean>(false);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);
    const [wizardIdState, setWizardIdState] = useState(surveyIdentifiers?.wizardId ?? id);
    const [recordIdState, setRecordIdState] = useState(surveyIdentifiers?.entityId ?? recordId);
    const [regardingObjectTypeIdState, setRegardingObjectTypeIdState] = useState(surveyIdentifiers?.regardingObjectTypeId ?? regardingObjectTypeId);
    const [partialCompletion, setPartialCompletion] = useState<boolean>(false);
    const [moduleSettings, setModuleSettings] = useState<GetRegardingObjectTypeResponse>();

    const selectRef = React.useRef();

    React.useEffect(() => {
        shortcutSearch();

        setLoading(true);
        document.body.addEventListener('keyup', textAreaAdjust);
        document.body.addEventListener("click", bodyClickHandler);

        componentOnLoad();
        return () => {
            document.body.removeEventListener('keyup', textAreaAdjust);
            document.body.removeEventListener("click", bodyClickHandler);
        };
    }, [])

    const textAreaAdjust = (ev: KeyboardEvent) => {
        if (ev === null) {
            return;
        }
        if (ev.target === null) {
            return;
        }
        let _target = ev.target as HTMLTextAreaElement;
        _target.style.height = "1px";
        _target.style.height = (25 + _target.scrollHeight) + "px";
    }

    async function componentOnLoad() {
        const _RegardingObjectTypesClient = new RegardingObjectTypesClient();
        const _QuestionAnswersClient = new QuestionAnswersClient();
        const _WizardsClient = new WizardsClient();
        const _CustomEntityValuesClient = new CustomEntityValuesClient();
        const _ProcessesClient = new ProcessesClient();


        let surveyQuestions: any[]; // missing response types
        let tempWizardPages: GetPageListResponse;
        let referenceObject: GetProcessResponse | GetCustomEntityValueResponse;


        Promise.all([
            await _RegardingObjectTypesClient.regardingObjectTypes(regardingObjectTypeIdState)
                .then(res => {
                    setModuleSettings(res)
                }),
            await _WizardsClient.pagesList(wizardIdState)
                .then(wizardPageResult => {
                    wizardPageResult.locale = currentOrgData.currentOrgData.defaultLanguage === "dk" ? "da" : currentOrgData.currentOrgData.defaultLanguage;
                    surveyQuestions = extractArrays(wizardPageResult);
                    tempWizardPages = wizardPageResult;
                }),
            regardingObjectTypeIdState === ResourceTypes.process ?
                await _ProcessesClient.processes(recordIdState)
                    .then(res => {
                        res.name = res.nameShort ?? res.name;
                        referenceObject = res;
                    })
                :
                await _CustomEntityValuesClient.customEntityValuesGet(recordIdState)
                    .then(res => {
                        referenceObject = res;
                    })
        ])
            .catch(HandleCaught_API_Error_Base)
            .finally(() => {
                tempWizardPages.title = referenceObject.name;
                setLock(referenceObject.lock);
                setFlowEngine(referenceObject.flowEngine);

                let tempModel: Survey.Model = new Survey.Model(tempWizardPages);
                setModel(tempModel);
            })

        await _QuestionAnswersClient.questionAnswersGet(recordIdState)
            .then(questionAnswerResult => {
                var json = {};
                handleQuestionAnswerResult(questionAnswerResult, json, surveyQuestions);
                setPopulateData(json);
            })
            .catch(HandleCaught_API_Error_Base)
            .finally(() => setLoading(false));
    }


    function handleQuestionAnswerResult(questionAnswerResult: GetRegardingObjectQuestionAnswersResponse[], json: {}, surveyQuestions: any) {
        questionAnswerResult.forEach(element => {
            if (element.answerDisplayTypeCode === 1015 ||
                element.answerDisplayTypeCode === 1016 ||
                element.answerDisplayTypeCode === 1007 ||
                element.answerDisplayTypeCode === 1008 ||
                element.answerDisplayTypeCode === 1014 ||
                element.answerDisplayTypeCode === 3001 ||
                element.answerDisplayTypeCode === 3002 ||
                element.answerDisplayTypeCode === 3003 ||
                element.answerDisplayTypeCode === 3010 ||
                element.answerDisplayTypeCode === 3012 ||
                element.answerDisplayTypeCode === 5001 ||
                element.answerDisplayTypeCode === 5002 ||
                element.answerDisplayTypeCode === 5003) {
                if (element.answer === "") {
                    return;
                }
                let answer = JSON.parse(element.answer);
                json[search(element.questionId, surveyQuestions)] = answer;
            } else if (element.answer !== null &&
                element.tagBoxSelectedValues.length === 0) {
                if (element.answer === "") {
                    return;
                }
                json[search(element.questionId, surveyQuestions)] =
                    element.answer;
            } else {
                if (element.answerOptionsSetValues.length < 2 &&
                    element.tagBoxSelectedValues.length === 0) {
                    json[search(element.questionId, surveyQuestions)] =
                        element.answerOptionsSetValues[0];
                } else if (((element.tagBoxSelectedValues.length > 0 &&
                    element.answerDisplayTypeCode === 1003) ||
                    (element.tagBoxSelectedValues.length > 0 &&
                        element.answerDisplayTypeCode === 1002)) &&
                    element.isSeen === true) {
                    json[search(element.questionId, surveyQuestions)] =
                        element.tagBoxSelectedValues;
                } else if (((element.tagBoxSelectedValues.length > 0 &&
                    element.answerDisplayTypeCode === 1003) ||
                    (element.tagBoxSelectedValues.length > 0 &&
                        element.answerDisplayTypeCode === 1002)) &&
                    element.isSeen === false) {
                    json[search(element.questionId, surveyQuestions)] = element.tagBoxSelectedValues.map(row => row.value);
                } else {
                    json[search(element.questionId, surveyQuestions)] =
                        element.answerOptionsSetValues;
                }
            }
        });
    }

    const shortcutSearch = () => {
        window.onkeydown = function (e) {
            if (e.altKey && e.keyCode == 83) {
                e.preventDefault();
                selectRef?.current.focus()
                return false;
            }
        }
    }


    const extractArrays = (arr, acc = []) => {
        var surveyQuestions = [];
        if (arr.pages.length === 0) return acc;
        arr.pages.forEach(page => {
            page.name = page.id;
            page.elements.forEach(panel => {
                acc.push(panel.elements);
            });
        });

        acc.forEach(questions => {
            questions.forEach(elements => {
                surveyQuestions.push(elements);
            });
        });

        return surveyQuestions;
    };

    const search = (key, array) => {
        for (var i = 0; i < array.length; i++) {
            if (array[i].questionId === key) {
                return array[i].name;
            }
        }
    };

    const bodyClickHandler = e => {
        if (e.target.className === "popup") {
            closePopup();
        }
    };

    const onComplete = (survey, options) => {
        //Write survey results into database
        setCompletingSurvey(true);
        var resultData = [];
        for (var key in survey.data) {
            var question = survey.getQuestionByValueName(key);
            if (question?.propertyHash?.parent?.propertyHash?.name == "Interwizard_Panel") {
                continue;
            }
            if (question) {
                if (
                    question.getType() === "text" ||
                    question.getType() === "comment" ||
                    question.getType() === "number" ||
                    question.getType() === "expression" ||
                    question.getType() === "date" ||
                    question.getType() === "email" ||
                    question.getType() === "rating" ||
                    question.getType() === "file"
                ) {
                    if (question.getType() === "file") {
                        var item = {
                            questionId: question.questionId,
                            answer: JSON.stringify(question.value),
                            answerOptionsSetValues: null,
                            answerDisplayTypeCode: question.answerDisplayTypeCode,
                            answerTypeCode: question.answerTypeCode
                        };
                    } else {
                        var item = {
                            questionId: question.questionId,
                            answer: question.value,
                            answerOptionsSetValues: null,
                            answerDisplayTypeCode: question.answerDisplayTypeCode,
                            answerTypeCode: question.answerTypeCode
                        };
                    }
                } else if (question.answerDisplayTypeCode === 3012) {
                    var item = {
                        questionId: question.questionId,
                        answer: JSON.stringify(question.value),
                        answerOptionsSetValues: null,
                        answerDisplayTypeCode: question.answerDisplayTypeCode,
                        answerTypeCode: question.answerTypeCode
                    };
                }
                else {
                    if (Array.isArray(question.value) || question.value.r01 !== undefined) {
                        if (question.getType() === "matrixdynamic" || question.getType() === "matrixdropdown") {
                            if (
                                question.answerDisplayTypeCode === 1015 ||
                                question.answerDisplayTypeCode === 1007 ||
                                question.answerDisplayTypeCode === 1008 ||
                                question.answerDisplayTypeCode === 1014 ||
                                question.answerDisplayTypeCode === 3001 ||
                                question.answerDisplayTypeCode === 3002 ||
                                question.answerDisplayTypeCode === 3003 ||
                                question.answerDisplayTypeCode === 3010 ||
                                question.answerDisplayTypeCode === 5001 ||
                                question.answerDisplayTypeCode === 5002 ||
                                question.answerDisplayTypeCode === 5003
                            ) {
                                if (question.value.length === 1) {
                                    if (question.value.User !== undefined) {
                                        continue;
                                    }
                                    if (question.value[0] && Object.keys(question.value[0]).length === 0 && question.value[0].constructor === Object) {
                                        continue;
                                    }
                                }


                                // KD date handling.
                                if (question.columns.some(x => x.name === 'date')) {
                                    // Check if other data on question exists.
                                    question.value.forEach(qValue => {
                                        if (qValue.date === undefined) {
                                            qValue.date = new Date().toISOString();
                                        }
                                    });
                                }


                                var item = {
                                    questionId: question.questionId,
                                    answer: JSON.stringify(question.value),
                                    answerOptionsSetValues: null,
                                    answerDisplayTypeCode: question.answerDisplayTypeCode,
                                    answerTypeCode: question.answerTypeCode
                                };
                            }
                        } else if (question.getType() === "tagbox") {
                            if (!question.isExternalURL) {
                                if (question.value === null || question.value === undefined) {
                                    var selected = [];
                                } else {
                                    var selected = question.value.map(row => {
                                        if (row.value !== undefined) {
                                            return row.value;
                                        } else {
                                            return row;
                                        }
                                    });
                                }

                                var item = {
                                    questionId: question.questionId,
                                    answer: null,
                                    answerOptionsSetValues: selected,
                                    answerDisplayTypeCode: question.answerDisplayTypeCode,
                                    answerTypeCode: question.answerTypeCode
                                };
                            } else {
                                var selected =
                                    question.value === null || question.value === undefined
                                        ? []
                                        : question.value;
                                var item = {
                                    questionId: question.questionId,
                                    answer: JSON.stringify(selected),
                                    answerOptionsSetValues: null,
                                    answerDisplayTypeCode: question.answerDisplayTypeCode,
                                    answerTypeCode: question.answerTypeCode
                                };
                            }
                        } else {
                            var item = {
                                questionId: question.questionId,
                                answer: null,
                                answerOptionsSetValues: question.value,
                                answerDisplayTypeCode: question.answerDisplayTypeCode,
                                answerTypeCode: question.answerTypeCode
                            };
                        }
                    } else {
                        if (
                            question.getType() === "tagbox" &&
                            !Array.isArray(question.value)
                        ) {
                            var selected =
                                question.value === null || question.value === undefined
                                    ? []
                                    : question.value;
                            var item = {
                                questionId: question.questionId,
                                answer: selected,
                                answerOptionsSetValues: null,
                                answerDisplayTypeCode: question.answerDisplayTypeCode,
                                answerTypeCode: question.answerTypeCode
                            };
                        } else {
                            var item = {
                                questionId: question.questionId,
                                answer: null,
                                answerOptionsSetValues: [question.value],
                                answerDisplayTypeCode: question.answerDisplayTypeCode,
                                answerTypeCode: question.answerTypeCode
                            };
                        }
                    }
                }
                resultData.push(item);
            }
        }
        var data = {
            userId: currentOrgData.currentOrgData.userId,
            regardingObjectId: recordIdState,
            language: currentOrgData.currentOrgData.defaultLanguage,
            regardingObjectTypeId: regardingObjectTypeIdState,
            answers: resultData,
            partialCompletion: partialCompletion,
            validated: survey.clientId !== 'notValidated',
            triggerFlow: survey.triggerFlow ?? false
        };

        console.debug("Survey results: ");
        console.debug(JSON.stringify(data));
        createQuestionAnswersAPI(data)
            .then(res => {
                if (isOtpAccess) {

                    // Update survey state for OTP user.
                    updateProgressStateForExternalQuestionnaire(
                        {
                            recordId: recordIdState,
                            regardingObjectTypeId: regardingObjectTypeIdState,
                            wizardId: wizardIdState,
                            partialCompletion: partialCompletion
                        }
                    )
                        .then(res => {
                            history.push(`${window.location.pathname}/thank-you`);
                        })
                    // TODO Lock access to wizard...
                    return;
                }
                history.push("/");
            })
            .catch(HandleCaught_API_Error_Base);
    }

    const onMatrixCellCreated = (_survey, options) => {
        if (options.htmlElement === undefined) {
            return;
        }
        var _data = options.cellQuestion.data.data;
        if (_data.answerDisplayTypeCode === 3001 || _data.answerDisplayTypeCode === 3003) {
            switch (options.column.name) {
                case 'n1':
                    options.htmlElement.children[0].children[0].tabIndex = 1;
                    break;
                case 'n3':
                    options.htmlElement.children[0].children[0].tabIndex = 2;
                    break;
                case 'n5':
                    options.htmlElement.children[0].children[0].tabIndex = 3;
                    break;
                default:
                    break;
            }
        }
        if (_data.answerDisplayTypeCode === 3002) {
            switch (options.column.name) {
                case 'n1':
                case 'n3':
                case 'n5':
                    options.htmlElement.children[0].className = "ltd_small_table_sum";
                    break;
                case 'n2':
                case 'n4':
                case 'n6':
                    options.htmlElement.children[0].className = "ltd_small_table_sum_p";
                    break;
                default:
                    break;
            }
        }
    }

    const onUpdateQuestionCssClasses = (survey, options) => {
        if (options.question.propertyHash.answerTypeCode === 3001) {
            var classes = options.cssClasses;
            classes.mainRoot += " ltd_small_teable_qstn";
        }
    }

    const onAfterRenderQuestion = (survey, options) => {
        if (options.question.propertyHash.defaultValue) {
            if (!options.question.value) {
                options.question.value = options.question.propertyHash.defaultValue;
            }
        }
        //Return if there is no description to show in popup
        if (options.question.descriptionLong && options.question.answerDisplayTypeCode !== 1020) {
            let validURLT = validURL(
                options.question.descriptionLong.replace(/<[^>]*>?/gm, "")
            );

            //Add a button;
            var btn = document.createElement("a");
            btn.className = "info-circle";
            btn.innerHTML = "<i class='fa fa-info-circle'></i>";
            btn.href = validURLT
                ? options.question.descriptionLong.replace(/<[^>]*>?/gm, "")
                : "javascript:void(0);";
            btn.target = validURLT ? "_blank" : "";
            var question = options.question;
            btn.onclick = function () {
                if (!validURLT) {
                    showDescription(question, survey, options, false);
                }
            };
            var header = options.htmlElement.querySelector("h5");
            var span = document.createElement("span");
            span.innerHTML = "  ";
            header.appendChild(span);
            header.appendChild(btn);
        }
        if (options.question.reference) {
            let validURLT = validURL(
                options.question.reference.replace(/<[^>]*>?/gm, "")
            );

            //Add a button;
            var anchor = document.createElement("a");
            var anchorText = document.createTextNode(
                options.question.referenceDisplayText
            );
            anchor.href = validURLT
                ? options.question.reference.replace(/<[^>]*>?/gm, "")
                : "javascript:void(0);";
            anchor.target = validURLT ? "_blank" : "";
            anchor.className = "reference";
            var question = options.question;
            anchor.onclick = function () {
                if (!validURLT) {
                    showDescription(question, survey, options, true);
                }
            };
            var header = options.htmlElement.querySelector("h5");
            var span = document.createElement("span");
            span.innerHTML = "  ";
            header.appendChild(span);
            anchor.appendChild(anchorText);
            header.parentNode.insertBefore(anchor, header.nextSibling);
        }
    }

    const toggleMoreInfoModal = () => {
        setMoreInfoModal(prev => (!prev))
        if (moreInfoModal === false) {
            setModalDescription("")
        }
    };

    const showDescription = (question, survey, options, isReference) => {
        showPopup(question, isReference);
    };

    const showPopup = (question, isReference) => {
        document.getElementsByTagName("body")[0].classList.add("modal-open");
        document.getElementsByTagName("body")[0].style.paddingRight = "16px";
        document.getElementById("custom-popup").style.display = "flex";
        if (isReference) {
            document.getElementsByClassName("popup-body")[0].innerHTML =
                question.reference;
        } else {
            document.getElementsByClassName("popup-body")[0].innerHTML =
                question.descriptionLong;
        }
    };

    const closePopup = () => {
        document.getElementsByTagName("body")[0].classList.remove("modal-open");
        document.getElementsByTagName("body")[0].style.paddingRight = "0px";
        document.getElementById("custom-popup").style.display = "none";
        document.getElementsByClassName("popup-body")[0].innerHTML = "";
    };

    const validURL = str => {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return !!pattern.test(str);
    };

    // Used when not completing through the normal complate, so "save"
    const completeSurvey = survey => {
        setPartialCompletion(true);
        survey.clientId = 'notValidated';

        for (var key in survey.data) {
            if (key === "undefined") {
                continue;
            }
            var question = survey.getQuestionByValueName(key);
            if (question === undefined || question === null) {
                continue;
            }
            if (question.getType() === "tagbox" && !question.isExternalURL) {
                if (question.value.some(element => element.value !== undefined)) {
                    if (question.value === null || question.value === undefined) {
                        var selected = [];
                    } else {
                        var selected = question.value.map(row => {
                            if (row.value !== undefined) {
                                return row.value;
                            } else {
                                return row;
                            }
                        });
                    }
                    question.value = selected;
                }
            }
        }
        survey.doComplete();
    };

    const onUploadFiles = async (survey, options) => {
        const token = await authService.getAccessToken();
        let formData = new FormData();
        // await options.files.forEach(async (file) => {
        //     let _compressed = await imageCompression(file, options);
        //     formData.append("attachment", _compressed);
        // });

        const fileComppresionOptions = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1500,
            useWebWorker: false,
            fileType: 'image/jpeg'
        }
        for (let i = 0; i < options.files.length; i++) {
            console.log("Start adding file to formData", i);
            const file = options.files[i];
            console.log('originalFile instanceof Blob', file instanceof Blob); // true
            console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

            try {
                const compressedFile = await imageCompression(file, fileComppresionOptions);
                console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                formData.append("attachment", compressedFile);
            } catch (error) {
                if (error.message !== 'The file given is not an image') {
                    console.log(error);
                    HandleCaught_API_Error(error);
                    return;
                }
                formData.append("attachment", file);
            }

            console.log("Finished adding file to formData", i);
        }

        let xhr = new XMLHttpRequest();
        xhr.open(
            "POST",
            `${Config.server_url}:${Config.port}${Config.api_prefix}/Attachments/UploadFile`
        ); // https://surveyjs.io/api/MySurveys/uploadFiles
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        // xhr.setRequestHeader("content-type", "multipart/form-data");
        xhr.onload = function () {
            let data = JSON.parse(xhr.responseText);
            options.callback(
                "success",
                options.files.map(function (file, index) {
                    return {
                        file: file,
                        content: data.createdFiles[index].fileName
                    };
                })
            );
        };
        xhr.send(formData);
    };

    const detectIEOrEdge = () => {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        var trident = ua.indexOf("Trident/");
        var edge = ua.indexOf("Edge/");
        return edge > 0 || trident > 0 || msie > 0;
    };

    const onDownloadFile = async (survey, options) => {
        const token = await authService.getAccessToken();
        var xhr = new XMLHttpRequest();
        // var self = this;
        xhr.open(
            "GET",
            `${Config.server_url}:${Config.port}${Config.api_prefix}/Attachments/DownloadFile?fileName=${options.content}&contentType=${options.fileValue.type}`
        ); // "https://surveyjs.io/api/MySurveys/files?name=" + options.content
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);

        xhr.onloadstart = function (ev) {
            xhr.responseType = "blob";
        };
        xhr.onload = function () {
            var file;
            if (detectIEOrEdge()) {
                file = new Blob([xhr.response], options.fileValue.name, {
                    type: options.fileValue.type
                });
            } else {
                file = new File([xhr.response], options.fileValue.name, {
                    type: options.fileValue.type
                });
            }
            var reader = new FileReader();
            reader.onload = function (e) {
                options.callback("success", e.target.result);
            };
            reader.readAsDataURL(file);
        };
        xhr.send();
    };

    const goBack = () => {
        history.goBack();
    };

    const handleNavLoader = () => {
        setLoadingNav(true);
        setLoadingNav(false);
    }

    if (!model) {
        return (<ScreenLoading />)
    }

    if (pageId !== undefined) {
        let _index = model.pages.findIndex((page) => {
            return page.name === pageId;
        });
        model.currentPage = model.pages[_index];
    }

    if (currentOrgData.currentOrgData.system === 'KD-Sys 360	') {
        model.clearInvisibleValues = 'none';
    }

    var costumCss = css;
    //Create showdown markdown converter
    var converter = new showdown.Converter();
    model.onTextMarkdown.add(function (survey, options) {
        //convert the markdown text to html
        var str = converter.makeHtml(options.text);
        //remove root paragraphs <p></p>
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
        //set html
        options.html = str;
    });

    return (
        <div className="survey">
            <RouterPrompt
                when={(!completingSurvey || lock) && mode !== "display"}
                title={"leave_wizard_without_saving"}
                cancelText="Cancel"
                okText="Confirm"
                onOK={() => true}
                onLeaveSave={() => {
                    console.log("Leave Save")
                    completeSurvey(model)
                    return true;
                }}
                onLeaveNotSave={() => {
                    return true;
                }}
                onCancel={() => {
                    return false;
                }}
            />
            {completingSurvey && (
                <ScreenLoading />
            )}
            {loading ? (
                <ScreenLoading />
            ) : (
                <>
                    <Row>
                        <Col md={12}>
                            {lock &&
                                <Alert color="info">
                                    <TranslatableText translationKey="kd.proccess.lock" />
                                </Alert>
                            }
                            {(mode === undefined && lock === false) &&
                                <>
                                    {loadingNav ? null :
                                        <SurveyNavigation
                                            model={model}
                                            header={true}
                                            selectRef={selectRef}
                                            flowEngine={flowEngine}
                                            searchInWizard={moduleSettings?.searchInWizard ?? false}
                                            goBack={() => goBack()}
                                            handleNavLoader={handleNavLoader}
                                            completeSurvey={() => completeSurvey(model)}
                                        />
                                    }
                                </>
                            }
                            <Survey.Survey
                                model={model}
                                css={costumCss}
                                onDownloadFile={onDownloadFile}
                                data={populateData}
                                onComplete={onComplete}
                                onUploadFiles={onUploadFiles}
                                onAfterRenderQuestion={onAfterRenderQuestion}
                                onMatrixCellCreated={onMatrixCellCreated}
                                onMatrixAfterCellRender={onMatrixCellCreated}
                                onUpdateQuestionCssClasses={onUpdateQuestionCssClasses}
                                showNavigationButtons={false}
                                mode={
                                    mode === undefined && lock === false
                                        ? "edit"
                                        : "display"
                                }
                            />
                        </Col>
                    </Row>
                    {(mode === undefined && lock === false) &&
                        <>
                            {loadingNav ? null :
                                <div className="footer">
                                    <SurveyNavigation
                                        model={model}
                                        header={false}
                                        selectRef={selectRef}
                                        flowEngine={flowEngine}
                                        searchInWizard={moduleSettings?.searchInWizard ?? false}
                                        goBack={() => goBack()}
                                        handleNavLoader={handleNavLoader}
                                        completeSurvey={() => completeSurvey(model)}
                                    />
                                </div>
                            }
                        </>
                    }

                    <Row>
                        <Col>
                            <div id="surveyContainer" />
                        </Col>
                    </Row>
                </>
            )}

            <div>
                <div id="custom-popup" className="popup">
                    <div className="popup-content">
                        <div className="popup-innerwrap">
                            <div className="popup-header">
                                <span className="close" onClick={closePopup}>
                                    &times;
                                </span>
                                <h4>
                                    <TranslatableText translationKey="more_info" />
                                </h4>
                            </div>
                            <div className="popup-body"></div>
                            <div className="popup-footer">
                                <Button outline onClick={closePopup}>
                                    <TranslatableText translationKey="close" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={moreInfoModal}
                    toggle={toggleMoreInfoModal}
                    className={className}
                >
                    <ModalHeader toggle={toggleMoreInfoModal}>
                        <TranslatableText translationKey="more_info" />
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col md={12}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: modalDescription
                                    }}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline onClick={toggleMoreInfoModal}>
                            <TranslatableText translationKey="close" />
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );

}

export default withRouter(SurveyWizardNew);