import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Documents from "./Documents";
import { withRouter } from "react-router-dom";

class DocumentsRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    return (
      <div className="parent-documents-view">
        <Switch>
          <Route path="/documents" render={() => <Documents />} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(DocumentsRouter);