import React, { Component } from "react";
import { Label, Row, Col, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { getTemplateRegardingObjectType } from "./companyClaimsAPI";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { getSections } from "../sections/companySectionsAPI";
import Accent from "../../../../components/accent/Accent";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";

class Roles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceLoader: false,
      sectionLoader: false,
      resourceClaims: [],
      sectionClaims: [],
      regardingObjectTypeIdDropdown: [],
      actionDropdown: [
        { code: 1000, name: "No Access" },
        { code: 1001, name: "Read" },
        { code: 1002, name: "Write" }
      ],
      sectionDropdown: [],
      loading: false,
      cuLoader: false
    };
  }

  componentDidMount() {
    this.setState({ resourceLoader: true, sectionLoader: true });
    getSections().then(res => {
      let sectionClaims = res;
      sectionClaims.forEach(element => {
        let action = this.searchArray(
          element.id,
          this.context.user.organizations.claims,
          "sectionId"
        );
        action = action === undefined ? 1000 : action;
        element.action = action;
      });
      this.setState({
        sectionLoader: false,
        sectionDropdown: Array.isArray(res) ? res : [],
        sectionClaims
      });
    });
    getTemplateRegardingObjectType().then(res => {
      let resourceClaims = res.filter(item =>
        item.id === "7eca674a-9b51-4aed-bd11-ea219c5c0d17" ||
        item.id === "7eca674a-9b51-4aed-bd11-ea219c5c8d17"
          ? false
          : true
      );
      resourceClaims.forEach(element => {
        let action = this.searchArray(
          element.id,
          this.context.user.organizations.claims,
          "regardingObjectTypeId"
        );
        action = action === undefined ? 1000 : action;
        element.action = action;
      });
      this.setState({
        resourceLoader: false,
        regardingObjectTypeIdDropdown: Array.isArray(res) ? res : [],
        resourceClaims
      });
    });
  }

  goBack = () => {
    this.props.history.goBack();
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i].action;
      }
    }
  };

  handleResourcesFieldChange = (idx, e) => {
    const { name, value } = e.target;
    const rows = [...this.state.resourceClaims];
    rows[idx].action = parseInt(value);

    this.setState({
      resourceClaims: rows
    });
  };

  handleSectionFieldChange = (idx, e) => {
    const { name, value } = e.target;
    const rows = [...this.state.sectionClaims];
    rows[idx].action = parseInt(value);

    this.setState({
      sectionClaims: rows
    });
  };

  render() {
    let { resourceClaims, sectionClaims } = this.state;

    return (
      <div className="create-wizard-view">
        <Row>
          <Col>
            <Accent useIcon={false}>
              <TranslatableText translationKey="roles" />
            </Accent>
          </Col>
        </Row>
        {!this.state.resourceLoader ? (
          <Row>
            <Col>
              <table className="claims-table">
                <thead>
                  <tr>
                    <th>
                      <TranslatableText translationKey="resoucres" />
                    </th>
                    <th>
                      <TranslatableText translationKey="action" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {resourceClaims.map((resource, idx) => {
                    return (
                      <tr key={idx}>
                        <td style={{width: '70%'}}>{resource.name}</td>
                        <td>
                          <div className="stv-radio-buttons-wrapper three">
                            {this.state.actionDropdown.map((element, key) => {
                              return (
                                <span key={key}>
                                  <input
                                    type="radio"
                                    className="stv-radio-button"
                                    name={resource.id}
                                    value={element.code}
                                    id={`button-${resource.id}-${key}`}
                                    onChange={e =>
                                      this.handleResourcesFieldChange(idx, e)
                                    }
                                    checked={resource.action === element.code}
                                  />
                                  <Label for={`button-${resource.id}-${key}`}>
                                    {element.name}
                                  </Label>
                                </span>
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        ) : (
          <InlineLoading />
        )}

        {!this.state.sectionLoader ? (
          <Row>
            <Col>
              <table className="claims-table">
                <thead>
                  <tr>
                    <th>
                      <TranslatableText translationKey="section" />
                    </th>
                    <th>
                      <TranslatableText translationKey="action" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sectionClaims.map((section, idx) => {
                    return (
                      <tr key={idx}>
                        <td style={{width: '70%'}}>{section.name}</td>
                        <td>
                          <div className="stv-radio-buttons-wrapper three">
                            {this.state.actionDropdown.map((element, key) => {
                              return (
                                <span key={key}>
                                  <input
                                    type="radio"
                                    className="stv-radio-button"
                                    name={section.id}
                                    value={element.code}
                                    id={`button-${section.id}-${key}`}
                                    onChange={e =>
                                      this.handleSectionFieldChange(idx, e)
                                    }
                                    checked={section.action === element.code}
                                  />
                                  <Label for={`button-${section.id}-${key}`}>
                                    {element.name}
                                  </Label>
                                </span>
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        ) : (
          <InlineLoading />
        )}
        <Row>
          <Col>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back_to_list" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Roles);
Roles.contextType = GlobalContext;