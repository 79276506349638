import React, { useEffect, useState } from "react";
import "survey-react/survey.min.css";
import "./SurveyWizard.scss";
import { useHistory } from "react-router-dom";

import authService from "../../components/api-authorization/AuthorizeService";
import axios from "axios";
import { Config } from "../../config/Config";
import SurveyWizard from "./SurveyWizardNew";
import { ScreenLoading } from "../../components/loading";

const ENTITY_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Survey`;

type IProps = {
    match: any
}

interface IInvitationData {
  email: string,
  wizardId: string, 
  recordId: string, 
  regardingObjectTypeId: string
  
}

interface ISurveyIdentifiers {
  wizardId: string,
  entityId: string,
  regardingObjectTypeId: string,
  questionnaire: string
}

export default function Questionnaire(props: IProps) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [surveyIdentifiers, setSurveyIdentifiers] = useState<ISurveyIdentifiers>(
    { wizardId: "", entityId: "", regardingObjectTypeId: "", questionnaire: ""});

  useEffect(() => {
    authService.getAccessToken()
        .then((token) => {
            var headers = {
              Authorization: `Bearer ${token}`
            };
            axios
              .get(
                `${ENTITY_API_URL}/QuiestionnaireIdentifiers/${props.match.params.questionnaire}`,
                { headers: headers }
              )
              .then(res => {  
                setSurveyIdentifiers({
                  wizardId: res.data.wizardId,
                  entityId: res.data.recordId,
                  regardingObjectTypeId: res.data.regardingObjectTypeId,
                  questionnaire: props.match.params.questionnaire
                })   
                setIsLoading(false);     
              });
        })
  }, []);

  if (isLoading) {
    return (
      <div className="center-align">
        <ScreenLoading />
      </div>
    );
  } 

    // @ts-ignore
  return (<SurveyWizard surveyIdentifiers={surveyIdentifiers} isOtpAccess={true} />)
}