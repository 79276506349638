import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { Component } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import TranslatableText from "../../Language/TranslatableText";
import "./Tile.scss";


class Tile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }
  render() {
    let {
      backgroundColor,
      textColor,
      className,
      title,
      subTitle,
      icon,
      iconSize,
      linkTo,
      disabled,
      externalLink,
    } = this.props;

    var tileStyle = {
      backgroundColor: backgroundColor,
      color: textColor
    };

    if (externalLink) {
      return (
        <a
          href={linkTo}
          className={classnames("tile mb-3", disabled ? "disabled" : "")}
          style={tileStyle}
          target="_blank"
        >
          <div className={classnames("", className)}>
            <h4>
              <TranslatableText translationKey={title} />
            </h4>
            <p>{subTitle}</p>
            <div className="flex-container center">
              <FontAwesomeIcon icon={icon} size={iconSize} />
            </div>
          </div>
        </a>
      );
    } 
    return (
      <NavLink
        tag={Link}
        className={classnames("tile mb-3", disabled ? "disabled" : "")}
        style={tileStyle}
        to={disabled ? "#" : linkTo}
      >
        <div className={classnames("", className)}>
          <h4>
            <TranslatableText translationKey={title} />
          </h4>
          <p>{subTitle}</p>
          <div className="flex-container center">
            <FontAwesomeIcon icon={icon} size={iconSize} />
          </div>
        </div>
      </NavLink>
    );
  }
}

export default Tile;
