import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Wizard from "./Wizard";
import CreateWizard from "./CreateWizard";
import WizardDetails from "./WizardDetails";
import PageRouter from "./views/page/PageRouter";
import PanelsRouter from "./views/panels/PanelsRouter";
import QuestionsRouter from "./views/questions/QuestionsRouter";
import AnswerOptionSetRouter from "./views/answeroptionset/AnswerOptionSetRouter";
import CreateWizardTemplate from "./CreateWizardTemplate";
import { withRouter } from "react-router-dom";

class WizardRouter extends Component {
  render() {
    return (
      <div className="admin-wizard-view">
        <Switch>
          <Route
            path={`/company-admin/wizard/details/:id/:organizationId`}
            render={props => (
              <WizardDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/update/:id`}
            render={props => (
              <CreateWizard {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/template/update/:wizardId/:id`}
            render={props => (
              <CreateWizardTemplate
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/template/create/:wizardId`}
            render={props => (
              <CreateWizardTemplate
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/create`}
            render={props => (
              <CreateWizard {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => (
              <WizardDetails {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/page`}
            render={props => (
              <PageRouter {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/panel`}
            render={props => (
              <PanelsRouter {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/question`}
            render={props => (
              <QuestionsRouter {...props} language={this.context.language} />
            )}
          />
          <Route
            path={`${this.props.match.path}/answeroptionset`}
            render={props => (
              <AnswerOptionSetRouter
                {...props}
                language={this.context.language}
              />
            )}
          />
          <Route
            path="/company-admin/wizard/:organizationId"
            render={props => (
              <Wizard {...props} language={this.context.language} />
            )}
          />
          <Route
            path="/admin/wizard"
            render={props => (
              <Wizard {...props} language={this.context.language} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(WizardRouter);