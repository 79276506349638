import { useQuery } from "@tanstack/react-query";
import React from 'react';
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { InlineLoading } from "../../components";
import Accent from "../../components/accent/Accent";
import EntityList from "./components/EntityTable";

type EntityDisplayType = 'list' | 'graph' | 'card';

type EntitySpec = {
  entityName: string;
  entityType: string;
  entityId: string;
  displayType: EntityDisplayType;
};

type EntityPageSpec = {
  pageTitle: string,
  icon: string,
  entities: EntitySpec[]
}

type EntityParams = { entityPageId: string }
export default function EntityWrapper() {
  const { entityPageId } = useParams<EntityParams>();
  const regardingObjectTypeQuery = useQuery(['entityPages', entityPageId], async () => {
    return getEntityPageSpec(entityPageId);
  });

  return (
    <div className="entities-view">
      <Row>
        <Col md={12}>
          <Accent useHtmlIcon={true} iconName={regardingObjectTypeQuery.data?.icon} >
            {regardingObjectTypeQuery.isLoading ? (
              <InlineLoading />
            ) : (
              regardingObjectTypeQuery.data?.pageTitle
            )}
          </Accent>
        </Col>
      </Row>
      {regardingObjectTypeQuery.data?.entities.map((pageSpec) => {
        switch (pageSpec?.displayType) {
          case 'list':
            return RenderEntityTableView(regardingObjectTypeQuery.isLoading, pageSpec);
          case 'graph':
          case 'card':
          default:
            return <></>;
        }
      })
      }
    </div>
  );
  function RenderEntityTableView(isLoading: boolean, pageSpec: EntitySpec): React.ReactNode {
    return <>
      <Row>
        <Col md={12}>
          <Accent useIcon={false}>
            {isLoading ? (
              <InlineLoading />
            ) : (
              pageSpec?.entityName
            )}
          </Accent>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <EntityList regardingObjectTypeId={pageSpec?.entityId} key={pageSpec?.entityId} />
        </Col>
      </Row>
    </>;
  }
}


function getEntityPageSpec(entityPageId: string): EntityPageSpec {
  let entityList: EntityPageSpec;
  switch (entityPageId) {
    case 'kd_entities':
      entityList = {
        "pageTitle": "KD Entities",
        "icon": "balance-scale",
        "entities": [
          {
            "entityName": "Noter",
            "entityType": "entity",
            "entityId": "2eca174a-0b51-4aed-bd11-ea219c5c0d16",
            "displayType": "list"
          },
          {
            "entityName": "Differencer",
            "entityType": "entity",
            "entityId": "fc01788a-fdd4-48ae-e2a9-08d89698d79c",
            "displayType": "list"
          }
        ]
      }
      break;
    case 'dpo_dataProcessor':
      entityList = {
        "pageTitle": "Databehandler",
        "icon": "balance-scale",
        "entities": [
          {
            "entityName": "Databehandlere",
            "entityType": "entity",
            "entityId": "ec3c87c8-4053-4147-5034-08d816ab75f0",
            "displayType": "list"
          },
          {
            "entityName": "Data Processor Records of Processing Activities",
            "entityType": "entity",
            "entityId": "dcbcfff5-adef-470e-f2aa-08d9cb998832",
            "displayType": "list"
          },
          {
            "entityName": "Revison af Databehandlere",
            "entityType": "entity",
            "entityId": "c18818a2-d942-4243-1b87-08d83e9b9516",
            "displayType": "list"
          }
        ]
      }
      break;
    default:
      entityList = {
        "pageTitle": "Blank",
        "icon": "faCheckCircle",
        "entities": []
      }
  }
  return entityList;
}