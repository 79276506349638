import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import CreateCompanyRole from "./CreateCompanyRole";
import CompanyRoleDetails from "./CompanyRoleDetails";
import { withRouter } from "react-router-dom";
import CompanyRoles from "./CompanyRoles";

class CompanyRolesRouter extends Component {
  render() {
    return (
      <div className="admin-wizard-view">
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}/update/:id`}
            render={props => <CreateCompanyRole {...props} />}
          />
          <Route
            path={`${this.props.match.path}/create`}
            render={props => <CreateCompanyRole {...props} />}
          />
          <Route
            path={`${this.props.match.path}/details/:id`}
            render={props => <CompanyRoleDetails {...props} />}
          />
          <Route
            path="/company-admin/roles"
            render={props => <CompanyRoles {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(CompanyRolesRouter);