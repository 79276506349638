import React, { Component } from "react";
import {
  FormGroup,
  Input,
  Label,
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import {
  getRegardingCustomFieldsAPI,
  deleteCustomFieldByIdAPI
} from "./customFieldsCompanyAPI";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import { getTemplateRegardingObjectType } from "../claims/companyClaimsAPI";
import TranslatableText from "../../../../Language/TranslatableText";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";



const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>No data available</span>
  </div>
);

class CF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      entity: "",
      regardingObjectTypeIdDropdown: [],
      customFields: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Field Name",
          sort: true
        },
        {
          dataField: "displayText",
          text: "Display Text",
          sort: true
        },
        {
          dataField: "dataType",
          text: "Type",
          sort: true
        },
        {
          dataField: "displayType",
          text: "Display Type",
          sort: true
        },
        {
          dataField: "actions",
          text: "Action",
          csvExport: false,
          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink
                  tag={Link}
                  to={`${this.props.match.path}/details/${row.id}/${row.regardingObjectTypeId}`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                </NavLink>
                <NavLink onClick={e => this.openDeleteModal(row.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
          }
        }
      ],
      deleteModal: false,
      customFieldId: null
    };
  }
  componentDidMount() {
    getTemplateRegardingObjectType()
      .then(res => {
        this.setState({
          regardingObjectTypeIdDropdown: Array.isArray(res) ? res : []
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  }

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            customFieldId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      customFieldId: id
    });
  };

  populateCustomFields = regardingObjectId => {
    this.setState({ tableLoader: true, customFields: [] });
    getRegardingCustomFieldsAPI(regardingObjectId)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ customFields: res, tableLoader: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  deleteAsset = id => {
    deleteCustomFieldByIdAPI(id)
      .then(res => {
        this.populateCustomFields(this.state.entity);
        this.setState({
          deleteModal: false,
          customFieldId: null
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          customFieldId: null
        });
      });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      () => {
        this.populateCustomFields(value);
      }
    );
  };

  render() {
    let { customFields, columns, tableLoader } = this.state;
    return (
      <div className="admin-custom-fields-view">
        <h1>Custom Fields</h1>
        <hr />
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="entity">
                <TranslatableText translationKey="question.select_an_entity" />
              </Label>
              <Input
                type="select"
                name="entity"
                id="entity"
                value={this.state.entity}
                onChange={this.handleChange}
              >
                <option value="">-- Vælg --</option>
                {this.state.regardingObjectTypeIdDropdown.map((e, key) => {
                  return (
                    <option key={e.id} value={e.id}>
                      {e.displayName}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <div className="table-container">
          <ToolkitProvider
            keyField="id"
            data={customFields}
            columns={columns}
            search
            exportCSV={{
              fileName: "data.csv",
              separator: this.context.user.language === 'dk' ? ';' : ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {props => (
              <div>
                <div className="flex space-between">
                  <div>
                    <SearchBar {...props.searchProps} />
                  </div>
                  <div>
                    <div className="flex space-between">
                      {this.context.userRoles.find(r =>
                        r.includes("Developer")
                      ) !== undefined && this.state.entity !== "" ? (
                        <ExportCSVButton
                          className="export-btn"
                          {...props.csvProps}
                        >
                          CSV
                        </ExportCSVButton>
                      ) : (
                        <></>
                      )}
                      <div>
                        {this.state.entity !== "" ? (
                          <NavLink
                            className="button-primary"
                            tag={Link}
                            to={`${this.props.match.path}/create/${this.state.entity}/${this.context.user.organizations.organizationId}`}
                          >
                            Create
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <BootstrapTable
                  noDataIndication={() =>
                    tableLoader ? (
                      <InlineLoading />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeletePopup}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleDeletePopup}>
            <TranslatableText translationKey="confirm_delete" />?
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <p>
                  <TranslatableText translationKey="custom_fields.confirm_delete_description" />
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              outline
              className="redOutline"
              onClick={e => this.deleteAsset(this.state.customFieldId, e)}
            >
              <TranslatableText translationKey="delete" />
            </Button>{" "}
            <Button outline onClick={this.toggleDeletePopup}>
              <TranslatableText translationKey="cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(CF);
CF.contextType = GlobalContext;