import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Project API URL
const PROJECTS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Organizations`;
const PROJECTS_STRING = `Projects`;

// Create Project API
export const createProjectAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${PROJECTS_API_URL}/Project`, data, { headers: headers })
    .then(res => res);
};

// Update Project API
export const updateProjectAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${PROJECTS_API_URL}/Project`, data, { headers: headers })
    .then(res => res);
};

// Get Project By ID
export const getProjectByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROJECTS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all Projects
export const getProjectsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROJECTS_API_URL}/Projects`, { headers: headers })
    .then(({ data }) => data);
};

// Get Template Projects
export const getTemplateProjectsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROJECTS_API_URL}/TemplateProjects`, { headers: headers })
    .then(({ data }) => data);
};

export const getOrganizationProjectsAPI = async organizationId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROJECTS_API_URL}/${organizationId}/${PROJECTS_STRING}`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get Partner Projects
export const getParnerProjectsAPI = async partnerId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PROJECTS_API_URL}/${partnerId}/PartnerProjects`, {
      headers: headers
    })
    .then(({ data }) => data);
};
