import { Route, Switch, useRouteMatch } from "react-router-dom";
import CreatePartnerUser from "../users/CreatePartnerUser";
import AddPartnerCompany from "./AddPartnerCompany";
import CompanyDetails from "./CompanyDetails";

export default function PartnerCompaniesRouter() {
  let match = useRouteMatch();

  return (
    <div className="parent-partner-view">
      <Switch>
        <Route path={`${match!.path}/details/:id`}>
          <CompanyDetails />
        </Route>
        <Route
          path={`${match!.path}/update/:partnerId/:id`}
          render={props => (
            <AddPartnerCompany {...props} />
          )}
        />
        <Route
          path={`${match!.path}/add/:partnerId`}
          render={props => (
            <AddPartnerCompany {...props} />
          )}
        />
        <Route
          path={`${match!.path}/user/create/:partnerId/:organizationId`}
          render={props => (
            <CreatePartnerUser {...props} />
          )}
        />
      </Switch>
    </div>
  );
}
