import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  createCustomFieldAPI,
  getCustomFieldByIdAPI,
  updateCustomFieldAPI,
  getCustomFieldDataType,
  getCustomFieldDisplayType
} from "./customFieldsAPI";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import classnames from "classnames";
import Gap from "../../../../components/gap/Gap";
import { Config } from "../../../../config/Config";
import { getTemplateRegardingObjectType } from "../../../company-admin/views/claims/companyClaimsAPI";
import Swal from "sweetalert2";
import { getRegardingCustomFieldsAPI } from "../../../company-admin/views/custom-fields/customFieldsCompanyAPI";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading, ScreenLoading } from "../../../../components";



class CreateCustomField extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "displayText",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      name: "",
      name2: "",
      displayText: "",
      displayText2: "",
      language: this.context?.user.organizations.defaultLanguage,
      languageDropdown: Config.languages,
      isRequired: false,
      validationMessage: "",
      customFieldType: "",
      customFieldDataTypeId: "",
      customFieldDataTypeIdDropdown: [],
      searchableDisplayTypeDropdown: [],
      customFieldDisplayTypeId: "",
      lookupFieldReferenceTypeId: "",
      regardingObjectTypeId2: "",
      answerDisplayTypeDropdown: [],
      entityDropdown: [],
      relatedEntityDropdown: [],
      relatedCustomFields: [],
      loading: false,
      entityLoader: false,
      showEntityDropdown: false,
      showSystemName: true,
      showDisplayType: true,
      showRelatedEntity: false,
      showRelatedDisplayText: false,
      autoPopulated: false,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    // Related Custom Fields
    getRegardingCustomFieldsAPI(
      this.props.match.params.regardingObjectId,
      this.props.match.params.organizationId
    )
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ relatedCustomFields: res });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    // Types Dropdown
    getCustomFieldDataType()
      .then(res => {
        this.setState({
          customFieldDataTypeIdDropdown: Array.isArray(res) ? res : []
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    //Display Type Dropdown
    getCustomFieldDisplayType()
      .then(res => {
        if (this.props.match.params.id !== undefined) {
          this.setState({
            answerDisplayTypeDropdown: Array.isArray(res) ? res : []
          });
        }
        this.setState({
          searchableDisplayTypeDropdown: Array.isArray(res) ? res : []
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getCustomFieldByIdAPI(this.props.match.params.id)
        .then(res => {
          if (
            res.data.lookupFieldReferenceTypeId !== "" &&
            res.data.lookupFieldReferenceTypeId !== null &&
            res.data.regardingObjectTypeId2 === "" &&
            res.data.regardingObjectTypeId2 === null
          ) {
            this.setState({ entityLoader: true, showEntityDropdown: true });
            getTemplateRegardingObjectType(
              this.context.user.organizations.organizationId
            )
              .then(result => {
                if (!Array.isArray(result)) {
                  console.log("debug: ", result);
                  return;
                }
                this.setState(
                  {
                    entityDropdown: result
                  },
                  () => {
                    this.setState({
                      lookupFieldReferenceTypeId:
                        res.data.lookupFieldReferenceTypeId,
                      entityLoader: false
                    });
                  }
                );
              })
              .catch(error => {
                toast.error(this.context.languages["api_error"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000
                });
                this.setState({
                  entityLoader: false
                });
              });
          } else if (
            res.data.regardingObjectTypeId2 !== "" &&
            res.data.regardingObjectTypeId2 !== null
          ) {
            this.setState({
              entityLoader: true,
              showEntityDropdown: true,
              showSystemName: false,
              showDisplayType: false,
              showRelatedEntity: true,
              showRelatedDisplayText: true
            });
          }
          this.setState({
            name: res.data.name,
            isRequired: res.data.isRequired,
            validationMessage:
              res.data.validationMessage === null
                ? ""
                : res.data.validationMessage,
            customFieldDataTypeId:
              res.data.customFieldDataTypeId === null
                ? ""
                : res.data.customFieldDataTypeId,
            customFieldDisplayTypeId:
              res.data.customFieldDisplayTypeId === null
                ? ""
                : res.data.customFieldDisplayTypeId,
            displayText: "0",
            language: "0",
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }

  isRequired = () => {
    this.setState({
      isRequired: !this.state.isRequired
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  createCustomField = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      name: this.state.name,
      name2: this.state.name2,
      displayText: this.state.displayText,
      displayText2: this.state.displayText2,
      customFieldDataTypeId: this.state.customFieldDataTypeId,
      customFieldDisplayTypeId: this.state.customFieldDisplayTypeId,
      lookupFieldReferenceTypeId: this.state.lookupFieldReferenceTypeId,
      regardingObjectTypeId2: this.state.regardingObjectTypeId2,
      isRequired: this.state.isRequired,
      language: this.state.language,
      validationMessage: this.state.validationMessage,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      regardingObjectTypeId:
        this.props.match.params.regardingObjectId !== undefined
          ? this.props.match.params.regardingObjectId
          : "",
      organizationId:
        this.props.match.params.organizationId !== undefined
          ? this.props.match.params.organizationId
          : ""
    };

    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ loading: true });
        updateCustomFieldAPI(data)
          .then(res => {
            this.setState({ loading: false }, () => {
              this.goBack();
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ loading: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ loading: true });
        createCustomFieldAPI(data)
          .then(res => {
            this.setState({ loading: false }, () => {
              this.goBack();
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ loading: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name == "customFieldDataTypeId") {
      var answerDisplayTypeArray = [];
      var selected = target.options[target.selectedIndex].text;
      this.setState({ customFieldType: selected });
      var displayTypeArray = this.state.searchableDisplayTypeDropdown;
      if (selected == "Text") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1001 || displayType.code === 1002;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          showEntityDropdown: false,
          showSystemName: true,
          showDisplayType: true,
          showRelatedEntity: false,
          showRelatedDisplayText: false,
          lookupFieldReferenceTypeId: ""
        });
      } else if (selected == "Number") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1003;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          showEntityDropdown: false,
          showSystemName: true,
          showDisplayType: true,
          showRelatedEntity: false,
          showRelatedDisplayText: false,
          lookupFieldReferenceTypeId: ""
        });
      } else if (selected == "Boolean") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1004;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          showEntityDropdown: false,
          showSystemName: true,
          showDisplayType: true,
          showRelatedEntity: false,
          showRelatedDisplayText: false,
          lookupFieldReferenceTypeId: ""
        });
      } else if (selected == "Date") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1005;
        });
        this.setState({
          answerDisplayTypeDropdown: answerDisplayTypeArray,
          showEntityDropdown: false,
          showSystemName: true,
          showDisplayType: true,
          showRelatedEntity: false,
          showRelatedDisplayText: false,
          lookupFieldReferenceTypeId: ""
        });
      } else if (selected == "Lookup") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 1006;
        });
        this.setState({
          showEntityDropdown: true,
          entityLoader: true,
          showSystemName: true,
          showDisplayType: true,
          showRelatedEntity: false,
          showRelatedDisplayText: false,
          lookupFieldReferenceTypeId: ""
        });
        getTemplateRegardingObjectType(
          this.context.user.organizations.organizationId
        )
          .then(res => {
            if (!Array.isArray(res)) {
              console.log("debug: ", res);
              return;
            }
            this.setState({
              entityDropdown: res,
              answerDisplayTypeDropdown: answerDisplayTypeArray,
              entityLoader: false
            });
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({
              answerDisplayTypeDropdown: [],
              showEntityDropdown: true,
              entityLoader: false
            });
          });
      } else if (selected == "Many to Many Lookup") {
        answerDisplayTypeArray = displayTypeArray.filter(displayType => {
          return displayType.code === 0;
        });
        this.setState({
          showEntityDropdown: true,
          entityLoader: true,
          showSystemName: false,
          showDisplayType: false,
          showRelatedEntity: true,
          showRelatedDisplayText: true
        });
        getTemplateRegardingObjectType(
          this.context.user.organizations.organizationId
        )
          .then(res => {
            if (!Array.isArray(res)) {
              console.log("debug: ", res);
              return;
            }
            this.setState(
              {
                entityDropdown: res,
                relatedEntityDropdown: res,
                answerDisplayTypeDropdown: answerDisplayTypeArray,
                entityLoader: false
              },
              () => {
                let entity1 = this.searchArray(
                  this.props.match.params.regardingObjectId,
                  this.state.entityDropdown,
                  "id"
                );
                if (entity1 !== undefined) {
                  this.setState({
                    lookupFieldReferenceTypeId: this.props.match.params
                      .regardingObjectId,
                    name: entity1.name,
                    displayText: entity1.pluralName,
                    autoPopulated: true
                  });
                }
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({
              answerDisplayTypeDropdown: [],
              showEntityDropdown: true,
              entityLoader: false
            });
          });
      } else {
        this.setState({
          answerDisplayTypeDropdown: [],
          showEntityDropdown: false,
          showSystemName: true,
          showDisplayType: true,
          showRelatedEntity: false,
          showRelatedDisplayText: false
        });
      }
    }

    if (name === "regardingObjectTypeId2") {
      if (this.state.lookupFieldReferenceTypeId === value) {
        toast.error("Entity 1 and Entity 2 cannot be same", {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          [name]: ""
        });
      } else {
        let field = this.searchArray(
          value,
          this.state.relatedCustomFields,
          "lookupFieldReferenceTypeId"
        );
        if (field !== undefined) {
          Swal.fire({
            title: this.context.languages["swal.are_you_sure"],
            text: this.context.languages["swal.are_you_sure.create_field"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: this.context.languages["swal.are_you_sure.yes"],
            cancelButtonText: this.context.languages["swal.are_you_sure.no"]
          }).then(result => {
            if (result.value) {
              let entity2 = this.searchArray(
                value,
                this.state.entityDropdown,
                "id"
              );
              this.setState({
                [name]: value,
                displayText2: entity2.pluralName,
                name2: entity2.name
              });

              // For more information about handling dismissals please visit
              // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // Swal.fire(
              //   'Cancelled',
              //   'Your imaginary file is safe :)',
              //   'error'
              // )
            }
          });
        } else {
          let entity2 = this.searchArray(
            value,
            this.state.entityDropdown,
            "id"
          );
          this.setState({
            [name]: value,
            displayText2: entity2.pluralName,
            name2: entity2.name
          });
        }
      }
    } else if (
      name === "lookupFieldReferenceTypeId" &&
      this.state.customFieldType === "Many to Many Lookup"
    ) {
      let entity1 = this.searchArray(value, this.state.entityDropdown, "id");
      this.setState({
        [name]: value,
        displayText: entity1.pluralName,
        name: entity1.name
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  handleRevisionDate = date => {
    this.setState({
      revisionDatePicker: date,
      revisionDate: date.toISOString()
    });
  };

  handleLastDate = date => {
    this.setState({
      lastDatePicker: date,
      lastTestDate: date.toISOString()
    });
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-asset-view">
        {loading && (
          <ScreenLoading />
        )}
        <h4>
          {this.props.match.params.id !== undefined ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}{" "}
          Custom Field
        </h4>
        <Gap size="20px" />
        <Form onSubmit={this.createCustomField}>
          <Row className="panel" form>
            <Col className="panel-header" md={12}>
              System Information
            </Col>
            <Col className="panel-body" md={12}>
              <div className="flex space-betwee">
                <FormGroup className="flex-one mr-10">
                  <Label for="customFieldDataTypeId">Type</Label>
                  <Input
                    type="select"
                    name="customFieldDataTypeId"
                    id="customFieldDataTypeId"
                    value={this.state.customFieldDataTypeId}
                    onChange={this.handleChange}
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.customFieldDataTypeIdDropdown.map((e, key) => {
                      return (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                {this.state.showEntityDropdown ? (
                  !this.state.entityLoader ? (
                    <FormGroup className="flex-one mr-10">
                      <Label for="lookupFieldReferenceTypeId">
                        {this.state.showRelatedEntity ? "Entity 1" : "Entity"}
                      </Label>
                      <Input
                        type="select"
                        name="lookupFieldReferenceTypeId"
                        id="lookupFieldReferenceTypeId"
                        value={this.state.lookupFieldReferenceTypeId}
                        onChange={this.handleChange}
                        disabled={
                          this.state.showRelatedEntity &&
                          this.state.lookupFieldReferenceTypeId !== "" &&
                          this.state.name !== "" &&
                          this.state.autoPopulated
                            ? true
                            : null
                        }
                      >
                        <option value="">-- Vælg --</option>
                        {this.state.entityDropdown.map((e, key) => {
                          return (
                            <option key={e.id} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  ) : (
                    <InlineLoading />
                  )
                ) : (
                  ""
                )}

                {this.state.showEntityDropdown &&
                this.state.showRelatedEntity ? (
                  !this.state.entityLoader ? (
                    <FormGroup className="flex-one mr-10">
                      <Label for="regardingObjectTypeId2">Entity 2</Label>
                      <Input
                        type="select"
                        name="regardingObjectTypeId2"
                        id="regardingObjectTypeId2"
                        value={this.state.regardingObjectTypeId2}
                        onChange={this.handleChange}
                        disabled={this.state.lookupFieldReferenceTypeId === ""}
                      >
                        <option value="">-- Vælg --</option>
                        {this.state.relatedEntityDropdown.map((e, key) => {
                          return (
                            <option key={e.id} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  ) : (
                    <InlineLoading />
                  )
                ) : (
                  ""
                )}

                {this.state.showDisplayType ? (
                  <FormGroup className="flex-one">
                    <Label for="customFieldDisplayTypeId">Display Type</Label>
                    <Input
                      type="select"
                      name="customFieldDisplayTypeId"
                      id="customFieldDisplayTypeId"
                      value={this.state.customFieldDisplayTypeId}
                      onChange={this.handleChange}
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.answerDisplayTypeDropdown.map((e, key) => {
                        return (
                          <option key={e.id} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                ) : (
                  <></>
                )}

                <FormGroup className="align-right">
                  <Label for="">
                    <TranslatableText translationKey="custom_fields.required" />
                    ?
                  </Label>
                  <div
                    onClick={this.isRequired}
                    className={classnames("search-toggle-btn")}
                  >
                    <span
                      className={classnames(
                        "ball",
                        this.state.isRequired ? "toggled" : ""
                      )}
                    />
                  </div>
                </FormGroup>
              </div>
              {this.state.showSystemName ? (
                <FormGroup
                  className={validation.name.isInvalid ? "has-error" : ""}
                >
                  <Label for="name">
                    <TranslatableText translationKey="company.system_name" />
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder=""
                    value={this.state.name}
                    onChange={this.handleChange}
                    disabled={
                      this.props.match.params.id !== undefined ? true : null
                    }
                  />
                  <span className="help-block">{validation.name.message}</span>
                </FormGroup>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          {this.props.match.params.id === undefined ? (
            <Row className="panel" form>
              <Col className="panel-header" md={12}>
                <TranslatableText translationKey="custom_fields.display_information" />
              </Col>
              <Col className="panel-body" md={12}>
                <div className="flex space-between flex-basis-2">
                  <FormGroup
                    className={validation.name.isInvalid ? "has-error" : ""}
                  >
                    <Label for="displayText">
                      {this.state.showRelatedDisplayText
                        ? "Display Text 1"
                        : "Display Text"}
                    </Label>
                    <Input
                      type="text"
                      name="displayText"
                      id="displayText"
                      placeholder=""
                      value={this.state.displayText}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.displayText.message}
                    </span>
                  </FormGroup>
                  {this.state.showRelatedDisplayText ? (
                    <FormGroup className="ml-10 mr-10">
                      <Label for="displayText2">
                        <TranslatableText translationKey="custom_fields.display_text" />
                      </Label>
                      <Input
                        type="text"
                        name="displayText2"
                        id="displayText2"
                        placeholder=""
                        value={this.state.displayText2}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  ) : (
                    ""
                  )}
                  <FormGroup
                    className={validation.language.isInvalid ? "has-error" : ""}
                  >
                    <Label for="language">
                      <TranslatableText translationKey="language" />
                    </Label>
                    <Input
                      type="select"
                      name="language"
                      id="language"
                      value={this.state.language}
                      onChange={this.handleChange}
                      disabled={
                        this.props.match.params.translationId !== undefined
                          ? true
                          : null
                      }
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.languageDropdown.map((e, key) => {
                        return (
                          <option key={key} value={e.value}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                    <span className="help-block">
                      {validation.language.message}
                    </span>
                  </FormGroup>
                </div>
                {this.state.isRequired ? (
                  <FormGroup>
                    <Label for="validationMessage">
                      <TranslatableText translationKey="custom_entities.validation_message" />
                    </Label>
                    <Input
                      type="text"
                      name="validationMessage"
                      id="validationMessage"
                      placeholder=""
                      value={this.state.validationMessage}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row form>
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              <Button>
                {this.props.match.params.id !== undefined ?  <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.languageResources.language_resources,
  user: state.organizations,
  roles: state.userRoles,
  loading: state.organizations.loading,
  error: state.organizations.error
});

export default withRouter(CreateCustomField);
CreateCustomField.contextType = GlobalContext;