import axios from "axios";
import { Config } from "../../../../../../config/Config";
import authService from "../../../../../../components/api-authorization/AuthorizeService";

// Questions API URL
const QUESTIONS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Questions`;

// Questions Translations API URL
const QUESTION_TRANSLATIONS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Questions/Translations`;

const QUESTION_TRANSLATIONS_API_STRING = "QuestionTranslations";
const QUESTION_TRANSLATION_API_STRING = "QuestionTranslation";
const LOOKUPS_API_STRING = `${Config.server_url}:${Config.port}${Config.api_prefix}/LookUps`;

// Create
export const createQuestionAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${QUESTIONS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update
export const updateQuestionAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${QUESTIONS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get By ID
export const getQuestionByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${QUESTIONS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all
export const getQuestionsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${QUESTIONS_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Get all Questions of a Wizard By Panel ID
export const getQuestionsByPaneldAPI = async panelID => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${QUESTIONS_API_URL}/${panelID}/Questions`, { headers: headers })
    .then(({ data }) => data);
};
// Get isQuestionHtml
export const isQuestionHtmlAPI = async questionId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${QUESTIONS_API_URL}/${questionId}/isQuestionHtml`, { headers: headers })
    .then(({ data }) => data);
};

// Get all Questions of a Wizard By Page ID
export const getQuestionsByPageIdAPI = async pageID => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${QUESTIONS_API_URL}/${pageID}/PageWizardQuestions`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get all Questions of a Wizard By Wizard ID
export const getQuestionsByWizardIdAPI = async wizardID => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${QUESTIONS_API_URL}/${wizardID}/WizardQuestions`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteQuestionByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${QUESTIONS_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Translations

// Add Translation for a Question
export const addQuestionTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${QUESTION_TRANSLATIONS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Specific Question Translations
export const getQuestionTranslationsAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${QUESTIONS_API_URL}/${id}/${QUESTION_TRANSLATIONS_API_STRING}`, {
      headers: headers
    })
    .then(res => res);
};

// Update Question Translation API
export const updateQuestionTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${QUESTION_TRANSLATIONS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

export const getQuestionBaseTranslationsByIdAPI = async translationId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${QUESTIONS_API_URL}/${translationId}/QuestionDefaultTranslation`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get Single Translation By ID
export const getSingleTranslationAPI = async translationId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${QUESTIONS_API_URL}/${QUESTION_TRANSLATION_API_STRING}/${translationId}`,
      { headers: headers }
    )
    .then(({ data }) => data);
};

// Get Question Panels
export const getQuestionPanels = async (id, lang) => {
  const token = await authService.getAccessToken();
  var headers = {
    Language: lang,
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${QUESTIONS_API_URL}/${id}/QuestionPanels`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteQuestionTranslationByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${QUESTIONS_API_URL}/DeleteQuestionTranslation`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Lookup - Get Platform entities with out of the box entities for Question creation

export const getPlatformEntitesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${LOOKUPS_API_STRING}/GetQuestionRegardingObjectType`, {
      headers: headers
    })
    .then(({ data }) => data);
};
