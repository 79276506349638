import { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { InlineLoading, ScreenLoading } from "../../components";
import Fields from "../../components/fields/Fields";
import FormValidator from "../../components/validator/FormValidator";
import "../../components/validator/FormValidator.scss";
import { GlobalContext } from "../../contexts/global-context";
import TranslatableText from "../../Language/TranslatableText";
import { getWizardsAPI } from "../admin/views/wizard/wizardAPI";
import { getCustomEntityByIdAPI as getOrganizationCustomEntityByIdAPI } from "../company-admin/views/custom-entities/tenantCustomEntitiesAPI";
import { getRegardingCustomFieldsAPI } from "../company-admin/views/custom-fields/customFieldsCompanyAPI";
import { getUsersAPI } from "../company-admin/views/users/usersAPI";
import {
  createEntityAPI, getEntitiesAPI, getEntityByIdAPI,
  updateEntityAPI
} from "./entityAPI";
import { History } from 'history'

interface IProps {
  match: any, // fix type
  history: History
}
interface IState {
  responsibleRequired: boolean,
  customEntity: object,
  collapse: boolean,
  parentEntityDropdown: any[],
  usersDropdown: any[],
  name: string,
  description: string,
  parentId: string,
  userId: string,
  organizationId: string,
  customFields: any[],
  loading: boolean,
  loader: boolean,
  cuLoader: boolean,
  wizardId: string,
  wizardDropdown: any[],
  validation: {
    isValid: boolean;
  },
  lock: boolean, // fix type
}
type validator = {
  field?: string,
  method?: string,
  validWhen?: boolean,
  message?: any
}
// Global Variables
var customFieldsModel;

class CreateEntity extends Component<IProps, IState> {
  validator: FormValidator;
  submitted: boolean;
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      responsibleRequired: false,
      customEntity: {},
      collapse: false,
      parentEntityDropdown: [],
      usersDropdown: [],
      name: "",
      description: "",
      parentId: "",
      userId: "",
      organizationId: "",
      customFields: [],
      loading: false,
      loader: false,
      cuLoader: false,
      wizardId: "",
      wizardDropdown: [],
      validation: this.validator.valid(),
      lock: false,
    };

    this.submitted = false;
  }
  componentDidMount() {
    this.setState({ loader: true });

    getWizardsAPI()
      .then(res => {
        this.setState({ wizardDropdown: Array.isArray(res) ? res : [] });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
    getOrganizationCustomEntityByIdAPI(
      this.props.match.params.regardingObjectTypeId
    )
      .then(res => {
        this.setState({
          customEntity: res.data,
          wizardId: res.data.wizardId === null ? "" : res.data.wizardId,
          loader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loader: false });
      });

    getEntitiesAPI(this.props.match.params.regardingObjectTypeId)
      .then(res => {
        res.sort((a, b) => {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.setState({ parentEntityDropdown: res });
      })
      .catch(error => {
        if (error.response.status === 403) {
          toast.error(this.context.languages["insufficient_permission"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          });
        } else {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          });
        }
      });

    getUsersAPI().then(res => {
      this.setState({ usersDropdown: Array.isArray(res) ? res : [] });
    });

    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getEntityByIdAPI(this.props.match.params.id)
        .then(res => {
          let self = this;
          this.setState(
            {
              name: res.data.name,
              description: res.data.description,
              parentId: res.data.parentId === null ? "" : res.data.parentId,
              userId: res.data.userId,
              organizationId: res.data.organizationId,
              lock: res.data.lock,
              customFields:
                res.data.customFieldValues === null ||
                  res.data.customFieldValues === undefined
                  ? []
                  : res.data.customFieldValues,
              loading: false
            },
            () => {
              let userCustomFields =
                res.data.customFieldValues === null ||
                  res.data.customFieldValues === undefined
                  ? []
                  : res.data.customFieldValues;
              userCustomFields.forEach(element => {
                if (element.displayTypeCode === 1007) {
                  // @ts-ignore
                  self.setState({
                    [element.id]:
                      element.tagBoxValues === null ? "" : element.tagBoxValues
                  });
                } else {
                  // @ts-ignore
                  self.setState({
                    [element.name]: element.value === null ? "" : element.value
                  });
                }
              });
            }
          );
        })
        .catch(error => {
          if (error.response !== undefined && error.response.status === 403) {
            toast.error(this.context.languages["insufficient_permission"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 2000
            });
          } else {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 2000
            });
          }
        });
    } else {
      this.populateCustomFields(this.props.match.params.regardingObjectTypeId);
    }
  }

  populateCustomFields = regardingObjectTypeId => {
    this.setState({ loading: true, customFields: [] });
    getRegardingCustomFieldsAPI(regardingObjectTypeId)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ customFields: res, loading: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  createEntity = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    let key,
      self = this;

    // Preparing custom fields model
    customFieldsModel = this.state.customFields;
    this.state.customFields.forEach((element, i) => {
      if (element.displayTypeCode === 1007) {
        key = element.id;
        customFieldsModel[i].value = JSON.stringify(
          Array.isArray(self.state[key])
            ? self.state[key].map(row => row.value)
            : []
        );
      } else {
        key = element.name;
        customFieldsModel[i].value = self.state[key];
      }
    });

    const data = {
      name: this.state.name,
      description: this.state.description,
      parentId: this.state.parentId,
      userId: this.state.userId,
      wizardId: this.state.wizardId,
      organizationId: this.context.user.organizations.organizationId,
      customFieldValues: customFieldsModel,
      regardingObjectTypeId: this.props.match.params.regardingObjectTypeId,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : ""
    };

    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        if (
          this.context.userRoles.find(r => r.includes("Developer")) !==
          undefined ||
          (this.context.userRoles.find(r => r.includes("Developer")) ===
            undefined &&
            this.state.userId !== "")
        ) {
          this.setState({ cuLoader: true });
          updateEntityAPI(data)
            .then(res => {
              this.setState(
                {
                  cuLoader: false
                },
                () => {
                  this.goBack();
                }
              );
            })
            .catch(error => {
              if (error.response.status === 403) {
                toast.error(this.context.languages["insufficient_permission"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 2000
                });
              } else {
                toast.error(this.context.languages["api_error"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 2000
                });
              }
              this.setState({ cuLoader: false });
            });
        } else {
          this.setState({
            responsibleRequired: true
          });
        }
      }
    } else {
      if (validation.isValid) {
        if (
          this.context.userRoles.find(r => r.includes("Developer")) !==
          undefined ||
          (this.context.userRoles.find(r => r.includes("Developer")) ===
            undefined &&
            this.state.userId !== "")
        ) {
          this.setState({ cuLoader: true });
          createEntityAPI(data)
            .then(res => {
              this.setState(
                {
                  cuLoader: false
                },
                () => {
                  this.goBack();
                }
              );
            })
            .catch(error => {
              if (error.response.status === 403) {
                toast.error(this.context.languages["insufficient_permission"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 2000
                });
              } else {
                toast.error(this.context.languages["api_error"], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 2000
                });
              }
              this.setState({ cuLoader: false });
            });
        } else {
          this.setState({
            responsibleRequired: true
          });
        }
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    // @ts-ignore
    this.setState({
      [name]: value
    });
  };

  handleTagboxChange = (id: string, selectedOptions: any) => {
    // @ts-ignore
    this.setState({
      [id]: selectedOptions
    });
  };

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  changeValidationMessage = (message: string, event: any) => {
    let msg = message === null ? "This is a required field" : message;
    event.target.setCustomValidity(msg);
  };

  searchArray = (key, array, selector) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i][selector] === key) {
        return array[i];
      }
    }
  };

  render() {
    let field;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    let { loading, loader, customEntity, lock } = this.state;
    return (
      <div className="create-Entitys-view">
        {loading && (
          <ScreenLoading />
        )}

        {loader ? (
          <InlineLoading />
        ) : (
          <h3>
            {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" />
              : <TranslatableText translationKey="create" />}{" "}
            {
              // @ts-ignore
              customEntity.displayName}{" "}
            <TranslatableText translationKey="values" />
          </h3>
        )}

        <Form onSubmit={this.createEntity}>
          <Row form>
            <Col md={this.state.wizardId === "" ? 4 : 3}>
              <FormGroup
                // @ts-ignore
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  <TranslatableText translationKey="name" />
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                  disabled={lock}
                />
                <span className="help-block">{
                  // @ts-ignore
                  validation.name.message}</span>
              </FormGroup>
            </Col>
            <Col md={this.state.wizardId === "" ? 4 : 3}>
              <FormGroup
                className={
                  this.context.userRoles.find(r =>
                    r.includes("Developer")
                  ) === undefined &&
                    this.state.userId === "" &&
                    this.state.responsibleRequired
                    ? "has-error"
                    : "no-error"
                }
              >
                <Label for="userId">
                  <TranslatableText translationKey="responsible" />
                </Label>
                <Input
                  type="select"
                  name="userId"
                  id="userId"
                  value={this.state.userId}
                  onChange={this.handleChange}
                  disabled={lock}
                >
                  <option value="">-- Vælg --</option>
                  {this.state.usersDropdown.map((e, key) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                {this.context.userRoles.find(r =>
                  r.includes("Developer")
                ) === undefined &&
                  this.state.userId === "" &&
                  this.state.responsibleRequired ? (
                  <span className="help-block">
                    <TranslatableText translationKey="requiredField" />
                  </span>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
            <Col md={this.state.wizardId === "" ? 4 : 3}>
              <FormGroup>
                <Label for="parentId">
                  <TranslatableText translationKey="parent_entity" />
                </Label>
                <Input
                  type="select"
                  name="parentId"
                  id="parentId"
                  value={this.state.parentId}
                  onChange={this.handleChange}
                  disabled={lock}
                >
                  <option value="">-- Vælg --</option>
                  {this.state.parentEntityDropdown.map((e, key) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
            {this.state.wizardId === "" ? (
              ""
            ) : (
              <Col md={3}>
                <FormGroup>
                  <Label for="wizardId">
                    <TranslatableText translationKey="wizard" />
                  </Label>
                  <Input
                    type="select"
                    name="wizardId"
                    id="wizardId"
                    value={this.state.wizardId}
                    onChange={this.handleChange}
                    // @ts-ignore
                    disabled={!this.state.customEntity.externalQuestionnaire}
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.wizardDropdown.map((e, key) => {
                      return (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            )}

            <Col md={12}>
              <FormGroup>
                <Label for="description">
                  <TranslatableText translationKey="description" />
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                  disabled={lock}
                />
              </FormGroup>
            </Col>
            <Fields
              customFields={this.state.customFields}
              componentState={this.state}
              handleChange={this.handleChange}
              handleTagboxChange={this.handleTagboxChange}
              changeValidationMessage={this.changeValidationMessage}
            />
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ? <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
// @ts-ignore
export default withRouter(CreateEntity);
CreateEntity.contextType = GlobalContext;