import { faGavel } from "@fortawesome/pro-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../contexts/language-context";
import { ScreenLoading } from "../../../components/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function LawSignUp() {
    const [isLoading, setIsLoading] = useState(true);
    const { languageData } = useLanguage();

    useEffect(() => {
        if (languageData.status === 'success') {
            setIsLoading(false);
        } else if (languageData.status === 'loading') {
            setIsLoading(true);
        }
    }, [languageData.status]);

    if (isLoading) {
        return (
            <div className="center-align">
                <ScreenLoading />
            </div>
        );
    } else {
        return (
            <div className="admin-view">
                <div className={"flex space-between mr-20 accent-line"}>
                    <div className="flex accent-content">
                        <FontAwesomeIcon icon={faGavel} size="5x" />
                        <h4>Digital Jura</h4>
                    </div>
                </div>
                {/* <form>
                <div className={"form-group"}>
                    <label>Example label</label>
                    <input type="text" className={"form-control"} placeholder="Example input">
                </div>
                <div className={"form-group"}>
                    <label>Another label</label>
                    <input type="text" className={"form-control"} placeholder="Another input">
                </div>
                </form> */}
            </div>
        );
    }
}