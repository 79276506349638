import { useQuery } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ApprovalFlowsClient, ApprovalFlowsHistoryClient, CustomEntityValuesClient, GetApprovalFlowProgressResponse, HandleCaught_API_Error_Base, ProcessesClient, UpdateUserApprovalFlowHistoryRequest } from "../../apiClients";
import { InlineLoading, ScreenLoading } from "../../components";
import Gap from "../../components/gap/Gap";
import ProgressWidget from "../../components/progress/ProgressWidget";
import { ApprovalFlowStatus } from "../../config/ApprovalFlowState";
import { ResourceTypes } from "../../config/ResourceTypes";
import { GlobalContext } from "../../contexts/global-context";
import { ISurveyIdentifiers } from "../wizard/REWrapper";
import SurveyWizard from "../wizard/SurveyWizardNew";
import ApprovalButtons from "./components/ApprovalButtons";
import Details from "./components/Details";

import Swal, { SweetAlertResult } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


type Params = {
  wizardId: string,
  regardingObjectId: string,
  regardingObjectTypeId: string,
  approvalId: string,
  dataVersion: string,
  icon: string,
}


export default function EntityApprovalDetails() {
  let { wizardId, regardingObjectTypeId, regardingObjectId, approvalId, dataVersion, icon } = useParams<Params>();
  let history = useHistory();
  let { user, languages } = useContext(GlobalContext);
  const _ApprovalFlowsClient = new ApprovalFlowsClient();
  const _ApprovalFlowsHistoryClient = new ApprovalFlowsHistoryClient();
  const _ProcessesClient = new ProcessesClient();
  const _CustomEntityValuesClient = new CustomEntityValuesClient();
  let actualDataVersion: number = dataVersion ? Number(dataVersion) : 2;
  const ApprovalSwal = withReactContent(Swal)

  const surveyIdentifiers: ISurveyIdentifiers =
  {
    wizardId: wizardId,
    entityId: regardingObjectId,
    regardingObjectTypeId: regardingObjectTypeId,
    questionnaire: ""
  };

  let approval = useQuery(['approval', wizardId], async () => {
    return _ApprovalFlowsClient.approvalFlowsProgressV1(regardingObjectTypeId, regardingObjectId, approvalId, actualDataVersion);
  });

  let entity = useQuery(['entity', regardingObjectId], async () => {
    if (regardingObjectTypeId === ResourceTypes.process) {
      return _ProcessesClient.processes(regardingObjectId);
    } else {
      return _CustomEntityValuesClient.customEntityValuesGet(regardingObjectId);
    }
  });

  let [isLoadingWhilaAction, setIsLoadingWhilaAction] = useState(false);


  const approveFlow = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsLoadingWhilaAction(true);
    let value: SweetAlertResult<any> | undefined = undefined
    if (approval.data?.commentOnApprove) {
      value = await ApprovalSwal.fire({
        input: 'textarea',
        icon: 'question',
        titleText: 'Are you sure that you want to approve?',
        inputPlaceholder: 'Type your comment here...',
        inputAttributes: { 'aria-label': 'Type your comment here' },
        showCancelButton: true,
        confirmButtonColor: 'var(--success)',
        confirmButtonText: '<i class="fas fa-check"></i> '+ languages['approve'] +'!',
        title: <p>{languages['approve']}?</p>,
        inputValidator: (swalValue) => {
          return new Promise((resolve) => {
            if (swalValue.length === 0) {
              resolve('You need to comment on this approval.')
            } else {
              resolve(null)
            }
          })
        },
      });
    } else {
      value = await ApprovalSwal.fire({
        icon: 'question',
        titleText: 'Are you sure that you want to approve?',
        showCancelButton: true,
        confirmButtonColor: 'var(--success)',
        confirmButtonText: '<i class="fas fa-check"></i> '+ languages['approve'] +'!',
        title: <p>{languages['approve']}?</p>
      });
    }

    if (value.isDismissed) {
      setIsLoadingWhilaAction(false);
      return;
    }

    let data = new UpdateUserApprovalFlowHistoryRequest({
      id: approvalId,
      regardingObjectId: regardingObjectId,
      comments: value?.value ?? ""
    });

    if (dataVersion === "2") {
      await _ApprovalFlowsHistoryClient.approveApprovalFlow(data)
        .then(() => { history.goBack(); })
        .catch(HandleCaught_API_Error_Base);
    } else {
      await _ApprovalFlowsHistoryClient.approveApprovalFlowV1(data)
        .then(() => { history.goBack(); })
        .catch(HandleCaught_API_Error_Base);
    }
  };

  const rejectFlow = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsLoadingWhilaAction(true);
    
    let value: SweetAlertResult<any> | undefined = undefined
    if (approval.data?.commentOnReject) {
      value = await ApprovalSwal.fire({
        input: 'textarea',
        icon: 'question',
        titleText: 'Are you sure that you want to reject?',
        inputPlaceholder: 'Type your comment here...',
        inputAttributes: { 'aria-label': 'Type your comment here' },
        showCancelButton: true,
        confirmButtonColor: 'var(--danger)',
        confirmButtonText: '<i class="fas fa-times"></i> '+ languages['reject'] +'!',
        title: <p>{languages['reject']}?</p>,
        inputValidator: (swalValue) => {
          return new Promise((resolve) => {
            if (swalValue.length === 0) {
              resolve('You need to comment on this approval.')
            } else {
              resolve(null)
            }
          })
        },
      });
    } else {
      value = await ApprovalSwal.fire({
        icon: 'question',
        titleText: 'Are you sure that you want to reject?',
        showCancelButton: true,
        confirmButtonColor: 'var(--danger)',
        confirmButtonText: '<i class="fas fa-times"></i> '+ languages['reject'] +'!',
        title: <p>{languages['reject']}?</p>
      });
    }

    if (value.isDismissed) {
      setIsLoadingWhilaAction(false);
      return;
    }

    let data = new UpdateUserApprovalFlowHistoryRequest({
      id: approvalId,
      regardingObjectId: regardingObjectId,
      comments: value?.value ?? ""
    });

    if (dataVersion === "2") {
      await _ApprovalFlowsHistoryClient.cancelApprovalFlow(data)
        .then(() => { history.goBack(); })
        .catch(HandleCaught_API_Error_Base);
    } else {
      await _ApprovalFlowsHistoryClient.cancelApprovalFlowV1(data)
        .then(() => { history.goBack(); })
        .catch(HandleCaught_API_Error_Base);
    }
  };

  const userIsAllwodToApprove = (approval: GetApprovalFlowProgressResponse) => {
    // Is it the correct user?
    if (approval.approverId !== user.organizations.userId) {
      return false;
    }
    // Is it the correct dataversion?
    if (actualDataVersion !== 2) {
      return false;
    }
    switch (approval.approvalFlowState) {
      // case ApprovalFlowStatus.None:
      // case ApprovalFlowStatus.Submit:
      // case ApprovalFlowStatus.Running:
      case ApprovalFlowStatus.Approved:
      case ApprovalFlowStatus.Rejected:
      case ApprovalFlowStatus.Cancel:
      case ApprovalFlowStatus.ApproveByOther:
      case ApprovalFlowStatus.RejectedByOther:
      case ApprovalFlowStatus.CancelByOther:
        return false;
    }
    return true;
  }

  return (
    <div className="create-notification-view">
      {approval.isLoading && (
        <ScreenLoading />
      )}
      {(wizardId !== undefined && approval.isSuccess) ? (
        <ProgressWidget
          approvers={approval.data.approvalProgresses!}
          approvalOrder={approval.data.approvalOrder}
        />
      ) : (
        <Gap size="20px" />
      )}

      {(!entity.isSuccess || !approval.isSuccess) ? (
        <InlineLoading />
      ) : (
        <Details
          icon={icon}
          entityName={entity.data.name ?? ""}
          approvalName={approval.data.name ?? ""}
          approvalDescription={approval.data.description ?? ""}
        />
      )}

      {(!approval.isSuccess) ? (
        <InlineLoading />
      ) : (
        <>
          {userIsAllwodToApprove(approval.data) && (
            <ApprovalButtons
              approvalFlowState={approval.data.approvalFlowState}
              loading={isLoadingWhilaAction}
              approveFlow={approveFlow}
              rejectFlow={rejectFlow}
            />
          )}
          {actualDataVersion !== 2 && (
            <h3>This is an older version of the approval flow. Please use the approvals page to approve or reject.</h3>
          )}
        </>
      )}

      {(wizardId !== null || wizardId !== "null") && (
        <Row>
          <Col>
            <SurveyWizard
              // @ts-ignore
              surveyIdentifiers={surveyIdentifiers}
              // @ts-ignore
              mode="display" />
          </Col>
        </Row>
      )}
    </div>
  );
}