import axios from "axios";
import { Config } from "../../../../../../config/Config";
import authService from "../../../../../../components/api-authorization/AuthorizeService";

// AnswerOptionSets API URL
const AnswerOptionSets_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/AnswerOptionSets`;

const GetAllAnswerDisplayTypeResponse = "GetAllAnswerDisplayTypeResponse";
const GetAllAnswerTypeResponse = "GetAllAnswerTypeResponse";

// Create
export const createAnswerOptionSetAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${AnswerOptionSets_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update
export const updateAnswerOptionSetAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${AnswerOptionSets_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get By ID
export const getAnswerOptionSetByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${AnswerOptionSets_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all
export const getAnswerOptionSetsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${AnswerOptionSets_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteAnswerOptionSetByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${AnswerOptionSets_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Get all Answer Type data..
export const getAnswerTypesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${AnswerOptionSets_API_URL}/${GetAllAnswerTypeResponse}`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get all Answer Display Type data..
export const getAnswerDisplayTypesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${AnswerOptionSets_API_URL}/${GetAllAnswerDisplayTypeResponse}`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Get all options against AnswerOptionSets
export const getAllOptionsForAnswerOptionSetByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${AnswerOptionSets_API_URL}/${id}/AnswerOptionSetValues`, {
      headers: headers
    })
    .then(({ data }) => data);
};

/* ******************************* */

// AnswerOptionSetValues API URL
const AnswerOptionSetValues_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/AnswerOptionSetValues`;

// Create
export const createAnswerOptionSetValuesAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${AnswerOptionSetValues_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update
export const updateAnswerOptionSetValuesAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${AnswerOptionSetValues_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get By ID
export const getAnswerOptionSetValuesByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${AnswerOptionSetValues_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all
export const getAnswerOptionSetValuesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${AnswerOptionSetValues_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteAnswerOptionSetValuesByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${AnswerOptionSetValues_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Translations
export const getTranslationsAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${AnswerOptionSetValues_API_URL}/${id}/AnswerOptionSetValueTranslations`,
      { headers: headers }
    )
    .then(({ data }) => data);
};

// Add Translation for a Answer option set values
export const addAnswerOptionSetValuesTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${AnswerOptionSetValues_API_URL}/Translations`, data, {
      headers: headers
    })
    .then(res => res);
};

// Get Specific AnswerOptionSetValue Translations
export const getAnswerOptionSetValueTranslationsAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${AnswerOptionSetValues_API_URL}/${id}/AnswerOptionSetValueTranslations`,
      { headers: headers }
    )
    .then(res => res);
};

// Update AnswerOptionSetValues Translation API
export const updateAnswerOptionSetValuesTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${AnswerOptionSetValues_API_URL}/Translations`, data, {
      headers: headers
    })
    .then(res => res);
};

// Get Single Translation By ID
export const getSingleTranslationAPI = async translationId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${AnswerOptionSetValues_API_URL}/AnswerOptionSetValueTranslation/${translationId}`,
      { headers: headers }
    )
    .then(({ data }) => data);
};

// Delete By ID
export const deleteAnswerOptionSetTranslationByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(
      `${AnswerOptionSetValues_API_URL}/DeleteAnswerOptionSetValueTranslation`,
      {
        data: {
          id: id,
          userId: userId
        },
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    .then(res => res);
};
