import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  createReportTemplateAPI,
  getReportTemplateByIdAPI,
  updateReportTemplateAPI
} from "./companyWizardAPI";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import { SyncLoader, PulseLoader } from "react-spinners";
import { Config } from "../../../../config/Config";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../components";



class CreateCompanyWizardTemplate extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
      },
      {
        field: "description",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="name_empty" />
      }
    ]);

    this.state = {
      name: "",
      description: "",
      language: "",
      loading: false,
      cuLoader: false,
      languageDropdown: Config.languages,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getReportTemplateByIdAPI(this.props.match.params.id)
        .then(res => {
          this.setState({
            name: res.name,
            description: res.description,
            language: res.language,
            loading: false
          });
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    }
  }
  createWizardTemplate = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    const data = {
      name: this.state.name,
      description: this.state.description,
      language: this.state.language,
      wizardId:
        this.props.match.params.wizardId !== undefined
          ? this.props.match.params.wizardId
          : "",
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      organizationId:
        this.props.match.params.organizationId !== undefined
          ? this.props.match.params.organizationId
          : "",
      userId: this.context.user.organizations.userId
    };

    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("language", data.language);
    formData.append("wizardId", data.wizardId);
    formData.append("id", data.id);
    formData.append("organizationId", data.organizationId);
    formData.append("userId", data.userId);
    formData.append("file", this.state.file);

    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateReportTemplateAPI(formData)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createReportTemplateAPI(formData)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (target.type === "file") {
      this.setState({
        file: target.files[0]
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { loading } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-wizard-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" />
            : <TranslatableText translationKey="create" />}{" "}
          <TranslatableText translationKey="wizard_template" />
        </h3>
        <Form onSubmit={this.createWizardTemplate}>
          <Row form>
            <Col md={6}>
              <FormGroup
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  <TranslatableText translationKey="name" />
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.name.message}</span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={validation.description.isInvalid ? "has-error" : ""}
              >
                <Label for="description">
                  <TranslatableText translationKey="description" />
                </Label>
                <Input
                  type="text"
                  name="description"
                  id="description"
                  placeholder=""
                  value={this.state.description}
                  onChange={this.handleChange}
                />
                <span className="help-block">
                  {validation.description.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={validation.language.isInvalid ? "has-error" : ""}
              >
                <Label for="language">
                  <TranslatableText translationKey="language" />
                </Label>
                <Input
                  type="select"
                  name="language"
                  id="language"
                  value={this.state.language}
                  onChange={this.handleChange}
                >
                  <option value="">-- Vælg --</option>
                  {this.state.languageDropdown.map((e, key) => {
                    return (
                      <option key={key} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                <span className="help-block">
                  {validation.language.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="file">
                  <TranslatableText translationKey="upload_template" />
                </Label>
                <Input
                  className="attachment-input"
                  type="file"
                  name="file"
                  id="file"
                  onChange={this.handleChange}
                  disabled={
                    this.props.match.params.id !== undefined ? true : null
                  }
                />
              </FormGroup>
            </Col>
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ? <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateCompanyWizardTemplate);
CreateCompanyWizardTemplate.contextType = GlobalContext;
