import React from "react";
import { Switch, Route } from "react-router-dom";
import TenantCustomEntities from "./TenantCustomEntities";
import TenantCustomEntityDetails from "./TenantCustomEntityDetails";
import CreateTenantCustomEntity from "./CreateTenantCustomEntity";
import { withRouter } from "react-router-dom";
import AddTenantCustomEntityTranslation from "./AddTenantCustomEntityTranslation";

let TenantCustomEntitiesRouter = props => {
  return (
    <div className="parent-custom-fields-view">
      <Switch>
        <Route
          path={`${props.match.path}/translation/add/:id`}
          render={props => <AddTenantCustomEntityTranslation {...props} />}
        />
        <Route
          path={`${props.match.path}/translation/update/:id/:translationId`}
          render={props => <AddTenantCustomEntityTranslation {...props} />}
        />
        <Route
          path={`${props.match.path}/create/:organizationId`}
          render={props => <CreateTenantCustomEntity {...props} />}
        />
        <Route
          path={`${props.match.path}/create`}
          render={props => <CreateTenantCustomEntity {...props} />}
        />
        <Route
          path={`${props.match.path}/update/:id`}
          render={props => <CreateTenantCustomEntity {...props} />}
        />
        <Route
          path={`${props.match.path}/details/:id/:organizationId`}
          render={props => <TenantCustomEntityDetails {...props} />}
        />
        <Route
          path="/company-admin/custom-entities"
          render={props => <TenantCustomEntities {...props} />}
        />
      </Switch>
    </div>
  );
};

export default withRouter(TenantCustomEntitiesRouter);