import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { icons } from "../../../../components/icons/icons";
import {
  createSectionAPI,
  getSectionByIdAPI,
  updateSectionAPI
} from "./companySectionsAPI";
import Select from "react-select";
import FormValidator from "../../../../components/validator/FormValidator";
import "../../../../components/validator/FormValidator.scss";
import TranslatableText from "../../../../Language/TranslatableText";
import { toast } from "react-toastify";
import { getEntityCustomFieldsAPI } from "../../../admin/views/custom-fields/customFieldsAPI";
import Fields from "../../../../components/fields/Fields";
import { Config } from "../../../../config/Config";
import { GlobalContext } from "../../../../contexts/global-context";
import { ScreenLoading, InlineLoading } from "../../../../components";


// Global Variables
var customFieldsModel;

class CreateSection extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "selectedOption",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "order",
        method: "isEmpty",
        validWhen: false,
        //Todo: Add Properkey for message

        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      name: "",
      icon: "",
      order: "",
      options: [],
      selectedOption: "",
      customFields: [],
      nameShort: "",
      nameLong: "",
      descriptionShort: "",
      descriptionLong: "",
      language: this.context?.user.organizations.defaultLanguage,
      languageDropdown: Config.languages,
      loading: false,
      cuLoader: false,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }
  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
      getSectionByIdAPI(this.props.match.params.id)
        .then(res => {
          let self = this;
          var selected = [
            {
              label: (
                <span className="icon-row">
                  <i className={`fas fa-${res.data.icon}`}></i> {res.data.icon}
                </span>
              ),
              value: res.data.icon
            }
          ];
          this.setState(
            {
              name: res.data.name,
              icon: res.data.icon,
              order: res.data.order,
              selectedOption: selected,
              customFields:
                res.data.customFieldValues === null ||
                  res.data.customFieldValues === undefined
                  ? []
                  : res.data.customFieldValues,
              loading: false,
              descriptionShort: "0", // bypass validations incase of update since this field is not available to update
              language: this.context.user.organizations.defaultLanguage // bypass validations incase of update since this field is not available to update
            },
            () => {
              let userCustomFields =
                res.data.customFieldValues === null ||
                  res.data.customFieldValues === undefined
                  ? []
                  : res.data.customFieldValues;
              userCustomFields.forEach(element => {
                if (element.displayTypeCode === 1007) {
                  self.setState({
                    [element.id]:
                      element.tagBoxValues === null ? "" : element.tagBoxValues
                  });
                } else {
                  self.setState({
                    [element.name]: element.value === null ? "" : element.value
                  });
                }
              });
            }
          );
        })
        .catch(error => {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
          });
          this.setState({ loading: false });
        });
    } else {
      this.populateCustomFields(1002);
    }

    var options = icons.map((icon, key) => {
      var pair = {
        label: (
          <span className="icon-row">
            <i className={icon}></i> {icon.slice(7)}
          </span>
        ),
        value: icon.slice(7)
      };
      return pair;
    });
    let temp = options.filter((val) => { return val.value == "user" })
    this.handleSelectChange(temp);

    this.setState({ options: options });
  }

  populateCustomFields = code => {
    this.setState({ loading: true, customFields: [] });
    getEntityCustomFieldsAPI(code)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ customFields: res, loading: false });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ loading: false });
      });
  };

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption: "" }, () => {
      this.setState({
        icon:
          selectedOption === null || selectedOption === undefined
            ? ""
            : selectedOption.value,
        selectedOption: selectedOption === null ? "" : selectedOption
      });
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  createCompanySection = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    let key,
      self = this;

    // Preparing custom fields model
    customFieldsModel = this.state.customFields;
    this.state.customFields.forEach((element, i) => {
      if (element.displayTypeCode === 1007) {
        key = element.id;
        customFieldsModel[i].value = JSON.stringify(
          Array.isArray(self.state[key])
            ? self.state[key].map(row => row.value)
            : []
        );
      } else {
        key = element.name;
        customFieldsModel[i].value = self.state[key];
      }
    });

    const data = {
      name: this.state.name,
      icon: this.state.icon,
      order: this.state.order,
      nameShort: this.state.name,
      descriptionShort: this.state.descriptionShort,
      language: this.state.language,
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      customFieldValues: customFieldsModel,
      organizationId: this.context.user.organizations.organizationId,
      userId: this.context.user.organizations.userId
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateSectionAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            if (error.response.status === 403) {
              toast.error(this.context.languages["insufficient_permission"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 2000
              });
            } else {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 2000
              });
            }
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createSectionAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            if (error.response.status === 403) {
              toast.error(this.context.languages["insufficient_permission"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 2000
              });
            } else {
              toast.error(this.context.languages["api_error"], {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 2000
              });
            }
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleTagboxChange = (id, selectedOptions) => {
    this.setState({
      [id]: selectedOptions
    });
  };

  changeValidationMessage = (message, event) => {
    let msg = message === null ? "This is a required field" : message;
    event.target.setCustomValidity(msg);
  };

  render() {
    let text, radio, field;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    let { loading } = this.state;
    return (
      <div className="create-section-view">
        {loading && (
          <ScreenLoading />
        )}
        <h3>
          {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" />
            : <TranslatableText translationKey="create" />}{" "}
          Section
        </h3>
        <Form onSubmit={this.createCompanySection}>
          <Row form>
            <Col md={6}>
              <FormGroup
                className={validation.name.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  <TranslatableText translationKey="name" />
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.name.message}</span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={
                  validation.selectedOption.isInvalid ? "has-error" : ""
                }
              >
                <Label for="icon">
                  <TranslatableText translationKey="icon" />
                </Label>
                <Select
                  value={
                    this.state.selectedOption === ""
                      ? null
                      : this.state.selectedOption
                  }
                  className="icon-selectbox"
                  options={this.state.options}
                  onChange={this.handleSelectChange}
                />
                <span className="help-block">
                  {validation.selectedOption.message}
                </span>
              </FormGroup>
            </Col>
            <Col md={this.props.match.params.id !== undefined ? 12 : 6}>
              <FormGroup
                className={validation.order.isInvalid ? "has-error" : ""}
              >
                <Label for="name">
                  <TranslatableText translationKey="page.order" />
                </Label>
                <Input
                  type="number"
                  name="order"
                  id="order"
                  placeholder=""
                  value={this.state.order}
                  onChange={this.handleChange}
                />
                <span className="help-block">{validation.order.message}</span>
              </FormGroup>
            </Col>
            {this.props.match.params.id === undefined ? (
              <Col md={6}>
                <FormGroup
                  className={validation.language.isInvalid ? "has-error" : ""}
                >
                  <Label for="language">
                    <TranslatableText translationKey="language" />
                  </Label>
                  <Input
                    type="select"
                    name="language"
                    id="language"
                    value={this.state.language}
                    onChange={this.handleChange}
                    disabled={
                      this.props.match.params.translationId !== undefined
                        ? true
                        : null
                    }
                  >
                    <option value="">-- Vælg --</option>
                    {this.state.languageDropdown.map((e, key) => {
                      return (
                        <option key={key} value={e.value}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Input>
                  <span className="help-block">
                    {validation.language.message}
                  </span>
                </FormGroup>
              </Col>
            ) : (
              <></>
            )}
            {this.props.match.params.id === undefined ? (
              <Col md={12}>
                <FormGroup>
                  <Label for="descriptionShort">
                    <TranslatableText translationKey="description" />
                  </Label>
                  <Input
                    type="textarea"
                    name="descriptionShort"
                    id="descriptionShort"
                    placeholder=""
                    value={this.state.descriptionShort}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            ) : (
              <></>
            )}
            <Fields
              customFields={this.state.customFields}
              componentState={this.state}
              handleChange={this.handleChange}
              handleTagboxChange={this.handleTagboxChange}
              changeValidationMessage={this.changeValidationMessage}
            />
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ? <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateSection);
CreateSection.contextType = GlobalContext;