import {
  faInfoCircle,
  faPencilAlt
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import { RiskAssesmentClient, RiskAssessmentModel } from "../../../apiClients";
import HandleCaught_API_Error from "../../../apiClients/api_caughtError";
import { InlineLoading } from "../../../components";
import Accent from "../../../components/accent/Accent";
import TranslatableText from "../../../Language/TranslatableText";
import RiskChart from "./RiskChart";
import "./riskAssesment.scss";

function color(value: number) {
  switch (value) {
    case 1:
      return "#0CC964";
    case 2:
      return "#ffc107";
    case 3:
      return "#fd7e14";
    case 4:
      return "#dc3545";
    default:
      return "";
  }
}
function headLines(value: number) {
  switch (value) {
    case 0:
      return <TranslatableText translationKey={"dpo.risk.risk_High"} />;
    case 1:
      return <TranslatableText translationKey={"dpo.risk.risk_MediumHigh"} />;
    case 2:
      return <TranslatableText translationKey={"dpo.risk.risk_Medium"} />;
    case 3:
      return <TranslatableText translationKey={"dpo.risk.risk_Low"} />;
    default:
      return value;
  }
}

function calculateRiskGroups(riskData: RiskAssessmentModel[] | undefined) {
  if (riskData === undefined) {
    return [];
  }
  return [
    {
      riskLevel: 0,
      processes: riskData?.filter(proc => proc.criticality + proc.vulnerability >= 8)
        .flatMap(p => p.processes).sort(a => a!.combinedAssessment)
    },
    {
      riskLevel: 1,
      processes: riskData?.filter(proc => proc.criticality + proc.vulnerability >= 4 && proc.criticality + proc.vulnerability <= 7)
        .flatMap(p => p.processes).sort(a => a!.combinedAssessment)
    },
    {
      riskLevel: 2,
      processes: riskData?.filter(proc => proc.criticality + proc.vulnerability > 2 && proc.criticality + proc.vulnerability < 4)
        .flatMap(p => p.processes).sort(a => a!.combinedAssessment)
    },
    {
      riskLevel: 3,
      processes: riskData?.filter(proc => proc.criticality + proc.vulnerability <= 2)
        .flatMap(p => p.processes).sort(a => a!.combinedAssessment)
    }
  ];
}

export default function RiskAssesment() {
  const _RiskAssesmentClient = new RiskAssesmentClient();
  const { isLoading, isError, data, error } = useQuery(['riskAssesments'], async () => {
    return _RiskAssesmentClient.getRiskAssesments();
  });
  if (isError) {
    HandleCaught_API_Error(error);
  }
  return (
    <div className="center-align">
      <h1><TranslatableText translationKey="dpo.risk.Header" /></h1>
      {isLoading && (<InlineLoading />)}
      <RiskChart data={data} />
      <div className="dpoa-RiskList">
        {calculateRiskGroups(data).map((riskGroup, index) => (

          <div key={index} className="dpoa-Risk mb-lg-5">
            <Accent>
              {headLines(riskGroup.riskLevel)}
            </Accent>

            <table className="table table-striped table-hover table-sm table-borderless">
              <thead>
                <tr>
                  <th><TranslatableText translationKey="dpo.risk.Process" /></th>
                  <th className="center" style={{ width: '10%' }}><TranslatableText translationKey="dpo.risk.Probability" /></th>
                  <th className="center" style={{ width: '10%' }}><TranslatableText translationKey="dpo.risk.Impact" /></th>
                  <th className="right" style={{ width: '5%' }}><TranslatableText translationKey="actions" /></th>
                </tr>
              </thead>
              <tbody>
                {riskGroup.processes.map((process) => (
                  <tr key={process?.id}>
                    <td align="left">{process?.name}</td>
                    {/* <td className="risk" align="center">{process?.probability}</td> */}
                    <td className="risk" style={{ backgroundColor: color(process?.probability!) }} align="center">{process?.probability}</td>
                    <td className="risk" style={{ backgroundColor: color(process?.impact!) }} align="center">{process?.impact}</td>
                    <td align="right">
                      <div className="actions">
                        <NavLink tag={Link} to={`/processes/details/${process?.id}`}>
                          <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                        </NavLink>
                        <NavLink
                          tag={Link}
                          to={`/processes/wizard/${process?.wizardId}/${process?.id}/${process?.regardingObjectTypeId}/${"5e6ab2fa-7044-493c-35ad-08d7db8d4755"}`}
                        >
                          <Tippy placement="top" content={<TranslatableText translationKey="Edit" />}>
                            <span>
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </span>
                          </Tippy>
                        </NavLink>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
}
