import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Sections API URL
const ADMIN_SECTION_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Sections`;

// Section Translations API URL
const ADMIN_SECTION_TRANSLATION_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Sections/Translations`;

const SECTION_TRANSLATIONS_API_STRING = "SectionTranslations";
const SECTION_TRANSLATION_API_STRING = "SectionTranslation";

// Create Section API
export const createSectionAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${ADMIN_SECTION_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update Section API
export const updateSectionAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${ADMIN_SECTION_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Section By ID
export const getSectionByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ADMIN_SECTION_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all Departments
export const getSections = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ADMIN_SECTION_API_URL}/GetAllOrganizationSections`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteSectionByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${ADMIN_SECTION_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Get Translations against an ID
export const getTranslations = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ADMIN_SECTION_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Add Translation for a Section
export const addSectionTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${ADMIN_SECTION_TRANSLATION_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Specific Section Translations
export const getSectionTranslationsAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ADMIN_SECTION_API_URL}/${id}/${SECTION_TRANSLATIONS_API_STRING}`, {
      headers: headers
    })
    .then(res => res);
};

// Update Section Translation API
export const updateSectionTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${ADMIN_SECTION_TRANSLATION_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Single Translation By ID
export const getSingleTranslationAPI = async translationId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(
      `${ADMIN_SECTION_API_URL}/${SECTION_TRANSLATION_API_STRING}/${translationId}`,
      { headers: headers }
    )
    .then(({ data }) => data);
};

// Delete By ID
export const deleteCompanySectionTranslationByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${ADMIN_SECTION_API_URL}/DeleteSectionTranslation`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Translate Section
export const translateSectionAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${ADMIN_SECTION_API_URL}/TranslateSection`, data, {
      headers: headers
    })
    .then(res => res);
};

// Base Translation
export const getSectionBaseTranslationsByIdAPI = async sectionId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${ADMIN_SECTION_API_URL}/${sectionId}/SectionDefaultTranslation`, {
      headers: headers
    })
    .then(({ data }) => data);
};
