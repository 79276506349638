import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import TranslatableText from "../../../../Language/TranslatableText";
import Select from "react-select";

let PartnerInformation = ({
  componentState,
  handleChange,
  handleMultiTagboxChange,
  handleSelectChange,
  validation,
  renderAccountRepresentative,
  renderCompanies,
  partnerId,
  companyId,
  editMode
}) => {
  return (
    <Row form>
      <Col className="multiselect-container" md={12}>
        <h4>Partner</h4>
        <FormGroup
          className={validation.partnerId.isInvalid ? "has-error" : ""}
        >
          <Label for="partnerId">
            <TranslatableText translationKey="company.partner_as_company" />
          </Label>
          <Input
            type="select"
            name="partnerId"
            id="partnerId"
            value={componentState.partnerId}
            onChange={e => handleChange(null, e)}
            // disabled={partnerId !== undefined ? true : null}
            disabled={
              componentState.partnerId !== "" && partnerId !== undefined
                ? true
                : null
            }
          >
            <option value="">-- Vælg --</option>
            {componentState.partnersDropDown.map((e, key) => {
              return (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              );
            })}
          </Input>
          <span className="help-block">{validation.partnerId.message}</span>
        </FormGroup>

        {renderCompanies ? (
          <FormGroup
            className={validation.companyId.isInvalid ? "has-error" : ""}
          >
            <Label for="companyId">
              <TranslatableText translationKey="company" />
            </Label>
            <Input
              type="select"
              name="companyId"
              id="companyId"
              value={componentState.companyId}
              onChange={e => handleChange(null, e)}
              disabled={
                componentState.companyId !== "" &&
                companyId !== undefined &&
                !editMode
                  ? true
                  : null
              }
              //   disabled={companyId !== undefined ? true : null}
            >
              <option value="">-- Vælg --</option>
              {componentState.companiesDropDown.map((e, key) => {
                return (
                  <option key={e.id} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </Input>
            <span className="help-block">{validation.companyId.message}</span>
          </FormGroup>
        ) : (
          <></>
        )}

        {renderAccountRepresentative ? (
          <FormGroup>
            <Label for="accountRepresentatives">Account Representative</Label>
            <Select
              isMulti
              value={
                componentState.selectedAccountRepresentatives === ""
                  ? null
                  : componentState.selectedAccountRepresentatives
              }
              className="user-selectbox"
              options={componentState.accountRepresentativeDropdown}
              onChange={selectedOption =>
                handleMultiTagboxChange(
                  "accountRepresentatives",
                  "selectedAccountRepresentatives",
                  selectedOption
                )
              }
              hideSelectedOptions={true}
            />
          </FormGroup>
        ) : (
          <></>
        )}

        <FormGroup
          className={validation.selectedOption.isInvalid ? "has-error" : ""}
        >
          <Label for="sysname">
            <TranslatableText translationKey="company.system_names" />
          </Label>
          <Select
            value={
              componentState.selectedOption === ""
                ? null
                : componentState.selectedOption
            }
            className="sysname-selectbox"
            options={componentState.allSystemIDs}
            onChange={handleSelectChange}
            placeholderButtonLabel="Select Systems"
            hideSelectedOptions={false}
          />
          <span className="help-block">
            {validation.selectedOption.message}
          </span>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default React.memo(PartnerInformation);
