import React from "react";
import { toast } from "react-toastify";
import TranslatableText from "../Language/TranslatableText";

export default function HandleCaught_API_Error(error: any) {
    if (error?.response?.status === 403) {
        toast.error(<TranslatableText translationKey="insufficient_permission" />);
    } else {
        toast.error(<TranslatableText translationKey="api_error" />);
    }
    console.debug(error);
}
export const HandleCaught_API_Error_Base = (error: any) => {
    HandleCaught_API_Error(error);
};