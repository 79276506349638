import "./resources/scss/common.scss";
import "react-toastify/dist/ReactToastify.min.css";
import 'tippy.js/dist/tippy.css';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./components/auth0-extensions/auth0-provider-with-history";

import axios from "axios";
import TelemetryProvider from './components/appInsights/telemetry-provider';
import { getAppInsights } from './components/appInsights/TelemetryService';
import FortifyApp from "./FortifyApp";
import { SignUp } from "./views/sign-up/sign-up";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;

const rootElement = document.getElementById("advisorRoot");
axios.defaults.headers.post["Content-Type"] = "application/json";

/// External Questionaire
let params = (new URL(window.location.toString())).searchParams;
let questionnaire = params.get("questionnaire");

if (questionnaire) {
    axios.defaults.headers.common['Questionnaire'] = questionnaire;
}
var pathParts = window.location.pathname.split('/').filter(Boolean)
if (pathParts[0] && pathParts[0] === "questionnaires" && pathParts[1]) {
    axios.defaults.headers.common['Questionnaire'] = pathParts[1];
}

// LAW Signup
if (window.location.toString().indexOf('/signUp/') > 0) {
    ReactDOM.render(
        <Router basename={baseUrl}>
            <SignUp />
        </Router>,
        rootElement);
} else {
    ReactDOM.render(
        <Router basename={baseUrl} getUserConfirmation={() => { /* Empty callback to block the default browser prompt */ }}>
            <Auth0ProviderWithHistory>
                <TelemetryProvider connectionString={""}
                    after={() => { let appInsights = getAppInsights() }}>
                    <FortifyApp />
                </TelemetryProvider>
            </Auth0ProviderWithHistory>
        </Router>,
        rootElement);
}