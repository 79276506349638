import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { faEdit, faLink, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import classnames from "classnames";
import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button, Col, Form,
  FormGroup, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalHeader, NavLink, Row
} from "reactstrap";
import Swal from "sweetalert2";
import { InlineLoading, ScreenLoading } from "../../../../../../components";
import Accent from "../../../../../../components/accent/Accent";
import FormValidator from "../../../../../../components/validator/FormValidator";
import "../../../../../../components/validator/FormValidator.scss";
import { Config } from "../../../../../../config/Config";
import { GlobalContext } from "../../../../../../contexts/global-context";
import TranslatableText from "../../../../../../Language/TranslatableText";
import { getBooleanCustomFieldsAPI } from "../../../../../company-admin/views/custom-fields/customFieldsCompanyAPI";
import { getEntitiesAPI } from "../../../../../entity/entityAPI";
import { getWizardEntitiesAPI, getWizardsAPI } from "../../wizardAPI";
import { getAnswerOptionSetsAPI } from "../answeroptionset/answeroptionsetAPI";
import ConditionsBuilder from "../common/conditionsBuilder/ConditionsBuilder";
import { getPanelsAPI } from "../panels/panelsAPI";
import Editor from "./editor/Editor";
import {
  createQuestionAPI, getPlatformEntitesAPI, getQuestionByIdAPI, getQuestionsByPaneldAPI, getQuestionsByWizardIdAPI, updateQuestionAPI
} from "./questionsAPI";



let conditionsArray = [];

class CreateQuestion extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "order",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "answerOptionSetId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "panelId",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "title",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      },
      {
        field: "language",
        method: "isEmpty",
        validWhen: false,
        message: <TranslatableText translationKey="field_empty" />
      }
    ]);

    this.state = {
      isQuestionHtml: false,
      groupId: -1,
      moduleConditions: [],
      groupOperator: "and",
      relationToWizardConditionOperator: "and",
      answerOptionsDropdown: [],
      regardingObjectTypeIdDropdown: [],
      panelsDropdown: [],
      questionDropdown: [],
      expressionQuestionDropdown: [],
      expressionQuestion: "",
      expressionQuestionsModal: false,
      regardingObjectTypeId: "",
      question: "",
      questionCode: "",
      name: "",
      order: "",
      condition: "",
      answerOptionSetId: "",
      panelId: "",
      questionsModal: false,
      isExpression: false,
      showEntity: false,
      showDefaultValue: false,
      showReadOnly: false,
      defaultValue: "",
      readOnly: false,
      expression: "",
      type: "",
      isRequired: false,
      loading: false,
      cuLoader: false,
      fetchingModuleRecords: false,
      fetchingCustomFields: false,
      conditions: [],
      columns: [
        {
          dataField: "moduleName",
          text: "Module",
          sort: true
        },
        {
          dataField: "entityRecordName",
          text: "Entity",
          sort: true
        },
        {
          dataField: "customFieldName",
          text: "Custom Field",
          sort: true
        },
        {
          dataField: "value",
          text: "Value",
          sort: true
        },
        {
          dataField: "actions",
          text: "Action",

          formatter: (cell, row) => {
            return (
              <div className="actions">
                <NavLink onClick={e => this.editRecord(row.id, e)}>
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </NavLink>
                <NavLink onClick={e => this.deleteRecord(row.id, e)}>
                  <FontAwesomeIcon
                    style={{ color: "#d84a4a" }}
                    icon={faTrash}
                    size="1x"
                  />
                </NavLink>
              </div>
            );
          }
        }
      ],
      moduleDropdown: [],
      entityRecordDropdown: [],
      customFieldDropdown: [],
      conditionId: "",
      moduleId: "",
      moduleName: "",
      entityRecordId: "",
      entityRecordName: "",
      customFieldId: "",
      customFieldName: "",
      value: "",
      title: "",
      descriptionShort: "",
      descriptionLong: "",
      language: this.context?.user.organizations.defaultLanguage,
      languageDropdown: Config.languages,
      validation: this.validator.valid(),
      copyConditionsModal: false,
      conditionType: "",
      selectedConditionType: "",
      conditionTypes: [
        { label: "Pages", value: "1002" },
        { label: "Panels", value: "1003" },
        { label: "Questions", value: "1004" }
      ],
      conditionEntity: "",
      conditionEntities: [],
      selectedConditionEntity: "",
      fetchingConditionEntities: false,
      updateCondition: false,
      recordId: "",
      conditionWizard: "",
      conditionWizards: [],
      selectedConditionWizard: "",
      referenceDisplayText: "",
      reference: "",
      questionsWizard: "",
      selectedQuestionsWizard: ""
    };

    this.submitted = false;
  }

  componentWillUnmount() {
    conditionsArray = [];
  }

  componentDidMount() {
    this.populateEntities();
    this.populateWizardList();
    if (this.props.match.params.id !== undefined) {
      this.setState({ loading: true });
    }
    getAnswerOptionSetsAPI()
      .then(res => {
        this.setState(
          { answerOptionsDropdown: Array.isArray(res) ? res : [] },
          () => {
            if (this.props.match.params.id !== undefined) {
              getQuestionByIdAPI(this.props.match.params.id)
                .then(res => {
                  // older version, will be removed in future
                  // conditionsArray =
                  //   res.data.moduleConditions === null ||
                  //   res.data.moduleConditions === "" ||
                  //   res.data.moduleConditions === undefined
                  //     ? []
                  //     : JSON.parse(res.data.moduleConditions);

                  let modConditions =
                    res.data.moduleConditionsV1 === null ||
                      res.data.moduleConditionsV1 === "" ||
                      res.data.moduleConditionsV1 === undefined
                      ? []
                      : JSON.parse(res.data.moduleConditionsV1);

                  // Backward compatibilty
                  const moduleConditions = [conditionsArray];
                  const moduleConditionsJSON = moduleConditions.map(
                    condition => {
                      return {
                        id: this.uuidv4(),
                        children: condition,
                        operator: "and"
                      };
                    }
                  );
                  // Backward compatibilty

                  var selectedAnswerOptionSet = this.search(
                    res.data.answerOptionSetId,
                    this.state.answerOptionsDropdown
                  );

                  if (selectedAnswerOptionSet.answerDisplayTypeCode === 3012) {
                    this.setState({
                      showEntity: true
                    });
                  } else {
                    this.setState({
                      showEntity: false
                    });
                  }

                  if (
                    selectedAnswerOptionSet.answerDisplayTypeCode === 1009 ||
                    (res.data.panelId !== "" && res.data.panelId !== null) ||
                    selectedAnswerOptionSet.answerDisplayTypeCode === 3000 ||
                    selectedAnswerOptionSet.answerDisplayTypeCode === 3001 ||
                    selectedAnswerOptionSet.answerDisplayTypeCode === 3002 ||
                    selectedAnswerOptionSet.answerDisplayTypeCode === 3003 ||
                    selectedAnswerOptionSet.answerDisplayTypeCode === 3009 ||
                    selectedAnswerOptionSet.answerDisplayTypeCode === 3010 ||
                    selectedAnswerOptionSet.answerDisplayTypeCode === 3011 ||
                    selectedAnswerOptionSet.answerDisplayTypeCode === 3012
                  ) {
                    if (
                      selectedAnswerOptionSet.answerDisplayTypeCode === 1009 ||
                      selectedAnswerOptionSet.answerDisplayTypeCode === 3000 ||
                      selectedAnswerOptionSet.answerDisplayTypeCode === 3001 ||
                      selectedAnswerOptionSet.answerDisplayTypeCode === 3002 ||
                      selectedAnswerOptionSet.answerDisplayTypeCode === 3003 ||
                      selectedAnswerOptionSet.answerDisplayTypeCode === 3009 ||
                      selectedAnswerOptionSet.answerDisplayTypeCode === 3010 ||
                      selectedAnswerOptionSet.answerDisplayTypeCode === 3011 ||
                      selectedAnswerOptionSet.answerDisplayTypeCode === 3012
                    ) {
                      this.setState(
                        {
                          isExpression: true
                        },
                        () => {
                          this.populateQuestionsDropdown(res.data.panelId);
                        }
                      );
                    } else {
                      this.populateQuestionsDropdown(res.data.panelId);
                    }
                  } else {
                    this.setState({
                      isExpression: false
                    });
                  }

                  // Manange Single / Multiple entities

                  if (selectedAnswerOptionSet.answerTypeCode === 1008) {
                    this.setState({
                      showEntity: true
                    });
                  } else {
                    this.setState({
                      showEntity: false
                    });
                  }

                  if (selectedAnswerOptionSet.answerDisplayTypeCode === 1001) {
                    this.setState({
                      showDefaultValue: true,
                      showReadOnly: true
                    });
                  } else if (selectedAnswerOptionSet.answerDisplayTypeCode === 1013) {
                    this.setState({
                      showDefaultValue: true,
                      showReadOnly: true
                    })
                  } else if (selectedAnswerOptionSet.answerDisplayTypeCode === 3001) {
                    this.setState({
                      isExpression: true
                    });
                  } else {
                    this.setState({
                      showDefaultValue: false,
                      showReadOnly: false
                    });
                  }

                  this.setState({
                    name: res.data.name,
                    order: res.data.order,
                    condition: res.data.condition,
                    answerOptionSetId: res.data.answerOptionSetId,
                    panelId: res.data.panelId,
                    expression:
                      res.data.expression === null ? "" : res.data.expression,
                    isRequired: res.data.isRequired,
                    questionCode: res.data.questionCode,
                    regardingObjectTypeId: res.data.regardingObjectTypeId,
                    defaultValue:
                      res.data.defaultValue === null
                        ? ""
                        : res.data.defaultValue,
                    readOnly: res.data.readOnly,
                    relationToWizardConditionOperator:
                      res.data.relationToWizardConditionOperator,
                    groupOperator:
                      modConditions.length === 0
                        ? "and"
                        : modConditions.operator,
                    moduleConditions:
                      modConditions.length === 0 ? [] : modConditions.groups,
                    title: "0", // bypass validations incase of update since this field is not available to update
                    descriptionShort: "0", // bypass validations incase of update since this field is not available to update
                    descriptionLong: "0", // bypass validations incase of update since this field is not available to update
                    language: this.context.user.organizations.defaultLanguage, // bypass validations incase of update since this field is not available to update
                    loading: false
                  });
                })
                .catch(error => {
                  toast.error(this.context.languages["api_error"], {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                  });
                  this.setState({ loading: false });
                });
            } else if (this.props.match.params.panelId !== undefined) {
              this.populateQuestionsDropdown(this.props.match.params.panelId);
            }
          }
        );
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    getPanelsAPI()
      .then(res => {
        this.setState({ panelsDropdown: Array.isArray(res) ? res : [] });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });

    // questionId here is basically the panelId passed by the panel on question creation.
    // Need to change in the router ---- Fixed
    if (this.props.match.params.panelId !== undefined) {
      this.setState({
        panelId: this.props.match.params.panelId
      });
    }
  }

  populateWizardList = () => {
    this.setState({ conditionWizards: [] });

    getWizardsAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        let wizards = res.map(wizard => ({
          label: wizard.name,
          value: wizard.id
        }));
        this.setState({
          conditionWizards: wizards
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  deleteRecord = id => {
    // this.setState({ tableLoader: true, conditions: [] }, () => {
    Swal.fire({
      title: this.context.languages["swal.are_you_sure"],
      text: this.context.languages["swal.are_you_sure.delete_record"],
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: this.context.languages[
        "swal.are_you_sure.confirmButtonText"
      ],
      cancelButtonText: this.context.languages[
        "swal.are_you_sure.cancelButtonText"
      ]
    }).then(result => {
      if (result.value) {
        let record = this.searchInnerArray(id, this.state.moduleConditions);
        let groups = [...this.state.moduleConditions];
        for (var i = 0; i < groups.length; i++) {
          for (var j = 0; j < groups[i].children.length; j++) {
            if (groups[i].children[j].id === id) {
              const index = groups[i].children.indexOf(record);
              if (index >= 0) {
                groups[i].children.splice(index, 1);
              }
            }
          }
        }
        // conditionsArray.splice(
        //   conditionsArray.findIndex(item => item.id === id),
        //   1
        // );
        this.setState({
          moduleConditions: groups,
          tableLoader: false
        });
        Swal.fire(
          this.context.languages["swal.deleted"],
          this.context.languages["swal.deleted_record_deleted"],
          "success"
        );
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.context.languages["swal.cancelled"],
          this.context.languages["swal.cancelled_your_record_is_safe"],
          "error"
        );
      }
    });
    // });
  };

  editRecord = id => {
    this.setState(
      { loading: true, updateCondition: true, recordId: id },
      () => {
        let record = this.searchInnerArray(id, this.state.moduleConditions);
        this.populateEntityRecords(record.moduleId);
        this.populateCustomFields(record.moduleId);
        this.setState(
          {
            moduleId: record.moduleId,
            moduleName: record.moduleName,
            entityRecordId: record.entityRecordId,
            entityRecordName: record.entityRecordName,
            customFieldId: record.customFieldId,
            customFieldName: record.customFieldName,
            value: record.value,
            loading: false
          },
          () => {
            this.toggleConditionModal();
          }
        );
      }
    );
  };

  populateEntities = () => {
    getPlatformEntitesAPI()
      .then(res => {
        this.setState({
          regardingObjectTypeIdDropdown: Array.isArray(res) ? res : []
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  toggleExpressionQuestionsModal = (type = "") => {
    this.setState(
      prevState => ({
        expressionQuestionsModal: !prevState.expressionQuestionsModal,
        type: type
      }),
      () => {
        if (!this.state.expressionQuestionsModal) {
          this.setState({
            expressionQuestion: "",
            type: ""
          });
        }
      }
    );
  };

  toggleWizardQuestionsModal = (type = "") => {
    this.setState(
      prevState => ({
        questionsModal: !prevState.questionsModal,
        type: type
      }),
      () => {
        if (!this.state.questionsModal) {
          if (Config.wizard_based_conditions) {
            this.setState({
              selectedQuestionsWizard: "",
              questionDropdown: [],
              type: ""
            });
          } else {
            this.setState({
              type: ""
            });
          }
        }
      }
    );
  };

  toggleConditionModal = groupId => {
    this.setState(
      prevState => ({
        conditionModal: !prevState.conditionModal,
        groupId: groupId
      }),
      () => {
        if (!this.state.conditionModal) {
          this.setState({
            value: "",
            moduleId: "",
            entityRecordId: "",
            customFieldId: "",
            entityRecordDropdown: [],
            customFieldDropdown: [],
            updateCondition: false,
            recordId: "",
            groupId: -1
          });
        }
      }
    );
  };

  search = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === key) {
        return array[i];
      }
    }
  };

  searchInnerArray = (key, array) => {
    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < array[i].children.length; j++) {
        if (array[i].children[j].id === key) {
          return array[i].children[j];
        }
      }
    }
  };

  createQuestion = e => {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    let moduleConditionsV1 = {
      groups: this.state.moduleConditions,
      operator: this.state.groupOperator
    };

    const data = {
      ...this.state,
      moduleConditions: JSON.stringify([]),
      moduleConditionsV1:
        this.state.moduleConditions.length === 0
          ? null
          : JSON.stringify(moduleConditionsV1),
      id:
        this.props.match.params.id !== undefined
          ? this.props.match.params.id
          : "",
      userId: this.context.user.organizations.userId
    };
    if (this.props.match.params.id !== undefined) {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        updateQuestionAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    } else {
      if (validation.isValid) {
        this.setState({ cuLoader: true });
        createQuestionAPI(data)
          .then(res => {
            this.setState(
              {
                cuLoader: false
              },
              () => {
                this.goBack();
              }
            );
          })
          .catch(error => {
            toast.error(this.context.languages["api_error"], {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 3000
            });
            this.setState({ cuLoader: false });
          });
      }
    }
  };

  populateEntityRecords = id => {
    this.setState({ entityRecordDropdown: [], fetchingModuleRecords: true });
    getEntitiesAPI(id)
      .then(res => {
        if (res !== undefined && Array.isArray(res)) {
          if (res.length === 0) {
            this.setState(
              {
                fetchingModuleRecords: false
              },
              () => {
                Swal.fire({
                  icon: "error",
                  title: this.context.languages["swal.oops"],
                  text: this.context.languages["swal.oops.select_another"]
                });
              }
            );
          } else {
            this.setState({
              entityRecordDropdown: res,
              fetchingModuleRecords: false
            });
          }
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          toast.error(this.context.languages["insufficient_permission"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          });
        } else {
          toast.error(this.context.languages["api_error"], {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
          });
        }
        this.setState({ tableLoader: false });
      });
  };

  populateCustomFields = id => {
    this.setState({ customFieldDropdown: [], fetchingCustomFields: true });
    getBooleanCustomFieldsAPI(id)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        if (res.length === 0) {
          this.setState({ fetchingCustomFields: false }, () => {
            Swal.fire({
              icon: "error",
              title: this.context.languages["swal.oops"],
              text: this.context.languages["swal.oops_entity_boolean"]
            });
          });
        } else {
          this.setState({
            customFieldDropdown: res,
            fetchingCustomFields: false
          });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "answerOptionSetId") {
      var selected = target.options[target.selectedIndex].getAttribute(
        "data-answerdisplaytypecode"
      );
      var selectedAnswerTypeCode = target.options[
        target.selectedIndex
      ].getAttribute("data-answertypecode");
      if (selected === "1020") {
        this.setState({ isQuestionHtml: true })
      }
      if (selected === "1009") {
        // this.populateQuestionsDropdown(this.state.panelId);
        if (Config.wizard_based_conditions) {
          this.setState({
            isExpression: true,
            selectedQuestionsWizard: "",
            questionDropdown: []
          });
        } else {
          this.setState({
            isExpression: true
          });
        }
      } else {
        this.setState({
          isExpression: false,
          expression: ""
          // questionDropdown: []
        });
      }

      if (selected === "1001") {
        this.setState({
          showDefaultValue: true,
          showReadOnly: true
        });
      } else if (selected === "1013") {
        this.setState({
          showDefaultValue: true,
          showReadOnly: true
        });
      } else {
        this.setState({
          showDefaultValue: false,
          showReadOnly: false
        });
      }

      if (selectedAnswerTypeCode === "1008") {
        this.setState({
          showEntity: true
        });
      } else if (selectedAnswerTypeCode === "3001") {
        if (selected === "3012") {
          this.setState({
            showEntity: true
          });
        } else {
          this.setState({
            showEntity: false
          });
        }
        this.setState({
          isExpression: true,
          expression: ""
        });
      } else {
        this.setState({
          showEntity: false,
          regardingObjectTypeId: ""
        });
      }
    } else if (name === "panelId") {
      if (value !== "") {
        this.populateQuestionsDropdown(value);
      } else {
        this.setState({ questionDropdown: [] });
      }
    }

    this.setState({
      [name]: value
    });
  };

  handleConditionsChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "moduleId") {
      if (value !== "") {
        this.populateEntityRecords(value);
        let selected = this.search(
          value,
          this.state.regardingObjectTypeIdDropdown
        );
        this.setState({
          moduleName: selected.name
        });
      } else {
        this.setState({
          moduleName: ""
        });
      }
    }

    if (name === "entityRecordId") {
      this.populateCustomFields(this.state.moduleId);
      let selected = this.search(value, this.state.entityRecordDropdown);
      this.setState({
        entityRecordName: selected.name
      });
    }

    if (name === "customFieldId") {
      if (value !== "") {
        let selected = this.search(value, this.state.customFieldDropdown);
        this.setState({
          customFieldName: selected.name
        });
      } else {
        this.setState({
          customFieldName: ""
        });
      }
    }

    this.setState({
      [name]: value
    });
  };

  toggleButton = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  insertQuestionID = e => {
    e.preventDefault();
    console.log("Question ID selected", this.state.question);

    if (this.state.type === "condition") {
      var getConditionValue = this.state.condition;
      var setConditionValue = getConditionValue.concat(
        `{${this.state.question}}`
      );
      if (Config.wizard_based_conditions) {
        this.setState(
          {
            condition: setConditionValue,
            question: "",
            selectedQuestionsWizard: "",
            questionDropdown: []
          },
          () => {
            this.toggleWizardQuestionsModal();
          }
        );
      } else {
        this.setState(
          {
            condition: setConditionValue,
            question: "",
            selectedQuestionsWizard: ""
          },
          () => {
            this.toggleWizardQuestionsModal();
          }
        );
      }
    } else {
      var getExpressionValue = this.state.expression;
      var setExpressionValue = getExpressionValue.concat(
        `{${this.state.expressionQuestion}}`
      );
      this.setState(
        { expression: setExpressionValue, expressionQuestion: "" },
        () => {
          this.toggleExpressionQuestionsModal();
        }
      );
    }
  };

  insertModuleConditions = e => {
    e.preventDefault();
    let groups = [...this.state.moduleConditions];
    if (
      this.state.moduleId !== "" &&
      this.state.entityRecordId !== "" &&
      this.state.customFieldId !== "" &&
      this.state.value !== ""
    ) {
      // this.setState({ tableLoader: true, conditions: [] }, () => {
      let condition = {
        id: !this.state.updateCondition ? this.uuidv4() : this.state.recordId,
        moduleId: this.state.moduleId,
        moduleName: this.state.moduleName,
        entityRecordId: this.state.entityRecordId,
        entityRecordName: this.state.entityRecordName,
        customFieldId: this.state.customFieldId,
        customFieldName: this.state.customFieldName,
        value: this.state.value
      };
      if (!this.state.updateCondition) {
        for (var i = 0; i < groups.length; i++) {
          if (this.state.groupId === groups[i].id) {
            groups[i].children.push(condition);
          }
        }

        // Previous way
        // groupSet = groups.map(group => ({
        //   ...group,
        //   children:
        //     this.state.groupId === group.id
        //       ? group.children.push(condition)
        //       : group.children
        // }));
      } else {
        for (var i = 0; i < groups.length; i++) {
          for (var j = 0; j < groups[i].children.length; j++) {
            if (groups[i].children[j].id === this.state.recordId) {
              groups[i].children[j] = condition;
            }
          }
        }
      }
      this.setState({ moduleConditions: groups, tableLoader: false }, () => {
        this.toggleConditionModal();
      });
      // });
    } else {
      Swal.fire({
        icon: "error",
        title: this.context.languages["swal.oops"],
        text: this.context.languages["swal.oops.all_fields_required"]
      });
    }
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  populateQuestionsDropdown = panelID => {
    getQuestionsByPaneldAPI(panelID)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        if (Config.wizard_based_conditions) {
          this.setState({ expressionQuestionDropdown: res });
        } else {
          this.setState({
            questionDropdown: res,
            expressionQuestionDropdown: res
          });
        }
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  populateQuestionsByWizardID = wizardID => {
    getQuestionsByWizardIdAPI(wizardID)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        this.setState({ questionDropdown: res });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  toggleCopyConditionsModal = () => {
    this.setState(
      prevState => ({
        copyConditionsModal: !prevState.copyConditionsModal
      }),
      () => {
        if (!this.state.copyConditionsModal) {
          this.setState({
            selectedConditionType: "",
            conditionType: "",
            conditionEntities: [],
            selectedConditionEntity: "",
            conditionEntity: "",
            conditionWizard: "",
            selectedConditionWizard: ""
          });
        }
      }
    );
  };

  handleTagboxChange = (state, selectedStateName, selectedOption) => {
    this.setState({
      [state]: selectedOption.value,
      [selectedStateName]: selectedOption === null ? "" : selectedOption
    });
    if (state === "conditionWizard") {
      this.setState({
        conditionEntities: [],
        selectedConditionEntity: "",
        conditionEntity: "",
        conditionType: "",
        selectedConditionType: ""
      });
    }
    if (state === "conditionType") {
      this.populateConditionsEntity(selectedOption.value);
    }
    if (state === "questionsWizard") {
      this.populateQuestionsByWizardID(selectedOption.value);
    }
  };
  handleEditor = (e, type) => {
    if (e == "<p><br></p>") {
      this.setState({ [type]: "" })
    } else {
      this.setState({ [type]: e })
    }
  }
  populateConditionsEntity = entity => {
    this.setState({ fetchingConditionEntities: true });
    getWizardEntitiesAPI(this.state.conditionWizard, entity)
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        let entities = res.map(item => ({
          label: item.name,
          value:
            item.moduleConditions === null ? item.id : item.moduleConditions,
          isDisabled: item.moduleConditions === null ? true : false
        }));
        this.setState({
          conditionEntities: entities,
          fetchingConditionEntities: false
        });
      })
      .catch(error => {
        this.setState({ fetchingConditionEntities: false });
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
      });
  };

  copyConditions = e => {
    e.preventDefault();
    conditionsArray = [];
    if (this.state.conditionType !== "" && this.state.conditionEntity !== "") {
      this.setState({ tableLoader: true, conditions: [] }, () => {
        conditionsArray = JSON.parse(this.state.conditionEntity);
        this.setState(
          { conditions: conditionsArray, tableLoader: false },
          () => {
            this.toggleCopyConditionsModal();
          }
        );
      });
    } else {
      Swal.fire({
        icon: "error",
        title: this.context.languages["swal.oops"],
        text: this.context.languages["swal.oops.all_fields_required"]
      });
    }
  };

  renderTableData = records => {
    if (records.length > 0) {
      return records.map(record => (
        <tr key={record.id}>
          <td>{record.moduleName}</td>
          <td>{record.entityRecordName}</td>
          <td>{record.customFieldName}</td>
          <td>{record.value}</td>
          <td>
            <div className="actions">
              <NavLink onClick={e => this.editRecord(record.id, e)}>
                <FontAwesomeIcon icon={faEdit} size="1x" />
              </NavLink>
              <NavLink onClick={e => this.deleteRecord(record.id, e)}>
                <FontAwesomeIcon
                  style={{ color: "#d84a4a" }}
                  icon={faTrash}
                  size="1x"
                />
              </NavLink>
            </div>
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan="5">
            <TranslatableText translationKey="no_data_available" />
          </td>
        </tr>
      );
    }
  };
  renderTableHeader = () => {
    return (
      <tr className="thead">
        <th>
          <TranslatableText translationKey="module" />
        </th>
        <th>
          <TranslatableText translationKey="resource" />
        </th>
        <th>
          <TranslatableText translationKey="customField" />
        </th>
        <th style={{ width: '5%' }}>
          <TranslatableText translationKey="value" />
        </th>
        <th style={{ width: '5%' }}>
          <TranslatableText translationKey="actions" />
        </th>
      </tr>
    );
  };

  addGroup = e => {
    e.preventDefault();
    // [...] is to resolve the array mutation issue
    let groups = [...this.state.moduleConditions];

    groups.push({
      id: this.uuidv4(),
      children: [],
      operator: "and"
    });
    this.setState({ moduleConditions: groups });
  };

  deleteGroup = (index, e) => {
    e.preventDefault();

    let groups = this.state.moduleConditions;
    // let clonedGroups = [...groups];
    // let toDelete = clonedGroups[index];
    // this.setState({
    //   moduleConditions: clonedGroups.filter(item => item !== toDelete)
    // });
    const moduleConditions = groups.filter((item, i) => i !== index);
    this.setState({
      moduleConditions
    });
  };

  handleDynamicFieldChange = (groupId, e) => {
    const { name, value } = e.target;
    let moduleConditions = this.state.moduleConditions;
    for (var i = 0; i < moduleConditions.length; i++) {
      if (moduleConditions[i].id === groupId) {
        moduleConditions[i].operator = value;
      }
    }

    this.setState({
      moduleConditions
    });
  };
  handleHtmlChange = event => {
    this.setState({ descriptionLong: event.target.value });
  }
  render() {
    let { loading, tableLoader } = this.state;
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;
    return (
      <div className="create-question-view">
        {loading && (
          <ScreenLoading />
        )}
        <Row>
          <Col md={12}>
            <h3>
              {this.props.match.params.id !== undefined ? <TranslatableText translationKey="update" />
                : <TranslatableText translationKey="create" />}{" "}
              <TranslatableText translationKey="question.question" />
            </h3>
          </Col>
        </Row>
        <Form onSubmit={this.createQuestion}>
          <Row className="panel" form>
            <Col className="panel-header" md={12}>
              <TranslatableText translationKey="page.system_information" />
            </Col>
            <Col className="panel-body" md={12}>
              <Row form>
                <Col md={4}>
                  <FormGroup
                    className={validation.name.isInvalid ? "has-error" : ""}
                  >
                    <Label for="name">
                      <TranslatableText translationKey="company.system_name" />
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder=""
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.name.message}
                    </span>
                  </FormGroup>
                </Col>

                <Col md={2}>
                  <FormGroup
                    className={validation.order.isInvalid ? "has-error" : ""}
                  >
                    <Label for="order">
                      <TranslatableText translationKey="order" />
                    </Label>
                    <Input
                      type="number"
                      name="order"
                      id="order"
                      placeholder=""
                      value={this.state.order}
                      onChange={this.handleChange}
                    />
                    <span className="help-block">
                      {validation.order.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup
                    className={validation.panelId.isInvalid ? "has-error" : ""}
                  >
                    <Label for="panelId">
                      <TranslatableText translationKey="panel" />
                    </Label>
                    <Input
                      type="select"
                      name="panelId"
                      id="panelId"
                      value={this.state.panelId}
                      onChange={this.handleChange}
                      disabled={
                        this.props.match.params.panelId !== undefined
                          ? true
                          : null
                      }
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.panelsDropdown.map((e, key) => {
                        return (
                          <option key={e.id} value={e.id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                    <span className="help-block">
                      {validation.panelId.message}
                    </span>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup
                    className={
                      validation.answerOptionSetId.isInvalid ? "has-error" : ""
                    }
                  >
                    <Label for="answerOptionSetId">

                      <TranslatableText translationKey="answer_option_set" />&nbsp;
                      <Link
                        to={`/admin/wizard/answeroptionset/details/${this.state.answerOptionSetId}`}
                      >
                        <Tippy placement="top" content="Go to answer option set details">
                          <span>
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                          </span>
                        </Tippy>
                      </Link>
                    </Label>
                    <Input
                      type="select"
                      name="answerOptionSetId"
                      id="answerOptionSetId"
                      value={this.state.answerOptionSetId}
                      onChange={this.handleChange}
                      disabled={this.state.panelId === "" ? true : false}
                    >
                      <option value="">-- Vælg --</option>
                      {this.state.answerOptionsDropdown.map((e, key) => {
                        return (
                          <option
                            key={e.id}
                            value={e.id}
                            data-answerdisplaytypecode={e.answerDisplayTypeCode}
                            data-answertypecode={e.answerTypeCode}
                          >
                            {e.name}
                          </option>
                        );
                      })}
                    </Input>
                    <span className="help-block">
                      {validation.answerOptionSetId.message}
                    </span>
                  </FormGroup>
                </Col>
                {this.state.showEntity ? (
                  <Col md={3}>
                    <FormGroup>
                      <Label for="regardingObjectTypeId">
                        <TranslatableText translationKey="question.select_an_entity" />
                      </Label>
                      <Input
                        type="select"
                        name="regardingObjectTypeId"
                        id="regardingObjectTypeId"
                        value={this.state.regardingObjectTypeId}
                        onChange={this.handleChange}
                      >
                        <option value="">-- Vælg --</option>
                        {this.state.regardingObjectTypeIdDropdown.map(
                          (e, key) => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.name}
                              </option>
                            );
                          }
                        )}
                      </Input>
                    </FormGroup>
                  </Col>
                ) : (
                  ""
                )}
                <Col md={this.state.showEntity ? 8 : 11}>
                  <FormGroup>
                    <Label for="condition">
                      <TranslatableText translationKey="page.condition" />
                    </Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="condition"
                        id="condition"
                        placeholder=""
                        value={this.state.condition}
                        onChange={this.handleChange}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          disabled={
                            this.state.panelId === "" &&
                              !Config.wizard_based_conditions
                              ? true
                              : false
                          }
                          color="secondary"
                          onClick={e =>
                            this.toggleWizardQuestionsModal("condition")
                          }
                        >
                          <FontAwesomeIcon icon={faLink} size="1x" />{" "}
                          <TranslatableText translationKey="page.insert_question_id" />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={1}>
                  <FormGroup className="align-right">
                    <Label for="">
                      <TranslatableText translationKey="custom_fields.required" />
                    </Label>
                    <div
                      onClick={e => this.toggleButton("isRequired")}
                      className={classnames("search-toggle-btn")}
                    >
                      <span
                        className={classnames(
                          "ball",
                          this.state.isRequired ? "toggled" : ""
                        )}
                      />
                    </div>
                  </FormGroup>
                </Col>
                {this.state.showDefaultValue && this.state.showReadOnly ? (
                  <>
                    <Col md={11}>
                      <FormGroup>
                        <Label for="name">
                          <TranslatableText translationKey="question.default_value" />
                        </Label>
                        <Input
                          type="text"
                          name="defaultValue"
                          id="defaultValue"
                          placeholder=""
                          value={this.state.defaultValue}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup className="align-right">
                        <Label for="">
                          {" "}
                          <TranslatableText translationKey="readonly" />
                        </Label>
                        <div
                          onClick={e => this.toggleButton("readOnly")}
                          className={classnames("search-toggle-btn")}
                        >
                          <span
                            className={classnames(
                              "ball",
                              this.state.readOnly ? "toggled" : ""
                            )}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                ) : (
                  <></>
                )}

                {this.state.isExpression ? (
                  <Col md={12}>
                    <FormGroup>
                      <div className="flex space-between label flex-align">
                        <Label for="expression">
                          <TranslatableText translationKey="question.please_write_expression" />
                        </Label>
                        <Button
                          outline
                          onClick={e =>
                            this.toggleExpressionQuestionsModal("expression")
                          }
                        >
                          <FontAwesomeIcon icon={faLink} size="1x" />{" "}
                          <TranslatableText translationKey="page.insert_question_id" />
                        </Button>
                      </div>
                      <Input
                        type="textarea"
                        name="expression"
                        id="expression"
                        rows="5"
                        placeholder="{questionId} (operator) {questionId}"
                        value={this.state.expression}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  </Col>
                ) : (
                  ""
                )}
                <Col md={12}>
                  <Accent useHtmlIcon={false}>
                    {" "}
                    <TranslatableText translationKey="page.module_conditions" />
                  </Accent>
                  <FormGroup>
                    <Label for="condition">
                      <TranslatableText translationKey="relation_to_wizard_condition" />
                    </Label>
                    <div className="flex">
                      <div className="stv-radio-buttons-wrapper two">
                        <span>
                          <input
                            type="radio"
                            className="stv-radio-button"
                            name="relationToWizardConditionOperator"
                            value="and"
                            id="wizard-condition-and-relation"
                            onChange={e => this.handleChange(e)}
                            checked={
                              this.state.relationToWizardConditionOperator ===
                              "and"
                            }
                          />
                          <Label for="wizard-condition-and-relation">
                            <TranslatableText translationKey="and" />
                          </Label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            className="stv-radio-button"
                            name="relationToWizardConditionOperator"
                            value="or"
                            id="wizard-condition-or-relation"
                            onChange={e => this.handleChange(e)}
                            checked={
                              this.state.relationToWizardConditionOperator ===
                              "or"
                            }
                          />
                          <Label for="wizard-condition-or-relation">
                            <TranslatableText translationKey="or" />
                          </Label>
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <ConditionsBuilder
                  handleChange={this.handleChange}
                  componentState={this.state}
                  addGroup={this.addGroup}
                  deleteGroup={this.deleteGroup}
                  handleDynamicFieldChange={this.handleDynamicFieldChange}
                  toggleCopyConditionsModal={this.toggleCopyConditionsModal}
                  toggleConditionModal={this.toggleConditionModal}
                  renderTableHeader={this.renderTableHeader}
                  renderTableData={this.renderTableData}
                />
              </Row>
            </Col>
          </Row>
          {this.props.match.params.id === undefined ? (
            <Row className="panel" form>
              <Col className="panel-header" md={12}>
                <TranslatableText translationKey="custom_fields.display_information" />
              </Col>
              <Col className="panel-body" md={12}>
                <Row form>
                  <Col md={12}>
                    <FormGroup
                      className={validation.title.isInvalid ? "has-error" : ""}
                    >
                      <Label className="flex space-between" for="title">
                        <span className="flex verticle-flex-end">
                          <TranslatableText translationKey="title" />
                        </span>
                      </Label>
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder=""
                        value={this.state.title}
                        onChange={this.handleChange}
                      />
                      <span className="help-block">
                        {validation.title.message}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup
                      className={
                        validation.language.isInvalid ? "has-error" : ""
                      }
                    >
                      <Label for="language">
                        <TranslatableText translationKey="language" />
                      </Label>
                      <Input
                        type="select"
                        name="language"
                        id="language"
                        value={this.state.language}
                        onChange={this.handleChange}
                        disabled={
                          this.props.match.params.translationId !== undefined
                            ? true
                            : null
                        }
                      >
                        <option value="">-- Vælg --</option>
                        {this.state.languageDropdown.map((e, key) => {
                          return (
                            <option key={key} value={e.value}>
                              {e.name}
                            </option>
                          );
                        })}
                      </Input>
                      <span className="help-block">
                        {validation.language.message}
                      </span>
                    </FormGroup>
                  </Col>
                  {!this.state.isQuestionHtml &&

                    <Col md={8}>
                      <FormGroup>
                        <Label
                          className="flex space-between"
                          for="descriptionShort"
                        >
                          <span>
                            <TranslatableText translationKey="description_short" />
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="descriptionShort"
                          id="descriptionShort"
                          placeholder=""
                          value={this.state.descriptionShort}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  }
                  <Col md={12}>
                    <FormGroup>
                      <Label
                        className="flex space-between"
                        for="descriptionLong"
                      >
                        <span>
                          {!this.state.isQuestionHtml &&
                            <TranslatableText translationKey="description_long" />
                          }
                          {this.state.isQuestionHtml &&
                            <p>HTML</p>
                          }
                        </span>
                      </Label>
                      {!this.state.isQuestionHtml &&
                        <Editor initialValue={this.state.descriptionLong} callback={e => this.handleEditor(e, "descriptionLong")} disabled={false} />

                      }

                      {this.state.isQuestionHtml &&
                        <Input
                          type="textarea"
                          name="base_descriptionLong"
                          id="base_descriptionLong"
                          value={this.state.descriptionLong}
                          onChange={e => this.handleHtmlChange(e)}
                        />
                      }
                    </FormGroup>
                  </Col>
                  {!this.state.isQuestionHtml &&

                    <Col md={12}>
                      <FormGroup>
                        <Label
                          className="flex space-between"
                          for="referenceDisplayText"
                        >
                          <span>
                            <TranslatableText translationKey="questions.reference_display_text" />
                          </span>
                        </Label>
                        <Input
                          type="text"
                          name="referenceDisplayText"
                          id="referenceDisplayText"
                          placeholder=""
                          value={this.state.referenceDisplayText}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  }
                  {!this.state.isQuestionHtml &&

                    <Col md={12}>
                      <FormGroup>
                        <Label className="flex space-between" for="reference">
                          <span>
                            <TranslatableText translationKey="referece" />
                          </span>
                        </Label>
                        <Editor initialValue={this.state.reference} callback={e => this.handleEditor(e, "reference")} disabled={false} />
                      </FormGroup>
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <Row>
            <Col className="space-between flex" md={12}>
              <Button onClick={this.goBack}>
                <TranslatableText translationKey="back_to_list" />
              </Button>
              {this.state.cuLoader === false ? (
                <Button>
                  {this.props.match.params.id !== undefined
                    ? <TranslatableText translationKey="update" />
                    : <TranslatableText translationKey="create" />}
                </Button>
              ) : (
                <InlineLoading />
              )}
            </Col>
          </Row>
        </Form>
        <div className="modals">
          <Modal
            isOpen={this.state.questionsModal}
            toggle={this.toggleWizardQuestionsModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleWizardQuestionsModal}>
              <TranslatableText translationKey="question.questions" />
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={this.insertQuestionID}>
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    <Row>
                      {Config.wizard_based_conditions ? (
                        <Col className="multiselect-container" md={12}>
                          <FormGroup>
                            <Label for="question">Wizard</Label>
                            <Select
                              value={
                                this.state.selectedQuestionsWizard === ""
                                  ? null
                                  : this.state.selectedQuestionsWizard
                              }
                              className="user-selectbox"
                              options={this.state.conditionWizards}
                              onChange={selectedOption =>
                                this.handleTagboxChange(
                                  "questionsWizard",
                                  "selectedQuestionsWizard",
                                  selectedOption
                                )
                              }
                              hideSelectedOptions={false}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <></>
                      )}

                      <Col md={12}>
                        <FormGroup>
                          <Label for="question">
                            <TranslatableText translationKey="page.questions_id_description" />
                          </Label>
                          <Input
                            type="select"
                            name="question"
                            id="question"
                            value={this.state.question}
                            onChange={this.handleChange}
                          >
                            <option value="">-- Vælg --</option>
                            {this.state.questionDropdown.map((e, key) => {
                              return (
                                <option key={e.id} value={e.questionCode}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row form className="flex flex-end">
                  <Button className="mr-10" outline>
                    <TranslatableText translationKey="page.insert" />
                  </Button>
                  <Button outline onClick={this.toggleWizardQuestionsModal}>
                    <TranslatableText translationKey="page.cancel" />
                  </Button>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.expressionQuestionsModal}
            toggle={this.toggleExpressionQuestionsModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleExpressionQuestionsModal}>
              <TranslatableText translationKey="question.questions" />
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={this.insertQuestionID}>
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="expressionQuestion">
                            <TranslatableText translationKey="questions.select_question_for_expression" />
                          </Label>
                          <Input
                            type="select"
                            name="expressionQuestion"
                            id="expressionQuestion"
                            value={this.state.expressionQuestion}
                            onChange={this.handleChange}
                          >
                            <option value="">-- Vælg --</option>
                            {this.state.expressionQuestionDropdown.map(
                              (e, key) => {
                                return (
                                  <option key={e.id} value={e.questionCode}>
                                    {e.name}
                                  </option>
                                );
                              }
                            )}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row form className="flex flex-end">
                  <Button className="mr-10" outline>
                    <TranslatableText translationKey="insert" />
                  </Button>
                  <Button outline onClick={this.toggleExpressionQuestionsModal}>
                    <TranslatableText translationKey="cancel" />
                  </Button>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.conditionModal}
            toggle={this.toggleConditionModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleConditionModal}>
              <TranslatableText translationKey="page.add_condition" />
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={this.insertModuleConditions}>
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    <FormGroup>
                      <Label for="moduleId">
                        <TranslatableText translationKey="page.module" />
                      </Label>
                      <Input
                        type="select"
                        name="moduleId"
                        id="moduleId"
                        value={this.state.moduleId}
                        onChange={this.handleConditionsChange}
                        disabled={this.state.updateCondition ? true : null}
                      >
                        <option value="">-- Vælg --</option>
                        {this.state.regardingObjectTypeIdDropdown.map(
                          (e, key) => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.name}
                              </option>
                            );
                          }
                        )}
                      </Input>
                    </FormGroup>

                    {this.state.moduleId === "" ? (
                      <></>
                    ) : this.state.moduleId !== "" &&
                      this.state.entityRecordDropdown.length === 0 &&
                      this.state.fetchingModuleRecords ? (
                      <FormGroup>
                        <InlineLoading />
                      </FormGroup>
                    ) : !this.state.fetchingModuleRecords &&
                      this.state.moduleId !== "" &&
                      this.state.entityRecordDropdown.length === 0 ? (
                      <></>
                    ) : (
                      <FormGroup>
                        <Label for="entityRecordId">
                          <TranslatableText translationKey="entity" />
                        </Label>
                        <Input
                          type="select"
                          name="entityRecordId"
                          id="entityRecordId"
                          value={this.state.entityRecordId}
                          onChange={this.handleConditionsChange}
                          disabled={this.state.updateCondition ? true : null}
                        >
                          <option value="">-- Vælg --</option>
                          {this.state.entityRecordDropdown.map((e, key) => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    )}

                    {this.state.entityRecordId === "" ? (
                      <></>
                    ) : this.state.entityRecordId !== "" &&
                      this.state.customFieldDropdown.length === 0 &&
                      this.state.fetchingCustomFields ? (
                      <FormGroup>
                        <InlineLoading />
                      </FormGroup>
                    ) : !this.state.fetchingCustomFields &&
                      this.state.entityRecordId !== "" &&
                      this.state.customFieldDropdown.length === 0 ? (
                      <></>
                    ) : (
                      <FormGroup>
                        <Label for="customFieldId">
                          <TranslatableText translationKey="custom_field" />
                        </Label>
                        <Input
                          type="select"
                          name="customFieldId"
                          id="customFieldId"
                          value={this.state.customFieldId}
                          onChange={this.handleConditionsChange}
                        >
                          <option value="">-- Vælg --</option>
                          {this.state.customFieldDropdown.map((e, key) => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    )}

                    {this.state.entityRecordId !== "" &&
                      this.state.moduleId !== "" &&
                      this.state.customFieldId !== "" ? (
                      <FormGroup>
                        <Label for="value">
                          <TranslatableText translationKey="value" />
                        </Label>
                        <Input
                          type="select"
                          name="value"
                          id="value"
                          value={this.state.value}
                          onChange={this.handleConditionsChange}
                        >
                          <option value="">-- Vælg --</option>
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </Input>
                      </FormGroup>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row form className="flex flex-end">
                  {this.state.updateCondition ? (
                    <Button className="mr-10" outline>
                      <TranslatableText translationKey="update" />
                    </Button>
                  ) : (
                    <Button className="mr-10" outline>
                      <TranslatableText translationKey="add" />
                    </Button>
                  )}

                  <Button outline onClick={this.toggleConditionModal}>
                    <TranslatableText translationKey="cancel" />
                  </Button>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.copyConditionsModal}
            toggle={this.toggleCopyConditionsModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleCopyConditionsModal}>
              <TranslatableText translationKey="page.copy_conditions" />
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={this.copyConditions}>
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    <FormGroup>
                      <Label for="question">Wizard</Label>
                      <Select
                        value={
                          this.state.selectedConditionWizard === ""
                            ? null
                            : this.state.selectedConditionWizard
                        }
                        className="user-selectbox"
                        options={this.state.conditionWizards}
                        onChange={selectedOption =>
                          this.handleTagboxChange(
                            "conditionWizard",
                            "selectedConditionWizard",
                            selectedOption
                          )
                        }
                        hideSelectedOptions={false}
                      />
                    </FormGroup>
                  </Col>
                  {this.state.conditionWizard === "" ? (
                    <></>
                  ) : (
                    <Col className="multiselect-container" md={12}>
                      <FormGroup>
                        <Label for="question">
                          {" "}
                          <TranslatableText translationKey="type" />
                        </Label>
                        <Select
                          value={
                            this.state.selectedConditionType === ""
                              ? null
                              : this.state.selectedConditionType
                          }
                          className="user-selectbox"
                          options={this.state.conditionTypes}
                          onChange={selectedOption =>
                            this.handleTagboxChange(
                              "conditionType",
                              "selectedConditionType",
                              selectedOption
                            )
                          }
                          hideSelectedOptions={false}
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {this.state.conditionType === "" ? (
                    <></>
                  ) : this.state.conditionType !== "" &&
                    this.state.conditionEntities.length === 0 &&
                    this.state.fetchingConditionEntities ? (
                    <Col className="multiselect-container" md={12}>
                      <FormGroup>
                        <InlineLoading />
                      </FormGroup>
                    </Col>
                  ) : !this.state.fetchingConditionEntities &&
                    this.state.conditionType !== "" &&
                    this.state.conditionEntities.length === 0 ? (
                    <></>
                  ) : (
                    <Col className="multiselect-container" md={12}>
                      <FormGroup>
                        <Label for="question">
                          {" "}
                          <TranslatableText translationKey="entity" />
                        </Label>
                        <Select
                          value={
                            this.state.selectedConditionEntity === ""
                              ? null
                              : this.state.selectedConditionEntity
                          }
                          className="user-selectbox"
                          options={this.state.conditionEntities}
                          onChange={selectedOption =>
                            this.handleTagboxChange(
                              "conditionEntity",
                              "selectedConditionEntity",
                              selectedOption
                            )
                          }
                          hideSelectedOptions={false}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row form className="flex flex-end">
                  <Button className="mr-10" outline>
                    <TranslatableText translationKey="add" />
                  </Button>
                  <Button outline onClick={this.toggleCopyConditionsModal}>
                    <TranslatableText translationKey="cancel" />
                  </Button>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateQuestion);
CreateQuestion.contextType = GlobalContext;