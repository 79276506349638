import {
  faCheckCircle,
  faInfoCircle
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCircleExclamation,
  faCircleX,
  faPaperPlane,
  faPersonCircleCheck,
  faPersonCircleExclamation,
  faPersonCircleXmark, faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import dayjs from "dayjs";
import localeDa from 'dayjs/locale/da';
import React, { useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport, Search
} from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import {
  Button, Col, Form,
  FormGroup, Input, Label, Modal, ModalBody,
  ModalFooter, ModalHeader, Row
} from "reactstrap";
import { ApprovalFlowsClient, ApprovalFlowsHistoryClient, GetApprovalFlowsHistoryResponse, UpdateUserApprovalFlowHistoryRequest } from "../../apiClients";
import { HandleCaught_API_Error_Base } from "../../apiClients/api_caughtError";
import { InlineLoading } from "../../components";
import Accent from "../../components/accent/Accent";
import { Table_GetLocalStorage, Table_HeaderFormatter, Table_NoDataIndication, Table_SetLocalStorage } from "../../components/table";
import groupBy from "../../components/utilities/groupBy/groupBy";
import { approvalStateVisualiser } from "../../config/ApprovalFlowState";
import { AccountLevel } from "../../config/Levels";
import { ResourceTypes } from "../../config/ResourceTypes";
import { usePlatformUserData } from "../../contexts";
import TranslatableText from "../../Language/TranslatableText";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

type ApprovalsState = {
  cuLoader: boolean,
  tableLoader: boolean,
  recordId: string | null,
  regardingObjectId: string | null,
  comments: string,
  approveModal: boolean,
  rejectModal: boolean,
  detailModal: boolean,
  detailModalTitle: string,
  detailModalDescription: string,
  approveValidation: boolean,
  rejectValidation: boolean,
  checkValidation: boolean,
  dataVersion: string
}

const initialState: ApprovalsState = {
  cuLoader: false,
  tableLoader: false,
  recordId: null,
  regardingObjectId: null,
  comments: "",
  approveModal: false,
  rejectModal: false,
  detailModal: false,
  detailModalTitle: "",
  detailModalDescription: "",
  approveValidation: false,
  rejectValidation: false,
  checkValidation: false,
  dataVersion: ""
};

interface FormattedGetApprovalFlowsHistoryResponse extends GetApprovalFlowsHistoryResponse {
  rowSpan: number,
  sl_id: number
}

type ApprovalCollectionState = {
  /** Array used to actually show data */
  approvals: FormattedGetApprovalFlowsHistoryResponse[],
}

const initialStateApprovalCollection: ApprovalCollectionState = {
  approvals: []
};

export default function Approvals() {
  const [{ cuLoader,
    tableLoader,
    recordId,
    regardingObjectId,
    comments,
    approveModal,
    rejectModal,
    detailModal,
    detailModalTitle,
    detailModalDescription,
    approveValidation,
    rejectValidation,
    checkValidation,
    dataVersion }, setState]
    = useState(initialState);
  const [{ approvals }, setApprovalCollectionState]
    = useState(initialStateApprovalCollection);

  const history = useHistory();
  const _ApprovalFlowsClient = new ApprovalFlowsClient();
  const _ApprovalFlowsHistoryClient = new ApprovalFlowsHistoryClient();
  const currentOrgData = usePlatformUserData();


  useEffect(() => {
    loadApprovals();
  }, []);

  async function loadApprovals() {
    setState((prevState) => ({ ...prevState, tableLoader: true }));
    setApprovalCollectionState((prevState) => ({ ...prevState, initialStateApprovalCollection }));
    Promise.all([
      _ApprovalFlowsClient.getProjectApprovalFlows()
        .then(res => {
          let app = formateApprovalsList(res);
          setApprovalCollectionState((prevState) => ({
            ...prevState,
            approvals: app
          }));
        })
    ])
      .catch(HandleCaught_API_Error_Base)
      .finally(() => {
        setState((prevState) => ({ ...prevState, tableLoader: false }));
      });
  }

  const tableColumns: Array<ColumnDescription<FormattedGetApprovalFlowsHistoryResponse>> = [
    {
      dataField: "id",
      text: "ID",
      hidden: true
    },
    {
      dataField: "sl_id",
      text: "name",
      headerFormatter: Table_HeaderFormatter,
      // sort: true,
      formatter: (_cell, row) => {
        return (
          <Tippy placement="top" content={row.regardingObject}>
            <span>
              {row.icon && <FontAwesomeIcon className="mr-1"
                // @ts-ignore
                icon={row.icon} size="1x" />}
              &nbsp;{row.regardingObject}
            </span>
          </Tippy>
        )
      },
      style: (_cell, row, _rowIndex, _colIndex) => {
        let group = approvals.filter(val => val.flowEngineHistoryLogId === row.flowEngineHistoryLogId)
        let groupN = approvals.filter(val => val.flowEngineHistoryLogId === row.flowEngineHistoryLogId && val.approvalFlowState !== 1001)
        let color = "";
        let borderColor = "";
        if (group.length > 1) {
          if (group.length !== groupN.length) {
            color = "#fff";
            borderColor = "#dee2e6"
          }
          if (group.length === groupN.length) {
            let checkIfRejected = approvals.filter(val => val.flowEngineHistoryLogId === row.flowEngineHistoryLogId && (val.approvalFlowState === 1003 || val.approvalFlowState === 2003))
            if (checkIfRejected.length > 0) {
              color = "#f8cdc8"
              borderColor = "#f3a29a"
            } else {
              color = "#bae6cf"
              borderColor = "#80d1a6"
            }
          }
        }
        return row.rowSpan ? { backgroundColor: color, borderColor: borderColor } : { visibility: 'hidden' }
      },
      // @ts-ignore
      attrs: (_cell, row, _rowIndex, _colIndex) => {
        return row.rowSpan ? { title: '', rowSpan: row.rowSpan } : { title: '', hidden: true };
      }
    },
    {
      dataField: "approvalName",
      text: "approval.name",
      headerFormatter: Table_HeaderFormatter,
      // sort: true,
      formatter: (_cell, row) => {
        return (
          <div className="d-flex">
            <Col className="p-0">
              <Row className="m-0">
                {row.approvalStepDetails?.name || "-"}
                {row.approvalStepDetails?.description &&
                  <div className="actions ml-2" >
                    <Tippy placement="top" content={row.approvalStepDetails?.description}>
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </Tippy>
                  </div>
                }
              </Row>
            </Col>
          </div>
        )
      }
    },
    {
      dataField: "userName",
      text: "approval.approver",
      headerFormatter: Table_HeaderFormatter,
      sort: true,
    },
    {
      dataField: 'updatedAtTime',
      text: "Submitted_For_Approval",
      headerFormatter: Table_HeaderFormatter,
      formatter: (_cell, row) => {
        return (
          <>
            {approvalStateVisualiser(row.approvalFlowState)}
            <Tippy placement="top" content={<>Sendt til godkendelse: {dateFormatter(row.triggerTime)}</>}>
              <span>
                &nbsp;{dateFormatter(row.updatedAtTime)}
              </span>
            </Tippy>
          </>
        )
      },
      // sort: true
    },
    {
      dataField: "actions",
      text: "actions",
      csvExport: false,
      headerFormatter: Table_HeaderFormatter,
      formatter: (_cell, row) => {
        return (
          <div>
            <div className="btn-group" role="group">
              <>
                {(row.approvalFlowState === 1001 && row.userId === currentOrgData.currentOrgData.userId) && (
                  <button onClick={e =>
                    openApproveModal(row.id, row.regardingObjectId, row.approvalStepDetails?.commentOnApprove, row.dataVersion ?? '1')
                  }
                    type="button" className="greenOutline btn btn-outline-secondary p-1 f-12"><TranslatableText translationKey="approve" /></button>
                )}
                {(row.approvalFlowState === 1001 && row.userId === currentOrgData.currentOrgData.userId) && (
                  <button onClick={e =>
                    openRejectModal(row.id, row.regardingObjectId, row.approvalStepDetails?.commentOnReject, row.dataVersion ?? '1')
                  }
                    type="button" className="redOutline btn btn-outline-secondary p-1 f-12"><TranslatableText translationKey="reject" /></button>
                )}
              </>
              {((row.approvalFlowState === 1002 || row.approvalFlowState === 1003 || row.approvalFlowState === 2002 || row.approvalFlowState === 2003) && row.comments !== "" && row.comments !== null && (row.commentOnApprove || row.commentOnReject)) && (
                <button onClick={e =>
                  // @ts-ignore
                  toggleDetailPopup(<TranslatableText translationKey="comments" />, row.comments ?? '')
                }
                  type="button" className={`${(row.approvalFlowState === 1002 || row.approvalFlowState === 2002) && "greenOutline"} ${(row.approvalFlowState === 1003 || row.approvalFlowState === 2003) && "redOutline"} btn p-1 f-12`}><TranslatableText translationKey="comments" /></button>
              )}
              {row.regardingObjectTypeId === ResourceTypes.task ? (
                <button onClick={() => history.push(`tasks/update/${row.regardingObjectId}`)}
                  type="button" className="btn btn-outline-dark f-12 ml-2 p-1"><TranslatableText translationKey="details" /></button>
              ) : (
                <button onClick={() => history.push(`approvals/details/${row.wizardId}/${row.regardingObjectId}/${row.regardingObjectTypeId}/${row.id}/${row.dataVersion}/${row.icon}`)}
                  type="button" className="btn btn-outline-dark f-12 ml-2 p-1"><TranslatableText translationKey="details" /></button>
              )}
            </div>
          </div>
        );
      }
    }
  ];
  const dateFormatter = (time: dayjs.Dayjs) => {
    return (time.locale(localeDa).format('HH:mm, DD MMM YYYY'));
  };

  const formateApprovalsList = (res: GetApprovalFlowsHistoryResponse[]) => {
    res = res.reverse();
    let tempFinal: FormattedGetApprovalFlowsHistoryResponse[] = []
    let rowId = 1;

    groupBy(res, x => (x.flowEngineHistoryLogId)).forEach((entityAppr) => {
      let t = entityAppr;
      entityAppr.forEach((approval, i) => {
        if (i === 0 || approval.dataVersion === '1') {
          let formattedAprroval = approval as FormattedGetApprovalFlowsHistoryResponse;
          formattedAprroval.rowSpan = approval.dataVersion !== '1' ? entityAppr.length : 1;
          formattedAprroval.sl_id = rowId;
          rowId++;
        }
      })
      tempFinal.push(...t as FormattedGetApprovalFlowsHistoryResponse[])
    })
    console.debug('formatted approvals', tempFinal);

    return tempFinal;
  }

  const getStatusColor = (state: number) => {
    if (state === 1002 || state === 2002) {
      return "green";
    } else if (state === 1003 || state === 2003) {
      return "red";
    } else {
      return "";
    }
  };

  const rowClasses = (row: FormattedGetApprovalFlowsHistoryResponse) => {
    switch (getStatusColor(row.approvalFlowState)) {
      case 'red': // Used in KD for signing that the process is not in use
        return 'table-danger';
      case 'green':
        return 'table-success';
      default:
        return '';
    }
  };

  const toggleApprovePopup = () => {
    setState((prevState) => ({ ...prevState, approveModal: !prevState.approveModal }));

    if (approveModal === false) {
      setState((prevState) => ({
        ...prevState,
        recordId: null,
        regardingObjectId: null,
        comments: ""
      }));
    }
  };

  const openApproveModal = (id: string, _regardingObjectId: string, commentOnApprove: boolean, _dataVersion: string) => {
    setState((prevState) => ({
      ...prevState,
      checkValidation: commentOnApprove,
      approveValidation: false,
      rejectValidation: false,
      approveModal: true,
      recordId: id,
      regardingObjectId: _regardingObjectId,
      dataVersion: _dataVersion,
    }));
  };

  const toggleDetailPopup = (title: string, description: string) => {
    setState((prevState) => ({
      ...prevState,
      detailModal: !prevState.detailModal,
      detailModalTitle: title,
      detailModalDescription: description
    }));
  };

  const toggleRejectPopup = () => {
    setState((prevState) => ({ ...prevState, rejectModal: !prevState.rejectModal }));

    if (rejectModal === false) {
      setState((prevState) => ({
        ...prevState,
        recordId: null,
        regardingObjectId: null,
        comments: ""
      }));
    }
  };

  const openRejectModal = (id: string, _regardingObjectId: string, commentOnReject: boolean, _dataVersion: string) => {
    setState((prevState) => ({
      ...prevState,
      checkValidation: commentOnReject,
      approveValidation: false,
      rejectValidation: false,
      rejectModal: true,
      recordId: id,
      regardingObjectId: _regardingObjectId,
      dataVersion: _dataVersion,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "comments") {
      setState((prevState) => ({
        ...prevState,
        approveValidation: false,
        rejectValidation: false,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const approveFlow = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (recordId === null || regardingObjectId === null) {
      return;
    }
    if (comments === "" && checkValidation) {
      setState((prevState) => ({ ...prevState, approveValidation: true }));
      return;
    }

    let data = new UpdateUserApprovalFlowHistoryRequest({
      id: recordId,
      regardingObjectId: regardingObjectId,
      comments: comments
    });
    setState((prevState) => ({ ...prevState, cuLoader: true }));

    if (dataVersion === "2") {
      _ApprovalFlowsHistoryClient.approveApprovalFlow(data)
        .then(() => { loadApprovals(); })
        .catch(HandleCaught_API_Error_Base)
        .finally(() => {
          toggleApprovePopup();
          setState((prevState) => ({ ...prevState, cuLoader: false, comments: "" }));
        });
    } else {
      _ApprovalFlowsHistoryClient.approveApprovalFlowV1(data)
        .then(() => { loadApprovals(); })
        .catch(HandleCaught_API_Error_Base)
        .finally(() => {
          toggleApprovePopup();
          setState((prevState) => ({ ...prevState, cuLoader: false, comments: "" }));
        });
    }
  };

  const rejectFlow = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (recordId === null || regardingObjectId === null) {
      return;
    }
    if (comments === "" && checkValidation) {
      setState((prevState) => ({ ...prevState, rejectValidation: true }));
      return;
    }

    let data = new UpdateUserApprovalFlowHistoryRequest({
      id: recordId,
      regardingObjectId: regardingObjectId,
      comments: comments
    });

    setState((prevState) => ({ ...prevState, cuLoader: true }));
    if (dataVersion === "2") {
      _ApprovalFlowsHistoryClient.cancelApprovalFlow(data)
        .then(() => { loadApprovals(); })
        .catch(HandleCaught_API_Error_Base)
        .finally(() => {
          toggleRejectPopup();
          setState((prevState) => ({ ...prevState, cuLoader: false, comments: "" }));
        });
    } else {
      _ApprovalFlowsHistoryClient.cancelApprovalFlowV1(data)
        .then(() => { loadApprovals(); })
        .catch(HandleCaught_API_Error_Base)
        .finally(() => {
          toggleRejectPopup();
          setState((prevState) => ({ ...prevState, cuLoader: false, comments: "" }));
        });
    }
  };

  return (
    <div className="admin-partners-view">
      <Accent useIcon={true} icon={faCheckCircle}>
        <TranslatableText translationKey="approvals" />
      </Accent>
      <div className="table-container">
        <ToolkitProvider
          keyField="id"
          data={approvals}
          columns={tableColumns}
          search
          bootstrap4
          exportCSV={{
            fileName: "data.csv",
            separator: currentOrgData.currentOrgData.defaultLanguage === 'dk' ? ';' : ',',
            ignoreHeader: false,
            noAutoBOM: false
          }}
        >
          {props => (
            <div>
              <div className="flex space-between">
                <div>
                  <SearchBar {...props.searchProps} />
                </div>
                <div className="d-flex">

                  <div>
                    {(currentOrgData.currentOrgData.accountLevel >= AccountLevel.Developer) && (
                      <ExportCSVButton className="export-btn" {...props.csvProps} >
                        <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV
                      </ExportCSVButton>
                    )}
                  </div>

                </div>
              </div>
              <hr />
              <BootstrapTable
                noDataIndication={() =>
                  tableLoader ? (
                    <InlineLoading loading={tableLoader} />
                  ) : (
                    <Table_NoDataIndication />
                  )
                }
                rowClasses={rowClasses}
                {...props.baseProps}
                pagination={paginationFactory({ sizePerPage: Table_GetLocalStorage(), onSizePerPageChange: e => Table_SetLocalStorage(e) })} // OBS <-- implement localStorage here
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <Row>
        <Col md={12}>
          <Button onClick={() => history.goBack()}>
            <TranslatableText translationKey="back" />
          </Button>
        </Col>
      </Row>




      <div>
        <Modal
          isOpen={approveModal}
          toggle={toggleApprovePopup}
        >
          <ModalHeader toggle={toggleApprovePopup}>
            <TranslatableText translationKey="approvals.confirm_approval" />
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="approvals.confirm_approval" />
                  </p>
                </Col>
              </Row>
              {checkValidation &&
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    <FormGroup className={approveValidation ? "has-error" : ""}>
                      <Label for="comments">
                        <TranslatableText translationKey="comments" />
                      </Label>
                      <Input
                        className={
                          approveValidation ? "invalid" : "valid"
                        }
                        type="textarea"
                        name="comments"
                        id="comments"
                        value={comments}
                        onChange={handleChange}
                      ></Input>
                      {approveValidation &&
                        <span className="help-block"><TranslatableText translationKey="field_empty" /></span>
                      }
                    </FormGroup>
                  </Col>
                </Row>
              }
            </Form>
          </ModalBody>
          <ModalFooter>
            {cuLoader === false ? (
              <Button
                outline
                className="greenOutline"
                onClick={e => approveFlow(e)}
              >
                <TranslatableText translationKey="approve" />
              </Button>
            ) : (
              <InlineLoading loading={cuLoader} />
            )}{" "}
            <Button outline onClick={toggleApprovePopup}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>


        <Modal
          isOpen={rejectModal}
          toggle={toggleRejectPopup}
        >
          <ModalHeader toggle={toggleRejectPopup}>
            <TranslatableText translationKey="confirm" /> <TranslatableText translationKey="reject" />
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="confirm_reject_tasks" />
                  </p>
                </Col>
              </Row>
              {checkValidation &&
                <Row form>
                  <Col className="multiselect-container" md={12}>
                    <FormGroup className={rejectValidation ? "has-error" : ""}>
                      <Label for="comments">
                        <TranslatableText translationKey="comments" />
                      </Label>
                      <Input
                        className={
                          rejectValidation ? "invalid" : "valid"
                        }
                        type="textarea"
                        name="comments"
                        id="comments"
                        value={comments}
                        onChange={handleChange}
                      ></Input>
                      {rejectValidation &&
                        <span className="help-block"><TranslatableText translationKey="field_empty" /></span>
                      }
                    </FormGroup>
                  </Col>
                </Row>
              }
            </Form>
          </ModalBody>
          <ModalFooter>
            {cuLoader === false ? (
              <Button
                outline
                className="redOutline"
                onClick={e => rejectFlow(e)}
              >
                <TranslatableText translationKey="reject" />
              </Button>
            ) : (
              <InlineLoading loading={cuLoader} />
            )}&nbsp;
            <Button outline onClick={toggleRejectPopup}>
              <TranslatableText translationKey="cancel" />
            </Button>
          </ModalFooter>
        </Modal>


        <Modal
          isOpen={detailModal}
          toggle={() => toggleDetailPopup('', '')}
        >
          <ModalHeader toggle={() => toggleDetailPopup('', '')}>
            {detailModalTitle}
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row form>
                <Col md={12}>
                  <p>
                    {detailModalDescription}
                  </p>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button outline onClick={() => toggleDetailPopup('', '')}>
              <TranslatableText translationKey="cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}
