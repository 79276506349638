import axios from "axios";
import { Config } from "../../../../../../config/Config";
import authService from "../../../../../../components/api-authorization/AuthorizeService";

// Panels API URL
const PANELS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Panels`;

// Panels Translations API URL
const PANEL_TRANSLATIONS_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Panels/Translations`;

const PANEL_TRANSLATIONS_API_STRING = "PanelTranslations";
const PANEL_TRANSLATION_API_STRING = "PanelTranslation";

// Create
export const createPanelAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${PANELS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update
export const updatePanelAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${PANELS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get By ID
export const getPanelByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PANELS_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all
export const getPanelsAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PANELS_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deletePanelByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${PANELS_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Translations

// Get Base translation
export const getPanelBaseTranslationsByIdAPI = async panelID => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PANELS_API_URL}/${panelID}/PanelDefaultTranslation`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Add Translation for a Panel
export const addPanelTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${PANEL_TRANSLATIONS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Specific Panel Translations
export const getPanelTranslationsAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PANELS_API_URL}/${id}/${PANEL_TRANSLATIONS_API_STRING}`, {
      headers: headers
    })
    .then(res => res);
};

// Update Panel Translation API
export const updatePanelTranslationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${PANEL_TRANSLATIONS_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get Single Translation By ID
export const getSingleTranslationAPI = async translationId => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PANELS_API_URL}/${PANEL_TRANSLATION_API_STRING}/${translationId}`, {
      headers: headers
    })
    .then(({ data }) => data);
};

// Association

// Panel Question Association
export const addPanelQuestionAssociationAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${PANELS_API_URL}/PanelQuestionAssociation`, data, {
      headers: headers
    })
    .then(res => res);
};

// Get Panel Questions
export const getPanelQuestions = async (id, lang) => {
  const token = await authService.getAccessToken();
  var headers = {
    Language: lang,
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${PANELS_API_URL}/${id}/PanelQuestions`, { headers: headers })
    .then(({ data }) => data);
};

// Delete Panel Question Association
export const deletePanelQuestionAssociationAPI = async data => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${PANELS_API_URL}/DeletePanelQuestionAssociation`, {
      data: data,
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Delete Translation By ID
export const deletePanelTranslationByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${PANELS_API_URL}/DeletePanelTranslation`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};
