import React, { Component } from "react";
import {
  NavLink,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faInfoCircle,
  faTrash,
  faClipboardCheck,
  faUserNinja
} from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { LanguageContext } from "../../../../contexts/language-context";
import { getClaimsAPI, deleteClaimByIdAPI } from "./companyClaimsAPI";
import Accent from "../../../../components/accent/Accent";
import { toast } from "react-toastify";
import TranslatableText from "../../../../Language/TranslatableText";
import { GlobalContext } from "../../../../contexts/global-context";
import { InlineLoading } from "../../../../components";


const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const NoDataIndication = () => (
  <div className="text-center">
    <span>
      <TranslatableText translationKey="no_data_available" />
    </span>
  </div>
);

class CompanyClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoader: false,
      claims: [],
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true
        },
        {
          dataField: "name",
          text: "Name",
          sort: true
        },
        {
          dataField: "description",
          text: "Description",
          sort: true
        },
        {
          dataField: "entityName",
          text: "Entity",
          sort: true
        },
        {
          dataField: "scopeName",
          text: "Scope",
          sort: true
        },
        {
          dataField: "action",
          text: "Action",
          formatter: (cell, row) => {
            return row.action === 1001 ? "Read" : "Write";
          },
          sort: true
        },
        {
          dataField: "sectionName",
          text: "Section",
          sort: true
        },
        {
          dataField: "actions",
          text: "Actions",
          csvExport: false
        }
      ],

      deleteModal: false,
      deleteLoader: false,
      claimRemovalId: null
    };
  }

  componentDidMount() {
    this.populateClaimsTable();
  }

  populateClaimsTable = () => {
    var self = this;
    this.setState({ tableLoader: true, claims: [] });
    getClaimsAPI()
      .then(res => {
        if (!Array.isArray(res)) {
          console.log("debug: ", res);
          return;
        }
        var data = res.map(function (el) {
          var o = Object.assign({}, el);
          o.actions = (
            <div className="actions">
              <NavLink
                tag={Link}
                to={`${self.props.match.path}/details/${el.id}`}
              >
                <Tippy placement="top" content="See details">
                  <span>
                    <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink
                tag={Link}
                to={`${self.props.match.path}/update/${el.id}`}
              >
                <Tippy placement="top" content="Update">
                  <span>
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                  </span>
                </Tippy>
              </NavLink>
              <NavLink onClick={e => self.openDeleteModal(el.id, e)}>
                <Tippy placement="top" content="Delete">
                  <span>
                    <FontAwesomeIcon
                      style={{ color: "#d84a4a" }}
                      icon={faTrash}
                      size="1x"
                    />
                  </span>
                </Tippy>
              </NavLink>
            </div>
          );
          return o;
        });
        this.setState({
          claims: data,
          language: this.context.language,
          tableLoader: false
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({ tableLoader: false });
      });
  };

  toggleDeletePopup = () => {
    this.setState(
      prevState => ({
        deleteModal: !prevState.deleteModal
      }),
      () => {
        if (this.state.deleteModal === false) {
          this.setState({
            claimRemovalId: null
          });
          console.log("After Modal Dismissal", this.state.deleteModal);
        }
      }
    );
  };

  openDeleteModal = id => {
    this.setState({
      deleteModal: true,
      claimRemovalId: id
    });
  };

  deleteClaim = id => {
    this.setState({ deleteLoader: true });
    deleteClaimByIdAPI(id)
      .then(res => {
        this.populateClaimsTable();
        this.setState({
          deleteModal: false,
          deleteLoader: false,
          claimId: null
        });
      })
      .catch(error => {
        toast.error(this.context.languages["api_error"], {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          autoClose: 3000
        });
        this.setState({
          deleteModal: false,
          deleteLoader: false,
          claimId: null
        });
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let { tableLoader } = this.state;
    return (
      <div className="user-details-view">
        <section>
          <Row>
            <Col md={12}>
              <Accent useIcon={true} icon={faClipboardCheck}>
                <TranslatableText translationKey="claims" />
              </Accent>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="table-container">
                <ToolkitProvider
                  keyField="id"
                  data={this.state.claims}
                  columns={this.state.columns}
                  search
                  exportCSV={{
                    fileName: "data.csv",
                    separator: this.context.user.language === 'dk' ? ';' : ',',
                    ignoreHeader: false,
                    noAutoBOM: false
                  }}
                >
                  {props => (
                    <div>
                      <div className="flex space-between">
                        <div>
                          <SearchBar {...props.searchProps} />
                        </div>
                        <div>
                          <div className="flex space-between">
                            {this.context.userRoles.find(r =>
                              r.includes("Developer")
                            ) !== undefined ? (
                              <ExportCSVButton
                                className="export-btn"
                                {...props.csvProps}
                              >
                                <FontAwesomeIcon icon={faUserNinja} size="1x" />&nbsp;CSV</ExportCSVButton>
                            ) : (
                              <></>
                            )}
                            <div>
                              <NavLink
                                className="button-primary"
                                tag={Link}
                                to={`${this.props.match.path}/create`}
                              >
                                <TranslatableText translationKey="create" />
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <BootstrapTable
                        noDataIndication={() =>
                          tableLoader ? (
                            <InlineLoading />
                          ) : (
                            <NoDataIndication />
                          )
                        }
                        {...props.baseProps}
                        pagination={paginationFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        </section>

        <Row>
          <Col md={12}>
            <Button onClick={this.goBack}>
              <TranslatableText translationKey="back" />
            </Button>
          </Col>
        </Row>
        <div className="modals">
          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeletePopup}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePopup}>
              Confirm Delete?
            </ModalHeader>
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <p>
                    <TranslatableText translationKey="claims.confirm_delete_claim_description" />
                  </p>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.deleteLoader === false ? (
                <Button
                  outline
                  className="redOutline"
                  onClick={e => this.deleteClaim(this.state.claimRemovalId, e)}
                >
                  <TranslatableText translationKey="delete" />
                </Button>
              ) : (
                <InlineLoading />
              )}{" "}
              <Button outline onClick={this.toggleDeletePopup}>
                <TranslatableText translationKey="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanyClaims);
CompanyClaims.contextType = GlobalContext;
