import { useQuery } from '@tanstack/react-query';
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { CustomEntityValuesClient, RegardingObjectTypesClient } from "../../apiClients";
import HandleCaught_API_Error from "../../apiClients/api_caughtError";
import { InlineLoading } from "../../components";
import Accent from "../../components/accent/Accent";
import { useLanguage } from "../../contexts/language-context";
import { usePlatformUserData } from "../../contexts/platform-user-context";
import EntityList from "./components/EntityTable";


type EntityParams = { regardingObjectTypeId: string }

export default function EntityPage(props: any) {// fix type (string) 
  const { regardingObjectTypeId } = useParams<EntityParams>();
  const history = useHistory();
  const _CustomEntityValuesClient = new CustomEntityValuesClient();
  const _RegardingObjectTypesClient = new RegardingObjectTypesClient();
  const organisationData = usePlatformUserData();
  const { language } = useLanguage();
  let _regardingObjectTypeId: string;

  // if props exists --> dont contact api. 
  if (props.regardingObjectTypeId == undefined) {
    _regardingObjectTypeId = regardingObjectTypeId;
  } else {
    _regardingObjectTypeId = props.regardingObjectTypeId;
  }

  const regardingObjectTypeQuery = useQuery(['regardingObjectTypes', _regardingObjectTypeId], async () => {
    return _RegardingObjectTypesClient.regardingObjectTypes(_regardingObjectTypeId);
  });
  
  return (
    <div className="entities-view">
      <Row>
        <Col md={12}>
          <Accent useIcon={false}>
            {regardingObjectTypeQuery.isLoading ? (
              <InlineLoading />
            ) : (
              regardingObjectTypeQuery.data?.displayName
            )}
          </Accent>
        </Col>
      </Row>
      {/* Table */}
      <EntityList regardingObjectTypeId={_regardingObjectTypeId} />
      <Row>
        <Col md={12}>
          <Button onClick={() => history.goBack()}>Back</Button>
        </Col>
      </Row>
    </div>
  );
}
