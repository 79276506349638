import axios from "axios";
import { Config } from "../../../../config/Config";
import authService from "../../../../components/api-authorization/AuthorizeService";

// Roles API URL
const TEMPLATE_ROLES_API_URL = `${Config.server_url}:${Config.port}${Config.api_prefix}/Roles`;

// Create
export const createRoleAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${TEMPLATE_ROLES_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Update
export const updateRoleAPI = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .put(`${TEMPLATE_ROLES_API_URL}`, data, { headers: headers })
    .then(res => res);
};

// Get by ID
export const getRoleByIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TEMPLATE_ROLES_API_URL}/${id}`, { headers: headers })
    .then(res => res);
};

// Get all
export const getRolesAPI = async () => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TEMPLATE_ROLES_API_URL}`, { headers: headers })
    .then(({ data }) => data);
};

// Delete By ID
export const deleteRoleByIdAPI = async (id, userId) => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${TEMPLATE_ROLES_API_URL}`, {
      data: {
        id: id,
        userId: userId
      },
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};

// Role-Claim Association

export const templateRoleClaimAssociation = async data => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .post(`${TEMPLATE_ROLES_API_URL}/RoleClaimAssociation`, data, {
      headers: headers
    })
    .then(res => res);
};

// Role-Claim Association list API
export const getClaimsByRoleIdAPI = async id => {
  const token = await authService.getAccessToken();
  var headers = {
    Authorization: `Bearer ${token}`
  };
  return axios
    .get(`${TEMPLATE_ROLES_API_URL}/${id}/Claims`, { headers: headers })
    .then(({ data }) => data);
};

// Delete Association
export const deleteRoleClaimAssociationAPI = async data => {
  const token = await authService.getAccessToken();
  return axios
    .delete(`${TEMPLATE_ROLES_API_URL}/DeleteRoleClaimAssociation`, {
      data: data,
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res);
};
